import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'survey_users'

export default {
  getSurveyUser (id) {
    return api.get(`${resource}/${id}`)
  },
  getAssignedToMeRecent (filters) {
    return api.get(`${resource}/assigned_to_me?completed=false&${stringify(decamelize(filters))}`)
  },
  getAssignedToMeCompleted (filters) {
    return api.get(`${resource}/assigned_to_me?completed=true&${stringify(decamelize(filters))}`)
  }
}
