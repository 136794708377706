<template>
  <div style="margin-top: 3rem">
    <b-row>
      <abk-loader v-if="showLoader" class="with-margins"/>
      <template v-else-if="news.length">
        <abk-news v-for="n in news"
                  class="col-12 message"
                  :title="n.title"
                  :key="n.id"
                  :date="n.date"
                  :message="n.content"
                  :address="n.address"
                  :image="n.image"
                  @newsClick="goToNews(n)">
          {{ n.content }}
        </abk-news>
      </template>
      <pcg-box v-else>
        <span class="pcg-no-records">{{ $t('views.news.news_list.no_records') }}</span>
      </pcg-box>
    </b-row>
    <pcg-pagination v-model="pagination.currentPage"
                    @input="getNews"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkNews from '../../components/AbkNews'
import ApiWebapges from '../../api/webpages'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { mapGetters } from 'vuex'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'NewsList',
  components: { AbkLoader, AbkNews },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      news: []
    }
  },
  computed: {
    ...mapGetters('auth', ['roles', 'currentRole'])
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.news.news_list.title')
      })
    },
    getNews () {
      ApiWebapges.getNews({ page: this.pagination.currentPage, published: true })
        .then(response => {
          this.setPagination(response)
          this.news = response.data.data.map(n => {
            return {
              id: n.id,
              title: n.attributes.title,
              image: n.attributes.image.url,
              content: n.attributes.content,
              date: n.attributes.createdAt,
              address: n.attributes.address
            }
          })
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          this.loadingDone = true
        })
    },
    goToNews (news) {
      this.$router.push({ name: 'webpages_show_path', params: { id: news.address } })
    }
  },
  mounted () {
    this.getNews()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars";

.message {
  cursor: pointer;
}

.top-bar {
  margin-bottom: $pcg-m-sm;
}
.top-bar-content {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.filters-text {
  white-space: nowrap;
  color: $pcg-gray;
  font-weight: 500;
  margin-right: $pcg-m-sm;
}
.filter-select {
  margin: $pcg-m-sm $pcg-m-sm;
  width: 220px;
}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
