<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <div class="d-flex">
        <div class="icon-container">
          <i class="far fa-calendar"/>
        </div>
        <div>{{ $t('components.abk_upcoming_events_box.title') }}</div>
      </div>
      <router-link class="pcg-link pcg-small text-right" :to="{ name: 'events_list_all_tab_path' }">
        {{ $t('general.show_all') }}
      </router-link>
    </div>
<!--    <pcg-search-select :options="cities" v-model="actualLocation" class="location-select"/>-->
    <ul v-if="events && events.length" class="events">
      <li v-for="event in events" :key="event.id" class="event">
        {{ event.eventTitle }}
        <div class="d-flex justify-content-between">
          <div class="event-location">{{ event.place }}</div>
        </div>
        <ul class="subevents">
          <li v-for="sub in event.subEvents" :key="sub.id" class="subevent">
            <router-link class="event-link" :to="sub.path">
              {{ sub.attributes[0] }}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <div v-else class="pcg-no-records ml-5 mt-4">
      {{ $t('components.abk_upcoming_events_box.no_records') }}
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkUpcomingEventsBox',
  props: {
    events: Array,
    dateFormat: {
      type: String,
      default: 'D MMMM'
    }
  },
  data () {
    return {
      actualLocation: 'warszawa'
    }
  },
  methods: {
    formatDate (date) {
      if (date) {
        const momentDate = moment(date)
        return momentDate.isValid() ? momentDate.format(this.dateFormat) : null
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  /deep/ .box {
    min-height: 100%;
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .pcg-box-inner-header {
    padding: 0 $pcg-box-padding-x;
    margin-bottom: $pcg-m-xs;
  }
  .location-select {
    position: absolute!important;
    padding-left: 32px;
    margin-top: -0.6em;
    /deep/ .dropdown-menu {
      transform: translateX(30px);
    }
    /deep/ .change-value-btn {
      font-size: $font-size-s;
      color: $main-active-color;
      font-weight: bold;
    }
    /deep/ .select-input {
      input[disabled] {
        color: $pcg-dark-gray;
      }
    }
  }
  .events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .subevents {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .event {
    position: relative;
    padding: 15px $pcg-box-padding-x 15px 52px;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &:before {
      content: $pcg-icon-bullet;
      color: $pcg-light-gray;
      top: .7em;
      left: 1.857rem;
      position: absolute;
      font-size: $font-size-xl;
    }
  }
  .event-link {
    display: block;
    color: $main-color;
    font-weight: 400;
    margin-bottom: .1em;
  }
  .event-location {
    color: $pcg-dark-gray;
    max-width: 50%;
    line-height: 1.2;
  }
  .event-date {
    color: $pcg-gray;
  }
  .event-location, .event-date {
    font-size: $font-size-s;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .location-select {
      /deep/ .change-value-btn {
        color: $main-active-color;
      }
      /deep/ .select-input {
        input[disabled] {
          color: $pcg-dark-gray;
        }
      }
    }
    .event {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
      &:before {
        color: $pcg-light-gray;
      }
    }
    .event-link {
      color: $main-color;
    }
    .event-location {
      color: $pcg-dark-gray;
    }
    .event-date {
      color: $pcg-gray;
    }
  }
</style>
