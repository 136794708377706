<template>
  <div>
    <!-- Basic info -->
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_1_form.basic_info') }}</div>
    <div class="horizontal-container mb-md">
      <pcg-text-input :label="$t('components.surveys.steps.abk_survey_step_1_form.survey_name')"
                      required
                      :disabled="disableInputs"
                      v-model="value.name"
                      :error-text="getError('name')"
                      :show-error="errorExist('name')"
                      class="survey-name-input"/>
      <router-link v-if="!disableInputs"
                   :to="{ name: 'surveys_list_active_tab_path' }"
                   class="pcg-link ml-lg mt-sm create-survey-link">
        {{ $t('components.surveys.steps.abk_survey_step_1_form.create_survey_based_on_another') }}
      </router-link>
    </div>
    <div>
      <div class="pcg-input-label">{{ $t('components.surveys.steps.abk_survey_step_1_form.survey_attributes') }}</div>
      <div class="d-sm-flex">
        <pcg-checkbox :value="value.requiresAnswer"
                      :error-text="getError('requiresAnswer')"
                      :show-error="errorExist('requiresAnswer')"
                      :disabled="disableInputs"
                      @input="updateRequiresAnswer">
          {{ $t('general.surveys.attributes.requires_answer') }}
        </pcg-checkbox>
        <pcg-checkbox v-model="value.anonymous"
                      :error-text="getError('anonymous')"
                      :show-error="errorExist('anonymous')"
                      :disabled="disableInputs">
          {{ $t('general.surveys.attributes.anonymous') }}
        </pcg-checkbox>
        <pcg-checkbox v-model="value.graduateFate"
                      :error-text="getError('graduateFate')"
                      :show-error="errorExist('graduateFate')"
                      :disabled="disableInputs">
          {{ $t('general.surveys.attributes.graduate_fate') }}
        </pcg-checkbox>
      </div>
    </div>
    <div class="pcg-separator"/>

    <!-- Publication period -->
    <div class="pcg-section-title">
      {{ $t('components.surveys.steps.abk_survey_step_1_form.publication_period') }}
    </div>
    <transition name="slide">
      <pcg-text-input v-if="value.graduateFate"
                      v-model="value.graduateSendDate"
                      :disabled="disableInputs"
                      :label="$t('components.surveys.steps.abk_survey_step_1_form.graduate_send_date')"
                      class="graduate-send-date-input"
                      required
                      type="number"
                      :error-text="getError('graduateSendDate')"
                      :show-error="errorExist('graduateSendDate')"/>
    </transition>
    <abk-datetime-picker v-model="value.expirationDate"
                         :disabled="disableInputs"
                         :label="$t('components.surveys.steps.abk_survey_step_1_form.expiration_date')"
                         variant="date"
                         class="expiration-date-picker"
                         :error-text="getError('expirationDate')"
                         :show-error="errorExist('expirationDate')"/>
    <div class="pcg-separator"/>

    <!-- Reminders -->
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_1_form.reminders') }}</div>
    <div>
      <b-collapse v-for="i in 3" :id="`reminder-collapse-${i}`" :key="i" :visible="expandedReminders >= i">
        <div class="horizontal-container reminder-container">
          <div class="pcg-input-label mr-lg align-self-start reminder-title">
            {{ $t(`components.surveys.steps.abk_survey_step_1_form.reminder_${i}`) }}
          </div>
          <div class="reminder-input-container">
            <span v-if="expandedReminders === i && !disableInputs"
                  class="pcg-link pcg-small delete-reminder-btn ml-auto"
                  @click="removeLastReminder">
              ✕ {{ $t('components.surveys.steps.abk_survey_step_1_form.delete') }}
            </span>
            <pcg-input-number v-model="value[`reminderTime${i}`]"
                              :disabled="disableInputs"
                              class="reminder-input"
                              :min="1"
                              :label="$t('components.surveys.steps.abk_survey_step_1_form.number_of_days_from_receipt')"
                              :error-text="getError(`reminderTime${i}`)"
                              :show-error="errorExist(`reminderTime${i}`)"/>
            <pcg-text-input v-model="value[`reminderTitle${i}`]"
                            :disabled="disableInputs"
                            :label="$t('components.surveys.steps.abk_survey_step_1_form.reminder_title')"
                            :error-text="getError(`reminderTitle${i}`)"
                            :show-error="errorExist(`reminderTitle${i}`)"/>
            <pcg-text-input v-model="value[`reminderMessage${i}`]"
                            :disabled="disableInputs"
                            :label="$t('components.surveys.steps.abk_survey_step_1_form.reminder_content')"
                            type="textarea"
                            :error-text="getError(`reminderMessage${i}`)"
                            :show-error="errorExist(`reminderMessage${i}`)"/>
          </div>
        </div>
      </b-collapse>
      <span v-if="expandedReminders < 3 && !disableInputs" class="pcg-link" @click="++expandedReminders">
        + {{ $t('components.surveys.steps.abk_survey_step_1_form.add_reminder') }}
      </span>
    </div>
    <div class="pcg-separator"/>

    <!-- Recipient group -->
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_1_form.receivers_group') }}</div>
      <div class="col-12 form-group">
        <transition name="slide" mode="out-in">
        <b-row v-if="!showRecipientGroupForm" class="align-items-center">
          <pcg-text-input v-if="disableInputs"
                          disabled
                          class="abk-input col-12 col-lg"
                          :value="selectedRecipientGroupName"/>
          <pcg-search-select v-else
                             class="abk-input col-12 col-lg"
                             always-active
                             :options="recipientGroups"
                             :value="String(value.recipientGroupId)"
                             :error-text="getError('recipientGroupId') || getError('recipientGroup')"
                             :show-error="errorExist('recipientGroupId') || errorExist('recipientGroup')"
                             @input="$set(value, 'recipientGroupId', $event)"/>
          <b-col v-if="!disableInputs" class="col-12 col-lg-auto text-center recipient-group-separator">
            {{ $t('general.or') }}
          </b-col>
          <b-col v-if="!disableInputs" class="col-12 col-lg-auto text-center">
            <pcg-btn size="small"
                     variant="additional"
                     class="action-btn"
                     @click="showRecipientGroupForm = true">
              {{ $t('general.create_new') }}
            </pcg-btn>
          </b-col>
        </b-row>
        <abk-recipient-group-form v-if="showRecipientGroupForm"
                                  :universityStructure="universityStructure"
                                  :messageRecipientTypes="value.recipientTypes"
                                  parent-type="survey"
                                  @saved="recipientGroupSaved"
                                  @close="showRecipientGroupForm = false"/>
        </transition>
      </div>
    <transition name="slide" mode="out-in">
      <div class="col-12 form-group" v-if="!showRecipientGroupForm">
        <label class="pcg-input-label">
          {{ $t('components.surveys.steps.abk_survey_step_1_form.send_to') }}
        </label>
        <div class="recipients-list" :class="{ collapsed: recipientsCollapsed }">
          {{ recipients }}
          <span class="custom-ellipsis" @click="recipientsCollapsed = !recipientsCollapsed" v-if="recipients">
            {{ toggleRecipientsText }}
          </span>
        </div>
        <div class="recipient-count mt-2">
          {{ $t('components.surveys.steps.abk_survey_step_1_form.recipients_count') }}
          <b>{{ recipientsCount }}</b>
        </div>
      </div>
    </transition>
    <div class="pcg-separator"/>

    <!-- Message -->
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_1_form.message') }}</div>
    <pcg-text-input v-model="value.title"
                    :label="$t('components.surveys.steps.abk_survey_step_1_form.message_title')"
                    :disabled="disableInputs"
                    required
                    :error-text="getError('title')"
                    :show-error="errorExist('title')"
                    class="mb-md"/>
    <pcg-text-input v-model="value.message"
                    :label="$t('components.surveys.steps.abk_survey_step_1_form.add_message_content')"
                    :disabled="disableInputs"
                    required
                    type="textarea"
                    :error-text="getError('message')"
                    :show-error="errorExist('message')"
                    class="textarea-input"/>
    <div class="pcg-separator"/>

    <!-- Additional info -->
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_1_form.additional_info') }}</div>
    <pcg-text-input v-model="value.informationText"
                    :disabled="disableInputs"
                    type="textarea"
                    required
                    class="mb-md textarea-input"
                    :label="$t('components.surveys.steps.abk_survey_step_1_form.description')"
                    :error-text="getError('informationText')"
                    :show-error="errorExist('informationText')"/>
    <pcg-text-input v-model="value.messageAfterCompleting"
                    :disabled="disableInputs"
                    type="textarea"
                    class="mb-md textarea-input"
                    :label="$t('components.surveys.steps.abk_survey_step_1_form.message_after_completing')"
                    :error-text="getError('messageAfterCompleting')"
                    :show-error="errorExist('messageAfterCompleting')"/>
  </div>
</template>

<script>
import AbkDatetimePicker from '../../AbkDatetimePicker'
import AbkRecipientGroupForm from '../../recipient_groups/AbkRecipientGroupForm'
import ApiRecipientGroups from '../../../api/recipient_groups'
import ApiMessages from '../../../api/messages'

export default {
  name: 'AbkSurveyStep1Form',
  components: {
    AbkRecipientGroupForm,
    AbkDatetimePicker
  },
  props: {
    value: Object,
    errors: Object,
    recipientGroups: Array,
    disableInputs: Boolean
  },
  data () {
    return {
      showRecipientGroupForm: false,
      recipients: '',
      recipientsCount: 0,
      universityStructure: {},
      recipientsCollapsed: true,
      expandedReminders: 0
    }
  },
  watch: {
    value (newValue) {
      this.computeExpandedReminders(newValue)
    },
    watchedRecipientType () {
      this.getUsers()
      this.value.recipientGroupId = null
    },
    watchedRecipientGroupId () {
      this.getUsers()
    },
    recipientGroups (newValue) {
      const recipientGroupsIds = newValue.map(e => String(e.value))
      if (!recipientGroupsIds.includes(String(this.value.recipientGroupId))) {
        this.value.recipientGroupId = null
      }
    }
  },
  computed: {
    watchedRecipientType () {
      return this.value.recipientTypes
    },
    watchedRecipientGroupId () {
      return this.value.recipientGroupId
    },
    toggleRecipientsText () {
      return this.recipientsCollapsed
        ? this.$t('components.surveys.steps.abk_survey_step_1_form.show_more')
        : this.$t('components.surveys.steps.abk_survey_step_1_form.collapse')
    },
    selectedRecipientGroupName () {
      const selectedRecipientGroup = this.recipientGroups.find(e => {
        return String(e.value) === String(this.value.recipientGroupId)
      })
      return selectedRecipientGroup ? selectedRecipientGroup.text : ''
    }
  },
  mounted () {
    this.getRecipientGroups()
    this.getUsers()
    this.getUniversityStructure()
    this.computeExpandedReminders(this.value)
  },
  methods: {
    computeExpandedReminders (survey) {
      const lastCompletedReminder = this.getLastCompletedReminder(survey)
      if (lastCompletedReminder > this.expandedReminders) {
        this.expandedReminders = lastCompletedReminder
      }
    },
    getLastCompletedReminder (value) {
      const completedRemindersPairs = _.toPairs(value).filter(([key, value]) => {
        return key.match(/reminder(Time|Title|Message)[1-3]/) && !_.isNil(value) && String(value).length > 0
      })
      const completedRemindersNumbers = completedRemindersPairs.map(([key, _]) => {
        return parseInt(key.replace(/reminder(Time|Title|Message)/, ''))
      })

      return completedRemindersNumbers.length > 0 ? Math.max(...completedRemindersNumbers) : 0
    },
    removeLastReminder () {
      const lastReminder = this.expandedReminders--
      this.$emit('input', {
        ...this.value,
        [`reminderTime${lastReminder}`]: null,
        [`reminderTitle${lastReminder}`]: null,
        [`reminderMessage${lastReminder}`]: null
      })
    },
    updateRequiresAnswer (requiresAnswer) {
      this.value.requiresAnswer = requiresAnswer
      if (requiresAnswer) {
        this.$emit('requiresAnswer')
      }
    },
    recipientGroupSaved (recipientGroupId) {
      this.value.recipientGroupId = recipientGroupId
      this.getRecipientGroups()
    },
    getRecipientGroups () {
      ApiRecipientGroups.getSurveysRecipientGroups()
        .then(response => {
          this.$emit('update:recipientGroups', response.data.data.map(recipientGroup => {
            return {
              text: recipientGroup.attributes.name,
              value: recipientGroup.id
            }
          }))
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.surveys.steps.abk_survey_step_1_form.error_download_recipients'))
        })
    },
    getUsers () {
      ApiMessages.getUserList({
        recipient_group_id: this.value.recipientGroupId,
        recipient_type: this.value.recipientTypes,
        message_type: 'survey',
        graduate_fate: this.value.graduateFate
      })
        .then(response => {
          this.recipientsCollapsed = true
          this.recipients = response.data.records.join(', ')
          this.recipientsCount = response.data.count
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.surveys.steps.abk_survey_step_1_form.error_download_list_of_recipients'))
        })
    },
    getUniversityStructure () {
      ApiMessages.getUniversityStructure()
        .then(response => {
          this.universityStructure = response.data
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.surveys.steps.abk_survey_step_1_form.error_university_structure'))
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .pcg-link {
    cursor: pointer;
  }
  .horizontal-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .survey-name-input {
    flex-grow: 1;
  }
  .mt-sm {
    margin-top: $pcg-m-sm;
  }
  .ml-lg {
    margin-left: $pcg-m-lg;
  }
  .mr-lg {
    margin-right: $pcg-m-lg;
  }
  .mb-md {
    margin-bottom: $pcg-m-md;
  }
  .pcg-separator {
    margin: $pcg-m-xl 0;
  }
  .graduate-send-date-input {
    /deep/ .form-control {
      width: 155px;
    }
  }
  .textarea-input {
    /deep/ .form-control {
      min-height: 250px !important;
    }
  }
  .reminder-title {
    margin-top: $pcg-m-xxl;
  }
  .reminder-container {
    &:not(:last-child) {
      margin-bottom: $pcg-m-xl;
    }
  }
  .reminder-input-container {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: $pcg-m-xl;
  }
  .delete-reminder-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .reminder-title-input {
    flex-grow: 1;
    /deep/ .form-control {
      width: 100%;
    }
  }
  .reminder-input {
    width: 155px;
  }
  .expiration-date-picker {
    width: 220px;
  }
  .recipient-count {
    color: $pcg-dark-gray;
    line-height: $font-size-m;
    font-size: $font-size-s;
  }
  .recipients-list {
    color: $pcg-dark-gray;
    line-height: $font-size-m;
    font-size: $font-size-s;
    .custom-ellipsis {
      color: $main-active-color;
      font-weight: bolder;
      cursor: pointer;
    }
    &.collapsed {
      white-space: nowrap;
      overflow: hidden;
      .custom-ellipsis {
        position: absolute;
        right: 10px;
        // background color
        background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0) 0%,
                rgba(247, 248, 250, 1) 25%,
                rgba(247, 248, 250, 1) 0%
        );
        padding-left: 30px;
      }
    }
  }
  .recipient-group-separator {
    color: $pcg-the-darkest-gray;
    font-size: $font-size-s;
    @media (max-width: $screen-md-max) {
      margin-top: 10px;
    }
  }
  @media(max-width: $screen-sm-max) {
    .delete-reminder-btn {
      top: -36px;
    }
    .reminder-title {
      margin-top: 0;
      margin-bottom: $pcg-m-lg;
    }
    .reminder-input-container {
      width: 100%;
    }
    .reminder-container {
      flex-direction: column;
    }
    .reminder-input {
      flex-grow: 1;
    }
    .reminder-input, .survey-name-input, .expiration-date-picker {
      width: 100%;
    }
    .create-survey-link {
      margin-left: 0;
      margin-bottom: $pcg-m-sm;
    }
    .survey-name-input {
      margin-bottom: 0;
    }
    .pcg-separator {
      margin: $pcg-m-lg 0;
    }
    .mb-md {
      margin-bottom: $pcg-m-sm;
    }
  }
</style>

<style lang="scss">
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast {
    .recipients-list {
      color: $pcg-dark-gray;
      .custom-ellipsis {
        color: $main-active-color;
      }
      &.collapsed {
        .custom-ellipsis {
          background: $pcg-the-lightest-gray;
        }
      }
    }
  }
</style>
