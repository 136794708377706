<template>
  <b-row>
    <div class="w-100 text-center">
      <h4 v-if="showCreateFromTemplate" class="pcg-new-offer-header-text m-0">
        {{ $t('components.abk_new_offer_step_1_form.title') }}
        <router-link class="pcg-link" :to="{ name: 'offers_list_path' }">
          {{ $t('components.abk_new_offer_step_1_form.use_template') }}
        </router-link>
      </h4>
    </div>
    <b-col cols="12" xl="8">
      <div class="form-container separator">
        <b-row class="radio-container">
          <pcg-radio class="col-12"
                     :required="true"
                     :options="options.offerType"
                     v-model="value.offerType"
                     name="offer-type"
                     :show-error="errorExist('offerType')"
                     :error-text="getError('offerType')"
                     :label="$t('components.abk_new_offer_step_1_form.offer_type')"/>
        </b-row>
        <b-row>
          <pcg-search-select v-if="showEmployersSelect"
                             class="abk-input col-12 col-lg-6"
                             :required="true"
                             always-active
                             show-label
                             :options="options.company"
                             :value="getDictionaryAttributeValue('company')"
                             @input="updateDictionaryAttribute('company', $event)"
                             :error-text="getError('company')"
                             :show-error="errorExist('company')"
                             :label="$t('components.abk_new_offer_step_1_form.company')"/>
        </b-row>
        <b-row>
          <pcg-text-input class="abk-input col-12 mb-0"
                          :required="true"
                          :label="$t('components.abk_new_offer_step_1_form.job_title')"
                          :error-text="getError('jobTitle')"
                          :show-error="errorExist('jobTitle')"
                          v-model="value.jobTitle"/>
        </b-row>
      </div>
      <div class="form-container separator">
        <b-row>
          <pcg-search-select class="abk-input col-12 col-lg-6"
                             always-active
                             show-label
                             :options="options.workplace"
                             :value="getDictionaryAttributeValue('workplace')"
                             @input="updateDictionaryAttribute('workplace', $event)"
                             :error-text="getError('workplace')"
                             :show-error="errorExist('workplace')"
                             :show-value-addition="isDictionaryOpen('workplace')"
                             :dictionary-id="getDictionaryId('workplace')"
                             @showModalSuggestion="showModalSuggestion(getDictionaryId('workplace'))"
                             :label="$t('components.abk_new_offer_step_1_form.workplace')"/>
        </b-row>
        <b-row>
          <pcg-search-select class="abk-input col-12 col-lg-6"
                             always-active
                             show-label
                             :options="options.trade"
                             :value="getDictionaryAttributeValue('trade')"
                             @input="updateDictionaryAttribute('trade', $event)"
                             :error-text="getError('trade')"
                             :show-error="errorExist('trade')"
                             :show-value-addition="isDictionaryOpen('trade')"
                             @showModalSuggestion="showModalSuggestion(getDictionaryId('trade'))"
                             :label="$t('components.abk_new_offer_step_1_form.trade')"/>
        </b-row>
        <b-row class="d-flex">
          <pcg-select class="abk-input col-12 col-lg-4"
                      :label="$t('components.abk_new_offer_step_1_form.position_type')"
                      :options="options.positionType"
                      :error-text="getError('positionType')"
                      :show-error="errorExist('positionType')"
                      :value="getDictionaryAttributeValue('positionType')"
                      :show-value-addition="isDictionaryOpen('positionType')"
                      @showModalSuggestion="showModalSuggestion(getDictionaryId('positionType'))"
                      @input="updateDictionaryAttribute('positionType', $event)"/>
          <pcg-select class="abk-input col-12 col-lg-4"
                      :label="$t('components.abk_new_offer_step_1_form.type_of_work')"
                      :options="options.typeOfWork"
                      :error-text="getError('typeOfWork')"
                      :show-error="errorExist('typeOfWork')"
                      :value="getDictionaryAttributeValue('typeOfWork')"
                      :show-value-addition="isDictionaryOpen('typeOfWork')"
                      :dictionary-id="getDictionaryId('typeOfWork')"
                      @showModalSuggestion="showModalSuggestion(getDictionaryId('typeOfWork'))"
                      @input="updateDictionaryAttribute('typeOfWork', $event)"/>
          <pcg-select class="abk-input col-12 col-lg-4"
                      :label="$t('components.abk_new_offer_step_1_form.work_dimension')"
                      :options="options.workDimension"
                      :error-text="getError('workDimension')"
                      :show-error="errorExist('workDimension')"
                      :value="getDictionaryAttributeValue('workDimension')"
                      :show-value-addition="isDictionaryOpen('workDimension')"
                      :dictionary-id="getDictionaryId('workDimension')"
                      @showModalSuggestion="showModalSuggestion(getDictionaryId('workDimension'))"
                      @input="updateDictionaryAttribute('workDimension', $event)"/>
        </b-row>
        <b-row>
          <pcg-text-input class="abk-input col-12 col-lg-4"
                          min="1"
                          :label="$t('components.abk_new_offer_step_1_form.free_places')"
                          v-model="value.freePlaces"
                          :show-error="errorExist('freePlaces')"
                          :error-text="getError('freePlaces')"/>
        </b-row>
        <b-row>
          <div class="abk-input col-12 col-lg-5">
            <abk-datetime-picker class="expiration-date-input mb-0"
                                 :label="$t('components.abk_new_offer_step_1_form.publication_date')"
                                 variant="datetime"
                                 v-model="value.publicationDate"
                                 :error-text="getError('publicationDate')"
                                 :show-error="errorExist('publicationDate')"/>
          </div>
          <div class="abk-input col-0 col-lg-2 mb-0"></div>
          <div class="abk-input col-12 col-lg-5">
            <abk-datetime-picker class="expiration-date-input mb-0"
                                 :label="$t('components.abk_new_offer_step_1_form.expiration_date')"
                                 variant="datetime"
                                 v-model="value.expirationDate"
                                 :error-text="getError('expirationDate')"
                                 :show-error="errorExist('expirationDate')"/>
          </div>
        </b-row>
      </div>
      <div class="form-container">
        <div class="salary-container-header">{{ $t('components.abk_new_offer_step_1_form.salary_text') }}</div>
        <b-row class="salary-container">
          <b-col cols="6" lg="3" class="salary-wrapper">
            <span class="salary-indicator">{{ $t('general.from') }}</span>
            <pcg-text-input class="flex-grow-1"
                            :show-label="false"
                            v-model="value.salaryFrom"
                            :show-error="errorExist('salaryFrom')"
                            :error-text="getError('salaryFrom')"/>
            <span class="salary-indicator">{{ $t('general.currency.pln') }}</span>
          </b-col>
          <b-col cols="6" lg="3" class="salary-wrapper">
            <span class="salary-indicator">{{ $t('general.to') }}</span>
            <pcg-text-input class="flex-grow-1"
                            :show-label="false"
                            v-model="value.salaryTo"
                            :show-error="errorExist('salaryTo')"
                            :error-text="getError('salaryTo')"/>
            <span class="salary-indicator">{{ $t('general.currency.pln') }}</span>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <abk-suggest-dictionary-word-modal v-model="showSuggestDictionaryWordModal" :dictionary-id="currentDictionaryId"/>
  </b-row>
</template>

<script>
import AbkDatetimePicker from './AbkDatetimePicker'
import ApiDictionaries from '../api/dictionaries'
import humps from 'humps'
import { mapGetters } from 'vuex'
import ApiCompanies from '../api/companies'
import ApiOffers from '../api/offers'
import AbkSuggestDictionaryWordModal from './AbkSuggestDictionaryWordModal'
import SuggestingDictionaryWordsMixin from '../mixins/suggesting_dictionary_words'

export default {
  name: 'AbkNewOfferStep1Form',
  components: { AbkDatetimePicker, AbkSuggestDictionaryWordModal },
  mixins: [SuggestingDictionaryWordsMixin],
  props: {
    value: Object,
    companies: Array,
    errors: Object
  },
  data () {
    return {
      offersCount: 0,
      dictionaries: ['workplace', 'trade', 'positionType', 'typeOfWork', 'workDimension'],
      maxWorkLocations: 7,
      options: {
        offerType: [
          { text: this.$t('general.job'), value: 'job' },
          { text: this.$t('general.training'), value: 'training' },
          { text: this.$t('general.practice'), value: 'practice' }
        ]
      },
      dictionariesMetaInfo: {}
    }
  },
  watch: {
    companies () {
      this.options.company = this.companies
    }
  },
  methods: {
    getDictionaryId (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryId : null
    },
    isDictionaryOpen (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryType === 'open' && !this.isCareerOffice : false
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    updateDictionaries (responses) {
      this.dictionaries.forEach((dictionary, index) => {
        if (responses[index] && responses[index].data && responses[index].data.data) {
          const options = responses[index].data.data.map(({ id, attributes }) => {
            return { text: attributes.value, value: id }
          })
          this.$set(this.options, dictionary, options)
          this.$set(this.dictionariesMetaInfo, dictionary, responses[index].data.meta)
        }
      })
    },
    getDictionaryAttributeValue (attribute) {
      return this.value[attribute] ? this.value[attribute].value : null
    },
    updateDictionaryAttribute (attribute, value) {
      const valueObject = this.options[attribute].find(e => e.value === value)
      const newForm = { ...this.value, [attribute]: valueObject }

      this.$emit('input', newForm)
    },
    getDictionaries () {
      ApiDictionaries.getSelectedDictionaries(this.decamelizedDictionaries)
        .then(responses => {
          this.updateDictionaries(responses)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('general.error_try_later'))
        })
    },
    getOffersInfo () {
      if (!this.showEmployerActions) { return }
      ApiOffers.getOffersInfo()
        .then(response => {
          this.offersCount = response.data.totalCount
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters('auth', ['roles']),
    decamelizedDictionaries () {
      return this.dictionaries.map(dictionary => humps.decamelize(dictionary))
    },
    showEmployersSelect () {
      return this.isCareerOffice && !this.employerId
    },
    isCareerOffice () {
      return this.$store.getters['auth/currentRole'] === 'career_office'
    },
    showEmployerActions () {
      return this.roles && (this.roles.includes('employer') || this.roles.includes('recruiter'))
    },
    showCreateFromTemplate () {
      return this.showEmployerActions && this.offersCount > 0
    }
  },
  mounted () {
    this.getDictionaries()
    this.getOffersInfo()
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .radio-container {
    margin-bottom: $pcg-m-lg;
  }
  .pcg-new-offer-header-text {
    margin-bottom: $pcg-m-md;
  }
  .form-container {
    width: 100%;
    padding: 1.785rem 0 $pcg-m-xl;
  }
  .separator {
    border-bottom: 1px solid $pcg-lightest-gray;
  }
  .offer-type-select {
    margin-bottom: 1rem;
    /deep/ label {
      font-size: $font-size-s;
    }
  }
  /deep/ .abk-input {
    margin-bottom: $pcg-m-lg;
    height: fit-content!important;
  }
  .expiration-date-input {
    /deep/ input {
      color: $pcg-gray;
    }
    /deep/ .bootstrap-datetimepicker-widget {
      width: 312px;
    }
  }
  .salary-container {
    .form-group {
      margin: 0 $pcg-m-sm;
      input {
        margin-bottom: 0;
      }
    }
  }
  .salary-container-header {
    margin-bottom: $pcg-m-lg;
  }
  .salary-container span, .salary-container-header {
      font-size: $font-size-s;
      font-weight: 500;
  }
  .go-to-next-step-btn {
    padding-left: 50px;
    padding-right: 50px;
  }
  .salary-indicator {
    margin-top: 11px;
    margin-bottom: auto;
  }
  .salary-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  @media (min-width: $screen-lg-min) {
    .expiration-date-input {
      /deep/ input {
        width: 100%;
      }
      /deep/ .pcg-datetimepicker-btn {
        position: absolute;
      }
    }
    .work-location-input {
      width: 100%;
    }
    .remove-location-btn {
      margin-top: .5em;
    }
  }

  @media (max-width: $screen-lg-min) {
    .work-location-input {
      margin: 0;
    }
  }

  .slide-enter-active, .slide-leave-active {
    transition: all .5s;
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>
<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .expiration-date-input {
      /deep/ input {
        color: $pcg-gray;
      }
    }
  }
</style>
