<template>
  <b-modal v-model="model"
           footer-class="d-flex justify-content-between abk-modal-footer" centered hide-header content-class="abk-modal-content">
    <h2>{{ $t('components.offer_filters.abk_remove_object_filter_confirmation_modal.confirm_delete') }}</h2>
    <abk-observed-search-result :filter="filter" :dictionaries="dictionaries"/>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn @click="cancel" variant="additional" class="mr-3">{{ $t('general.cancel') }}</pcg-btn>
      <pcg-btn @click="remove" class="mr-3">{{ $t('general.delete') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>
import AbkObservedSearchResult from './AbkObservedSearchResult'

export default {
  name: 'AbkRemoveObjectFilterConfirmationModal',
  components: { AbkObservedSearchResult },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: () => {}
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {

    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('input', false)
    },
    remove () {
      this.$emit('remove', false)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  h2 {
    margin-bottom: 2em;
    color: $pcg-gray;
  }

  /deep/ .modal-footer {
    padding-top: 3em;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    h2 {
      color: $pcg-gray;
    }
    /deep/ .modal-content {
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    }
  }
</style>
