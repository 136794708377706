<template>
  <div class="position-relative">
    <abk-attendance-list status="main"/>
  </div>
</template>

<script>
import AbkAttendanceList from '../../components/attendances/AbkAttendanceList'

export default {
  name: 'AttendanceIndex',
  components: { AbkAttendanceList },
  data () {
    return {
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.attendances.students_index.title')
      })
    }
  },
  mounted () {
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-message-btn-container {
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-sm-min) {
    .new-message-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>
