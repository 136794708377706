<template>
  <abk-surveys-list surveys-type="completed"/>
</template>

<script>
import AbkSurveysList from '../../../components/surveys/AbkSurveysList'
export default {
  name: 'CompletedSurveysTab',
  components: { AbkSurveysList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.surveys.list_tabs.completed_surveys_tab.title'),
        subtitle: this.$t('views.surveys.list_tabs.completed_surveys_tab.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped>

</style>
