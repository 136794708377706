<template>
  <div>
    <div class="title-container d-flex align-items-center">
      <h1 class="pcg-form-title d-inline-block h-auto mb-0 mr-3">
        {{ $t('components.curriculum_vitaes.abk_cv_competences_form.title') }}
      </h1>
    </div>
    <b-row>
      <abk-tags-box v-model="model"
                    :options="competencesOptions"
                    :placeholder-text="$t('components.curriculum_vitaes.abk_cv_competences_form.competence_placeholder')"
                    :empty-text="$t('components.curriculum_vitaes.abk_cv_competences_form.no_competences')"
                    :show-error="showErrors"
                    :error-text="filteredErrors"/>
    </b-row>
  </div>
</template>

<script>
import ApiCompetences from '../../api/competences'
import AbkTagsBox from '../AbkTagsBox'

export default {
  name: 'AbkCvCompetencesForm',
  components: { AbkTagsBox },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    errors: Object
  },
  data () {
    return {
      competencesOptions: []
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    filteredErrors () {
      const errors = []
      for (const key in this.errors) {
        if (Object.prototype.hasOwnProperty.call(this.errors, key) && /^cvCompetences/.test(key)) {
          errors.push(...this.errors[key])
        }
      }

      return errors.join(', ')
    },
    showErrors () {
      return this.filteredErrors.length > 0
    }
  },
  mounted () {
    this.getCompetencesOptions()
  },
  methods: {
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    getCompetencesOptions () {
      ApiCompetences.getAllCompetences()
        .then(response => {
          this.competencesOptions = response.data.data.map((competence) => {
            return {
              value: competence.id,
              text: competence.attributes.name
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .title-container {
    margin-bottom: $pcg-m-lg;
  }
</style>
