<template>
  <pcg-datetime-picker v-bind="$props"
                       ref="datePicker"
                       @input="$emit('input', $event)"
                       :locale="$i18n.locale"
                       :tooltips="$t('components.abk_datetime_picker.tooltips')"/>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'AbkDatetimePicker',
  props: {
    value: {
      type: null
    },
    variant: {
      type: String,
      default: 'datetime',
      validator (variant) {
        return ['datetime', 'date', 'time'].includes(variant)
      }
    },
    errorText: String,
    showError: Boolean,
    showLabel: {
      type: Boolean,
      default: true
    },
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    outputType: {
      type: String,
      default: 'date'
    },
    disabled: Boolean
  },
  created () {
    if (Vue.$cookies.get('locale')) {
      this.$i18n.locale = Vue.$cookies.get('locale')
    }
  }
}
</script>
