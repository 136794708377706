import api from './index'

const resource = 'event_registrations'

export default {
  createEventRegistration (payload) {
    return api.post(`${resource}`, payload)
  },
  removeEventRegistration (eventRegistrationId) {
    return api.delete(`${resource}/${eventRegistrationId}`)
  },
  signOutEventRegistration (eventRegistrationId) {
    return api.patch(`${resource}/${eventRegistrationId}/sign_out`)
  }
}
