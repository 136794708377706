<template>
<div class="other_events">
    <span class="title mb-3 d-block text-uppercase">{{ $t('components.events.abk_event_quick_info.other_dates') }}</span>
    <template v-for="date in otherDates">
        <div v-if="date.data.id !== $route.params.id" :key="date.data.id" class="other_event-box">
            <b-container>
                <b-row class="align-items-center justify-content-between">
                    <b-col lg="8" cols="12" class="p-0">
                        <b-container class="dates_and_address p-0">
                            <b-row class="justify-content-start align-items-center mb-2">
                                <b-col cols="1" class="pl-0">
                                    <i style="font-weight: 500;" class="fa fa-clock pcg-lg icon"/>
                                </b-col>
                                <b-col cols="6" class="content p-0">
                                    <span class="range pcg-txt-small property">{{ $t('general.from') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'HH:mm')[0] }}</span><span v-if="showOnlyStartDate(date.data.attributes.startDate, date.data.attributes.endDate)" style="font-size: 0.97em;" class="range pcg-txt-small property"> - {{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'HH:mm')[1] }}</span></span></span>
                                    <p class="mb-0">{{ getFormattedDatesArray(date.data.attributes.endDate, date.data.attributes.startDate, 'dd', true)[0] }}, {{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'DD MMMM YYYY', true)[0] }}</p>
                                </b-col>
                                <b-col v-if="!showOnlyStartDate(date.data.attributes.startDate, date.data.attributes.endDate)" cols="5" class="content p-0">
                                    <span class="range pcg-txt-small property">{{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'HH:mm')[1] }}</span></span>
                                    <p class="mb-0">{{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'dd', true)[1] }}, {{ getFormattedDatesArray(date.data.attributes.startDate, date.data.attributes.endDate, 'DD MMMM YYYY', true)[1] }}</p>
                                </b-col>
                            </b-row>
                            <b-row class="justify-content-start align-items-center mb-2">
                              <b-col cols="1" class="pl-0">
                                <i style="font-weight: 500;" class="fa fa-clock pcg-lg icon"/>
                              </b-col>
                              <b-col cols="6" class="content p-0">
                                <span class="range pcg-txt-small property">{{ $t('components.events.abk_event_quick_info.registration_start') }} <span class="pcg-txt-small-bold">{{ getFormattedDate(date.data.attributes.registrationStart, 'HH:mm') }}</span></span>
                                <p class="mb-0">{{ getFormattedDate(date.data.attributes.registrationStart, 'dd') }}, {{ getFormattedDate(date.data.attributes.registrationStart, 'DD MMMM YYYY') }}</p>
                              </b-col>
                              <b-col cols="5" class="content p-0">
                                <span class="range pcg-txt-small property">{{ $t('components.events.abk_event_quick_info.registration_end') }} <span class="pcg-txt-small-bold">{{ getFormattedDate(date.data.attributes.registrationEnd, 'HH:mm') }}</span></span>
                                <p class="mb-0">{{ getFormattedDate(date.data.attributes.registrationEnd, 'dd') }}, {{ getFormattedDate(date.data.attributes.registrationEnd, 'DD MMMM YYYY') }}</p>
                              </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                    <b-col lg="4" cols="12" class="align-items-center">
                        <b-row>
                            <b-col cols="12" class="d-flex justify-content-lg-start justify-content-center align-items-center">
                              <div v-if="date.data.attributes.status === 'canceled' && !showCareerOfficeActions && !showEmployerActions" class="date-canceled">
                                {{ $t('components.events.abk_event_quick_info.date_canceled') }}
                              </div>
                              <pcg-btn v-else
                                       size="small"
                                       variant="additional"
                                       @click="redirectToDate(date)">
                                {{ $t('components.events.abk_event_quick_info.choose_date') }}
                              </pcg-btn>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </template>
</div>
</template>

<script>
import { decamelize } from '@ridi/object-case-converter'

export default {
  name: 'AbkAdditionalDates',
  props: {
    otherDates: {
      type: Array,
      default: () => []
    },
    showEmployerActions: Boolean,
    showCareerOfficeActions: Boolean,
    isNotDraft: Boolean
  },
  computed: {
    daysNamesRange () {
      return this.getRangeString(...this.getFormattedDatesArray('dddd', true).map(day => _.capitalize(day)))
    },
    datesRange () {
      return this.getFormattedDatesRange('DD.MM.YYYY', true)
    },
    timeRange () {
      return this.getFormattedDatesRange('HH.mm')
    }
  },
  methods: {
    redirectToDate (date) {
      this.$router.push(`/events/${date.data.id}/sub_event`)
    },
    removeDate (date) {
      this.$emit('removeSubevent', date.data.id)
    },
    showOnlyStartDate (startDate, endDate) {
      return this.getFormattedDate(startDate, 'DD.MM.YYYY') === this.getFormattedDate(endDate, 'DD.MM.YYYY')
    },
    decamelizeString (value) {
      return Object.keys(decamelize({ [value]: null }))[0]
    },
    getFormattedDatesRange (format, checkDate = false) {
      return this.getRangeString(...this.getFormattedDatesArray(format, checkDate))
    },
    getRangeString (startValue, endValue) {
      return _.compact([startValue, endValue]).join(' - ')
    },
    getFormattedDatesArray (startDate, endDate, format, checkDate = false) {
      const finalEndDate = checkDate && this.showOnlyStartDate(startDate, endDate) ? null : endDate
      return [startDate, finalEndDate].map(date => this.getFormattedDate(date, format))
    },
    getFormattedDate (date, format) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.format(format) : null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .other_events {
    margin: 0 -30px;
    padding: 25px 30px 0 30px;
    border-top: 1px solid $pcg-light-gray;
    background-color: $pcg-the-lightest-gray;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .other_event-box {
    border-bottom: 1px solid $pcg-light-gray;
    margin: 0 -30px;
    padding: 15px 30px;

    &:last-of-type {
        border-bottom: 0;
    }
  }

  .vr {
    width: 2px;
    height: 72px;
    margin: 0 auto;
    background-color: $pcg-light-gray;
  }

  .title {
    font-weight: 900;
    text-transform: uppercase;
    color: $main-color;

    /deep/ i {
      font-size: $font-size-l;
      margin-right: .5rem;
    }
  }

  .property {
    color: $pcg-light-gray;
  }

  .places p, .places .fa.icon {
    color: $pcg-green-color;
  }

  .places .fa.icon {
    margin-right: 0.7rem;
  }

  .margin-bottom {
    margin-bottom: $pcg-m-lg;
  }
  .icon {
    color: $pcg-light-gray;
    margin-right: $pcg-m-lg;
  }
  .label {
    color: $main-color;
    font-weight: bold;
  }
  .content {
    color: $pcg-gray;
    font-weight: 500;

    p {
      line-height: 1rem;
      font-size: .86em;
    }
  }
  .range {
    white-space: nowrap;
    margin-right: $pcg-m-sm;
  }
  .date {
    background-color: $pcg-lightest-gray;
    padding: $pcg-m-xs;
    border-radius: 4px;
  }
  .date-canceled {
    color: $alert-error
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .other_events {
      border-top: 1px solid $pcg-light-gray;
      background-color: $pcg-the-lightest-gray;
    }
    .other_event-box {
      border-bottom: 1px solid $pcg-light-gray;
    }
    .vr {
      background-color: $pcg-light-gray;
    }
    .title {
      color: $main-color;
    }
    .property {
      color: $pcg-light-gray;
    }
    .places p, .places .fa.icon {
      color: $pcg-green-color;
    }
    .icon {
      color: $pcg-light-gray;
    }
    .label {
      color: $main-color;
    }
    .content {
      color: $pcg-gray;
    }
    .date {
      background-color: $pcg-lightest-gray;
    }
    .date-canceled {
      color: $alert-error
    }
  }
</style>
