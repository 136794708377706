<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <pcg-btn size="small" @click="newNote">{{ $t('general.new_note') }}</pcg-btn>
    </div>
    <div v-for="note in notes" :key="note.id">
      <abk-student-notes :note="note" @deleted="getStudentNotes" :current-user="userId"></abk-student-notes>
    </div>
    <pcg-pagination v-model="pagination.currentPage"
                    @input="getStudentNotes"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center"/>
  </div>
</template>

<script>
import ApiStudents from '../../api/students'
import LoaderDelayMixin from '../../mixins/loader_delay'
import AbkStudentNotes from '../../components/students/AbkStudentNotes'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'StudentNotes',
  components: { AbkStudentNotes },
  mixins: [LoaderDelayMixin, PaginableResourceMixin],
  data () {
    return {
      notes: [],
      studentName: null
    }
  },
  mounted () {
    this.getStudentNotes()
  },
  computed: {
    ...mapGetters('auth', ['userId']),
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.student_notes.title')
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY HH:mm') : null
    },
    getStudentNotes () {
      ApiStudents.getStudentNotes(this.$route.params.id, { page: this.pagination.currentPage })
          .then(response => {
            this.setPagination(response)
            if (response.data.meta && response.data.meta.user) {
              this.studentName = response.data.meta.user.name
              this.$store.dispatch('header/setHeader', {
                title: this.$t('views.student_notes.title'),
                subtitle: this.studentName
              })
            }
            this.notes = response.data.data.map(note => {
              return {
                id: note.id,
                date: this.formattedDate(note.attributes.createdAt),
                edited: note.attributes.createdAt !== note.attributes.updatedAt,
                editedDate: this.formattedDate(note.attributes.updatedAt),
                creatorId: note.attributes.creatorId,
                creatorName: note.attributes.creatorName,
                content: note.attributes.content
              }
            })
          })
          .catch(() => {
            this.$toastr.e(this.$t('components.abk_students.abk_student_list.error_download_student_notes'))
          })
          .finally(() => {
            this.loadingDone = true
          })
    },
    newNote () {
      this.$router.push({ name: 'student_notes_new_path', params: { id: this.$route.params.id } })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
<style scoped lang="scss">

</style>
