<template>
  <abk-file-input ref="fileInput"
                  show-remove-button
                  :read-only="fromUrl"
                  :file-url="value.imageUrl"
                  @remove="$emit('close')"
                  accept="image/*"
                  :status="fileUploadStatus"
                  :error-text="getError('image')"
                  v-b-tooltip.hover
                  :title="getError('image')"
                  @input="handleFileInput"/>
</template>

<script>
import AbkFileInput from './AbkFileInput'
export default {
  name: 'abk-offer-image-section',
  components: { AbkFileInput },
  props: {
    value: {
      type: Object
    },
    errors: Object
  },
  data () {
    return {
      image: null,
      localErrors: this.errors
    }
  },
  watch: {
    errors (newValue) {
      this.localErrors = newValue
    }
  },
  methods: {
    getError (field) {
      const error = this.localErrors[field]
      return error ? error.join(', ') : null
    },
    updateObject (object) {
      this.$emit('input', { ...this.value, ...object })
    },
    handleFileInput (event) {
      this.localErrors.image = null
      this.image = event
      this.updateObject({ image: this.image })
    }
  },
  computed: {
    fileUploadStatus () {
      if (this.localErrors.image) {
        return 'fail'
      } else if (this.image || this.fromUrl) {
        return 'success'
      }
      return 'upload'
    },
    fromUrl () {
      return !!this.value.imageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .preview-container {
    display: inline-block;
  }

  .controls-container {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }

  .img-preview {
    max-height: 155px;
  }

  .close {
    float: none;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

  .filename {
    color: $main-color;
  }

  .pcg-link {
    display: inline-block;
    margin-left: 15px;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    .cross {
      padding-right: 2px;
      font-size: $font-size-xs;
    }
  }
  .remove {

  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .filename {
      color: $main-color;
    }
  }
</style>
