<template>
  <div>
    <b-col cols="12" class="box mt-3">
      <b-row class="box-header">
        <b-col cols="12" class="d-flex mb-4">
          <h2 class="title">{{ $t('components.abk_video_section_form.title') }}</h2>
          <button class="section-btn small" @click="removeVideo">✕ {{ $t('general.delete') }}</button>
        </b-col>
        <pcg-text-input class="col-12 col-xl-6 m-0"
                        v-model="value.name"
                        :show-error="errorExist('name')"
                        :error-text="getError('name')"
                        :label="$t('components.abk_video_section_form.title_placeholder')"/>
      </b-row>
      <transition-group name="slide" type="out-in" class="subsection row">
        <b-col cols="12" class="d-flex mb-4" key="subsection-header">
          <h2>{{ $t('components.abk_video_section_form.subtitle') }}</h2>
          <button class="section-btn small" @click="addVideo">+ {{ $t('components.abk_video_section_form.add_video') }}</button>
        </b-col>
        <template v-for="(video, index) in value.companySubsections">
          <div v-if="!video._destroy" :key="video.key" class="col-12">
            <div style="margin-bottom: 2rem">
              <b-col cols="12" class="d-flex">
                <h3>{{ $t('components.abk_video_section_form.subsection_name') }}</h3>
                <button class="section-btn remove-subsection-btn small" @click="removeSubsection(index)">
                  ✕ {{ $t('general.delete') }}
                </button>
              </b-col>
              <pcg-text-input v-model="video.content"
                              :show-error="subsectionErrorExist(index, 'name')"
                              :error-text="getSubsectionError(index, 'name')"
                              :showLabel="false"
                              class="d-inline-block w-100 m-0"/>
            </div>
          </div>
        </template>
      </transition-group>
    </b-col>
  </div>
</template>

<script>
export default {
  name: 'AbkVideoSectionForm',
  props: {
    value: Object,
    errors: Object,
    sectionIndex: [Number, String]
  },
  data () {
    return {
      maxVideos: 12
    }
  },
  methods: {
    addVideo () {
      if (this.value.companySubsections.length < this.maxVideos) {
        const videoKeys = this.value.companySubsections.map(video => video.key)
        const newKey = videoKeys.length > 0 ? Math.max(...videoKeys) + 1 : 0
        this.value.companySubsections.push({ key: newKey, content: null })
      }
    },
    removeVideo () {
      this.$emit('remove')
    },
    getError (field) {
      return this.errorExist(field) ? this.errors[`companySections.${this.sectionIndex}.${field}`].join(', ') : null
    },
    errorExist (field) {
      return !!this.errors[`companySections.${this.sectionIndex}.${field}`]
    },
    getSubsectionErrorKey (index, field) {
      return `companySections.${this.sectionIndex}.companySubsections.${index}.${field}`
    },
    getSubsectionError (index, field) {
      const error = this.errors[this.getSubsectionErrorKey(index, field)]
      return error ? error.join(', ') : null
    },
    subsectionErrorExist (index, field) {
      return !!this.errors[`companySections.${this.sectionIndex}.companySubsections.${index}.${field}`]
    },
    removeSubsection (index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          const subsection = this.value.companySubsections[index]
          if (subsection.id) {
            subsection._destroy = true
          } else {
            this.value.companySubsections.splice(index, 1)
          }
          this.$emit('input', { ...this.value })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/vars";
h2 {
  font-size: 1em;
  color: $pcg-gray;
  width: fit-content;
  margin: 0 1em 0 0;
}
h3 {
  font-size: 0.86em;
  color: $pcg-the-darkest-gray;
  margin-right: .6px;
  position: relative;
  right: 10px;
  margin-bottom: 0.3rem;
  font-weight: 500;
}
.title {
  text-transform: uppercase;
}
.box {
  border: 2px dashed $pcg-light-gray;
  border-radius: 15px;
}
.section-btn {
  background: none;
  border: none;
  color: $main-active-color;
  padding: 0;
  margin: 0;
  outline: none;
  &.remove-subsection-btn {
    position: relative;
    bottom: .2rem;
  }
  &.small {
    font-size: $font-size-s;
  }
}
.box-header {
  padding: 30px 25px;
}
.subsection {
  border-top: 2px solid $pcg-lightest-gray;
  padding: $pcg-m-xl 25px $pcg-m-sm;
}
.content-textarea {
  height: 100%;
  /deep/ textarea {
    height: 100%;
  }
}
@media (max-width: $screen-md-min) {
  .box-header, .subsection {
    padding: 20px 5px;
  }
}

@media (max-width: $screen-xlg-min) {
  .subsection {
    padding-top: 40px;
  }
}

.images {
  padding: 0 25px;
}
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast{
  h2 {
    color: $pcg-gray;
  }
  .box {
    border: 2px dashed $pcg-light-gray;
  }
  .section-btn {
    color: $main-active-color;
  }
  .subsection {
    border-top: 2px solid $pcg-lightest-gray;
  }
}
</style>
