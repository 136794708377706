<template>
  <div class="user-messages">
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'user-messages-view'
}
</script>

<style lang="scss" scoped>

</style>
