<template>
  <div>
    <span class="limit-info" :style="note.length > 4000 ? { 'color': '#feae00' } : { }">{{ lengthWithoutTags }} / 4000</span>
    <tinymce-editor class="mt-4"
                    :init="tinymceSettings"
                    name="description"
                    :value="note"
                    @input="update('note', $event)"/>
    <div class="d-flex justify-content-end mb-4 mt-4">
      <pcg-btn size="small" @click="saveNote">{{ $t('views.student_notes_new.save_note') }}</pcg-btn>
    </div>
  </div>
</template>

<script>
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/colorpicker'
import TinymceEditor from '@tinymce/tinymce-vue'
import ApiStudents from '../../api/students'

export default {
  name: 'StudentNotesNew',
  components: { TinymceEditor },
  data () {
    return {
      note: '',
      userId: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 450,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  computed: {
    lengthWithoutTags () {
      // Length without HTML tags and HTML sequences
      const textWithoutTags = this.note.replace(/<[^>]+>/g, '').replace(/&[^;]+;/g, ' ')
      return textWithoutTags.length
    }
  },
  mounted () {
    ApiStudents.getNote(this.$route.params.id)
        .then((result) => {
          this.note = result.data.data.attributes.content
          this.userId = result.data.data.attributes.userId
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.student_notes_edit.title')
      })
    },
    update (key, value) {
      this.note = value
    },
    saveNote () {
      ApiStudents.editNote(this.$route.params.id, this.note)
          .then(() => {
            this.$toastr.s(this.$t('views.student_notes_new.note_saved'))
            this.$router.push({ name: 'student_notes_path', params: { id: this.userId } })
          })
          .catch(error => {
            console.error(error)
            this.$toastr.e(this.$t('views.student_notes_new.note_error'))
          })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars";

.limit-info {
  color: $pcg-gray;
  font-weight: 400;
}
</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .limit-info {
    color: $pcg-gray;
  }
}
</style>
