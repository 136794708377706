<template>
  <b-row style="margin-top: 2rem">
    <abk-user-registration-form class="col-12 form" v-model="user" :errors="errors"/>
    <b-col cols="12" class="mt-3 d-flex justify-content-center">
      <div class="text-right">
        <pcg-btn class="save-button mr-0" size="small" @click="registerUser()">{{ $t('views.register_user.register') }}</pcg-btn>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import AbkUserRegistrationForm from '../components/AbkUserRegistrationForm'
import ApiUsers from '../api/users'
import { mapGetters } from 'vuex'

export default {
  name: 'register-user',
  components: { AbkUserRegistrationForm },
  data () {
    return {
      user: {
        firstName: null,
        lastName: null,
        email: null,
        albumNo: null,
        password: null,
        passwordConfirmation: null,
        companyName: null,
        nip: null,
        roles: null,
        acceptances: [],
        captchaSuccess: false
      },
      errors: {}
    }
  },
  created () {
    this.hideAppLoader()
  },
  watch: {
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapGetters('environment', ['env'])
  },
  methods: {
    registerUser () {
      if (this.user.captchaSuccess || this.env.showRecaptcha == 0) {
        return new Promise((resolve, reject) => {
          ApiUsers.registerUser({ user: this.user })
            .then((response) => {
              this.$toastr.s(this.$t('views.register_user.user_registered'))
              this.$router.go(-1)
              resolve(response)
            })
            .catch(error => {
              this.errors = error.response.data
              if (this.errors.mailer && this.errors.mailer.length > 0 && this.errors.mailer[0]) {
                this.$toastr.e(this.errors.mailer[0])
              } else {
                this.$toastr.e(this.$t('general.fields_not_filled'))
              }
              reject(error)
            })
        })
      } else {
        this.$toastr.e(this.$t('general.no_captcha'))
      }
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.register_user.title'),
        subtitle: this.$t('views.register_user.subtitle')
      })
    },
    hideAppLoader () {
      setTimeout(() => {
        document.getElementById('app-loader').style.display = 'none'
      }, 500)
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style scoped>

</style>
