<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <div class="pcg-title">{{ skillName }}</div>
      <b-row>
        <abk-matching-to-labor-market-box class="col-12"
                                          :skill-id="skillId"
                                          :skill-degree="skillDegree"
                                          :matching-offers-count="matchingOffersCount"
                                          :missing-competences="missingCompetences"/>
        <abk-competence-training-box class="col-12" :skill-id="skillId" :events="suggestedTrainings"/>
        <abk-graduates-competences-box class="col-12"
                                       :graduates-count="graduatesCount"
                                       :competences="graduatesCompetences"/>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbkMatchingToLaborMarketBox from '../../components/skills/AbkMatchingToLaborMarketBox'
import AbkGraduatesCompetencesBox from '../../components/skills/AbkGraduatesCompetencesBox'
import AbkCompetenceTrainingBox from '../../components/skills/AbkCompetenceTrainingBox'
import ApiSkills from '../../api/skills'
import AbkPageNotFound from '../../components/AbkPageNotFound'
export default {
  name: 'SkillsReport',
  components: { AbkPageNotFound, AbkCompetenceTrainingBox, AbkGraduatesCompetencesBox, AbkMatchingToLaborMarketBox },
  data () {
    return {
      skillId: null,
      skillName: null,
      acceptedOffersCount: 0,
      matchingOffersCount: 0,
      graduatesCount: 0,
      graduatesCompetences: [],
      missingCompetences: [],
      suggestedTrainings: [],
      degreeBarsCount: 5,
      notFound: false
    }
  },
  methods: {
    getSkillReport () {
      if (!this.skillId) { return }

      ApiSkills.getSkillReport(this.skillId)
        .then(response => {
          const attributes = response.data.data.attributes
          this.matchingOffersCount = attributes.matchingOffersCount
          this.skillName = attributes.name
          this.graduatesCount = attributes.graduatesCount
          this.graduatesCompetences = attributes.graduatesCompetences
          this.missingCompetences = attributes.missingCompetences
          this.suggestedTrainings = attributes.suggestedTrainings
          this.acceptedOffersCount = attributes.acceptedOffersCount
          this.$store.dispatch('header/setObjectName', this.skillName)
        })
        .catch(error => {
          this.notFound = true
          console.error(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.skills.skills_report.title'),
        subtitle: this.$t('views.skills.skills_report.subtitle')
      })
    }
  },
  computed: {
    skillDegree () {
      const degree = Math.ceil(2 * this.matchingOffersCount * this.degreeBarsCount / this.acceptedOffersCount)

      return [0, degree, this.degreeBarsCount].sort((a, b) => a - b)[1]
    }
  },
  mounted () {
    this.skillId = this.$route.params.id
    this.getSkillReport()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped>

</style>
