<template>
  <pcg-box class="info-bar col-12">
    <span class="info-text">
      <i class="pcg-icon-comment-info-alt"/>{{ infoText }}
    </span>
    <router-link v-if="path" :to="path" class="pcg-link">
      {{ pathText }}
    </router-link>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkInfoBar',
  props: {
    infoText: String,
    path: Object,
    pathText: String
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .info-bar {
    color: $pcg-dark-gray;
    font-weight: 500;
    i {
      display: inline-block;
      margin-right: $pcg-m-sm;
      transform: translateY(2px);
    }
    ::v-deep .box {
      padding: 15px 20px !important;
    }
  }
  .info-text {
    margin-right: $pcg-m-md;
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    .info-bar {
      color: $pcg-dark-gray !important;
    }
  }
</style>
