import api from './index'
import axios from 'axios'
import { store } from '../store/index'

const resource = 'curriculum_vitaes'

export default {
  getCurriculumVitaes (params = {}) {
    return api.get(`${resource}`, { params: params })
  },
  getMyCurriculumVitae () {
    return api.get(`${resource}/my_cv`)
  },
  getCurriculumVitaePdf (cvId) {
    return axios({
      url: `/api/v1/curriculum_vitaes/${cvId}/cv_pdf`,
      headers: { Authorization: store.getters['auth/token'] },
      params: { token: this.exportToken },
      method: 'GET',
      responseType: 'blob'
    })
  },
  getMyCurriculumVitaeEdit () {
    return api.get(`${resource}/my_cv_edit`)
  },
  getCurriculumVitaeEdit (cvId) {
    return api.get(`${resource}/${cvId}/edit`)
  },
  getCurriculumVitae (curriculumVitaeId) {
    return api.get(`${resource}/${curriculumVitaeId}`)
  },
  updateCurriculumVitae (cvId, payload) {
    return api.patch(`${resource}/${cvId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateMyCurriculumVitae (payload) {
    return api.patch(`${resource}/update_my_cv`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  destroyDraft () {
    return api.delete(`${resource}/destroy_draft`)
  },
  getSectionAttachment (curriculumVitaeId, sectionType, sectionId) {
    const query = `?section_type=${sectionType}&section_id=${sectionId}`
    const url = `${resource}/${curriculumVitaeId}/download_section_attachment${query}`
    return api.get(url, { responseType: 'arraybuffer' })
  },
  getMyCvStatus () {
    return api.get(`${resource}/my_cv_status`)
  }
}
