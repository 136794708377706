import api from './index'

const resource = 'webpages'

export default {
  getTree (orderBy, orderDirection, accessRoles, language) {
    return api.get(`${resource}/tree`, {
      params: {
        order_by: orderBy,
        order_direction: orderDirection,
        access_roles: accessRoles,
        language: language
      }
    })
  },
  getPublishedTree (role, language) {
    return api.get(`${resource}`, {
      params: {
        role: role,
        language: language
      }
    })
  },
  getWebpage (webpageId) {
    return api.get(`${resource}/${webpageId}`)
  },
  getWebpageByAddress (webpageAddress) {
    return api.get(`${resource}/address/${webpageAddress}`)
  },
  createWebpage (payload) {
    return api.post(`${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateWebpage (webpageId, payload) {
    return api.patch(`${resource}/${webpageId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  removeWebpage (webpageid) {
    return api.delete(`${resource}/${webpageid}`)
  },
  getNews (params= {}) {
    return api.get(`${resource}/get_news`, { params: params })
  }
}
