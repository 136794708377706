<template>
  <div>
    <h4 class="pcg-event-header-text">{{ $t('components.events.abk_event_step_2_form.title') }}</h4>
    <b-row>
      <b-col xl="8">
        <b-row>
          <abk-event-description-form v-model="value.sections"
                                      :errors="errors.eventSections"
                                      class="col-12  description-form"/>
        </b-row>
        <abk-attachments v-model="value.attachments" :errors="errors.eventAttachments"/>
      </b-col>
      <b-col xl="4" v-if="env && env.competenceFeature !== 'off'">
        <!-- TODO przywrócić pcg-tags-box jak rozwiążemy problemy w pcg-core-vue -->
        <abk-tags-box v-model="value.competences"
                      :options="competencesOptions"
                      :header="$t('components.events.abk_event_step_2_form.competence_header')"
                      :placeholder-text="$t('components.events.abk_event_step_2_form.competence_placeholder')"
                      :empty-text="$t('components.events.abk_event_step_2_form.no_competences')"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AbkEventDescriptionForm from './AbkEventDescriptionForm'
import AbkAttachments from '../AbkAttachments'
import ApiCompetences from '../../api/competences'
import { mapGetters } from 'vuex'
import AbkTagsBox from '../AbkTagsBox'

export default {
  name: 'AbkEventStep2Form',
  components: { AbkTagsBox, AbkAttachments, AbkEventDescriptionForm },
  props: {
    value: Object,
    errors: Object
  },
  data () {
    return {
      competencesOptions: []
    }
  },
  computed: {
    ...mapGetters('environment', ['env'])
  },
  mounted () {
    this.getCompetencesOptions()
  },
  methods: {
    getCompetencesOptions () {
      ApiCompetences.getAllCompetences()
        .then(response => {
          this.competencesOptions = response.data.data.map((competence) => {
            return {
              value: competence.id,
              text: competence.attributes.name
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .description-form {
    margin-bottom: $pcg-m-xxl;
  }
</style>
