<template>
  <b-collapse :id="componentId" :visible="isAnyFilter">
    <span v-for="(filterProperties, filterName) in search" :key="`filter-name-${filterName}`">
      <span v-for="filterProperty in filterProperties"
            :key="`filter-property-${filterProperty}`"
            @click="removeFilter(filterName, filterProperty)"
            class="active-filter">
        {{ dictionaryTranslatedKey(filterName, filterProperty.toString()) }}
        <span>✕</span>
      </span>
    </span>
  </b-collapse>
</template>

<script>

export default {
  name: 'abk-search-active-filters',
  props: {
    path: {
      type: String,
      required: true
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      componentId: this._uid.toString()
    }
  },
  computed: {
    search () {
      return this.$route.query && this.$route.query.search ? this.$route.query.search : {}
    },
    isAnyFilter () {
      return _.toPairs(this.search).some(([_, v]) => v && v.length > 0)
    }
  },
  methods: {
    dictionaryTranslatedKey (distionaryName, distionaryKey) {
      if (this.dictionaries && this.dictionaries[distionaryName]) {
        const found = this.dictionaries[distionaryName].find(dictionaryWord => dictionaryWord.value === distionaryKey)
        return found && found.text
      }
      return distionaryKey
    },
    // translatedProperty (filterName, filterProperty) {
    //   const path = `components.abk_search_filter.${filterName}.${filterProperty}`
    //   return this.$te(path) ? this.$t(path) : filterProperty
    // },
    removeFilter (filterName, filterProperty) {
      const search = (this.$route.query && this.$route.query.search) ? Object.assign({}, this.$route.query.search) : {}
      if (search[filterName]) {
        if (search[filterName].indexOf(filterProperty) === -1) {
          search[filterName] = search[filterName].concat([filterProperty])
        } else {
          search[filterName] = search[filterName].concat([filterProperty]).filter(el => el !== filterProperty)
        }
      } else {
        search[filterName] = [filterProperty]
      }
      this.$router.push({ name: this.path, query: { search } })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .active-filter {
    color: $main-color;
    background-color: $color-active-filter;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    white-space: nowrap;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1rem;
    padding: 8px 13px;
    border-radius: 12px;
    transition: background-color 0.3s;
    &:hover {
      cursor: pointer;
      background-color: darken($color-active-filter, 10%);
      span {
        color: darken($pcg-dark-gray, 10%);
      }
    }
    span {
      color: $pcg-dark-gray;
      padding-left: 10px;
      font-size: 0.9rem;
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{

    .active-filter {
      color: $main-color;
      background-color: $color-active-filter;
      &:hover {
        background-color: darken($color-active-filter, 10%);
        span {
          color: darken($pcg-dark-gray, 10%);
        }
      }
      span {
        color: $pcg-dark-gray;
      }
    }
  }
</style>
