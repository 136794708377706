<template>
  <div>
    <pcg-btn size="small" @click="createNewWebpage" class="action-btn mr-3">{{ $t('views.webpages.webpages_new.save') }}</pcg-btn>
    <pcg-btn size="small" @click="createNewWebpage(true)" variant="additional" class="action-btn">{{ $t('views.webpages.webpages_new.save_draft') }}</pcg-btn>
    <abk-new-page-form class="form" v-model="form" :loading="loading" :submitted="submitted" :errors="errors"/>
    <pcg-btn size="small" @click="createNewWebpage" class="action-btn mr-3">{{ $t('views.webpages.webpages_new.save') }}</pcg-btn>
    <pcg-btn size="small" @click="createNewWebpage(true)" variant="additional" class="action-btn">{{ $t('views.webpages.webpages_new.save_draft') }}</pcg-btn>
  </div>
</template>

<script>
import AbkNewPageForm from '../../components/AbkNewPageForm'
import ApiWebpages from '../../api/webpages'

export default {
  name: 'WebpagesNew',
  components: { AbkNewPageForm },
  data () {
    return {
      errors: {},
      loading: false,
      submitted: false,
      form: {
        roles: [],
        navigation: null,
        address: null,
        title: null,
        content: null,
        parentId: null,
        sort: null,
        metaDescription: null,
        keywords: null,
        forum: null,
        webpageAttachments: [],
        language: null
      }
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.webpages.webpages_new.title'),
        subtitle: this.$t('views.webpages.webpages_new.subtitle')
      })
    },
    createNewWebpage (draft = false) {
      this.loading = true
      this.submitted = false
      const webpage = draft ? { ...this.form, status: 'draft' } : { ...this.form, status: 'recent' }
      ApiWebpages.createWebpage({ webpage: webpage })
        .then(response => {
          this.errors = {}
          draft ? this.$router.push({ name: 'webpages_edit_path', params: { id: response.data.data.id } }) : this.$router.push({ name: 'webpages_path' })
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
          this.submitted = true
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .action-btn {
    padding-left: 0;
    padding-right: 0;
    width: 150px;
    margin: 0;
  }
  .form {
    margin-top: $pcg-m-xxl;
  }
</style>
