<template>
  <div class="curriculum-vitaes">
    <transition name="slide" mode="out-in">
      <router-view :key="$route.path"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'curriculum-vitaes-view'
}
</script>

<style lang="scss" scoped>

</style>
