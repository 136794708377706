<template>
  <div class="basic-info-container">
    <b-row>
      <b-col cols="12">
        <b-row class="d-flex justify-content-center">
          <h1 class="pcg-form-title col-6 d-flex justify-content-center">{{ $t('components.abk_user_registration_form.title') }}</h1>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="col-6 subtitle">
            <span style="margin-right: .17rem; white-space: pre-wrap;">{{ $t('components.abk_user_registration_form.subtitle') }}</span>
            <a href="javascript:;" :aria-label="$t('components.abk_user_registration_form.log_in_here')" @click="login">
              {{ $t('components.abk_user_registration_form.log_in_here') }}
            </a>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-col class="col-6">
            <pcg-select
                :options="rolesOptions"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="value.roles"
                name="roles"
                :label="$t('components.abk_user_registration_form.role')"
                :required="true"
                :error-text="getError('roles')"
                :show-error="errorExist('roles')"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="firstName"
                          :required="true"
                          :label="$t('components.abk_user_registration_form.first_name')"
                          v-model="value.firstName"
                          :error-text="getError('firstName')"
                          :show-error="errorExist('firstName')"/>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          name="lastName"
                          :required="true"
                          :label="$t('components.abk_user_registration_form.last_name')"
                          v-model="value.lastName"
                          :error-text="getError('lastName')"
                          :show-error="errorExist('lastName')"/>
        </b-row>
        <transition  name="album" mode="out-in">
          <b-row class="d-flex justify-content-center" key="album" v-if="value.roles === 'graduate' && (env && env.registrationInternal == 1 && env.symU10 == 0)">
            <pcg-text-input class="col-6"
                            autocomplete="on"
                            name="albumNo"
                            :required="true"
                            :label="$t('components.abk_user_registration_form.album_no')"
                            v-model="value.albumNo"
                            :error-text="getError('albumNo')"
                            :show-error="errorExist('albumNo')"/>
          </b-row>
        </transition>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-6"
                          autocomplete="on"
                          type="email"
                          name="email"
                          :required="true"
                          :label="$t('components.abk_user_registration_form.email')"
                          v-model="value.email"
                          :error-text="getError('email')"
                          :show-error="errorExist('email')"/>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-6"
                          autocomplete="off"
                          name="password"
                          type="password"
                          :required="true"
                          :label="$t('components.abk_user_registration_form.password')"
                          v-model="value.password"
                          :error-text="getError('password')"
                          :show-error="errorExist('password')"/>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-6"
                          autocomplete="off"
                          name="passwordConfirmation"
                          type="password"
                          :required="true"
                          :label="$t('components.abk_user_registration_form.password_confirmation')"
                          v-model="value.passwordConfirmation"
                          :error-text="getError('passwordConfirmation')"
                          :show-error="errorExist('passwordConfirmation')"/>
        </b-row>
        <transition-group name="fade" mode="out-in">
          <b-row v-if="value.roles === 'employer'" key="0" class="d-flex justify-content-center">
            <pcg-text-input class="col-6"
                            autocomplete="on"
                            name="companyName"
                            :label="$t('components.abk_user_registration_form.company_name')"
                            v-model="value.companyName"
                            :error-text="getError('companyName')"
                            :show-error="errorExist('companyName')"/>
          </b-row>
          <b-row v-if="value.roles === 'employer'" key="1" class="d-flex justify-content-center">
            <pcg-text-input class="col-6"
                            autocomplete="on"
                            name="nip"
                            :required="true"
                            placeholder="PL1464791822"
                            :label="$t('components.abk_user_registration_form.nip')"
                            v-model="value.nip"
                            :error-text="getError('nip')"
                            :show-error="errorExist('nip')"/>
          </b-row>
        </transition-group>
        <b-row class="d-flex justify-content-center">
          <vue-recaptcha
            v-if="env && env.showRecaptcha == 1"
            ref="recaptcha"
            :sitekey="env.recaptchaSitekey"
            :loadRecaptchaScript="true"
            @verify="verifyMethod"
            @expired="expiredMethod"
            @error="errorMethod"
          />
        </b-row>
        <b-row class="d-flex justify-content-center">
          <div class="col-6 password-complexity">{{ $t('components.abk_user_registration_form.password_complexity') }}</div>
        </b-row>
        <b-row class="d-flex justify-content-center"
               v-for="(acceptance, index) in value.acceptances"
               :key="`approval-${index}`">
          <div v-if="acceptance.wordType === 'clause'" class="col-6 clause-information">
            <span v-html="acceptance.text"></span>
          </div>
          <pcg-checkbox v-else
                        variant="normal"
                        class="col-6"
                        :required="acceptance.required"
                        :show-error="errorExist(`acceptances.${index}.value`)"
                        :error-text="getError(`acceptances.${index}.value`)"
                        v-model="acceptance.value">
            <span v-html="acceptance.text"></span>
          </pcg-checkbox>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiDictionaryAcceptances from '../api/dictionary_acceptances'
import { mapGetters } from 'vuex'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'abk-user-registration-form',
  components: {
    VueRecaptcha
  },
  props: {
    value: Object,
    errors: Object
  },
  data () {
    return {
      roles: ['graduate', 'employer'],
      rolesOptions: [],
      acceptances: []
    }
  },
  computed: {
    ...mapGetters('environment', ['env'])
  },
  watch: {
    'value.roles': function (newvalue) {
      let result = []
      if (newvalue) {
        if (newvalue === 'employer') {
          result = this.acceptances.filter((a) => {
            if (a.key === 'employer' || a.key === 'cooperation' || a.key === 'survey') {
              return true
            } else {
              return false
            }
          })
        } else {
          result = this.acceptances.filter((a) => {
            if (a.key === 'cv' || a.key === 'survey') {
              return true
            } else {
              return false
            }
          })
        }
      }
      this.$set(this.value, 'acceptances', result)
    }
  },
  mounted () {
    this.initializeOptions()
  },
  methods: {
    expiredMethod () {
      this.value.captchaSuccess = false
      this.$refs.recaptcha.reset()
    },
    async verifyMethod (response) {
      try {
        const res = await this.$store.dispatch('auth/verifyRecaptcha', {
          response
        })
        if (res) {
          this.value.captchaSuccess = true
        }
      } catch (err) {
        console.log(err)
      }
    },
    errorMethod () {
      this.value.captchaSuccess = false
      this.$refs.recaptcha.reset()
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeRolesOptions()
        this.initializeAcceptances()
      }, 0)
    },
    initializeRolesOptions () {
      this.rolesOptions = this.roles.map(role => {
        return { text: this.$t(`components.abk_user_registration_form.roles.${role}`), value: role }
      })
    },
    initializeAcceptances () {
      ApiDictionaryAcceptances.getDictionaryAcceptances({ })
        .then(response => {
          response.data.data.forEach((d) => {
            ApiDictionaryAcceptances.getDictionaryAcceptanceWords(d.attributes.key)
              .then(res => {
                res.data.data.forEach((dw) => {
                  const a = {
                    key: d.attributes.key,
                    dictionaryAcceptanceWordId: dw.id,
                    required: dw.attributes.registrationRequired,
                    text: dw.attributes.value,
                    value: false,
                    name: dw.attributes.name,
                    wordType: dw.attributes.wordType
                  }
                  if (dw.attributes.registration) {
                    this.acceptances.push(a)
                  }
                })
                const sortByName = []
                sortByName.push(...this.acceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
                this.acceptances = sortByName
              })
              .catch(err => {
                this.$toastr.e(err)
                console.log(err)
              })
          })
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    login () {
      window.location = '/users/auth/openid_connect'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .form-group {
    margin-bottom: $pcg-m-lg;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .password-complexity {
    white-space: pre-wrap;
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .subtitle {
    color: $pcg-dark-gray;
    font-weight: 400;
    margin-bottom: 2rem;
    a {
      color: unset;
      text-decoration: underline;
    }
  }

  .clause-information {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-bottom: 1rem;
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style scoped lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .clause-information {
    color: $pcg-gray;
  }
}
</style>
