<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <div class="d-flex">
        <span class="completion-progress">{{ `${completionProgress.toFixed(0)}%` }}</span>
        <abk-form-progress-bar :progress-percent="completionProgress" class="progress-bar"/>
      </div>
      <div class="survey-container">
        <abk-question v-for="(question, index) in sortedQuestions"
                      :visible="isQuestionVisible(question.id)"
                      :question="question"
                      :key="`question-${index}`"
                      class="question"
                      :value="answers[question.id]"
                      @input="handleQuestionInput($event, question.id)"
                      :errors="errors[`answer.${question.id}`]"/>
      </div>
      <pcg-btn v-if="!getSurveyToPreview" size="small" class="ml-0" @click="submitAction">
        {{ $t('general.steps.next') }}
      </pcg-btn>
    </div>
  </div>
</template>

<script>
import ApiSurveyUsers from '../../api/survey_users'
import ApiSurveys from '../../api/surveys'
import AbkQuestion from '../../components/surveys/AbkQuestion'
import AbkFormProgressBar from '../../components/AbkFormProgressBar'
import ApiAnswers from '../../api/answers'
import { mapGetters } from 'vuex'
import AbkPageNotFound from '../../components/AbkPageNotFound'

export default {
  name: 'SurveysShow',
  components: { AbkPageNotFound, AbkFormProgressBar, AbkQuestion },
  props: {
    validate: {
      type: Boolean,
      default: false
    },
    getSurveyToPreview: Function
  },
  data () {
    return {
      id: null,
      survey: {
        name: null,
        questions: []
      },
      requirements: [],
      questionFields: [],
      answers: {},
      completedQuestionFieldsIds: [],
      errors: {},
      completionProgress: 0,
      notFound: false
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn']),
    sortedQuestions () {
      return _.orderBy(this.survey.questions, 'sort', 'asc')
    },
    surveysApi () {
      return this.validate ? ApiSurveys.getSurveyPreview : ApiSurveyUsers.getSurveyUser
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  created () {
    this.id = this.$route.params.id
    if (this.getSurveyToPreview instanceof Function) {
      const preview = this.getSurveyToPreview()
      this.survey = preview.survey
      this.requirements = preview.requirements
      this.questionFields = preview.questionFields
    } else {
      this.getSurvey()
    }
  },
  methods: {
    submitAction () {
      this.validate ? this.validateAnswer() : this.createAnswer()
    },
    createAnswer () {
      this.errors = {}

      ApiAnswers.createAnswer(this.getFormattedAnswer())
        .then(() => {
          this.$toastr.s(this.$t('views.surveys.surveys_show.creating_answer_success'))
          this.$router.push({ name: this.signedIn ? 'surveys_list_completed_tab_path' : 'root_path' })
          this.showMessageAfterCompleting()
        })
        .catch(error => {
          this.errors = error.response.data
        })
    },
    validateAnswer () {
      this.errors = {}

      ApiAnswers.validateAnswer(this.getFormattedAnswer())
        .then(() => {
          this.$toastr.s(this.$t('views.surveys.surveys_show.validates_success'))
          this.showMessageAfterCompleting()
        })
        .catch(error => {
          this.errors = error.response.data
        })
    },
    showMessageAfterCompleting () {
      const message = this.survey.messageAfterCompleting

      if (message) {
        this.$swal({ text: message })
      }
    },
    handleQuestionInput (value, questionId) {
      this.isAnswerCorrect(value) ? this.answers[questionId] = value : this.$delete(this.answers, questionId)
      this.updateCompletedQuestionFieldsIds()
      this.completionProgress = this.getCompletionProgress()
    },
    getSurvey () {
      if (!this.id) { return }

      this.surveysApi(this.id)
        .then(response => {
          this.requirements = this.getIncludedRecordsByType(response, 'requirement')
          this.questionFields = this.getIncludedRecordsByType(response, 'question_field')
          this.setSurveyFromResponse(response.data.included)
          this.setHeader()
          this.$store.dispatch('header/setObjectName', this.survey.name)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.notFound = true
          }
          console.error(error)
          if (error.response.status === 422 && error.response.data.errors && error.response.data.errors.surveyUser) {
            this.$toastr.e(error.response.data.errors.surveyUser.join('\n'))
            this.$router.push({ name: 'root_path' })
          }
        })
    },
    getIncludedRecordsByType ({ data: { included } }, type) {
      return included.filter(record => record.type === type)
        .map(record => { return { id: record.id, ...record.attributes } })
    },
    setSurveyFromResponse (included) {
      const includedSurvey = included.find(e => e.type === 'survey')
      this.survey = {
        ...this.survey,
        ...includedSurvey.attributes,
        questions: this.setQuestions(included)
      }
    },
    setQuestions (collection) {
      return collection
        .filter(inclusion => inclusion.type === 'question')
        .map(({ id, attributes, relationships: { questionFields, requirements } }) => {
          return {
            id: id,
            ...attributes,
            questionFields: this.mapRelationshipsToInclusions(questionFields, collection, 'question_field'),
            requirements: this.mapRelationshipsToInclusions(requirements, collection, 'requirement')
          }
        })
    },
    mapRelationshipsToInclusions (relationships, collection, type) {
      return relationships.data.map(({ id }) => {
        const element = collection.find(e => e.type === type && e.id === id)
        return { id: element.id, ...element.attributes }
      })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.surveys.surveys_show.title'),
        subtitle: this.survey.name
      })
    },
    isQuestionVisible (id) {
      const parentRequirements = this.requirements.filter(e => e.childQuestionId === id)
      return parentRequirements.length > 0 ? parentRequirements.some(e => this.isRequirementMet(e)) : true
    },
    isRequirementMet ({ questionFieldIds, requirementType }) {
      const requirementQuestionFieldIds = questionFieldIds.map(e => e.toString())

      return requirementType === 'all_selected'
        ? this.areAllFieldsCompleted(requirementType, requirementQuestionFieldIds)
        : this.isAnyFieldCompleted(requirementType, requirementQuestionFieldIds)
    },
    isAnyFieldCompleted (requirementType, requiredFieldsIds) {
      return requiredFieldsIds.some(e => this.completedQuestionFieldsIds.includes(e))
    },
    areAllFieldsCompleted (requirementType, requiredFieldsIds) {
      return requiredFieldsIds.every(e => this.completedQuestionFieldsIds.includes(e))
    },
    isQuestionAnswered (id) {
      return this.isAnswerCorrect(this.answers[id])
    },
    getCompletedQuestionsByRequirements (requirements, completedQuestionsArr) {
      return requirements.reduce((acc, requirement) => {
        const childQuestion = this.getQuestionById(requirement.childQuestionId)
        if (!this.isRequirementMet(requirement) && this.isQuestionToAnswer(childQuestion.questionType)) {
          return [...this.getCompletedQuestionsByRequirements(childQuestion.requirements, acc), childQuestion]
        }
        return acc
      }, completedQuestionsArr)
    },
    getQuestionById (id) {
      return this.survey.questions.find(e => e.id === id)
    },
    isAnswerCorrect (answer) {
      return (typeof answer === 'object' && answer && Object.keys(answer).length > 0) ||
        (typeof answer === 'string' && answer.length > 0)
    },
    getFormattedAnswer () {
      const result = {
        answer: {
          answers: Object.keys(this.answers).map(k => {
            const { questionType } = this.survey.questions.find(e => e.id.toString() === k.toString())
            let value

            if (questionType === 'checkbox') {
              value = Object.values(this.answers[k])
            } else if (questionType === 'radiobutton') {
              value = Object.values(this.answers[k])[0]
            } else {
              value = this.answers[k]
            }

            return { questionId: k, value: value }
          })
        }
      }
      this.validate ? result.surveyId = this.id : result.surveyUserId = this.id
      return result
    },
    getCompletionProgress () {
      const questionsToAnswer = this.getQuestionsToAnswer()
      const completedQuestions = this.getCompletedQuestions()

      return questionsToAnswer.length > 0 ? completedQuestions.length * 100 / questionsToAnswer.length : 0
    },
    getCompletedQuestions () {
      const questions = this.survey.questions.reduce((acc, question) => {
        if (this.isQuestionVisible(question.id) && this.isQuestionAnswered(question.id)) {
          return [...this.getCompletedQuestionsByRequirements(question.requirements, acc), question]
        }
        return acc
      }, [])

      return _.uniqBy(questions, e => e.id)
    },
    getQuestionsToAnswer () {
      return this.survey.questions.filter(e => this.isQuestionToAnswer(e.questionType))
    },
    isQuestionToAnswer (questionType) {
      return !['section', 'separator'].includes(questionType)
    },
    updateCompletedQuestionFieldsIds () {
      this.completedQuestionFieldsIds = _.uniq(_.flattenDeep(_.toPairs(this.answers).map(([_, answers]) => Object.keys(answers))))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "app/javascript/abk/assets/stylesheets/vars";

  .survey-container {
    max-width: 600px;
    margin-bottom: $pcg-m-xxl;
  }
  .question {
    margin-bottom: $pcg-m-lg;
  }
  .progress-bar {
    margin-bottom: $pcg-m-xxl;
  }
  .completion-progress {
    margin-right: $pcg-m-md;
    color: $pcg-green-color;
    font-weight: 500;
  }
</style>
