import api from './index'

const resource = 'skills'

export default {
  getSkills(params={}) {
    return api.get(`${resource}`, { params: params })
  },
  getConnectedWithCompetences(params={}) {
    return api.get(`${resource}/connected_with_competences`, { params: params })
  },
  getNotConnectedWithCompetences(params={}) {
    return api.get(`${resource}/not_connected_with_competences`, { params: params })
  },
  getAllSkills () {
    return api.get(`${resource}/all`)
  },
  getSkillReport (skillId) {
    return api.get(`${resource}/${skillId}/report`)
  }
}
