<template>
  <pcg-box class="pcg-txt-small-bold pcg-dark-gray-color">
    <div class="subsection">
      <div class="pcg-box-inner-header">
        <i class="pcg-icon-profile"></i>{{ $t('views.employer_cv.introduction') }}
      </div>
      <span v-html="content.intro"></span>
    </div>
    <div v-if="isSectionPresent('experience')" class="subsection">
      <abk-timeline :items="content.experience"
                    :cv-id="content.id"
                    date-format="MMMM YYYY"
                    type="experience">
        <template v-slot:header>
          <i class="pcg-icon-buildings"></i>{{ $t('views.employer_cv.experience') }}
        </template>
        <template v-slot="experience">
          <div v-if="experience.institution">{{ experience.institution }}</div>
          <span v-if="experience.position">{{ experience.position }}</span>
          <i v-if="experience.position && $te(`general.${experience.experienceType}`)"
             class="pcg-icon-bullet bullet-icon" />
          <span v-if="$te(`general.${experience.experienceType}`)" class="experience-type">
            {{ $t(`general.${experience.experienceType}`) }}
          </span>
          <div v-if="experience.description" class="additional-info">{{ experience.description }}</div>
        </template>
      </abk-timeline>
    </div>
    <div v-if="isSectionPresent('education')" class="subsection">
      <abk-timeline :items="content.education"
                    :cv-id="content.id"
                    date-format="YYYY"
                    type="education">
        <template v-slot:header>
          <i class="pcg-icon-graduation-cap-alt"></i>{{ $t('views.employer_cv.education') }}
        </template>
        <template v-slot="education">
          <div v-if="education.institution">{{ education.institution }}</div>
          <div v-if="education.discipline || education.degreeValue">
            {{ compact([education.discipline, education.degreeValue]).join(', ') }}
          </div>
          <div v-if="education.faculty || education.diplomaDate" class="additional-info">
            <div v-if="education.faculty">{{ education.faculty }}</div>
            <div v-if="education.diplomaDate">
              {{ $t('views.employer_cv.diploma_date') }}: {{ formatDate(education.diplomaDate, 'DD.MM.YYYY') }}
            </div>
          </div>
        </template>
      </abk-timeline>
    </div>
    <div v-if="isSectionPresent('certificates')" class="subsection">
      <abk-timeline :items="content.certificates"
                    :cv-id="content.id"
                    date-format="MMMM YYYY"
                    type="certificate">
        <template v-slot:header>
          <i class="pcg-icon-award"></i>{{ $t('views.employer_cv.certificates') }}
        </template>
        <template v-slot="certificate">
          <div v-if="certificate.name">{{ certificate.name }}</div>
          <div v-if="certificate.institution">{{ certificate.institution }}</div>
          <div v-if="certificate.exam || certificate.description" class="additional-info">
            <div v-if="certificate.exam">{{ $t('views.employer_cv.with_exam') }}</div>
            <div v-if="certificate.description">{{ certificate.description }}</div>
          </div>
        </template>
      </abk-timeline>
    </div>
  </pcg-box>
</template>

<script>
import AbkTimeline from './AbkTimeline'
export default {
  name: 'AbkCvMainSection',
  components: { AbkTimeline },
  props: {
    content: Object
  },
  methods: {
    isSectionPresent (section) {
      return this.content[section] && this.content[section].length
    },
    compact (array) {
      return _.compact(array)
    },
    formatDate (date, format) {
      return moment(date).format(format)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .subsection:not(:last-child) {
    margin-bottom: 3.2em;
  }
  .additional-info {
    margin-top: $pcg-m-sm;
    font-size: $font-size-s;
  }
  .experience-type {
    color: $pcg-gray;
    text-transform: lowercase;
  }
  .bullet-icon {
    margin: 0 $pcg-m-sm;
    &:before {
      opacity: .5;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .experience-type {
      color: $pcg-gray;
    }
  }
</style>
