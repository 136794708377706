<template>
  <div>
    <b-row style="margin-bottom: 4rem">
      <b-col cols="12">
        <pcg-btn size="small" class="new-page-btn mt-0 mx-0" @click="$router.push({name: 'news_new_path'})">
          {{ $t('views.news.news_index.add_news') }}
        </pcg-btn>

        <abk-loader v-if="showLoader" class="with-margins"/>
        <template v-else-if="news.length > 0">
          <abk-news-tree :table-key="`webpages-tree-${tableKey}`"
                         :news="news"
                         @publishPage="publishNews"
                         @deletePage="deletePage"/>
        </template>
      </b-col>
    </b-row>
    <pcg-pagination v-model="pagination.currentPage"
                    @input="getNews"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiWebpages from '../../api/webpages'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import AbkNewsTree from '../../components/AbkNewsTree'

export default {
  name: 'NewsIndex',
  components: { AbkLoader, AbkNewsTree },
  mixins: [LoaderDelayMixin, PaginableResourceMixin],
  data () {
    return {
      accessRoles: {
        student: false,
        graduate: false,
        career_office: false,
        employer: false,
        recruiter: false,
        public: false
      },
      news: [],
      tableKey: 0
    }
  },
  mounted () {
    this.getNews()
  },
  methods: {
    getNews () {
      ApiWebpages.getNews({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.news = response.data.data.map(n => { return { id: n.id, ...n.attributes } })
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.news.news_index.title'),
        subtitle: this.$t('views.news.news_index.subtitle')
      })
    },
    deletePage (id) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiWebpages.removeWebpage(id)
            .then(response => {
              this.getNews()
            }).catch(error => {
              console.log(error)
            })
        }
      })
    },
    publishNews ({ newsId, publish }) {
      const newsToUpdate = this.news.find((n) => n.id === newsId)
      const currentStatus = newsToUpdate.status

      ApiWebpages.updateWebpage(newsId, { webpage: { status: this.booleanToStatus(publish) } })
        .then(() => {
          newsToUpdate.status = this.booleanToStatus(publish)
        }).catch(error => {
          const news = this.news.find((n) => n.id === newsId)
          if (news) {
            setTimeout(() => { news.published = this.statusToBoolean(currentStatus) }, 300)
          }
          console.log(error.response.data)
        })
    },
    statusToBoolean (status) {
      if (status === 'published') {
        return true
      } else {
        return false
      }
    },
    booleanToStatus (status) {
      if (status) {
        return 'published'
      } else {
        return 'recent'
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars";

.new-page-btn {
  margin-bottom: $pcg-m-lg;
}

.abk-inline-block{
  display: inline-block;
}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
