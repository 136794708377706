<template>
  <pcg-box :header="header">
    <div v-if="innerHeader" class="pcg-box-inner-header">
      <i class="pcg-icon-comment-alt"></i>{{ $t('components.abk_contact.header') }}
    </div>
    <div v-if="firmInfo.name" class="name ">
      {{ firmInfo.name }}
    </div>
    <div class="content d-flex" v-if="firmInfo.address">
      <i class="pcg-icon-location-alt pcg-lg icon"></i>
      <div class="location-content">
        {{ firmInfo.address }}
      </div>
    </div>
    <div class="content" v-if="firmInfo.phone">
      <i class="pcg-icon-phone pcg-lg icon"></i>
      <a class="pcg-link" :href="`tel:${firmInfo.phone}`">{{ firmInfo.phone }}</a>
    </div>
    <div class="content" v-if="firmInfo.email">
      <i class="pcg-icon-envelope-alt pcg-lg icon"></i>
      <a class="pcg-link" :href="`mailto:${firmInfo.email}`">{{ firmInfo.email }}</a>
    </div>
    <div class="content" v-if="currentRole === 'career_office' && firmInfo.privateEmail">
      <i class="pcg-icon-envelope-alt pcg-lg icon"></i>
      <i class="pcg-icon-graduation-cap graduation-icon"></i>
      <a class="pcg-link" :href="`mailto:${firmInfo.privateEmail}`">{{ firmInfo.privateEmail }}</a>
    </div>
    <div v-if="firmInfo.websites">
      <div class="content" v-for="website in firmInfo.websites" v-show="website">
        <i class="pcg-icon-globe pcg-lg icon"></i>
        <a class="pcg-link" :href="getValidLink(website)" target="_blank">{{ website }}</a>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <a v-if="firmInfo.facebook" :href="getValidLink(firmInfo.facebook)" target="_blank">
        <i class="fab fa-facebook-square fa-2x socials"></i>
      </a>
      <a v-if="firmInfo.twitter" :href="getValidLink(firmInfo.twitter)" target="_blank">
        <i class="fab fa-twitter-square fa-2x socials"></i>
      </a>
      <a v-if="firmInfo.linkedin" :href="getValidLink(firmInfo.linkedin)" target="_blank">
        <i class="fab fa-linkedin fa-2x socials"></i>
      </a>
    </div>
  </pcg-box>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'abk-contact',
  props: {
    firmInfo: {
      type: Object,
      default: () => {
      }
    },
    innerHeader: Boolean
  },
  methods: {
    getValidLink (link) {
      return !/^(http|https):\/\//.test(link) ? `http://${link}` : link
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    header () {
      return this.innerHeader ? null : this.$t('components.abk_contact.header')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .name {
    font-weight: bold;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: $font-size-l;

    /deep/ i {
      font-size: $font-size-l;
    }
  }

  .location-content {
    margin-left: 7px;
  }
  .content {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-gray;
    margin-bottom: $font-size-m;
    display: flex;
    align-items: center;
  }

  .pcg-icon-location-alt {
    font-size: 1.5rem;
  }
  .icon {
    margin-right: $pcg-m-lg;
    display: inline-block;
    min-width: 20px;
    text-align: center;
  }

  .graduation-icon {
    width: 0;
    height: 0;
    font-size: 1.2em;
    color: $main-color;
    font-weight: bold;
    position: relative;
    right: 2.2rem;
    top: .1rem;
  }

  .socials {
    margin-top: 1.6rem;
    margin-right: .8rem;
    color: $pcg-socials;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .name {
      color: $main-color;
    }
    .content {
      color: $pcg-gray;
    }
    .socials {
      color: $pcg-socials;
    }
    .graduation-icon {
      color: $main-color;
    }
  }
</style>
