import Webpages from '../api/webpages'
import NavigationMenuStaticLinksMixin from './navigation_menu_static_links'
import { mapGetters } from 'vuex'

export default {
  mixins: [NavigationMenuStaticLinksMixin],
  data () {
    return {
      webpagesLinks: [],
      applicationLanguage: null
    }
  },
  computed: {
    ...mapGetters('user', ['acceptedCompany']),
    allLinks () {
      return this.staticLinks.concat(this.webpagesLinks)
    },
    signedAndRoleCombined () {
      return [this.$store.getters['auth/currentRole'], this.$store.getters['auth/signedIn']]
    }
  },
  watch: {
    signedAndRoleCombined: {
      handler: function (newValue, oldValue) {
        if (newValue[0] && newValue[1] && !oldValue[1]) {
          this.getWebpagesLinks()
        }
      },
      deep: true
    }
  },
  created () {
    if (this.$cookies.get('locale')) {
      this.applicationLanguage = this.$cookies.get('locale')
    }
  },
  mounted () {
    this.$root.$on('toggleMobileMenu', () => {
      this.mobileMenuOpen = !this.mobileMenuOpen
    })
    this.getWebpagesLinks()
  },
  methods: {
    getWebpagesLinks () {
      const userRole = this.$store.getters['auth/signedIn'] ? this.$store.getters['auth/currentRole'] : null
      Webpages.getPublishedTree(userRole, this.applicationLanguage)
        .then(response => {
          this.webpagesLinks = this.prepareNavigation(response.data.data, userRole)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    prepareNavigation (navigation, role) {
      const result = []
      navigation.forEach((webpage, index) => {
        let web = {}
        web = {
          id: webpage.id,
          name: webpage.navigation,
          path: 'webpages_show_path',
          query: { id: webpage.address },
          iconClass: 'fas fa-file',
          roles: [role],
          sub: []
        }
        if (webpage.children.length > 0) {
          webpage.children.forEach((subwebpage, index) => {
            let subweb = {}
            subweb = {
              id: subwebpage.id,
              name: subwebpage.navigation,
              path: 'webpages_show_path',
              query: { id: subwebpage.address },
              iconClass: 'fas fa-file',
              roles: [role]
            }
            web.sub.push(subweb)
          })
        }
        result.push(web)
      })
      return result
    }
  }
}
