<template>
  <div class="companies">
    <transition name="slide" mode="out-in">
      <router-view :key="$route.path"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Students'
}
</script>
