<template>
    <b-modal v-model="isOpen"
            ref="deleteAccount"
            :hide-header="true"
            :centered="true"
            v-bind:ok-title="$t('general.remove_account')"
             ok-variant="primary"
            :no-close-on-esc="true"
            :no-close-on-backdrop="true"
            @ok="removeAccount"
            @cancel="closeModal"
            v-bind:cancel-title="$t('general.cancel')"
            cancel-variant="default"
            footer-class="d-flex justify-content-between abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2>{{ $t('components.abk_remove_account_modal.make_sure_title') }}</h2>
      <i class="fa fa-exclamation-triangle modal-icon"></i>
      <h6>{{ $t('components.abk_remove_account_modal.warning_title') }}</h6>
      <h6>{{ $t('components.abk_remove_account_modal.warning_text') }}</h6>
      <p v-if="type === 'company'" class="text-danger text-center my-1 mx-0 font-weight-bold">
          {{ $t('components.abk_remove_account_modal.company_events_warning') }}
      </p>
      <div class="mt-5">
        <pcg-text-input class="rejection-textarea m-xl-0"
                        type="textarea"
                        required
                        :label="$t('components.abk_remove_account_modal.remove_reason')"
                        :error-text="getError('removeReason')"
                        :show-error="errorExist('removeReason')"
                        v-model="removeReason"/>
    </div>
    </b-modal>
</template>

<script>
import ApiUsers from '../api/users'

export default {
    name: 'AbkRemoveAccountModal',
    props: {
        show: {
            type: Boolean,
            default: false,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        deletingId: {
            type: String,
            required: false,
            default: ''
        }
    },
    data () {
        return {
            isOpen: this.show,
            removeReason: null,
            errors: {}
        }
    },
    watch: {
        show (newValue) {
            this.isOpen = newValue
        }
    },
    methods: {
        getError (field) {
            return this.errorExist(field) ? this.errors[field].join(', ') : ''
        },
        errorExist (field) {
            return !!(this.errors && this.errors[field])
        },
        removeAccount (e) {
            e.preventDefault()
            if (this.type === 'admin') {
                ApiUsers.adminRemoveAccount({ reason: this.removeReason, removedAccountId: this.deletingId })
                    .then((response) => {
                        this.isOpen = false
                        this.$emit('removed')
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors
                    })
            } else {
                ApiUsers.removeAccount({ reason: this.removeReason })
                    .then((response) => {
                        this.isOpen = false
                        this.$emit('removed')
                    })
                    .catch((error) => {
                        this.errors = error.response.data.errors
                    })
            }
        },
        closeModal () {
            this.isOpen = false
            this.$emit('discard')
        }
    }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .modal-icon {
    display: block;
    text-align: center;
    margin: 10px 0 30px;
  }

  .modal-icon::before {
    font-size: 90px;
    color: $pcg-red;
  }
</style>
