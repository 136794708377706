<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <div class="d-flex flex-wrap">
          <div class="filters-text d-flex align-items-center">{{ $t('components.abk_users_list.filter_users') }}</div>
          <pcg-search @searchEvent="getUsersAndResetPagination" @input="getUsersAndResetPagination" class="filter-select" v-model="filters.fullName"></pcg-search>
          <pcg-select :options="rolesSortOptions"
                      :default-option="{ text: this.$t('views.users_list.labels.roles'), value: null }"
                      class="filter-select"
                      v-model="filters.role"
                      @input="getUsersAndResetPagination"/>
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex flex-wrap align-items-center" style="position: relative; right: 10px;">
            <abk-datetime-picker class="col-10 col-xl-4"
                                :value="filters.deletedAtStart"
                                v-model="filters.deletedAtStart"
                                @input="getUsersAndResetPagination"
                                :show-label="true"
                                :label="$t('components.abk_users_list.deleted_at_start')"
                                :error-text="getError('dateStart')"
                                :show-error="errorExist('dateStart')"
                                variant="date" />
            <abk-datetime-picker class="col-10 col-xl-4"
                                :value="filters.deletedAtEnd"
                                v-model="filters.deletedAtEnd"
                                @input="getUsersAndResetPagination"
                                :show-label="true"
                                :label="$t('components.abk_users_list.deleted_at_end')"
                                :error-text="getError('dateEnd')"
                                :show-error="errorExist('dateEnd')"
                                variant="date" />
            <pcg-sort-btn class="sort-button align-items-center ml-3" :options="sortOptions" :value="filters" @input="changeSortDirection" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <abk-loader v-if="showLoader" class="with-margins"/>
        <pcg-table v-else-if="users && users.length" :items="users" :fields="fields">
          <template v-slot:header>{{ $t('components.abk_users_list.table_header') }}</template>
          <template v-slot:cell(roles)="data">
            <div v-if="data.value.length > 0" class="my-text">
              <div v-for="role in data.value" :key="role.id">
                <span>{{ roleName(role) }}</span>
              </div>
            </div>
            <div v-else class="my-text">
              <span class="no-role">{{ $t('views.users_list.no_role') }}</span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="data">{{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}</template>
          <template v-slot:cell(removedAt)="data">
            <span v-if="data.value">
              {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
            </span>
          </template>
          <template v-slot:cell(removedBy)="data">
            <span v-if="data.value">
              {{ data.value }}
            </span>
          </template>
          <template v-slot:cell(removeReason)="data">
            <span v-if="data.value">
              {{ data.value }}
            </span>
          </template>
        </pcg-table>
        <pcg-box v-else>
          <span class="pcg-no-records">{{ $t('components.abk_users_list.no_records') }}</span>
        </pcg-box>
      </b-col>
      <b-col class="col-12">
        <pcg-pagination v-model="pagination.currentPage"
                        @input="getUsers"
                        :page-count="pagination.pages"
                        :total-records="pagination.totalRecords"
                        class="col-12 justify-content-center my-pagination"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiUsers from '../api/users'
import LoaderDelayMixin from '../mixins/loader_delay'
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import AbkLoader from './AbkLoader'
import PaginableResourceMixin from '../mixins/paginable_resource_mixin'
import AbkDatetimePicker from './AbkDatetimePicker'

export default {
  name: 'abk-removed-users-list',
  components: { AbkLoader, AbkDatetimePicker },
  mixins: [LoaderDelayMixin, PaginableResourceMixin],
  data () {
    return {
      changingRoles: [],
      currentAcceptances: [],
      currentUser: {},
      fields: [],
      errors: {},
      filters: {
        full_name: null,
        orderDirection: 'desc',
        orderBy: 'deleted_at',
        page: 1,
        role: null,
        deletedAtStart: null,
        deletedAtEnd: null
      },
      roles: ['graduate', 'employer', 'recruiter', 'career_office', 'student', 'counselor', 'administrator'],
      rolesOptions: [],
      sortOptions: [
        { text: this.$t('general.removed_date'), value: 'deleted_at' },
        { text: this.$t('general.full_name'), value: 'full_name' }
      ],
      rolesSortOptions: [],
      users: []
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    isAnyUser () {
      return this.users && this.users.length
    },
    queryParams () {
      return {
        filters: this.filters
      }
    }
  },
  mounted () {
    this.getUsers()
    this.initializeOptions()
  },
  methods: {
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    changeSortDirection (result) {
      this.filters = result
      this.getUsersAndResetPagination()
    },
    getUsersAndResetPagination () {
      this.resetPaginationPage()
      this.getUsers()
    },
    getUsers () {
      this.filters.page = this.pagination.currentPage
      ApiUsers.getRemovedUsers(this.filters)
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.users = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    dataTable (data) {
      const result = []
      data.forEach(user => {
        result.push({
          ...user.attributes
        })
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key !== 'id') {
              fields.push({
                key: key,
                label: this.$t(`views.removed_users_list.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })

      return fields
    },
    roleName (role) {
      return this.$t(`views.users_list.roles.${role}`)
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeRolesOptions()
      }, 0)
    },
    initializeRolesOptions () {
      this.rolesSortOptions = this.roles.map(role => {
        return { text: this.$t(`views.users_list.roles.${role}`), value: role }
      })
      this.rolesSortOptions.unshift({ text: this.$t('components.abk_users_list.no_role'), value: 'no_role' })
      this.rolesSortOptions.unshift({ text: this.$t('components.abk_users_list.all'), value: '' })
      this.rolesOptions = this.roles.map(role => {
        return { text: this.$t(`views.users_list.roles.${role}`), value: role }
      })
    },
    escRoles () {
      this.$refs.roles.hide()
    },
    escAcceptances () {
      this.$refs.acceptances.hide()
    },
    addRole () {
      this.changingRoles.push({
        id: ''
      })
    },
    showAcceptances (id) {
      this.currentAcceptances = []
      ApiUsers.getAllAcceptances({ userId: id })
        .then(res => {
          this.currentAcceptances = res.data.acceptances
          this.$refs.acceptances.show()
        })
        .catch(err => {
          console.log(err)
        })
    },
    editRoles (id) {
      this.changingRoles = []
      this.$refs.roles.show()
      const idx = this.users.findIndex(u => {
        return u.id === id.toString()
      })
      this.currentUser = this.users[idx]
      this.currentUser.roles.forEach(role => {
        this.changingRoles.push({ id: role })
      })
    },
    removeRole (id) {
      this.changingRoles.splice(id, 1)
    },
    rolesSave () {
      const roles = []
      this.changingRoles.forEach(role => {
        roles.push(role.id)
      })
      ApiUsers.updateRoles(this.currentUser.id, { user: { roles: roles } })
        .then(result => {
          this.getUsers()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.roles)
          console.log(err.response.data)
        })
    },
    setTime (date) {
      if (date) {
        const momentDate = moment(date)
        return momentDate.format('LT')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .matching-competences-container {
    margin-left: auto;
    min-width: 130px;
    text-align: center;
  }
  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  /deep/ .pcg-table-container {
    tr {
      td:not(:first-child):not(:last-child) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    .matching-competences-container {
      min-width: 70px;
      width: 70px;
    }
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-roles {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .modal-select {
    position: relative;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
    /deep/ .ls-element .pcg-input{
      height: calc(1.5em + 0.75em + 2px);
    }
  }

  .my-cross {
    color: $pcg-gray;
    position: relative;
    top: .8rem;
    cursor: pointer;
  }

  .one-role {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }

  .sort-button {
    position: relative;
    top: .5rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .filters-text {
      color: $pcg-gray;
    }
    .edit-roles {
      color: $pcg-gray;
    }
    .modal-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .my-cross {
      color: $pcg-gray;
    }
    .one-role {
      color: $pcg-gray;
    }
    .sort-button {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
