<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <b-row>
        <b-col class="col-12 mb-5">
          <abk-simple-message-form v-model="message" :errors="errors" :key="simpleMessageFormKey"/>
          <pcg-btn size="small" @click="sendMessage">{{ $t('general.send') }}</pcg-btn>
        </b-col>
        <template v-if="messages.length">
          <abk-message v-for="message in messages"
                       @click.native="markAsRead(message.id)"
                       class="col-12 message"
                       :title="message.title"
                       :highlighted="message.unread"
                       :key="message.id"
                       :sender-name="message.senderName"
                       :sender-company="message.companyName"
                       :sender-avatar="message.senderAvatar"
                       :sender-company-img="message.companyLogo"
                       :date="message.date"
                       :message="message.content"
                       :user-profile-path="message.userProfilePath"
                       :user-profile-id="message.userProfileId"
                       :is-deleted="message.isDeleted"
                       :important="message.important">
            {{ message.content }}
          </abk-message>
        </template>
        <pcg-box v-else>
          <span class="pcg-no-records">{{ $t('views.simple_messages.simple_messages_index.no_records') }}</span>
        </pcg-box>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbkMessage from '../../components/AbkMessage'
import ApiSimpleMessages from '../../api/simple_messages'
import AbkSimpleMessageForm from '../../components/simple_messages/AbkSimpleMessageForm'
import { mapGetters } from 'vuex'
import AbkPageNotFound from '../../components/AbkPageNotFound'

export default {
  name: 'SimpleMessagesShow',
  components: { AbkPageNotFound, AbkSimpleMessageForm, AbkMessage },
  data () {
    return {
      message: {
        title: null,
        content: null
      },
      errors: {},
      messages: [],
      simpleMessageId: this.$route.params.id,
      notFound: false,
      simpleMessageFormKey: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['roles']),
    hasCareerOfficeRole () {
      return this.roles.includes('career_office')
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.simple_messages.simple_messages_show.title')
      })
    },
    sendMessage () {
      this.errors = {}
      ApiSimpleMessages.createSimpleMessage({
        simpleMessage: {
          ...this.message,
          parentId: this.simpleMessageId
        }
      })
        .then(() => {
          this.$toastr.s(this.$t('general.message_sent'))
          this.getSimpleMessage()
          this.clearMessage()
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    clearMessage () {
      this.message = {
        title: null,
        content: null
      }
      this.simpleMessageFormKey += 1
    },
    getSimpleMessage () {
      ApiSimpleMessages.getSimpleMessage(this.simpleMessageId)
        .then(response => {
          this.messages = []
          this.addMessage(response.data.data, response.data.included, response.data.data.relationships)
          if (response.data.data.relationships && response.data.data.relationships.simpleMessages) {
            const filteredSimpleMessages = response.data.included.filter(included => included.type === 'simple_messages')
            response.data.data.relationships.simpleMessages.data.forEach(simpleMessageId => {
              const simpleMessage = filteredSimpleMessages.find(filteredSimpleMessage => filteredSimpleMessage.id === simpleMessageId.id)
              if (simpleMessage) {
                this.addMessage(simpleMessage, response.data.included, simpleMessage.relationships)
              }
            })
          }
          this.$store.dispatch('header/setObjectName', this.messages[0].title)
        }).catch(error => {
          this.notFound = true
          console.error(error)
        })
    },
    addMessage (data, included, relationships) {
      const attributes = data.attributes
      const user = included.find(inclusion => inclusion.id === relationships.user.data.id)
      const userHasCareerOffice = user.attributes.roles.includes('career_office')
      const avatar = user.attributes.avatar
      const userProfilePath = user.attributes.roles.includes('career_office')
        ? null
        : user.attributes.companyId ? 'company_show_path' : 'curriculum_vitae_show_path'
      this.messages.push({
        id: data.id,
        title: attributes.title,
        content: attributes.content,
        date: attributes.createdAt,
        senderAvatar: avatar && avatar.thumb ? avatar.thumb.url : (avatar ? avatar.url : null),
        senderName: `${user.attributes.firstName} ${user.attributes.lastName}`,
        isDeleted: user.attributes.isDeleted,
        unread: this.hasCareerOfficeRole
          ? (userHasCareerOffice ? false : attributes.status === 'recent')
          : (userHasCareerOffice ? attributes.status === 'recent' : false),
        userProfilePath: userProfilePath,
        userProfileId: user.attributes.companyId || user.attributes.curriculumVitaeId
      })
    },
    markAsRead (messageId) {
      const message = this.messages.find(message => message.id === messageId)
      if (!message.unread) { return }

      ApiSimpleMessages.markAsRead(messageId)
        .then(() => {
          message.unread = false
          this.$store.dispatch('notifications/getMessages')
        })
        .catch(error => {
          console.error(error)
        })
    }
  },
  mounted () {
    this.simpleMessageId = this.$route.params.id
    this.getSimpleMessage()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    window.scrollTo(0, 0)
    this.simpleMessageId = this.$route.params.id
    this.getSimpleMessage()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .message {
    cursor: pointer;
    /deep/ &.dimmed {
      cursor: default;
    }
  }

  /deep/ .abk-simple-messages-form .form-group:first-child {
    display: none;
  }
</style>
