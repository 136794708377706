<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <b-row v-if="finished">
        <abk-info-bar :info-text="$t('views.events.events_show.event_finished')"
                      :path="{ name: 'events_list_similar_path', params: { eventId: event.id } }"
                      :path-text="$t('views.events.events_show.show_similar_events')"/>
      </b-row>
      <b-row class="top-bar">
        <b-col cols="12" xl="8">
          <div class="d-flex align-items-center type-icon">
            <i v-if="event.eventType === 'training'" class="fas fa-graduation-cap"></i>
            <i v-else-if="event.eventType === 'fair'" class="fas fa-store"></i>
            <i v-else class="fas fa-message-plus"></i>
            <h2 class="event-name mb-0">{{ eventInfo.eventTitle }}</h2>
          </div>
          <div v-if="canBeEdited || canBeSendForPublication" class="text-right">
            <pcg-btn v-if="canBeEdited"
                     size="small"
                     class="mt-0"
                     variant="additional"
                     @click="$router.push({ name: 'events_edit_path', params: { id: event.id } })">
              {{ $t('general.edit') }}
            </pcg-btn>
            <pcg-btn v-if="canBeSendForPublication"
                     size="small"
                     class="mt-0"
                     variant="additional"
                     @click="sendForPublication">
              {{ $t('general.send_for_publication') }}
            </pcg-btn>
          </div>
        </b-col>
        <b-col v-if="subEvent.status === 'canceled'" xl="4" class="d-flex flex-xl-column justify-content-end align-items-center">
          <div class="canceled-event">{{ $t('views.events.events_show.event_canceled') }}</div>
        </b-col>
        <b-col xl="4" class="d-flex flex-xl-column justify-content-end align-items-center" v-if="showStudentActions && cvConsent">
          <pcg-btn v-if="isRegistrationAvailable"
                   size="small"
                   class="mr-0 mb-0 mt-0 m-xl-2"
                   @click="registerForEvent">
            {{ $t('views.events.events_show.register') }}
          </pcg-btn>
          <pcg-btn v-else-if="isUserRegistered && isAccepted"
                   size="small"
                   class="mr-0 mb-0 mt-0 m-xl-2"
                   @click="unregisterFromEvent">
            {{ $t('views.events.events_show.unregister') }}
          </pcg-btn>
          <pcg-btn v-else-if="isReservationAvailable"
                   size="small"
                   class="mr-0 mb-0 mt-0 m-xl-2"
                   @click="registerForReserveList">
            {{ $t('views.events.events_show.register_reserve') }}
          </pcg-btn>
          <pcg-btn v-else-if="isUserReserved && isAccepted"
                   size="small"
                   class="mr-0 mb-0 mt-0 m-xl-2"
                   @click="unregisterFromEvent">
            {{ $t('views.events.events_show.unregister_reserve') }}
          </pcg-btn>
          <span v-else-if="noFreePlaces" class="pcg-error-messages">
          {{ $t('views.events.events_show.no_free_places') }}
        </span>
        </b-col>
        <div v-if="(showEmployerActions || showCareerOfficeActions) && isNotDraft"
             class="col-12 col-xl-4 d-flex justify-content-center align-items-end">
          <template v-if="isWaitingForAcceptance && showCareerOfficeActions">
            <pcg-btn size="small"
                     variant="additional"
                     @click="performAction('acceptEvent')">
              {{ $t('general.accept') }}
            </pcg-btn>
            <pcg-btn size="small"
                     variant="additional"
                     @click="performAction('rejectEvent')">
              {{ $t('general.reject') }}
            </pcg-btn>
          </template>
          <template v-else>
            <div>
              <pcg-btn v-if="isAccepted"
                       size="small"
                       variant="additional"
                       @click="performAction('cancelEvent')">
                {{ $t('general.cancel') }}
              </pcg-btn>
              <pcg-btn size="small"
                       variant="additional"
                       @click="$router.push({name: 'reserve_list_path',params: {event: subEvent.id}})">
                {{ $t('components.events.abk_events_list.reserve_list') }}
              </pcg-btn>
            </div>
            <div>
              <pcg-btn size="small"
                       variant="additional"
                       @click="$router.push({name: 'attendance_list_path',params: {event: subEvent.id}})">
                {{ $t('components.events.abk_events_list.attendance_list') }}
              </pcg-btn>
              <pcg-btn size="small"
                       variant="additional"
                       @click="$router.push({name: 'sign_out_list_path',params: {event: subEvent.id}})">
                {{ $t('components.events.abk_events_list.sign_out_list') }}
              </pcg-btn>
            </div>
          </template>
        </div>
      </b-row>
      <b-row>
        <b-col xl="8">
          <b-row>
            <abk-time-and-place-box v-if="showTimeAndPlaceBox && event.subEvents.length > 0"
                                    :start-date="subEvent.startDate"
                                    :end-date="subEvent.endDate"
                                    :place="event.place"
                                    :info="eventInfo"
                                    :address="event.address"
                                    :other-dates="event.otherDates"
                                    :show-employer-actions="showEmployerActions"
                                    :show-career-office-actions="showCareerOfficeActions"
                                    :is-not-draft="isNotDraft"
                                    @removeSubevent="removeSubevent"
                                    class="col-12"/>
            <abk-time-and-place-box v-if="showTimeAndPlaceBox && event.subEvents.length === 0"
                                    :start-date="event.startDate"
                                    :end-date="event.endDate"
                                    :place="event.place"
                                    :info="eventInfo"
                                    :address="event.address"
                                    :other-dates="event.otherDates"
                                    class="col-12"/>
            <pcg-box v-if="env && env.newEventsView && event && event.company" class="w-100 my-3">
              <b-col class="d-flex align-items-center">
                <img class="company-logo" :src="event.company.logo" :alt="event.company.name"/>
                <label class="employer-name">{{ event.company.name }}</label>
                <template v-if="event.company.employerType === 'partner'">
                  <span class="partner-text">{{ event.company.employerTypeText }}</span>
                  <img v-if="contrast" :src="event.company.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
                  <img v-else :src="event.company.employerTypeLogo" class="img-fluid rounded partner-logo">
                </template>
              </b-col>
            </pcg-box>
            <abk-content-preview v-if="eventSections.length"
                                 :contentSections="eventSections"
                                 :attachments="event.eventAttachments"
                                 :download-attachment-action="downloadAttachmentAction"
                                 class="col-12"/>
          </b-row>
        </b-col>
        <b-col xl="4">
          <div class="row">
            <abk-event-quick-info :info="eventInfo" class="col-12 col-sm-6 col-xl-12"/>
            <abk-events-box v-if="similarEvents && similarEvents.length > 0"
                            :events="similarEvents"
                            :limit="4"
                            class="col-12 col-sm-6 col-xl-12">
              <template v-slot:header>
                <i class="pcg-icon-fan"/>
                {{ $t('views.events.events_show.similar_events') }}
              </template>
            </abk-events-box>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="number-of-views">
          {{ $t('views.events.events_show.number_of_views') }} {{ subEvent.numberOfViews }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbkEventQuickInfo from '../../components/events/AbkEventQuickInfo'
import AbkEventsBox from '../../components/events/AbkEventsBox'
import AbkContentPreview from '../../components/AbkContentPreview'
import ApiEvents from '../../api/events'
import ApiSubEvents from '../../api/sub_events'
import ApiEventAttachments from '../../api/event_attachments'
import { mapGetters } from 'vuex'
import AbkTimeAndPlaceBox from '../../components/AbkTimeAndPlaceBox'
import { decamelize } from 'humps'
import ApiEventRegistrations from '../../api/event_registrations'
import ErrorHandlerMixin from '../../mixins/error_handler'
import AbkInfoBar from '../../components/AbkInfoBar'
import AbkPageNotFound from '../../components/AbkPageNotFound'

export default {
  name: 'events-show',
  components: {
    AbkPageNotFound,
    AbkInfoBar,
    AbkTimeAndPlaceBox,
    AbkEventsBox,
    AbkEventQuickInfo,
    AbkContentPreview
  },
  mixins: [ErrorHandlerMixin],
  data () {
    return {
      eventId: null,
      event: {},
      company: {},
      similarEvents: [],
      prevRoute: null,
      notFound: false,
      subEvent: {},
      isSubEvent: false
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['cvConsent', 'companyConsent']),
    ...mapGetters('header', ['title', 'subtitle']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('environment', ['env']),
    showTimeAndPlaceBox () {
      if (this.event.subEvents) {
        return true
      } else {
        return this.event.startDate || this.event.endDate || this.event.place || this.event.address
      }
    },
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office' || this.currentRole === 'counselor'
    },
    isWaitingForAcceptance () {
      if (this.isSubEvent && this.event.subEvents) {
        return this.subEvent.status === 'for_acceptance'
      } else {
        return this.event.status === 'for_acceptance'
      }
    },
    eventSections () {
      return this.event.eventSections || []
    },
    eventInfo () {
      const { eventSections, eventAttachments, startDate, endDate, place, address, ...eventInfo } = this.event
      eventInfo.currentlyFreePlaces = this.subEvent.currentlyFreePlaces
      eventInfo.currentlyReservePlaces = this.subEvent.currentlyReservePlaces
      eventInfo.startDate = this.subEvent.startDate
      eventInfo.endDate = this.subEvent.endDate
      eventInfo.registrationEnd = this.subEvent.registrationEnd
      eventInfo.registrationStart = this.subEvent.registrationStart
      eventInfo.freePlaces = this.subEvent.freePlaces
      eventInfo.reservePlaces = this.subEvent.reservePlaces
      eventInfo.competences = this.event.competences
      return eventInfo
    },
    canBeEdited () {
      return (this.isNotSend && (this.showEmployerActions)) ||
        (this.showCareerOfficeActions)
    },
    canBeSendForPublication () {
      return this.showEmployerActions && this.isNotSend
    },
    isNotSend () {
      if (this.isSubEvent && this.event.subEvents) {
        return ['draft', 'recent'].includes(this.subEvent.status)
      } else {
        return ['draft', 'recent'].includes(this.event.status)
      }
    },
    isNotDraft () {
      if (this.isSubEvent && this.event.subEvents) {
        return this.subEvent.status !== 'draft'
      } else {
        return this.event.status !== 'draft'
      }
    },
    isAccepted () {
      if (this.isSubEvent && this.event.subEvents) {
        return this.subEvent.status === 'accepted'
      } else {
        return this.event.status === 'accepted'
      }
    },
    subtitle () {
      const momentDate = moment(this.event.startDate)

      if (this.event.startDate && momentDate.isValid()) {
        return momentDate.format('MMMM YYYY')
      }
      return null
    },
    downloadAttachmentAction () {
      return ApiEventAttachments.getEventAttachment
    },
    isRegistrationAvailable () {
      return this.isAccepted && Date.parse(this.subEvent.registrationEnd) > Date.now() &&
          Date.parse(this.subEvent.registrationStart) < Date.now() &&
          (!this.isUserRegistered && !this.isUserReserved) &&
        (this.subEvent.currentlyFreePlaces === null || this.subEvent.currentlyFreePlaces > 0)
    },
    isReservationAvailable () {
      return this.isAccepted && Date.parse(this.subEvent.registrationEnd) > Date.now() &&
          Date.parse(this.subEvent.registrationStart) < Date.now() &&
          (!this.isUserReserved && !this.isUserRegistered) &&
          (this.subEvent.currentlyReservePlaces === null || this.subEvent.currentlyReservePlaces > 0)
    },
    isUserRegistered () {
      return !!this.subEvent.eventRegistrationId
    },
    isUserReserved () {
      return !!this.subEvent.eventReservationId
    },
    noFreePlaces () {
      const freePlaces = this.subEvent.currentlyFreePlaces
      return ['string', 'number'].includes(typeof freePlaces) && freePlaces <= 0
    },
    noReservePlaces () {
      const reservePlaces = this.subEvent.currentlyReservePlaces
      return ['string', 'number'].includes(typeof reservePlaces) && reservePlaces <= 0
    },
    finished () {
      if (this.isSubEvent && this.event.subEvents) {
        return this.subEvent.status === 'finished'
      } else {
        return this.event.status === 'finished'
      }
    },
    unlimitedFreePlaces () {
      return [null, undefined].includes(this.subEvent.currentlyFreePlaces)
    },
    unlimitedReservePlaces () {
      return [null, undefined].includes(this.subEvent.currentlyReservePlaces)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
      vm.prevRoute = from
    })
  },
  beforeRouteUpdate (to, from, next) {
    next()
    window.scrollTo(0, 0)
    this.eventId = this.$route.params.id
    this.getEvent()
    // this.getSimilarEvents()
  },
  mounted () {
    this.eventId = this.$route.params.id
    if (this.$route.name === 'sub_events_show_path') {
      this.isSubEvent = true
    }
    this.getEvent()
  },
  watch: {
    '$route' (route) {
      this.eventId = route.params.id
      if (route.name === 'sub_events_show_path') {
        this.isSubEvent = true
      }
      this.getEvent()
    }
  },
  methods: {
    registerForEvent () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('views.events.events_show.register_confirmation_question'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.register'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          if (this.isSubEvent) {
            ApiEventRegistrations.createEventRegistration({ eventRegistration: { eventId: this.eventId, status: 'main' } })
              .then(response => {
                this.subEvent.eventRegistrationId = response.data.id
                if (!this.unlimitedFreePlaces && !this.noFreePlaces) {
                  --this.subEvent.currentlyFreePlaces
                }
                this.$toastr.s(this.$t('views.events.events_show.registered_for_event'))
              })
              .catch(error => {
                this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.register_for_event_error'))
              })
          } else {
            ApiEventRegistrations.createEventRegistration({ eventRegistration: { eventId: this.eventId, status: 'main' } })
              .then(response => {
                this.event.eventRegistrationId = response.data.id
                if (!this.unlimitedFreePlaces && !this.noFreePlaces) {
                  --this.event.currentlyFreePlaces
                }
                this.$toastr.s(this.$t('views.events.events_show.registered_for_event'))
              })
              .catch(error => {
                this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.register_for_event_error'))
              })
          }
        }
      })
    },
    registerForReserveList () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('views.events.events_show.register_reserve_confirmation_question'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.register'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          if (this.isSubEvent) {
            ApiEventRegistrations.createEventRegistration({ eventRegistration: { eventId: this.eventId, status: 'reserve' } })
              .then(response => {
                this.subEvent.eventReservationId = response.data.id
                if (!this.unlimitedReservePlaces && !this.noReservePlaces) {
                  --this.subEvent.currentlyReservePlaces
                }
                this.$toastr.s(this.$t('views.events.events_show.registered_for_event_reserve'))
              })
              .catch(error => {
                this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.register_for_event_error'))
              })
          } else {
            ApiEventRegistrations.createEventRegistration({ eventRegistration: { eventId: this.eventId, status: 'reserve' } })
              .then(response => {
                this.event.eventReservationId = response.data.id
                if (!this.unlimitedReservePlaces && !this.noReservePlaces) {
                  --this.event.currentlyReservePlaces
                }
                this.$toastr.s(this.$t('views.events.events_show.registered_for_event_reserve'))
              })
              .catch(error => {
                this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.register_for_event_error'))
              })
          }
        }
      })
    },
    unregisterFromEvent () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('views.events.events_show.unregister_confirmation_question'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.unregister'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          if (this.isSubEvent) {
            if (this.subEvent.eventRegistrationId) {
              ApiEventRegistrations.signOutEventRegistration(this.subEvent.eventRegistrationId)
                .then(() => {
                  this.getEvent()
                  this.$toastr.s(this.$t('views.events.events_show.unregistered_from_event'))
                })
                .catch(error => {
                  this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.unregister_from_event_error'))
                })
            } else if (this.subEvent.eventReservationId) {
              ApiEventRegistrations.signOutEventRegistration(this.subEvent.eventReservationId)
                .then(() => {
                  this.getEvent()
                  this.$toastr.s(this.$t('views.events.events_show.unregistered_from_event_reserve'))
                })
                .catch(error => {
                  this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.unregister_from_event_error'))
                })
            }
          } else {
            if (this.event.eventRegistrationId) {
              ApiEventRegistrations.signOutEventRegistration(this.event.eventRegistrationId)
                .then(() => {
                  this.getEvent()
                  this.$toastr.s(this.$t('views.events.events_show.unregistered_from_event'))
                })
                .catch(error => {
                  this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.unregister_from_event_error'))
                })
            } else if (this.event.eventRegistrationId) {
              ApiEventRegistrations.signOutEventRegistration(this.event.eventReservationId)
                .then(() => {
                  this.getEvent()
                  this.$toastr.s(this.$t('views.events.events_show.unregistered_from_event_reserve'))
                })
                .catch(error => {
                  this.handle422ToastrErrors(error.response, this.$t('views.events.events_show.unregister_from_event_error'))
                })
            }
          }
        }
      })
    },
    sendForPublication () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.send_for_publication'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiEvents.sendForPublication(this.event.id)
            .then(() => {
              this.$toastr.s(this.$t('views.events.events_show.event_sent_for_publication'))
              this.event.status = 'for_acceptance'
              this.getEvent()
            })
            .catch(() => {
              this.$toastr.e(this.$t('views.events.events_show.event_sending_for_publication_error'))
            })
        }
      })
    },
    goBackToSearch () {
      this.$router.push(this.prevRoute.fullPath)
    },
    performAction (action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiEvents[action](this.event.id)
            .then(() => {
              this.getEvent()
            }).catch(() => {
              this.$toastr.e(this.$t('views.events.events_show.unexpected_error'))
            })
        }
      })
    },
    removeSubevent (id) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.remove_sub_event'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiSubEvents.cancelEvent(id)
            .then(() => {
              this.getEvent()
            }).catch(() => {
              this.$toastr.e(this.$t('views.events.events_show.unexpected_error'))
            })
        }
      })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.events_show.title'),
        subtitle: this.subtitle
      })
    },
    getEvent () {
      if (this.isSubEvent) {
        ApiSubEvents.getEvent(this.eventId)
          .then(response => {
            this.event = { ...response.data.data.attributes, id: response.data.data.id }
            this.company = response.data.data.attributes.company
            if (this.event.subEvents) {
              this.subEvent = { ...this.event.subEvents[0].data.attributes }
              this.subEvent.id = this.event.subEvents[0].data.id
            }
            this.$store.dispatch('header/setObjectName', this.event.eventTitle)
            this.getSimilarEvents()
            this.visitedEvent()
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.notFound = true
            }
            console.log(error)
          })
      } else {
        ApiEvents.getEvent(this.eventId)
          .then(response => {
            this.event = { ...response.data.data.attributes, id: response.data.data.id }
            if (this.event.subEvents) {
              this.subEvent = { ...this.event.subEvents[0].data.attributes }
              this.subEvent.id = this.event.subEvents[0].data.id
            }
            this.company = response.data.data.attributes.company
            this.$store.dispatch('header/setObjectName', this.event.eventTitle)
            this.getSimilarEvents()
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.notFound = true
            }
            console.log(error)
          })
      }
    },
    getSimilarEvents () {
      ApiEvents.getSimilarEvents({ eventId: this.event.id, filters: { count: 4 } })
        .then(response => {
          this.similarEvents = response.data.data.map(event => {
            return {
              id: event.attributes.subEvents[0].data.id,
              ...event.attributes
            }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    visitedEvent () {
      if (this.showStudentActions) {
        ApiSubEvents.visitedEvent(this.eventId)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .type-icon {
    color: $main-color;
    margin-bottom: $pcg-m-lg;
  }

  .fa-message-plus::before {
    content: \f4a8;
  }

  .partner-text {
    color: $pcg-black;
    font-weight: bold;
    background-color: rgba(200, 200, 200, 0.5);
    padding: 0.15rem 0.4rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .full-height {
    height: 100%;
  }

  .company-logo {
    max-height: 30px;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .employer-name {
    color: $main-color;
    margin: 0 1.5rem;
    font-size: 1.71em;
    font-weight: 400;
  }

  .partner-container {
    display: flex;
    align-items: center;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 4rem;
  }

  .partner-logo {
    position: relative;
    right: .1rem;
    max-width: 15px;
    margin-left: 10px;
  }

  .event-name {
    font-size: $pcg-title-font-size;
    font-weight: 400;
    color: $main-color;
    margin-left: 1rem;
  }
  .top-bar {
    margin-bottom: $pcg-m-lg;
  }
  .number-of-views {
    margin-top: 1rem;
    color : $pcg-gray;
    font-weight: 400;
  }
  .canceled-event {
    color: $alert-error;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .type-icon {
      color: $main-color;
    }
    .partner-text {
      color: $pcg-black;
      background-color: rgba(200, 200, 200, 0.5);
    }
    .employer-name {
      color: $main-color;
    }
    .event-name {
      color: $main-color;
    }
    .number-of-views {
      color : $pcg-gray;
    }
    .canceled-event {
      color: $alert-error;
    }
  }
</style>
