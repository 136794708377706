import api from './index'

const resource = 'students'

export default {
  getStudentsList (params) {
    // return api.get(`${resource}/student_list`, { params: params })
    return api.get(`${resource}`, { params: params })
  },
  getStudent (studentId) {
    return api.get(`${resource}/${studentId}`)
  },
  getStudentNotes (studentId, params = {}) {
    return api.get(`${resource}/${studentId}/student_notes`, { params: params })
  },
  deleteNote (noteId) {
    return api.delete(`${resource}/destroy_student_notes`, { params: { note_id: noteId } })
  },
  newNote (studentId, content) {
    return api.post(`${resource}/${studentId}/new_student_notes`, { params: { content: content } })
  },
  editNote (noteId, content) {
    return api.patch(`${resource}/edit_student_notes`, { params: { note_id: noteId, content: content } })
  },
  getNote (noteId) {
    return api.get(`${resource}/get_note`, { params: { note_id: noteId } })
  }
}
