import api from './index'

const resource = 'user_messages'

export default {
  getUserMessages (page) {
    return api.get(`${resource}?page=${page}`)
  },
  markAsRead (userMessageId) {
    return api.patch(`${resource}/${userMessageId}/mark_as_read`)
  },
  getCommunique () {
    return api.get(`${resource}/communique`)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_communiques`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  getUserMessage (userMessageId) {
    return api.get(`${resource}/${userMessageId}`)
  }
}
