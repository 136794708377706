<template>
  <div>
    <b-col cols="12" class="box">
      <b-row class="box-header">
        <b-col cols="12" class="d-flex mb-4">
          <h2>{{ $t('components.abk_text_section_form.subtitle') }}</h2>
          <button class="section-btn small" @click="removeSection">✕ {{ $t('general.delete') }}</button>
        </b-col>
        <pcg-text-input class="col-12 col-xl-6 m-0"
                        v-model="value.name"
                        :show-error="errorExist('name')"
                        :error-text="getError('name')"
                        :label="$t('components.abk_text_section_form.subtitle')"/>
        <button class="section-btn add-subsection-btn mt-3 ml-auto mr-4 m-xl-0" @click="addSubsection">
          + {{ $t('components.abk_text_section_form.add_subsection') }}
        </button>
      </b-row>
      <transition-group name="slide" type="out-in">
        <template v-for="(subsection, index) in value.companySubsections">
          <b-row v-if="!subsection._destroy" :key="subsection.key" class="subsection">
            <b-col cols="12" xl="6">
              <div class="d-flex h-100 flex-column">
                <div class="position-relative subsection-name-input-wrapper">
                  <pcg-text-input v-model="subsection.name"
                                  :show-error="subsectionErrorExist(index, 'name')"
                                  :error-text="getSubsectionError(index, 'name')"
                                  :label="$t('components.abk_text_section_form.subsection_name')"
                                  class="d-inline-block w-100 m-0"/>
                  <button class="section-btn remove-subsection-btn small" @click="removeSubsection(index)">
                    ✕ {{ $t('general.delete') }}
                  </button>
                </div>
                <label class="pcg-input-label">
                  {{ $t('components.abk_text_section_form.content') }}
                </label>
                <tinymce-editor class=""
                                :class="{ invalid: subsectionErrorExist(index, 'content') }"
                                :init="tinymceSettings"
                                :value="subsection.content"
                                @input="update($event, index)"/>
                <input class="pcg-field d-none" :required="subsectionErrorExist(index, 'content')"/>
                <div v-if="subsectionErrorExist(index, 'content')" class="pcg-error-messages">{{ getSubsectionError(index, 'content') }}</div>
              </div>
            </b-col>
            <b-col cols="12" xl="5" offset-xl="1">
              <h3>{{ $t('components.abk_text_section_form.background') }}</h3>
              <abk-file-input class="m-0"
                              :accept="acceptableExtensions"
                              :ref="`attachment${index}`"
                              show-remove-button
                              :error-text="getSubsectionError(index, 'attachment')"
                              v-b-tooltip.hover
                              :title="getSubsectionError(index, 'attachment')"
                              :file-url="getImageUrl(index)"
                              @input="updateImage(index, $event)"
                              @remove="removeImage(index)"
                              :status="fileInputStatus(index)"/>
            </b-col>
          </b-row>
        </template>
      </transition-group>
    </b-col>
  </div>
</template>

<script>
import AbkFileInput from './AbkFileInput'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'AbkTextSectionForm',
  components: { AbkFileInput, TinymceEditor },
  props: {
    value: Object,
    errors: Object,
    sectionIndex: [Number, String]
  },
  data () {
    return {
      maxSubsections: 5,
      acceptableExtensions: '.jpg,.jpeg,.gif,.png',
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 150,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  methods: {
    addSubsection () {
      if (this.value.companySubsections.length < this.maxSubsections) {
        const subsectionsKeys = this.value.companySubsections.map(e => e.key)
        const newKey = subsectionsKeys.length > 0 ? Math.max(...subsectionsKeys) + 1 : 0
        this.value.companySubsections.push({ key: newKey, name: null, content: null, attachment: null })
      }
    },
    removeSubsection (index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          const subsection = this.value.companySubsections[index]
          if (subsection.id) {
            subsection._destroy = true
          } else {
            this.value.companySubsections.splice(index, 1)
          }
          this.$emit('input', { ...this.value })
        }
      })
    },
    removeSection () {
      this.$emit('remove')
    },
    fileInputStatus (index) {
      if (this.getSubsectionError(index, 'attachment')) {
        return 'fail'
      } else if (this.attachmentExist(index)) {
        return 'success'
      }
      return 'upload'
    },
    attachmentExist (index) {
      const image = this.value.companySubsections[index]
      return image.attachment && (image.attachment instanceof File || image.attachment.url)
    },
    getImageUrl (index) {
      const attachment = this.value.companySubsections[index].attachment
      return attachment ? attachment.url : null
    },
    updateImage (index, value) {
      this.value.companySubsections[index].attachment = value
      this.errors[this.getSubsectionErrorKey(index, 'attachment')] = null
      const copy = { ...this.value }
      this.$emit('input', copy)
    },
    removeImage (index) {
      this.errors[this.getSubsectionErrorKey(index, 'attachment')] = null
      const subsection = this.value.companySubsections[index]
      if (subsection.id) {
        subsection.remove_attachment = true
        subsection.attachment = null
        this.$refs[`attachment${index}`][0].clear()
      } else {
        this.value.companySubsections.splice(index, 1)
      }
      this.$emit('input', { ...this.value })
    },
    getSubsectionErrorKey (index, field) {
      return `companySections.${this.sectionIndex}.companySubsections.${index}.${field}`
    },
    getSubsectionError (index, field) {
      const error = this.errors[this.getSubsectionErrorKey(index, field)]
      return error ? error.join(', ') : null
    },
    subsectionErrorExist (index, field) {
      return !!this.errors[`companySections.${this.sectionIndex}.companySubsections.${index}.${field}`]
    },
    getError (field) {
      return this.errorExist(field) ? this.errors[`companySections.${this.sectionIndex}.${field}`].join(', ') : null
    },
    errorExist (field) {
      return !!this.errors[`companySections.${this.sectionIndex}.${field}`]
    },
    update (value, index) {
      this.value.companySubsections[index].content = value
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  h2, h3 {
    font-size: 1em;
    color: $pcg-gray;
  }
  h2 {
    text-transform: uppercase;
    width: fit-content;
    margin: 0 1em 0 0;
  }
  h3 {
    margin-top: .87em;
    margin-bottom: $pcg-m-xl;
  }
  .title, .subtitle {
    text-transform: uppercase;
  }
  button {
    padding-left: 50px;
    padding-right: 50px;
  }
  .box {
    border: 2px dashed $pcg-light-gray;
    border-radius: 15px;
  }
  .section-btn {
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.remove-subsection-btn {
      height: fit-content;
      position: absolute;
      top: 40px;
      bottom: 0;
      right: -53px;
      margin: 0 auto 10px;
    }
    &.small {
      font-size: $font-size-s;
    }
  }
  .add-subsection-btn {
    padding-top: 26px;
  }
  .box-header, .subsection {
    padding: 30px 25px;
  }
  .subsection {
    border-top: 2px solid $pcg-lightest-gray;
  }
  .subsection-name-input-wrapper {
    margin-bottom: $pcg-m-lg;
  }
  .content-textarea {
    /deep/ textarea {
      height: 100%;
    }
  }
  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
  @media (max-width: $screen-md-min) {
    .box-header, .subsection {
      padding: 20px 5px;
    }
  }

  @media (max-width: $screen-xlg-min) {
    h3 {
      margin-top: $pcg-m-lg;
      margin-bottom: .8em;
    }
    .subsection {
      padding-top: 40px;
    }
    .remove-subsection-btn {
      top: -30px!important;
      right: 0!important;
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    h2, h3 {
      color: $pcg-gray;
    }
    .box {
      border: 2px dashed $pcg-light-gray;
    }
    .section-btn {
      color: $main-active-color;
    }
    .subsection {
      border-top: 2px solid $pcg-lightest-gray;
    }
  }
</style>
