<template>
  <pcg-file-input ref="fileInput"
                  v-bind="$props"
                  @input="$emit('input', $event)"
                  @remove="$emit('remove')"
                  :success-title="successTitle || $t('components.abk_file_input.success_title')"
                  :loading-title="loadingTitle || $t('components.abk_file_input.loading_title')"
                  :error-title="errorTitle || $t('components.abk_file_input.error_title')"
                  :upload-title="uploadTitle || $t('components.abk_file_input.upload_title')"/>
</template>

<script>
export default {
  name: 'AbkFileInput',
  props: {
    value: String,
    fileUrl: String,
    readOnly: Boolean,
    errorText: String,
    accept: String,
    successTitle: String,
    loadingTitle: String,
    errorTitle: String,
    uploadTitle: String,
    showRemoveButton: Boolean,
    status: {
      type: String,
      default: 'upload',
      validator (direction) {
        return ['upload', 'success', 'fail', 'loading'].includes(direction)
      }
    }
  },
  methods: {
    clear () {
      this.$refs.fileInput.clear()
    }
  }
}
</script>
