import Vue from 'vue'
import VueRouter from 'vue-router'
import { app } from '../index'
import { store } from '../store/index'
import { i18n } from '../translations/index'
import _ from 'lodash'
import qs from 'qs'

import Test from '../views/Test'
import Test2 from '../views/Test2'
import ChildrenTest from '../views/ChildrenTest'
import ChildrenTest2 from '../views/ChildrenTest2'
import Attendance from '../views/Attendances'
import AttendanceIndex from '../views/attendance/AttendanceIndex'
import StudentDashboard from '../views/StudentDashboard'
import EmployerDashboard from '../views/EmployerDashboard'
import Offers from '../views/Offers'
import OffersIndex from '../views/offers/OffersIndex'
import OffersShow from '../views/offers/OffersShow'
import OffersEmployerTabs from '../views/offers/OffersEmployerTabs'
import OffersNew from '../views/offers/OffersNew'
import OffersList from '../views/offers/OffersList'
import OfferFilters from '../views/OfferFilters'
import ApplicationsOffers from '../views/offer_applications/ApplicationsOffers'
import OfferFiltersIndex from '../views/offer_filters/OfferFiltersIndex'
import FavoriteOffersIndex from '../views/favorite_offers/FavoriteOffersIndex'
import OffersMatchingToSkillList from '../views/offers/OffersMatchingToSkillList'
import OffersNotMatchingToSkillList from '../views/offers/OffersNotMatchingToSkillList'
import Webpages from '../views/Webpages'
import WebpagesIndex from '../views/webpages/WebpagesIndex'
import WebpagesNew from '../views/webpages/WebpagesNew'
import WebpagesEdit from '../views/webpages/WebpagesEdit'
import WebpagesShow from '../views/webpages/WebpagesShow'
import Dictionaries from '../views/Dictionaries'
import DictionariesIndex from '../views/dictionaries/DictionariesIndex'
import DictionariesEdit from '../views/dictionaries/DictionariesEdit'
import DictionaryWords from '../views/DictionaryWords'
import DictionaryWordsIndex from '../views/dictionary_words/DictionaryWordsIndex'
import DictionaryWordsRecent from '../views/dictionary_words/DictionaryWordsRecent'
import DictionaryWordsEdit from '../views/dictionary_words/DictionaryWordsEdit'
import DictionaryWordsNew from '../views/dictionary_words/DictionaryWordsNew'
import DictionaryWordsTabs from '../views/dictionary_words/DictionaryWordsTabs'
import DictionaryAcceptances from '../views/DictionaryAcceptances'
import DictionaryAcceptancesIndex from '../views/dictionary_acceptances/DictionaryAcceptancesIndex'
import DictionaryAcceptancesEdit from '../views/dictionary_acceptances/DictionaryAcceptancesEdit'
import DictionaryAcceptanceWords from '../views/DictionaryAcceptanceWords'
import DictionaryAcceptanceWordsIndex from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsIndex'
import DictionaryAcceptanceWordsEdit from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsEdit'
import DictionaryAcceptanceWordsNew from '../views/dictionary_acceptance_words/DictionaryAcceptanceWordsNew'
import Messages from '../views/Messages'
import MessagesNew from '../views/messages/MessagesNew'
import MessagesEdit from '../views/messages/MessagesEdit'
import MessagesCopy from '../views/messages/MessagesCopy'
import MessagesIndex from '../views/messages/MessagesIndex'
import UserMessages from '../views/UserMessages'
import UserMessagesIndex from '../views/user_messages/UserMessagesIndex'
import Login from '../views/Login'
import RoleChoose from '../views/RoleChoose'
import CurriculumVitaes from '../views/CurriculumVitaes'
import CurriculumVitaeShow from '../views/curriculum_vitaes/CurriculumVitaeShow'
import CurriculumVitaeEdit from '../views/curriculum_vitaes/CurriculumVitaeEdit'
import OffersEdit from '../views/offers/OffersEdit'
import Companies from '../views/Companies'
import CompaniesIndex from '../views/companies/CompaniesIndex'
import AcceptedCompaniesTab from '../views/companies/index_tabs/AcceptedCompaniesTab'
import RejectedCompaniesTab from '../views/companies/index_tabs/RejectedCompaniesTab'
import ForAcceptanceCompaniesTab from '../views/companies/index_tabs/ForAcceptanceCompaniesTab'
import CompanyEdit from '../views/companies/CompanyEdit'
import CompanyShow from '../views/companies/CompanyShow'
import DevSessionsIndex from '../views/dev_sessions/DevSessionsIndex'
import SimpleMessages from '../views/SimpleMessages'
import SimpleMessagesIndex from '../views/simple_messages/SimpleMessagesIndex'
import SimpleMessagesShow from '../views/simple_messages/SimpleMessagesShow'
import GroupMessagesTab from '../views/messages/index_tabs/GroupMessagesTab'
import DraftMessagesTab from '../views/messages/index_tabs/DraftMessagesTab'
import RecipientGroups from '../views/RecipientGroups'
import RecipientGroupsIndex from '../views/recipient_groups/RecipientGroupsIndex'
import CareerOfficeDashboard from '../views/CareerOfficeDashboard'
import Events from '../views/Events'
import EventsNew from '../views/events/EventsNew'
import EventsList from '../views/events/EventsList'
import EventsShow from '../views/events/EventsShow'
import EventsEdit from '../views/events/EventsEdit'
import Competences from '../views/Competences'
import CompetencesList from '../views/competences/CompetencesList'
import AllCompetencesTab from '../views/competences/list_tabs/AllCompetencesTab'
import CompetencesConnectedWithSkillsTab from '../views/competences/list_tabs/CompetencesConnectedWithSkillsTab'
import CompetencesNotConnectedWithSkillsTab from '../views/competences/list_tabs/CompetencesNotConnectedWithSkillsTab'
import CompetencesNew from '../views/competences/CompetencesNew'
import CompetencesEdit from '../views/competences/CompetencesEdit'
import CompetenceSkills from '../views/CompetenceSkills'
import CompetenceSkillsIndex from '../views/competence_skills/CompetenceSkillsIndex'
import CompetenceSkillsEdit from '../views/competence_skills/CompetenceSkillsEdit'
import Skills from '../views/Skills'
import SkillsList from '../views/skills/SkillsList'
import AllSkillsTab from '../views/skills/list_tabs/AllSkillsTab'
import SkillsConnectedWithCompetencesTab from '../views/skills/list_tabs/SkillsConnectedWithCompetencesTab'
import SkillsNotConnectedWithCompetencesTab from '../views/skills/list_tabs/SkillsNotConnectedWithCompetencesTab'
import SkillsReport from '../views/skills/SkillsReport'
import SkillCompetencesIndex from '../views/skills/SkillCompetencesIndex'
import AllEventsTab from '../views/events/list_tabs/AllEventsTab'
import CurrentEventsTab from '../views/events/list_tabs/CurrentEventsTab'
import ArchivedEventsTab from '../views/events/list_tabs/ArchivedEventsTab'
import RegisteredEventsTab from '../views/events/list_tabs/RegisteredEventsTab'
import AssignedEventsTab from '../views/events/list_tabs/AssignedEventsTab'
import PendingEventsTab from '../views/events/list_tabs/PendingEventsTab'
import Surveys from '../views/Surveys'
import SurveysShow from '../views/surveys/SurveysShow'
import SurveysList from '../views/surveys/SurveysList'
import SurveysNew from '../views/surveys/SurveysNew'
import SurveysEdit from '../views/surveys/SurveysEdit'
import SurveysStats from '../views/surveys/SurveysStats'
import CompletedSurveysTab from '../views/surveys/list_tabs/CompletedSurveysTab'
import RecentSurveysTab from '../views/surveys/list_tabs/RecentSurveysTab'
import ActiveSurveysTab from '../views/surveys/list_tabs/ActiveSurveysTab'
import InactiveSurveysTab from '../views/surveys/list_tabs/InactiveSurveysTab'
import AnswersIndex from '../views/surveys/answers/AnswersIndex'
import AnswersShow from '../views/surveys/answers/AnswersShow'
import OfferInvitationsList from '../views/offer_invitations/OfferInvitationsList'
import OfferInvitationsAllTab from '../views/offer_invitations/list_tabs/OfferInvitationsAllTab'
import OfferInvitationsCurrentTab from '../views/offer_invitations/list_tabs/OfferInvitationsCurrentTab'
import OfferInvitationsArchivedTab from '../views/offer_invitations/list_tabs/OfferInvitationsArchivedTab'
import OffersListSimilar from '../views/offers/OffersListSimilar'
import EventsListSimilar from '../views/events/EventsListSimilar'
import UserMessagesShow from '../views/user_messages/UserMessagesShow'
import SurveysParams from '../views/surveys/SurveysParams'
import UsersList from '../views/UsersList'
import RemovedUsersList from '../views/RemovedUsersList.vue'
import RegisterUser from '../views/RegisterUser'
import LoginUser from '../views/LoginUser'
import EmailConfirmation from '../views/EmailConfirmation'
import ResetPassword from '../views/ResetPassword'
import NewPassword from '../views/NewPassword'
import Students from '../views/Students'
import StudentsIndex from '../views/students/StudentsIndex'
import News from '../views/News'
import NewsList from '../views/news/NewsList'
import NewsNew from '../views/news/NewsNew'
import NewsEdit from '../views/news/NewsEdit'
import NewsIndex from '../views/news/NewsIndex'
import PageNotFound from '../views/PageNotFound'
import EventsStudentRegistered from '../views/events/EventsStudentRegistered'
import StudentNotes from '../views/student_notes/StudentNotes'
import StudentNotesNew from '../views/student_notes/StudentNotesNew'
import StudentNotesEdit from '../views/student_notes/StudentNotesEdit'
import { user } from '../store/modules/user'
import AttendanceReserveIndex from '../views/attendance/AttendanceReserveIndex'
import SignOutList from '../views/attendance/SignOutList'

Vue.use(VueRouter)

function acceptedCompany () {
  if (user.state.company) {
    return user.state.acceptedCompany
  } else {
    return true
  }
}

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'exact-active',
  routes: [
    {
      path: '/',
      name: 'root_path',
      component: Login
    },
    {
      path: '/login',
      name: 'login_path',
      component: Login
    },
    {
      path: '/role_choose',
      name: 'role_choose_path',
      component: RoleChoose,
      meta: { auth: true }
    },
    {
      path: '/openid-connect',
      name: 'openid_login_path',
      component: Login
    },
    {
      path: '/test1',
      name: 'components_path',
      component: Test,
      children: [
        {
          path: 'children',
          name: 'children_path',
          component: ChildrenTest
        },
        {
          path: 'children2',
          name: 'children2_path',
          component: ChildrenTest2
        }
      ]
    },
    {
      path: '/test2',
      name: 'test2_path',
      component: Test2,
      meta: { auth: true, roles: ['career_office'] }
    },
    {
      path: '/student_dashboard',
      name: 'student_dashboard_path',
      component: StudentDashboard,
      meta: {
        auth: true,
        roles: ['student', 'graduate'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.dashboard'
      }
    },
    {
      path: '/career_office_dashboard',
      name: 'career_office_dashboard_path',
      component: CareerOfficeDashboard,
      meta: {
        auth: true,
        roles: ['career_office'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.dashboard'
      }
    },
    {
      path: '/employer_dashboard',
      name: 'employer_dashboard_path',
      component: EmployerDashboard,
      meta: {
        auth: true,
        roles: ['employer', 'recruiter'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.dashboard'
      }
    },
    {
      path: '/attendance_list',
      component: Attendance,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.attendance_list' },
      children: [
        {
          path: '/attendance_list/:event',
          name: 'attendance_list_path',
          component: AttendanceIndex,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter']
          },
          children: []
        }
      ]
    },
    {
      path: '/reserve_list',
      component: Attendance,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.reserve_list' },
      children: [
        {
          path: '/reserve_list/:event',
          name: 'reserve_list_path',
          component: AttendanceReserveIndex,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter']
          },
          children: []
        }
      ]
    },
    {
      path: '/sign_out_list',
      component: Attendance,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.sign_out_list' },
      children: [
        {
          path: '/sign_out_list/:event',
          name: 'sign_out_list_path',
          component: SignOutList,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter']
          },
          children: []
        }
      ]
    },
    {
      path: '/simple_messages',
      component: SimpleMessages,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.contact' },
      children: [
        {
          path: '',
          name: 'simple_messages_index_path',
          component: SimpleMessagesIndex,
          meta: { auth: true, roles: ['career_office', 'employer', 'recruiter', 'student', 'graduate'] }
        },
        {
          path: ':id',
          name: 'simple_messages_show_path',
          component: SimpleMessagesShow,
          meta: { auth: true, roles: ['career_office', 'employer', 'recruiter', 'student', 'graduate'] }
        }
      ]
    },
    {
      path: '/offers',
      component: Offers,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.catalog_of_offers'
      },
      children: [
        {
          path: 'list',
          name: 'offers_list_path',
          component: OffersList,
          meta: {
            auth: true,
            roles: ['career_office', 'employer', 'recruiter'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.list_of_offers'
          }
        },
        {
          path: 'list/matching_to_skill/:skillId',
          name: 'offers_matching_to_skill_list_path',
          component: OffersMatchingToSkillList,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.list_of_offers'
          }
        },
        {
          path: 'list/not_matching_to_skill/:skillId/:competenceId',
          name: 'offers_not_matching_to_skill_list_path',
          component: OffersNotMatchingToSkillList,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.list_of_offers'
          }
        },
        {
          path: '',
          name: 'offers_path',
          component: OffersIndex,
          meta: {
            auth: true,
            roles: ['student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.search'
          }
        },
        {
          path: 'my_applications',
          name: 'applications_offers_path',
          component: ApplicationsOffers,
          meta: {
            auth: true,
            roles: ['student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.my_submissions'
          }
        },
        {
          path: ':student_id/my_applications',
          name: 'student_applications_offers_path',
          component: ApplicationsOffers,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.my_submissions'
          }
        },
        {
          path: 'favorites',
          name: 'favorite_offers_path',
          component: FavoriteOffersIndex,
          meta: {
            auth: true,
            roles: ['student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.favorites'
          }
        },
        {
          path: 'new',
          name: 'offers_new_path',
          component: OffersNew,
          meta: {
            auth: true,
            roles: ['career_office', 'employer', 'recruiter'],
            allowed: acceptedCompany(),
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_offer'
          }
        },
        {
          path: 'invitations',
          name: 'offers_invitations_path',
          component: OfferInvitationsList,
          meta: {
            auth: true,
            roles: ['student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.invitations'
          },
          children: [
            {
              path: '',
              name: 'offer_invitations_list_all_tab_path',
              component: OfferInvitationsAllTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate'],
                navigationNameTranslationPath: 'views.offer_invitations.offer_invitations_list.all'
              }
            },
            {
              path: 'current',
              name: 'offer_invitations_list_current_tab_path',
              component: OfferInvitationsCurrentTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate'],
                navigationNameTranslationPath: 'views.offer_invitations.offer_invitations_list.current'
              }
            },
            {
              path: 'archived',
              name: 'offer_invitations_list_archived_tab_path',
              component: OfferInvitationsArchivedTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate'],
                navigationNameTranslationPath: 'views.offer_invitations.offer_invitations_list.archived'
              }
            }
          ]
        },
        {
          path: ':id',
          name: 'offers_show_path',
          component: OffersShow,
          meta: { auth: true }
        },
        {
          path: 'employer/:id',
          name: 'offers_employer_show_path',
          component: OffersEmployerTabs,
          meta: { auth: true }
        },
        {
          path: ':id/edit',
          name: 'offers_edit_path',
          component: OffersEdit,
          meta: {
            auth: true,
            roles: ['career_office', 'employer', 'recruiter'],
            navigationNameTranslationPath: 'views.offers.offers_edit.subtitle'
          }
        },
        {
          path: ':offerId/similar_offers',
          name: 'offers_list_similar_path',
          component: OffersListSimilar,
          meta: {
            auth: true,
            roles: ['career_office', 'employer', 'recruiter', 'student', 'graduate'],
            navigationNameTranslationPath: 'views.offers.offers_list_similar.title'
          }
        }
      ]
    },
    {
      path: '/messages',
      component: Messages,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.messages'
      },
      children: [
        {
          path: '',
          name: 'messages_index_path',
          component: MessagesIndex,
          meta: { auth: true, roles: ['career_office'] },
          children: [
            {
              path: 'group',
              name: 'messages_index_group_path',
              component: GroupMessagesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.group_messages'
              }
            },
            {
              path: 'draft',
              name: 'messages_index_draft_path',
              component: DraftMessagesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.draft_messages'
              }
            }
          ]
        },
        {
          path: 'new',
          name: 'messages_new_path',
          component: MessagesNew,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_message'
          }
        },
        {
          path: ':id/edit',
          name: 'messages_edit_path',
          component: MessagesEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.messages.messages_new.subtitle'
          }
        },
        {
          path: ':id/copy',
          name: 'messages_copy_path',
          component: MessagesCopy,
          meta: {
            auth: true,
            roles: ['career_office']
          }
        }
      ]
    },
    {
      path: '/recipient_groups',
      component: RecipientGroups,
      meta: {
        auth: true,
        navigationNameTranslationPath: [
          'components.abk_navigation_menu.recipient_groups',
          'components.abk_navigation_menu.messages'
        ]
      },
      children: [
        {
          path: '',
          name: 'recipient_groups_index_path',
          component: RecipientGroupsIndex,
          meta: { auth: true, roles: ['career_office'] }
        }
      ]
    },
    {
      path: '/communiques',
      component: UserMessages,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.communiques'
      },
      children: [
        {
          path: '',
          name: 'user_messages_index_path',
          component: UserMessagesIndex,
          meta: { auth: true, roles: ['student', 'graduate', 'employer', 'recruiter'] }
        },
        {
          path: ':id',
          name: 'user_messages_show_path',
          component: UserMessagesShow,
          meta: { auth: true, roles: ['student', 'graduate', 'employer', 'recruiter'] }
        }
      ]
    },
    {
      path: '/offer_filters',
      component: OfferFilters,
      meta: {
        auth: true,
        navigationNameTranslationPath: [
          'components.abk_navigation_menu.observed_results',
          'components.abk_navigation_menu.catalog_of_offers'
        ]
      },
      children: [
        {
          path: '',
          name: 'offer_filters_path',
          component: OfferFiltersIndex,
          meta: { auth: true, roles: ['student', 'graduate'] }
        }
      ]
    },
    {
      path: '/webpages',
      component: Webpages,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.cms'
      },
      children: [
        {
          path: '',
          name: 'webpages_path',
          component: WebpagesIndex,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.list_of_pages'
          }
        },
        {
          path: 'new',
          name: 'webpages_new_path',
          component: WebpagesNew,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_page'
          }
        },
        {
          path: ':id/edit',
          name: 'webpages_edit_path',
          component: WebpagesEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.webpages.webpages_edit.subtitle'
          }
        }
      ]
    },
    {
      path: '/news',
      component: News,
      meta: {
        auth: true,
        roles: ['career_office'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.news'
      },
      children: [
        {
          path: 'new',
          name: 'news_new_path',
          component: NewsNew,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_news'
          }
        },
        {
          path: 'news_list',
          name: 'news_list_path',
          component: NewsIndex,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.news_index'
          }
        },
        {
          path: ':id/edit',
          name: 'news_edit_path',
          component: NewsEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.news.news_edit.subtitle'
          }
        }
      ]
    },
    {
      path: '/news',
      component: News,
      children: [
        {
          path: '/news',
          name: 'news_path',
          component: NewsList,
          meta: {
            auth: true,
            roles: ['employer', 'recruiter', 'student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.list_of_news'
          }
        }
      ]
    },
    {
      path: '/web',
      component: Webpages,
      children: [
        {
          path: ':id',
          name: 'webpages_show_path',
          component: WebpagesShow
        }
      ]
    },
    {
      path: '/dictionaries',
      component: Dictionaries,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.dictionaries' },
      children: [
        {
          path: '',
          name: 'dictionaries_path',
          component: DictionariesIndex,
          meta: { auth: true, roles: ['career_office'] }
        },
        {
          path: ':id/edit',
          name: 'dictionaries_edit_path',
          component: DictionariesEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.dictionaries.edit.subtitle'
          }
        },
        {
          path: ':dictionaryId/dictionary_words',
          component: DictionaryWords,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.dictionary_words.index.title'
          },
          children: [
            {
              path: '',
              component: DictionaryWordsTabs,
              meta: { auth: true, roles: ['career_office'] },
              children: [
                {
                  path: '',
                  name: 'dictionary_words_path',
                  component: DictionaryWordsIndex,
                  meta: {
                    auth: true,
                    roles: ['career_office'],
                    navigationNameTranslationPath: 'views.dictionary_words.index.all'
                  }
                },
                {
                  path: 'recent',
                  name: 'dictionary_words_recent_path',
                  component: DictionaryWordsRecent,
                  meta: {
                    auth: true,
                    roles: ['career_office'],
                    navigationNameTranslationPath: 'views.dictionary_words.index.recent'
                  }
                },
                {
                  path: 'accepted',
                  name: 'dictionary_words_accepted_path',
                  component: DictionaryWordsIndex,
                  meta: {
                    auth: true,
                    roles: ['career_office'],
                    navigationNameTranslationPath: 'views.dictionary_words.index.accepted'
                  },
                  props: { status: 'accepted' }
                },
                {
                  path: 'rejected',
                  name: 'dictionary_words_rejected_path',
                  component: DictionaryWordsIndex,
                  meta: {
                    auth: true,
                    roles: ['career_office'],
                    navigationNameTranslationPath: 'views.dictionary_words.index.rejected'
                  },
                  props: { status: 'rejected' }
                }
              ]
            },
            {
              path: 'new',
              name: 'dictionary_words_new_path',
              component: DictionaryWordsNew,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.dictionary_words.new.subtitle'
              }
            },
            {
              path: ':id/edit',
              name: 'dictionary_words_edit_path',
              component: DictionaryWordsEdit,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.dictionary_words.edit.subtitle'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/competences',
      component: Competences,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.competences'
      },
      children: [
        {
          path: 'list',
          component: CompetencesList,
          meta: {
            auth: true,
            roles: ['career_office']
          },
          children: [
            {
              path: '',
              name: 'competences_list_all_tab_path',
              component: AllCompetencesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.competences_list'
              }
            },
            {
              path: 'connected_with_skills',
              name: 'competences_list_connected_with_skills_tab_path',
              component: CompetencesConnectedWithSkillsTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.competences_connected_with_skills'
              }
            },
            {
              path: 'not_connected_with_skills',
              name: 'competences_list_not_connected_with_skills_tab_path',
              component: CompetencesNotConnectedWithSkillsTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.competences_not_connected_with_skills'
              }
            }
          ]
        },
        {
          path: 'skills',
          component: CompetenceSkills,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.skills'
          },
          children: [
            {
              path: '',
              name: 'competences_skills_index_path',
              component: CompetenceSkillsIndex,
              meta: { auth: true, roles: ['career_office'] }
            },
            {
              path: 'edit',
              name: 'competences_skills_edit_path',
              component: CompetenceSkillsEdit,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.competence_skills.edit.subtitle'
              }
            }
          ]
        },
        {
          path: 'new',
          name: 'competences_new_path',
          component: CompetencesNew,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.competences.new.subtitle'
          }
        },
        {
          path: ':id/edit',
          name: 'competences_edit_path',
          component: CompetencesEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.competences.edit.subtitle'
          }
        }
      ]
    },
    {
      path: '/skills',
      component: Skills,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.skills'
      },
      children: [
        {
          path: 'list',
          component: SkillsList,
          meta: { auth: true, roles: ['career_office'] },
          children: [
            {
              path: '',
              name: 'skills_list_all_tab_path',
              component: AllSkillsTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.skills_list'
              }
            },
            {
              path: 'connected_with_competences',
              name: 'skills_list_connected_with_competences_tab_path',
              component: SkillsConnectedWithCompetencesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.skills_connected_with_competences'
              }
            },
            {
              path: 'not_connected_with_competences',
              name: 'skills_list_not_connected_with_competences_tab_path',
              component: SkillsNotConnectedWithCompetencesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.skills_not_connected_with_competences'
              }
            }
          ]
        },
        {
          path: 'competences',
          name: 'skill_competences_index_path',
          component: SkillCompetencesIndex,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.competences'
          }
        },
        {
          path: ':id/report',
          name: 'skills_report_path',
          component: SkillsReport,
          meta: {
            auth: true,
            roles: ['career_office']
          }
        }
      ]
    },
    {
      path: '/dictionary_acceptances',
      component: DictionaryAcceptances,
      meta: {
        auth: true,
        navigationNameTranslationPath: [
          'components.abk_navigation_menu.dictionaries_of_consents',
          'components.abk_navigation_menu.dictionaries'
        ]
      },
      children: [
        {
          path: '',
          name: 'dictionary_acceptances_path',
          component: DictionaryAcceptancesIndex,
          meta: { auth: true, roles: ['career_office'] }
        },
        {
          path: ':id/edit',
          name: 'dictionary_acceptances_edit_path',
          component: DictionaryAcceptancesEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.dictionaries.edit.subtitle'
          }
        },
        {
          path: ':dictionaryId/dictionary_acceptance_words',
          component: DictionaryAcceptanceWords,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.dictionary_acceptance_words.index.title'
          },
          children: [
            {
              path: '',
              name: 'dictionary_acceptance_words_path',
              component: DictionaryAcceptanceWordsIndex,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.dictionary_acceptance_words.index.title'
              }
            },
            {
              path: 'new',
              name: 'dictionary_acceptance_words_new_path',
              component: DictionaryAcceptanceWordsNew,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.dictionary_acceptance_words.new.subtitle'
              }
            },
            {
              path: ':id/edit',
              name: 'dictionary_acceptance_words_edit_path',
              component: DictionaryAcceptanceWordsEdit,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'views.dictionary_acceptance_words.edit.subtitle'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/curriculum_vitae',
      component: CurriculumVitaes,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.cv'
      },
      children: [
        {
          path: '',
          name: 'curriculum_vitae_my_show_path',
          component: CurriculumVitaeShow,
          meta: {
            auth: true,
            roles: ['student', 'graduate', 'employer', 'recruiter'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.my_cv'
          }
        },
        {
          path: 'edit',
          name: 'curriculum_vitae_edit_path',
          component: CurriculumVitaeEdit,
          meta: {
            auth: true,
            roles: ['student', 'graduate'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.edit'
          }
        },
        // Zakomentowane przez RODO
        // {
        //   path: ':id/edit',
        //   name: 'student_curriculum_vitae_edit_path',
        //   component: CurriculumVitaeEdit,
        //   meta: {
        //     auth: true,
        //     roles: ['career_office'],
        //     navigationNameTranslationPath: 'components.abk_navigation_menu.edit'
        //   }
        // },
        {
          path: ':id',
          name: 'curriculum_vitae_show_path',
          component: CurriculumVitaeShow,
          meta: { auth: true, roles: ['student', 'graduate', 'employer', 'recruiter', 'career_office'] }
        }
      ]
    },
    {
      path: '/dev_login',
      name: 'dev_sessions_path',
      component: DevSessionsIndex,
      meta: { env: 'development' }
    },
    {
      path: '/register_user',
      name: 'register_user_path',
      component: RegisterUser,
      meta: { auth: false, navigationNameTranslationPath: 'components.abk_navigation_menu.register_user' }
    },
    {
      path: '/login_user',
      name: 'login_user_path',
      component: LoginUser,
      meta: { auth: false, navigationNameTranslationPath: 'components.abk_navigation_menu.login_user' }
    },
    {
      path: '/email_confirmation/:token',
      name: 'email_confirmation_path',
      component: EmailConfirmation,
      meta: { auth: false, navigationNameTranslationPath: 'components.abk_navigation_menu.email_confirmation' }
    },
    {
      path: '/reset_password',
      name: 'reset_password_path',
      component: ResetPassword,
      meta: { auth: false, navigationNameTranslationPath: 'components.abk_navigation_menu.reset_password' }
    },
    {
      path: '/new_password',
      name: 'new_password_path',
      component: NewPassword,
      meta: { auth: false, navigationNameTranslationPath: 'components.abk_navigation_menu.new_password' }
    },
    {
      path: '/companies',
      component: Companies,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.employer_list' },
      children: [
        {
          path: '',
          name: 'companies_index_path',
          component: CompaniesIndex,
          meta: {
            auth: true,
            roles: ['career_office']
          },
          children: [
            {
              path: 'for_acceptance',
              name: 'companies_index_for_acceptance_tab_path',
              component: ForAcceptanceCompaniesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.for_acceptance_companies'
              }
            },
            {
              path: 'accepted',
              name: 'companies_index_accepted_tab_path',
              component: AcceptedCompaniesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.accepted_companies'
              }
            },
            {
              path: 'rejected',
              name: 'companies_index_rejected_tab_path',
              component: RejectedCompaniesTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.rejected_companies'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/company',
      name: 'company_path',
      component: Companies,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.firm'
      },
      children: [
        {
          path: 'profile',
          name: 'profile_company_show_path',
          component: CompanyShow,
          meta: {
            auth: true,
            navigationNameTranslationPath: 'components.abk_navigation_menu.profile'
          }
        },
        {
          path: 'edit',
          name: 'company_edit_path',
          component: CompanyEdit,
          meta: {
            auth: true,
            roles: ['employer', 'recruiter'],
            navigationNameTranslationPath: 'views.edit_company_profile.subtitle'
          }
        },
        {
          path: ':id',
          name: 'company_show_path',
          component: CompanyShow,
          meta: { auth: true }
        }
      ]
    },
    {
      path: '/events',
      component: Events,
      meta: {
        auth: true,
        navigationNameTranslationPath: 'components.abk_navigation_menu.events'
      },
      children: [
        {
          path: 'new',
          name: 'events_new_path',
          component: EventsNew,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter'],
            allowed: acceptedCompany(),
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_event'
          }
        },
        {
          path: 'list',
          component: EventsList,
          meta: { auth: true, roles: ['career_office', 'counselor', 'employer', 'recruiter', 'student', 'graduate'] },
          children: [
            {
              path: '',
              name: 'events_list_all_tab_path',
              component: AllEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor', 'employer', 'recruiter', 'student', 'graduate'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.events_list'
              }
            },
            {
              path: 'current',
              name: 'events_list_current_tab_path',
              component: CurrentEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor', 'employer', 'recruiter', 'student', 'graduate'],
                navigationNameTranslationPath: 'views.events.events_list.current'
              }
            },
            {
              path: 'archived',
              name: 'events_list_archived_tab_path',
              component: ArchivedEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor', 'employer', 'recruiter', 'student', 'graduate'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.archived_events'
              }
            },
            {
              path: 'registered',
              name: 'events_list_registered_tab_path',
              component: RegisteredEventsTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.registered_events'
              }
            },
            {
              path: 'assigned',
              name: 'events_list_assigned_tab_path',
              component: AssignedEventsTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.assigned_events'
              }
            },
            {
              path: 'pending',
              name: 'events_list_pending_tab_path',
              component: PendingEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.pending_events'
              }
            },
            {
              path: ':student_id',
              name: 'student_events_list_all_tab_path',
              component: EventsStudentRegistered,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.events_list'
              }
            },
            {
              path: ':student_id/current',
              name: 'student_events_list_current_tab_path',
              component: CurrentEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'views.events.events_list.current'
              }
            },
            {
              path: ':student_id/archived',
              name: 'student_events_list_archived_tab_path',
              component: ArchivedEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.archived_events'
              }
            },
            {
              path: ':student_id/registered',
              name: 'student_events_list_registered_tab_path',
              component: RegisteredEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.registered_events'
              }
            },
            {
              path: ':student_id/assigned',
              name: 'student_events_list_assigned_tab_path',
              component: AssignedEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.assigned_events'
              }
            },
            {
              path: ':student_id/pending',
              name: 'student_events_list_pending_tab_path',
              component: PendingEventsTab,
              meta: {
                auth: true,
                roles: ['career_office', 'counselor'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.pending_events'
              }
            }
          ]
        },
        {
          path: ':id',
          name: 'events_show_path',
          component: EventsShow,
          meta: { auth: true }
        },
        {
          path: ':id/sub_event',
          name: 'sub_events_show_path',
          component: EventsShow,
          meta: { auth: true }
        },
        {
          path: ':id/edit',
          name: 'events_edit_path',
          component: EventsEdit,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter'],
            navigationNameTranslationPath: 'views.events.events_edit.subtitle'
          }
        },
        {
          path: ':eventId/similar_events',
          name: 'events_list_similar_path',
          component: EventsListSimilar,
          meta: {
            auth: true,
            roles: ['career_office', 'counselor', 'employer', 'recruiter', 'student', 'graduate'],
            navigationNameTranslationPath: 'views.events.events_list_similar.title'
          }
        }
      ]
    },
    {
      path: '/students',
      component: Students,
      meta: { auth: true, navigationNameTranslationPath: 'components.abk_navigation_menu.student_list' },
      children: [
        {
          path: '/students',
          name: 'students_index_path',
          component: StudentsIndex,
          meta: {
            auth: true,
            roles: ['career_office']
          },
          children: []
        }
      ]
    },
    {
      path: '/surveys',
      component: Surveys,
      meta: { navigationNameTranslationPath: 'components.abk_navigation_menu.surveys' },
      children: [
        {
          path: 'list',
          component: SurveysList,
          meta: { auth: true, roles: ['career_office', 'student', 'graduate', 'employer', 'recruiter'] },
          children: [
            {
              path: 'active',
              name: 'surveys_list_active_tab_path',
              component: ActiveSurveysTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.active'
              }
            },
            {
              path: 'inactive',
              name: 'surveys_list_inactive_tab_path',
              component: InactiveSurveysTab,
              meta: {
                auth: true,
                roles: ['career_office'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.inactive'
              }
            },
            {
              path: 'recent',
              name: 'surveys_list_recent_tab_path',
              component: RecentSurveysTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate', 'employer', 'recruiter'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.recent'
              }
            },
            {
              path: 'completed',
              name: 'surveys_list_completed_tab_path',
              component: CompletedSurveysTab,
              meta: {
                auth: true,
                roles: ['student', 'graduate', 'employer', 'recruiter'],
                navigationNameTranslationPath: 'components.abk_navigation_menu.completed'
              }
            }
          ]
        },
        {
          path: 'answers/:id',
          name: 'answers_show_path',
          component: AnswersShow,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.surveys.answers.answers_index.subtitle'
          }
        },
        {
          path: 'new',
          name: 'surveys_new_path',
          component: SurveysNew,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'components.abk_navigation_menu.new_survey'
          }
        },
        {
          path: ':id',
          name: 'surveys_show_path',
          component: SurveysShow
        },
        {
          path: ':id/preview',
          name: 'surveys_preview_path',
          component: SurveysShow,
          meta: { auth: true, roles: ['career_office'] },
          props: { validate: true }
        },
        {
          path: ':id/answers',
          name: 'answers_index_path',
          component: AnswersIndex,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.surveys.answers.answers_index.subtitle'
          }
        },
        {
          path: ':id/edit',
          name: 'surveys_edit_path',
          component: SurveysEdit,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.surveys.surveys_edit.subtitle'
          }
        },
        {
          path: ':id/stats',
          name: 'surveys_stats_path',
          component: SurveysStats,
          meta: {
            auth: true,
            roles: ['career_office'],
            navigationNameTranslationPath: 'views.surveys.surveys_stats.subtitle'
          }
        },
        {
          path: ':id/params',
          name: 'surveys_params_path',
          component: SurveysParams,
          meta: { auth: true, roles: ['career_office'] }
        }
      ]
    },
    {
      path: '/users_list',
      name: 'users_list_path',
      component: UsersList,
      meta: {
        auth: true,
        roles: ['administrator'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.users_list'
      }
    },
    {
      path: '/removed_users_list',
      name: 'removed_users_list_path',
      component: RemovedUsersList,
      meta: {
        auth: true,
        roles: ['administrator'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.removed_users_list'
      }
    },
    {
      path: '/student_notes/:id',
      name: 'student_notes_path',
      component: StudentNotes,
      meta: {
        auth: true,
        roles: ['career_office'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.student_notes'
      }
    },
    {
      path: '/student_notes/:id/new',
      name: 'student_notes_new_path',
      component: StudentNotesNew,
      meta: {
        auth: true,
        roles: ['career_office'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.student_notes_new'
      }
    },
    {
      path: '/student_notes/:id/edit',
      name: 'student_notes_edit_path',
      component: StudentNotesEdit,
      meta: {
        auth: true,
        roles: ['career_office'],
        navigationNameTranslationPath: 'components.abk_navigation_menu.student_notes_edit'
      }
    },
    {
      path: '*',
      name: 'page_not_found_path',
      component: PageNotFound
    }
  ],
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    const result = qs.stringify(query, { arrayFormat: 'brackets' })
    return result ? ('?' + result) : ''
  }
})

router.beforeEach((to, from, next) => {
  const meta = (typeof to.meta === 'function') ? to.meta() : to.meta
  if (meta.allowed === false || meta.block) {
    return next(false)
  }
  if (to.name !== from.name) {
    store.dispatch('header/clearHeader')
    store.dispatch('pageCover/clearBackgroundImage')
    store.dispatch('pageCover/clearBackgroundImageStyle')
    store.dispatch('pageCover/clearBackgroundImageClasses')
    store.dispatch('sidebarMenu/clearSidebarMenuClasses')
    store.dispatch('footerNavbar/clearItems')

    setTimeout(() => {
      const navigationPath = _.compact(to.matched.slice().reverse().map(route => {
        const translationPath = route.meta.navigationNameTranslationPath

        if (Array.isArray(translationPath)) {
          return translationPath.map(path => app ? app.$t(path) : i18n.t(path)).join(' | ')
        }
        return app ? app.$t(translationPath) : i18n.t(translationPath)
      }))
      store.dispatch('header/clearObjectName')
      store.dispatch('header/setNavigationPath', navigationPath)
    }, 0)

    const userRoles = store.getters['auth/roles']

    if (
      store.getters['auth/signedIn'] &&
      store.getters['auth/currentRole'] &&
      userRoles && userRoles.length > 0 &&
      !['root_path', 'login_path', 'role_choose_path', 'openid_login_path'].includes(to.name)
    ) {
      store.dispatch('user/getDictionaryWordsCounters')
      store.dispatch('notifications/getAllNotifications')
    }
  }

  if (to && to.meta.env) {
    if (process.env.RAILS_ENV === to.meta.env) {
      return next()
    } else {
      return next({ name: 'login_path' })
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters['auth/signedIn']) {
      if (to.meta.roles) {
        const userRole = store.getters['auth/currentRole']
        if (to.meta.roles.includes(userRole)) {
          next()
        } else {
          // TODO duplicated in api axios interceptors, extract this
          if (['student', 'graduate'].includes(userRole)) {
            next({ name: 'student_dashboard_path' })
          } else if (userRole === 'employer' || userRole === 'recruiter') {
            next({ name: 'employer_dashboard_path' })
          } else if (userRole === 'career_office') {
            next({ name: 'career_office_dashboard_path' })
          } else if (userRole === 'counselor') {
            next({ name: 'events_list_all_tab_path' })
          } else {
            next({ name: 'root_path' })
          }
        }
      } else {
        next()
      }
    } else {
      const redirectTo = {
        name: to.name, params: to.params, query: to.query
      }
      store.dispatch('auth/setRedirectTo', redirectTo)
      next({ name: 'login_path' })
    }
  } else {
    const userRole = store.getters['auth/currentRole']
    if (store.getters['auth/signedIn'] & ['login_path', 'root_path'].includes(to.name)) {
      if (['student', 'graduate'].includes(userRole)) {
        next({ name: 'student_dashboard_path' })
      } else if (userRole === 'employer' || userRole === 'recruiter') {
        next({ name: 'employer_dashboard_path' })
      } else if (userRole === 'career_office') {
        next({ name: 'career_office_dashboard_path' })
      } else if (userRole === 'counselor') {
        next({ name: 'events_list_all_tab_path' })
      }
    }
    next()
  }
})

export default router
