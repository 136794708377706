<template>
  <b-row>
    <b-col class="col-12 mb-4">
      <pcg-btn @click="backToIndex" size="small" class="ml-0">
        &lt; {{ $t('general.back') }}
      </pcg-btn>
    </b-col>
    <b-col class="col-12">
      <abk-multi-select-maping :left-label="$t('views.competence_skills.labels.competences')"
                               :right-label="$t('views.competence_skills.labels.skills')"
                               :selected-left="selectedCompetences"
                               :selected-right="selectedSkills"
                               :left-search-placeholder="$t('views.competence_skills.edit.competences_filter_placeholer')"
                               :right-search-placeholder="$t('views.competence_skills.edit.skills_filter_placeholer')"
                               @selectedLeft="CompetenceChanged"
                               @selectedRight="selectedSkills = $event"
                               :left-options="competencesOptions"
                               :right-options="skillsOptions"/>
    </b-col>
    <b-col class="col-12">
      <pcg-btn @click="save" :disabled="selectedCompetences.length === 0">{{ $t('views.competence_skills.edit.save') }}</pcg-btn>
      <pcg-btn @click="clear" variant="additional">{{ $t('views.competence_skills.edit.clear') }}</pcg-btn>
    </b-col>
  </b-row>
</template>

<script>
import ApiCompetences from '../../api/competences'
import ApiSkills from '../../api/skills'
import ApiCompetenceSkills from '../../api/competence_skills'
import AbkMultiSelectMaping from '../../components/AbkMultiSelectMaping'
export default {
  name: 'CompetenceSkillsEdit',
  components: { AbkMultiSelectMaping },
  data () {
    return {
      selectedCompetences: [],
      selectedSkills: [],
      competencesOptions: [],
      skillsOptions: []
    }
  },
  mounted () {
    this.getCompetences()
    this.getSkills()
  },
  methods: {
    clear () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.clear'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          this.selectedSkills = []
        }
      })
    },
    save () {
      ApiCompetenceSkills.createCompetenceSkills({
        competence_skills: {
          competencesIds: this.selectedCompetences,
          skillsIds: this.selectedSkills
        }
      })
        .then(response => {
          this.$toastr.s(this.$t('views.competence_skills.edit.connections_created'))
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    CompetenceChanged (competences) {
      this.selectedCompetences = competences
      this.getCompetenceSkills(this.selectedCompetences)
    },
    getCompetenceSkills (competences) {
      const competenceId = competences[0]
      if (!competenceId) {
        return
      }
      ApiCompetences.getCompetenceSkills(competenceId)
        .then(response => {
          this.selectedSkills = response.data.data.map(skill => skill.id)
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    getCompetences () {
      ApiCompetences.getAllCompetences()
        .then(response => {
          this.competencesOptions = response.data.data.map((competence) => {
            return {
              id: competence.id,
              name: competence.attributes.name
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    getSkills () {
      ApiSkills.getAllSkills()
        .then(response => {
          this.skillsOptions = response.data.data.map((skill) => {
            return {
              id: skill.id,
              name: skill.attributes.name
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.competence_skills.edit.title'),
        subtitle: this.$t('views.competence_skills.edit.subtitle')
      })
    },
    backToIndex () {
      this.$router.push({ name: 'competences_skills_index_path' })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
