<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-chart"></i> {{ $t('components.skills.abk_matching_to_labor_market_box.title') }}
    </div>
    <b-row>
      <b-col cols="12" lg="5" class="d-flex justify-content-around align-items-center">
        <abk-match-indicator :bars-count="barsCount" :degree="skillDegree" :degrees-texts="degreesTexts"/>
        <router-link :to="{ name: 'offers_matching_to_skill_list_path', params: { skillId: skillId } }"
                     class="matching-offers-container pcg-link">
          <div class="matching-offers-count">{{ matchingOffersCount }}</div>
          <div class="matching-offers-text">
            {{ $tc('components.skills.abk_matching_to_labor_market_box.matching_offers', matchingOffersCount) }}
          </div>
        </router-link>
      </b-col>
      <b-col cols="12" lg="3" class="angle-container">
        <i class="fas fa-angle-right fa-5x"></i>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="info-text">
          {{ $t('components.skills.abk_matching_to_labor_market_box.increasing_competences_text') }}
        </div>
        <div v-for="competence in missingCompetences" :key="competence.id" class="competence-container">
          <div class="competence-name">{{ competence.name }}</div>
          <router-link :to="getOffersNotMatchingToSkillByCompetenceListPath(competence.id)" class="pcg-link">
            {{ $tc('components.skills.abk_matching_to_labor_market_box.offers_count', competence.offersCount) }}
          </router-link>
        </div>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import AbkMatchIndicator from '../AbkMatchIndicator'
export default {
  name: 'AbkMatchingToLaborMarketBox',
  components: { AbkMatchIndicator },
  props: {
    skillId: [String, Number],
    skillDegree: Number,
    matchingOffersCount: Number,
    missingCompetences: Array
  },
  data () {
    return {
      barsCount: 5,
      degreesTexts: this.$t('components.skills.abk_matching_to_labor_market_box.degrees_texts')
    }
  },
  methods: {
    getOffersNotMatchingToSkillByCompetenceListPath (competenceId) {
      return {
        name: 'offers_not_matching_to_skill_list_path',
        params: {
          skillId: this.skillId,
          competenceId: competenceId
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .matching-offers-container {
    text-align: center;
    margin-left: 20%;
  }
  .matching-offers-count {
    font-size: $font-size-xl;
  }
  .matching-offers-text {
    margin-top: $pcg-m-md;
    font-weight: 500;
  }
  .info-text {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 500;
  }
  .fa-angle-right {
    transform: scaleY(2.5);
    text-shadow: 3px 0 2px $pcg-lightest-gray;
    color: $pcg-white;
  }
  .competence-container {
    margin-top: $pcg-m-sm;
    font-weight: 500;
  }
  .competence-name {
    color: $main-color;
  }
  .angle-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: $screen-lg-min) {
    .fa-angle-right {
      transform: rotate(90deg);
    }
    .angle-container {
      max-height: 80px;
    }
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .matching-offers-container {
      color: $main-active-color;
    }
    .info-text {
      color: $pcg-gray;
    }
    .fa-angle-right {
      text-shadow: 3px 0 2px $pcg-lightest-gray;
      color: $pcg-white;
    }
    .competence-name {
      color: $main-color;
    }
  }
</style>
