<template>
  <div class="carousel-container">
    <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="0"
        controls
        background="transparent">
      <b-carousel-slide v-for="(slide, index) in slides(images, 4)" :key="`slide-${index}`">
        <div class="gallery" slot="img">
          <div v-b-modal.modal-center
               v-for="image in slide"
               @click="imageToShow = image.url"
               class="gallery-image-wrapper"
               :style="{ backgroundImage: `url(${image.url})` }"
               :key="image.id">
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
    <b-modal :body-class="['p-0', 'modal-body']"
             id="modal-center"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
             centered
             hide-footer
             hide-header>
      <img class="full-image" :src="imageToShow" alt="">
    </b-modal>
  </div>
</template>

<script>
import chunk from 'lodash.chunk'

export default {
  name: 'AbkCarousel',
  props: {
    images: Array
  },
  data () {
    return {
      slide: 0,
      imageToShow: null
    }
  },
  methods: {
    slides (array, size) {
      return chunk(array, size)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .carousel-container {
    /deep/ .carousel-control-prev, /deep/ .carousel-control-next {
      width: fit-content;
      padding: $font-size-xs;
    }
  }
  /deep/ .modal-dialog {
    width: fit-content!important;
  }
  .gallery {
    display: flex;
  }
  .gallery-image-wrapper {
    width: 22.1%;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &:not(:first-child) {
      margin-left: 3.87%;
    }

    &:before {
      content: " ";
      display: block;
      padding-top: 100%;
    }
  }
  /deep/ .modal-dialog, .full-image {
    max-width: 90vw;
  }

  @media(max-width: $screen-sm-min) {
    /deep/ .modal-dialog {
      margin: auto!important;
    }
  }
</style>
