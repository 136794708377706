<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-info"></i>{{ $t('components.abk_quick_info.title') }}
    </div>
    <template v-for="property in properties">
      <div v-if="formattedProperty(property, offerInfo[property])" class="property-container" :key="property">
        <span class="pcg-txt-small-bold property">
          {{ $t(`components.abk_quick_info.${decamelizeString(property)}`) }}
        </span>
        <br/>
        <span class="pcg-txt-small-bold value">{{ formattedProperty(property, offerInfo[property]) }}</span>
      </div>
    </template>
    <div v-if="offerInfo && offerInfo.tags" class="pcg-txt-small-bold tags">{{ offerInfo.tags.join(', ') }}</div>
  </pcg-box>
</template>

<script>
import { decamelize } from '@ridi/object-case-converter'

export default {
  name: 'AbkQuickInfo',
  props: {
    offerInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dictionaryProperties: ['workplace', 'positionType', 'typeOfWork', 'workDimension']
    }
  },
  methods: {
    decamelizeString (value) {
      return Object.keys(decamelize({ [value]: null }))[0]
    },
    formattedProperty (name, value) {
      if (value == null) { return null }
      if (name === 'expirationDate') { return this.formattedExpirationTime }
      if (name === 'publicationDate') { return this.formattedPublicationTime }
      if (this.dictionaryProperties.includes(name)) { return value.value }
      if (name === 'offerType') {
        return this.$te(`general.offer_type.${value}`) ? this.$t(`general.offer_type.${value}`) : value
      }

      return value
    }
  },
  computed: {
    properties () {
      return ['companyName', 'offerType', ...this.dictionaryProperties, 'freePlaces', 'publicationDate', 'expirationDate']
    },
    formattedExpirationTime () {
      const time = moment(this.offerInfo.expirationDate)
      return time.isValid() ? time.format('YYYY-MM-DD HH:mm') : null
    },
    formattedPublicationTime () {
      const time = moment(this.offerInfo.publicationDate)
      return time.isValid() ? time.format('YYYY-MM-DD HH:mm') : null
    },
    offerTimeLeft () {
      const time = moment(this.offerInfo.expirationDate)
      const now = new Date()

      if (time.isValid()) {
        const timeLeft = time.diff(now, 'days') + 1
        return timeLeft > 0 ? timeLeft : null
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .property-container {
    margin-bottom: $font-size-m;
    line-height: .7;
  }
  .property {
    color: $pcg-light-gray;
  }
  .value {
    color: $main-color;
  }
  .tags {
    color: $main-active-color;
    margin: $font-size-l 0;
  }
  .offer-time-left {
    color: $pcg-orange;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .property {
      color: $pcg-light-gray;
    }
    .value {
      color: $main-color;
    }
    .tags {
      color: $main-active-color;
    }
    .offer-time-left {
      color: $pcg-orange;
    }
  }
</style>
