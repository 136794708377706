const tmpIds = {
  namespaced: true,
  state: {
    tmpId: 1000000000,
  },
  getters: {
    tmpId: state => {
      return state.tmpId
    }
  },
  mutations: {
    increaseTmpId (state, image) {
      state.tmpId = state.tmpId + 1
    }
  },
  actions: {
    generateTmpId ({ commit, state }) {
      commit('increaseTmpId')
      return state.tmpId
    }
  }
}

export {
  tmpIds
}
