<template>
  <div>
    <b-tabs v-model="tab" @input="changeTab">
      <b-tab :title="$t('views.offer_invitations.offer_invitations_list.all')"/>
      <b-tab :title="$t('views.offer_invitations.offer_invitations_list.current')"/>
      <b-tab :title="$t('views.offer_invitations.offer_invitations_list.archived')"/>
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OfferInvitationsList',
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  data () {
    return {
      tab: 1,
      tabs: [
        'offer_invitations_list_all_tab_path',
        'offer_invitations_list_current_tab_path',
        'offer_invitations_list_archived_tab_path'
      ]
    }
  },
  mounted () {
    this.tab = this.tabs.indexOf(this.$route.name)
  },
  methods: {
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab })
      }
    }
  }
}
</script>
