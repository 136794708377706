<template>
  <div>
    <div style="margin-top: 3rem" v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else :class="backgroundImage.url ? 'company-with-image' : 'company'">
      <b-row>
        <div v-if="(showCareerOfficeActions) && isNotRecent"
             class="col-12 d-flex justify-content-center align-items-end mb-4">
          <pcg-btn size="small"
                   v-if="canBeAccepted"
                   variant="additional"
                   @click="updateCompanyStatus('accept')">
            {{ $t('general.accept') }}
          </pcg-btn>
          <pcg-btn size="small"
                   v-if="canBeRejected"
                   variant="additional"
                   @click="updateCompanyStatus('reject')">
            {{ $t('components.companies.abk_company_list.reject') }}
          </pcg-btn>
        </div>
      </b-row>
      <b-row>
        <abk-not-accepted-users v-if="(showCareerOfficeActions || (isEmployer && acceptedCompany)) && notAcceptedUsers.length > 0" :users="notAcceptedUsers" @userChanged="userChanged" class="col-12"></abk-not-accepted-users>
      </b-row>
      <b-row>
        <abk-current-offers :currentOffers="companyBasicInfo" class="current-offers" :company-id="realCompanyId"/>
      </b-row>
      <div v-if="companyBasicInfo.employerType !== 'lite'">
        <b-row v-for="section in textSections" :key="section.id">
          <abk-get-to-know-us :section="section"></abk-get-to-know-us>
        </b-row>
        <b-row v-for="gallery in galleries" :key="gallery.id">
          <pcg-box :header="gallery.name" class="col-12">
            <abk-carousel :images="gallery.images"></abk-carousel>
          </pcg-box>
        </b-row>
        <b-row style="margin-top: 2rem;">
          <b-col cols="12" v-for="video in videos" :key="video.id">
            <h2 class="video-title">{{ video.name }}</h2>
            <div style="margin-bottom: 2rem" v-for="section in video.subsections" :key="section.id">
              <lazy-youtube v-if="checkVideoType(section.content) === 'youtube'" maxWidth="10000px" :src="section.content" />
              <lazy-vimeo v-if="checkVideoType(section.content) === 'vimeo'" maxWidth="10000px" :src="section.content" />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <abk-actual-recrutations-box v-if="showCareerOfficeActions || acceptedCompany" :offers="actualRegistration" :offers-count="4" class="col-12 col-xl-6">
        </abk-actual-recrutations-box>
        <abk-contact :firmInfo="contact"></abk-contact>
      </b-row>
    </div>
  </div>
</template>

<script>

import AbkCurrentOffers from '../../components/AbkCurrentOffers'
import AbkGetToKnowUs from '../../components/AbkGetToKnowUs'
import AbkCarousel from '../../components/AbkCarousel'
import AbkActualRecrutationsBox from '../../components/AbkActualRecrutationsBox'
import AbkContact from '../../components/AbkContact'
import AbkNotAcceptedUsers from '../../components/AbkNotAcceptedUsers'
import ApiCompanies from '../../api/companies'
import { LazyYoutube, LazyVimeo } from 'vue-lazytube'
import { mapGetters } from 'vuex'
import AbkPageNotFound from '../../components/AbkPageNotFound'
export default {
  name: 'CompanyShow',
  components: { AbkPageNotFound, AbkContact, LazyYoutube, LazyVimeo, AbkActualRecrutationsBox, AbkCarousel, AbkGetToKnowUs, AbkCurrentOffers, AbkNotAcceptedUsers },
  data: function () {
    return {
      companyId: null,
      realCompanyId: null,
      companyStatus: null,
      backgroundImage: { url: null },
      companyBasicInfo: {
        name: null,
        shortDescription: null,
        logo: {
          url: null
        },
        offersCount: 0,
        facebook: null,
        twitter: null,
        linkedin: null
      },
      textSections: [],
      galleries: [],
      videos: [],
      actualRegistration: [],
      contact: {
        name: null,
        address: null,
        phone: null,
        email: null,
        website: null,
        facebook: null,
        twitter: null,
        linkedin: null
      },
      notAcceptedUsers: [],
      notFound: false
    }
  },
  mounted () {
    this.companyId = this.$route.params.id
    this.getCompany()
    this.getActualRegistration()
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['acceptedCompany']),
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    },
    isEmployer () {
      return this.currentRole === 'employer'
    },
    isNotRecent () {
      return this.companyStatus !== 'recent'
    },
    canBeAccepted () {
      return this.companyStatus === 'rejected' || this.companyStatus === 'saved'
    },
    canBeRejected () {
      return this.companyStatus === 'accepted' || this.companyStatus === 'saved'
    }
  },
  methods: {
    getCompany () {
      const action = this.companyId ? 'getCompany' : 'getMyCompany'
      ApiCompanies[action](this.companyId)
        .then(response => {
          const { attributes } = response.data.data
          const included = response.data.included
          this.realCompanyId = response.data.data.id
          this.companyStatus = response.data.data.attributes.status
          this.backgroundImage = attributes.backgroundImage
          this.setBackgroundImage()
          this.companyBasicInfo = {
            name: attributes.name,
            shortDescription: attributes.shortDescription,
            logo: attributes.logo,
            facebook: attributes.facebook,
            twitter: attributes.twitter,
            linkedin: attributes.linkedin,
            offersCount: attributes.offersCount,
            employerType: attributes.employerType,
            employerTypeText: attributes.employerTypeText,
            employerTypeLogo: attributes.employerTypeLogo,
            employerTypeLogoNegative: attributes.employerTypeLogoNegative
          }
          response.data.data.attributes.users.forEach(user => {
            if (user.roles.includes('recruiter')) {
              this.notAcceptedUsers.push(user)
            }
          })
          const text = included.filter(e => e.type === 'company_section' && e.attributes.sectionType === 'text')
          const gallery = included.filter(e => e.type === 'company_section' && e.attributes.sectionType === 'gallery')
          const video = included.filter(e => e.type === 'company_section' && e.attributes.sectionType === 'video')

          this.textSections = text.map(section => {
            return {
              id: section.id,
              name: section.attributes.name,
              subsections: section.relationships.companySubsections.data.map(relationship => {
                const subsectionObject = included.find(e => e.type === 'company_subsection' && e.id === relationship.id)
                return { id: subsectionObject.id, ...subsectionObject.attributes }
              })
            }
          })

          this.galleries = gallery.map(section => {
            return {
              id: section.id,
              name: section.attributes.name,
              images: section.relationships.companySubsections.data.map(relationship => {
                const subsectionObject = included.find(e => e.type === 'company_subsection' && e.id === relationship.id)
                const attachment = subsectionObject.attributes.attachment || {}
                return {
                  id: subsectionObject.id,
                  url: attachment.url
                }
              })
            }
          })

          this.videos = video.map(section => {
            return {
              id: section.id,
              name: section.attributes.name,
              subsections: section.relationships.companySubsections.data.map(relationship => {
                const subsectionObject = included.find(e => e.type === 'company_subsection' && e.id === relationship.id)
                return { id: subsectionObject.id, ...subsectionObject.attributes }
              })
            }
          })

          this.contact = {
            name: attributes.name,
            address: _.compact([attributes.address, attributes.city]).join(', '),
            email: attributes.email,
            phone: attributes.phone,
            website: attributes.website,
            facebook: attributes.facebook,
            linkedin: attributes.linkedin,
            twitter: attributes.twitter
          }

          if (this.$route.name !== 'profile_company_show_path') {
            this.$store.dispatch('header/setObjectName', this.companyBasicInfo.name)
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.notFound = true
          }
          console.log(error)
        })
    },
    getActualRegistration () {
      ApiCompanies.getActualRegistration(this.companyId)
        .then(response => {
          this.actualRegistration = response.data.data.map(offer => { return { id: offer.id, ...offer.attributes } })
        }).catch(error => {
          console.log(error)
        })
    },
    updateCompanyStatus (action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${action}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCompanies[`${action}Company`](this.realCompanyId)
            .then(() => {
              this.getCompany()
            }).catch(() => {
              this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
            })
        }
      })
    },
    setBackgroundImage () {
      this.$store.dispatch('pageCover/setBackgroundImage', this.backgroundImage.url)
    },
    userChanged (userId) {
      const index = this.notAcceptedUsers.findIndex(user => user.id === userId)
      if (index >= 0) {
        this.notAcceptedUsers[index].accepted = !this.notAcceptedUsers[index].accepted
      }
    },
    checkVideoType (url) {
      url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/)
      if (RegExp.$3.indexOf('youtu') > -1) {
        return 'youtube'
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        return 'vimeo'
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';
  .box-container {
    margin-bottom: $pcg-m-xxl;
  }

  .company {
    padding-top: 30px;
  }

  .company-with-image {
    padding-top: 200px;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 45px;
    }
  }

  .video-title {
    color: $main-color;
    font-size: 1.71em;
  }
</style>
