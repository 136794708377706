<template>
  <div class="container">
    <b-row class="top-bar align-items-center d-flex">
        <pcg-search class="col-10 col-xl-3 mb-0"
                    :placeholder="$t('general.search_word')"
                    v-model="wordPattern"
                    @searchEvent="getDictionaryAcceptanceWords"/>
      <b-col class="text-right col-2 col-xl-9">
        <pcg-btn size="small"
                 @click="$router.push({
               name: 'dictionary_acceptance_words_new_path',
               params: { dictionaryId: dictionaryId } })">
          + {{$t('views.dictionary_words.index.add')}}
        </pcg-btn>
      </b-col>
    </b-row>
    <abk-loader v-if="showLoader" class="with-margins"/>
    <pcg-table v-else :items="dictionaryWords" :fields="fields">
      <template v-slot:header>{{$t('views.dictionary_acceptance_words.index.table_header')}}</template>
      <template v-slot:cell(actions)="{ item }">
        <div class="d-flex align-items-center">
          <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                          class="my-0"
                          :tooltip="$t('general.edit')"
                          @click="goToEditPath(item.id)"/>
          <pcg-btn-symbol icon-class="pcg-icon-trash-can"
                          class="abk-delete-btn my-0"
                          :tooltip="$t('general.delete')"
                          @click="deleteDictionaryAcceptanceWords(item.id)"/>
        </div>
      </template>
      <template #cell(registration)="row">
        <span class="pcg-inline-block-switch">
            <pcg-switch class="mx-auto ml-sm-3"
                        :value="row.value"
                        @input="updateRegistration(row.item)"
            />
          </span>
      </template>
      <template #cell(registrationRequired)="row">
        <span class="pcg-inline-block-switch">
            <pcg-switch class="mx-auto ml-sm-3"
                        :value="row.value"
                        @input="updateRegistrationRequired(row.item)"
            />
          </span>
      </template>
      <template #cell(blockedOnProfile)="row">
        <span class="pcg-inline-block-switch">
            <pcg-switch class="mx-auto ml-sm-3"
                        :value="row.value"
                        @input="updateBlockedOnProfile(row.item)"
            />
          </span>
      </template>
    </pcg-table>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getDictionaryAcceptanceWords"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiDictionaryAcceptanceWords from '../../api/dictionary_acceptance_words'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
import ApiSubEvents from "../../api/sub_events";

export default {
  name: 'DictionaryAcceptanceWordsIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      dictionaryWords: [],
      fields: [],
      wordPattern: null
    }
  },
  mounted () {
    this.getDictionaryAcceptanceWords()
  },
  methods: {
    goToEditPath (wordId) {
      this.$router.push({
        name: 'dictionary_acceptance_words_edit_path',
        params: { dictionaryId: this.dictionaryId, id: wordId }
      })
    },
    getDictionaryAcceptanceWords () {
      ApiDictionaryAcceptanceWords.getDictionaryAcceptanceWords(this.dictionaryId, {
        page: this.pagination.currentPage,
        pattern: this.wordPattern
      })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaryWords = this.dataTable(response.data.data)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    deleteDictionaryAcceptanceWords (dictionaryWordId) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('views.dictionary_acceptance_words.index.delete_word'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiDictionaryAcceptanceWords.deleteDictionaryAcceptanceWord(this.dictionaryId, dictionaryWordId)
              .then(response => {
                this.dictionaryWords = this.dictionaryWords.filter((word) => word.hidden_id !== dictionaryWordId)
              }).catch(error => {
                this.$toastr.e(error)
                console.log(error)
              })
        }
      })
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptance_words.index.title'),
        subtitle: this.$t('views.dictionary_acceptance_words.index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach(dictionary => {
        const attributes = { id: dictionary.id, ...dictionary.attributes }
        Object.assign(attributes, { hidden_id: dictionary.id })
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'status') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${key}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.statuses.${value}`)
                }
              })
            } else if (key === 'type') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${key}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.types.${value}`)
                }
              })
            } else if (key === 'wordType') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.word_types.${value}`)
                }
              })
            } else if (key === 'registration') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`)
              })
            } else if (key === 'registrationRequired') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`),
                thClass: 'pl-1'
              })
            } else if (key === 'blockedOnProfile') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`),
                thClass: 'pl-1'
              })
            } else if (key === 'acceptanceType') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.acceptance_types.${value}`)
                }
              })
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_acceptance_words.labels.actions')
      })
      return fields
    },
    updateRegistration ({ id }) {
      this.loading = true
      const dictionaryWord = this.dictionaryWords.find(e => e.id === id)
      const payload = {
        dictionaryAcceptanceWord: {
          registration: !dictionaryWord.registration
        }
      }
      ApiDictionaryAcceptanceWords.updateDictionaryAcceptanceWord(this.dictionaryId, dictionaryWord.id, payload)
        .then(response => {
          this.errors = {}
          dictionaryWord.registration = !dictionaryWord.registration
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        })
        .catch(error => {
          this.handleErrors(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateRegistrationRequired ({ id }) {
      this.loading = true
      const dictionaryWord = this.dictionaryWords.find(e => e.id === id)
      const payload = {
        dictionaryAcceptanceWord: {
          registrationRequired: !dictionaryWord.registrationRequired
        }
      }
      ApiDictionaryAcceptanceWords.updateDictionaryAcceptanceWord(this.dictionaryId, dictionaryWord.id, payload)
        .then(response => {
          this.errors = {}
          dictionaryWord.registrationRequired = !dictionaryWord.registrationRequired
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.handleErrors(error)
        }).finally(() => {
          this.loading = false
        })
    },
    updateBlockedOnProfile ({ id }) {
      this.loading = true
      const dictionaryWord = this.dictionaryWords.find(e => e.id === id)
      const payload = {
        dictionaryAcceptanceWord: {
          blockedOnProfile: !dictionaryWord.blockedOnProfile
        }
      }
      ApiDictionaryAcceptanceWords.updateDictionaryAcceptanceWord(this.dictionaryId, dictionaryWord.id, payload)
        .then(response => {
          this.errors = {}
          dictionaryWord.blockedOnProfile = !dictionaryWord.blockedOnProfile
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.handleErrors(error)
        }).finally(() => {
          this.loading = false
        })
    },
    handleErrors (errors) {
      this.errors = errors.response.data
      setTimeout(() => {
        if (errors.response.status === 422 && this.errors) {
          this.$toastr.e(Object.values(this.errors).map(errors => errors.join('<br/>')).join(('<br/>')))
        } else {
          this.$toastr.e(this.$t('general.check_errors'))
        }
      }, 1)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .top-bar {
    margin-bottom: $pcg-m-md;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
