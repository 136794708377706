import { user } from '../store/modules/user'

function acceptedCompany () {
  if (user.state.company) {
    return user.state.acceptedCompany
  } else {
    return true
  }
}

export default {
  computed: {
    staticLinks () {
      return [
        {
          id: 1,
          name: this.$t('components.abk_navigation_menu.dashboard'),
          path: 'student_dashboard_path',
          iconClass: 'pcg-icon-home',
          roles: ['student', 'graduate'],
          sub: []
        },
        {
          id: 2,
          name: this.$t('components.abk_navigation_menu.dashboard'),
          path: 'employer_dashboard_path',
          iconClass: 'pcg-icon-home',
          roles: ['employer', 'recruiter'],
          sub: []
        },
        {
          id: 3,
          name: this.$t('components.abk_navigation_menu.dashboard'),
          path: 'career_office_dashboard_path',
          iconClass: 'pcg-icon-home',
          roles: ['career_office'],
          sub: []
        },
        {
          id: 4,
          name: this.$t('components.abk_navigation_menu.communiques'),
          path: 'user_messages_index_path',
          iconClass: 'pcg-icon-envelope-alt',
          roles: ['employer', 'recruiter', 'student', 'graduate'],
          sub: []
        },
        {
          id: 5,
          name: this.$t('components.abk_navigation_menu.contact'),
          path: 'simple_messages_index_path',
          iconClass: 'pcg-icon-phone',
          roles: ['employer', 'recruiter', 'student', 'graduate'],
          sub: []
        },
        {
          id: 6,
          name: this.$t('components.abk_navigation_menu.messages'),
          path: 'root_path',
          iconClass: 'pcg-icon-envelope-alt',
          roles: ['career_office'],
          sub: [
            {
              id: 601,
              name: this.$t('components.abk_navigation_menu.new_message'),
              path: 'messages_new_path',
              roles: ['career_office']
            },
            {
              id: 602,
              name: this.$t('components.abk_navigation_menu.conversations'),
              path: 'simple_messages_index_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 603,
              name: this.$t('components.abk_navigation_menu.group_messages'),
              path: 'messages_index_group_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 604,
              name: this.$t('components.abk_navigation_menu.draft_messages'),
              path: 'messages_index_draft_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 605,
              name: this.$t('components.abk_navigation_menu.recipient_groups'),
              path: 'recipient_groups_index_path',
              iconClass: 'pcg-icon-envelope',
              roles: ['career_office'],
              sub: []
            }
          ]
        },
        {
          id: 7,
          name: this.$t('components.abk_navigation_menu.employer_list'),
          path: 'root_path',
          iconClass: 'pcg-icon-profiles',
          roles: ['career_office'],
          sub: [
            {
              id: 701,
              name: this.$t('components.abk_navigation_menu.for_acceptance_companies'),
              path: 'companies_index_for_acceptance_tab_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 702,
              name: this.$t('components.abk_navigation_menu.accepted_companies'),
              path: 'companies_index_accepted_tab_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 703,
              name: this.$t('components.abk_navigation_menu.rejected_companies'),
              path: 'companies_index_rejected_tab_path',
              roles: ['career_office'],
              sub: []
            }
          ]
        },
        {
          id: 40,
          name: this.$t('components.abk_navigation_menu.student_list'),
          path: 'students_index_path',
          iconClass: 'pcg-icon-profiles',
          roles: ['career_office'],
          sub: []
        },
        {
          id: 8,
          name: this.offersTabName,
          path: 'root_path',
          iconClass: 'pcg-icon-briefcase',
          roles: ['student', 'graduate', 'employer', 'recruiter', 'career_office'],
          sub: [
            {
              id: 801,
              name: this.$t('components.abk_navigation_menu.new_offer'),
              path: 'offers_new_path',
              roles: ['employer', 'recruiter', 'career_office'],
              hide: !acceptedCompany()
            },
            {
              id: 802,
              name: this.$t('components.abk_navigation_menu.search'),
              path: 'offers_path',
              roles: ['student', 'graduate']
            },
            {
              id: 803,
              name: this.$t('components.abk_navigation_menu.observed_results'),
              path: 'offer_filters_path',
              roles: ['student', 'graduate'],
              counter: { value: this.$store.getters['user/newOfferFilterResults'], type: 'boolean' }
            },
            {
              id: 804,
              name: this.$t('components.abk_navigation_menu.list_of_offers'),
              path: 'offers_list_path',
              roles: ['employer', 'recruiter', 'career_office']
            },
            {
              id: 805,
              name: this.$t('components.abk_navigation_menu.my_submissions'),
              path: 'applications_offers_path',
              roles: ['student', 'graduate'],
              counter: { value: this.$store.getters['user/offerApplicationsCount'], type: 'number' }
            },
            {
              id: 806,
              name: this.$t('components.abk_navigation_menu.favorites'),
              path: 'favorite_offers_path',
              roles: ['student', 'graduate'],
              counter: { value: this.$store.getters['user/favoriteOffersCount'], type: 'number' }
            },
            {
              id: 807,
              name: this.$t('components.abk_navigation_menu.invitations'),
              path: 'offer_invitations_list_current_tab_path',
              roles: ['student', 'graduate']
            }
          ]
        },
        {
          id: 9,
          name: this.$t('components.abk_navigation_menu.cms'),
          path: 'root_path',
          iconClass: 'pcg-icon-table',
          roles: ['career_office'],
          sub: [
            {
              id: 91,
              name: this.$t('components.abk_navigation_menu.new_page'),
              path: 'webpages_new_path',
              roles: ['career_office']
            },
            {
              id: 92,
              name: this.$t('components.abk_navigation_menu.list_of_pages'),
              path: 'webpages_path',
              roles: ['career_office']
            }
          ]
        },
        {
          id: 50,
          name: this.$t('components.abk_navigation_menu.news'),
          path: 'root_path',
          iconClass: 'pcg-icon-table',
          roles: ['career_office'],
          sub: [
            {
              id: 501,
              name: this.$t('components.abk_navigation_menu.new_news'),
              path: 'news_new_path',
              roles: ['career_office']
            },
            {
              id: 502,
              name: this.$t('components.abk_navigation_menu.news_index'),
              path: 'news_list_path',
              roles: ['career_office']
            }
          ]
        },
        {
          id: 10,
          name: this.$t('components.abk_navigation_menu.cv'),
          iconClass: 'pcg-icon-person',
          roles: ['student', 'graduate'],
          sub: [
            {
              id: 1001,
              name: this.$t('components.abk_navigation_menu.edit'),
              path: 'curriculum_vitae_edit_path',
              roles: ['student', 'graduate']
            },
            {
              id: 1002,
              name: this.$t('components.abk_navigation_menu.my_cv'),
              path: 'curriculum_vitae_my_show_path',
              roles: ['student', 'graduate']
            }
          ]
        },
        {
          id: 11,
          name: this.$t('components.abk_navigation_menu.dictionaries'),
          path: 'dictionary_acceptances_path',
          iconClass: 'pcg-icon-content',
          roles: ['career_office'],
          sub: [
            {
              id: 1101,
              name: this.$t('components.abk_navigation_menu.dictionaries'),
              path: 'dictionaries_path',
              roles: ['career_office'],
              sub: [],
              counter: this.getCounter('user/recentDictionaryWordsCount')
            },
            {
              id: 1102,
              name: this.$t('components.abk_navigation_menu.dictionaries_of_consents'),
              path: 'dictionary_acceptances_path',
              roles: ['career_office'],
              sub: []
            }
          ]
        },
        {
          id: 20,
          name: this.$t('components.abk_navigation_menu.competences'),
          path: 'root_path',
          iconClass: 'pcg-icon-check-shield',
          roles: ['career_office'],
          hide: this.competenceFeature === 'off',
          sub: [
            {
              id: 2001,
              name: this.$t('components.abk_navigation_menu.competences_list'),
              path: 'competences_list_all_tab_path',
              roles: ['career_office']
            },
            {
              id: 2002,
              name: this.$t('components.abk_navigation_menu.competences_connected_with_skills'),
              path: 'competences_list_connected_with_skills_tab_path',
              roles: ['career_office']
            },
            {
              id: 2003,
              name: this.$t('components.abk_navigation_menu.competences_not_connected_with_skills'),
              path: 'competences_list_not_connected_with_skills_tab_path',
              roles: ['career_office']
            },
            {
              id: 2004,
              name: this.$t('components.abk_navigation_menu.skills'),
              path: 'competences_skills_index_path',
              roles: ['career_office'],
              sub: []
            }
          ]
        },
        {
          id: 30,
          name: this.$t('components.abk_navigation_menu.skills'),
          path: 'root_path',
          iconClass: 'pcg-icon-graduation-cap-alt',
          roles: ['career_office'],
          hide: this.competenceFeature === 'off',
          sub: [
            {
              id: 3001,
              name: this.$t('components.abk_navigation_menu.skills_list'),
              path: 'skills_list_all_tab_path',
              roles: ['career_office']
            },
            {
              id: 3002,
              name: this.$t('components.abk_navigation_menu.skills_connected_with_competences'),
              path: 'skills_list_connected_with_competences_tab_path',
              roles: ['career_office']
            },
            {
              id: 3003,
              name: this.$t('components.abk_navigation_menu.skills_not_connected_with_competences'),
              path: 'skills_list_not_connected_with_competences_tab_path',
              roles: ['career_office']
            },
            {
              id: 3004,
              name: this.$t('components.abk_navigation_menu.competences'),
              path: 'skill_competences_index_path',
              roles: ['career_office']
            }
          ]
        },
        {
          id: 12,
          name: this.$t('components.abk_navigation_menu.firm'),
          path: '',
          iconClass: 'pcg-icon-person',
          roles: ['employer', 'recruiter'],
          sub: [
            {
              id: 1201,
              name: this.$t('components.abk_navigation_menu.edit'),
              path: 'company_edit_path',
              roles: ['employer'],
              sub: []
            },
            {
              id: 1202,
              name: this.$t('components.abk_navigation_menu.profile'),
              path: 'profile_company_show_path',
              roles: ['employer', 'recruiter'],
              sub: []
            }
          ]
        },
        {
          id: 15,
          name: this.$t('components.abk_navigation_menu.events'),
          path: 'root_path',
          iconClass: 'pcg-icon-calendar-date',
          roles: ['employer', 'counselor', 'recruiter', 'career_office', 'student', 'graduate'],
          sub: [
            {
              id: 1501,
              name: this.$t('components.abk_navigation_menu.new_event'),
              path: 'events_new_path',
              roles: ['employer', 'recruiter', 'career_office', 'counselor'],
              hide: !acceptedCompany()
            },
            {
              id: 1502,
              name: this.$t('components.abk_navigation_menu.events_list'),
              path: 'events_list_all_tab_path',
              roles: ['employer', 'recruiter', 'career_office', 'counselor']
            },
            {
              id: 1503,
              name: this.$t('components.abk_navigation_menu.current_events'),
              path: 'events_list_current_tab_path',
              roles: ['employer', 'recruiter', 'career_office', 'counselor']
            },
            {
              id: 1504,
              name: this.$t('components.abk_navigation_menu.archived_events'),
              path: 'events_list_archived_tab_path',
              roles: ['employer', 'recruiter', 'career_office', 'counselor']
            },
            {
              id: 1505,
              name: this.$t('components.abk_navigation_menu.current_events'),
              path: 'events_list_current_tab_path',
              roles: ['student', 'graduate']
            },
            {
              id: 1506,
              name: this.$t('components.abk_navigation_menu.registered_events'),
              path: 'events_list_registered_tab_path',
              roles: ['student', 'graduate']
            },
            {
              id: 1507,
              name: this.$t('components.abk_navigation_menu.events_list_student'),
              path: 'events_list_all_tab_path',
              roles: ['student', 'graduate']
            },
            {
              id: 1508,
              name: this.$t('components.abk_navigation_menu.assigned_events'),
              path: 'events_list_assigned_tab_path',
              hide: this.competenceFeature === 'off',
              roles: ['student', 'graduate']
            },
            {
              id: 1509,
              name: this.$t('components.abk_navigation_menu.archived_events'),
              path: 'events_list_archived_tab_path',
              roles: ['student', 'graduate']
            },
            {
              id: 1510,
              name: this.$t('components.abk_navigation_menu.pending_events'),
              path: 'events_list_pending_tab_path',
              roles: ['career_office', 'counselor']
            }
          ]
        },
        {
          id: 16,
          name: this.$t('components.abk_navigation_menu.surveys'),
          path: 'root_path',
          iconClass: 'pcg-icon-list',
          roles: ['career_office', 'student', 'graduate', 'employer', 'recruiter'],
          sub: [
            {
              id: 801,
              name: this.$t('components.abk_navigation_menu.new_survey'),
              path: 'surveys_new_path',
              roles: ['career_office']
            },
            {
              id: 1601,
              name: this.$t('components.abk_navigation_menu.recent'),
              path: 'surveys_list_recent_tab_path',
              roles: ['student', 'graduate', 'employer', 'recruiter'],
              sub: []
            },
            {
              id: 1602,
              name: this.$t('components.abk_navigation_menu.completed'),
              path: 'surveys_list_completed_tab_path',
              roles: ['student', 'graduate', 'employer', 'recruiter'],
              sub: []
            },
            {
              id: 1603,
              name: this.$t('components.abk_navigation_menu.active'),
              path: 'surveys_list_active_tab_path',
              roles: ['career_office'],
              sub: []
            },
            {
              id: 1604,
              name: this.$t('components.abk_navigation_menu.inactive'),
              path: 'surveys_list_inactive_tab_path',
              roles: ['career_office'],
              sub: []
            }
          ]
        },
        {
          id: 17,
          name: this.$t('components.abk_navigation_menu.users_list'),
          path: 'users_list_path',
          iconClass: 'fa fa-users',
          roles: ['administrator'],
          sub: []
        },
        {
          id: 18,
          name: this.$t('components.abk_navigation_menu.list_of_news'),
          path: 'news_path',
          iconClass: 'pcg-icon-table',
          roles: ['employer', 'recruiter', 'student', 'graduate'],
          sub: []
        },
        {
          id: 19,
          name: this.$t('components.abk_navigation_menu.removed_users_list'),
          path: 'removed_users_list_path',
          iconClass: 'fa fa-user-slash',
          roles: ['administrator'],
          sub: []
        },
        // ,
        // {
        //   id: 13,
        //   name: this.$t('components.abk_navigation_menu.static_pages'),
        //   path: 'root_path',
        //   iconClass: 'fas fa-tools',
        //   roles: ['career_office'],
        //   sub: [
        //     {
        //       id: 1301,
        //       name: this.$t('components.abk_navigation_menu.base_blocks'),
        //       path: 'test2_path',
        //       iconClass: 'pcg-icon-document',
        //       roles: ['career_office'],
        //       sub: []
        //     },
        //     {
        //       id: 1302,
        //       name: this.$t('components.abk_navigation_menu.components'),
        //       path: 'components_path',
        //       iconClass: 'pcg-icon-document',
        //       roles: ['career_office'],
        //       sub: []
        //     }
        //   ]
        // }
        // {
        //   id: 14,
        //   name: this.$t('components.abk_navigation_menu.log_in'),
        //   path: 'login_path',
        //   iconClass: 'fas fa-sign-in-alt',
        //   roles: [],
        //   sub: [],
        //   mustLogout: true
        // }
      ]
    },
    offersTabName () {
      const userRole = this.$store.getters['auth/currentRole']
      return userRole && (userRole === 'employer' || userRole === 'recruiter')
        ? this.$t('components.abk_navigation_menu.my_offers')
        : this.$t('components.abk_navigation_menu.catalog_of_offers')
    }
  },
  methods: {
    getCounter (name) {
      const counter = this.$store.getters[name]
      return counter > 0 ? { value: counter, type: 'number' } : null
    }
  }
}
