import api from './index'

const resource = 'simple_messages'

export default {
  getSimpleMessages (params={}) {
    return api.get(`${resource}`, { params: params })
  },
  getSimpleMessage (simpleMessageId) {
    return api.get(`${resource}/${simpleMessageId}`)
  },
  createSimpleMessage (payload) {
    return api.post(`${resource}`, payload)
  },
  markAsRead (messageId) {
    return api.patch(`${resource}/${messageId}/mark_as_read`)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  }
}
