<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20">
        <path :class="variant" d="M10.6 14.1l-1 5.2H8.2c-.3 0-.5-.1-.7-.3s-.3-.5-.3-.8v-.1-.1l.8-3.9H5.4l-.7 4c-.1.4-.3.7-.5.9-.3.2-.6.3-.9.3H1.8l1-5.2H1.3c-.3 0-.5-.1-.6-.2-.1-.1-.2-.4-.2-.7v-.4l.2-1h2.4l.7-3.5H1.1L1.3 7c.1-.3.2-.6.4-.7.2-.2.5-.2 1-.2h1.4L4.9 2c.1-.4.2-.6.5-.8s.5-.3.9-.3h1.4l-1 5.2h2.6l1-5.2h1.4c.3 0 .5.1.7.3.2.2.3.4.3.6V2l-.8 4.1h2.6l-.2 1.3c-.1.3-.2.6-.4.7-.2.2-.5.2-1 .2h-1.3l-.6 3.5h1.8c.3 0 .5.1.6.2.1.1.2.4.2.7v.4l-.2 1h-2.8zm-4.9-2.3h2.6L9 8.3H6.4l-.7 3.5z"/>
    </svg>
</template>

<script>
export default {
  name: 'AbkSurveySectionSvg',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (variant) {
        return ['primary', 'secondary'].includes(variant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars";

    .primary {
        fill: $main-color
    }
    .secondary {
        fill: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars-contrast";

    .contrast{
        .primary {
            fill: $main-color
        }
        .secondary {
            fill: $main-active-color;
        }
    }
</style>
