<template>
  <b-row>
    <div class="w-100 text-center">
      <h4 v-if="showCreateFromTemplate" class="pcg-event-header-text m-0">
        {{ $t('components.events.abk_event_step_1_form.title') }}
        <router-link class="pcg-link" :to="{ name: 'events_list_all_tab_path' }">
          {{ $t('components.events.abk_event_step_1_form.use_template') }}
        </router-link>
      </h4>
    </div>
    <b-col cols="12">
      <div class="form-container separator">
        <b-row>
          <abk-file-input @input="handleFile($event)"
                          :file-url="value.mainImage ? value.mainImage.url : ''"
                          :status="fileUploadStatus()"
                          ref="mainImage"
                          :error-text="getError('mainImage')"
                          v-b-tooltip.hover
                          :title="getError('mainImage')"
                          show-remove-button
                          @remove="removeFile"/>
        </b-row>
        <b-row class="radio-container">
          <pcg-radio class="col-12"
                     :required="true"
                     :options="options.eventType"
                     v-model="value.eventType"
                     name="event-type"
                     :show-error="errorExist('eventType')"
                     :error-text="getError('eventType')"
                     :label="$t('components.events.abk_event_step_1_form.event_type')"/>
        </b-row>
        <b-row>
          <pcg-search-select v-if="showEmployersSelect"
                             class="abk-input col-12 col-lg-6"
                             :required="true"
                             always-active
                             show-label
                             :options="companies"
                             :value="getDictionaryAttributeValue('company')"
                             @input="updateDictionaryAttribute('company', $event)"
                             :error-text="getError('company')"
                             :show-error="errorExist('company')"
                             :label="$t('components.events.abk_event_step_1_form.company')"/>
        </b-row>
        <b-row>
          <pcg-select v-if="value.eventType === 'counseling'"
                      class="abk-input col-12 col-lg-6"
                      :options="options.instructor"
                      :value="value.instructorId"
                      @input="updateDictionaryAttribute('instructor', $event)"
                      :error-text="getError('instructor')"
                      :show-error="errorExist('instructor')"
                      :show-label="true"
                      :label="$t('components.events.abk_event_step_1_form.instructor')" />
        </b-row>
        <b-row>
          <pcg-text-input class="abk-input col-12 mb-0"
                          :required="true"
                          :label="$t('components.events.abk_event_step_1_form.event_title')"
                          :error-text="getError('eventTitle')"
                          :show-error="errorExist('eventTitle')"
                          v-model="value.eventTitle"/>
        </b-row>
      </div>
      <div class="form-container separator-bold">
        <b-row>
          <pcg-text-input class="abk-input col-12"
                          v-model="value.place"
                          :error-text="getError('place')"
                          :show-error="errorExist('place')"
                          :label="$t('components.events.abk_event_step_1_form.place')"/>
          <pcg-text-input class="abk-input col-12"
                          v-model="value.address"
                          :error-text="getError('address')"
                          :show-error="errorExist('address')"
                          :label="$t('components.events.abk_event_step_1_form.address')"/>
        </b-row>
        <b-row>
          <pcg-text-input class="abk-input col-12 col-lg-4"
                          min="1"
                          :label="$t('components.events.abk_event_step_1_form.reminder')"
                          v-model="value.reminder"
                          :show-error="errorExist('reminder')"
                          :error-text="getError('reminder')"/>
        </b-row>
      </div>
      <div class="form-container separator">
        <b-row>
          <b-col class="label">
            {{ $t('components.events.abk_event_step_1_form.deadlines') }}
          </b-col>
        </b-row>
        <b-row>
          <abk-sub-events v-model="value.subEvents" :errors="getSubEventErrors()" @addSubEvent="addSubEvent()"></abk-sub-events>
        </b-row>
      </div>
      <div class="form-container separator">
        <b-row>
            <pcg-text-input class="col-11 col-lg-4"
                            :label="$t('components.events.abk_event_step_1_form.cost')"
                            autocomplete="off"
                            v-model="value.cost"
                            :show-error="errorExist('cost')"
                            :error-text="getError('cost')"/>
          <div class="pcg-input-label event-cost-currency">{{ $t('general.currency.pln') }}</div>
        </b-row>
      </div>
      <div class="form-container">
        <b-row>
          <pcg-checkbox v-model="value.attendance"
                        :error-text="getError('attendance')"
                        :show-error="errorExist('attendance')"
                        class="col-11 col-lg-4">
            {{ $t('components.events.abk_event_step_1_form.attendance_mandatory') }}
          </pcg-checkbox>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import humps from 'humps'
import { mapGetters } from 'vuex'
import ApiEvents from '../../api/events'
import AbkSubEvents from './AbkSubEvents'
import AbkFileInput from '../AbkFileInput'

export default {
  name: 'AbEventStep1Form',
  components: { AbkSubEvents, AbkFileInput },
  props: {
    value: Object,
    companies: Array,
    errors: Object
  },
  data () {
    return {
      eventsCount: 0,
      options: {
        eventType: [],
        instructor: []
      }
    }
  },
  methods: {
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateFormObject (object) {
      this.$emit('input', { ...this.value, ...object })
    },
    async handleFile (file) {
      if (this.value.removeMainImage) {
        let base64File
        const reader = new FileReader()
        let copy = { ...this.value, mainImage: file }
        reader.readAsDataURL(file)
        reader.onload = () => {
          base64File = reader.result
          copy = { ...copy, eventMainImage64: base64File }
          delete copy.removeMainImage
          this.$emit('input', copy)
        }
      } else {
        let base64File
        this.updateForm('mainImage', file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          base64File = reader.result
          this.updateForm('eventMainImage64', base64File)
        }
      }
      if (this.errorExist('mainImage')) {
        delete this.errors.mainImage
      }
    },
    removeFile () {
      this.$refs.mainImage.clear()
      this.updateFormObject({
        mainImage: null,
        eventMainImage: { filename: null, url: null },
        eventMainImage64: null,
        removeMainImage: true
      })
    },
    fileUploadStatus () {
      if (this.loading) {
        return 'loading'
      } else if (this.errorExist('mainImage')) {
        return 'fail'
      } else if (this.value.mainImage instanceof File || (this.value.mainImage && this.value.mainImage.url)) {
        return 'success'
      }
      return 'upload'
    },
    addSubEvent () {
      this.$emit('addSubEvent')
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    getSubEventErrors () {
      const keys = Object.keys(this.errors).filter((k) => { return k.includes('subEvent') })
      const errors = []
      if (keys.length > 0) {
        keys.forEach((k) => {
          const els = k.split('.')
          if (errors[els[1]] === undefined) {
            errors[els[1]] = {}
          }
          errors[els[1]][els[2]] = this.errors[k]
        })
      }
      return errors
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    getDictionaryAttributeValue (attribute) {
      return this.value[attribute] ? this.value[attribute].value : null
    },
    updateDictionaryAttribute (attribute, value) {
      const valueObject = this.options[attribute].find(e => e.value === value)
      const newForm = { ...this.value, [attribute]: valueObject }

      this.$emit('input', newForm)
    },
    getEventsInfo () {
      if (!this.showEmployerActions) { return }
      ApiEvents.getEventsInfo()
        .then(response => {
          this.eventsCount = response.data.totalCount
        })
        .catch(error => {
          console.log(error)
        })
    },
    getInstructors () {
      if (this.value.eventType === 'counseling') {
        if (this.options.instructor.length === 0) {
          ApiEvents.getInstructors()
            .then(response => {
              const users = response.data.data
              users.forEach(user => {
                this.options.instructor.push({
                  text: user.attributes.fullName,
                  value: user.id
                })
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    }
  },
  watch: {
    companies () {
      this.options.company = this.companies
    },
    'value.eventType' () {
      this.getInstructors()
    },
    'value.subEvents' () {
      if (this.value.subEvents.length === 0) {
        this.addSubEvent()
      } else {
        const reduced = this.value.subEvents.filter((se) => {
          return !se._destroy
        })
        if (reduced.length === 0) {
          this.addSubEvent()
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['roles']),
    decamelizedDictionaries () {
      return this.dictionaries.map(dictionary => humps.decamelize(dictionary))
    },
    showEmployersSelect () {
      return (this.isCareerOffice || this.$store.getters['auth/currentRole'] === 'counselor') && !this.employerId
    },
    isCareerOffice () {
      return this.$store.getters['auth/currentRole'] === 'career_office'
    },
    showEmployerActions () {
      return this.roles && (this.roles.includes('employer') || this.roles.includes('recruiter'))
    },
    showCreateFromTemplate () {
      return this.showEmployerActions && this.eventsCount > 0
    }
  },
  mounted () {
    this.getEventsInfo()
    this.getInstructors()
    if (this.$store.getters['auth/currentRole'] !== 'counselor') {
      this.options.eventType.push({ text: this.$t('general.event_type.fair'), value: 'fair' })
      this.options.eventType.push({ text: this.$t('general.event_type.training'), value: 'training' })
      this.options.eventType.push({ text: this.$t('general.event_type.rally'), value: 'rally' })
      if (this.isCareerOffice) {
        this.options.eventType.push({ text: this.$t('general.event_type.counseling'), value: 'counseling' })
      }
      this.options.eventType.push({ text: this.$t('general.event_type.other'), value: 'other' })
    } else {
      this.options.eventType.push({ text: this.$t('general.event_type.counseling'), value: 'counseling' })
      this.value.eventType = 'counseling'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .event-cost-currency {
    display: flex;
    align-items: center;
    padding-top: 25px;
  }

  .radio-container {
    margin-bottom: $pcg-m-lg;
  }
  .pcg-event-header-text {
    margin-bottom: $pcg-m-md;
  }
  .form-container {
    width: 100%;
    padding: 1.785rem 0 $pcg-m-xl;
  }
  .separator {
    border-bottom: 1px solid $pcg-lightest-gray;
  }
  .separator-bold {
    border-bottom: 2px solid $pcg-light-gray;
  }
  /deep/ .abk-input {
    margin-bottom: $pcg-m-lg;
    height: fit-content!important;
  }
  .date-input {
    /deep/ input {
      color: $pcg-gray;
    }
    /deep/ .bootstrap-datetimepicker-widget {
      width: 312px;
    }
  }
  .go-to-next-step-btn {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-width: $screen-lg-min) {
    .date-input {
      /deep/ input {
        width: 100%;
      }
      /deep/ .pcg-datetimepicker-btn {
        position: absolute;
      }
    }
    .end-date-picker {
      margin-left: 76px;
    }
  }

  @media (max-width: $screen-lg-min) {
    .start-date-picker {
      margin-bottom: $pcg-m-lg!important;
    }
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .separator {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    .separator-bold {
      border-bottom: 2px solid $pcg-light-gray;
    }
    /deep/ .abk-input {
      margin-bottom: $pcg-m-lg;
    }
    .date-input {
      /deep/ input {
        color: $pcg-gray;
      }
    }
  }
</style>
