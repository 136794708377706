<template>
  <b-modal :id="id"
           footer-class="d-flex justify-content-center abk-modal-footer"
           centered hide-header
           :visible="visible"
           content-class="abk-modal-content"
           :no-close-on-backdrop="disableCancel"
           :no-close-on-esc="disableCancel">
    <h2>{{ title }}</h2>
    <pcg-search-select v-if="searchable" always-active v-model="selectedValue" :options="options"/>
    <pcg-select v-else v-model="selectedValue" :options="options"/>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn :disabled="disableCancel" @click="cancel()" variant="additional" class="mr-3">
        {{ $t('general.cancel') }}
      </pcg-btn>
      <pcg-btn :disabled="!selectedValue" @click="selectOption">{{ $t('general.select') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AbkSelectModal',
  props: {
    id: {
      type: String,
      required: true
    },
    title: String,
    options: Array,
    value: String,
    visible: Boolean,
    searchable: Boolean,
    disableCancel: Boolean
  },
  data () {
    return {
      selectedValue: this.value
    }
  },
  methods: {
    selectOption () {
      if (this.selectedValue) {
        this.$root.$emit('bv::hide::modal', this.id)
        this.$emit('input', this.selectedValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  h2 {
    text-align: center;
    margin-bottom: 2em;
    color: $main-color;
  }

  /deep/ .modal-footer {
    padding-top: 3em;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    h2 {
      color: $main-color;
    }
  }
</style>
