<template>
  <div>
    <abk-loader v-if="showLoader"/>
    <div v-else>
      <b-row class="top-bar d-flex justify-content-between">
        <abk-progress-bar class="mb-3" :steps="creatingOfferSteps" :current-step="currentStep"/>
        <pcg-btn v-if="(showCareerOfficeActions || acceptedCompany) && showDraft" size="small" variant="additional" @click="saveDraft">
          {{ $t('components.abk_offer_form.save_working_copy') }}
        </pcg-btn>
      </b-row>
      <transition name="fade" mode="out-in">
        <keep-alive v-if="currentStep === 1">
          <abk-new-offer-step1-form v-model="step1Form" :companies="companies" :errors="errors"/>
        </keep-alive>
        <keep-alive v-else-if="currentStep === 2">
          <abk-new-offer-step2-form v-model="step2Form" :errors="step2FormFormattedErrors"/>
        </keep-alive>
        <keep-alive v-else-if="currentStep === 3">
          <abk-new-offer-step3-form :quick-info="quickInfoForm"
                                    :attachments="filtredAttachments"
                                    :content="sectionsPreview"/>
        </keep-alive>
      </transition>
    </div>
    <b-modal ref="acceptedOffer"
             centered
             hide-header
             hide-footer
             ok-variant="primary"
             cancel-variant="default"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
    >
      <div class="modal-text">
        <span v-if="currentRole === 'career_office'">{{ $t('components.abk_offer_form.accepted_offer_bk') }}</span>
        <span v-else>{{ $t('components.abk_offer_form.accepted_offer') }}</span>
      </div>
      <div class="d-flex justify-content-center modal-btn">
        <pcg-btn @click="hideModal">
          {{ $t('components.abk_offer_form.accept') }}
        </pcg-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AbkProgressBar from './AbkProgressBar'
import AbkNewOfferStep1Form from './AbkNewOfferStep1Form'
import AbkNewOfferStep2Form from './AbkNewOfferStep2Form'
import AbkNewOfferStep3Form from './AbkNewOfferStep3Form'
import ApiOffers from '../api/offers'
import { mapGetters } from 'vuex'
import ApiCompanies from '../api/companies'
import AbkLoader from './AbkLoader'
import Vue from 'vue'

export default {
  name: 'AbkOfferForm',
  components: { AbkNewOfferStep3Form, AbkNewOfferStep2Form, AbkNewOfferStep1Form, AbkProgressBar, AbkLoader },
  props: {
    employerId: String,
    offerId: String,
    submitAction: {
      type: Function,
      required: true
    },
    validateAction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      dictionaryAttributes: ['workplace', 'trade', 'positionType', 'typeOfWork', 'workDimension', 'user', 'company'],
      copyOfferId: this.$route.query.copy_id,
      step2ErrorKeys: ['offerAttachments', 'offerSections', 'offerCompetences'],
      errors: {},
      showLoader: true,
      step1Form: {
        user: null,
        company: null,
        offerType: null,
        jobTitle: null,
        workplace: null,
        trade: null,
        positionType: null,
        typeOfWork: null,
        workDimension: null,
        expirationDate: moment(new Date()).add(7, 'days'),
        publicationDate: moment(new Date()),
        salaryFrom: null,
        salaryTo: null,
        freePlaces: null
      },
      step2Form: {
        sections: [{ content: '', key: 1, sectionType: 'text', title: null }],
        attachments: [],
        competences: []
      },
      currentStep: 1,
      companies: []
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole', 'userId']),
    ...mapGetters('user', ['acceptedCompany']),
    creatingOfferSteps () {
      return [
        this.$t('components.abk_offer_form.step_1'),
        this.$t('components.abk_offer_form.step_2'),
        this.$t('components.abk_offer_form.step_3')
      ]
    },
    step2FormFormattedErrors () {
      const errors = this.getStep2FormErrorsObject()
      this.step2FormErrors.forEach(key => {
        const splittedKey = key.split('.')
        if (!errors[splittedKey[0]][splittedKey[1]]) { errors[splittedKey[0]][splittedKey[1]] = {} }
        if (splittedKey[0] === 'offerAttachments' || splittedKey[0] === 'offerCompetences') {
          errors[splittedKey[0]][splittedKey[1]] = this.errors[key]
        } else {
          errors[splittedKey[0]][splittedKey[1]][splittedKey[2]] = this.errors[key]
        }
      })

      return errors
    },
    step2FormErrors () {
      return Object.keys(this.errors).filter(key => this.step2ErrorKeys.includes(key.split('.')[0]))
    },
    formattedForm () {
      return {
        offer: {
          step: this.currentStep,
          ...this.formattedStep1Form,
          offerSections: this.formattedSections,
          offerAttachments: this.formattedAttachments,
          offerCompetences: this.formattedCompetences
        }
      }
    },
    formattedStep1Form () {
      const newForm = { ...this.step1Form }
      this.dictionaryAttributes.forEach(attribute => {
        delete newForm[attribute]
        if (this.step1Form[attribute]) {
          newForm[`${attribute}Id`] = this.step1Form[attribute].value
        }
      })
      return newForm
    },
    formattedAttachments () {
      let attachments = this.step2Form.attachments
      if (this.copyOfferId) {
        attachments = attachments.filter(attachment => !attachment._destroy)
      }
      return attachments.map(({ key, ...rest }) => { return { ...rest } })
    },
    formattedSections () {
      let sections = this.step2Form.sections
      if (this.copyOfferId) {
        sections = sections.filter(section => !section._destroy)
      }

      return sections.map(({ key, fileName, imageFile, imageUrl, ...form }, index) => {
        return { sort: index, ...form }
      })
    },
    formattedCompetences () {
      const competences = this.step2Form.competences
      return competences.map(competence => {
        return { competenceId: competence.value }
      })
    },
    quickInfoForm () {
      const newForm = { ...this.step1Form }
      this.dictionaryAttributes.forEach(attribute => {
        const attributeObject = this.step1Form[attribute]
        if (attributeObject) {
          newForm[attribute] = { value: attributeObject.text }
        }
      })
      return newForm
    },
    sectionsPreview () {
      return this.step2Form.sections.filter(section => !section._destroy)
    },
    filtredAttachments () {
      return this.step2Form.attachments.filter(section => !section._destroy)
    },
    showCareerOfficeActions () {
      return this.$store.getters['auth/currentRole'] === 'career_office'
    },
    showEmployerActions () {
      return this.$store.getters['auth/currentRole'] === 'employer' || this.$store.getters['auth/currentRole'] === 'recruiter'
    },
    showDraft () {
      return this.formattedForm.offer.status === 'draft' || this.formattedForm.offer.status === 'accepted'
    }
  },
  watch: {
    currentStep () {
      this.setFooterNavbarItems()
    }
  },
  mounted () {
    if (Vue.$cookies.get('locale')) {
      this.$i18n.locale = Vue.$cookies.get('locale')
    }
    this.getOffer()
    this.setUser()
    if (this.showCareerOfficeActions) { this.getCompanies() }
    this.setFooterNavbarItems()
  },
  methods: {
    setFooterNavbarItems () {
      const items = []

      items.push({
        show: this.currentStep > 1,
        variant: 'additional',
        class: 'abk-footer-nav-btn ml-0',
        type: 'button',
        text: this.$t('general.steps.previous'),
        action: () => { --this.currentStep }
      })
      items.push({
        show: this.currentStep < 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.steps.next'),
        action: this.goToNextStep
      })
      items.push({
        show: this.currentStep === 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.save'),
        action: this.save
      })
      items.push({
        show: this.currentStep === 3,
        class: 'abk-footer-nav-btn mr-0',
        type: 'button',
        text: this.showCareerOfficeActions
          ? this.$t('general.steps.publish')
          : this.$t('general.steps.send_for_publication'),
        action: this.publish
      })

      this.$store.dispatch('footerNavbar/setItems', items)
    },
    setUser () {
      if (this.showEmployerActions) {
        this.step1Form.user = { value: this.userId }
      }
    },
    saveDraft () {
      this.submitAction({ offer: { ...this.formattedForm.offer, status: 'draft' } }, this.offerId)
        .then(() => {
          this.$toastr.s(this.$t('components.abk_offer_form.working_copy_saved'))
          this.$router.push({ name: 'offers_list_path' })
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.errors = error.response.data
        })
    },
    save () {
      this.submitAction({ offer: { ...this.formattedForm.offer, status: 'recent' } }, this.offerId)
        .then(() => {
          this.$toastr.s(this.$t('components.abk_offer_form.working_copy_saved'))
          this.$router.push({ name: 'offers_list_path' })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    goToNextStep () {
      this.validateAction(this.formattedForm, this.offerId)
        .then(() => {
          this.clearErrors()
          ++this.currentStep
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    publish () {
      this.submitAction({ offer: { ...this.formattedForm.offer, status: 'for_acceptance' } }, this.offerId)
        .then(response => {
          this.$toastr.s(this.$t('components.abk_offer_form.offer_updated'))
          this.$router.push({
            name: this.showEmployerActions ? 'offers_employer_show_path' : 'offers_show_path',
            params: {
              id: response.data.data.id
            }
          })
        }).catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    clearErrors () {
      this.errors = {}
    },
    getStep2FormErrorsObject () {
      const errors = {}
      this.step2ErrorKeys.forEach(key => { errors[key] = [] })
      return errors
    },
    getOffer () {
      if (!this.offerId && !this.copyOfferId) {
        this.showLoader = false
        return
      }
      ApiOffers.getOffer(this.offerId || this.copyOfferId)
        .then(response => {
          if (response.data.data.attributes.status === 'accepted') {
            this.$refs.acceptedOffer.show()
          }
          this.setOffer(response.data.data.attributes)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_offer_form.offer_download_error'))
        })
    },
    setOffer ({ offerSections, offerAttachments, competences, ...offer }) {
      this.dictionaryAttributes.forEach(attribute => {
        if (offer[attribute]) {
          offer[attribute] = {
            text: offer[attribute].value,
            value: offer[attribute].id.toString()
          }
        }
      })
      Object.assign(this.step1Form, {
        ...offer,
        expirationDate: offer.expirationDate && moment(offer.expirationDate).toDate(),
        publicationDate: offer.publicationDate && moment(offer.publicationDate).toDate(),
        salaryFrom: offer.salaryFrom,
        salaryTo: offer.salaryTo
      })
      Object.assign(this.step2Form, {
        sections: offerSections.map(section => {
          return {
            id: section.id,
            content: section.content,
            key: section.sort,
            sectionType: section.sectionType,
            title: section.title,
            imageUrl: section.image ? section.image.url : null
          }
        }),
        attachments: offerAttachments,
        competences: competences.map(competence => {
          return {
            text: competence.name,
            value: competence.id.toString()
          }
        })
      })
      this.$store.dispatch('header/setObjectName', this.step1Form.jobTitle)
      this.showLoader = false
    },
    getCompanies () {
      ApiCompanies.getCompanies({ status: ['accepted'] })
        .then(response => {
          if (this.step1Form.company === null) {
            const co = response.data.data.find(({ attributes }) => {
              return attributes.careerOffice
            })
            this.step1Form.company = { text: co.attributes.name, value: co.id }
          }
          this.companies = response.data.data.map((el) => {
            return { value: el.id, text: el.attributes.name }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    hideModal () {
      this.$refs.acceptedOffer.hide()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/vars";

  .top-bar {
    margin-bottom: 1.7rem;
  }
  .navigation-buttons-container {
    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .modal-text {
    font-size: $font-size-m;
    color: $pcg-dark-gray;
    margin-bottom: 2rem;
  }
</style>
