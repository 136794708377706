<template>
  <div>
    <b-row>
      <b-col cols="12" class="d-flex align-items-baseline filter-bar flex-wrap">
        <span class="filter-text">{{ $t('components.messages.abk_messages_list.filter_messages') }}</span>
        <pcg-select class="col-lg-2 filter-input"
                    :show-label="false"
                    :options="messageType"
                    :default-option="{ text: $t('general.channel'), value: null }"
                    v-model="filters.messageType"
                    @input="filterMessages"/>
        <pcg-search-select always-active
                           allow-empty
                           class="col-lg-4 filter-input"
                           :placeholder="$t('general.recipients_group')"
                           v-model="filters.recipientGroupId"
                           @input="filterMessages"
                           :options="options.recipientGroup"/>
      </b-col>
    </b-row>
    <b-row>
      <pcg-box v-if="showLoader">
        <abk-loader/>
      </pcg-box>
      <template v-else-if="messages.length > 0">
        <abk-message-secondary v-for="message in messages"
                               :key="message.id"
                               :message="message"
                               :recipient-group-properties="findRecipientGroupProperties(message.recipientGroupId)"
                               :allow-copy="allowCopy"
                               :allow-delete="allowDelete"
                               :allow-edit="allowEdit"
                               class="col-12"
                               @deleted="filterMessages"/>
      </template>
      <pcg-box v-else>
        <span class="pcg-no-records">{{ $t('components.messages.abk_messages_list.no_records') }}</span>
      </pcg-box>
    </b-row>
    <pcg-pagination v-model="filters.page"
                    :page-count="pages"
                    :total-records="totalRecords"
                    @input="getMessages"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkMessageSecondary from '../AbkMessageSecondary'
import ApiMessages from '../../api/messages'
import ApiRecipientGroups from '../../api/recipient_groups'
import AbkLoader from '../AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'AbkMessagesList',
  components: { AbkLoader, AbkMessageSecondary },
  mixins: [LoaderDelayMixin],
  props: {
    messagesStatus: String,
    allowCopy: Boolean,
    allowEdit: Boolean,
    allowDelete: Boolean
  },
  data () {
    return {
      messages: [],
      options: {
        recipientGroup: []
      },
      filters: {
        messageType: null,
        recipientGroupId: null,
        page: 1
      },
      pages: 0,
      totalRecords: 0,
      universityStructure: {},
      recipientGroups: [],
      messagesLoaded: false,
      recipientsGroupsLoaded: false,
      universityStructureLoaded: false
    }
  },
  computed: {
    messageType () {
      return [
        { text: this.$t('general.message_type.communique'), value: 'communique' },
        { text: this.$t('general.message_type.sms'), value: 'sms' },
        { text: this.$t('general.message_type.newsletter'), value: 'newsletter' },
        { text: this.$t('general.all'), value: '' }
      ]
    },
    translatedRecipientGroups () {
      return this.recipientGroups.map(recipientGroup => {
        let properties = _.pick(recipientGroup.attributes, ['recipients', 'activityId', 'departmentId',
          'fieldOfStudyId', 'recruitmentYearId', 'typeOfStudiesId', 'specialtyId', 'specializationId',
          'studyModeId'])
        properties = _.omitBy(properties, (value, _key) => value === null || value === false)
        properties = _.toPairs(properties).map(([key, value]) => {
          if (key === 'recipients') {
            return value.map(recipient => this.$t(`components.messages.abk_messages_list.recipient_types.${recipient}`))
          }
          return this.translatedProperty(key, value)
        })
        properties = _.flatten(properties)

        return {
          id: recipientGroup.id,
          properties: properties
        }
      })
    },
    loadingDone () {
      return this.messagesLoaded &&
        this.recipientsGroupsLoaded &&
        this.universityStructureLoaded &&
        this.translatedRecipientGroups
    }
  },
  mounted () {
    this.getMessages()
    this.getRecipientGroups()
    this.getUniversityStructure()
  },
  methods: {
    findRecipientGroupProperties (id) {
      const group = this.translatedRecipientGroups.find(group => group.id === String(id))
      return group ? group.properties : []
    },
    getMessages () {
      ApiMessages.getMessages({ status: this.messagesStatus, ...this.filters })
        .then(response => {
          const { pagination } = response.data.meta
          this.pages = pagination.pages
          this.totalRecords = pagination.count
          this.recipientGroups = response.data.included.filter(inclusion => inclusion.type === 'recipient_group')
          this.messages = response.data.data.map(message => {
            const messageAttachments = response.data.included.filter(inclusion => {
              return message.relationships.messageAttachments.data.map(e => e.id).includes(inclusion.id) &&
                inclusion.type === 'message_attachment'
            })

            return {
              id: message.id,
              ...message.attributes,
              attachments: messageAttachments.map(({ id, attributes }) => {
                return {
                  id: id,
                  filename: attributes.filename
                }
              })
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.messagesLoaded = true
        })
    },
    getRecipientGroups () {
      ApiRecipientGroups.getRecipientGroups()
        .then(response => {
          this.options.recipientGroup = response.data.data.map(recipientGroup => {
            return {
              text: recipientGroup.attributes.name,
              value: recipientGroup.id
            }
          })
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('components.messages.abk_messages_list.download_recipient_groups_error'))
        })
        .finally(() => {
          this.recipientsGroupsLoaded = true
        })
    },
    filterMessages () {
      this.filters.page = 1
      this.getMessages()
    },
    getUniversityStructure () {
      ApiMessages.getUniversityStructure()
        .then(response => {
          this.universityStructure = response.data
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.messages.abk_messages_list.error_university_structure'))
        })
        .finally(() => {
          this.universityStructureLoaded = true
        })
    },
    translatedProperty (key, value) {
      if (Object.keys(this.universityStructure).length === 0) {
        return ''
      }
      const keyMapping = {
        activityId: 'generalType',
        departmentId: 'faculty',
        fieldOfStudyId: 'course',
        typeOfStudiesId: 'type',
        studyModeId: 'kind',
        specialtyId: 'speciality',
        specializationId: 'specialization'
      }

      if (keyMapping[key]) {
        const record = this.universityStructure[keyMapping[key]].find(record => record.id === value)
        return record ? record.name.polish : value
      } else {
        if (key === 'recruitmentYearId') {
          return value
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .filter-bar {
    margin-bottom: $pcg-m-lg;
  }
  .filter-text {
    font-weight: 500;
    color: $pcg-gray;
    margin-right: $pcg-m-md;
  }
  .filter-input {
    margin-top: $pcg-m-sm;
    margin-bottom: $pcg-m-sm;
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .filter-text {
      color: $pcg-gray;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
