<template>
  <b-row>
    <b-col class="col-12 text-right">
      <pcg-btn size="small" @click="goToNewForm">
        {{ $t('views.skills.skill_competences_index.add_button') }}
      </pcg-btn>
    </b-col>

    <b-col class="col-12">
      <abk-loader v-if="showLoader" class="with-margins"/>
      <pcg-table v-else :items="skillCompetences" :fields="fields" class="table">
        <template v-slot:header>{{$t('views.skills.skill_competences_index.table_header')}}</template>
        <template v-slot:cell(competences)="{ index, item: { competences } }">
          <div v-show="!showAllCompetences[index]"
               class="competence-wrapper"
               :class="{ 'with-dots': competencesOverflowing[index] }"
               :ref="`competencesWrapper_${index}`">
            {{ competences.join(', ') }}
          </div>
          <div v-show="showAllCompetences[index]">
            <div v-for="competence in competences" :key="competence" class="competence-record">{{ competence }}</div>
          </div>
          <span v-if="competencesOverflowing[index]"
                class="pcg-link pcg-small"
                @click="toggleCompetences(index)">
            {{ showAllCompetences[index] ? $t('general.hide') : $t('general.expand') }}
          </span>
        </template>
      </pcg-table>
    </b-col>

    <b-col class="col-12">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getSkillCompetences"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </b-col>
  </b-row>
</template>

<script>
import ApiCompetenceSkills from '../../api/competence_skills'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import WindowResizeMixin from '../../mixins/window_resize'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'SkillCompetencesIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, WindowResizeMixin, LoaderDelayMixin],
  data () {
    return {
      skillCompetences: [],
      fields: [],
      labels: [],
      competencesOverflowing: [],
      showAllCompetences: []
    }
  },
  mounted () {
    this.getSkillCompetences()
  },
  watch: {
    skillCompetences () {
      this.checkAllCompetencesOverflowing()
    },
    windowWidth () {
      this.checkAllCompetencesOverflowing()
    }
  },
  methods: {
    toggleCompetences (index) {
      this.$set(this.showAllCompetences, index, !this.showAllCompetences[index])
      this.areCompetencesOverflowing(index)
    },
    checkAllCompetencesOverflowing () {
      for (let i = 0; i < this.skillCompetences.length; ++i) {
        this.areCompetencesOverflowing(i)
      }
    },
    areCompetencesOverflowing (index) {
      this.$nextTick(() => {
        if (!this.showAllCompetences[index]) {
          const element = this.$refs[`competencesWrapper_${index}`]
          this.$set(this.competencesOverflowing, index, element && (element.offsetWidth < element.scrollWidth))
        }
      })
    },
    goToNewForm () {
      this.$router.push({ name: 'competences_skills_edit_path' })
    },
    getSkillCompetences () {
      ApiCompetenceSkills.getSkillCompetences({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.skillCompetences = this.dataTable(response.data.data)
          this.showAllCompetences = []
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.skills.skill_competences_index.title'),
        subtitle: this.$t('views.skills.skill_competences_index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((skill, index) => {
        const attributes = skill.attributes
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            fields.push({
              key: key,
              label: this.$t(`views.skills.skill_competences_index.labels.${key}`)
            })
          })
        }
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "app/javascript/abk/assets/stylesheets/vars";

  ::v-deep .table {
    table {
      table-layout: fixed;
    }
    th, td {
      &:nth-child(1) {
        width: 180px;
      }
    }
  }

  .competence-record {
    margin-bottom: $pcg-m-sm;
  }
  .competence-wrapper {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    &.with-dots {
      &::after {
        padding-left: 2px;
        content: '...';
        display: block;
        background-color: $pcg-white;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .pcg-link {
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .competence-wrapper {
    &.with-dots {
      &::after {
        background-color: $pcg-white;
      }
    }
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
