<template>
  <b-tab :title="tabTitle">
    <b-row class="mb-5">
      <b-col cols="12">
        <abk-list-of-employers :list-of-employers="matchingProfiles" v-if="matchingProfiles.length > 0">
          <template v-slot="profile">
            <div class="record-container">
              <div class="match-text">
                {{`${$t('components.offers.tabs.abk_offer_matching_profiles_tab.match_text')} \
                ${calculatePercentageMatchingCompetences(profile.matchingCompetencesCount)}%`}}
              </div>
              <pcg-btn v-if="!profile.invitedToOffer && companyConsent" size="small" class="m-0" @click="inviteUser(profile.id)">
                {{ $t('components.offers.tabs.abk_offer_matching_profiles_tab.invite') }}
              </pcg-btn>
            </div>
          </template>
        </abk-list-of-employers>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import AbkListOfEmployers from '../../AbkListOfEmployers'
import ApiUsers from '../../../api/users'
import ApiOfferInvitations from '../../../api/offer_invitations'
import { mapGetters } from 'vuex'
export default {
  name: 'AbkOfferMatchingProfilesTab',
  components: { AbkListOfEmployers },
  data () {
    return {
      offerId: null,
      matchingProfiles: [],
      matchingProfilesTotalCount: 0,
      offerCompetencesCount: 0
    }
  },
  methods: {
    getMatchingProfiles () {
      ApiUsers.getMatchingToOffer(this.offerId)
        .then(response => {
          this.setMatchingProfiles(response.data)
        }).catch(error => {
          console.error(error)
        })
    },
    setMatchingProfiles ({ meta, data }) {
      this.matchingProfilesTotalCount = meta.pagination.count
      this.offerCompetencesCount = meta.offerCompetencesCount
      this.matchingProfiles = data.map(({ id, attributes }) => {
        return {
          id: id,
          user_id: attributes.curriculumVitaeId,
          avatar: attributes.avatar,
          name: `${attributes.firstName} ${attributes.lastName}`,
          invitedToOffer: attributes.invitedToOffer,
          matchingCompetencesCount: attributes.matchingCompetencesCount
        }
      })
      this.matchingProfiles = _.orderBy(this.matchingProfiles, 'matchingCompetencesCount', 'desc')
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    inviteUser (userId) {
      ApiOfferInvitations.createOfferInvitation({ userId: userId, offerId: this.offerId })
        .then(() => {
          const user = this.matchingProfiles.find(profile => profile.id === userId)
          this.$toastr.s(this.$t('components.offers.tabs.abk_offer_matching_profiles_tab.invitation_sent'))
          user.invitedToOffer = true
        })
        .catch(error => {
          this.$toastr.e(this.$t('components.offers.tabs.abk_offer_matching_profiles_tab.invitation_error'))
          console.error(error)
        })
    },
    calculatePercentageMatchingCompetences (matchesCount) {
      const intMatchesCount = parseInt(matchesCount)
      const intOfferCompetencesCount = parseInt(this.offerCompetencesCount)
      if (intMatchesCount > 0 && intOfferCompetencesCount > 0) {
        return ((intMatchesCount / intOfferCompetencesCount) * 100).toFixed(0)
      }

      return null
    }
  },
  computed: {
    ...mapGetters('user', ['cvConsent', 'companyConsent']),
    tabTitle () {
      const title = this.$t('components.offers.tabs.abk_offer_matching_profiles_tab.title')
      return `${title} (${this.matchingProfilesTotalCount})`
    }
  },
  mounted () {
    this.offerId = this.$route.params.id
    this.getMatchingProfiles()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .record-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: available;
    margin-left: $pcg-m-lg;
    flex-grow: 1;
  }
  .match-text {
    font-weight: 500;
    color: $main-color;
    margin: $pcg-m-sm $pcg-m-lg $pcg-m-sm 0;
  }

  @media(max-width: 649px) {
    .record-container {
      margin-left: 0;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast {
    .match-text {
      color: $main-color;
    }
  }
</style>
