<template>
  <div>
    <b-col cols="12" class="box mt-3">
      <b-row class="box-header">
        <b-col cols="12" class="mb-4 d-flex justify-content-between">
          <pcg-radio :name="`customCompetenceType-${value.key}`"
                     :show-label="false"
                     class="d-inline-block"
                     :show-error="errorExist('customCompetenceType')"
                     :error-text="getError('customCompetenceType')"
                     :value="value.customCompetenceType"
                     @input="changeCustomCompetenceType($event)"
                     :options="customCompetenceTypeOptions"/>
          <button class="section-btn small float-right" @click="remove()">✕ {{ $t('general.delete') }}</button>
        </b-col>
        <b-col cols="12" xl="6">
          <b-row>
            <pcg-select class="abk-input col-12 form-group"
                        :options="getDictionaryOptions"
                        :value="String(value.customCompetenceId)"
                        @input="updateForm('customCompetenceId', $event)"
                        :error-text="getError('customCompetenceId')"
                        :show-error="errorExist('customCompetenceId')"
                        :show-value-addition="isDictionaryOpen(dictionaryType)"
                        @showModalSuggestion="showModalSuggestion(getDictionaryId(dictionaryType))"/>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <abk-suggest-dictionary-word-modal v-model="showSuggestDictionaryWordModal" :dictionary-id="currentDictionaryId"/>
  </div>
</template>

<script>
import AbkSuggestDictionaryWordModal from '../AbkSuggestDictionaryWordModal'
import SuggestingDictionaryWordsMixin from '../../mixins/suggesting_dictionary_words'
export default {
  name: 'AbkCvCustomCompetenceFormSection',
  components: { AbkSuggestDictionaryWordModal },
  mixins: [SuggestingDictionaryWordsMixin],
  props: {
    index: Number,
    value: Object,
    errors: Object,
    loading: Boolean,
    submitted: Boolean,
    dictionariesOptions: Object,
    dictionariesMetaInfo: Object
  },
  data () {
    return {
      showAddAttachment: false,
      customCompetenceTypeOptions: [
        {
          text: this.$t('components.curriculum_vitaes.abk_cv_custom_competence_form_section.custom_competence_type.foreign_language'),
          value: 'foreign_language'
        },
        {
          text: this.$t('components.curriculum_vitaes.abk_cv_custom_competence_form_section.custom_competence_type.driving_license'),
          value: 'driving_license'
        },
        {
          text: this.$t('components.curriculum_vitaes.abk_cv_custom_competence_form_section.custom_competence_type.other'),
          value: 'other'
        }
      ]
    }
  },
  computed: {
    dictionaryType () {
      return (this.value.customCompetenceType === 'other') ? 'cv_custom_competence_other' : this.value.customCompetenceType
    },
    getDictionaryOptions () {
      if (this.dictionaryType) {
        return this.dictionariesOptions[this.dictionaryType]
      } else {
        return []
      }
    }
  },
  methods: {
    changeCustomCompetenceType (newCustomCompetenceType) {
      this.updateFormObject({
        customCompetenceType: newCustomCompetenceType,
        customCompetenceId: null
      })
      // this.updateForm('customCompetenceType', newCustomCompetenceType)
      // this.updateForm('customCompetenceId', null)
    },
    getDictionaryId (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryId : null
    },
    isDictionaryOpen (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryType === 'open' : false
    },
    remove () {
      this.$emit('remove')
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[`cvCustomCompetences.${this.index}.${field}`].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[`cvCustomCompetences.${this.index}.${field}`])
    },
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateFormObject (object) {
      this.$emit('input', { ...this.value, ...object })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  h2 {
    font-size: 1em;
    color: $pcg-gray;
    width: fit-content;
    margin: 0 1em 0 0;
  }
  .title {
    text-transform: uppercase;
  }
  .box {
    border: 2px dashed $pcg-light-gray;
    border-radius: 15px;
  }
  .section-btn {
    white-space: nowrap;
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.remove-subsection-btn {
      height: fit-content;
      position: absolute;
      top: 12px;
      bottom: 0;
      right: -53px;
      margin: 0 auto 10px;
    }
    &.small {
      font-size: $font-size-s;
    }
  }
  .box-header {
    padding: 30px 25px;
  }
  .subsection {
    border-top: 2px solid $pcg-lightest-gray;
    padding: $pcg-m-xl 25px $pcg-m-sm;
  }
  /*/deep/ .form-group {*/
  /*.pcg-input-label {*/
  /*display: none;*/
  /*}*/
  /*}*/
  .content-textarea {
    height: 100%;
    /deep/ textarea {
      height: 100%;
    }
  }
  @media (max-width: $screen-md-min) {
    .box-header, .subsection {
      padding: 20px 5px;
    }
  }

  @media (max-width: $screen-xlg-min) {
    .subsection {
      padding-top: 40px;
    }
    .remove-subsection-btn {
      top: -30px!important;
      right: 0!important;
    }
  }

  .images {
    padding: 0 25px;
  }

  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
  .close {
    margin-left: 10px;
    max-height: 21px;
    user-select: none;
    float: none;
    &:hover {
      cursor: pointer;
    }
  }
  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>
