<template>
  <div>
    <b-row class="form-container">
      <b-col cols="12" xl="8" class="mb-4 mb-xl-0">
        <div class="form-group">
          <label class="pcg-input-label">
            {{ $t('components.abk_new_page_form.permissions') }}
          </label>
          <div>
            <pcg-checkbox v-for="role in accessRoles"
                          :value="isRoleChecked(role)"
                          @input="updateRoles(role, $event)"
                          :key="role">
              {{ $t(`components.abk_new_page_form.access_roles.${role}`) }}
            </pcg-checkbox>
            <div v-show="errorExist('roles')" class="pcg-error-messages">{{ getError('roles') }}</div>
          </div>
        </div>
        <pcg-select class="mb-4"
                    required
                    :error-text="getError('language')"
                    :show-error="errorExist('language')"
                    :label="$t('components.abk_new_page_form.language')"
                    :options="languageOptions"
                    :value="value.language"
                    @input="updateForm('language', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        :label="$t('components.abk_new_page_form.page_title')"
                        :value="value.title"
                        :error-text="getError('title')"
                        :show-error="errorExist('title')"
                        @input="updateForm('title', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        :label="$t('components.abk_new_page_form.navigation')"
                        :value="value.navigation"
                        :error-text="getError('navigation')"
                        :show-error="errorExist('navigation')"
                        @input="updateForm('navigation', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        :label="$t('components.abk_new_page_form.address')"
                        :value="value.address"
                        :error-text="getError('address')"
                        :show-error="errorExist('address')"
                        @input="updateForm('address', $event)"/>
        <pcg-select class="mb-4"
                    v-if="value.language"
                    :error-text="getError('parentId')"
                    :show-error="errorExist('parentId')"
                    :label="$t('components.abk_new_page_form.parent')"
                    :options="parentSelectOptions"
                    :value="value.parentId"
                    @input="updateForm('parentId', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        :min="0"
                        :error-text="getError('sort')"
                        :show-error="errorExist('sort')"
                        :label="$t('components.abk_new_page_form.sort')"
                        :value="value.sort"
                        @input="updateForm('sort', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        type="textarea"
                        class="description-input"
                        :error-text="getError('metaDescription')"
                        :show-error="errorExist('metaDescription')"
                        :label="$t('components.abk_new_page_form.metaDescription')"
                        :value="value.metaDescription"
                        @input="updateForm('metaDescription', $event)"/>
        <pcg-text-input :show-label="true"
                        required
                        type="textarea"
                        class="keywords-input"
                        :error-text="getError('keywords')"
                        :show-error="errorExist('keywords')"
                        :label="$t('components.abk_new_page_form.keywords')"
                        :value="value.keywords"
                        @input="updateForm('keywords', $event)"/>
        <div class="form-group">
          <label class="pcg-input-label">
            {{ $t('components.abk_new_page_form.forum') }}
          </label>
          <div>
            <pcg-checkbox :value="value.forum" @input="updateForm('forum', $event)">
            </pcg-checkbox>
            <div v-show="errorExist('forum')" class="pcg-error-messages">{{ getError('forum') }}</div>
          </div>
        </div>
        <b-row>
          <div class="col-12 mb-4 image-input">
            <span class="file-input-label">{{ $t('components.abk_new_page_form.image') }}</span>
            <abk-file-input ref="image"
                            :fileUrl="getImageUrl('image')"
                            @input="updateForm('image', $event)"
                            :status="imageUploadStatus('image')"
                            :error-text="getError('image')"
                            v-b-tooltip.hover
                            :title="getError('image')"
                            :accept="acceptableExtensions"
                            show-remove-button
                            @remove="removeField('image')"/>
          </div>
        </b-row>
        <b-row>
          <abk-new-page-content-form class="col-12 mb-4"
                                     required
                                     :error-text="getError('content')"
                                     :show-error="errorExist('content')"
                                     :value="value.content"
                                     @input="updateForm('content', $event)"/>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 pl-4 pl-xl-5 mb-3">
        <label class="attachment-input" @click="addNewAttachment">
          <i class="pcg-icon-clip mr-3"></i> {{ $t('components.abk_attachment_input.text') }}
        </label>
      </b-col>
      <b-col v-show="!webpageAttachment._destroy && !webpageAttachment.id"
             class="col-12 col-md-6 col-xl-4"
             :key="webpageAttachment.key"
             v-for="(webpageAttachment, index) in value.webpageAttachments">
        <div class="attachment-container">
          <abk-file-input show-remove-button
                          @remove="removeAttachment(webpageAttachment, index)"
                          @input="handleFile(webpageAttachment, $event, index)"
                          :status="fileUploadStatus(index)"
                          v-b-tooltip.hover
                          :title="getError(`webpageAttachments.${index}.attachment`)"
                          :error-text="getError(`webpageAttachments.${index}.attachment`)"/>
        </div>
      </b-col>
      <b-col class="col-12">
        <div v-show="showAttachment(webpageAttachment)"
             :key="webpageAttachment.key"
             v-for="(webpageAttachment, index) in value.webpageAttachments">
          {{ webpageAttachment.filename }}
          <span class="close" @click="removeAttachment(webpageAttachment, index)">✕</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AbkNewPageContentForm from './AbkNewPageContentForm'
import ApiWebpages from '../api/webpages'
import AbkFileInput from './AbkFileInput'

export default {
  name: 'AbkNewPageForm',
  components: { AbkFileInput, AbkNewPageContentForm },
  props: {
    value: Object,
    errors: Object,
    loading: Boolean,
    submitted: Boolean
  },
  data () {
    return {
      parentSelectOptions: [{ text: '', value: null }],
      languageOptions: [
        { text: this.$t('general.languages.polish'), value: 'pl' },
        { text: this.$t('general.languages.english'), value: 'en' }
      ],
      accessRoles: ['student', 'graduate', 'career_office', 'employer', 'recruiter', 'public'],
      localErrors: this.errors,
      acceptableExtensions: '.jpg,.jpeg,.gif,.png,.svg'
    }
  },
  computed: {
    maxKey () {
      return Math.max(...this.value.webpageAttachments.map((attachment) => attachment.key ? attachment.key : 0), 0)
    }
  },
  methods: {
    updateForm (key, value) {
      if (key === 'image') {
        if (value.size > 4200000) {
          this.removeField(key)
          this.localErrors[key] = [this.$t('components.abk_new_page_form.max_size')]
        } else {
          this.localErrors[key] = null
          delete this.value[this.removeFieldName(key)]
          this.$emit('input', { ...this.value, [key]: value })
        }
      } else {
        this.$emit('input', { ...this.value, [key]: value })
      }
    },
    generateKey () {
      return this.maxKey + 1
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.localErrors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.localErrors && this.localErrors[field])
    },
    getTree () {
      ApiWebpages.getTree(null, null, null, this.value.language)
        .then(response => {
          this.parentSelectOptions = [
            { text: '', value: null },
            ...this.generateParentSelectOptions(response.data.data, 0)
          ]
        }).catch(error => {
          console.log(error)
        })
    },
    generateParentSelectOptions (webpages, level) {
      if (level > 1) {
        return []
      }
      let result = []
      webpages.forEach((webpage, index) => {
        result.push({ text: `${'    '.repeat(level)}${webpage.title}`, value: webpage.id })
        if (webpage.children.length > 0) {
          result = result.concat(this.generateParentSelectOptions(webpage.children, level + 1))
        }
      })
      return result
    },
    isRoleChecked (checkboxRole) {
      return this.value.roles && Array.isArray(this.value.roles) && this.value.roles.includes(checkboxRole)
    },
    updateRoles (checkboxRole, event) {
      if (event) {
        this.updateForm('roles', [...this.value.roles, checkboxRole])
      } else {
        this.updateForm('roles', this.value.roles.filter(accessRole => accessRole !== checkboxRole))
      }
    },
    addNewAttachment () {
      this.updateForm('webpageAttachments', [...this.value.webpageAttachments, { id: null, attachment: null, key: this.generateKey() }])
    },
    removeAttachment (attachment, index) {
      if (attachment.id) {
        attachment._destroy = true
        this.updateForm('webpageAttachments', this.value.webpageAttachments)
      } else {
        const copy = [...this.value.webpageAttachments]
        copy.splice(index, 1)
        this.updateForm('webpageAttachments', copy)
        // attachment['_destroy'] = true
        // this.updateForm('webpageAttachments',  this.value.webpageAttachments)
        if (this.errorExist(`webpageAttachments.${index}.attachment`)) {
          delete this.errors[`webpageAttachments.${index}.attachment`]
        }
        this.value.webpageAttachments.forEach((attachment, attachmentIndex) => {
          if (attachmentIndex > index) {
            if (this.errorExist(`webpageAttachments.${attachmentIndex}.attachment`)) {
              this.errors[`webpageAttachments.${attachmentIndex - 1}.attachment`] = this.errors[`webpageAttachments.${attachmentIndex}.attachment`]
              delete this.errors[`webpageAttachments.${attachmentIndex}.attachment`]
            }
          }
        })
      }
    },
    showAttachment (attachment) {
      return attachment.id && !attachment._destroy
    },
    handleFile (attachment, file, index) {
      attachment.attachment = file
      this.updateForm('webpageAttachments', this.value.webpageAttachments)
      if (this.errorExist(`webpageAttachments.${index}.attachment`)) {
        delete this.errors[`webpageAttachments.${index}.attachment`]
      }
    },
    fileUploadStatus (index) {
      if (this.loading) {
        return 'loading'
      } else if (this.errorExist(`webpageAttachments.${index}.attachment`)) {
        return 'fail'
      } else if (this.value.webpageAttachments[index].attachment) {
        return 'success'
      }
      return 'upload'
    },
    imageUploadStatus (field) {
      if (this.localErrors[field]) {
        return 'fail'
      } else if (this.isImagePresent(field)) {
        return 'success'
      }
      return 'upload'
    },
    isImagePresent (field) {
      return this.value[field] instanceof File || (this.value[field] && this.value[field].url)
    },
    getImageUrl (field) {
      return this.value[field] ? this.value[field].url : null
    },
    removeFieldName (field) {
      return `remove${_.upperFirst(field)}`
    },
    removeField (field) {
      this.localErrors[field] = null
      this.$refs[field].clear()
      this.$emit('input', { ...this.value, [field]: null, [this.removeFieldName(field)]: true })
    }
  },
  watch: {
    'value.language' () {
      this.updateForm('parentId', null)
      this.getTree()
    },
    errors (newValue) {
      this.localErrors = newValue
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .form-container {
    margin-bottom: $pcg-m-xxl;
  }
  .form-group {
    height: fit-content;
    margin-bottom: 1.5rem;
    /deep/ .pcg-textarea {
      height: 100%!important;
    }
  }
  .description-input /deep/ textarea{
    min-height: 150px;
  }
  .keywords-input /deep/ textarea {
    min-height: 120px;
  }

  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }

  .attachment-container {
    display: flex;
  }
  .close {
    margin-left: 10px;
    max-height: 21px;
    user-select: none;
    float: none;
    &:hover {
      cursor: pointer;
    }
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }

  .file-input-label {
    font-weight: 500;
    color: $pcg-gray;
    z-index: 10;
    top: 5px;
    left: 20px;
    position: absolute;
  }

  @media(max-width: $screen-xlg-min) {
    .image-input:not(:last-child) {
      margin-bottom: $pcg-m-lg !important;
    }
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .attachment-input {
      color: $main-active-color;
    }
    .file-input-label {
      color: $pcg-light-gray;
    }
  }
</style>
