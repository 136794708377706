<template>
  <div class="offers">
    <b-row>
      <b-col>
        <pcg-search @searchEvent="searchKeyword" v-model="keyword"></pcg-search>
      </b-col>
    </b-row>
    <b-row class="align-items-center">
      <b-col class="col-12 col-xl-4 sort-pagination-container">
        <div>
          <pcg-pagination-summary class="pagination-summary"
                                  :currentPage="currentPage"
                                  :perPage="perPage"
                                  :totalRecords="totalRecords">
          </pcg-pagination-summary>
          <pcg-sort-btn class="sort-button" v-model="sort" :options="sortOptions" @input="sortOffers">
            {{ $t('views.offers.offers_index.sort_btn') }}
          </pcg-sort-btn>
        </div>
      </b-col>
      <b-col>
        <abk-search-active-filters :path="serachPath"
                                   :dictionaries="options"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <abk-save-offer-filter :id="savedOfferFilter" :search="search" @toggle="toggleSavedOfferFilter"/>
      </b-col>
    </b-row>
    <b-row>
      <abk-search-filter-list class="col-12 col-lg-5 col-xl-4 d-none d-md-block"
                              :path="serachPath"
                              :dictionaries="options"
                              :filters="parsedFilters"/>
      <pcg-btn class="d-block d-md-none w-100" @click="showFiltersModal = true">
        {{ $t('views.offers.offers_index.show_filters') }}
      </pcg-btn>
      <b-col class="col-12 col-lg-7 col-xl-8">
        <b-row>
          <abk-work-record :workRecords="offers"
                           :dictionaries="options"
                           class="col-12"
                           :show-loader="showOffersLoader"/>

          <pcg-pagination v-model="currentPage"
                          :page-count="pages"
                          :total-records="totalRecords"
                          class="col-12 justify-content-center my-pagination"/>
        </b-row>

      </b-col>

      <b-col class="col-12 col-lg-7 col-xl-8 offset-lg-5 offset-xl-4 text-center">
      </b-col>
    </b-row>
    <b-row>
      <abk-search-new-offers :offers="newOffers"></abk-search-new-offers>
    </b-row>
    <abk-mobile-btn v-if="showFiltersModal"
                    :cancel="true"
                    @click="hideModalAndLoadOffers"
                    @cancel="showFiltersModal = false">
      {{ $t('general.show_results') }}
      <template v-slot:cancel>
        {{ $t('general.cancel') }}
      </template>
    </abk-mobile-btn>
    <abk-search-filter-modal v-model="showFiltersModal"
                             :path="serachPath"
                             :dictionaries="options"
                             :filters="parsedFilters"/>
  </div>
</template>

<script>
import AbkSearchFilterList from '../../components/AbkSearchFilterList'
import AbkSearchActiveFilters from '../../components/AbkSearchActiveFilters'
import AbkSearchNewOffers from '../../components/AbkSearchNewOffers'
import AbkWorkRecord from '../../components/AbkWorkRecord'
import ApiOffers from '../../api/offers'
import ApiOfferFilters from '../../api/offer_filters'
import ApiCompanies from '../../api/companies'
import ApiDictionaries from '../../api/dictionaries'
import { decamelize } from '@ridi/object-case-converter'
import AbkSaveOfferFilter from '../../components/offers/AbkSaveOfferFilter'
import AbkMobileBtn from '../../components/AbkMobileBtn'
import gridBreakpoints from '../../helpers/grid_breakpoints'
import AbkSearchFilterModal from '../../components/offers/AbkSearchFilterModal'

export default {
  name: 'OffersIndex',
  components: {
    AbkSearchFilterModal,
    AbkMobileBtn,
    AbkSaveOfferFilter,
    AbkWorkRecord,
    AbkSearchNewOffers,
    AbkSearchActiveFilters,
    AbkSearchFilterList
  },
  data: function () {
    return {
      dictionaries: ['workplace', 'trade', 'position_type', 'type_of_work', 'work_dimension'],
      filters: {},
      offers: [],
      newOffers: [],
      currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      totalRecords: 0,
      pages: 0,
      perPage: 10,
      savedOfferFilter: null,
      serachPath: 'offers_path',
      keyword: '',
      sort: { orderBy: 'publication_date', orderDirection: 'desc' },
      sortOptions: [
        { text: this.$t('general.date_added'), value: 'publication_date' },
        { text: this.$t('general.time_to_end'), value: 'expiration_date' }
      ],
      options: {
        offer_type: [
          { text: this.$t('general.job'), value: 'job' },
          { text: this.$t('general.training'), value: 'training' },
          { text: this.$t('general.practice'), value: 'practice' }
        ]
      },
      showFiltersModal: false,
      showOffersLoader: true
    }
  },
  computed: {
    search () {
      return this.$route.query && this.$route.query.search ? this.$route.query.search : {}
    },
    parsedFilters () {
      return decamelize(this.filters, { recursive: true })
    }
  },
  watch: {
    search (newValue, oldValue) {
      if (!_.isEqual(oldValue, newValue)) {
        this.currentPage = 1
      }
      if (window.innerWidth >= gridBreakpoints.screenMdMin) {
        this.loadOffers()
      }
      this.keyword = newValue && newValue.keyword ? newValue.keyword : ''
    }
  },
  created () {
    ApiDictionaries.getSelectedDictionaries(this.dictionaries)
      .then(responses => {
        this.dictionaries.forEach((dictionary, index) => {
          if (responses[index] && responses[index].data && responses[index].data.data) {
            const options = responses[index].data.data.map(({ id, attributes }) => {
              return { text: attributes.value, value: id }
            })
            this.$set(this.options, dictionary, options)
          }
        })
      })
      .catch(() => {
        this.$toastr.e(this.$t('general.error_try_later'))
      })

    ApiCompanies.getCompaniesNames()
      .then(response => {
        const options = response.data.data.map(company => {
          return { value: company[0], text: company[1] }
        })
        this.$set(this.options, 'company', options)
      })
      .catch(() => {
        this.$toastr.e(this.$t('general.error_try_later'))
      })
  },
  mounted () {
    const search = (this.$route.query && this.$route.query.search) ? Object.assign({}, this.$route.query.search) : {}
    this.$router.replace({ name: 'offers_path', query: { search, page: this.currentPage } }).catch(() => {})

    this.loadOffers()
    this.loadNewOffers()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_index.title')
      })
    },
    hideModalAndLoadOffers () {
      this.loadOffers()
      this.showFiltersModal = false
    },
    toggleSavedOfferFilter () {
      if (this.savedOfferFilter) {
        ApiOfferFilters.removeOfferFilter(this.savedOfferFilter)
          .then(response => {
            this.savedOfferFilter = null
            this.$store.dispatch('user/getCatalogOfferCounters')
          })
          .catch(error => {
            // TODO Wyświetlanie błędów
            console.log(error.response)
          })
      } else {
        ApiOfferFilters.createOfferFilter(this.search)
          .then(response => {
            this.savedOfferFilter = response.data.data.id
            this.$store.dispatch('user/getCatalogOfferCounters')
          })
          .catch(error => {
            // TODO Wyświetlanie błędów
            console.log(error.response)
          })
      }
    },
    loadOffers () {
      ApiOffers.getOffers({
        ...decamelize(this.search, { recursive: true }),
        ...decamelize(this.sort),
        ...{ page: this.currentPage }
      })
        .then(response => {
          if (response.data && response.data.meta && response.data.meta.pagination) {
            this.currentPage = response.data.meta.pagination.page
            this.totalRecords = response.data.meta.pagination.count
            this.perPage = response.data.meta.pagination.perPage
            this.pages = response.data.meta.pagination.pages
          }
          if (response.data && response.data.meta && response.data.meta.aggregations) {
            const newFilters = {}
            Object.keys(response.data.meta.aggregations).forEach(key => {
              newFilters[key] = response.data.meta.aggregations[key].buckets.map(bucket => {
                return {
                  key: bucket.key,
                  value: bucket.key,
                  docCount: bucket.docCount
                }
              })
            })
            this.filters = newFilters
          }
          if (response.data && response.data.meta && response.data.meta) {
            this.savedOfferFilter = response.data.meta.savedOfferFilterId
          }

          this.offers = response.data.data.map(({ id, attributes: { expirationDate, ...rest } }) => {
            return { id, ...rest }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
        .finally(() => {
          this.showOffersLoader = false
        })
    },
    loadNewOffers () {
      ApiOffers.getRecentOffers(4)
        .then(response => {
          this.newOffers = response.data.data.map(offer => { return { id: offer.id, ...offer.attributes } })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    searchKeyword (keyword) {
      const search = (this.$route.query && this.$route.query.search) ? Object.assign({}, this.$route.query.search) : {}
      if (keyword.length > 0) {
        search.keyword = [keyword]
      } else {
        search.keyword = []
      }
      this.$router.push({ name: 'offers_path', query: { search } }).catch(() => {})
    },
    sortOffers () {
      this.loadOffers()
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .control-container {
    margin-bottom: 30px;
  }
  .sort-pagination-container {
    margin-top: 12px;
    min-height: 40px;
    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .pcg-sort-btn, .pagination-summary{
        white-space: nowrap;
      }
    }
  }

  .pagination-summary {
    margin-right: 25px;
  }

  /deep/ .filter-offers-button-container {
    z-index: 1060;
  }

  @media(max-width: $screen-xlg-min) {
    .sort-pagination-container {
      margin-top: 0;
    }
  }

/*  /deep/ .pcg-search {
    input {
      font-size: 1rem;
      border-radius: 20px;
      padding: 1.5rem 2.4rem;
      padding-right: 4.5rem;
      height: calc(3rem + 1rem + 2px);
      color: $pcg-gray;
    }
    span {
      font-size: 1.6rem;
      margin-right: 27px;
      margin-top: -39px;
      margin-top: -2.8rem;
      margin-right: 1.9rem;
      color: $pcg-light-gray;
    }
  }*/
</style>

<style scoped lang="scss">
@import "app/javascript/abk/assets/stylesheets/vars-contrast";
.contrast{
  .sort-button {
    color: $pcg-gray;
    /deep/ .bootstrap-select .dropdown-toggle {
      color: $main-color !important;
      border: none;
    }
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
