<template>
  <div>
    <b-tab :title="tabTitle">
      <b-row class="mb-5">
        <b-col cols="12">
          <abk-list-of-employers :list-of-employers="applications" v-if="applications.length > 0"/>
        </b-col>
      </b-row>
    </b-tab>
    <pcg-pagination v-model="currentPage"
                    :page-count="pages"
                    :total-records="totalRecords"
                    @input="getOfferApplications"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkListOfEmployers from '../../AbkListOfEmployers'
import ApiOffers from '../../../api/offers'
export default {
  name: 'AbkOfferApplicationsTab',
  components: { AbkListOfEmployers },
  data () {
    return {
      offerId: null,
      applications: [],
      applicationsCount: 0,
      currentPage: 1,
      pages: 0,
      totalRecords: 0
    }
  },
  methods: {
    getOfferApplications () {
      ApiOffers.getOfferApplications(this.offerId, this.queryParams)
        .then(response => {
          const pagination = response.data.meta.pagination

          this.pages = pagination.pages
          this.totalRecords = pagination.count
          this.setApplications(response.data)
        })
    },
    setApplications ({ meta, included, data }) {
      this.applicationsCount = meta.pagination.count
      this.applications = data.map(({ id, attributes, relationships }) => {
        const user = included.find(e => e.id === relationships.user.data.id)
        return {
          user_id: user.attributes.curriculumVitaeId,
          queryParams: { offerApplicationId: id },
          avatar: user.attributes.avatar,
          date: this.formattedDate(attributes.createdAt),
          name: [user.attributes.firstName, user.attributes.lastName].join(' '),
          attachments: attributes.offerApplicationAttachments
        }
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    }
  },
  computed: {
    tabTitle () {
      return `${this.$t('components.offers.tabs.abk_offer_applications_tab.title')} (${this.applicationsCount})`
    },
    queryParams () {
      return { page: this.currentPage }
    }
  },
  mounted () {
    this.offerId = this.$route.params.id
    this.getOfferApplications()
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>