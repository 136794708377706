<template>
  <div>
    <abk-loader v-if="showLoader"/>
    <div v-else>
      <div class="attendance-event">
        <span style="margin-right: 1rem;">{{ event.title }}</span>
        <span>{{ event.duration }}</span>
      </div>
      <div style="margin-bottom: 1rem;">
        <pcg-btn @click="getAttendanceList" size="small">
          <i v-if="listInProgress" class="fa fa-spinner fa-spin"/>
          <span v-if="status === 'main'" :class="{'ml-2': listInProgress }">{{ $t('views.attendances.download_list') }}</span>
          <span v-else-if="status === 'reserve'" :class="{'ml-2': listInProgress }">{{ $t('views.attendances.download_reserve_list') }}</span>
          <span v-else-if="status === 'signout'" :class="{'ml-2': listInProgress }">{{ $t('views.attendances.download_sign_out_list') }}</span>
        </pcg-btn>
      </div>
      <pcg-box class="list-of-students">
        <template v-if="listOfStudents.length > 0">
          <div v-for="(record, index) in listOfStudents" :key="`record-${index}`" class="oneline d-flex justify-content-between">
            <div class="one-row d-flex">
              <pcg-avatar size="extra-small" class="mr-3" :img="getAvatar(record.avatar)"/>
              <div>
                <router-link :to="{ name: 'curriculum_vitae_show_path', params: { id: record.cvId } }">
                  <span class="name mr-4" :style="[ record.avatar ? { 'font-weight': 'bold' } : { 'font-weight': '400' } ]">
                    {{ record.name }}
                  </span>
                </router-link>
                <span class="name mr-4">
                  {{ record.email }}
                </span>
                <span class="name mr-4">
                  {{ record.phone }}
                </span>
              </div>
            </div>
            <div v-if="status === 'main'">
              <div class="one-row d-flex attendance-container" v-if="showActions && attendanceMandatory">
                <div class="checkbox-container">
                  <pcg-checkbox class="checkbox" v-model="record.attendance" @input="attendanceUser(record)">
                    {{ $t('views.attendances.actions.present') }}
                  </pcg-checkbox>
                </div>
              </div>
              <div class="one-row d-flex attendance-container" v-if="!showActions && attendanceMandatory">
                <pcg-checkbox class="checkbox" v-model="record.attendance" disabled="true">
                  {{ $t('views.attendances.actions.present') }}
                </pcg-checkbox>
              </div>
              <slot v-bind="record"></slot>
            </div>
            </div>
        </template>
        <span v-else class="pcg-no-records">{{ noRecordsText }}</span>
      </pcg-box>
    </div>
  </div>
</template>

<script>
import AbkLoader from '../AbkLoader'
import ApiAttendance from '../../api/attendances'

export default {
  name: 'abk-list-of-students',
  components: { AbkLoader },
  props: {
    listOfStudents: {
      type: Array,
      default: () => []
    },
    showActions: {
      type: Boolean,
      default: false
    },
    noRecordsText: String,
    showLoader: Boolean,
    attendanceMandatory: Boolean,
    event: Object,
    status: String
  },
  data () {
    return {
      listInProgress: false
    }
  },
  methods: {
    getAvatar (avatar) {
      return avatar && avatar.url ? avatar.url : require('../../assets/images/avatar-placeholder.png')
    },
    attendanceUser (record) {
      ApiAttendance.attendentStudent(record)
        .then(() => {})
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    getAttendanceList () {
      this.listInProgress = true
      ApiAttendance.getAttendanceListXlsx(this.$route.params.event, this.status)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'attendance.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          this.$toastr.e(error.response)
        }).finally(() => {
          this.listInProgress = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .oneline {
    padding-bottom: 10px;
    flex-wrap: wrap;
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .name {
    color: $main-color;
  }
  .one-row {
    align-items: center;
    flex-wrap: wrap;
  }
  .information {
    color: $pcg-dark-gray;
    margin-left: 15px;
  }
  .firm {
    color: $main-color;
    font-weight: bold;
    margin-left: 15px;
  }
  .reject {
    color: $main-active-color;
    margin-left: 5px;
    font-weight: 400;
    &:hover {
      cursor: pointer;
    }
  }
  .attachment-icon {
    margin-left: $pcg-m-sm;
    cursor: pointer;
    color: $pcg-gray;
  }
  .offer-type {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-gray;
  }
  .attendance-event {
    font-weight: bold;
    font-size: $font-size-xl;
    color: $pcg-dark-gray;
    margin-bottom: 2rem;
    margin-left: 1rem;
  }
  .attendance-container {
    position: relative;
    top: .3rem;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .oneline {
       &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .name {
      color: $main-color;
    }
    .information {
      color: $pcg-dark-gray;
    }
    .firm {
      color: $main-color;
    }
    .reject {
      color: $main-active-color;
    }
    .attachment-icon {
      color: $pcg-gray;
    }
    .offer-type {
      color: $pcg-gray;
    }
    .attendance-event {
      color: $pcg-dark-gray;
    }
  }
</style>
