<template>
  <abk-messages-list messages-status="draft" allow-edit allow-delete/>
</template>

<script>
import AbkMessagesList from '../../../components/messages/AbkMessagesList'
export default {
  name: 'DraftMessagesTab',
  components: { AbkMessagesList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.messages.index_tabs.draft_messages_tab.title')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
