export const general = {
  remove_account: 'Remove account',
  account_removed: 'Account removed',
  accept: 'Accept',
  accept_event: 'Accept event',
  accept_offer: 'Accept offer',
  accept_employer: 'Accept employer',
  accuracy: 'Accuracy',
  action_cannot_be_reverted: 'Action cannot be reverted',
  add: 'Add',
  add_attachment: 'Add attachment',
  add_event: 'Add event',
  add_offer: 'Add offer',
  add_user: 'Add user',
  add_value: 'Add value',
  all: 'All',
  answer: 'Answer',
  apply: 'Apply',
  apprenticeship: 'Apprenticeship',
  are_you_sure: 'Are you sure?',
  back: 'Back',
  back_to_edit: 'Back to edit',
  cancel: 'Cancel',
  cancel_event: 'Cancel event',
  cancel_sub_event: 'Cancel date',
  career_office_team: 'Career office team',
  change: 'Change',
  change_order: 'Change order',
  change_student_to_graduate: {
    confirm: 'Convert account',
    dismiss: 'Create a new account',
    title: 'Existing account',
    text: 'An existing student account was found with this email address. Do you want to join this account and convert it into an alumni account?'
  },
  channel: 'Channel',
  check_errors: 'Check errors',
  clear: 'Clear',
  close: 'Close',
  close_survey: 'Close survey',
  collapse: 'Collapse',
  collapse_all: 'Collapse all',
  confirmed: 'Confirmed',
  company: 'Company',
  company_popup: {
    go_to_company_edit: 'Edit company profile',
    title: 'Company profile edit',
    text: 'In order to add new offers your company needs to be accepted by the career office member. ' +
      'Please fill company profile data.'
  },
  competence_deleted: 'Competence was deleted.',
  competence_updated: 'Competence was updated.',
  confirm: 'Confirm',
  content: 'Content',
  copy: 'Copy',
  cookies_information: 'Information about COOKIES files',
  cookies_policy: 'Please be advised that in order to optimize the content available on our website and adapt it to your individual needs, ' +
    'we use information saved using cookies on end users\' devices. The cookie files can be controlled by the user through ' +
    'the settings of his web browser. By continuing to use our website without changing the browser settings, the user accepts the use of cookies.',
  currency: {
    pln: 'PLN'
  },
  current_version: 'Current version',
  curriculum_vitae_popup: {
    go_to_cv_edit: 'Go to the CV edition',
    text: 'Completing your CV',
    title: 'To take full advantage of the application, complete the required information.'
  },
  create_new: 'Create new',
  date_added: 'date added',
  date_of_publication: 'date published',
  days_left: '| 1 day left | {count} days left | {count} days left',
  delete: 'Delete',
  delete_message: 'Delete message',
  delete_note: 'Delete note',
  destroy_survey: 'Delete survey',
  dictionary_updated: 'Dictionary was updated.',
  dictionary_word_updated: 'Term was updated.',
  discard_changes: 'Dsicard changes',
  edit: 'Edit',
  edited: 'Edited',
  employer_recruiter: 'Employer/Recruiter',
  end_date: 'End date',
  error_try_later: 'There was an unexpected error. Please try again later.',
  event: 'Event',
  event_type: {
    counseling: 'Counseling',
    fair: 'Fair',
    training: 'Training',
    rally: 'Rally',
    other: 'Other'
  },
  events: {
    statuses: {
      accepted: 'Accepted',
      canceled: 'Canceled',
      draft: 'Draft',
      finished: 'Finished',
      for_acceptance: 'Waiting',
      recent: 'Recent',
      rejected: 'Rejected',
      published: 'Published'
    }
  },
  expand: 'Expand',
  expand_all: 'Expand all',
  fields_not_filled: 'Some fields were not filled correctly. Please check errors and try again.',
  no_captcha: 'You need to confirm that You are not a robot',
  finish: 'End',
  finish_event: 'End event',
  finish_offer: 'End offer',
  forbidden: 'You do not have permission to perform this action.',
  from: 'form',
  full_time: 'Full-time',
  full_name: 'Name and surname',
  gallery: 'Gallery',
  hello_world: 'Hello world!',
  hide: 'Hide',
  in: 'in',
  job: 'Job',
  languages: {
    english: 'English',
    polish: 'Polish'
  },
  list: 'List type',
  list_type: {
    basic: 'Basic list',
    reserve: 'Reserve list'
  },
  localization: 'Location',
  logged_out: 'You were logged out',
  log_in: 'Log in',
  make_copy: 'Copy',
  make_offer: 'Make an offer',
  message_sent: 'Message was send',
  message_type: {
    communique: 'Communique',
    newsletter: 'Newsletter',
    sms: 'SMS'
  },
  missing_approvals: 'In order to log in to the system, you must select the necessary consents',
  new_message: 'New message',
  new_note: 'New note',
  no_access: 'No access',
  noname: 'Noname',
  number: 'Number',
  offer: 'Offer',
  offer_type: {
    job: 'Job',
    practice: 'Practice',
    training: 'Training'
  },
  offers: {
    statuses: {
      accepted: 'Accepted',
      draft: 'Draft',
      finished: 'Finished',
      for_acceptance: 'Waiting',
      recent: 'Recent',
      rejected: 'Rejected',
      published: 'Published'
    }
  },
  or: 'or',
  old_version: 'Previous version',
  other: 'Other',
  page_not_found: 'Page not found',
  part_time: 'part time',
  practice: 'Practice',
  preview: 'Preview',
  preview_saved_draft: 'Draft preview',
  publish: 'Publish',
  publish_survey: 'Publish survey',
  read_less: '< Read less',
  read_more: 'Read more >',
  removed_date: 'Removed date',
  recipient_type: {
    employers: 'Employers',
    recruiters: 'Recruiters',
    students: 'Students',
    graduates: 'Graduates'
  },
  recipients_group: 'Recipients group',
  register: 'Register',
  reject: 'Reject',
  reject_changes: 'Reject changes',
  reject_event: 'Reject event',
  reject_offer: 'Reject offer',
  remote_work: 'Remote work',
  remove: 'Remove',
  remove_from_favorites: 'Remove from favorites',
  remove_sub_event: 'Cancel date',
  roles: {
    administrator: 'administrator',
    career_office: 'career office',
    counselor: 'counselor',
    employer: 'employer',
    graduate: 'graduate',
    recruiter: 'recruiter',
    student: 'student'
  },
  save: 'Save',
  save_and_login: 'Save and log in',
  save_changes: 'Save changes',
  save_draft: 'Save draft',
  save_note: 'Save note',
  search_word: 'Search phrase...',
  see_less: 'show less',
  see_more: 'show more',
  select: 'Select',
  select_city: 'Select city',
  send: 'Submit',
  send_for_publication: 'Submit for publication',
  show_all: 'Show all',
  show_more: 'SHOW MORE',
  show_report: 'Show report',
  show_results: 'Show results',
  start_date: 'Start date',
  status: 'Status',
  steps: {
    next: 'Next',
    previous: 'Previous',
    publish: 'Save and publish',
    send_for_publication: 'Save and submit for publication'
  },
  student_graduate: 'Student/Graduate',
  suggest: 'Suggest',
  survey: 'Survey',
  surveys: {
    attributes: {
      anonymous: 'Anonymous',
      graduate_fate: 'Fate od graduates',
      requires_answer: 'Required answers'
    },
    statuses: {
      draft: 'Draft',
      published: 'Published',
      completed: 'Ended',
      closed: 'Closed'
    }
  },
  text_section: 'Text',
  time_to_end: 'remaining time',
  title: 'Title',
  training: 'Training',
  to: 'to',
  type: 'Type',
  unexpected_error: 'There was an unexpected error.',
  unregister: 'Unsubscribe',
  video: 'Video',
  when: 'when',
  where: 'wher',
  words: 'Phrases',
  work_on_site: 'Onsite work'
}
