<template>
  <div class="position-relative">
    <b-tabs v-if="!hideTabs" v-model="tab" @input="changeTab">
      <b-tab v-for="tabName in tabsPerRole" :key="tabName" :title="$t(`views.events.events_list.${tabName}`)" />
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EventsList',
  data () {
    return {
      tab: 1
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    tabsPerRole () {
      if (this.showStudentActions) {
        return ['current', 'registered', 'all', 'assigned', 'archived']
      } else if (this.showCareerOfficeActions) {
        return ['all', 'current', 'archived', 'pending']
      }

      return ['all', 'current', 'archived']
    },
    tabsPath () {
      return this.tabsPerRole.map(tabName => `events_list_${tabName}_tab_path`)
    },
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office' || this.currentRole === 'counselor'
    },
    hideTabs () {
      return this.$route.params && this.$route.params.student_id
    }
  },
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabsPath.indexOf(newValue)
    }
  },
  mounted () {
    this.tab = this.tabsPath.indexOf(this.$route.name)
  },
  methods: {
    changeTab (event) {
      const targetTab = this.tabsPath[event]
      const studentId = this.$route.params.student_id

      if (this.$route.name !== targetTab) {
        if (studentId) {
          this.$router.push({ name: `student_${targetTab}`, params: { student_id: studentId } })
        } else {
          this.$router.push({ name: targetTab })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-message-btn-container {
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-sm-min) {
    .new-message-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>
