<template>
  <div class="container">
    <abk-loader v-if="showLoader" class="with-margins"/>
    <pcg-table v-else :items="dictionaries" :fields="fields" class="dictionaries-table">
      <template v-slot:header>{{$t('views.dictionaries.index.table_header')}}</template>
      <template v-slot:cell(recentWordsCount)="{ item }">
        <span v-if="item.recentWordsCount > 0" class="abk-counter">{{ item.recentWordsCount }}</span>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="d-flex align-items-center">
          <pcg-btn-symbol icon-class="pcg-icon-comment-content"
                          class="my-0"
                          :tooltip="$t('general.words')"
                          @click="$router.push({ name: 'dictionary_words_path', params: { dictionaryId: item.id } })"/>
          <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                          class="my-0"
                          :tooltip="$t('general.edit')"
                          @click="$router.push({ name: 'dictionaries_edit_path', params: { id: item.id } })"/>
        </div>
      </template>
    </pcg-table>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getDictionaries"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiDictionaries from '../../api/dictionaries'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'DictionaryIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      dictionaries: [],
      fields: []
    }
  },
  mounted () {
    this.getDictionaries()
  },
  methods: {
    getDictionaries () {
      ApiDictionaries.getDictionaries({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaries = this.dataTable(response.data.data)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionaries.index.title'),
        subtitle: this.$t('views.dictionaries.index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach(dictionary => {
        const attributes = { id: dictionary.id, ...dictionary.attributes }
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          fields.push({
            key: 'recentWordsCount',
            label: ''
          })
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'dictionaryType') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionaries.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionaries.dictionary_types.${value}`)
                }
              })
            } else if (key !== 'recentWordsIndex') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionaries.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionaries.labels.actions')
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  .dictionaries-table {
    ::v-deep td:first-child {
      width: 70px;
      min-width: 70px;
    }
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
