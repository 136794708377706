<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <div class="d-flex flex-wrap">
          <div class="filters-text d-flex align-items-center">{{ $t('components.abk_students.filter_students') }}</div>
          <pcg-search @searchEvent="getStudentsAndResetPagination" @input="getStudentsAndResetPagination" class="filter-select" v-model="filters.fullName"></pcg-search>
          <pcg-select :options="rolesSortOptions"
                      :default-option="{ text: $t('components.abk_students.role'), value: null }"
                      class="filter-select"
                      v-model="filters.role"
                      @input="getStudentsAndResetPagination"/>
        </div>
      </b-col>
    </b-row>
    <abk-list-of-students :list-of-students="students"
                           :linkPath="'student_show_path'"
                           :no-records-text="$t(`components.abk_students.abk_student_list.no_records`)"
                           :date-label-text="$t('components.abk_students.abk_student_list.registration_date')"
                           :show-loader="showLoader"
                           :show-actions="true" >
    </abk-list-of-students>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getStudents"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkListOfStudents from './AbkListOfStudents'
import ApiStudents from '../../api/students'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'AbkStudentList',
  components: { AbkListOfStudents },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      students: [],
      filters: {
        full_name: null,
        order_direction: 'asc',
        order_by: 'full_name',
        page: 1,
        role: null
      },
      roles: ['graduate', 'student'],
      rolesSortOptions: []
    }
  },
  computed: {
    queryParams () {
      return {
        filters: this.filters
      }
    }
  },
  mounted () {
    this.getStudents()
    this.initializeOptions()
  },
  methods: {
    getStudentsAndResetPagination () {
      this.resetPaginationPage()
      this.getStudents()
    },
    getStudents () {
      this.filters.page = this.pagination.currentPage
      ApiStudents.getStudentsList(this.filters)
        .then(response => {
          this.setPagination(response)
          this.students = response.data.data.map(student => {
            return {
              id: student.id,
              cvId: student.attributes.curriculumVitaeId,
              avatar: student.attributes.avatar,
              date: this.formattedDate(student.attributes.createdAt),
              name: `${student.attributes.firstName} ${student.attributes.lastName}`,
              numberOfNotes: student.attributes.numberOfNotes
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_students.abk_student_list.error_download_students'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    updateStudentStatus (studentId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${action}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiStudents[`${action}Student`](studentId)
            .then(() => {
              this.getStudents()
            }).catch(() => {
              this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
            })
        }
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeRolesOptions()
      }, 0)
    },
    initializeRolesOptions () {
      this.rolesSortOptions = this.roles.map(role => {
        return { text: this.$t(`views.users_list.roles.${role}`), value: role }
      })
      this.rolesSortOptions.unshift({ text: this.$t('components.abk_users_list.all'), value: '' })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';
  /deep/ .list-of-students {
    .name {
      font-weight: 400 !important;
    }
  }
  .top-bar {
    margin-bottom: $pcg-m-sm;
    margin-left: .3rem;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .filters-text {
    color: $pcg-gray;
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
