import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'answers'

export default {
  getAnswers (surveyId, filters) {
    return api.get(`${resource}?survey_id=${surveyId}?${stringify(decamelize(filters))}`)
  },
  getAnswer (answerId) {
    return api.get(`${resource}/${answerId}`)
  },
  createAnswer (payload) {
    return api.post(`${resource}`, payload)
  },
  validateAnswer (payload) {
    return api.post(`${resource}/validate`, payload)
  }
}
