import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'open_survey_question_answers'

export default {
  getOpenSurveyQuestionAnswers (filters) {
    return api.get(`${resource}?${stringify(decamelize(filters))}`)
  }
}
