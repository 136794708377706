<template>
  <pcg-box v-if="competencesPresent">
    <div v-if="$slots.header" class="pcg-box-inner-header">
      <slot name="header"></slot>
    </div>
    <ul class="competences">
      <li v-for="competencesEntries in items"
          :key="`competence-${competencesEntries[0]}`" class="competence">
        <div>
          <span class="content">
            <div>
              {{ headers[competencesEntries[0]] }}
            </div>
            <div v-for="competence in competencesEntries[1]" :key="competence.id">
              {{ competence.name }}
            </div>
          </span>
        </div>
      </li>
    </ul>
  </pcg-box>
</template>

<script>
export default {
  name: 'abk-competences-box',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    competencesPresent () {
      return !_.isEmpty(this.items)
    }
  }
}

</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .competences {
    padding-left: 24px;
    position: relative;
    margin: 0;
  }
  .competence {
    position: relative;
    z-index: 0;
    padding-left: $font-size-s;
    margin-bottom: $font-size-m;
    list-style: none;
    &:last-child {
      margin-bottom: 0;
    }
    .content {
      font-weight: 400;
      color: $pcg-gray;
      margin-bottom: $font-size-l;
    }
    &:before {
      content: $pcg-icon-bullet;
      color: $pcg-gray;
      left: -15px;
      position: absolute;
      font-size: 40px;
      top: -17px;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";

  .contrast{
    .competence {
      .content {
        color: $pcg-gray;
      }
      &:before {
        color: $pcg-gray;
      }
    }
  }
</style>
