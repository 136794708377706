<template>
  <b-tabs content-class="pcg-tab-content" v-model="tabIndex" @input="changeRouteHash">
    <abk-offer-tab/>
    <abk-offer-matching-profiles-tab v-if="env && env.competenceFeature !== 'off'"/>
    <abk-offer-applications-tab/>
  </b-tabs>
</template>

<script>
import AbkOfferTab from '../../components/offers/tabs/AbkOfferTab'
import AbkOfferApplicationsTab from '../../components/offers/tabs/AbkOfferApplicationsTab'
import AbkOfferMatchingProfilesTab from '../../components/offers/tabs/AbkOfferMatchingProfilesTab'
import { mapGetters } from 'vuex'

export default {
  name: 'offers-employer-tabs',
  components: { AbkOfferMatchingProfilesTab, AbkOfferTab, AbkOfferApplicationsTab },
  data () {
    return {
      tabIndex: 0,
      tabs: ['', '#matching_profiles', '#applications']
    }
  },
  computed: {
    ...mapGetters('environment', ['env'])
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_employer_tabs.title'),
        subtitle: this.$t('views.offers.offers_employer_tabs.subtitle')
      })
    },
    changeRouteHash (tabIndex) {
      const newHash = this.tabs[tabIndex]

      if (newHash !== this.$route.hash) {
        this.$router.push({ name: this.$route.name, hash: newHash })
      }
    }
  },
  mounted () {
    this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
