<template>
  <div>
    <b-col cols="12" class="box mt-3">
      <b-row class="box-header">
        <b-col cols="12" class="mb-4 d-flex justify-content-between">
          <pcg-radio :name="`institutionType-${value.key}`"
                     :show-label="false"
                     class="d-inline-block"
                     :show-error="errorExist('institutionType')"
                     :error-text="getError('institutionType')"
                     :value="value.institutionType"
                     @input="updateForm('institutionType', $event)"
                     :options="institutionTypeOptions"/>
          <button class="section-btn small float-right" @click="remove()">✕ {{ $t('general.delete') }}</button>
        </b-col>
        <b-col cols="12" xl="6">
          <b-row>
            <pcg-text-input class="col-12"
                            :show-label="true"
                            :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.institution')"
                            :error-text="getError('institution')"
                            :show-error="errorExist('institution')"
                            :value="value.institution"
                            @input="updateForm('institution', $event)"/>
            <pcg-text-input class="col-12"
                            :show-label="true"
                            :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.discipline')"
                            :error-text="getError('discipline')"
                            :show-error="errorExist('discipline')"
                            :value="value.discipline"
                            @input="updateForm('discipline', $event)"/>
            <pcg-text-input class="col-12"
                            :show-label="true"
                            :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.faculty')"
                            :error-text="getError('faculty')"
                            :show-error="errorExist('faculty')"
                            :value="value.faculty"
                            @input="updateForm('faculty', $event)"/>
            <pcg-select class="col-12"
                        :options="degreeOptions"
                        :value="String(value.degreeId)"
                        @input="updateForm('degreeId', $event)"
                        :error-text="getError('degreeId')"
                        :show-error="errorExist('degreeId')"
                        :show-value-addition="isDictionaryOpen('degree')"
                        @showModalSuggestion="showModalSuggestion(getDictionaryId('degree'))"
                        :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.degree')"/>
          </b-row>
        </b-col>
        <b-col cols="12" xl="6">
          <b-row>
            <abk-datetime-picker class="col-12 col-xl-6"
                                 :value="value.dateStart"
                                 @input="updateForm('dateStart', $event)"
                                 :show-label="true"
                                 :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.date_start')"
                                 :error-text="getError('dateStart')"
                                 :show-error="errorExist('dateStart')"
                                 variant="date"/>
            <abk-datetime-picker class="col-12 col-xl-6"
                                 :value="value.dateEnd"
                                 @input="updateForm('dateEnd', $event)"
                                 :show-label="true"
                                 :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.date_end')"
                                 :error-text="getError('dateEnd')"
                                 :show-error="errorExist('dateEnd')"
                                 variant="date"/>
            <b-col class="form-group">
              <pcg-checkbox :value="value.finished" @input="updateForm('finished', $event)">
                {{ $t('components.curriculum_vitaes.abk_cv_education_form_section.finished') }}
              </pcg-checkbox>
              <div v-show="errorExist('finished')" class="pcg-error-messages">{{ getError('finished') }}</div>
            </b-col>
            <abk-datetime-picker class="col-12"
                                 :value="value.diplomaDate"
                                 @input="updateForm('diplomaDate', $event)"
                                 :show-label="true"
                                 :label="$t('components.curriculum_vitaes.abk_cv_education_form_section.diploma_date')"
                                 :error-text="getError('diplomaDate')"
                                 :show-error="errorExist('diplomaDate')"
                                 variant="date"/>
            <b-col class="form-group">
              <label v-show="!showFileInput" class="attachment-input" @click="showAddAttachment = true">
                <i class="pcg-icon-clip pcg-sm mr-3"></i> {{ $t('general.add_attachment') }}
              </label>
              <div class="attachment-container">
                <abk-file-input @input="handleFile($event)"
                                :file-url="value.filename"
                                v-show="showFileInput"
                                :status="fileUploadStatus()"
                                ref="attachmentInput"
                                :showRemoveButton="true"
                                @remove="removeAttachment"
                                v-b-tooltip.hover
                                :title="getError('attachment')"
                                :error-text="getError('attachment')"/>
              </div>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <abk-suggest-dictionary-word-modal v-model="showSuggestDictionaryWordModal" :dictionary-id="currentDictionaryId"/>
  </div>
</template>

<script>
import AbkDatetimePicker from '../AbkDatetimePicker'
import AbkFileInput from '../AbkFileInput'
import SuggestingDictionaryWordsMixin from '../../mixins/suggesting_dictionary_words'
import AbkSuggestDictionaryWordModal from '../AbkSuggestDictionaryWordModal'

export default {
  name: 'abk-cv-education-form-section',
  components: { AbkSuggestDictionaryWordModal, AbkFileInput, AbkDatetimePicker },
  mixins: [SuggestingDictionaryWordsMixin],
  props: {
    index: Number,
    value: Object,
    errors: Object,
    loading: Boolean,
    submitted: Boolean,
    degreeOptions: Array,
    dictionariesMetaInfo: Object
  },
  data () {
    return {
      showAddAttachment: false,
      institutionTypeOptions: [
        {
          text: this.$t('components.curriculum_vitaes.abk_cv_education_form_section.institution_types.high_school'),
          value: 'high_school'
        },
        {
          text: this.$t('components.curriculum_vitaes.abk_cv_education_form_section.institution_types.college'),
          value: 'college'
        }
      ],
      localErrors: []
    }
  },
  methods: {
    getDictionaryId (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryId : null
    },
    isDictionaryOpen (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryType === 'open' : false
    },
    remove () {
      this.$emit('remove')
    },
    getError (field) {
      if (this.errorExist(field)) {
        if (field === 'attachment' && this.localErrors.length > 0) {
          return this.localErrors[0]
        } else {
          return this.errors[`cvEducations.${this.index}.${field}`].join(', ')
        }
      }
      return ''
    },
    errorExist (field) {
      if (field === 'attachment' && this.localErrors.length > 0) {
        return this.localErrors.length > 0
      } else {
        return !!(this.errors && this.errors[`cvEducations.${this.index}.${field}`])
      }
    },
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateFormObject (object) {
      this.$emit('input', { ...this.value, ...object })
    },
    handleFile (file) {
      if (file.size > 4200000) {
        if (this.$refs.attachmentInput) {
          this.$refs.attachmentInput.clear()
        }
        this.localErrors = [this.$t('components.abk_visual_identification_form.max_size')]
      } else {
        if (this.value.removeAttachment) {
          const copy = { ...this.value, attachment: file }
          delete copy.removeAttachment
          this.$emit('input', copy)
        } else {
          this.updateForm('attachment', file)
        }
        if (this.errorExist('attachment')) {
          delete this.errors.attachment
        }
      }
    },
    fileUploadStatus () {
      if (this.loading) {
        return 'loading'
      } else if (this.errorExist('attachment')) {
        return 'fail'
      } else if (this.value.attachment || this.value.filename) {
        return 'success'
      }
      return 'upload'
    },
    removeAttachment () {
      this.showAddAttachment = false
      if (this.$refs.attachmentInput) {
        this.$refs.attachmentInput.clear()
      }
      this.localErrors = []
      this.updateFormObject({
        attachment: null,
        removeAttachment: true,
        filename: null
      })
    }
  },
  computed: {
    showFileInput () {
      return this.showAddAttachment || this.value.filename
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  h2 {
    font-size: 1em;
    color: $pcg-gray;
    width: fit-content;
    margin: 0 1em 0 0;
  }
  .title {
    text-transform: uppercase;
  }
  .box {
    border: 2px dashed $pcg-light-gray;
    border-radius: 15px;
  }
  .section-btn {
    white-space: nowrap;
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.remove-subsection-btn {
      height: fit-content;
      position: absolute;
      top: 12px;
      bottom: 0;
      right: -53px;
      margin: 0 auto 10px;
    }
    &.small {
      font-size: $font-size-s;
    }
  }
  .box-header {
    padding: 30px 25px;
  }
  .subsection {
    border-top: 2px solid $pcg-lightest-gray;
    padding: $pcg-m-xl 25px $pcg-m-sm;
  }
  /*/deep/ .form-group {*/
    /*.pcg-input-label {*/
      /*display: none;*/
    /*}*/
  /*}*/
  .content-textarea {
    height: 100%;
    /deep/ textarea {
      height: 100%;
    }
  }
  @media (max-width: $screen-md-min) {
    .box-header, .subsection {
      padding: 20px 5px;
    }
  }

  @media (max-width: $screen-xlg-min) {
    .subsection {
      padding-top: 40px;
    }
    .remove-subsection-btn {
      top: -30px!important;
      right: 0!important;
    }
  }

  .images {
    padding: 0 25px;
  }

  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
  .close {
    margin-left: 10px;
    max-height: 21px;
    user-select: none;
    float: none;
    &:hover {
      cursor: pointer;
    }
  }
  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    h2 {
      color: $pcg-gray;
    }
    .box {
      border: 2px dashed $pcg-light-gray;
    }
    .section-btn {
      color: $main-active-color;
    }
    .subsection {
      border-top: 2px solid $pcg-lightest-gray;
    }
    .attachment-input {
      color: $main-active-color;
    }
    .attachment-input {
      color: $main-active-color;
    }
  }
</style>
