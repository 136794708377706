<template>
  <div class="position-relative">
    <b-tabs v-model="tab" @input="changeTab">
      <b-tab :title="$t('views.companies.companies_index.for_acceptance')"/>
      <b-tab :title="$t('views.companies.companies_index.accepted')"/>
      <b-tab :title="$t('views.companies.companies_index.rejected')"/>
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CompaniesIndex',
  watch: {
    '$route.name' (newValue) {
      console.log(newValue)
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  data () {
    return {
      tab: 1,
      tabs: [
        'companies_index_for_acceptance_tab_path',
        'companies_index_accepted_tab_path',
        'companies_index_rejected_tab_path'
      ]
    }
  },
  methods: {
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab })
      }
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.companies.companies_index.title')
      })
    }
  },
  mounted () {
    this.tab = this.tabs.indexOf(this.$route.name)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-message-btn-container {
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-sm-min) {
    .new-message-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>
