<template>
  <div class="webpage">
    <h1 class="pcg-title">
      {{webpage.title}}
    </h1>
    <div class="d-flex justify-content-between">
      <div v-html="webpage.content" style="margin-right: 1rem;"></div>
      <img v-if="webpage.image" :src="webpage.image.url" class="img-fluid webpage-image">
    </div>

    <div v-if="webpage.attachments.length" class="attachments">
      <abk-attachment v-for="attachment in webpage.attachments"
                      :key="attachment.id"
                      class="attachment"
                      @click="downloadAttachment(attachment.id, attachment.filename)">
        {{ attachment.filename }}
      </abk-attachment>
    </div>

    <vue-disqus :shortname="env.disqusId" v-if="webpage.forum" :identifier="webpage.id" :url="this.$route.query.page"></vue-disqus>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import AbkAttachment from '../../components/offers/AbkAttachment'
import ApiWebpages from '../../api/webpages'
import ApiWebpageAttachments from '../../api/webpage_attachments'
import { mapGetters } from 'vuex'

export default {
  name: 'WebpageShow',
  components: { AbkAttachment },
  data: function () {
    return {
      webpage: {
        id: null,
        parentId: null,
        title: null,
        address: null,
        navigation: null,
        content: null,
        keywords: null,
        meta_description: null,
        created_at: null,
        forum: null,
        image: null,
        attachments: []
      }
    }
  },
  computed: {
    ...mapGetters('environment', ['env'])
  },
  mounted () {
    this.address = this.$route.params.id
    this.getWebpage()
  },
  methods: {
    getWebpage () {
      ApiWebpages.getWebpageByAddress(this.address)
        .then(response => {
          this.webpage = response.data.data.attributes
          this.setAttachmentsFromResponse(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    setAttachmentsFromResponse ({ data: { included } }) {
      const attachments = included.filter(e => e.type === 'webpage_attachment')
        .map(e => ({ id: e.id, filename: e.attributes.filename }))
      this.$set(this.webpage, 'attachments', attachments)
    },
    downloadAttachment (id, filename) {
      ApiWebpageAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';
  .box-container {
    margin-bottom: $pcg-m-xxl;
  }

  .company {
    padding-top: 200px;
  }
  .attachments {
    margin: $pcg-m-xl 0;
  }

  .attachment {
    margin-top: $pcg-m-sm;
    padding: 0;
    display: block;
  }

  .webpage-image {
    max-height: 400px;
  }
</style>
