import api from './index'

const resource = 'instances'

export default {
  getInstance () {
    const url = resource + '/get_instance'
    return api.get(url)
  }
}
