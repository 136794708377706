<template>
  <div class="row pcg-header-container footer-navbar">
    <b-col md="auto" class="pcg-logo-container"/>
    <div class="col pcg-navbar-container">
      <template v-for="(item, index) in items">
        <pcg-btn v-if="item.type === 'button'"
                 v-show="!item.hasOwnProperty('show') || item.show"
                 size="small"
                 :class="item.class"
                 :variant="item.variant"
                 :key="index"
                 @click="item.action">
          {{ item.text }}
        </pcg-btn>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AbkFooterNavbar',
  computed: {
    ...mapGetters('footerNavbar', ['items'])
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .footer-navbar {
    bottom: 0;
    top: auto !important;
    position: fixed;
    height: fit-content !important;
    z-index: 3 !important;
    .pcg-navbar-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      height: auto;
      button {
        line-height: initial;
      }
    }
  }
</style>
