import api from './index'

const resource = 'competences'

export default {
  getCompetences (params={}) {
    return api.get(`${resource}`, { params: params })
  },
  getConnectedWithSkills (params={}) {
    return api.get(`${resource}/connected_with_skills`, { params: params })
  },
  getNotConnectedWithSkills (params={}) {
    return api.get(`${resource}/not_connected_with_skills`, { params: params })
  },
  getAllCompetences () {
    return api.get(`${resource}/all`)
  },
  getCompetence (competenceId) {
    return api.get(`${resource}/${competenceId}`)
  },
  getCompetenceSkills (competenceId) {
    return api.get(`${resource}/${competenceId}/skills`)
  },
  createCompetence (payload) {
    return api.post(`${resource}`, payload)
  },
  updateCompetence (competenceId, payload) {
    return api.patch(`${resource}/${competenceId}`, payload)
  },
  destroyCompetence (competenceId) {
    return api.delete(`${resource}/${competenceId}`)
  }
}
