<template>
  <b-row>
    <pcg-box class="cols-12">
      <abk-list :items="surveys.records"
                :show-loader="showLoader"
                :hide-status="showStudentOrEmployerActions"
                :items-column-name="$t('general.survey')"
                :no-records-text="$t('components.surveys.abk_surveys_list.no_surveys')">
        <template v-if="showCareerOfficeActions" v-slot:header>
          <div class="completed-answers-header" :style="{ marginRight: `${slotWidth - 103}px` }">
            {{ $t('components.surveys.abk_surveys_list.completed_answers') }}
          </div>
        </template>
        <template v-slot="record" v-if="showCareerOfficeActions">
          <div class="slot-container" ref="slotContainer">
            <div class="pcg-record-count answers-count">{{ record.answersCount }}</div>
            <div class="btn-container">
              <pcg-btn-symbol class="btn-symbol"
                              icon-class="pcg-icon-info"
                              :tooltip="$t('general.show_params')"
                              @click="goToSurveyParams(record.id)"/>
              <pcg-btn-symbol v-if="record.status.value !== 'draft'"
                              class="btn-symbol"
                              icon-class="pcg-icon-chart-alt"
                              :tooltip="$t('components.surveys.abk_surveys_list.stats')"
                              @click="goToSurveyStats(record.id)"/>
              <pcg-btn-symbol v-if="record.status.value !== 'draft'"
                              class="btn-symbol"
                              icon-class="pcg-icon-content-check"
                              :tooltip="$t('components.surveys.abk_surveys_list.show_completed_surveys')"
                              @click="goToSurveyAnswersList(record.id)"/>
              <pcg-btn-symbol v-if="record.status.value === 'published'"
                              class="btn-symbol"
                              icon-class="pcg-icon-power-off"
                              :tooltip="$t('general.close')"
                              @click="performSurveyAction(record.id, 'closeSurvey')"/>
              <pcg-btn-symbol v-else-if="record.status.value === 'draft'"
                              class="btn-symbol"
                              icon-class="pcg-icon-edit-alt"
                              :tooltip="$t('general.edit')"
                              @click="goToEditSurvey(record.id)"/>
              <pcg-btn-symbol v-if="record.status.value === 'draft'"
                              class="btn-symbol abk-delete-btn"
                              icon-class="pcg-icon-trash-can"
                              :tooltip="$t('general.remove')"
                              @click="performSurveyAction(record.id, 'destroySurvey')"/>
              <router-link :to="{ name: 'surveys_new_path', query: { copy_id: record.id } }"
                           class="check-file" v-b-tooltip.hover
                           :title="$t('general.copy')">
                <i class="pcg-icon-duplicate"></i>
              </router-link>
            </div>
          </div>
        </template>
      </abk-list>
    </pcg-box>
    <pcg-pagination v-model="filters.page"
                    :page-count="surveys.meta.pages"
                    :total-records="surveys.meta.totalRecords"
                    @input="getSurveys"
                    class="col-12 justify-content-center my-pagination"/>
  </b-row>
</template>

<script>
import ApiSurveyUsers from '../../api/survey_users'
import ApiSurveys from '../../api/surveys'
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import LoaderDelayMixin from '../../mixins/loader_delay'
import AbkList from '../AbkList'

export default {
  name: 'AbkSurveysList',
  components: { AbkList },
  mixins: [LoaderDelayMixin],
  props: {
    surveysType: {
      type: String,
      default: 'all',
      validate (surveysType) {
        return ['all', 'recent', 'completed', 'active', 'inactive'].includes(surveysType)
      }
    }
  },
  data () {
    return {
      surveys: {
        records: [],
        meta: {
          pages: 0,
          totalRecords: 0
        }
      },
      filters: {
        page: 1,
        order_direction: 'desc',
        order_by: 'title'
      },
      surveysActionPerType: {
        all: 'getAssignedToMe',
        recent: 'getAssignedToMeRecent',
        completed: 'getAssignedToMeCompleted',
        active: 'getActiveSurveys',
        inactive: 'getInactiveSurveys'
      },
      getSurveysAction: null,
      markPerStatus: {
        draft: 'edit',
        published: 'check',
        closed: 'times',
        completed: 'question'
      },
      slotWidth: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showStudentOrEmployerActions () {
      return this.showStudentActions || this.showEmployerActions
    },
    showCareerOfficeActions () {
      return ['career_office'].includes(this.currentRole)
    },
    isSurveyUsers () {
      return ['all', 'recent', 'completed'].includes(this.surveysType)
    },
    surveysApi () {
      return this.isSurveyUsers ? ApiSurveyUsers : ApiSurveys
    }
  },
  beforeUpdate () {
    this.$nextTick(() => {
      if (this.$refs.slotContainer) {
        this.slotWidth = this.$refs.slotContainer.clientWidth
      }
    })
  },
  mounted () {
    this.getSurveys()
  },
  methods: {
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    },
    goToSurveyParams (surveyId) {
      this.$router.push({ name: 'surveys_params_path', params: { id: surveyId } })
    },
    goToSurveyStats (surveyId) {
      this.$router.push({ name: 'surveys_stats_path', params: { id: surveyId } })
    },
    goToEditSurvey (surveyId) {
      this.$router.push({ name: 'surveys_edit_path', params: { id: surveyId } })
    },
    getSurveys () {
      this.surveysApi[this.surveysActionPerType[this.surveysType]](this.filters)
        .then(response => {
          this.isSurveyUsers ? this.setSurveyUsers(response.data) : this.setSurveys(response.data.data)
          this.setPagination(response.data.meta.pagination)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('components.surveys.abk_surveys_list.surveys_download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setSurveyUsers ({ data, included }) {
      this.surveys.records = data.map(({ id, attributes, relationships }) => {
        const survey = included.find(e => e.type === 'survey' && e.id === relationships.survey.data.id)
        const { requiresAnswer, anonymous, graduateFate } = survey.attributes
        const initialSentDate = this.formatDate(attributes.initialSentDate, 'DD.MM.YYYY')
        const updatedAt = this.formatDate(attributes.updatedAt, 'DD.MM.YYYY')
        return {
          id,
          ...attributes,
          ...survey.attributes,
          path: this.surveysType === 'completed' ? null : { name: 'surveys_show_path', params: { id } },
          disabledPath: this.surveysType === 'completed',
          status: {
            value: attributes.status
          },
          attributes: _.compact([
            updatedAt && attributes.completed
              ? this.$t('components.surveys.abk_surveys_list.completed_at', { date: updatedAt })
              : null,
            initialSentDate ? this.$t('components.surveys.abk_surveys_list.sent_at', { date: initialSentDate }) : null,
            requiresAnswer ? this.$t('general.surveys.attributes.requires_answer') : null,
            anonymous ? this.$t('general.surveys.attributes.anonymous') : null,
            graduateFate ? this.$t('general.surveys.attributes.graduate_fate') : null
          ])
        }
      })
    },
    setSurveys (data) {
      this.surveys.records = data.map(({ id, attributes }) => {
        const expirationDate = this.formatDate(attributes.expirationDate, 'DD.MM.YYYY')

        return {
          id,
          ...attributes,
          path: { name: attributes.status === 'draft' ? 'surveys_edit_path' : 'surveys_preview_path', params: { id } },
          name: attributes.name,
          status: {
            mark: this.markPerStatus[attributes.status],
            text: this.$t(`general.surveys.statuses.${attributes.status}`),
            value: attributes.status
          },
          attributes: _.compact([
            expirationDate ? this.$t('components.surveys.abk_surveys_list.expires_at', { date: expirationDate }) : null,
            attributes.requiresAnswer ? this.$t('general.surveys.attributes.requires_answer') : null,
            attributes.anonymous ? this.$t('general.surveys.attributes.anonymous') : null,
            attributes.graduateFate ? this.$t('general.surveys.attributes.graduate_fate') : null
          ])
        }
      })
    },
    setPagination ({ count, pages }) {
      this.surveys.meta = {
        totalRecords: count,
        pages: pages
      }
    },
    performSurveyAction (surveyId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiSurveys[action](surveyId)
            .then(() => {
              this.getSurveys()
            }).catch(() => {
              this.$toastr.e(this.$t('components.surveys.abk_surveys_list.unexpected_error'))
            })
        }
      })
    },
    changeOrderDirection (orderDirection) {
      this.filters.order_direction = orderDirection
      this.filterSurveys()
    },
    filterSurveys () {
      this.resetPage()
      this.getSurveys()
    },
    resetPage () {
      this.filters.page = 1
    },
    goToSurveyAnswersList (surveyId) {
      this.$router.push({ name: 'answers_index_path', params: { id: surveyId } })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .slot-container {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  .answers-count {
    margin-left: 0;
  }
  .btn-symbol {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
  .btn-container {
    text-align: right;
    margin-left: 2rem;
  }
  .completed-answers-header {
    margin-left: auto;
    width: 103px;
  }
  ::v-deep .record-info {
    width: 20%;
  }
  .check-file {
    color: $pcg-dark-gray;
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
  }

  @media(max-width: $screen-md-max) {
    ::v-deep .record-info {
      flex-grow: 1;
    }
    .completed-answers-header, .answers-count {
      display: none;
    }
  }
  @media(max-width: $screen-sm-max) {
    .slot-container {
      margin-top: $pcg-m-sm;
    }
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
