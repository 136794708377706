<template>
  <div>
    <div class="text-right">
      <span v-if="!required" class="close" @click="$emit('close')">✕</span>
    </div>
    <pcg-text-input :value="value.title"
                    @input="update('title', $event)"
                    :error-text="getError('title')"
                    :show-error="errorExist('title')"
                    :label="$t('components.events.abk_event_text_section.title')"/>

    <label class="pcg-input-label pcg-input-label-required">
      {{ $t('components.events.abk_event_text_section.content') }}
    </label>
    <tinymce-editor :class="{ invalid: showContentErrors }"
                    :init="tinymceSettings"
                    :value="value.content"
                    @input="update('content', $event)"/>
    <input class="pcg-field d-none" :required="showContentErrors"/>
    <div v-if="errorExist('content')" class="pcg-error-messages">{{ getError('content') }}</div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'
export default {
  name: 'abk-event-text-section',
  components: { TinymceEditor },
  props: {
    value: {
      type: Object
    },
    errors: Object,
    required: Boolean
  },
  watch: {
    'errors.content': function (newError) {
      if (newError) {
        this.invalidContentValue = this.value.content
      }
    }
  },
  data () {
    return {
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 150,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  computed: {
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.content)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.value.content
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 1rem;
    &:hover {
      cursor: pointer;
    }
  }

</style>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars-contrast';

  .contrast {
    .close {
      color: $pcg-the-darkest-gray;
    }
  }
</style>
