<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-search-history"></i>{{ $t('components.abk_popular_searches_box.title') }}
    </div>
    <div v-if="searches && searches.length" class="searches-container">
      <label class="search" v-for="search in searches" :key="search" @click="showSearch(search)">
        {{ search }}
      </label>
    </div>
    <span v-else class="pcg-no-records">{{ $t('components.abk_popular_searches_box.no_records') }}</span>
    <div v-if="searches && totalSearches > searches.length" class="all-offers-link mt-4">
      <router-link :to="{ name: 'offers_path' }" class="pcg-link pcg-small">{{ $t('general.show_more') }}</router-link>
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkPopularSearchesBox',
  props: {
    searches: Array,
    totalSearches: Number
  },
  methods: {
    showSearch (term) {
      this.$router.push({ name: 'offers_path', query: { search: { keyword: [term] } } })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  /deep/ .box {
    height: 100%;
  }
  .search {
    font-size: $font-size-s;
    font-weight: 400;
    color: $main-color;
    line-height: .9;
    &:hover {
      cursor: pointer;
    }
  }
  .searches-container, .all-offers-link {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  .all-offers-link {
    font-size: $font-size-m;
    .pcg-link {
      grid-column-end: -1;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .search {
      color: $main-color;
    }
  }
</style>
