<template>
  <div>
    <div class="title-container d-flex align-items-center">
      <h1 class="pcg-form-title d-inline-block h-auto mb-0 mr-3">
        {{ $t('components.curriculum_vitaes.abk_curriculum_vitaes_additional_information_form.title') }}
      </h1>
    </div>
    <b-row>
      <b-col cols="12">
        <tinymce-editor class="m-xl-0"
                        :class="{ invalid: showContentErrors }"
                        :init="tinymceSettings"
                        :value="value.additionalInformation"
                        @input="updateForm('additionalInformation', $event)"/>
        <input class="pcg-field d-none" :required="showContentErrors"/>
        <div v-if="errorExist('additionalInformation')" class="pcg-error-messages">{{ getError('additionalInformation') }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'abk-curriculum-vitaes-additional-information-form',
  components: { TinymceEditor },
  props: {
    value: Object,
    errors: Object
  },
  data () {
    return {
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 200,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  watch: {
    'errors.additionalInformation': function (newError) {
      if (newError) {
        this.invalidContentValue = this.value.additionalInformation
      }
    }
  },
  methods: {
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  computed: {
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.additionalInformation)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.value.additionalInformation
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .description-textarea {
    /deep/ textarea {
      height: 145px;
    }
  }
  .form-group {
    margin-bottom: $pcg-m-lg;
  }
  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
</style>
