<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-star"></i>{{ $t('components.abk_cv_hobby_box.title') }}
    </div>
    <span class="content" v-html="content"></span>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkCvHobbyBox',
  props: {
    content: String
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .content {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: $font-size-l;
  }
</style>
<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .content {
      color: $pcg-gray;
    }
  }
</style>
