import api from './index'
import axios from 'axios'
import {store} from '../store'

const resource = 'attendances'

export default {
  getStudentsList (eventId, params = {}) {
    return api.get(`${resource}/${eventId}`, { params: params })
  },
  attendentStudent (record) {
    return api.patch(`${resource}/${record.id}`, { body: { attendance: record.attendance } })
  },
  getAttendanceListXlsx (eventId, status) {
    return axios({
      url: '/api/v1/attendances/attendance_list_xlsx',
      headers: { Authorization: store.getters['auth/token'] },
      params: { event: eventId, status: status },
      method: 'GET',
      responseType: 'blob'
    })
  }
}
