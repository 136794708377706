<template>
  <div>
    <abk-loader v-if="showLoader" class="with-margins"/>
    <div v-else-if="recipientGroups.length > 0">
      <div v-for="recipientGroup in recipientGroups" :key="`recipient-group-${recipientGroup.id}`" class="oneline">
        <div class="name-container">
          <span class="name">
            {{ recipientGroup.name }}
          </span>
          <span class="date-added ml-1">
          {{ $t('components.abk_recipient_group_list.date_added') }} {{ formatDate(recipientGroup.createdAt) }}
          </span>
        </div>
        <div class="flex-container">
          <span>
            <div class="box">
              <template v-for="(recipientGroupPropertyValue, recipientGroupPropertyKey) in recipientGroup.properties">
                <template v-if="recipientGroupPropertyKey === 'recipients'">
                  <span v-for="recipientType in recipientGroupPropertyValue"
                        :key="`recipient-group-property-${recipientType}`"
                        class="one-tag">
                    {{ $t(`components.abk_recipient_group_list.recipient_types.${recipientType}`) }}
                  </span>
                </template>
                 <span v-else class="one-tag"
                        :key="`recipient-group-property-${recipientGroupPropertyKey}`">
                   {{ translatedProperty(recipientGroupPropertyKey, recipientGroupPropertyValue) }}
                </span>
              </template>
            </div>

          </span>
          <pcg-btn-symbol icon-class="pcg-icon-trash-can"
                          class="abk-delete-btn"
                          :tooltip="$t('general.delete')"
                          @click="removeRecipientGroup(recipientGroup.id)"/>
        </div>
      </div>
    </div>
    <pcg-box v-else>
      <span class="pcg-no-records">{{ $t('components.abk_recipient_group_list.no_records') }}</span>
    </pcg-box>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getRecipientGroups"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiRecipientGroups from '../../api/recipient_groups'
import ApiMessages from '../../api/messages'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import AbkLoader from '../AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'AbkRecipientGroupList',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      recipientGroups: [],
      universityStructure: {},
      universityStructureLoaded: false,
      recipientGroupsLoaded: false
    }
  },
  computed: {
    loadingDone () {
      return this.universityStructureLoaded && this.recipientGroupsLoaded
    }
  },
  created () {
    this.getUniversityStructure()
  },
  mounted () {
    this.getRecipientGroups()
  },
  methods: {
    formatDate (date) {
      const newDate = moment(date)
      return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
    },
    translatedProperty (key, value) {
      if (Object.keys(this.universityStructure).length === 0) {
        return ''
      }
      const keyMapping = {
        activityId: 'generalType',
        departmentId: 'faculty',
        fieldOfStudyId: 'course',
        typeOfStudiesId: 'type',
        studyModeId: 'kind',
        specialtyId: 'speciality',
        specializationId: 'specialization'
      }

      if (keyMapping[key]) {
        // const idFieldName = ['studyModeId', 'typeOfStudiesId'].includes(key) ? 'sTypeId' : 'id'
        const idFieldName = 'id'
        const record = this.universityStructure[keyMapping[key]].find(record => record[idFieldName] === value)
        return record ? record.name.polish : value
      } else {
        if (key === 'recruitmentYearId') {
          return value
        }
      }
    },
    getRecipientGroups () {
      ApiRecipientGroups.getRecipientGroupsList({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.recipientGroups = response.data.data.map(recipientGroup => {
            let properties = _.pick(recipientGroup.attributes, ['recipients', 'activityId', 'departmentId',
              'fieldOfStudyId', 'recruitmentYearId', 'typeOfStudiesId', 'specialtyId', 'specializationId',
              'studyModeId'])
            properties = _.omitBy(properties, (value, _key) => value === null || value === false)
            return {
              id: recipientGroup.id,
              name: recipientGroup.attributes.name,
              createdAt: recipientGroup.attributes.createdAt,
              properties: properties
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_recipient_group_list.error_download'))
        })
        .finally(() => {
          this.recipientGroupsLoaded = true
        })
    },
    getUniversityStructure () {
      ApiMessages.getUniversityStructure()
        .then(response => {
          this.universityStructure = response.data
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_message_form.error_university_structure'))
        })
        .finally(() => {
          this.universityStructureLoaded = true
        })
    },
    removeRecipientGroup (recipientGroupId) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiRecipientGroups.removeRecipientGroup(recipientGroupId)
            .then(() => {
              this.getRecipientGroups()
            }).catch(() => {
              this.$toastr.e(this.$t('components.abk_recipient_group_list.unexpected_error'))
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .flex-container {
    display: flex;
    align-items: center;
  }
  .name-container {
    margin-bottom: .2rem;
    .name {
      font-weight: bold;
      color: $main-color;
    }
  }
  .date-added {
    display: inline-block;
    color: $pcg-dark-gray;
  }
  .results {
    color: $main-color;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
  }
  .new-results {
    color: $pcg-white;
    background-color: $pcg-green;
    padding: .2rem .8rem;
    border-radius: 25px;
    font-size: .8em;
    font-weight: 400;
    margin-left: 1rem;
    display: inline-block;
    white-space: nowrap;
  }
  .delete {
    margin-left: 2.5rem;
    ::v-deep i {
      font-size: 16px !important;
    }
  }
  .one-tag {
    background-color: $color-active-filter;
    color: $main-color;
    font-weight: 400;
    border-radius: 10px;
    padding: 8px 13px;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }
  .box {
    background: $pcg-white;
    border-radius: 20px;
    padding: 15px 15px 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    margin-right: 1.5rem;
  }
  .oneline {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .name-container {
      .name {
        color: $main-color;
      }
    }
    .date-added {
      color: $pcg-dark-gray;
    }
    .results {
      color: $main-color;
    }
    .new-results {
      color: $pcg-white;
      background-color: $pcg-green;
    }
    .one-tag {
      background-color: $color-active-filter;
      color: $main-color;
    }
    .box {
      background: $pcg-white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
