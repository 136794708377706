<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.968" height="12">
        <path :class="variant" d="M19.533 0H2.447A2.428 2.428 0 00.006 2.4v7.2A2.428 2.428 0 002.447 12h17.086a2.429 2.429 0 002.441-2.4V2.4A2.428 2.428 0 0019.533 0zm.474 10.159H1.972V1.842h18.035v8.317zM5.761 9.27a1.356 1.356 0 01-.129-.426 1.576 1.576 0 01-1.219.514 1.735 1.735 0 01-1.177-.4 1.292 1.292 0 01-.467-1.019 1.333 1.333 0 01.57-1.159 2.846 2.846 0 011.645-.409h.594v-.272a.779.779 0 00-.172-.527.684.684 0 00-.542-.2.78.78 0 00-.512.153.518.518 0 00-.185.422H2.876a1.265 1.265 0 01.259-.764 1.71 1.71 0 01.733-.551 2.709 2.709 0 011.062-.2 2.133 2.133 0 011.418.441 1.533 1.533 0 01.525 1.241v2.059a2.242 2.242 0 00.192 1.024v.074h-1.3zm-1.067-.882a1.123 1.123 0 00.527-.126.832.832 0 00.357-.336V7.11h-.482a.835.835 0 00-1.032.658v.075a.505.505 0 00.169.391.671.671 0 00.461.153zm7.524-1.45a2.853 2.853 0 01-.5 1.781 1.652 1.652 0 01-1.384.639 1.515 1.515 0 01-1.255-.594l-.058.506H7.86V2.525h1.29v2.42a1.481 1.481 0 011.175-.514 1.668 1.668 0 011.387.639 2.856 2.856 0 01.5 1.8v.07zm-1.291-.092a1.867 1.867 0 00-.232-1.052.792.792 0 00-.692-.331.853.853 0 00-.849.5v1.874a.866.866 0 00.858.5.8.8 0 00.821-.607 3.053 3.053 0 00.094-.885zm4.094 1.489a.861.861 0 00.582-.193.684.684 0 00.232-.514h1.209a1.616 1.616 0 01-.267.885 1.764 1.764 0 01-.722.623 2.3 2.3 0 01-1.011.222 2.114 2.114 0 01-1.634-.648 2.537 2.537 0 01-.6-1.789v-.084a2.5 2.5 0 01.6-1.752 2.089 2.089 0 011.629-.654 2.056 2.056 0 011.454.507 1.782 1.782 0 01.555 1.35h-1.209a.877.877 0 00-.232-.6.873.873 0 00-1.271.091 1.854 1.854 0 00-.23 1.049v.132a1.869 1.869 0 00.228 1.055.788.788 0 00.687.319z" fill-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
  name: 'AbkSurveyTextSvg',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (variant) {
        return ['primary', 'secondary'].includes(variant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars";

    .primary {
        fill: $main-color
    }
    .secondary {
        fill: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars-contrast";

    .contrast{
        .primary {
            fill: $main-color
        }
        .secondary {
            fill: $main-active-color;
        }
    }
</style>
