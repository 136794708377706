<template>
    <b-modal v-model="model"
             class="search-filter-modal"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
             centered hide-header hide-footer>
        <div class="pcg-box-inner-header text-center">
            {{ title }}
        </div>
        <div class="d-flex justify-content-center flex-wrap">
            <pcg-btn v-for="button in buttons"
                     :key="button.event"
                     :size="button.size"
                     :variant="button.variant"
                     @click="$emit(button.event)">
                {{ button.text }}
            </pcg-btn>
        </div>
    </b-modal>
</template>

<script>
export default {
  name: 'UnsavedChangesModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: String,
    buttons: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
    @import "../assets/stylesheets/vars";

    /deep/ .modal-content {
        border-radius: 20px;
        border: none;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    }
    /deep/ .modal .modal-dialog {
        margin-bottom: 80px;
    }
</style>

<style lang="scss">
    @import "../assets/stylesheets/vars-contrast";
    .contrast{

    }
</style>
