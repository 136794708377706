<template>
  <pcg-box>
    <div class="pcg-box-inner-header">{{ $t('components.surveys.abk_survey_stats_info_box.title') }}</div>
    <div class="table-container">
      <table class="info-table">
        <tbody>
          <tr v-for="field in infoFields" :key="field" class="info-table-row">
            <td class="field-name">
              {{ $t(`components.surveys.abk_survey_stats_info_box.fields_names.${decamelizeString(field)}`) }}
            </td>
            <td class="field-value" :class="{ 'highlighted': field === 'sentCount' }">
              <template v-if="field === 'attributes'">
                <div v-for="attribute in info[field]" :key="attribute">
                  {{ $t(`components.surveys.abk_survey_stats_info_box.attributes.${decamelizeString(attribute)}`) }}
                </div>
              </template>
              <template v-else-if="field === 'publicationPeriod' && info[field]">
                <template v-if="info[field].from || info[field].to">
                  <div v-if="info[field].from">{{ `${$t('general.from')} ${formatDate(info[field].from)}` }}</div>
                  <div v-if="info[field].to">{{ `${$t('general.to')} ${formatDate(info[field].to)}` }}</div>
                </template>
                <template v-else>—</template>
              </template>
              <template v-else-if="field === 'completedCount'">
                <router-link :to="{ name: 'answers_index_path', params: { id: surveyId } }" class="pcg-link">
                  {{ info[field] }}
                </router-link>
              </template>
              <template v-else>{{ info[field] }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </pcg-box>
</template>

<script>
import { decamelize } from 'humps'

export default {
  name: 'AbkSurveyStatsBoxInfo',
  props: {
    info: {
      type: Object,
      required: true
    },
    surveyId: [String, Number]
  },
  data () {
    return {
      infoFields: [
        'name',
        'attributes',
        'publicationPeriod',
        'recipientGroup',
        'sentCount',
        'completedCount'
      ]
    }
  },
  methods: {
    decamelizeString (string) {
      return decamelize(string)
    },
    formatDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .table-container {
    columns: 2;
    table, tbody, tr {
      display: block;
    }
  }
  .info-table {
    tr {
      break-inside: avoid;
    }
  }
  .field-name {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    vertical-align: top;
    padding-right: $pcg-m-xl;
    min-width: 200px;
  }
  .field-value {
    color: $main-color;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    &.highlighted {
      color: $main-active-color;
    }
  }
  .info-table-row:not(:last-child) {
    td {
      padding-bottom: $pcg-m-lg;
    }
  }

  @media (max-width: $screen-lg-max) {
    .table-container {
      column-count: 1;
    }
  }

  @media (max-width: $screen-xs-max) {
    .field-name {
      min-width: 150px;
      padding-right: $pcg-m-md;
    }
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .field-name {
      color: $pcg-gray;
    }
    .field-value {
      color: $main-color;
      &.highlighted {
        color: $main-active-color;
      }
    }
  }
</style>
