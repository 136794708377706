<template>
  <div class="box">
    <template v-for="(filterEntries, dictionary) in filter">
       <span  class="one-tag" v-for="filterEntry in filterEntries" :key="`tag-entry-${filterEntry}`">
         {{ dictionaryTranslatedKey(dictionary, filterEntry.toString()) }}
      </span>
    </template>
  </div>
</template>

<script>

export default {
  name: 'abk-observed-search-result',
  props: {
    filter: {
      type: Object,
      default: () => {}
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    dictionaryTranslatedKey (distionaryName, distionaryKey) {
      if (this.dictionaries && this.dictionaries[distionaryName]) {
        const found = this.dictionaries[distionaryName].find(dictionaryWord => dictionaryWord.value === distionaryKey)
        return found && found.text
      }
      return distionaryKey
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .one-tag {
    background-color: $color-active-filter;
    color: $main-color;
    font-weight: 400;
    border-radius: 10px;
    padding: 8px 13px;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }
  .box {
    background: $pcg-white;
    border-radius: 20px;
    padding: 15px 15px 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    margin-right: 1.5rem;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .one-tag {
      background-color: $color-active-filter;
      color: $main-color;
    }
    .box {
      background: $pcg-white;
    }
  }
</style>
