<template>
  <pcg-box :header="header">
    <div class="content" v-for="user in users" :key="user.id">
      <span class="name" style="margin-right: 2rem">{{ user.name }} ({{ user.email }})</span>
      <pcg-btn size="small" v-if="!user.accepted" @click="acceptUser(user.id,true)">{{ $t('components.abk_not_accepted_users.approve') }}</pcg-btn>
      <pcg-btn size="small" v-else @click="acceptUser(user.id,false)">{{ $t('components.abk_not_accepted_users.reject') }}</pcg-btn>
    </div>
  </pcg-box>
</template>

<script>
import ApiUsers from '../api/users'

export default {
  name: 'abk-not-accepted-users',
  props: {
    users: Array
  },
  computed: {
    header () {
      return this.$t('components.abk_not_accepted_users.header')
    }
  },
  methods: {
    acceptUser (userId, status) {
      ApiUsers.changeCompanyEmployeeAccepted({ userId: userId, status: status })
        .then((response) => {
          if (status) {
            this.$toastr.s(this.$t('components.abk_not_accepted_users.approve_success'))
          } else {
            this.$toastr.s(this.$t('components.abk_not_accepted_users.reject_success'))
          }
          this.$emit('userChanged', userId)
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
            this.errors = error.response.data
          }
        })
    }
  }
}
</script>1

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  .name {
    font-weight: bold;
    color: $main-color;
    margin-bottom: $font-size-l;
    position: relative;
    top: .71rem;
  }

  .content {
    display: flex;
    align-items: center;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .name {
      color: $main-color;
    }
  }
</style>
