<template>
  <b-modal v-model="model" footer-class="d-flex justify-content-between abk-modal-footer" centered hide-header content-class="abk-modal-content">
    <b-col class="col-12">
      <h3>{{ $t('components.offer_applications.abk_offer_application_modal.title') }}</h3>
      <div class="title">
        {{ offer.jobTitle }}
        <span>{{ $t('general.in') }}</span>
        {{ company.name }}
      </div>
      <div>
        <div class="attachment-info">{{ $t('components.offer_applications.abk_offer_application_modal.attachment_info') }}</div>
        <div class="attachments-container">
          <abk-attachments v-model="attachments" :errors="attachmentErrors" tileClass="col-sm-6 col-12"/>
        </div>
      </div>
    </b-col>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn @click="cancel" variant="additional" class="mr-3">{{ $t('general.cancel') }}</pcg-btn>
      <pcg-btn @click="apply" class="mr-3">{{ $t('general.apply') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>
import ApiOfferApplications from '../../api/offer_applications'
import AbkAttachments from '../AbkAttachments'

export default {
  name: 'abk-offer-application-modal',
  components: { AbkAttachments },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    offer: {
      type: Object,
      default: () => {}
    },
    company: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      attachments: [],
      errors: {}
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    attachmentErrorsKeys () {
      const keys = Object.keys(this.errors).filter(key => {
        return key.split('.')[0] === 'offerApplicationAttachments' && key.split('.').length > 1
      })
      return keys
    },
    attachmentErrors () {
      const errors = []

      this.attachmentErrorsKeys.forEach(key => {
        const splittedKey = key.split('.')
        // console.log(splittedKey)
        if (!errors[splittedKey[1]]) { errors[splittedKey[1]] = {} }
        errors[splittedKey[1]] = this.errors[key]
      })

      return errors
    }
  },
  methods: {
    cancel () {
      this.$emit('input', false)
    },
    apply () {
      this.errors = {}
      ApiOfferApplications.createOfferApplication({
        offer_application: {
          offer_id: this.offer.id,
          offer_application_attachments: this.attachments
        }
      })
        .then(response => {
          this.$toastr.s(this.$t('components.offer_applications.abk_offer_application_modal.application_info'))
          this.$emit('input', false)
          this.attachments = []
          this.offer.applied = true
          this.$store.dispatch('user/getCatalogOfferCounters')
          this.$emit('applied', true)
        }).catch(error => {
          this.errors = error.response.data
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  /deep/ .modal-footer {
    padding-top: 17px;
    padding-bottom: 10px;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }

  h3, .attachment-info {
    color: $pcg-gray;
    margin-bottom: 10px;
  }

  .title {
    font-size: $font-size-xl;
    color: $main-color;
    margin-bottom: 40px;
    span {
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      font-size: $font-size-s;
      color: $pcg-gray;
    }
  }

  .attachments-container {
    padding: 25px 25px;
    border: 2px dashed $pcg-gray;
    min-height: 130px;
    border-radius: 20px;
  }

</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    h3, .attachment-info {
      color: $pcg-gray;
    }

    .title {
      color: $main-color;
      span {
        color: $pcg-gray;
      }
    }

    .attachments-container {
      border: 2px dashed $pcg-gray;
    }
  }
</style>
