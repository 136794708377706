<template>
  <div class="container">
    <abk-loader v-if="showLoader" class="with-margins"/>
    <pcg-table v-else :items="dictionaries" :fields="fields">
      <template v-slot:header>{{$t('views.dictionaries.index.table_header')}}</template>
      <template v-slot:cell(actions)="{ item }">
        <div class="d-flex align-items-center">
          <pcg-btn-symbol icon-class="pcg-icon-comment-content"
                          class="my-0"
                          :tooltip="$t('general.words')"
                          @click="goToDictionaryAcceptanceWords(item.id)"/>
          <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                          class="my-0"
                          :tooltip="$t('general.edit')"
                          @click="goToDictionaryAcceptanceEditPath(item.id)"/>
        </div>
      </template>
    </pcg-table>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getDictionaries"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiDictionaryAcceptances from '../../api/dictionary_acceptances'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'DictionaryAcceptancesIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      dictionaries: [],
      fields: []
    }
  },
  mounted () {
    this.getDictionaries()
  },
  methods: {
    goToDictionaryAcceptanceWords (dictionaryId) {
      this.$router.push({ name: 'dictionary_acceptance_words_path', params: { dictionaryId: dictionaryId } })
    },
    goToDictionaryAcceptanceEditPath (dictionaryId) {
      this.$router.push({ name: 'dictionary_acceptances_edit_path', params: { id: dictionaryId } })
    },
    getDictionaries () {
      ApiDictionaryAcceptances.getDictionaryAcceptances({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaries = this.dataTable(response.data.data)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptances.index.title'),
        subtitle: this.$t('views.dictionary_acceptances.index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach(dictionary => {
        const attributes = { id: dictionary.id, ...dictionary.attributes }
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            fields.push({
              key: key,
              label: this.$t(`views.dictionary_acceptances.index.labels.${key}`)
            })
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_acceptances.index.labels.actions')
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
