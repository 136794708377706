<template>
  <pcg-box class="message" :class="{ dimmed: !highlighted }" @click.native="$emit('newsClick')">
    <b-row>
      <b-col class="message-content d-flex flex-column">
        <p class="top-bar">
          <span class="message-date">{{ formatDate(date) }}</span>
        </p>
        <div ref="messageText" class="message-text" :class="{ unread: highlighted, collapsed: !show && showExpander }">
          <div class="message-title" v-if="title">{{ title }}</div>
          <div class="d-flex justify-content-between">
            <div ref="messageTextDiv" v-html="message" style="margin-right: 1rem;"></div>
            <img :src="image" class="image-fluid news-image">
          </div>
          <br/>
          <div class="expander-btn" :class="{ collapsed: !show }" @click.stop="toggleShow" v-if="showExpander">
            {{ show ? $t('general.read_less') : $t('general.read_more') }}
          </div>
        </div>
        <div class="d-flex flex-wrap mt-auto">
        </div>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import WindowResizeMixin from '../mixins/window_resize'

export default {
  name: 'AbkMessage',
  props: {
    date: [Date, String],
    highlighted: Boolean,
    message: String,
    title: String,
    address: String,
    image: String
  },
  mixins: [WindowResizeMixin],
  data: function () {
    return {
      messageTextDivHeight: 0,
      show: false
    }
  },
  mounted () {
    this.updateMessageTextDivHeight()
  },
  watch: {
    windowWidth (newValue) {
      this.updateMessageTextDivHeight()
    },
    message (newValue) {
      this.updateMessageTextDivHeight()
    }
  },
  computed: {
    showExpander () {
      return this.messageTextDivHeight > 112
    }
  },
  methods: {
    formatDate (date) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.calendar() : null
    },
    updateMessageTextDivHeight () {
      this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      setTimeout(() => {
        this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      }, 500)
    },
    toggleShow () {
      this.show = !this.show
      const element = this.$refs.messageText
      if (this.show) {
        const sectionHeight = element.scrollHeight
        element.style.height = sectionHeight + 'px'
        const handler = function (e) {
          element.removeEventListener('transitionend', handler)
          element.style.height = null
        }
        element.addEventListener('transitionend', handler)
      } else {
        const sectionHeight = element.scrollHeight
        const elementTransition = element.style.transition
        element.style.transition = ''
        requestAnimationFrame(function () {
          element.style.height = sectionHeight + 'px'
          element.style.transition = elementTransition
          requestAnimationFrame(function () {
            element.style.height = 111 + 'px'
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/vars";

/deep/ .box {
  padding-top: $font-size-m !important;
  padding-bottom: $font-size-m !important;
  position: relative;
  overflow: hidden;
}
.box-container {
  &:not(.dimmed) {
    .sender-avatar {
      ::v-deep .pcg-avatar-image {
        transform: scale(0.6);
      }
    }
  }
  &.dimmed {
    /deep/ .box {
      background-color: $pcg-ghost-white !important;
      box-shadow: $pcg-dimmed-box-shadow !important;
    }
  }
}
.message-content {
  min-width: 170px;
}
.message-date {
  color: $pcg-light-gray;
  font-weight: 400;
}
.message-text {
  line-height: 1.2;
  font-weight: 400;
  color: $pcg-dark-gray;
  /deep/ a {
    color: $main-active-color;
  }
  position: relative;
  margin-bottom: 10px;
  transition: height 0.3s ease-out;
  height: auto;
  &.unread {
    font-weight: bold;
  }
  &.collapsed {
    height: 111px;
    overflow: hidden;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(to top, $pcg-ghost-white -4%, rgba(255, 255, 255, 0) 90%);
    }
    &.unread {
      &:before {
        background: linear-gradient(to top, rgba(255, 255, 255, 1) -4%, rgba(255, 255, 255, 0) 90%);
      }
    }
  }
}
.expander-btn {
  text-align: right;
  color: $main-color;
  font-weight: bold;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -14px;
  &.collapsed {
    bottom: 2px;
  }
  &:hover {
    cursor: pointer;
  }
}
.abk-important-stamp {
  position: absolute;
  left: -12px;
  top: -12px;
  background: $pcg-the-lightest-gray;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $pcg-red;
    font-size: $font-size-xl;
    font-weight: bold;
    position: relative;
    top: 4px;
    left: 5px;
  }
}
.abk-show-thread {
  position: absolute;
  right: 20px;
  top: 10px;
  color: $main-color;
  font-size: $font-size-xl;
  z-index: 1;
  &:hover {
    color: $hover-color;
  }
}
.message-title {
  font-size: $font-size-m;
  color: $main-color;
  font-weight: bold;
  margin-bottom: $pcg-m-sm;
}
/deep/ .abk-avatar-container {
  &.career-office-logo {
    padding-top: 24px;

    .abk-avatar-image {
      height: 71px;
      padding: 0px;
      background-color: transparent;
      border-radius: 0 !important;
      background-size: auto;
      margin-bottom: 20px;
    }

    .abk-role {
      font-size: $font-size-m !important;
    }
  }
}
.sender-name {
  color: $main-color;
  font-weight: 500;
  display: none;
}
@media(max-width: $screen-lg-max) {
  .message-footer {
    text-align: center;
  }
}
@media(max-width: $screen-sm-min) {
  .sender-avatar {
    display: none;
  }
  .sender-name {
    display: block;
  }
  .top-bar {
    display: inline-flex;
    flex-direction: column;
  }
}
.message-attachment {
  margin-right: $pcg-m-lg;
}

.news-image {
  max-height: 250px;
  max-width: 400px;
}
</style>

<style scoped lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast{
  .box-container {
    &.dimmed {
      /deep/ .box {
        background-color: $pcg-ghost-white !important;
      }
    }
  }
  .message-date {
    color: $pcg-light-gray;
  }
  .message-text {
    color: $pcg-dark-gray;
    /deep/ a {
      color: $main-active-color;
    }
    &.collapsed {
      &:before {
        background: linear-gradient(to top, $pcg-ghost-white -4%, rgba(0, 0, 0, 0) 90%);
      }
      &.unread {
        &:before {
          background: linear-gradient(to top, rgba(0, 0, 0, 1) -4%, rgba(0, 0, 0, 0) 90%);
        }
      }
    }
  }
  .expander-btn {
    color: $main-color;
  }
  .abk-important-stamp {
    background: $pcg-the-lightest-gray;
    span {
      color: $pcg-red;
    }
  }
  .abk-show-thread {
    color: $main-color;
    &:hover {
      color: $hover-color;
    }
  }
  .message-title {
    color: $main-color;
  }
  .sender-name {
    color: $main-color;
  }
}
</style>
