<template>
    <div ref="progressBar" class="abk-progress-bar">
      <div v-for="(step, index) in steps"
           :key="`step-${index + 1}`"
           :class="{ done: index + 1 <= currentStep, active: index + 1 === currentStep }"
           class="progress-bar-step">
        <i class="fas fa-circle fa-xs mr-1 mr-sm-3"></i>{{ step }}
      </div>
    </div>
</template>

<script>
export default {
  name: 'AbkProgressBar',
  props: {
    steps: Array,
    currentStep: Number
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  $transition-duration: .5s;
  $transition-function: ease-in-out;

  .abk-progress-bar {
    background-color: $pcg-white;
    font-size: $font-size-l;
    height: 45px;
    display: flex;
    justify-content: stretch;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    border-radius: 10px;
    position: relative;
  }
  .progress-bar-step {
    width: 100%;
    font-weight: 500;
    position: relative;
    background-color: $pcg-white;
    color: $pcg-dark-gray;
    white-space: nowrap;
    padding: 14px 4% 10px;
    display: flex;
    align-items: center;
    transition: background-color $transition-duration $transition-function;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $main-active-color;
      opacity: 0;
      transition: opacity $transition-duration $transition-function;
    }
    i {
      color: $pcg-lightest-gray;
      transition: color $transition-duration $transition-function;
    }
    &.done {
      color: $pcg-white;
      background-color: $main-color;
      &:before {
        opacity: 1;
      }
    }
    &.active {
      i {
        color: $main-active-color;
      }
    }
  }
  .progress-bar-background {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    background: linear-gradient(to right, white 0%, $main-color 33%);
    z-index: 0;
  }
  @media (max-width: $screen-md-max) {
    .abk-progress-bar {
      font-size: 1rem;
    }
  }
  @media (max-width: $screen-xs-max) {
    .progress-bar-step {
      font-size: $font-size-s;
      padding-left: 5px;
      padding-right: 5px;
      &:first-child {
        padding-left: 10px;
      }
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .abk-progress-bar {
      background-color: $pcg-white;
    }
    .progress-bar-step {
      background-color: $pcg-white;
      color: $pcg-dark-gray;
      &:before {
        background-color: $main-active-color;
      }
      i {
        color: $pcg-lightest-gray;
      }
      &.done {
        color: $pcg-white;
        background-color: $main-color;
      }
      &.active {
        i {
          color: $main-active-color;
        }
      }
    }
    .progress-bar-background {
      background: linear-gradient(to right, white 0%, $main-color 33%);
    }
  }
</style>
