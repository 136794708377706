<template>
  <div>
    <div class="title-container d-flex align-items-center">
      <h1 class="pcg-form-title d-inline-block h-auto mb-0 mr-3">
        {{ $t('components.curriculum_vitaes.abk_cv_experiences_form.title') }}
      </h1>
    </div>
    <draggable ghost-class="abk-ghost" animation="200" :value="value" @input="$emit('input', $event)">
      <transition-group name="slide" mode="out-in">
        <div v-for="(cvExperience, index) in value" :key="cvExperience.key" class="section">
          <abk-cv-experiences-form-section :value="cvExperience"
                                           :index="index"
                                           v-show="!cvExperience._destroy"
                                           :errors="errors"
                                           :workDimensionOptions="workDimensionOptions"
                                           @remove="remove(cvExperience, index)"
                                           @input="updateSection($event, index)"
                                           :dictionaries-meta-info="dictionariesMetaInfo"/>
        </div>
      </transition-group>
    </draggable>
    <pcg-btn size="small" variant="additional" class="my-0" @click="addSection">
      {{ $t('general.add') }}
    </pcg-btn>
  </div>
</template>

<script>
import AbkCvExperiencesFormSection from './AbkCvExperiencesFormSection'
import ApiDictionaryWords from '../../api/dictionary_words'
import draggable from 'vuedraggable'

export default {
  name: 'AbkCvExperiencesForm',
  components: { AbkCvExperiencesFormSection, draggable },
  props: {
    value: Array,
    errors: Object
  },
  data () {
    return {
      workDimensionOptions: [],
      dictionariesMetaInfo: {}
    }
  },
  computed: {
    maxKey () {
      return Math.max(...this.value.map((section) => section.key ? section.key : 0), 0)
    }
  },
  created () {
    this.getTypeOfWorkOptions()
  },
  methods: {
    getTypeOfWorkOptions () {
      ApiDictionaryWords.getDictionaryWordsByKey('work_dimension')
        .then(response => {
          this.workDimensionOptions = response.data.data.map(workDimension => {
            return {
              text: workDimension.attributes.value,
              value: workDimension.id
            }
          })
          this.dictionariesMetaInfo = { ...this.dictionariesMetaInfo, workDimension: { ...response.data.meta } }
        }).catch(error => {
          console.log(error)
        })
    },
    update (newArray) {
      this.$emit('input', newArray)
    },
    updateSection (newSection, index) {
      const copy = [...this.value]
      copy.splice(index, 1, newSection)
      this.update(copy)
    },
    remove (section, index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          if (section.id) {
            const copy = [...this.value]
            copy.splice(index, 1, { ...section, _destroy: true })
            this.update(copy)
          } else {
            const copy = [...this.value]
            copy.splice(index, 1)
            this.update(copy)
            // TODO remove errors
            // if (this.errorExist(`webpageAttachments.${index}.attachment`)) {
            //   delete this.errors[`webpageAttachments.${index}.attachment`]
            // }
            // this.value.webpageAttachments.forEach((attachment, attachmentIndex) => {
            //   if (attachmentIndex > index) {
            //     if (this.errorExist(`webpageAttachments.${attachmentIndex}.attachment`)) {
            //       this.errors[`webpageAttachments.${attachmentIndex - 1}.attachment`] = this.errors[`webpageAttachments.${attachmentIndex}.attachment`]
            //       delete this.errors[`webpageAttachments.${attachmentIndex}.attachment`]
            //     }
            //   }
            // })
          }
        }
      })
    },
    addSection () {
      const emptySection = this.value.filter(entry => !entry._destroy).some(entry => {
        const filtredEntry = _.omitBy(entry, (value, key) => {
          return ['id', 'key', 'experienceType', 'removeAttachment'].includes(key)
        })
        return _.isEmpty(_.pickBy(filtredEntry))
      })
      if (emptySection) {
        this.$toastr.e(this.$t('components.curriculum_vitaes.abk_cv_experiences_form.empty_section_error'))
        return
      }

      this.update([...this.value, {
        id: null,
        key: this.generateKey(),
        experienceType: 'job',
        position: null,
        institution: null,
        dateStart: null,
        dateEnd: null,
        description: null,
        employmentTypeId: null,
        attachment: null
      }])
    },
    generateKey () {
      return this.maxKey + 1
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .title-container {
    margin-bottom: $pcg-m-lg;
  }
  .section:not(:first-child) {
    margin-top: $pcg-m-lg;
  }
  .section:last-child {
    margin-bottom: 15px;
  }
</style>
