<template>
  <pcg-box :header="header" iconClass="pcg-icon-competences header-icon">
    <pcg-search-select :placeholder="placeholderText"
                       :options="clearedSuggestions"
                       autocomplete="off"
                       :value="selectedTag"
                       @input="add"
                       always-active
                       allow-empty
                       :show-value-addition="showValueAddition"
                       :show-error="showError"
                       :error-text="errorText"
                       @showModalSuggestion="showModalSuggestion"
                       class="flex-grow-1"/>
    <div class="tags-container" v-if="value.length > 0">
      <span v-for="tag in value"
            :key="`tag-${tag.value}`"
            @click="remove(tag)"
            :class="tag.class"
            v-b-tooltip.hover
            :title="tag.text"
            class="tag">
        <span>
          ✕
        </span>
        {{ tag.text }}
      </span>
    </div>
    <div class="tags-container pcg-no-records" v-else>
      {{ emptyText }}
    </div>
  </pcg-box>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'AbkTagsBox',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    header: {
      type: String,
      required: false
    },
    emptyText: String,
    placeholderText: String,
    showError: Boolean,
    errorText: String,
    showValueAddition: Boolean
  },
  mixins: [clickaway],
  data: function () {
    return {
      newTag: '',
      selectedTag: ''
    }
  },
  computed: {
    clearedSuggestions () {
      const ids = this.value.map(tag => tag.value)
      return this.options.filter(tag => !ids.includes(tag.value))
    }
  },
  methods: {
    showModalSuggestion () {
      this.$emit('showModalSuggestion')
    },
    remove (removeTag) {
      this.$emit('input', this.value.filter(tag => tag.value !== removeTag.value))
      this.$emit('removed', removeTag.value)
      this.selectedTag = ''
    },
    add (addTagId) {
      const newTag = this.fetchTag(addTagId)
      if (newTag && !this.value.map(tag => tag.value).includes(newTag.value)) {
        this.$emit('added', addTagId)
        this.$emit('input', this.value.concat(newTag))
      }
      this.selectedTag = ''
    },
    fetchTag (tagId) {
      return this.options.find(option => option.value === tagId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

.tags-container {
  padding-top: 15px;
}

.tag {
  color: $main-color;
  background-color: $color-active-filter;
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: $font-size-s;
  font-weight: 500;
  line-height: 1rem;
  padding: 8px 13px;
  border-radius: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  transition: background-color 0.3s;
  &:hover {
    cursor: pointer;
    background-color: darken($color-active-filter, 10%);
    span {
      color: darken($pcg-dark-gray, 10%);
    }
  }
  span {
    color: $pcg-dark-gray;
    padding-right: 5px;
    font-size: 0.9rem;
  }
}

.bootstrap-select {
  position: relative;
  .dropdown-menu {
    top: -14px;
  }
}

</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast{
  .tag {
    color: $main-color;
    background-color: $color-active-filter;
    &:hover {
      background-color: darken($color-active-filter, 10%);
      span {
        color: darken($pcg-dark-gray, 10%);
      }
    }
    span {
      color: $pcg-dark-gray;
    }
  }
}
</style>
