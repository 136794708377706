<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <b-row v-if="prevRoute && prevRoute.name === 'offers_path'" class="navigation-bar">
        <b-col>
          <button class="pcg-link prev-route-link" @click="$router.go(-1)">
            {{ $t('views.offers.offers_show.go_back_to_search') }}
          </button>
        </b-col>
      </b-row>
      <b-row v-if="isFinished">
        <abk-info-bar :info-text="$t('views.offers.offers_show.outdated_offer')"
                      :path="{ name: 'offers_list_similar_path', params: { offerId: offer.id }}"
                      :path-text="$t('views.offers.offers_show.show_similar_offers')"/>
      </b-row>
      <b-row class="top-bar">
        <b-col cols="12" xl="8">
          <h2 class="offer-name">{{ offerInfo.jobTitle }}</h2>
          <div class="d-flex justify-content-between align-items-center">
            <div class="offer-location">
              <router-link :to="{ name: 'company_show_path', params: { id: companyId } }"
                           class="company-name pcg-main-active-color mr-2">
                {{ companyName }}
              </router-link>
              <span class="pcg-dark-gray-color">{{ workplace }}</span>
              <div v-if="company.employerType === 'partner'" class="partner-container">
                <span class="partner-text">{{ company.employerTypeText }}</span>
                <img v-if="contrast" :src="company.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
                <img v-else :src="company.employerTypeLogo" class="img-fluid rounded partner-logo">
              </div>
            </div>
            <abk-add-to-favorites-button v-if="showStudentActions && cvConsent" :added="favorite" @click="toggleFavorites"/>
            <div v-if="canBeEdited || canBeSendForPublication">
              <pcg-btn v-if="canBeEdited"
                       size="small"
                       class="mt-0"
                       variant="additional"
                       @click="$router.push({ name: 'offers_edit_path', params: { id: offer.id } })">
                {{ $t('general.edit') }}
              </pcg-btn>
              <pcg-btn v-if="canBeSendForPublication"
                       size="small"
                       class="mt-0"
                       variant="additional"
                       @click="sendForPublication">
                {{ $t('general.send_for_publication') }}
              </pcg-btn>
              <pcg-btn v-if="oldVersionExist"
                       size="small"
                       class="mt-0"
                       variant="additional"
                       @click="$router.push({ name: 'offers_show_path', params: { id: offer.oldVersionId } })">
                {{ $t('general.old_version') }}
              </pcg-btn>
            </div>
            <pcg-btn v-if="isOldVersion"
                     size="small"
                     class="mt-0"
                     variant="additional"
                     @click="$router.push({ name: 'offers_show_path', params: { id: offer.originalId } })">
              {{ $t('general.current_version') }}
            </pcg-btn>
          </div>
          <div v-if="userCvMatchingPresent && env && env.competenceFeature !== 'off'" class="progress-bar-container">
            <span class="pcg-dark-gray-color">{{ $t('views.offers.offers_show.cv_matching_offer_percent') }}</span>
            <abk-form-progress-bar :progress-percent="userCvMatching"/>
          </div>
        </b-col>
        <div class="apply-container col-12 col-xl-4 d-flex flex-xl-column justify-content-end align-items-center"
             v-if="showStudentActions && cvConsent">
          <template v-if="applied">
            <span class="matching-cv">{{ $t('views.offers.offers_show.you_applied_for_this_offer') }}</span>
            <router-link :to="{ name: 'applications_offers_path'}" class="ml-1 applied-offer">
              {{ $t('views.offers.offers_show.see_your_applications_link') }}
            </router-link>
          </template>
          <template v-else>
          <span class="matching-cv" v-if="offer.offerMatch">
            {{ $t('components.offers.tabs.abk_offer_tab.matching_cv') }}
          </span>
            <pcg-btn v-if="isAccepted"
                     size="small"
                     class="mr-0 mb-0 mt-0 m-xl-2"
                     @click="offerApplicationModalShow = true">
              {{ $t('components.offers.tabs.abk_offer_tab.apply_now') }}
            </pcg-btn>
          </template>
        </div>
        <div v-if="(showEmployerActions || showCareerOfficeActions) && isNotDraft"
             class="col-12 col-xl-4 d-flex justify-content-center align-items-end">
          <template v-if="isWaitingForAcceptance && showCareerOfficeActions">
            <pcg-btn size="small"
                     variant="additional"
                     @click="performAction('acceptOffer')">
              {{ $t('general.accept') }}
            </pcg-btn>
            <pcg-btn size="small"
                     variant="additional"
                     @click="rejectOffer(offerId)">
              {{ $t('general.reject') }}
            </pcg-btn>
          </template>
          <template v-else>
            <pcg-btn v-if="isAccepted"
                     size="small"
                     variant="additional"
                     @click="performAction('finishOffer')">
              {{ $t('general.finish') }}
            </pcg-btn>
          </template>
        </div>
      </b-row>
      <b-row>
        <abk-content-preview
            v-if="offerSections.length"
            :contentSections="offerSections"
            :attachments="offer.offerAttachments"
            class="col-12 col-xl-8"/>
        <b-col cols="12" xl="4">
          <div class="row">
            <abk-offer-salary-box v-if="salaryFrom || salaryTo" :salary-from="salaryFrom" :salary-to="salaryTo"/>
            <abk-quick-info :offer-info="offerInfo" class="col-12 col-sm-6 col-xl-12"/>
            <abk-offers-box v-if="!isOldVersion" :offers="similarOffers"
                            :limit="4"
                            class="col-12 col-sm-6 col-xl-12">
              <template v-slot:header>
                <i class="pcg-icon-fan"></i>
                {{ $t('components.offers.tabs.abk_offer_tab.similar_offers') }}
              </template>
            </abk-offers-box>
            <abk-offers-box v-if="!isOldVersion" :offers="otherOffers" :limit="4">
              <template v-slot:header>
                <i class="pcg-icon-briefcase"></i>
                {{ $t('components.offers.tabs.abk_offer_tab.other_company_offers') }}
              </template>
            </abk-offers-box>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="offer.updateDate">
        <b-col cols="12">
          {{ $t('views.offers.offers_show.update_date') }}: {{ formattedUpdateTime }}
        </b-col>
      </b-row>
      <abk-offer-application-modal :company="company" :offer="offer" v-model="offerApplicationModalShow"/>
      <abk-reject-offer-modal v-model="rejectOfferModalShow" :offer-id="offerId" @reload="getOffer"/>
    </div>
  </div>
</template>

<script>
import AbkQuickInfo from '../../components/AbkQuickInfo'
import AbkOffersBox from '../../components/AbkOffersBox'
import AbkAddToFavoritesButton from '../../components/AbkAddToFavoritesButton'
import AbkContentPreview from '../../components/AbkContentPreview'
import ApiOffers from '../../api/offers'
import AbkOfferSalaryBox from '../../components/AbkOfferSalaryBox'
import AbkOfferApplicationModal from '../../components/offer_applications/AbkOfferApplicationModal'
import AbkRejectOfferModal from '../../components/offers/AbkRejectOfferModal'
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import AbkFormProgressBar from '../../components/AbkFormProgressBar'
import AbkInfoBar from '../../components/AbkInfoBar'
import AbkPageNotFound from '../../components/AbkPageNotFound'

export default {
  name: 'offers-show',
  components: {
    AbkPageNotFound,
    AbkInfoBar,
    AbkFormProgressBar,
    AbkOfferApplicationModal,
    AbkOfferSalaryBox,
    AbkAddToFavoritesButton,
    AbkOffersBox,
    AbkRejectOfferModal,
    AbkQuickInfo,
    AbkContentPreview
  },
  data () {
    return {
      offerId: null,
      offer: {},
      company: {},
      offerApplicationModalShow: false,
      similarOffers: [],
      otherOffers: [],
      prevRoute: null,
      notFound: false,
      rejectOfferModalShow: false
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('header', ['title', 'subtitle']),
    ...mapGetters('user', ['cvConsent', 'companyConsent']),
    ...mapGetters('environment', ['env']),
    ...mapGetters('page', ['contrast']),
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    },
    applied () {
      return this.offer.applied
    },
    favorite () {
      return this.offer.favorite
    },
    salaryFrom () {
      return this.offer.salaryFrom
    },
    salaryTo () {
      return this.offer.salaryTo
    },
    offerSections () {
      return this.offer.offerSections || []
    },
    offerInfo () {
      const { offerSections, offerAttachments, ...offerInfo } = this.offer
      return offerInfo
    },
    companyName () {
      return this.offer.company && this.offer.company.name
    },
    companyId () {
      return this.offer.company && this.offer.company.id
    },
    workplace () {
      return this.offerInfo.workplace ? this.offerInfo.workplace.value : null
    },
    canBeEdited () {
      return (this.isNotFinished && (this.showEmployerActions || this.showCareerOfficeActions)) ||
        (this.isWaitingForAcceptance && this.showCareerOfficeActions)
    },
    canBeSendForPublication () {
      return this.showEmployerActions && this.isNotSend
    },
    oldVersionExist () {
      return this.offer.oldVersionId
    },
    isOldVersion () {
      return this.offer.status === 'old_version'
    },
    isNotSend () {
      return ['draft', 'recent'].includes(this.offer.status)
    },
    isNotFinished () {
      return ['draft', 'recent', 'for_acceptance', 'accepted'].includes(this.offer.status)
    },
    isNotDraft () {
      return this.offer.status !== 'draft'
    },
    isWaitingForAcceptance () {
      return this.offer.status === 'for_acceptance'
    },
    isAccepted () {
      return this.offer.status === 'accepted'
    },
    isFinished () {
      return this.offer.status === 'finished'
    },
    userCvMatchingPresent () {
      return _.isNumber(this.offer.userCvMatching) && ['student', 'graduate'].includes(this.currentRole)
    },
    userCvMatching () {
      return _.isNumber(this.offer.userCvMatching) ? this.offer.userCvMatching * 100 : null
    },
    formattedUpdateTime () {
      const time = moment(this.offer.updateDate)
      return time.isValid() ? time.format('YYYY-MM-DD HH:mm') : null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
      vm.prevRoute = from
    })
  },
  beforeRouteUpdate (to, from, next) {
    next()
    window.scrollTo(0, 0)
    this.offerId = this.$route.params.id
    this.getOffer()
    this.getSimilarOffers()
    this.getOtherOffers()
  },
  mounted () {
    this.offerId = this.$route.params.id
    this.getOffer()
    this.getSimilarOffers()
    this.getOtherOffers()
  },
  methods: {
    sendForPublication () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.send_for_publication'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiOffers.sendForPublication(this.offerId)
            .then(() => {
              this.$toastr.s(this.$t('views.offers.offers_show.offer_sent_for_publication'))
              this.offer.status = 'for_acceptance'
            })
            .catch(() => {
              this.$toastr.e(this.$t('views.offers.offers_show.offer_sending_for_publication_error'))
            })
        }
      })
    },
    goBackToSearch () {
      this.$router.push(this.prevRoute.fullPath)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_show.title'),
        subtitle: this.$t('views.offers.offers_show.subtitle')
      })
    },
    toggleFavorites () {
      const action = this.favorite ? 'removeFromFavorites' : 'addToFavorites'

      ApiOffers[action](this.offerId)
        .then(() => {
          this.offer.favorite = !this.favorite
          this.$store.dispatch('user/getCatalogOfferCounters')
        })
        .catch(error => {
          this.$toastr.e(error.response)
        })
    },
    getOffer () {
      ApiOffers.getOffer(this.offerId)
        .then(response => {
          if (['draft', 'recent', 'for_acceptance'].includes(response.data.data.attributes.status) && this.showStudentActions && response.data.data.attributes.applied === false) {
            this.$toastr.e(this.$t('general.forbidden'))
            this.goBackToSearch()
          }
          this.offer = { ...response.data.data.attributes, id: response.data.data.id }
          this.company = response.data.data.attributes.company
          this.$store.dispatch('header/setObjectName', this.offer.jobTitle)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.notFound = true
          }
          console.log(error)
        })
    },
    getOtherOffers () {
      ApiOffers.getOtherOffers(this.offerId, 4)
        .then(response => {
          this.otherOffers = response.data.data.map(offer => {
            return {
              id: offer.id,
              ...offer.attributes
            }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    getSimilarOffers () {
      ApiOffers.getSimilarOffers({ offerId: this.offerId, filters: { count: 4 } })
        .then(response => {
          this.similarOffers = response.data.data.map(offer => {
            return {
              id: offer.id,
              ...offer.attributes
            }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    rejectOffer (offerId) {
      this.rejectOfferModalShow = true
    },
    performAction (action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiOffers[action](this.offerId)
            .then(() => {
              this.getOffer()
            }).catch(() => {
              this.$toastr.e(this.$t('views.offers.offers_show.unexpected_error'))
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .progress-bar-container {
    margin-top: $pcg-m-lg;
  }
  .prev-route-link {
    font-size: $font-size-m;
    text-transform: uppercase;
    font-weight: 500;
    background: none;
    border: none;
    outline: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .offer-name {
    font-size: $pcg-title-font-size;
    font-weight: 400;
    color: $main-color;
    margin-bottom: $pcg-m-lg;
    line-height: 1.2;
  }
  /deep/ .favorite-btn {
    font-size: $font-size-s;
    font-weight: bold;
  }
  .matching-cv {
    font-size: $font-size-s;
    color: $pcg-orange;
  }
  .applied-offer {
    font-size: $font-size-s;
  }
  .offer-location {
    font-size: $font-size-m;
    font-weight: 400;
  }
  .top-bar {
    margin-bottom: $pcg-m-lg;
  }
  .navigation-bar {
    margin-bottom: $pcg-m-xl;
  }

  @media (max-width: $screen-xlg-min) {
    .apply-container {
      margin-top: $pcg-m-lg;
    }
  }

  .partner-container {
    display: inline-block;
    margin-left: 1rem;
  }

  .partner-text {
    color: $pcg-black;
    font-weight: bold;
    font-size: $font-size-s;
    background-color: rgba(200, 200, 200, 0.5);
    padding: .15rem .4rem;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .partner-logo {
    position: relative;
    right: .2rem;
    max-width: 20px;
  }
</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .partner-text {
    color: $pcg-black;
  }
}
</style>
