<template>
  <div class="form-progress-bar">
    <div class="progress" :style="{ width: `${progressPercent}%` }"></div>
  </div>
</template>

<script>
export default {
  name: 'AbkFormProgressBar',
  props: {
    progressPercent: {
      type: Number,
      default: 0,
      validator (progress) {
        return progress >= 0 && progress <= 100
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .form-progress-bar {
    background-color: $pcg-white;
    height: 15px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
    overflow: hidden;
  }

  .progress {
    background-color: $pcg-green-color;
    border-radius: inherit;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .form-progress-bar {
      background-color: $pcg-white;
      box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
    }

    .progress {
      background-color: $pcg-green-color;
    }
  }
</style>
