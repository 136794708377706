<template>
  <button class="attachment-btn" @click="$emit('click')"><i class="pcg-icon-clip pcg-sm"></i><slot></slot></button>
</template>

<script>
export default {
  name: 'AbkAttachment',
  props: {
    attachment: Object
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .attachment-btn {
    padding: 0;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $main-active-color;
    background: none;
    outline: none;
    border: none;
    i {
      margin-right: $pcg-m-sm;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .attachment-btn {
      color: $main-active-color;
    }
  }
</style>
