<template>
  <div class="surveys">
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Surveys'
}
</script>

<style scoped>

</style>
