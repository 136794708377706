<template>
  <abk-competences-list competences-type="all"/>
</template>

<script>
import AbkCompetencesList from '../../../components/competences/AbkCompetencesList'

export default {
  name: 'AllCompetencesTab',
  components: { AbkCompetencesList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.competences.list_tabs.all_competences_tab.title'),
        subtitle: this.$t('views.competences.list_tabs.all_competences_tab.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
