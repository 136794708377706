<template>
  <pcg-box>
    <div :class="[ 'offer-invitation-container', { faded: outdatedOffer || applied }]">
      <b-row>
        <b-col cols="10" offset-sm="2">
          <div class="invitation-top-bar">
            <div class="invitation-date">
              {{ formatDate(offerInvitation.createdAt) }}
            </div>
            <div v-if="applied">{{ $t('components.abk_invitation_box.applied') }}</div>
            <div v-else-if="outdatedOffer" class="outdated-offer-text">
              {{ $t('components.abk_invitation_box.outdated_offer') }}
            </div>
          </div>
        </b-col>
      </b-row>
      <abk-offer-record :record="offerInvitation.offer" class="row"/>
      <b-row>
        <b-col cols="2" class="d-none d-sm-block inviting-name-container">
          <div class="inviting-name">{{ offerInvitation.inviting.name }}</div>
          <div class="inviting-name">{{ offerInvitation.inviting.companyName }}</div>
        </b-col>
        <b-col cols="10">
          <pcg-btn v-if="!applied && !outdatedOffer"
                   size="small"
                   class="mb-0 ml-0"
                   @click="offerApplicationModalShow = true">
            {{ $t('general.apply') }}
          </pcg-btn>
        </b-col>
      </b-row>
    </div>
    <abk-offer-application-modal :company="{ name: offerInvitation.inviting.companyName }"
                                 :offer="offerInvitation.offer"
                                 v-model="offerApplicationModalShow"
                                 @applied="$emit('applied', $event)"/>
  </pcg-box>
</template>

<script>
import AbkOfferRecord from './AbkOfferRecord'
import AbkOfferApplicationModal from './offer_applications/AbkOfferApplicationModal'
export default {
  name: 'AbkInvitationBox',
  components: { AbkOfferApplicationModal, AbkOfferRecord },
  props: {
    offerInvitation: Object
  },
  data () {
    return {
      offerApplicationModalShow: false
    }
  },
  computed: {
    outdatedOffer () {
      return this.offerInvitation.offer.status === 'finished'
    },
    applied () {
      return this.offerInvitation.offer.applied
    }
  },
  methods: {
    formatDate (date) {
      return moment(date).calendar()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .offer-invitation-container {
    &.faded {
      opacity: .5;
    }
  }
  .invitation-top-bar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid $pcg-lightest-gray;
    padding-bottom: $pcg-m-xs;
    font-weight: 500;
  }
  .invitation-date {
    color: $pcg-light-gray;
    margin-right: $pcg-m-sm;
  }
  .outdated-offer-text {
    color: $pcg-the-darkest-gray;
  }
  .inviting-name-container {
    padding-top: $pcg-m-sm;
  }
  .inviting-name {
    font-size: $font-size-s;
    color: $main-color;
    text-align: center;
    font-weight: 500;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .offer-invitation-container {
      .record {
        border-bottom: none !important;
      }
    }
    .invitation-top-bar {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    .invitation-date {
      color: $pcg-light-gray;
    }
    .outdated-offer-text {
      color: $pcg-the-darkest-gray;
    }
    .inviting-name {
      color: $main-color;
    }
  }
</style>
