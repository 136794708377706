<template>
  <b-collapse :id="componentId" :visible="searchPresent">
    <div class="abk-save-offer-filter">
      <div v-if="id">
        <span class="pcg-link" @click="emitToggle">
          <i class="pcg-icon-eye"></i>
          {{ $t('components.abk_save_offer_filter.remove') }}
        </span>
        <router-link :to="{ name: 'offer_filters_path' }" class="pcg-link pcg-link-darken">
          {{ $t('components.abk_save_offer_filter.show_observed') }}
        </router-link>
      </div>
      <template v-else>
        <span class="pcg-link" @click="emitToggle">
          <i class="pcg-icon-eye"></i>
          {{ $t('components.abk_save_offer_filter.add') }}
        </span>
        <span class="info-text">{{ $t('components.abk_save_offer_filter.info_text') }}</span>
      </template>
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: 'abk-save-offer-filter',
  props: ['id', 'search'],
  data () {
    return {
      componentId: this._uid.toString()
    }
  },
  computed: {
    filteredSearch () {
      return _.pickBy(this.search, (value, key) => Array.isArray(value) && value.length)
    },
    searchPresent () {
      return !_.isEmpty(this.filteredSearch)
    }
  },
  methods: {
    emitToggle () {
      // console.log('toggle')
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .abk-save-offer-filter {
    padding-bottom: 20px;

    .pcg-link {
      margin-right: 15px;
      font-weight: bold;
      i {
        font-size: $font-size-l;
        vertical-align: text-top;
        margin-right: 5px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .info-text {
      color: $pcg-gray;
      font-size: $font-size-s;
    }
  }

  @media(max-width: $screen-xlg-min) {
    .abk-save-offer-filter {
      padding-top: 10px;
    }
  }

</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .abk-save-offer-filter {
      .info-text {
        color: $pcg-gray;
      }
    }
  }
</style>
