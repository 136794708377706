<template>
  <div>
    <h1 class="pcg-form-title">{{ $t('components.abk_visual_identification_form.title') }}</h1>
    <b-row>
      <div class="col-12 col-xl-6 image-input">
        <span class="file-input-label">{{ $t('components.abk_visual_identification_form.logo') }}</span>
        <abk-file-input ref="logo"
                        :fileUrl="getImageUrl('logo')"
                        @input="updateField('logo', $event)"
                        :status="fileUploadStatus('logo')"
                        :error-text="getError('logo')"
                        v-b-tooltip.hover
                        :title="getError('logo')"
                        :accept="acceptableExtensions"
                        show-remove-button
                        @remove="removeField('logo')"/>
      </div>
      <div class="col-12 col-xl-6 image-input">
        <span class="file-input-label">{{ $t('components.abk_visual_identification_form.background') }}</span>
        <abk-file-input ref="backgroundImage"
                        :fileUrl="getImageUrl('backgroundImage')"
                        @input="updateField('backgroundImage', $event)"
                        :status="fileUploadStatus('backgroundImage')"
                        :error-text="getError('backgroundImage')"
                        v-b-tooltip.hover
                        :title="getError('backgroundImage')"
                        :accept="acceptableExtensions"
                        show-remove-button
                        @remove="removeField('backgroundImage')"/>
      </div>
    </b-row>
  </div>
</template>

<script>
import AbkFileInput from './AbkFileInput'
export default {
  name: 'AbkVisualIdentificationForm',
  components: { AbkFileInput },
  props: {
    value: Object,
    errors: Object
  },
  data () {
    return {
      localErrors: this.errors,
      acceptableExtensions: '.jpg,.jpeg,.gif,.png'
    }
  },
  watch: {
    errors (newValue) {
      this.localErrors = newValue
    }
  },
  methods: {
    updateField (field, value) {
      if (value.size > 4200000) {
        this.removeField(field)
        this.localErrors[field] = [this.$t('components.abk_visual_identification_form.max_size')]
      } else {
        this.localErrors[field] = null
        delete this.value[this.removeFieldName(field)]
        this.$emit('input', { ...this.value, [field]: value })
      }
    },
    fileUploadStatus (field) {
      if (this.localErrors[field]) {
        return 'fail'
      } else if (this.isFilePresent(field)) {
        return 'success'
      }
      return 'upload'
    },
    isFilePresent (field) {
      return this.value[field] instanceof File || (this.value[field] && this.value[field].url)
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.localErrors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.localErrors && this.localErrors[field])
    },
    getImageUrl (field) {
      return this.value[field] ? this.value[field].url : null
    },
    removeFieldName (field) {
      return `remove${_.upperFirst(field)}`
    },
    removeField (field) {
      this.localErrors[field] = null
      this.$refs[field].clear()
      this.$emit('input', { ...this.value, [field]: null, [this.removeFieldName(field)]: true })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .file-input-label {
    font-weight: 500;
    color: $pcg-gray;
    z-index: 10;
    top: 5px;
    left: 20px;
    position: absolute;
  }

  @media(max-width: $screen-xlg-min) {
    .image-input:not(:last-child) {
      margin-bottom: $pcg-m-lg !important;
    }
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .file-input-label {
      color: $pcg-light-gray;
    }
  }
</style>
