<template>
  <b-collapse :visible="visible" :id="String(question.id)">
    <div v-if="question.questionType === 'section'" class="pcg-section-title">{{ question.title }}</div>
    <div v-else-if="question.questionType === 'separator'" class="pcg-separator"/>
    <template v-else>
      <div v-if="question.title" :class="['pcg-input-label', { 'pcg-input-label-required': question.mandatory }]">
        {{ question.title }}
      </div>
      <div v-if="question.description" class="pcg-input-label faded-text">{{ question.description }}</div>
      <abk-radio-buttons-question v-if="question.questionType === 'radiobutton'"
                                  :question="question"
                                  :value="value"
                                  @input="$emit('input', $event)"
                                  :required="question.mandatory"
                                  :error-text="errorText"
                                  :show-error="showError"
                                  :show-text-question-field.sync="showTextQuestionField"/>
      <abk-checkbox-question v-else-if="question.questionType === 'checkbox'"
                             :question="question"
                             :value="value"
                             :required="question.mandatory"
                             :error-text="errorText"
                             :show-error="showError"
                             :show-text-question-field.sync="showTextQuestionField"
                             @input="$emit('input', $event)"/>
      <pcg-text-input v-else-if="['text', 'number'].includes(question.questionType)"
                      :show-label="false"
                      :value="value"
                      :class="{'number-question': question.questionType === 'number' }"
                      :type="question.questionType"
                      @input="$emit('input', $event)"
                      :required="question.mandatory"
                      :error-text="errorText"
                      :show-error="showError"/>
    </template>
  </b-collapse>
</template>

<script>
import AbkRadioButtonsQuestion from '../../components/surveys/AbkRadioButtonsQuestion'
import AbkCheckboxQuestion from '../../components/surveys/AbkCheckboxQuestion'

export default {
  name: 'AbkQuestion',
  components: { AbkRadioButtonsQuestion, AbkCheckboxQuestion },
  props: {
    question: Object,
    value: [String, Number, Array, Object],
    visible: Boolean,
    errors: Array
  },
  data () {
    return {
      showTextQuestionField: false
    }
  },
  watch: {
    visible (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.$emit('input', null)
        this.showTextQuestionField = false
      }
    }
  },
  computed: {
    errorText () {
      return _.compact(this.errors).join(', ')
    },
    showError () {
      return this.errorText.length > 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .number-question {
    max-width: 250px;
  }
  .faded-text {
    color: $pcg-gray;
    margin-top: -5px;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .faded-text {
      color: $pcg-gray;
    }
  }
</style>
