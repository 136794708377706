<template>
  <pcg-box class="list-of-employers">
    <abk-loader v-if="showLoader"/>
    <template v-else-if="listOfEmployers.length > 0">
      <div v-for="(record, index) in listOfEmployers" :key="`record-${index}`"
           class="oneline d-flex justify-content-between">
        <div class="one-row d-flex">
          <pcg-avatar size="extra-small" class="mr-3" :img="getAvatar(record.avatar)"/>
          <div v-if="record.company && record.company.employerType === 'partner'" style="width: 0; height: 0; position: relative; right: 2rem; bottom: 2rem">
            <img v-if="contrast" :src="record.company.employerTypeLogoNegative" class="img-fluid rounded" style="max-width: 20px;">
            <img v-else :src="record.company.employerTypeLogo" class="img-fluid rounded" style="max-width: 20px;">
          </div>
          <div>
            <div v-if="$te(`general.${record.offerType}`)" class="offer-type">
              {{ $t(`general.${record.offerType}`) }}
            </div>
            <div>
              <router-link :to="{ name: linkPath, params: { id: record.user_id }, query: record.queryParams }">
                <span class="name" :style="[ record.avatar ? { 'font-weight': 'bold' } : { 'font-weight': '400' } ]">
                  {{ record.name }}
                </span>
              </router-link>
              <router-link class="firm"
                           v-if="record.company"
                           :to="{ name: 'company_show_path', params: { id: record.company.id } }">
                {{ record.company.name }}
              </router-link>
              <span class="information" v-if="record.date">
                <label v-if="dateLabelText">{{ dateLabelText }}</label>
                {{ record.date }}
              </span>
              <span class="information" v-if="record.avatar">
                {{ record.college }}
              </span>
              <i v-for="attachment in record.attachments" v-b-tooltip.hover :title="attachment.filename"
                 :key="attachment.id"
                 class="pcg-icon-clip pcg-sm attachment-icon"
                 @click="downloadAttachment(attachment)"></i>
            </div>
          </div>
        </div>
        <div class="one-row d-flex" v-if="showActions">
          <pcg-btn size="small" v-if="!record.avatar">
            {{ $t('general.accept') }}
          </pcg-btn>
          <span class="reject" v-if="!record.avatar">
            {{ $t('general.reject') }}
          </span>
          <pcg-btn size="small" v-if="record.avatar && !record.date">
            {{ $t('components.abk_list_of_employers.recruit') }}
          </pcg-btn>
        </div>
        <slot v-bind="record"></slot>
      </div>
    </template>
    <span v-else class="pcg-no-records">{{ noRecordsText }}</span>
  </pcg-box>

</template>

<script>
import ApiOfferApplicationAttachments from '../api/offer_application_attachments'
import { saveAs } from 'file-saver'
import AbkLoader from './AbkLoader'
import { mapGetters } from 'vuex'

export default {
  name: 'abk-list-of-employers',
  components: { AbkLoader },
  props: {
    listOfEmployers: {
      type: Array,
      default: () => []
    },
    showActions: {
      type: Boolean,
      default: false
    },
    linkPath: {
      type: String,
      default: 'curriculum_vitae_show_path'
    },
    noRecordsText: String,
    dateLabelText: String,
    showLoader: Boolean
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    getAvatar (avatar) {
      return avatar && avatar.url ? avatar.url : require('../assets/images/avatar-placeholder.png')
    },
    downloadAttachment ({ id, filename }) {
      ApiOfferApplicationAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

  .oneline {
    padding-bottom: 10px;
    flex-wrap: wrap;
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .name {
    color: $main-color;
  }
  .one-row {
    align-items: center;
    flex-wrap: wrap;
  }
  .information {
    color: $pcg-dark-gray;
    margin-left: 15px;
  }
  .firm {
    color: $main-color;
    font-weight: bold;
    margin-left: 15px;
  }
  .reject {
    color: $main-active-color;
    margin-left: 5px;
    font-weight: 400;
    &:hover {
      cursor: pointer;
    }
  }
  .attachment-icon {
    margin-left: $pcg-m-sm;
    cursor: pointer;
    color: $pcg-gray;
  }
  .offer-type {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-gray;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .oneline {
       &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .name {
      color: $main-color;
    }
    .information {
      color: $pcg-dark-gray;
    }
    .firm {
      color: $main-color;
    }
    .reject {
      color: $main-active-color;
    }
    .attachment-icon {
      color: $pcg-gray;
    }
    .offer-type {
      color: $pcg-gray;
    }
  }
</style>
