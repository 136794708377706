import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'surveys'

export default {
  getSurvey (id) {
    return api.get(`${resource}/${id}`)
  },
  getSurveyPreview (id) {
    return api.get(`${resource}/${id}/preview`)
  },
  createSurvey (payload) {
    return api.post(`${resource}`, payload)
  },
  validateStep (payload) {
    return api.post(`${resource}/validate`, payload)
  },
  updateValidateStep (surveyId, payload) {
    return api.post(`${resource}/${surveyId}/validate`, payload)
  },
  updateSurvey (surveyId, payload) {
    return api.patch(`${resource}/${surveyId}`, payload)
  },
  getActiveSurveys (filters) {
    return api.get(`${resource}/active?${stringify(decamelize(filters))}`)
  },
  getInactiveSurveys (filters) {
    return api.get(`${resource}/inactive?${stringify(decamelize(filters))}`)
  },
  publishSurvey (id) {
    return api.patch(`${resource}/${id}/publish`)
  },
  closeSurvey (id) {
    return api.patch(`${resource}/${id}/close`)
  },
  destroySurvey (surveyId) {
    return api.delete(`${resource}/${surveyId}`)
  },
  getSurveyStats (surveyId) {
    return api.get(`${resource}/${surveyId}/stats`)
  },
  exportAnswers (surveyId) {
    return api.post(`${resource}/${surveyId}/export_answers`)
  },
  getAnswersExportStatus (id) {
    return api.get(`${resource}/${id}/check_answers_export_status`)
  },
  getSurveyDetails (id) {
    return api.get(`${resource}/${id}/details`)
  }
}
