<template>
  <b-col cols="12">
    <abk-section-header :title="$t('components.events.abk_event_description_form.title')"
                        :subtitle="$t('components.events.abk_event_description_form.subtitle')"
                        icon-class="pcg-icon-content"/>
    <draggable :value="value"
               animation="200"
               ghost-class="abk-ghost"
               class="sections-container row"
               @input="$emit('input', $event)"
               @end="onEnd">
      <b-col v-for="(section, index) in value"
             cols="12"
             class="abk-section-box"
             :class="{ 'd-none': section._destroy }"
             :key="`section-${section.key}`">
        <template v-if="!section._destroy">
          <component :is="`abk-event-${section.sectionType}-section`"
                     :required="isRequiredSection(section)"
                     @close="remove(index)"
                     :errors="errors[index] || {}"
                     v-model="value[index]">
          </component>
          <div class="separator"></div>
        </template>
      </b-col>
    </draggable>

    <div class="row">
      <div class="col">
        <abk-add-button @click.native="add('text')" icon-class="pcg-icon-add-content pcg-2x">
          {{ $t('components.events.abk_event_description_form.add_text_section') }}
        </abk-add-button>
      </div>
      <div class="col">
        <abk-add-button @click.native="add('image')" icon-class="pcg-icon-add-image pcg-2x">
          {{ $t('components.events.abk_event_description_form.add_image') }}
        </abk-add-button>
      </div>
    </div>
  </b-col>
</template>

<script>
import AbkEventTextSection from './AbkEventTextSection'
import AbkAddButton from '../AbkAddButton'
import draggable from 'vuedraggable'
import AbkEventImageSection from './AbkEventImageSection'
import AbkSectionHeader from '../AbkSectionHeader'

export default {
  name: 'abk-event-description-form',
  components: { AbkSectionHeader, AbkEventImageSection, AbkAddButton, AbkEventTextSection, draggable },
  props: {
    value: {
      type: Array
    },
    errors: Array
  },
  computed: {
    maxKey () {
      return Math.max(...this.value.map((section) => section.key ? section.key : 0), 0)
    },
    minTextSectionKey () {
      const textSections = this.value.filter(section => section.sectionType === 'text')
      const textSectionKeys = textSections.map((section) => section.key ? section.key : 0)
      return Math.min(...textSectionKeys)
    }
  },
  methods: {
    isRequiredSection ({ key, sectionType }) {
      return key === this.minTextSectionKey && sectionType === 'text'
    },
    update (newArray) {
      this.$emit('input', newArray)
    },
    onEnd (event) {
      const copy = [...this.value]
      copy.forEach(section => {
        if (section.sectionType === 'text') section.key = this.generateKey()
      })
      this.update(copy)
    },
    remove (index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          const copy = [...this.value]
          copy[index].id ? copy[index]._destroy = true : copy.splice(index, 1)
          this.update(copy)
        }
      })
    },
    generateKey () {
      return this.maxKey + 1
    },
    add (type) {
      const emptySection = this.value.filter(entry => !entry._destroy && entry.sectionType === type).some(entry => {
        if (type === 'text') {
          return !(entry.content || entry.title)
        } else {
          return !(entry.image || entry.imageUrl)
        }
      })
      if (emptySection) {
        this.$toastr.e(this.$t(`components.abk_event_description_form.empty_section_error.${type}`))
        return
      }

      if (type === 'text') {
        this.update([...this.value, {
          key: this.generateKey(),
          sectionType: 'text',
          title: '',
          content: ''
        }])
      } else if (type === 'image') {
        this.update([...this.value, {
          key: this.generateKey(),
          sectionType: 'image'
        }])
      }
    },
    sortSections () {
      this.update(_.sortBy(this.value, 'key'))
    }
  },
  mounted () {
    this.sortSections()
  },
  activated () {
    this.onEnd()
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .buttons {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
  }

  /deep/ .header-button {
    color: $main-active-color;
    font-size: $font-size-m;
    font-weight: 500;
    margin-left: 30px;
    i {
      padding-right: 5px;
    }
    &:hover {
      cursor: pointer;
      color: $hover-color;
    }
  }
  .sections-container {
    margin-bottom: $pcg-m-lg;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    /deep/ .header-button {
      color: $main-active-color;
      &:hover {
        color: $hover-color;
      }
    }
  }
</style>
