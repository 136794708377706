<template>
  <abk-skills-list skills-type="all"/>
</template>

<script>
import AbkSkillsList from '../../../components/skills/AbkSkillsList'

export default {
  name: 'AllSkillsTab',
  components: { AbkSkillsList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.skills.list_tabs.all_skills_tab.title'),
        subtitle: this.$t('views.skills.list_tabs.all_skills_tab.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
