<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <span class="filters-text">{{ $t('components.events.abk_events_list.filter_events') }}</span>
        <div class="d-flex flex-wrap">
          <pcg-search-select v-if="showCareerOfficeActions"
                             :placeholder="$t('general.company')"
                             always-active
                             allow-empty
                             :options="companies"
                             class="filter-select"
                             v-model="filters.company"
                             @input="filterEvents"/>
          <pcg-select v-if="eventsType === 'all'"
                      :options="statusOptions"
                      :default-option="{ text: $t('general.status'), value: null }"
                      class="filter-select"
                      v-model="filters.status"
                      @input="filterEvents"/>
          <pcg-select :options="typeOptions"
                      :default-option="{ text: this.$t('general.type'), value: null }"
                      class="filter-select"
                      v-model="filters.event_type"
                      @input="filterEvents"/>
          <pcg-select v-if="eventsType === 'registered'"
                      :options="listOptions"
                      :default-option="{ text: this.$t('general.list'), value: null }"
                      class="filter-select"
                      v-model="filters.list_type"
                      @input="filterEvents"/>
        </div>
        <pcg-btn v-if="showEmployerActions || showCareerOfficeActions"
                 class="ml-auto"
                 size="small"
                 @click="goToNewEventView">
          + {{ $t('general.add_event') }}
        </pcg-btn>
      </b-col>
    </b-row>
    <b-row class="mb-3" :class="env && env.newEventsView && !(showEmployerActions || showCareerOfficeActions) ? 'custom-grid' : ''">
      <template v-if="env && env.newEventsView && !(showEmployerActions || showCareerOfficeActions)">
        <template v-for="(event, index) in events.records">
          <abk-events-list-box v-if="event.subEvents.length > 0" :event="event" :eventsType="eventsType"
                               :showCareerOfficeActions="showCareerOfficeActions" :showEmployerActions="showEmployerActions" :key="index"
                               @getEvents="getEvents"
          ></abk-events-list-box>
        </template>
      </template>
      <template v-else>
        <pcg-box class="col-12">
          <abk-list-with-sub :items="events.records"
                             :show-loader="showLoader"
                             :items-column-name="$t('general.event')"
                             :no-records-text="$t('components.events.abk_events_list.no_events')"
                             :hide-status="showStudentActions">
            <template v-if="eventsType === 'assigned'" v-slot:header>
              <div class="matching-competences-container">
                {{ $t('components.events.abk_events_list.matching_competences') }}
              </div>
            </template>
            <template v-slot:title="record">
            <span v-if="record.place" class="event-place-container">
              <i class="pcg-icon-location-alt"/>
              <span>{{ record.place }}</span>
            </span>
            </template>
            <template v-slot="record">
              <div class="d-flex align-items-center ml-auto">
                <pcg-btn-symbol v-if="showCareerOfficeActions && isAccepted(record.status.value)"
                                class="my-0 ml-auto"
                                icon-class="pcg-icon-power-off"
                                :tooltip="$t('general.cancel_event')"
                                @click="performEventAction(record.id, 'cancelEvent')"/>
                <pcg-btn-symbol v-if="showAcceptRejectActions(record.status.value)"
                                class="my-0 ml-auto"
                                variant="success"
                                icon-class="pcg-icon-check-alt"
                                :tooltip="$t('general.accept')"
                                @click="performEventAction(record.id, 'acceptEvent')"/>
                <pcg-btn-symbol v-if="showAcceptRejectActions(record.status.value)"
                                variant="danger"
                                icon-class="pcg-icon-times"
                                class="my-0 ml-auto"
                                :tooltip="$t('general.reject')"
                                @click="performEventAction(record.id, 'rejectEvent')"/>
                <span v-if="showEmployerActions"
                      class="download"
                      :class="{ 'not-allowed': !isAccepted(record.status.value) }"
                      :title="$t('general.cancel_event')"
                      v-b-tooltip.hover
                      @click="performEventAction(record.id, 'cancelEvent')">
                <i class="pcg-icon-download"/>
              </span>
                <span v-if="showEmployerActions || showCareerOfficeActions"
                      class="check-file"
                      :title="$t('general.copy')"
                      v-b-tooltip.hover
                      @click="$router.push({ name: 'events_new_path', query: { copy_id: record.id } })">
                <i class="pcg-icon-duplicate"/>
              </span>
                <div v-if="eventsType === 'assigned'" class="matching-competences-container ml-3">
                  <div class="pcg-record-count matching-competences-count"
                       :id="`event-${record.id}-matching-competences`"
                       v-b-tooltip>
                    {{ record.matchingCompetencesCount }}
                  </div>
                  <b-tooltip v-if="record.matchingCompetencesNames" :target="`event-${record.id}-matching-competences`">
                    <div v-for="name in record.matchingCompetencesNames" :key="`${record.id}-${name}`">{{ name }}</div>
                  </b-tooltip>
                </div>
              </div>
            </template>
            <template v-slot:sub-event="sub">
              <template v-if="showStudentActions">
              <span v-if="sub.status.value === 'finished'" class="free-places-text not-available">
                  {{ $t('components.events.abk_events_list.already_taken_place') }}
                </span>
                <span v-else-if="sub.status.value === 'canceled'" class="free-places-text not-available">
                  {{ $t('components.events.abk_events_list.event_canceled') }}
                </span>
                <span v-else-if="sub.userRegistered" class="free-places-text registered">
                  <i class="pcg-icon-check mr-2"/>{{ $t('components.events.abk_events_list.take_part') }}
                </span>
                <span v-else-if="sub.userRegisteredReservation" class="free-places-text registered">
                  <i class="pcg-icon-check mr-2"/>{{ $t('components.events.abk_events_list.take_part_reserve') }}
                </span>
                <span v-else-if="sub.currentlyFreePlaces > 0" class="free-places-text available">
                  {{ $tc('components.events.abk_events_list.free_places_left', sub.currentlyFreePlaces) }}
                </span>
                <p v-else-if="sub.currentlyReservePlaces > 0" class="free-places-text available">
                  {{ $tc('components.events.abk_events_list.reserve_places_left', sub.currentlyReservePlaces) }}
                </p>
                <p v-else-if="sub.currentlyFreePlaces === 0 && sub.currentlyReservePlaces === 0" class="free-places-text not-available">
                  {{ $t('components.events.abk_events_list.no_free_places') }}
                </p>
                <span v-else class="free-places-text available">
                  {{ $t('components.events.abk_events_list.unlimited_free_places') }}
                </span>
              </template>
              <pcg-btn-symbol v-if="showCareerOfficeActions && isAccepted(sub.status.value)"
                              class="my-0 ml-auto"
                              icon-class="pcg-icon-power-off"
                              :tooltip="$t('general.cancel_sub_event')"
                              @click="performSubEventAction(sub.id, 'cancelEvent')"/>
              <pcg-btn-symbol v-if="(showCareerOfficeActions || showEmployerActions) && isNotDraft(sub.status.value)"
                              class="my-0 ml-auto"
                              icon-class="fa fa-users"
                              :tooltip="$t('components.events.abk_events_list.attendance_list')"
                              @click="$router.push({name: 'attendance_list_path',params: {event: sub.id}})"/>
              <pcg-btn-symbol v-if="(showCareerOfficeActions || showEmployerActions) && isNotDraft(sub.status.value)"
                              class="my-0 ml-auto"
                              icon-class="fa fa-user"
                              :tooltip="$t('components.events.abk_events_list.reserve_list')"
                              @click="$router.push({name: 'reserve_list_path',params: {event: sub.id}})"/>
              <pcg-btn-symbol v-if="(showCareerOfficeActions || showEmployerActions) && isNotDraft(sub.status.value)"
                              class="my-0 ml-auto"
                              icon-class="fa fa-user-slash"
                              :tooltip="$t('components.events.abk_events_list.sign_out_list')"
                              @click="$router.push({name: 'sign_out_list_path',params: {event: sub.id}})"/>
              <span v-if="showEmployerActions"
                    class="download"
                    :class="{ 'not-allowed': !isAccepted(sub.status.value) }"
                    :title="$t('general.cancel_sub_event')"
                    v-b-tooltip.hover
                    @click="performSubEventAction(sub.id, 'cancelEvent')">
                <i class="pcg-icon-download"/>
              </span>
            </template>
          </abk-list-with-sub>
        </pcg-box>
      </template>
    </b-row>
    <pcg-pagination v-model="pagination.currentPage"
                    :page-count="events.meta.pages"
                    :total-records="events.meta.totalRecords"
                    @input="getPaginationEvents"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiEvents from '../../api/events'
import ApiSubEvents from '../../api/sub_events'
import ApiCompanies from '../../api/companies'
import { decamelize } from 'humps'
import { mapGetters } from 'vuex'
import LoaderDelayMixin from '../../mixins/loader_delay'
import AbkListWithSub from '../AbkListWithSub'
import AbkEventsListBox from './AbkEventsListBox'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'

export default {
  name: 'AbkEventsList',
  components: { AbkListWithSub, AbkEventsListBox },
  mixins: [LoaderDelayMixin, PaginableResourceMixin],
  props: {
    eventsType: {
      type: String,
      default: 'all',
      validate (eventType) {
        return ['all', 'current', 'archived', 'assigned', 'pending', 'similar'].includes(eventType)
      }
    }
  },
  data () {
    return {
      companies: [],
      events: {
        records: [],
        meta: {
          pages: 0,
          totalRecords: 0
        }
      },
      filters: {
        page: 1,
        event_type: null,
        status: null,
        order_direction: 'desc',
        order_by: 'created_at',
        company: null,
        list_type: null
      },
      types: ['fair', 'training', 'rally', 'counseling', 'other'],
      statusOptions: [],
      typeOptions: [],
      listOptions: [
        { text: this.$t('general.list_type.basic'), value: 'basic' },
        { text: this.$t('general.list_type.reserve'), value: 'reserve' },
        { text: this.$t('components.events.abk_events_list.all'), value: '' }
      ],
      employers: [],
      getEventsActionPerType: {
        all: 'getEvents',
        current: 'getCurrentEvents',
        archived: 'getFinishedEvents',
        registered: 'getMyRegisteredEvents',
        assigned: 'getAssignedToMeEvents',
        pending: 'getPendingEvents',
        similar: 'getSimilarEvents'
      },
      getEventsAction: null,
      markPerStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question',
        canceled: 'question'
      },
      eventId: null,
      studentId: null,
      studentName: null
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['acceptedCompany']),
    ...mapGetters('environment', ['env']),
    showStudentActions () {
      return (['student', 'graduate'].includes(this.currentRole) || (this.studentId != null && true))
    },
    showEmployerActions () {
      return (this.currentRole === 'employer' || this.currentRole === 'recruiter') && (this.studentId === null || this.studentId === undefined) && this.acceptedCompany
    },
    showCareerOfficeActions () {
      return (this.currentRole === 'career_office' || this.currentRole === 'counselor') && (this.studentId === null || this.studentId === undefined)
    },
    queryParams () {
      return {
        eventId: this.eventId,
        studentId: this.studentId,
        filters: this.filters
      }
    },
    availableStatuses () {
      return this.showStudentActions
        ? ['accepted', 'canceled', 'finished']
        : ['canceled', 'draft', 'recent', 'for_acceptance', 'accepted', 'rejected', 'finished']
    }
  },
  mounted () {
    this.eventId = this.$route.params.eventId
    this.studentId = this.$route.params.student_id
    if (this.$route.query.page) {
      this.filters.page = this.$route.query.page
    }
    this.getEvents()
    this.getCompanies()
    this.initializeOptions()
  },
  methods: {
    isAccepted (status) {
      return status === 'accepted'
    },
    isNotDraft (status) {
      return status !== 'draft'
    },
    goToNewEventView () {
      this.$router.push({ name: 'events_new_path' })
    },
    getPaginationEvents () {
      this.filters.page = this.pagination.currentPage
      this.getEvents()
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    },
    getEvents () {
      if (this.currentRole === 'career_office' && this.studentId) {
        ApiEvents.getStudentRegisteredEvents(this.queryParams, this.studentId)
          .then(response => {
            this.setPagination(response)
            this.setEvents(response.data)
            if (response.data.meta.user) {
              this.studentId = response.data.meta.user.id
              this.studentName = response.data.meta.user.name
              this.setHeader()
            }
          })
          .catch(error => {
            console.error(error)
            this.$toastr.e(this.$t('components.events.abk_events_list.events_download_error'))
          })
          .finally(() => {
            this.loadingDone = true
          })
      } else {
        ApiEvents[this.getEventsActionPerType[this.eventsType]](this.queryParams)
          .then(response => {
            this.setPagination(response)
            this.setEvents(response.data)
            if (response.data.meta.user) {
              this.studentId = response.data.meta.user.id
              this.studentName = response.data.meta.user.name
              this.setHeader()
            }
          })
          .catch(error => {
            console.error(error)
            this.$toastr.e(this.$t('components.events.abk_events_list.events_download_error'))
          })
          .finally(() => {
            this.loadingDone = true
          })
      }
    },
    setEvents ({ data, meta: { eventTitle, pagination: { count, pages } } }) {
      this.events = {
        records: data.map(({ id, attributes }) => {
          const eventTypeTranslationPath = `general.event_type.${attributes.eventType}`
          const startDate = this.formatDate(attributes.startDate, 'dddd DD.MM.YYYY HH:mm')
          const endDate = this.formatDate(attributes.endDate, 'dddd DD.MM.YYYY HH:mm')
          let duration

          if (startDate && endDate) {
            duration = _.uniq([startDate, endDate]).join(' - ')
          } else if (startDate) {
            duration = `${this.$t('general.from')} ${startDate}`
          } else if (endDate) {
            duration = `${this.$t('general.to')} ${endDate}`
          }

          return {
            ...attributes,
            id,
            highlighted: attributes.status === 'for_acceptance',
            faded: ['rejected', 'finished', 'canceled'].includes(attributes.status),
            path: { name: 'events_show_path', params: { id: id } },
            name: attributes.eventTitle,
            status: {
              mark: this.markPerStatus[attributes.status],
              text: this.$t(`general.events.statuses.${attributes.status}`),
              value: attributes.status
            },
            subs: attributes.subEvents.map((sub) => {
              const subAttributes = sub.data.attributes
              const startDate = this.formatDate(subAttributes.startDate, 'dddd DD.MM.YYYY HH:mm')
              const endDate = this.formatDate(subAttributes.endDate, 'dddd DD.MM.YYYY HH:mm')
              let duration

              if (startDate && endDate) {
                duration = _.uniq([startDate, endDate]).join(' - ')
              } else if (startDate) {
                duration = `${this.$t('general.from')} ${startDate}`
              } else if (endDate) {
                duration = `${this.$t('general.to')} ${endDate}`
              }

              return {
                ...subAttributes,
                id: sub.data.id,
                faded: ['rejected', 'finished', 'canceled'].includes(subAttributes.status),
                name: attributes.eventTitle,
                status: {
                  mark: this.markPerStatus[subAttributes.status],
                  text: this.$t(`general.events.statuses.${subAttributes.status}`),
                  value: subAttributes.status
                },
                path: { name: 'sub_events_show_path', params: { id: sub.data.id } },
                attributes: _.compact([
                  duration
                ])
              }
            }),
            attributes: _.compact([
              this.$te(eventTypeTranslationPath) ? this.$t(eventTypeTranslationPath) : null,
              !this.showEmployerActions && attributes.company ? attributes.company.name : null,
              attributes.instructor ? attributes.instructor.fullName : null
            ])
          }
        }),
        meta: {
          totalRecords: count,
          pages: pages
        }
      }

      if (this.eventsType === 'similar') {
        this.$store.dispatch('header/setObjectName', eventTitle)
      }
    },
    getCompanies () {
      if (!this.showCareerOfficeActions) { return }

      ApiCompanies.getCompanies()
        .then(response => {
          this.employers = response.data.data.map(({ attributes }) => {
            return { value: attributes.userId, text: attributes.name }
          })
          this.companies = response.data.data.map(({ id, attributes }) => {
            return { value: id, text: attributes.name }
          })
        })
        .catch(error => {
          // TODO Obsługa błędów
          console.log(error)
        })
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeStatusOptions()
        this.initializeTypeOptions()
      }, 0)
    },
    initializeStatusOptions () {
      this.statusOptions = this.availableStatuses.map(status => {
        return { text: this.$t(`general.events.statuses.${status}`), value: status }
      })
      this.statusOptions.push({ text: this.$t('components.events.abk_events_list.all'), value: '' })
    },
    initializeTypeOptions () {
      this.typeOptions = this.types.map(type => {
        return { text: this.$t(`general.event_type.${type}`), value: type }
      })
      this.typeOptions.push({ text: this.$t('components.events.abk_events_list.all'), value: '' })
    },
    changeOrderDirection (orderDirection) {
      this.filters.order_direction = orderDirection
      this.filterEvents()
    },
    filterEvents () {
      this.resetPage()
      this.getEvents()
    },
    resetPage () {
      this.filters.page = 1
    },
    performEventAction (eventId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiEvents[action](eventId)
            .then(() => {
              this.getEvents()
            }).catch(() => {
              this.$toastr.e(this.$t('components.events.abk_events_list.unexpected_error'))
            })
        }
      })
    },
    performSubEventAction (eventId, action) {
      let confirmButton = ''
      if (action === 'cancelEvent') {
        confirmButton = this.$t('general.cancel_sub_event')
      } else {
        confirmButton = this.$t(`general.${decamelize(action)}`)
      }
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiSubEvents[action](eventId)
            .then(() => {
              this.getEvents()
            }).catch(() => {
              this.$toastr.e(this.$t('components.events.abk_events_list.unexpected_error'))
            })
        }
      })
    },
    showAcceptRejectActions (status) {
      return status === 'for_acceptance' && this.showCareerOfficeActions
    },
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    },
    setHeader () {
      this.$nextTick(() => {
        let title = this.$store.getters['header/title']
        const subTitle = this.$store.getters['header/subtitle']
        title = `${this.studentName}: ${title}`
        this.$store.dispatch('header/setHeader', {
          title: title,
          subtitle: subTitle
        })
      })
    }

  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .custom-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    @media screen and (min-width: 750px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .event-place-container {
    margin-left: $pcg-m-md;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 500;
    color: $pcg-dark-gray;
    i {
      color: $pcg-light-gray;
      margin-right: $pcg-m-xs;
    }
  }
  .free-places-text {
    font-size: $font-size-s;
    font-weight: 500;
    position: relative;
    bottom: .4rem;
    &.available {
      color: $main-color;
    }
    &.registered {
      color: $pcg-green-color;
    }
    &.not-available {
      color: $pcg-gray;
    }
  }
  .matching-competences-count {
    margin-left: 0;
    margin-right: auto;
  }
  .not-allowed {
    cursor: not-allowed;
  }
  .matching-competences-container {
    margin-left: auto;
    width: 100px;
    text-align: left;
  }
  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }

  .download {
    cursor: pointer;
    color: $pcg-dark-gray;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
    &.not-allowed {
      opacity: .5;
      cursor: not-allowed;
    }
  }
  .check-file {
    color: $pcg-dark-gray;
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
    cursor: pointer;
  }

  @media(max-width: $screen-sm-max) {
    .matching-competences-container {
      min-width: 70px;
      width: 70px;
    }
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .event-place-container {
      color: $pcg-dark-gray;
      i {
        color: $pcg-light-gray;
      }
    }
    .free-places-text {
      &.available {
        color: $main-color;
      }
      &.registered {
        color: $pcg-green-color;
      }
      &.not-available {
        color: $pcg-gray;
      }
    }
    .filters-text {
      color: $pcg-gray;
    }
    .download {
      color: $pcg-dark-gray;
    }
    .check-file {
      color: $pcg-dark-gray;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
