<template>
  <div class="question-container" :class="[this.mainVisible ? 'bcg-active' : '', `lv-${level}`]">
    <div class="question-header abk-border">
      <span class="icon-container">
        <component :is="`abk-survey-${value.questionType}-svg`"></component>
      </span>
      <button class="btn question-title no-borders"
              :class="mainVisible ? null : 'collapsed'"
              :aria-expanded="mainVisible ? 'true' : 'false'"
              :aria-controls="mainCollapseId"
              :disabled="!otherThanSeparator"
              @click="mainVisible = !mainVisible">
      <span class="question-text">
        {{ value.title }}
      </span>
      <abk-caret :open="mainVisible" v-if="otherThanSeparator"/>
      </button>
      <span class="header-actions ml-auto">
        <button type="button" class="btn" aria-label="Copy" @click="copy">
          <img src="../../../assets/images/surveys/form/copy_icon.svg"/>
        </button>
        <button type="button" class="close" aria-label="Close" @click="remove">
          <span aria-hidden="true">✕</span>
        </button>
      </span>
    </div>
    <b-collapse v-model="mainVisible" @input="$emit('expand', $event)" :id="mainCollapseId" v-if="otherThanSeparator">
      <div class="abk-border-first">
        <div class="collapse-container">
          <b-row>
            <pcg-select v-if="isRadioOrCheckbox"
                        class="col-xl-3 col-lg-4 col-12 form-group"
                        :options="radioOrCheckboxDisplayTypeOptions"
                        :value="value.displayType"
                        @input="updateForm('displayType', $event)"
                        :error-text="getError('displayType')"
                        :show-error="errorExist('displayType')"
                        :label="$t('components.surveys.abk_survey_question_form.labels.display_type')"/>
            <pcg-text-input class="col"
                            required
                            autocomplete="off"
                            :label="questionTitleLabel"
                            :error-text="getError('title')"
                            :show-error="errorExist('title')"
                            :value="value.title"
                            @input="updateForm('title', $event)"/>
            <template v-if="otherThanSection">
              <pcg-text-input class="col-12"
                              :label="$t('components.surveys.abk_survey_question_form.labels.description')"
                              autocomplete="off"
                              :error-text="getError('description')"
                              :show-error="errorExist('description')"
                              :value="value.description"
                              @input="updateForm('description', $event)"/>
              <pcg-checkbox class="col-12 form-group"
                            variant="normal"
                            :show-error="errorExist('mandatory')"
                            :error-text="getError('mandatory')"
                            :value="value.mandatory"
                            @input="updateMandatory($event)">
                {{ $t('components.surveys.abk_survey_question_form.labels.mandatory') }}
              </pcg-checkbox>
              <b-col v-if="isRadioOrCheckbox" class="col-12">
                <abk-survey-question-fields-form :errors="errors"
                                                 :value="value.questionFields"
                                                 :question-type="value.questionType"
                                                 :parent-error-path="errorPath"
                                                 @questionFieldRemoved="questionFieldRemoved"
                                                 @open="mainVisible = true; $emit('open')"
                                                 @input="updateForm('questionFields', $event)"/>
              </b-col>
            </template>

          </b-row>
        </div>
      </div>

      <div class="abk-border-first" v-if="requirmentsSectionShow">
        <div class="question-header abk-second-lv">
          <button class="btn question-title no-borders"
                  :class="requirmentsVisible ? null : 'collapsed'"
                  :aria-expanded="requirmentsVisible ? 'true' : 'false'"
                  :aria-controls="requirmentsCollapseId"
                  @click="visibleRequirementsLength ? requirmentsVisible = !requirmentsVisible : null">
            {{ $t('components.surveys.abk_survey_question_form.conditions_text') }}
            <abk-caret :open="requirmentsVisible" v-show="visibleRequirementsLength"/>
          </button>
          <button class="btn question-header-btn" @click="addRequirement">
            {{ $t('components.surveys.abk_survey_question_form.add_requirement_btn') }}
          </button>
        </div>
        <b-collapse v-model="requirmentsVisible" :id="requirmentsCollapseId" class="collapse-container requirements-container">
          <draggable :value="value.requirements"
                     @input="updateForm('requirements', $event)"
                     animation="200">
            <transition-group name="slide" mode="out-in">
              <template v-for="(requirement, index) in value.requirements">
                <abk-survey-question-requirement-form :value="requirement"
                                                      :question="value"
                                                      :level="level"
                                                      :key="requirement.tmpId"
                                                      :errors="errors"
                                                      :requires-answer="requiresAnswer"
                                                      v-if="!value.requirements[index]._destroy"
                                                      @input="updateRequirement($event, index)"
                                                      @editRequirement="editRequirement(requirement, index)"
                                                      @open="mainVisible = requirmentsVisible = true; $emit('open')"
                                                      @remove="removeRequirementWithConfirmation(requirement, index)"/>
              </template>
            </transition-group>
          </draggable>
        </b-collapse>
      </div>
    </b-collapse>
    <abk-requirement-modal v-model="requirementModalShow"
                           :question="value"
                           :requirement="currentRequirementModal"
                           :errors="errors"
                           @requirement="requirementConfirmed"/>
  </div>
</template>

<script>
  import AbkCaret from "../../AbkCaret";
  import AbkSurveyQuestionFieldsForm from "./AbkSurveyQuestionFieldsForm";
  import AbkRequirementModal from "./AbkRequirementModal";
  import draggable from 'vuedraggable'
  import AbkSurveyCheckboxSvg from "../icons/AbkSurveyCheckboxSvg";
  import AbkSurveySectionSvg from "../icons/AbkSurveySectionSvg";
  import AbkSurveyNumberSvg from "../icons/AbkSurveyNumberSvg";
  import AbkSurveyRadiobuttonSvg from "../icons/AbkSurveyRadiobuttonSvg";
  import AbkSurveySeparatorSvg from "../icons/AbkSurveySeparatorSvg";
  import AbkSurveyTextSvg from "../icons/AbkSurveyTextSvg";

  export default {
    name: "AbkSurveyQuestionForm",
    components: {
      AbkSurveyQuestionRequirementForm: () => import("./AbkSurveyQuestionRequirementForm"),
      AbkRequirementModal,
      AbkSurveyQuestionFieldsForm,
      AbkCaret,
      draggable,
      AbkSurveyTextSvg,
      AbkSurveySeparatorSvg,
      AbkSurveyRadiobuttonSvg,
      AbkSurveyNumberSvg,
      AbkSurveySectionSvg,
      AbkSurveyCheckboxSvg
    },
    props: {
      value: Object,
      errors: Object,
      level: Number,
      requiresAnswer: Boolean,
      newQuestion: Boolean
    },
    data: function () {
      return {
        questionTypes: ['text', 'number', 'checkbox', 'radiobutton', 'separator', 'section'],
        mainVisible: false,
        mainCollapseId: `main-collapse-${this._uid.toString()}`,
        questionAnswersCollapseId: `question-answers-collapse-${this._uid.toString()}`,
        requirmentsVisible: false,
        requirmentsCollapseId: `requirments-collapse-${this._uid.toString()}`,
        checkboxDisplayTypeOptions: [
          {
            text: this.$t('components.surveys.abk_survey_question_form.checkbox_display_type_options.vertical'),
            value: 'vertical'
          },
          {
            text: this.$t('components.surveys.abk_survey_question_form.checkbox_display_type_options.horizontal'),
            value: 'horizontal'
          }
        ],
        radioDisplayTypeOptions: [
          {
            text: this.$t('components.surveys.abk_survey_question_form.radio_display_type_options.vertical'),
            value: 'vertical'
          },
          {
            text: this.$t('components.surveys.abk_survey_question_form.radio_display_type_options.horizontal'),
            value: 'horizontal'
          }
        ],
        requirementModalShow: false,
        currentRequirementModal: null,
        currentRequirementIndexModal: null
      }
    },
    computed: {
      visibleRequirementsLength () {
        return this.value.requirements.filter(requirement => !requirement._destroy).length
      },
      radioOrCheckboxDisplayTypeOptions () {
        return this.value.questionType === 'radiobutton' ? this.radioDisplayTypeOptions : this.checkboxDisplayTypeOptions
      },
      isRadioOrCheckbox () {
        return this.value.questionType === 'radiobutton' || this.value.questionType === 'checkbox'
      },
      otherThanSeparator () {
        return this.value.questionType !== 'separator'
      },
      otherThanSection () {
        return this.value.questionType !== 'section'
      },
      questionTitleLabel () {
        return this.otherThanSection ?
          this.$t('components.surveys.abk_survey_question_form.labels.title') :
          this.$t('components.surveys.abk_survey_question_form.labels.section')
      },
      requirmentsSectionShow () {
        return this.isRadioOrCheckbox && this.level < 2
      },
      errorPath () {
        return `questions.${ this.value.id || this.value.tmpId }`
      }
    },
    watch: {
      value (newValue, oldValue) {
        if (!oldValue.edited) {
          this.updateForm('edited', true)
        }
      }
    },
    created () {
      this.mainVisible = this.otherThanSeparator && this.newQuestion
    },
    methods: {
      updateMandatory (mandatory) {
        this.updateForm('mandatory', mandatory)
        if (!mandatory) {
          this.$emit('dontRequiresAnswer')
        }
      },
      updateRequirement (requirement, index) {
        const requirementsCopy = [...this.value.requirements]
        requirementsCopy.splice(index, 1, requirement)
        this.updateForm('requirements', requirementsCopy)
      },
      updateForm (key, value) {
        this.$emit('input', { ...this.value, [key]: value })
      },
      errorExist (field) {
        return !!(this.errors && this.errors[`${this.errorPath}.${ field }`])
      },
      getError (field) {
        if (this.errorExist(field)) {
          this.$emit('open')
          return this.errors[`${ this.errorPath }.${ field }`].join(', ')
        }
        return ''
      },
      async copy () {
        const questionCopy =  _.cloneDeep(this.value)
        await this.deepRegenerateTmpIds(questionCopy)
        this.$emit('copy', questionCopy)
      },
      async deepRegenerateTmpIds (question) {
        question.tmpId = await this.$store.dispatch('tmpIds/generateTmpId')
        for (let index = 0; index < question.questionFields.length; index++) {
          question.questionFields[index].tmpId = await this.$store.dispatch('tmpIds/generateTmpId')
        }
        for (let index = 0; index < question.requirements.length; index++) {
          question.requirements[index].tmpId = await this.$store.dispatch('tmpIds/generateTmpId')
          for (let nestedIndex = 0; nestedIndex < question.requirements[index].questions.length; nestedIndex++) {
            this.deepRegenerateTmpIds(question.requirements[index].questions[nestedIndex])
          }
        }
      },
      remove () {
        this.$emit('remove')
      },
      addRequirement () {
        if (this.value.questionFields.filter(questionField => {
          return !questionField._destroy && (questionField.fieldType === 'checkbox' || questionField.fieldType === 'radiobutton')
        }).length) {
          this.currentRequirementIndexModal = null
          this.currentRequirementModal = null
          this.requirementModalShow = true
        } else {
          this.$toastr.e(this.$t('components.surveys.abk_survey_question_form.missing_question_fields_text'))
        }
      },
      editRequirement (requirement, index) {
        this.currentRequirementIndexModal = index
        this.currentRequirementModal = requirement
        this.requirementModalShow = true
      },
      requirementConfirmed (requirement) {
        if (this.currentRequirementModal === null) {
          const newRequirement = {
            ...requirement,
            questions: [],
            requirementsIds: [],
            isNew: true,
            edited: false
          }
          this.$store.dispatch('tmpIds/generateTmpId').then(tmpId => {
            newRequirement.tmpId = tmpId
            this.updateForm('requirements', [...this.value.requirements, newRequirement])
            this.requirmentsVisible = true
          })
        } else {
          const requirementsCopy = [...this.value.requirements]
          requirementsCopy.splice(this.currentRequirementIndexModal, 1, { ...requirementsCopy[this.currentRequirementIndexModal], ...requirement })
          this.updateForm('requirements', requirementsCopy)
        }
      },
      removeRequirementWithConfirmation (requirement, index) {
        if (requirement.edited || !requirement.isNew) {
          this.$swal({
            title: this.$t('general.are_you_sure'),
            showCancelButton: true,
            confirmButtonText: this.$t('general.delete'),
            cancelButtonText: this.$t('general.cancel')
          }).then(result => {
            if (result.value) {
              this.removeRequirement(requirement, index)
            }
          })
        } else {
          this.removeRequirement(requirement, index)
        }
      },
      removeRequirement (requirement, index) {
        if (requirement.requirementsIds.length) {
          const requirementsCopy = [...this.value.requirements]
          requirementsCopy.splice(index, 1, { ...requirement, _destroy: true })
          this.updateForm('requirements', requirementsCopy)
        } else {
          const requirementsCopy = [...this.value.requirements]
          requirementsCopy.splice(index, 1)
          this.updateForm('requirements', requirementsCopy)
        }
        this.$nextTick(() => {
          if (this.visibleRequirementsLength === 0) {
            this.requirmentsVisible = false
          }
        })
      },
      questionFieldRemoved (questionFieldId) {
        this.$nextTick(() => {
          const requirementsCopy = [...this.value.requirements]
          requirementsCopy.forEach(requirement => {
            if (requirement.questionFieldIds.includes(questionFieldId)) {
              requirement.questionFieldIds = requirement.questionFieldIds.filter(x => x !== questionFieldId)
            }
          })
          this.updateForm('requirements', requirementsCopy)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  $separator-color-0: #e3e3e5;
  $separator-color-1: #d6d6d6;
  $separator-color-2: #c6c6c6;

  $bcg-lv-0: #efefef;
  $bcg-lv-1: #dfdfdf;
  $bcg-lv-2: #cccccc;

  .question-container {
    background: transparent;
    transition: background-color 0.3s ease;
    .no-borders {
      border: none !important;
    }
    &.lv-0 {
      &.bcg-active {
        background: $bcg-lv-0;
      }
      .abk-border, .abk-border-first {
        &:after {
          background: $separator-color-0;
        }
      }
      @media screen and (max-width: $screen-md-max) {
        .question-header {
          &.abk-second-lv {
            padding-left: 20px;
          }
        }
        .collapse-container {
          padding-left: 31px;
        }
      }
    }
    &.lv-1 {
      &.bcg-active {
        background: $bcg-lv-1;
      }
      .abk-border, .abk-border-first {
        &:after {
          background: $separator-color-1;
        }
      }
      @media screen and (max-width: $screen-md-max) {
        .question-header {
          &.abk-second-lv {
            padding-left: 20px;
          }
        }
        .collapse-container {
          padding-left: 31px;
        }
      }
    }
    &.lv-2 {
      &.bcg-active {
        background: $bcg-lv-2;
      }
      .abk-border, .abk-border-first {
        &:after {
          background: $separator-color-2;
        }
      }
      @media screen and (max-width: $screen-xlg-min) {
        .question-header {
          &.abk-second-lv {
            padding-left: 20px;
          }
        }
        .collapse-container {
          padding-left: 31px;
        }
      }
    }
    .abk-border, .abk-border-first {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 3px;
        right: 3px;
        height: 1px;
        background: $separator-color-0;
      }
    }
    .abk-border-first:last-child {
      &:after {
        bottom: 0px;
      }
    }
    .question-header {
      padding: 13px 20px;
      position: relative;
      display: flex;
      align-items: center;
      &.abk-second-lv {
        padding-left: 70px;
      }
      .icon-container {
        cursor: move;
        width: 30px;
        min-width: 30px;
        display: flex;
        justify-content: center;
        margin-right: 10px;
      }
      .question-title {
        display: flex;
        align-items: center;
        color: $main-color;
        overflow: hidden;
        &:disabled {
          opacity: 1;
        }
        i {
          padding-left: 15px;
        }
        .question-text {
          width: fit-content;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
        }
      }
      .header-actions {
        display: flex;
        .close {
          font-size: $pcg-m-md;
          float: none;
          padding: 0.3rem 0.7rem;
        }
      }
      .question-header-btn {
        color: $main-active-color;
      }
    }
    .collapse-container {
      padding: 20px 31px 20px 81px;
    }
    /deep/ .requirement:not(:last-child) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 860px) {
      &, &.lv-0, &.lv-1, &.lv-2 {
        .question-header {
          padding: 13px 5px;
          &.abk-second-lv {
            padding-left: 10px;
          }
        }
        .requirements-container {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  $separator-color-0: #e3e3e5;
  $separator-color-1: #d6d6d6;
  $separator-color-2: #c6c6c6;

  $bcg-lv-0: #151515;
  $bcg-lv-1: #272727;
  $bcg-lv-2: #404040;

  .contrast{
    .question-container {
      &.lv-0 {
        &.bcg-active {
          background: $bcg-lv-0;
        }
        .abk-border, .abk-border-first {
          &:after {
            background: $separator-color-0;
          }
        }
      }
      &.lv-1 {
        &.bcg-active {
          background: $bcg-lv-1;
        }
        .abk-border, .abk-border-first {
          &:after {
            background: $separator-color-1;
          }
        }
      }
      &.lv-2 {
        &.bcg-active {
          background: $bcg-lv-2;
        }
        .abk-border, .abk-border-first {
          &:after {
            background: $separator-color-2;
          }
        }
      }
      .abk-border, .abk-border-first {
        &:after {
          background: $separator-color-0;
        }
      }
      .question-header {
        background: $pcg-white;
        .question-title {
          color: $main-color;
        }
        .question-header-btn {
          color: $main-active-color;
        }
      }
    }
  }
</style>
