<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'children-test-2-view',
  data: function () {
    return {}
  }
}
</script>

<style scoped>

</style>
