import Users from '../../api/users'
import DictionaryWords from '../../api/dictionary_words'

const user = {
  namespaced: true,
  state: {
    newOfferFilterResults: false,
    favoriteOffersCount: 0,
    offerApplicationsCount: 0,
    recentDictionaryWordsCount: 0,
    acceptedCompany: false,
    company: null,
    cvConsent: false,
    companyConsent: false
  },
  getters: {
    newOfferFilterResults: state => state.newOfferFilterResults,
    favoriteOffersCount: state => state.favoriteOffersCount,
    offerApplicationsCount: state => state.offerApplicationsCount,
    recentDictionaryWordsCount: state => state.recentDictionaryWordsCount,
    acceptedCompany: state => state.acceptedCompany,
    company: state => state.company,
    cvConsent: state => state.cvConsent,
    companyConsent: state => state.companyConsent
  },
  mutations: {
    setNewOfferFilterResults (state, result) {
      state.newOfferFilterResults = result
    },
    setFavoriteOffersCount (state, count) {
      state.favoriteOffersCount = count
    },
    setOfferApplicationsCount (state, count) {
      state.offerApplicationsCount = count
    },
    setRecentDictionaryWordsCount (state, count) {
      state.recentDictionaryWordsCount = count
    },
    setAcceptedCompany (state, result) {
      state.acceptedCompany = result
    },
    setCompany (state, result) {
      state.company = result
    },
    setCvConsent (state, result) {
      state.cvConsent = result
    },
    setCompanyConsent (state, result) {
      state.companyConsent = result
    }
  },
  actions: {
    clearCatalogOfferCounters ({ commit }) {
      commit('setNewOfferFilterResults', false)
      commit('setFavoriteOffersCount', 0)
      commit('setOfferApplicationsCount', 0)
    },
    clearDictionaryWordsCounters ({ commit }) {
      commit('setRecentDictionaryWordsCount', 0)
    },
    clearAcceptedCompany ({ commit }) {
      commit('setAcceptedCompany', false)
    },
    clearCompany ({ commit }) {
      commit('setCompany', null)
    },
    clearCvConsent ({ commit }) {
      commit('setCvConsent', false)
    },
    clearCompanyConsent ({ commit }) {
      commit('setCompanyConsent', false)
    },
    getCatalogOfferCounters ({ commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const userRoles = rootGetters['auth/roles']
        if (userRoles && userRoles.length > 0 && ['student', 'graduate'].includes(rootGetters['auth/currentRole'])) {
          Users.getOffersCatalogCounters()
            .then(response => {
              commit('setNewOfferFilterResults', response.data.data.attributes.newResults)
              commit('setFavoriteOffersCount', response.data.data.attributes.favoriteOffersCount)
              commit('setOfferApplicationsCount', response.data.data.attributes.offerApplicationsCount)
              resolve(response)
            })
            .catch(error => {
              dispatch('clearCatalogOfferCounters')
              reject(error)
            })
        } else {
          dispatch('clearCatalogOfferCounters')
          resolve(null)
        }
      })
    },
    getDictionaryWordsCounters ({ commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        if (rootGetters['auth/currentRole'] === 'career_office') {
          DictionaryWords.getWordsCounter('recent')
            .then(response => {
              commit('setRecentDictionaryWordsCount', response.data.count)
              resolve(response)
            })
            .catch(error => {
              dispatch('clearDictionaryWordsCounters')
              reject(error)
            })
        } else {
          dispatch('clearDictionaryWordsCounters')
          resolve(null)
        }
      })
    },
    getAcceptedCompany ({ commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        if (rootGetters['auth/currentRole'] === 'employer' || rootGetters['auth/currentRole'] === 'recruiter') {
          Users.getMyCompanyStatus()
            .then(response => {
              commit('setAcceptedCompany', response.data.accepted)
              resolve(response)
            })
            .catch(error => {
              dispatch('clearAcceptedCompany')
              reject(error)
            })
        } else {
          dispatch('clearAcceptedCompany')
          resolve(null)
        }
      })
    },
    getCompany ({ commit, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        if (rootGetters['auth/currentRole'] === 'employer' || rootGetters['auth/currentRole'] === 'recruiter') {
          Users.getMyCompany()
            .then(response => {
              const company = {
                id: response.data.data.id,
                name: response.data.data.attributes.name
              }
              commit('setCompany', company)
              resolve(response)
            })
            .catch(error => {
              dispatch('clearCompany')
              reject(error)
            })
        } else {
          dispatch('clearCompany')
          resolve(null)
        }
      })
    },
    getConsents ({ commit, dispatch, rootGetters }) {
      const userRoles = rootGetters['auth/roles']
      if (userRoles && userRoles.length > 0 && ['student', 'graduate', 'employer', 'recruiter'].includes(rootGetters['auth/currentRole'])) {
        return new Promise((resolve, reject) => {
          Users.getConsents()
            .then(response => {
              commit('setCvConsent', response.data.cvConsent)
              commit('setCompanyConsent', response.data.companyConsent)
              resolve(response)
            })
            .catch(error => {
              dispatch('clearCvConsent')
              dispatch('clearCompanyConsent')
              reject(error)
            })
        })
      }
    }
  }
}

export {
  user
}
