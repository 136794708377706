import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'sub_events'

export default {
  getEvents (params) {
    const filters = params.filters
    if (params.studentId) {
      filters.studentId = params.studentId
    }
    return api.get(`${resource}?${stringify(decamelize(filters))}`)
  },
  getEvent (eventId) {
    return api.get(`${resource}/${eventId}`)
  },
  getSubEvent (eventId) {
    return api.get(`${resource}/${eventId}/sub_event_show`)
  },
  getEventsInfo () {
    return api.get(`${resource}/info`)
  },
  cancelEvent (eventId) {
    return api.patch(`${resource}/${eventId}/cancel`)
  },
  getSimilarEvents ({ eventId, filters }) {
    return api.get(`${resource}/${eventId}/similar_events?${stringify(decamelize(filters))}`)
  },
  acceptEvent (eventId) {
    return api.patch(`${resource}/${eventId}/accept`)
  },
  rejectEvent (eventId) {
    return api.patch(`${resource}/${eventId}/reject`)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  getUpcomingEvents () {
    return api.get(`${resource}/upcoming`)
  },
  getCurrentEvents ({ filters }) {
    return api.get(`${resource}/current?${stringify(decamelize(filters))}`)
  },
  getFinishedEvents ({ filters }) {
    return api.get(`${resource}/finished?${stringify(decamelize(filters))}`)
  },
  getMyRegisteredEvents ({ filters }) {
    return api.get(`${resource}/my_registered_events?${stringify(decamelize(filters))}`)
  },
  getAssignedToMeEvents ({ filters }) {
    return api.get(`${resource}/assigned_to_me?${stringify(decamelize(filters))}`)
  },
  getPendingEvents ({ filters }) {
    return api.get(`${resource}/pending?${stringify(decamelize(filters))}`)
  },
  visitedEvent (eventId) {
    return api.patch(`${resource}/${eventId}/visited_event`)
  }
}
