<template>
  <abk-loader v-if="showLoader"/>
  <abk-survey-step1-form v-else disable-inputs :value="survey" :recipient-groups.sync="recipientGroups"/>
</template>

<script>
import AbkSurveyStep1Form from '../../components/surveys/steps/AbkSurveyStep1Form'
import ApiSurveys from '../../api/surveys'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'SurveyParams',
  components: { AbkLoader, AbkSurveyStep1Form },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      recipientGroups: [],
      survey: {}
    }
  },
  mounted () {
    this.survey.id = this.$route.params.id
    this.getSurvey()
  },
  methods: {
    getSurvey () {
      ApiSurveys.getSurveyDetails(this.survey.id)
        .then(response => {
          this.setSurvey(response.data.data)
          this.setHeader()
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.surveys.abk_survey_form.survey_download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setSurvey (data) {
      this.survey = { id: data.id, ...data.attributes }
      this.$store.dispatch('header/setObjectName', this.survey.name)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.survey.name
      })
    }
  }
}
</script>

<style scoped>

</style>
