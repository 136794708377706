<template>
  <abk-offers-list offers-type="matching_to_skill"/>
</template>

<script>
import AbkOffersList from '../../components/offers/AbkOffersList'

export default {
  name: 'OffersMatchingToSkillList',
  components: { AbkOffersList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_matching_to_skill_list.title'),
        subtitle: this.$t('views.offers.offers_matching_to_skill_list.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
