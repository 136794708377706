<template>
  <abk-message-form :submitAction="createMessage"/>
</template>

<script>
import AbkMessageForm from '../../components/messages/AbkMessageForm'
import ApiMessages from '../../api/messages'

export default {
  name: 'MessagesNew',
  components: { AbkMessageForm },
  methods: {
    createMessage (message) {
      return ApiMessages.createMessage(message)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.messages.messages_new.title'),
        subtitle: this.$t('views.messages.messages_new.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
