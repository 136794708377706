import api from './index'

const resource = 'companies'

export default {
  getCompanies (params = {}) {
    return api.get(`${resource}`, { params: params })
  },
  getCompaniesList (params = {}) {
    return api.get(`${resource}/company_list`, { params: params })
  },
  getCompany (companyId) {
    return api.get(`${resource}/${companyId}`)
  },
  getMyCompany () {
    return api.get(`${resource}/my_company`)
  },
  getMyCompanyEdit () {
    return api.get(`${resource}/my_company_edit`)
  },
  getCompaniesNames () {
    return api.get(`${resource}/names`)
  },
  getRecentCompanies (count=4) {
    return api.get(`${resource}/recent`, { params: { count: count } })
  },
  updateMyCompany (payload) {
    return api.patch(`${resource}/update_my_company`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  destroyDraft () {
    return api.delete(`${resource}/destroy_draft`)
  },
  acceptCompany (companyId) {
    return api.patch(`${resource}/${companyId}/accept`)
  },
  rejectCompany (companyId) {
    return api.patch(`${resource}/${companyId}/reject`)
  },
  getActualRegistration (companyId) {
    const url = companyId ? `${resource}/${companyId}/actual_registration` : `${resource}/my_actual_registration`
    return api.get(url)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  getMyCompanyStatus () {
    return api.get(`${resource}/my_company_status`)
  },
  updateEmployerType (company) {
    const body = { employerType: company.employerType }
    return api.patch(`${resource}/${company.id}/update_employer_type`, body)
  }
}
