<template>
  <b-row>
    <abk-records-list :records="survey.answers.records"
                      hide-status-icon
                      :no-records-text="$t('views.surveys.answers.answers_index.no_records')"
                      :elements-column-title="$t('general.answer')"
                      :number-column-title="$t('general.number')"
                      :order-direction="filters.order_direction"
                      @changeOrderDirection="changeOrderDirection"
                      show-record-path="answers_show_path"/>
    <pcg-pagination v-model="filters.page"
                    :page-count="survey.answers.meta.pages"
                    :total-records="survey.answers.meta.totalRecords"
                    @input="getAnswers"
                    class="col-12 justify-content-center my-pagination"/>
  </b-row>
</template>

<script>
import ApiAnswers from '../../../api/answers'
import AbkRecordsList from '../../../components/AbkRecordsList'

export default {
  name: 'AnswersIndex',
  components: { AbkRecordsList },
  data () {
    return {
      survey: {
        id: null,
        name: null,
        answers: {
          records: [],
          meta: {
            pages: 0,
            totalRecords: 0
          }
        }
      },
      filters: {
        page: 1,
        order_direction: 'desc',
        order_by: 'created_at'
      }
    }
  },
  methods: {
    getAnswers () {
      if (!this.survey.id) { return }

      ApiAnswers.getAnswers(this.survey.id, this.filters)
        .then(response => {
          this.setAnswers(response.data)
          this.setHeader()
        })
        .catch(error => {
          console.error(error)
        })
    },
    setAnswers ({ meta: { survey, pagination }, data }) {
      this.survey.name = survey.name
      this.survey.answers.meta = { pages: pagination.pages, totalRecords: pagination.count }
      this.survey.answers.records = data.map(({ id, attributes }) => {
        return {
          name: attributes.userName || `${this.$t('general.answer')} ${id}`,
          id
        }
      })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.survey.name,
        subtitle: this.$t('views.surveys.answers.answers_index.subtitle')
      })
    },
    changeOrderDirection (orderDirection) {
      this.filters.order_direction = orderDirection
      this.filterAnswers()
    },
    filterAnswers () {
      this.resetPage()
      this.getAnswers()
    },
    resetPage () {
      this.filters.page = 1
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.survey.id = this.$route.params.id
    this.getAnswers()
  }
}
</script>

<style scoped>

</style>

<style lang="scss" scoped>
@import "../../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
