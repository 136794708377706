import api from './index'

const resourceParent = 'dictionaries'
const resource = 'dictionary_words'

export default {
  getDictionaryWords (dictionaryId, status, page, wordPattern) {
    if (_.isNil(status)) {
      return api.get(`${resourceParent}/${dictionaryId}/${resource}`, {
        params: { page: page, value: wordPattern }
      })
    } else {
      return api.get(`${resourceParent}/${dictionaryId}/${resource}`, {
        params: { status: status, page: page, value: wordPattern }
      })
    }
  },
  getDictionaryWordsRecent (dictionaryId, params={}) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/recent`, { params: params })
  },
  getDictionaryWordsByKey (dictionaryKey) {
    return api.get(`${resourceParent}/${dictionaryKey}/words`)
  },
  getDictionaryWord (dictionaryId, wordId) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`)
  },
  updateDictionaryWord (dictionaryId, wordId, payload) {
    return api.patch(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createDictionaryWord (dictionaryId, payload) {
    return api.post(`${resourceParent}/${dictionaryId}/${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  suggestDictionaryWord (dictionaryId, payload) {
    return api.post(`${resourceParent}/${dictionaryId}/${resource}/suggest`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getWordsCounter (status) {
    return api.get(`${resourceParent}/${resource}/words_counter`, { params: { status: status } })
  },
  getDictionaryWordsCounter (dictionaryId, status) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/words_counter`, { params: { status: status } })
  },
  getNotifications () {
    return api.get(`${resourceParent}/${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resourceParent}/${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resourceParent}/${resource}/read_all_notifications`)
  }
}
