<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="fas fa-user-friends"></i>{{ $t('components.abk_paired_offers_box.title') }}</span>
      <router-link v-if="totalOffers > offers.length" class="pcg-link pcg-small" :to="{ name: 'root_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <ul v-if="offers && offers.length" class="p-0 m-0">
      <abk-offer-item v-for="offer in offers" :key="offer.id" :offer="offer" no-border>
        <div class="d-flex w-25 ml-auto paired-user-container">
          <i class="fas fa-caret-right align-self-end"></i>
          <router-link :to="{ name: 'root_path' }">
            <pcg-avatar size="extra-small" :img="offer.paired_user_img"/>
          </router-link>
        </div>
      </abk-offer-item>
    </ul>
    <span v-else class="pcg-no-records">{{ $t('components.abk_paired_offers_box.no_records') }}</span>
  </pcg-box>
</template>

<script>
import AbkOfferItem from './AbkOfferItem'
export default {
  name: 'AbkPairedOffersBox',
  props: {
    offers: Array,
    totalOffers: Number
  },
  components: { AbkOfferItem }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .fa-caret-right {
    color: $pcg-dark-gray;
    margin-bottom: .4em;
    margin-right: 50%;
    margin-left: auto;
  }

  @media (max-width: $screen-xs-max) {
    .fa-caret-right {
      margin-right: 10%;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .fa-caret-right {
      color: $pcg-dark-gray;
    }
  }
</style>
