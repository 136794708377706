<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <span class="filters-text">{{ $t('components.offers.abk_offers_list.filter_offers') }}</span>
        <div class="d-flex flex-wrap">
          <pcg-search-select v-if="showCareerOfficeActions"
                             :placeholder="$t('general.company')"
                             always-active
                             allow-empty
                             :options="employers"
                             class="filter-select"
                             v-model="filters.company"
                             @input="filterOffers"/>
          <pcg-select v-if="offersType === 'all'"
                      :options="statusOptions"
                      :default-option="{ text: this.$t('general.status'), value: null }"
                      class="filter-select"
                      v-model="filters.status"
                      @input="filterOffers"/>
          <pcg-select :options="typeOptions"
                      :default-option="{ text: this.$t('general.type'), value: null }"
                      class="filter-select"
                      v-model="filters.offer_type"
                      @input="filterOffers"/>
        </div>
        <pcg-btn v-if="offersType === 'all' && (showCareerOfficeActions || acceptedCompany)" class="ml-auto" size="small" @click="goToNewOfferView">
          + {{ $t('general.add_offer') }}
        </pcg-btn>
      </b-col>
    </b-row>
    <b-row>
      <pcg-box class="col-12">
        <abk-list :items="offers.records"
                  :show-loader="showLoader"
                  :items-column-name="$t('general.offer')"
                  :no-records-text="$t('components.abk_new_offers_box.no_records')"
                  :hide-status="offersType === 'similar'">
          <template v-slot:header>
            <div v-if="showEmployerActions" class="header-columns-names-container">
              <span class="column-header expiration-date-column-header">
                {{ $t('components.offers.abk_offers_list.columns_names.expiration_date') }}
              </span>
              <span class="column-header matching-profiles-column-header">
                {{ $t('components.offers.abk_offers_list.columns_names.matching_profiles') }}
              </span>
              <span class="column-header applications-column-header">
                {{ $t('components.offers.abk_offers_list.columns_names.applications') }}
              </span>
            </div>
          </template>
          <template v-slot="item">
            <span class="rejection-reason"
                  v-if="item.rejectionReason"
                  v-b-tooltip.hover
                  :title="item.rejectionReason">
              <i class="far fa-comment-dots"></i>
            </span>
            <div class="d-flex ml-auto align-items-center">
              <template v-if="showEmployerActions">
                <span v-if="formatDate(item.expirationDate, 'DD.MM.YYYY')" class="expiration-date">
                  {{ formatDate(item.expirationDate, 'DD.MM.YYYY') }}
                </span>
                <router-link :to="offerMatchingProfilesPath(item.id)"
                             class="pcg-record-count matching-profiles-count"
                             :class="{ hidden: !item.matchingProfilesCount || (env && env.competenceFeature === 'off') }">
                  <div>{{ item.matchingProfilesCount }}</div>
                </router-link>
                <router-link :to="{ name: 'offers_employer_show_path', hash: '#applications', params: { id: item.id } }"
                             class="pcg-record-count alternative applications-count"
                             :class="{ hidden: !item.offerApplicationsCount }">
                  <div>{{ item.offerApplicationsCount }}</div>
                </router-link>
                <span class="download"
                      :class="{ 'not-allowed': !isAccepted(item.status.value) }"
                      v-b-tooltip.hover
                      @click="finishOffer(item)"
                      :title="$t('general.cancel')">
                <i class="pcg-icon-download"></i>
              </span>
              </template>
              <template v-else-if="showCareerOfficeActions">
                <pcg-btn-symbol v-if="isAccepted(item.status.value)"
                                class="my-0 ml-auto"
                                icon-class="pcg-icon-power-off"
                                :tooltip="$t('general.cancel')"
                                @click="performOfferAction(item.id, 'finishOffer')"/>
                <div v-if="waitingForAcceptance(item.status.value)">
                  <pcg-btn-symbol class="my-0 ml-auto"
                                  variant="success"
                                  icon-class="pcg-icon-check-alt"
                                  :tooltip="$t('general.accept')"
                                  @click="performOfferAction(item.id, 'acceptOffer')"/>
                  <pcg-btn-symbol variant="danger"
                                  icon-class="pcg-icon-times"
                                  class="my-0 ml-auto"
                                  :tooltip="$t('general.reject')"
                                  @click="rejectOffer(item.id)"/>
                </div>
              </template>
              <router-link v-if="showEmployerActions || showCareerOfficeActions"
                           :to="{ name: 'offers_new_path', query: { copy_id: item.id } }"
                           class="check-file"
                           :title="$t('general.copy')"
                           v-b-tooltip.hover>
                <i class="pcg-icon-duplicate"></i>
              </router-link>
            </div>
          </template>
        </abk-list>
      </pcg-box>
      <pcg-pagination v-model="filters.page"
                      :page-count="offers.meta.pages"
                      :total-records="offers.meta.totalRecords"
                      @input="getOffers"
                      class="col-12 justify-content-center my-pagination"/>
    </b-row>
    <abk-reject-offer-modal v-model="rejectOfferModalShow" :offer-id="toRejectOfferId" @reload="getOffers"/>
  </div>
</template>

<script>
import ApiOffers from '../../api/offers'
import ApiCompanies from '../../api/companies'
import LoaderDelayMixin from '../../mixins/loader_delay'
import AbkList from '../AbkList'
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import AbkRejectOfferModal from './AbkRejectOfferModal'

export default {
  name: 'AbkOffersList',
  components: { AbkRejectOfferModal, AbkList },
  mixins: [LoaderDelayMixin],
  props: {
    offersType: {
      type: String,
      default: 'all',
      validate (eventType) {
        return ['all', 'matching_to_skill', 'not_matching_to_skill', 'similar'].includes(eventType)
      }
    }
  },
  data () {
    return {
      offers: {
        records: [],
        meta: {
          pages: 0,
          totalRecords: 0
        }
      },
      filters: {
        page: 1,
        offer_type: null,
        status: null,
        order_direction: 'desc',
        order_by: 'created_at',
        company: null
      },
      statuses: ['draft', 'recent', 'for_acceptance', 'accepted', 'rejected', 'finished'],
      types: ['job', 'practice', 'training'],
      statusOptions: [],
      typeOptions: [],
      employers: [],
      getOffersActionPerType: {
        all: 'getAllOffers',
        matching_to_skill: 'getMatchingToSkill',
        not_matching_to_skill: 'getNotMatchingToSkill',
        similar: 'getSimilarOffers'
      },
      skillId: null,
      competenceId: null,
      offerId: null,
      rejectOfferModalShow: false,
      toRejectOfferId: null,
      markPerStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question'
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['acceptedCompany']),
    ...mapGetters('environment', ['env']),
    isAnyOffer () {
      return this.offers && this.offers.length
    },
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    },
    queryParams () {
      return {
        offerId: this.offerId,
        filters: this.filters,
        skillId: this.skillId,
        competenceId: this.competenceId
      }
    }
  },
  mounted () {
    this.offerId = this.$route.params.offerId
    this.skillId = this.$route.params.skillId
    this.competenceId = this.$route.params.competenceId
    this.getOffers()
    this.getCompanies()
    this.initializeOptions()
  },
  methods: {
    finishOffer ({ id, status }) {
      if (this.isAccepted(status.value)) {
        this.performOfferAction(id, 'finishOffer')
      }
    },
    offerMatchingProfilesPath (offerId) {
      return {
        name: 'offers_employer_show_path',
        hash: '#matching_profiles',
        params: { id: offerId }
      }
    },
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    },
    goToNewOfferView () {
      this.$router.push({ name: 'offers_new_path' })
    },
    getOffers () {
      ApiOffers[this.getOffersActionPerType[this.offersType]](this.queryParams)
        .then(response => {
          this.setOffers(response.data)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.offers.abk_offers_list.offers_download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setOffers ({ data, meta: { offer, pagination: { count, pages } } }) {
      this.offers = {
        records: data.map(({ attributes, id }) => {
          const offerTypeTranslationPath = `general.${attributes.offerType}`

          return {
            id,
            ...attributes,
            highlighted: attributes.status === 'for_acceptance',
            faded: ['rejected', 'finished'].includes(attributes.status),
            path: { name: 'offers_employer_show_path', params: { id: id } },
            status: {
              mark: this.markPerStatus[attributes.status],
              text: this.$t(`general.offers.statuses.${attributes.status}`),
              value: attributes.status
            },
            name: attributes.jobTitle,
            attributes: _.compact([
              this.$te(offerTypeTranslationPath) ? this.$t(offerTypeTranslationPath) : null,
              this.formatDate(attributes.publicationDate, 'DD.MM.YYYY'),
              attributes.company && attributes.company.name
            ])
          }
        }),
        meta: {
          totalRecords: count,
          pages: pages
        }
      }
      if (this.offersType === 'similar') {
        this.$store.dispatch('header/setObjectName', offer.jobTitle)
      }
    },
    getCompanies () {
      if (!this.showCareerOfficeActions) { return }

      ApiCompanies.getCompanies()
        .then(response => {
          this.employer = []
          response.data.data.forEach(company => {
            this.employers.push({ value: company.id, text: company.attributes.name })
          })
        })
        .catch(error => {
          // TODO Obsługa błędów
          console.log(error)
        })
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeStatusOptions()
        this.initializeTypeOptions()
      }, 0)
    },
    initializeStatusOptions () {
      this.statusOptions = this.statuses.map(status => {
        return { text: this.$t(`general.offers.statuses.${status}`), value: status }
      })
      this.statusOptions.push({ text: this.$t('components.offers.abk_offers_list.all'), value: '' })
    },
    initializeTypeOptions () {
      this.typeOptions = this.types.map(type => {
        return { text: this.$t(`general.${type}`), value: type }
      })
      this.typeOptions.push({ text: this.$t('components.offers.abk_offers_list.all'), value: '' })
    },
    changeOrderDirection (orderDirection) {
      this.filters.order_direction = orderDirection
      this.filterOffers()
    },
    filterOffers () {
      this.resetPage()
      this.getOffers()
    },
    resetPage () {
      this.filters.page = 1
    },
    rejectOffer (offerId) {
      this.toRejectOfferId = offerId
      this.rejectOfferModalShow = true
    },
    performOfferAction (offerId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiOffers[action](offerId)
            .then(() => {
              this.getOffers()
            }).catch(() => {
              this.$toastr.e(this.$t('components.abk_list_of_offers.unexpected_error'))
            })
        }
      })
    },
    isAccepted (status) {
      return status === 'accepted'
    },
    waitingForAcceptance (status) {
      return status === 'for_acceptance'
    },
    changeSortDirection ({ orderDirection }) {
      this.$emit('changeOrderDirection', orderDirection)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  .expiration-date {
    color: $pcg-gray;
    font-weight: 500;
    font-size: $font-size-s;
  }
  .download {
    cursor: pointer;
    color: $pcg-dark-gray;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
  }
  .check-file {
    color: $pcg-dark-gray;
    margin-right: 1rem;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
  }
  .rejection-reason {
    cursor: pointer;
    color: $alert-error;
    margin-left: 2rem;
    margin-top: .4rem;
    font-size: $font-size-m;
  }
  .empty-space {
    margin-left: 4.8rem;
  }
  .one-row {
    flex-wrap: wrap;
  }
  .not-allowed {
    opacity: .5;
    cursor: not-allowed;
  }
  .header-columns-names-container {
    margin-left: auto;
    margin-right: 81px;
    text-align: right;
  }
  .column-header {
    display: inline-block;
    text-align: left;
  }
  .expiration-date-column-header {
    width: 87px;
  }
  .matching-profiles-column-header {
    width: 64px;
  }
  .applications-column-header {
    width: 60px;
  }
  .hidden {
    visibility: hidden;
  }

  @media(max-width: $screen-lg-max) {
   .expiration-date,
   .pcg-record-count,
   .pcg-record-count,
   .expiration-date-column-header,
   .matching-profiles-column-header,
   .applications-column-header {
      display: none;
    }
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .filters-text {
    color: $pcg-gray;
  }
  .expiration-date {
    color: $pcg-gray;
  }
  .download {
    color: $pcg-dark-gray;
  }
  .check-file {
    color: $pcg-dark-gray;
  }
  .rejection-reason {
    color: $alert-error;
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
