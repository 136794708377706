<template>
  <div class="abk-simple-messages-form">
    <pcg-text-input v-model="value.title"
                    show-label
                    required
                    :label="$t('general.title')"
                    :show-error="errorExist('title')"
                    :error-text="getError('title')"/>
    <label class="pcg-input-label pcg-input-label-required">
      {{ $t('general.content') }}
    </label>
    <tinymce-editor :class="{ invalid: showContentErrors }"
                    :init="tinymceSettings"
                    :value="value.content"
                    @input="update('content', $event)"/>
    <input class="pcg-field d-none" :required="showContentErrors"/>
    <div v-if="errorExist('content')" class="pcg-error-messages">{{ getError('content') }}</div>
    <div v-if="errorExist('simpleMessage')" class="pcg-error-messages">{{ getError('simpleMessage') }}</div>
  </div>
</template>

<script>
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
  name: 'AbkSimpleMessageForm',
  components: { TinymceEditor },
  props: {
    value: Object,
    errors: Object
  },
  data () {
    return {
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 200,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  watch: {
    'errors.content': function (newError) {
      if (newError) {
        this.invalidContentValue = this.value.content
      }
    }
  },
  methods: {
    getError (field) {
      return this.errorExist(field) ? this.errors[field].join(', ') : null
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    }
  },
  computed: {
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.content)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.value.content
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";
  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
</style>
