export const components = {
  abk_actual_recrutations_box: {
    cv_match: "Your CV fits this offer perfectly!",
    days_left: "Less than 1 day left | 1 day left | {count} days left | {count} days left",
    header: "Current offers",
    no_offers: "No offers"
  },
  abk_add_to_favorites_button: {
    add: "Add to favorites",
    remove: "Remove from favorites"
  },
  abk_additional_sections_form: {
    add_subsection: "add subsection",
    background: "Background image",
    content: "Contents",
    empty_section_error: {
      gallery: "You cannot add a new gallery section because one empty gallery section already exists.",
      text: "You cannot add a new text section because one empty text already exists.",
      video: 'You cannot add a new video section because one empty video section already exists.'
    },
    section_name: "Name",
    select_section_type: "Choose section type",
    subsection_name: "Subsection name",
    subtitle: "Text section",
    title: "Additional Sections"
  },
  abk_attachment_input: {
    text: "Add attachment"
  },
  abk_attachments_box: {
    title: "App attachments"
  },
  abk_basic_info_form: {
    address: "Address",
    brief_description: "A brief description",
    city: "City",
    company_name: "Company name",
    email: "e-mail",
    facebook: "Facebook",
    info: "Fill out as much information as possible about your company.",
    linkedin: "Linkedin",
    nip: 'NIP',
    phone: "Phone",
    progress: "Percentage of profile completion",
    title: "Basic information",
    twitter: "Twitter",
    website: "Web page"
  },
  abk_competences_box: {
    title: "Competences"
  },
  abk_contact: {
    header: "Contact"
  },
  abk_content_preview: {
    attachments: "Attachments",
    header: "Content preview",
    hide: "Collapse",
    show: "Expand"
  },
  abk_current_offers: {
    number_of_offers: "Current job offers: {count}"
  },
  abk_cv_hobby_box: {
    title: "Interests, hobbies, additional activities"
  },
  abk_datetime_picker: {
    tooltips: {
      clear: "Clear",
      close: "Close",
      decrementHour: "Decrease hours",
      decrementMinute: "Decrease minutes",
      decrementSecond: "Decrease seconds",
      incrementHour: "Increase hours",
      incrementMinute: "Increase minutes",
      incrementSecond: "Increase seconds",
      nextCentury: "Next century",
      nextDecade: "Next decade",
      nextMonth: "Next month",
      nextYear: "Next year",
      pickHour: "Choose an hour",
      pickMinute: "Choose a minute",
      pickSecond: "Choose a second",
      prevCentury: "Previous age",
      prevDecade: "Previous decade",
      prevMonth: "Previous month",
      prevYear: "Previous year",
      selectDecade: "Choose a decade",
      selectMonth: "Choose a month",
      selectYear: "Choose a year",
      today: "Go to today"
    }
  },
  abk_employers_box: {
    no_records: "No employees",
    offers: "no offers | 1 offer | {count} offers | {count} offers",
    title: "meet employers"
  },
  abk_file_input: {
    error_title: "An error occured",
    loading_title: "Loading file",
    success_title: "File loaded successfully",
    upload_title: "Drag and drop or select a file"
  },
  abk_gallery_section_form: {
    add_image: "add picture",
    subtitle: "Photos",
    title: "Gallery",
    title_placeholder: "Name"
  },
  abk_get_to_know_us: {
    atmosphere: "atmosphere",
    benefits: "benefits",
    career: "career",
    title: "Meet us",
    what_we_do: "what we do?",
    who_we_seek: "who are we looking for?"
  },
  abk_header_navbar: {
    application_login: 'Employer/Graduate',
    login_btn: "Sign in",
    register_btn: "Register"
  },
  abk_image_input: {
    add_file: "Add file"
  },
  abk_invitation_box: {
    applied: "You have already applied to this offer",
    outdated_offer: "The offer is out of date"
  },
  abk_list_of_employers: {
    recruit: "Invite to recruitment"
  },
  abk_list_of_offers: {
    applications: "Applications",
    matching_profiles: "Matching profiles",
    number: "Number",
    offer: "Offer",
    unexpected_error: "An unexpected error occurred while performing the action"
  },
  abk_managing_events_box: {
    events_count: "Total number of events",
    no_records: "No events",
    registrations: "no subscriptions | 1 subscription | {count} subscription | {count} subscription",
    sort_btn: "start date",
    title: "Event management"
  },
  abk_managing_offers: {
    applications: "no applications | 1 application | {count} application | {count} application",
    no_records: "No offers",
    offers_count: "Total number of offers",
    sort_btn: "date of publication",
    title: "Offer management",
    type: {
      internship: "Training",
      work: "Job"
    }
  },
  abk_message: {
    attachment_download_error: "An error occurred while downloading the attachment.",
    show_thread: "Show thread"
  },
  abk_message_form: {
    collapse: "hide",
    error_download_list_of_recipients: "An unexpected error occurred while fetching the recipient list.",
    error_download_messages: "An unexpected error occurred while retrieving the message.",
    error_download_recipients: "An unexpected error occurred while retrieving group recipients.",
    error_university_structure: "An unexpected error occurred while downloading the university structure.",
    fields: {
      content: "Contents",
      message_type: "Message type",
      recipient_group_id: "recievers group",
      recipient_type: "Type of recipients",
      send_to: "To",
      title: "Title"
    },
    recipients_count: "Number of recipients:",
    show_more: "...show more",
    working_copy_saved: "A draft of the message has been saved."
  },
  abk_message_secondary: {
    attachment_download_error: "An error occurred while downloading the attachment.",
    delete_message_error: "An unexpected error occurred while deleting the message.",
    message_deleted: "Message has been deleted.",
    sent_to_users: "Sent to 0 users | Sent to 1 user | Sent to {count} users"
  },
  abk_messages_box: {
    create_newsletter: "Create newsletter",
    no_records: "No messages",
    title: "Messages",
    write_message: "Write a message"
  },
  abk_multiselect_mpaing: {
    selected: "Selected"
  },
  abk_navigation_menu: {
    accepted_companies: "Accepted",
    active: "Active",
    admin_dashboard: "Admin dashboard",
    archived_events: "Archive",
    assigned_events: "Assigned to me",
    attendance_list: 'Attendance list',
    base_blocks: "Base blocks",
    catalog_of_offers: "Offers catalog",
    cms: "CMS",
    communiques: "Announcements",
    competences: "Competences",
    competences_connected_with_skills: "Related to the programs",
    competences_list: "All competences",
    competences_not_connected_with_skills: "Not associated to programs",
    completed: "Filled",
    components: "Components",
    contact: "Contact",
    conversations: "Conversations",
    current_events: "Current",
    cv: "CV",
    dashboard: "Dashboard",
    dictionaries: "Dictionaries",
    dictionaries_of_consents: "Dictionaries of consents",
    draft_messages: "Draft messages",
    edit: "Edit",
    email_confirmation: 'Email confirmation',
    employer_dashboard: "Employer dashboard",
    employer_list: "Employers",
    events: "Events",
    events_list: "Event list",
    events_list_student: 'All',
    favorites: "Favorites",
    firm: "Company",
    for_acceptance_companies: "Waiting",
    group_messages: "Group messages",
    inactive: "Inactive",
    invitations: "Invitations",
    list_of_news: 'News',
    list_of_offers: "List of offers",
    list_of_pages: "List of pages",
    log_in: "Sign in",
    login_user: 'Sign in',
    messages: "Messages",
    messages_sent: "Sent messages",
    my_cv: "My CV",
    my_offers: "My offers",
    my_submissions: "My applications",
    new_event: "New event",
    new_message: "New message",
    new_news: 'Add news',
    new_offer: "New offer",
    new_page: "New page",
    new_password: 'New password',
    new_survey: "New survey",
    news: 'News',
    news_index: 'News list',
    observed_results: "Followed results",
    pending_events: "Waiting",
    reset_password: 'Password reset',
    profile: "Profile",
    recent: "New",
    recipient_groups: "Recipient groups",
    register_user: 'Register',
    registered_events: "My subscriptions",
    rejected_companies: "Rejected",
    removed_users_list: "Removed users",
    reserve_list: 'Reserve list',
    search: "Search",
    sign_out_list: 'Sign out list',
    skills: "Programs",
    skills_connected_with_competences: "Associated with competences",
    skills_list: "All programs",
    skills_not_connected_with_competences: "Not associated wit competences",
    static_pages: "Static pages",
    student: 'Student/graduate',
    student_list: 'Students/graduates',
    student_notes: 'Notes',
    student_notes_edit: 'Edit note',
    student_notes_new: 'New note',
    surveys: "Surveys",
    surveys_list: "Survey List",
    thread_messages: "Message list",
    users_competences: "Users",
    users_list: 'Users'
  },
  abk_new_employers_box: {
    no_records: "No employers",
    title: "New employers"
  },
  abk_new_news_form: {
    access_roles: {
      career_office: "Career Office",
      employer: "Employer",
      graduate: "Graduate",
      public: "Guests",
      recruiter: 'Recruiter',
      student: "Student"
    },
    address: "Url address",
    content: "Content",
    keywords: "Keywords",
    metaDescription: "Meta description",
    navigation: "Navigation",
    news_title: "News title",
    permissions: "Who can view the news"
  },
  abk_new_offer_step_1_form: {
    add: "+ add",
    company: "Employer",
    expiration_date: "Expiration date",
    free_places: "Number of places",
    job_title: "Job title",
    next: "Next",
    offer_type: "Offer type",
    position_type: "Job type",
    publication_date: 'Start date',
    salary_text: "You can add information on the range of expected salary.",
    title: "Complete the basic information or",
    trade: "Industry",
    type_of_work: "Type of job",
    use_template: "create from another offer",
    work_dimension: "Working time",
    workplace: "Workplace"
  },
  abk_new_offer_step_2_form: {
    competence_header: "Competences",
    competence_placeholder: "Choose competences",
    no_competences: "No competences selected",
    title: "Add detailed offer information"
  },
  abk_new_offer_step_3_form: {
    back: "Previous",
    info_text: "Before publishing, check that the given information is correct",
    publish: "Publish",
    title: "Content preview"
  },
  abk_new_offers_box: {
    no_records: "No offers",
    title: "New offers"
  },
  abk_new_page_content_form: {
    title: "Content"
  },
  abk_new_page_form: {
    access_roles: {
      career_office: "Career Office",
      employer: "Employer",
      graduate: "Graduate",
      public: "Guests",
      recruiter: 'Recruiter',
      student: "Student"
    },
    address: "Url address",
    content: "Content",
    forum: "Forum",
    image: 'Image',
    keywords: "Keywords",
    language: 'Page language',
    max_size: 'The file size should be less than 4 MB',
    metaDescription: "Meta description",
    navigation: "Navigation",
    page_title: "Page title",
    parent: "Parent",
    permissions: "Who can view the site",
    sort: "Order"
  },
  abk_not_accepted_users: {
    approve: 'Approve',
    approve_success: 'Employee has been successfully approved',
    header: 'Recruiters',
    reject: 'Reject employee',
    reject_success: 'Employee was successfully rejected'
  },
  abk_observed_search_results: {
    date_added: "date added:",
    new_results: "no new | 1 new | {count} new | {count} new",
    no_records: "You are not following any offers",
    results: "no results | 1 result | {count} results | {count} results"
  },
  abk_offer_description: {
    tasks: "Tasks:",
    we_expect: "We expect:",
    we_offer: "We offer:"
  },
  abk_offer_description_form: {
    add_image: "Add an image",
    add_text_section: "Add a text section",
    empty_section_error: {
      image: "You cannot add a new section with the image because one empty section already exists.",
      text: "The new text section cannot be added because one empty section already exists."
    },
    subtitle: "Drag the section to change the order",
    title: "CONTENT AND COMPETENCES"
  },
  abk_offer_form: {
    accept: 'Accept',
    accepted_offer: 'The modified offer will be published after the employee of the career office accepts it. Continuing and saving the new version of the offer will temporarily hide it (stop publishing) until it is accepted.',
    accepted_offer_bk: 'Editing the offer results in the need to re-accept the offer. Continuing and saving the new version of the offer will temporarily hide it (stop publishing) until it is accepted.',
    offer_download_error: "An unexpected error occurred while retrieving the offer.",
    offer_updated: "The offer has been updated.",
    save_working_copy: "Save draft",
    step_1: "Basic information",
    step_2: "Details",
    step_3: "Publish",
    working_copy_saved: "A working copy of the offer has been saved."
  },
  abk_offer_item: {
    carrer_office: "Added by Career Office"
  },
  abk_offer_salary_box: {
    title: "Gross salary"
  },
  abk_offer_text_section: {
    content: "Content",
    title: "Section title"
  },
  abk_offers_box: {
    title: "Similar offers"
  },
  abk_page_not_found: {
    main_page: 'Homepage',
    not_exist: 'The page you are looking for does not exist',
    title: 'PSSST! THERE IS NOTHING HERE'
  },
  abk_paired_offers_box: {
    no_records: "No offers",
    title: "Paired offers"
  },
  abk_popular_searches_box: {
    no_records: "No searches",
    title: "popular searches"
  },
  abk_quick_info: {
    company_name: "Company",
    expiration_date: "Expiration date of the offer",
    free_places: "Number of places",
    offer_type: "Offer type",
    position_type: "Job type",
    publication_date: 'Start date of the offer',
    time_left: " | 1 day left | {count} days left | {count} days left",
    title: "Basic information",
    type_of_work: "Type of job",
    work_dimension: "Working time",
    workplace: "Workplace"
  },
  abk_recipient_group_form: {
    buttons: {
      add: "Add a group",
      save: "Save recipient group"
    },
    fields: {
      activity_id: "Activity",
      career_office_users: "Only registered career office website users",
      department_id: "Department",
      dictionary_acceptance_employer: 'Consents of employers / recruiters',
      dictionary_acceptance_word_id: "Forms of cooperation",
      dictionary_acceptance_student: 'Consent of students / alumni',
      field_of_study_id: "Program",
      name: "Name",
      recruitment_year_id: "Recruitment year",
      specialization_id: "Specialization",
      specialty_id: "Specialty",
      students: "Students",
      study_mode_id: "Mode",
      subject: "Subject",
      surveys: "Surveys",
      type_of_studies_id: "Type"
    },
    recipient_group_saved: "Recipient group saved."
  },
  abk_recipient_group_list: {
    career_office_users: "Only registered career office website users",
    date_added: "date added:",
    error_download: "An error occurred while fetching recipient groups.",
    no_records: "There are no recipient groups.",
    recipient_types: {
      employer: "Employers",
      graduate: "Graduates",
      recruiter: 'Recruiters',
      student: "Students"
    },
    unexpected_error: "An unexpected error occurred while performing the action"
  },
  abk_recommended_profiles: {
    perfect_candidate: "The perfect candidate",
    title: "Recommended profiles"
  },
  abk_remove_account_modal: {
    make_sure_title: 'Are you sure you want to remove your account?',
    warning_title: 'This operation is irreversible!',
    warning_text: 'After removing your account, you won\'t be able to log in to the system.',
    company_events_warning: 'Created events are going to be deleted and registered participants will receive a message that the event has been canceled.',
    remove_reason: "Remove reason"
  },
  abk_save_offer_filter: {
    add: "Add to followed searches",
    info_text: "After adding a search to your followed searches you will be able to follow the search results later.",
    remove: "Added to followed searches - click to cancel",
    show_observed: "Show followed searches"
  },
  abk_search: {
    placeholder: "search..."
  },
  abk_search_filter: {
    headers: {
      categories: "Categories",
      company: "Company",
      offer_type: "Offer type",
      position_type: "Position level",
      trade: "Industries",
      type_of_work: "Contract type",
      work_dimension: "Working time",
      workplace: "Location"
    },
    offer_type: {
      course: "Training",
      internship: "Internship",
      practice: "Practice",
      work: "Job"
    }
  },
  abk_search_filter_list: {
    title: "Filters"
  },
  abk_search_new_offers: {
    title: "New job offers that may interest you"
  },
  abk_section: {
    toggle_hide: "hide",
    toggle_show: "show"
  },
  abk_students: {
    abk_student_list: {
      error_download_students: 'An error occurred while downloading students.',
      error_download_student_notes: 'An error occurred while downloading notes.',
      registration_date: 'Registration date.',
      unexpected_error: 'An unexpected error occurred while performing the action',
      no_records: 'No students.'
    },
    filter_students: 'Filter students',
    role: 'Role'
  },
  abk_student_notes: {
    note_deleted: 'Note deleted correctly',
    note_deleted_error: 'The note could not be deleted'
  },
  abk_suggest_dictionary_word_modal: {
    send_for_verification: "The term has been sent for verification",
    title: "Suggest new term"
  },
  abk_survey_question_choose: {
    type: {
      checkbox: "Checkbox",
      number: "Numbers",
      radiobutton: "Radio button",
      section: "Section",
      separator: "Separating line",
      text: "Text"
    }
  },
  abk_tags_box: {
    header: "TAGS",
    input_placeholder: "Enter the keyword"
  },
  abk_text_section_form: {
    add_subsection: "add subsection",
    background: "Background image",
    content: "Content",
    section_name: "Name",
    subsection_name: "Subsection name",
    subtitle: "Text section"
  },
  abk_timeline: {
    in_progress: "in progress"
  },
  abk_upcoming_events_box: {
    no_records: "No upcoming events",
    title: "upcoming events"
  },
  abk_user_login_form: {
    application_login: 'If you have created an employer or graduate account in the Career Office system',
    idp_login: 'If you have an account in the university login system',
    reset_password: 'Have you forgotten your password?',
    title: 'Login',
    university_login: 'Log in to the university system',
  },
  abk_user_registration_form: {
    album_no: 'Album number',
    company_name: 'Company name',
    email: 'E-mail',
    first_name: 'First name',
    last_name: 'Last name',
    log_in_here: 'log in here.',
    nip: 'NIP',
    password: 'Password',
    password_complexity: 'Proposed password:\n' +
      'must consist of at least 8 characters\n' +
      'must contain at least one uppercase and lowercase letter and a number\n' +
      'cannot contain 3 consecutive characters contained in the login\n' +
      'it cannot contain your name, surname or login\n' +
      'cannot contain Polish characters',
    password_confirmation: 'Password confirmation',
    role: 'Role',
    roles: {
      employer: 'Employer',
      graduate: 'Graduate',
      recruiter: 'Recruiter'
    },
    subtitle: 'Registration form for employers and graduates.\nIf you have an active university account',
    title: 'Registration'
  },
  abk_users_list: {
    add: 'Add',
    admin_login: 'Login to the user',
    all: 'Choose',
    edit_roles: 'Edit roles',
    filter_users: 'Filter users',
    no_records: 'No users',
    no_role: 'No role',
    role: 'Role',
    table_header: 'User table',
    acceptances: 'Show user acceptances',
    unblock: 'Unblock',
    block: 'Block',
    blocked: 'User has been blocked',
    unblocked: 'User has been unblocked',
    deleted_at_start: 'Deleted at from:',
    deleted_at_end: 'Deleted at to:'
  },
  abk_video_section_form: {
    add_video: 'add video address',
    subsection_name: 'video address',
    subtitle: 'Video adresses',
    title: 'Videos',
    title_placeholder: 'Name'
  },
  abk_visual_identification_form: {
    background: "Background image",
    logo: "Logo",
    max_size: 'The file size should be less than 4 MB',
    title: "Visual identification"
  },
  abk_work_record: {
    fresh_record: "New!",
    no_records: "No offers",
    offer_expires: "the offer expires",
    published: "published",
    type: {
      job: "Job",
      practice: "Practice",
      training: "Training"
    }
  },
  companies: {
    abk_company_list: {
      accepted: {
        no_records: "No approved employers."
      },
      edit_employer_type: 'Edit employer type',
      editing_employer_type: 'Editing employer type',
      employer_type: 'Employer type',
      employer_type_success: 'Successfully updated employer type',
      employer_types: {
        employer: 'Employer',
        lite: 'LITE',
        partner: 'Partner'
      },
      error_download_companies: "An error occurred while downloading employers.",
      forAcceptance: {
        no_records: "No employers pending approval."
      },
      registration_date: "Registration date:",
      reject: 'Reject company',
      rejected: {
        no_records: "No rejected employers."
      },
      unexpected_error: "An unexpected error occurred while performing the action"
    }
  },
  competences: {
    abk_competences_list: {
      add_button: "Add competences",
      delete_info_text: "Removing competences will also remove links between this competence and all offers, events, directions and CV in which it was used.",
      no_records: "No competences",
      table_header: "Competence table"
    }
  },
  curriculum_vitaes: {
    abk_curriculum_vitaes_additional_information_form: {
      title: "Interests, hobbies, additional activities"
    },
    abk_curriculum_vitaes_basic_info_form: {
      add_website: 'Add another page',
      avatar: "Profile picture",
      email: "E-mail",
      firstName: "First name",
      info: "Fill out as much information as possible about yourself, the more fields you fill in the form, the more attractive your CV will be to a potential employer.",
      introduction: "Introduction",
      lastName: "Last name",
      phone: "Phone",
      title: "Basic information",
      websites: "Web page"
    },
    abk_cv_certificates_form: {
      empty_section_error: "The new certificate section cannot be added because one empty section already exists.",
      title: "Training, courses, certificates"
    },
    abk_cv_certificates_form_section: {
      date_end: "Date of completion",
      description: "Description",
      exam: "Ended with an exam",
      institution: "Leading institution",
      name: "Name"
    },
    abk_cv_competences_form: {
      competence_placeholder: "Choose competences",
      no_competences: "No competencies selected",
      title: "Competences"
    },
    abk_cv_custom_competence_form: {
      empty_section_error: "A new skill section cannot be added because one empty section already exists.",
      title: "Skills"
    },
    abk_cv_custom_competence_form_section: {
      custom_competence_type: {
        driving_license: "Driving license",
        foreign_language: "Foreign language",
        other: "Others"
      }
    },
    abk_cv_education_form_section: {
      attachment: "",
      date_end: "Year of completion",
      date_start: "Start year",
      degree: "Degree",
      diploma_date: "Date of obtaining the diploma",
      discipline: "Program",
      faculty: "Department",
      finished: "Finished",
      institution: "Institution name",
      institution_types: {
        college: "University",
        high_school: "High school"
      }
    },
    abk_cv_educations_form: {
      empty_section_error: "A new education section cannot be added because one empty section already exists.",
      title: "Education"
    },
    abk_cv_experiences_form: {
      empty_section_error: "You cannot add a new experiment section because one empty section already exists.",
      title: "Experience"
    },
    abk_cv_experiences_form_section: {
      date_end: "Year of completion",
      date_start: "Start year",
      description: "Responsibilities",
      employment_type_id: "Form of employment",
      experience_types: {
        job: "Job",
        practice: "Practice",
        training: "Training"
      },
      institution: "Institution name",
      position: "Position"
    }
  },
  events: {
    abk_event_description_form: {
      add_image: "Add image",
      add_text_section: "Add a text section",
      empty_section_error: {
        image: "You cannot add a new section with the image because one empty section already exists.",
        text: "The new text section cannot be added because one empty section already exists."
      },
      subtitle: "Drag the section to change the order",
      title: "CONTENT AND COMPETENCE"
    },
    abk_event_form: {
      add_sub_event: "add next",
      event_download_error: "An unexpected error occurred while downloading the event.",
      event_updated: "The event has been updated.",
      save_working_copy: "Save draft",
      saved: "The event has been saved",
      step_1: "Basic information",
      step_2: "Details",
      step_3: "Publish",
      working_copy_saved: "The draft of the event has been saved."
    },
    abk_event_quick_info: {
      cost: "Expense",
      currently_free_places: "Number of places",
      currently_reserve_places: 'Number of reserve places',
      chosen_date: 'Chosen date',
      choose_date: 'Choose date',
      competences: 'Competences',
      date_canceled: 'Date canceled',
      other_dates: 'Other dates',
      more_dates: '... and {dates} other date | ... and {dates} other dates',
      no_places: 'No places left',
      free_places_left: 'No places left | There is {count} place left | There are {places} places left',
      finished: 'This event has passed',
      end_date: "End date",
      event_type: "Type",
      free: "Free",
      free_places: "Number of free places",
      instructor: 'Instructor',
      registration_end: 'Registration end',
      registration_start: 'Registration start',
      remove_date: 'Remove date',
      reserve_places: 'Number of reserve places',
      reserve_places_left: 'No reserve places left | There is {count} reserve place left | There are {places} reserve places left',
      start_date: "Start date",
      title: "Basic information",
      unlimited: "Unlimited",
      unlimited_places: "Unlimited number of places",
      unlimited_reserve_places: 'There are places left on the reserve list',
      where: "Where"
    },
    abk_event_step_1_form: {
      add: "+ add",
      address: "Address",
      company: 'Organizer',
      cost: "Price",
      deadlines: 'Deadlines',
      end_date: "End",
      event_title: "Event name",
      event_type: "Event type",
      free_places: "Number of places",
      instructor: 'Instructor',
      next: "Next",
      attendance_mandatory: 'Attendance mandatory',
      place: "Place name",
      registration_end: 'Registration end',
      registration_start: 'Registration start',
      reminder: 'Reminder (days)',
      reminder_content: 'Reminder content',
      reminder_topic: 'Reminder topic',
      remove: 'remove',
      reserve_places: 'Number of reserve places',
      start_date: "Start",
      title: "Complete the basic information or",
      use_template: "create from another event"
    },
    abk_event_step_2_form: {
      competence_header: "Competences",
      competence_placeholder: "Choose competences",
      no_competences: "No competences selected",
      title: "Add detailed event information"
    },
    abk_event_step_3_form: {
      back: "Previous",
      info_text: "Before publishing, check that the given information is correct",
      publish: "Publish",
      title: "Content preview"
    },
    abk_event_text_section: {
      content: "Content",
      title: "Section title"
    },
    abk_events_list: {
      all: "All",
      already_taken_place: "Events has already taken place",
      attendance_list: 'Attendance list',
      event_canceled: 'Event canceled',
      events_download_error: "An unexpected error occurred while downloading events.",
      filter_events: "Filter events",
      free_places_left: "|1 place left|{count} places left|{count} places left",
      matching_competences: "Matching competences",
      no_events: "No events",
      no_free_places: "No free places",
      reserve_list: 'Reserve list',
      reserve_places_left: "|1 place on reserve list left|{count} places on reserve list left|{count} places on reserve list left",
      sign_out_list: 'Sign out list',
      take_part: "You are subscribed",
      take_part_reserve: 'You are on the reserve list',
      unexpected_error: "An unexpected error occurred while performing the action",
      unlimited_free_places: "Unlimited number of places"
    },
    abk_events_list_box: {
      accepted: 'Event accepted',
      canceled: 'Event canceled',
      draft: 'Draft',
      finished: 'The event has already taken place',
      for_acceptance: 'Pending approval',
      recent: 'New event',
      registration_start: 'Registration start',
      rejected: 'Event rejected'
    },
    abk_new_events_box: {
      no_records: "No events",
      title: "New events"
    }
  },
  messages: {
    abk_messages_list: {
      career_office_users: "Only registered career office website users",
      download_recipient_groups_error: "An unexpected error occurred while retrieving group recipients.",
      error_university_structure: "An unexpected error occurred while downloading the university structure.",
      filter_messages: "Filter messages",
      no_records: "No messages",
      recipient_types: {
        employer: "Employers",
        graduate: "Graduates",
        recruiter: 'Recruiter',
        student: "Students"
      }
    }
  },
  offer_applications: {
    abk_offer_application_modal: {
      application_info: "Your application has been saved.",
      attachment_info: "If you want, you can add attachments to your application",
      title: "You are applying for an offer"
    }
  },
  offer_filters: {
    abk_remove_object_filter_confirmation_modal: {
      confirm_delete: "Confirm removal from the followed"
    }
  },
  offer_invitations: {
    abk_offer_invitations_list: {
      no_records: "No invitations",
      sort_by: "Sort by",
      sort_options: {
        invitation_sent_date: "date of the invitation",
        offer_job_title: "offer name"
      }
    }
  },
  offers: {
    abk_offers_list: {
      all: "All",
      columns_names: {
        applications: "Applications",
        expiration_date: "Expiration date",
        matching_profiles: "Matching profiles"
      },
      filter_offers: "Filter offers",
      offers_download_error: "An unexpected error occurred while retrieving offers."
    },
    abk_reject_offer_modal: {
      rejection_reason: "Reason for rejection",
      title: "Enter reason for rejection"
    },
    tabs: {
      abk_offer_applications_tab: {
        title: "Applications"
      },
      abk_offer_matching_profiles_tab: {
        invitation_error: "An unexpected error occurred while sending the invitation",
        invitation_sent: "The invitation has been sent",
        invite: "Invite to recruit",
        match_text: "Matching",
        title: "Matching profiles"
      },
      abk_offer_tab: {
        apply_now: "Apply now",
        matching_cv: "Your CV fits this offer perfectly!",
        other_company_offers: "Other offers of this company",
        similar_offers: "Similar offers",
        title: "Offer"
      }
    }
  },
  pcg_header_messages: {
    empty_messages: "You don't have any messages",
    footer_see_more: "See more >",
    header_title: "Messages"
  },
  pcg_header_notifications: {
    company_message: "{userName} awaiting company approval",
    company_title: "New employer",
    dictionary_message: "A new dictionary has been added - {title}",
    dictionary_title: "New dictionary",
    dictionary_word_message: "User {userName} suggested a new term {title}",
    dictionary_word_title: "New term suggestion",
    empty_notifications: "You have no notifications",
    event_message: "{userName} created a new event {title}.",
    event_title: "New event",
    header_title: "Notifications",
    offer_application_message: "{userName} submitted an application for offer {title}.",
    offer_application_title: "Application for an offer",
    offer_invitation_message: "{userName} invites you to apply for the offer {title}.",
    offer_invitation_title: "Invitation to an offer",
    offer_message: "{userName} created a new job offer for {title}.",
    offer_title: "New offer"
  },
  pcg_header_profile_button: {
    lang_change_to: "Change language to {lang}",
    logout: "Log out",
    role_change_to: "Change role to {role}",
    settings: "Settings"
  },
  pcg_pagination_summary: {
    summary: "Showing {from} - {to} of {total} results"
  },
  pcg_search_select: {
    add_value: "Add value",
    change: "Change"
  },
  skills: {
    abk_competence_training_box: {
      new_competences: "New competences",
      no_place: "Event location not specified",
      no_records: "No training",
      offers_count: "no job offers | + {count} job offer | + {count} job offers | + {count} job offers",
      title: "Recommended trainings",
      top_text: "Training proposals that would extend the competences of graduates"
    },
    abk_graduates_competences_box: {
      graduates_count: "Number of program's graduates registered in the system",
      no_records: "No competences",
      title: "Competences obtained by graduates of the program"
    },
    abk_matching_to_labor_market_box: {
      degrees_texts: [
        "No match",
        "Very low match",
        "Low match",
        "Medium match",
        "High match",
        "Very high match"
      ],
      increasing_competences_text: "To further enhance the match, equip graduates with the following competences:",
      matching_offers: "Matching offers | Matching offer | Matching offers | Matching offers",
      offers_count: "no job offers | + {count} job offer | + {count} job offers | + {count} job offers",
      title: "Degree of matching the program to the labor market"
    },
    abk_skills_list: {
      filter_skills: "Filter programs",
      no_records: "No programs",
      placeholders: {
        description: "Description",
        kind: "Mode",
        name: "Name",
        type: "Type"
      },
      table_header: "Table of programs"
    }
  },
  surveys: {
    abk_answer: {
      no_answer: "No answers"
    },
    abk_requirement_modal: {
      not_selected: "The condition must have at least one answer selected",
      requirementTypeOptions: {
        all_selected: "All selected",
        any_selected: "One of many"
      },
      title: "Define the condition"
    },
    abk_survey_form: {
      save_working_copy: "Save draft",
      survey_download_error: "An unexpected error occurred while downloading the survey.",
      survey_published: "The survey has been published.",
      survey_steps_texts: {
        step_1: "Metadata",
        step_2: "Questionnaire",
        step_3: "Publish"
      },
      working_copy_saved: "Survey draft saved."
    },
    abk_survey_question_field_form: {
      labels: {
        label: "Label",
        value: "Value"
      }
    },
    abk_survey_question_fields_form: {
      add_answer: "add an answer",
      add_open_answer: "add an open answer",
      title_text: "Answers"
    },
    abk_survey_question_form: {
      add_requirement_btn: "+ Add condition",
      checkbox_display_type_options: {
        horizontal: "Checkbox (horizontal)",
        vertical: "Checkbox (vertical)"
      },
      conditions_text: "Conditions",
      labels: {
        description: "Description",
        display_type: "Display type",
        mandatory: "Mandatory question",
        section: "Content of the section",
        title: "Question"
      },
      missing_question_fields_text: "This question has no defined definitions that can be used to define the condition.",
      radio_display_type_options: {
        horizontal: "Radio button (horizontal)",
        vertical: "Radio button (vertical)"
      }
    },
    abk_survey_stats_answers_box: {
      no_records: "No questions",
      title: "Response statistics"
    },
    abk_survey_stats_info_box: {
      attributes: {
        anonymous: "Anonymous",
        closed: "Closed",
        completed: "Finished",
        graduate_fate: "Examining the fate of graduates",
        published: "Published",
        requires_answer: "Mandatory"
      },
      fields_names: {
        attributes: "Survey attributes",
        completed_count: "Completed surveys",
        name: "Name of the survey",
        publication_period: "Publication period",
        recipient_group: "Recipients group",
        sent_count: "Surveys sent"
      },
      title: "Information"
    },
    abk_survey_stats_question_answers: {
      no_records: "No answers",
      show: "Show",
      show_more: "Show more"
    },
    abk_surveys_box: {
      no_records: "No new surveys",
      sort_options: {
        initial_sent_date: "Sent date",
        survey_name: "Name of the survey"
      },
      surveys_count: "Total number of new surveys",
      title: "New surveys"
    },
    abk_surveys_list: {
      completed_answers: "Completed surveys",
      completed_at: "Filled: {date}",
      expires_at: "Expires: {date}",
      no_surveys: "No surveys",
      sent_at: "Sent: {date}",
      show_completed_surveys: "Completed surveys",
      stats: "Statistics",
      surveys_download_error: "An unexpected error occurred while downloading surveys.",
      unexpected_error: "An unexpected error occurred while performing the action"
    },
    steps: {
      abk_survey_step_1_form: {
        add_message_content: "Add the content of the message that will be sent by email with the invitation to complete the survey",
        add_reminder: "add reminder",
        additional_info: "Additional information",
        basic_info: "Basic information",
        collapse: "hide",
        create_survey_based_on_another: "create from another survey",
        delete: 'delete',
        description: "Description",
        error_download_list_of_recipients: "An unexpected error occurred while fetching the recipient list.",
        error_download_recipients: "An unexpected error occurred while retrieving group recipients.",
        error_university_structure: "An unexpected error occurred while downloading the university structure.",
        expiration_date: "Expiration date",
        graduate_send_date: "The number of months after graduation that the survey is to be sent",
        message: "Message",
        message_after_completing: "The message after completing the survey",
        message_title: "Title of the message",
        number_of_days_from_receipt: "Number of days since receipt",
        publication_period: "Publication period",
        receivers_group: "Recipients group",
        recipients_count: "Number of recipients:",
        reminder_1: "1st reminder",
        reminder_2: "2nd reminder",
        reminder_3: "3rd reminder",
        reminder_content: "Reminder content",
        reminder_title: "Reminder title",
        reminders: "Reminders",
        send_to: "To",
        show_more: "...show more",
        survey_attributes: "Survey attributes",
        survey_name: "Survey name"
      },
      abk_survey_step_2_form: {
        title: "Survey"
      },
      abk_survey_step_3_form: {
        fields_names: {
          attributes: "Survey attributes",
          expiration_date: "Expiration date",
          name: "Survey name",
          questions_set: "Question set",
          recipient_group: "Recipients group"
        },
        info_text: "Before publishing the survey, check that the information given is correct",
        information: "Information"
      }
    }
  },
  webpages: {
    abk_webpages_tree: {
      creation_date: "Date created",
      edition_date: "Edit date",
      publication_status: "Publication status",
      show_in_menu: 'Visible in the menu'
    }
  }
}
