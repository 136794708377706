<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="pcg-icon-bookmark"/>{{ $t('components.abk_new_offers_box.title') }}</span>
      <!--<pcg-sort-btn class="sort-btn w-auto" :options="sortOptions" :value="order" @input="changeOrder"/>-->
      <router-link v-if="offers && totalOffers > offers.length"
                   class="pcg-link pcg-small"
                   :to="{ name: 'offers_list_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <abk-list v-if="offers && offers.length" :items="offers" :items-column-name="$t('general.offer')">
      <template v-slot="item">
        <pcg-btn-symbol v-if="waitingForAcceptance(item.status.value)"
                        class="my-0 ml-auto"
                        variant="success"
                        icon-class="pcg-icon-check-alt"
                        :tooltip="$t('general.accept')"
                        @click="$emit('acceptOffer', item.id)"/>
      </template>
    </abk-list>
    <span v-else class="pcg-no-records">{{ $t('components.abk_new_offers_box.no_records') }}</span>
  </pcg-box>
</template>

<script>
import AbkList from './AbkList'

export default {
  name: 'AbkNewOffersBox',
  props: {
    offers: Array,
    order: Object,
    totalOffers: Number
  },
  components: { AbkList },
  computed: {
    sortOptions () {
      return [
        { text: this.$t('general.date_of_publication'), value: 'publication_date' },
        { text: this.$t('general.time_to_end'), value: 'expiration_date' }
      ]
    }
  },
  methods: {
    changeOrder (newOrder) {
      this.$emit('changeOrder', newOrder)
    },
    waitingForAcceptance (status) {
      return ['recent', 'for_acceptance'].includes(status)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

  .offers-list {
    margin-left: -$pcg-box-padding-x;
    margin-right: -$pcg-box-padding-x;
    padding: 0;
  }

  .offer-item {
    padding-left: $pcg-box-padding-x;
    padding-right: $pcg-box-padding-x;
  }
</style>
