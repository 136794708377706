<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-filters normal-font-size"></i>
      {{ $t('components.abk_search_filter_list.title') }}
    </div>
    <abk-search-filter v-for="(filterProperties, name) in filters"
                       :filters="filterProperties"
                       :name="name"
                       :path="path"
                       :dictionaries="dictionaries"
                       :key="`filter-${name}`">
    </abk-search-filter>
  </pcg-box>
</template>

<script>
import AbkSearchFilter from './AbkSearchFilter'

export default {
  name: 'abk-search-filter-list',
  components: { AbkSearchFilter },
  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    path: {
      type: String,
      required: true
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .normal-font-size {
    font-size: 1rem;
  }
</style>
