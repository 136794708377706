<template>
  <b-row>
    <b-col cols="12">
      <pcg-btn size="small" class="new-page-btn mt-0 mx-0" @click="$router.push({name: 'webpages_new_path'})">
        {{ $t('views.pages_list.new_page') }}
      </pcg-btn>

      <div class="form-group">
        <label class="pcg-input-label">
          {{ $t('components.abk_new_page_form.permissions') }}
        </label>
        <div>
          <pcg-checkbox v-for="(value, role) in accessRoles"
                        :value="value"
                        @input="updateRoles(role, $event)"
                        :key="role"
                        class="abk-inline-block">
            {{ $t(`components.abk_new_page_form.access_roles.${role}`) }}
          </pcg-checkbox>
        </div>
      </div>

      <abk-loader v-if="showLoader" class="with-margins"/>
      <template v-else-if="pages.length > 0">
        <abk-webpages-tree :table-key="`webpages-tree-${tableKey}`"
                           :pages="pages"
                           :draggable="editMode"
                           @sort="sortPages"
                           @change="changePagesOrder"
                           @publishPage="publishPage"
                           @deletePage="deletePage"
                           @showInMenu="showInMenu"
                           :ondragend="validateNodeDragging"/>

        <div class="change-order-buttons-container">
          <pcg-btn size="small" class="ml-0" @click="toggleEditMode">
            <template v-if="!editMode">{{ $t('general.change_order') }}</template>
            <template v-else>{{ $t('general.save_changes') }}</template>
          </pcg-btn>
          <pcg-btn v-if="editMode" size="small" @click="discardChanges">
            {{ $t('general.discard_changes') }}
          </pcg-btn>
        </div>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import ApiWebpages from '../../api/webpages'
import axios from 'axios'
import AbkWebpagesTree from '../../components/webpages/AbkWebpagesTree'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'WebpagesIndex',
  components: { AbkLoader, AbkWebpagesTree },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      accessRoles: {
        student: false,
        graduate: false,
        career_office: false,
        employer: false,
        recruiter: false,
        public: false
      },
      pages: [],
      pagesBeforeSorting: [],
      orderBy: 'sort',
      orderDirection: 'asc',
      editMode: false,
      pagesToUpdate: [],
      tableKey: 0,
      applicationLanguage: null
    }
  },
  created () {
    if (this.$cookies.get('locale')) {
      this.applicationLanguage = this.$cookies.get('locale')
    }
  },
  mounted () {
    this.getTree()
  },
  methods: {
    validateNodeDragging (node) {
      return node.parent._id === node._vm.store.dplh.parent._id
    },
    changePagesOrder (node) {
      node.parent.children.forEach((child, index) => {
        const pageToUpdateIndex = this.pagesToUpdate.findIndex(page => page.id === child.id)
        if (pageToUpdateIndex >= 0) {
          const pageToUpdate = this.pagesToUpdate[pageToUpdateIndex]
          pageToUpdate.oldSort !== index ? pageToUpdate.sort = index : this.pagesToUpdate.splice(pageToUpdateIndex, 1)
        } else if (child.sort !== index) {
          this.pagesToUpdate.push({ id: child.id, sort: index, oldSort: child.sort })
        }
      })
    },
    toggleEditMode () {
      ++this.tableKey
      this.editMode ? this.updateTreeSorting() : this.sortPagesBeforeEdit()
      this.editMode = !this.editMode
    },
    discardChanges () {
      ++this.tableKey
      this.editMode = false
      this.pages = [...this.pagesBeforeSorting]
    },
    getTree () {
      ApiWebpages.getTree(this.orderBy, this.orderDirection, this.accessRolesTrue(), this.applicationLanguage)
        .then(response => {
          this.pages = response.data.data
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.pages_list.title'),
        subtitle: this.$t('views.pages_list.subtitle')
      })
    },
    deletePage (id) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiWebpages.removeWebpage(id)
            .then(response => {
              this.getTree()
            }).catch(error => {
              console.log(error)
            })
        }
      })
    },
    publishPage ({ pageId, publish }) {
      const pageToUpdate = this.findPageById(pageId)
      const currentStatus = pageToUpdate.status

      ApiWebpages.updateWebpage(pageId, { webpage: { status: this.booleanToStatus(publish) } })
        .then(() => {
          pageToUpdate.status = this.booleanToStatus(publish)
        }).catch(error => {
          const page = this.pages.find((page) => page.id === pageId)
          if (page) {
            setTimeout(() => { page.published = this.statusToBoolean(currentStatus) }, 300)
          }
          console.log(error.response.data)
        })
    },
    showInMenu ({ pageId, showInMenu }) {
      const pageToUpdate = this.findPageById(pageId)

      ApiWebpages.updateWebpage(pageId, { webpage: { showInMenu: showInMenu } })
        .then(() => {
          pageToUpdate.showInMenu = showInMenu
        }).catch(error => {
          console.log(error.response.data)
        })
    },
    sortPages ({ orderBy, orderDirection }) {
      this.orderBy = orderBy
      this.orderDirection = orderDirection
      this.getTree()
    },
    sortPagesBeforeEdit () {
      this.orderBy = 'sort'
      this.orderDirection = 'asc'

      ApiWebpages.getTree(this.orderBy, this.orderDirection, this.accessRolesTrue(), this.applicationLanguage)
        .then(response => {
          this.pages = response.data.data
          this.pagesBeforeSorting = [...this.pages]
        }).catch(error => {
          this.$toastr.e(error)
        })
    },
    statusToBoolean (status) {
      if (status === 'published') {
        return true
      } else {
        return false
      }
    },
    booleanToStatus (status) {
      if (status) {
        return 'published'
      } else {
        return 'recent'
      }
    },
    updateRoles (checkboxRole, event) {
      this.accessRoles[checkboxRole] = event
      this.getTree()
    },
    accessRolesTrue () {
      const tab = []
      _.forEach(this.accessRoles, (val, key) => {
        if (val) {
          tab.push(key)
        }
      })
      return tab
    },
    updateTreeSorting () {
      const batch = this.pagesToUpdate.map(page => ApiWebpages.updateWebpage(page.id, { webpage: { sort: page.sort } }))
      axios.all(batch)
        .then(responses => {
          this.pagesToUpdate = []
        })
        .catch(error => {
          console.error(error)
        })
    },
    findPageById (id) {
      function iter (a) {
        if (a.id === id) {
          result = a
          return true
        }
        return Array.isArray(a.children) && a.children.some(iter)
      }

      let result
      this.pages.some(iter)
      return result
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .change-order-buttons-container {
    margin-top: $pcg-m-lg;
  }

  .new-page-btn {
    margin-bottom: $pcg-m-lg;
  }

  .abk-inline-block{
    display: inline-block;
  }
</style>
