<template>
  <div :class="{'abk-draft': isDraft(news) }" class="grid position-relative">
    <span class="edit-page-link-container">
      <span class="edit-page-link">
        <router-link :to="{ name: 'webpages_show_path', params: { id: news.address} }">
          {{ news.title }}
        </router-link>
      </span>
    </span>
    <span class="actions">
      <pcg-switch class="mx-auto"
                  :value="news.status === 'published'"
                  @input="$emit('publishPage', { newsId: news.id, publish: $event })"/>
    </span>
    <span class="date">{{ formatDate(news.updatedAt) }}</span>
    <span class="date">{{ formatDate(news.createdAt) }}</span>
    <span class="actions">
      <button class="delete-page-btn" @click="$router.push({ name: 'news_edit_path', params: { id: news.id } })">
        <i class="pcg-icon-edit"/>
      </button>
      <button class="delete-page-btn" @click="$emit('deletePage', news.id)">
        <i class="pcg-icon-trash-can"/>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AbkWebpagesTreeNode',
  props: {
    news: Object
  },
  methods: {
    formatDate (date) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
      }
      return '—'
    },
    isDraft (currentPage) {
      return currentPage.status === 'draft'
    }
  }
}
</script>

<style scoped lang="scss">
@import "app/javascript/abk/assets/stylesheets/vars";

.delete-page-btn {
  background-color: transparent;
  border: none;
  outline: none;
  color: $pcg-dark-gray;
}
.edit-page-link-container {
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
  min-width: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left!important;
}
.edit-page-link {
  position: relative;
  &, a {
    color: $main-color;
  }
}
.abk-draft{
  opacity: 0.3;
}
.date {
  line-height: 1;
  color: $pcg-gray;
  font-weight: 500;
  padding: $pcg-m-sm $pcg-m-lg $pcg-m-sm 0;
}
.he-tree{
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
}
.tree-node-inner{
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.child-icon {
  color: $pcg-dark-gray;
  position: absolute;
  top: -2px;
  left: -25px;
  font-weight: bold;
}
.actions {
  position: relative;
  top: .5rem;
}
@media(max-width: $screen-md-max) {
  .actions {
    position: relative;
    top: 0;
  }
}
</style>

<style lang="scss">
@import "app/javascript/abk/assets/stylesheets/vars-contrast";
.contrast {
  .delete-page-btn {
    color: $pcg-dark-gray;
  }
  .edit-page-link {
    &, a {
      color: $main-color;
    }
  }
  .child-icon {
    color: $pcg-dark-gray;
  }
}
</style>
