import { render, staticRenderFns } from "./AbkNewOffersBox.vue?vue&type=template&id=4c599742&scoped=true&"
import script from "./AbkNewOffersBox.vue?vue&type=script&lang=js&"
export * from "./AbkNewOffersBox.vue?vue&type=script&lang=js&"
import style0 from "./AbkNewOffersBox.vue?vue&type=style&index=0&id=4c599742&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c599742",
  null
  
)

export default component.exports