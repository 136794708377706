<template>
  <b-row>
    <abk-curriculum-vitaes-basic-info-form class="col-12 form"
                                           :errors="errors"
                                           :progress="progress"
                                           v-model="form"
                                           :can-add-website="form.websites.length < 5 && lastWebsite !== '' && lastWebsite !== null"
                                           @addWebsite="addWebsite"
                                           @updateWebsite="updateWebsite"
                                           @removeWebsite="removeWebsite"/>
    <abk-cv-educations-form class="col-12 form"
                            :errors="errors"
                            v-model="cvEducations"/>
    <abk-cv-experiences-form class="col-12 form"
                            :errors="errors"
                            v-model="cvExperiences"/>
    <abk-cv-certificates-form class="col-12 form"
                              :errors="errors"
                              v-model="cvCertificates"/>
    <abk-cv-custom-competence-form class="col-12 form"
                                   :errors="errors"
                                   v-model="cvCustomCompetences"
                                   v-if="env && env.competenceFeature !== 'off'"/>
    <abk-cv-competences-form class="col-12 form"
                             :errors="errors"
                             v-model="cvCompetences"
                             v-if="env && env.competenceFeature !== 'off'"/>
    <abk-curriculum-vitaes-additional-information-form class="col-12 form"
                                                       :errors="errors"
                                                       v-model="form"/>
    <b-col cols="12">
      <h2 class="pcg-form-title">{{ $t('views.edit_company_profile.approvals') }}</h2>
      <div v-for="(cvAcceptance, index) in cvAcceptances"
           :key="`cvAcceptance-${cvAcceptance.key * index}`">
        <div v-if="cvAcceptance.wordType === 'clause'" class="clause-information">
          <span v-html="cvAcceptance.text"></span>
        </div>
        <pcg-checkbox v-else-if="cvAcceptance.blockedOnProfile"
                      class="blocked-on-profile"
                      variant="normal"
                      :disabled="true"
                      :required="cvAcceptance.required"
                      :show-error="errorExist(`cvAcceptances.${index}.value`)"
                      :error-text="getError(`cvAcceptances.${index}.value`)"
                      v-model="cvAcceptance.value">
          <span v-html="cvAcceptance.text"></span>
        </pcg-checkbox>
        <pcg-checkbox v-else
                      variant="normal"
                      :required="cvAcceptance.required"
                      :show-error="errorExist(`cvAcceptances.${index}.value`)"
                      :error-text="getError(`cvAcceptances.${index}.value`)"
                      v-model="cvAcceptance.value">
          <span v-html="cvAcceptance.text"></span>
        </pcg-checkbox>
      </div>
      <pcg-btn
            size="small"
            class="mr-auto ml-0 danger-delete-btn"
            @click="() => this.showAccountModal = true">
        {{ $t('general.remove_account') }}
      </pcg-btn>
    </b-col>
    <unsaved-changes-modal :title="$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.title')"
                           :buttons="unsavedChangesModalButtons"
                           v-model="showUnsavedChangesModal"
                           ref="unsavedChangesModal"/>
    <abk-remove-account-modal :show="showAccountModal" type="cv" @discard="showAccountModal = false" @removed="logout" />
  </b-row>
</template>

<script>
import AbkCurriculumVitaesBasicInfoForm from '../../components/curriculum_vitaes/AbkCurriculumVitaesBasicInfoForm'
import ApiCurriculumVitaes from '../../api/curriculum_vitaes'
import ApiDdictionaryAcceptances from '../../api/dictionary_acceptances'
import axios from 'axios'
import AbkCvEducationsForm from '../../components/curriculum_vitaes/AbkCvEducationsForm'
import AbkCvExperiencesForm from '../../components/curriculum_vitaes/AbkCvExperiencesForm'
import AbkCvCertificatesForm from '../../components/curriculum_vitaes/AbkCvCertificatesForm'
import AbkRemoveAccountModal from '../../components/AbkRemoveAccountModal.vue'
import AbkCurriculumVitaesAdditionalInformationForm
  from '../../components/curriculum_vitaes/AbkCurriculumVitaesAdditionalInformationForm'
import AbkCvCustomCompetenceForm from '../../components/curriculum_vitaes/AbkCvCustomCompetenceForm'
import AbkCvCompetencesForm from '../../components/curriculum_vitaes/AbkCvCompetencesForm'
import UnsavedChangesModal from '../../components/UnsavedChangesModal'
import WindowResizeMixin from '../../mixins/window_resize'
import { mapGetters } from 'vuex'

export default {
  name: 'curriculum-vitae-edit',
  components: {
    UnsavedChangesModal,
    AbkCvCompetencesForm,
    AbkCvCustomCompetenceForm,
    AbkCurriculumVitaesAdditionalInformationForm,
    AbkCvCertificatesForm,
    AbkCvExperiencesForm,
    AbkCvEducationsForm,
    AbkCurriculumVitaesBasicInfoForm,
    AbkRemoveAccountModal
  },
  mixins: [WindowResizeMixin],
  data () {
    return {
      inclusionsMaxKey: 0,
      errors: {},
      loading: false,
      form: {
        firstName: null,
        lastName: null,
        templateId: null,
        additionalInformation: null,
        introduction: null,
        email: null,
        phone: null,
        avatar: null,
        websites: [''],
        main: true
      },
      showAccountModal: false,
      cvId: null,
      cvEducations: [],
      cvExperiences: [],
      cvCertificates: [],
      cvCustomCompetences: [],
      cvCompetences: [],
      cvAcceptances: [],
      progress: 30,
      notSavedChanges: false,
      showUnsavedChangesModal: false,
      unsavedChangesModalButtons: [],
      lastWebsite: ''
    }
  },
  computed: {
    ...mapGetters('environment', ['env']),
    ...mapGetters('auth', ['currentRole']),
    formattedCompetences () {
      return this.cvCompetences.map(competence => {
        return { competenceId: competence.value }
      })
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.initModalButtons()
      }
    },
    form: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvEducations: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvExperiences: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvCertificates: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvCustomCompetences: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvCompetences: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cvAcceptances: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    windowWidth () {
      this.setFooterNavbarItems()
    }
  },
  mounted () {
    this.getCurriculumVitae()
    this.initModalButtons()
  },
  methods: {
    logout () {
      const role = this.currentRole
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${this.env.abkOpEndSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${this.env.abkOpEndSsessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    getIndexAsSort (elements) {
      return elements.map(({ key, ...rest }, index) => { return { ...rest, sort: index } })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.title'),
        subtitle: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.subtitle')
      })
    },
    setFooterNavbarItems () {
      const items = []

      if (this.$i18n != null) {
        items.push({
          type: 'button',
          text: this.$t('general.save_draft'),
          action: () => {
            this.updateCv('draft')
          }
        })
        items.push({
          type: 'button',
          text: this.$t('general.save'),
          action: () => {
            this.updateCv('saved')
          }
        })
      }
      if (!this.form.main && this.windowWidth > 815) {
        items.push({ type: 'button', text: this.$t('general.reject_changes'), action: this.deleteDraft })
        items.push({ type: 'button', text: this.$t('general.preview_saved_draft'), action: this.showPreview })
      }

      this.$store.dispatch('footerNavbar/setItems', items)
    },
    getCurriculumVitae () {
      let promises = null
      if (this.$route.name === 'student_curriculum_vitae_edit_path') {
        promises = [
          ApiDdictionaryAcceptances.getDictionaryAcceptanceWords('cv'),
          ApiDdictionaryAcceptances.getDictionaryAcceptanceWords('survey'),
          ApiCurriculumVitaes.getCurriculumVitaeEdit(this.$route.params.id)
        ]
      } else {
        promises = [
          ApiDdictionaryAcceptances.getDictionaryAcceptanceWords('cv'),
          ApiDdictionaryAcceptances.getDictionaryAcceptanceWords('survey'),
          ApiCurriculumVitaes.getMyCurriculumVitaeEdit()
        ]
      }
      axios.all(promises)
        .then(responses => {
          responses.forEach(response => {
            if (response && response.data && response.data.data && response.data.data.type === 'curriculum_vitae') {
              this.cvId = response.data.data.id
              this.form = { ...this.form, ...response.data.data.attributes }
              const { included } = response.data
              if (!this.form.websites) {
                this.form.websites = ['']
              }
              this.lastWebsite = this.form.websites[this.form.websites.length - 1]
              this.cvEducations = this.getSortedInclusions(included, 'cv_education')
              this.cvExperiences = this.getSortedInclusions(included, 'cv_experience')
              this.cvCertificates = this.getSortedInclusions(included, 'cv_certificate')
              this.cvCustomCompetences = this.getSortedInclusions(included, 'cv_custom_competence')
              this.cvCompetences = response.data.data.attributes.userAssignmentCompetences.map(competence => {
                return {
                  value: competence.id,
                  text: competence.name
                }
              })
              response.data.included.filter(included => included.type === 'cv_acceptance').forEach(cvAcceptance => {
                const existingAcceptance = this.cvAcceptances.find(acceptance => {
                  return acceptance.dictionaryAcceptanceWordId === cvAcceptance.attributes.dictionaryAcceptanceWordId.toString()
                })
                if (existingAcceptance) {
                  existingAcceptance.value = cvAcceptance.attributes.value
                  existingAcceptance.id = cvAcceptance.id
                } else {
                  this.cvAcceptances.push({
                    key: cvAcceptance.attributes.dictionaryAcceptanceWordId,
                    id: cvAcceptance.id,
                    ...cvAcceptance.attributes
                  })
                }
              })
              if (response.data.data.attributes.status === 'draft') {
                this.$swal({
                  title: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.draft_info_title'),
                  text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.draft_info_message')
                })
              }
            } else {
              this.cvAcceptances = [
                ...this.cvAcceptances,
                ...response.data.data.map(acceptance => {
                  if (this.cvAcceptances.findIndex(acc => acc.dictionaryAcceptanceWordId === acceptance.id.toString()) === -1) {
                    return {
                      id: null,
                      dictionaryAcceptanceWordId: acceptance.id,
                      value: null,
                      text: acceptance.attributes.value,
                      key: acceptance.id,
                      required: acceptance.attributes.status === 'required',
                      name: acceptance.attributes.name,
                      wordType: acceptance.attributes.wordType,
                      blockedOnProfile: acceptance.attributes.blockedOnProfile
                    }
                  }
                }).filter((el) => (el !== undefined))
              ]
            }
          })
          const sortByName = []
          sortByName.push(...this.cvAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
          this.cvAcceptances = sortByName
          this.$nextTick(() => {
            this.notSavedChanges = false
          })
          this.setFooterNavbarItems()
        }).catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('views.curriculum_vitaes.curriculum_vitae_edit.cv_download_error'))
        })
    },
    getSortedInclusions (data, inclusionType) {
      return _.sortBy(data.filter(included => included.type === inclusionType), [e => e.attributes.sort])
        .map(included => {
          return {
            key: this.inclusionsMaxKey++,
            id: included.id,
            ...included.attributes
          }
        })
    },
    updateCv (status) {
      return new Promise((resolve, reject) => {
        this.loading = true
        const curriculumVitae = {
          ...this.form,
          status: status,
          cvEducations: this.getIndexAsSort(this.cvEducations),
          cvExperiences: this.getIndexAsSort(this.cvExperiences),
          cvCertificates: this.getIndexAsSort(this.cvCertificates),
          cvCustomCompetences: this.getIndexAsSort(this.cvCustomCompetences),
          cvCompetences: this.formattedCompetences,
          cvAcceptances: this.cvAcceptances
        }
        if (this.$route.name === 'student_curriculum_vitae_edit_path') {
          ApiCurriculumVitaes.updateCurriculumVitae(this.cvId, { curriculumVitae: curriculumVitae })
            .then(response => {
              this.errors = {}
              this.getCurriculumVitae()
              this.$store.dispatch('auth/fetchUser')
                .then(() => {
                })
                .catch(error => {
                  console.log(error.response.data.message)
                  this.$toastr.e(error.response.data.message)
                })
              this.$toastr.s(this.$t('views.curriculum_vitaes.curriculum_vitae_edit.cv_updated'))
              this.notSavedChanges = false
              this.$router.go(-1)
              // this.$router.push({ name: 'webpages_path' })
              resolve(response)
            }).catch(error => {
              this.$toastr.e(this.$t('general.fields_not_filled'))
              this.errors = typeof error.response.data === 'object' ? error.response.data : null
              reject(error)
            }).finally(() => {
              this.loading = false
            })
        } else {
          ApiCurriculumVitaes.updateMyCurriculumVitae({ curriculumVitae: curriculumVitae })
            .then(response => {
              this.errors = {}
              this.getCurriculumVitae()
              this.$store.dispatch('auth/fetchUser')
                .then(() => {
                })
                .catch(error => {
                  console.log(error.response.data.message)
                  this.$toastr.e(error.response.data.message)
                })
              this.$toastr.s(this.$t('views.curriculum_vitaes.curriculum_vitae_edit.cv_updated'))
              this.notSavedChanges = false
              this.$router.go(-1)
              // this.$router.push({ name: 'webpages_path' })
              resolve(response)
            }).catch(error => {
              this.$toastr.e(this.$t('general.fields_not_filled'))
              this.errors = typeof error.response.data === 'object' ? error.response.data : null
              reject(error)
            }).finally(() => {
              this.loading = false
            })
        }
      })
    },
    deleteDraft () {
      this.loading = true
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.reject'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCurriculumVitaes.destroyDraft()
            .then(response => {
              this.errors = {}
              this.getCurriculumVitae()
              this.$toastr.s(this.$t('views.curriculum_vitaes.curriculum_vitae_edit.draft_destroyed'))
            }).catch(error => {
              console.log(error)
              this.$toastr.e(this.$t('general.unexpected_error'))
            }).finally(() => {
              this.loading = false
            })
        }
      })
    },
    showPreview () {
      this.$router.push({ name: 'curriculum_vitae_show_path', params: { id: this.cvId } })
    },
    initModalButtons () {
      this.unsavedChangesModalButtons = [
        {
          event: 'save',
          size: 'small',
          variant: 'main',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.save')
        },
        {
          event: 'draft',
          size: 'small',
          variant: 'main',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.draft')
        },
        {
          event: 'cancel',
          size: 'small',
          variant: 'additional',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.cancel')
        }
      ]
    },
    addWebsite () {
      this.form.websites.push('')
      this.lastWebsite = ''
    },
    updateWebsite (index, value) {
      this.form.websites[index] = value
      if (index === (this.form.websites.length - 1)) {
        this.lastWebsite = value
      }
    },
    removeWebsite (index) {
      this.form.websites.splice(index, 1)
      this.lastWebsite = this.form.websites[this.form.websites.length - 1]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.notSavedChanges) {
      this.showUnsavedChangesModal = true
      this.$nextTick(() => {
        this.$refs.unsavedChangesModal.$once('cancel', () => {
          this.showUnsavedChangesModal = false
          next()
        })
        this.$refs.unsavedChangesModal.$once('save', () => {
          this.updateCv('saved').then(response => {
            this.showUnsavedChangesModal = false
            next()
          })
        })
        this.$refs.unsavedChangesModal.$once('draft', () => {
          this.updateCv('draft').then(response => {
            this.showUnsavedChangesModal = false
            next()
          })
        })
        this.$refs.unsavedChangesModal.$once('input', (val) => {
          if (!val) {
            this.$refs.unsavedChangesModal.$off(['cancel', 'save', 'draft'])
            next(false)
          }
        })
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .danger-delete-btn, .danger-delete-btn:focus {
    background-color: $pcg-red;
    color: $color-wcag-static;
  }

  .danger-delete-btn:hover {
    background-color: $alert-error;
  }

  .form {
    margin-bottom: $pcg-m-xxl;
  }
  .title-container {
    margin-bottom: $pcg-m-lg;
  }
  .buttons {
    button {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  ::v-deep .dropdown-menu {
    z-index: 3;
  }
  ::v-deep .custom-file {
    .cross {
      z-index: 2;
    }
  }
  .clause-information {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-bottom: 1rem;
  }
  .blocked-on-profile {
    opacity: 0.6;
  }
</style>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .clause-information {
    color: $pcg-gray;
  }
}
</style>
