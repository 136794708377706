import { render, staticRenderFns } from "./SimpleMessages.vue?vue&type=template&id=85d562fe&scoped=true&"
import script from "./SimpleMessages.vue?vue&type=script&lang=js&"
export * from "./SimpleMessages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85d562fe",
  null
  
)

export default component.exports