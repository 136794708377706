<template>
  <div>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">
          <pcg-text-input class="abk-input col-12"
                          autocomplete="off"
                          :label="$t('views.competences.labels.name')"
                          :error-text="getError('name')"
                          :show-error="errorExist('name')"
                          v-model="competence.name"/>
          <pcg-text-input class="abk-input col-12"
                          type="textarea"
                          :label="$t('views.competences.labels.description')"
                          :error-text="getError('description')"
                          :show-error="errorExist('description')"
                          v-model="competence.description"/>
          <pcg-btn size="small" @click="save()" variant="additional" class="action-btn">
            {{ $t('views.common.save') }}
          </pcg-btn>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiCompetences from '../../api/competences'
export default {
  name: 'AbkCompetenceForm',
  props: {
    competenceId: String,
    successMessage: String,
    submitAction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      competence: {
        name: null,
        description: null
      },
      errors: {}
    }
  },
  methods: {
    save () {
      this.submitAction({ competence: this.competence })
        .then((response) => {
          this.clearErrors()
          this.$toastr.s(this.$t(this.successMessage))
          if (!this.competenceId) {
            console.log('lolz', response.data.data.id)
            this.$router.push({ name: 'competences_edit_path', params: { id: response.data.data.id } })
          }
        })
        .catch((error) => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    getCompetence () {
      if (!this.competenceId) { return }
      ApiCompetences.getCompetence(this.competenceId)
        .then(response => {
          this.competence = response.data.data.attributes
          this.$store.dispatch('header/setObjectName', this.competence.name)
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    clearErrors () {
      this.errors = {}
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  mounted () {
    this.getCompetence()
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

</style>
