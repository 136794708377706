<template>
  <div class="pcg-footer-container" v-html="content">
  </div>
</template>

<script>
export default {
  name: 'AbkSecondFooter',
  props: {
    content: String
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars";

.configurable-text-container {
  div {
    font-weight: 400;
    width: 100%;
    font-size: $font-size-s;
    color: $pcg-the-darkest-gray;
  }
}
.pcg-footer-container {
  background-color: transparent;
  height: fit-content;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 1;
}

</style>
