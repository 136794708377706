<template>
  <div class="survey-container">
    <abk-answer v-for="(question, index) in sortedVisibleQuestions"
                  :question="question"
                  :answer="getAnswerByQuestionId(question.id)"
                  :key="`question-${index}`"
                  class="question"/>
  </div>
</template>

<script>
import ApiAnswers from '../../../api/answers'
import AbkAnswer from '../../../components/surveys/AbkAnswer'

export default {
  name: 'AnswersShow',
  components: { AbkAnswer },
  data () {
    return {
      survey: {
        id: null,
        name: null,
        questions: []
      },
      answer: {
        id: null,
        userName: null,
        answers: []
      },
      requirements: null
    }
  },
  methods: {
    getAnswerByQuestionId (questionId) {
      return this.answer.answers.find(e => e.questionId === questionId)
    },
    getAnswer () {
      if (!this.answer.id) { return }

      ApiAnswers.getAnswer(this.answer.id)
        .then(response => {
          this.setAnswer(response.data.data)
          this.setRequirementsFromResponse(response)
          this.setSurveyFromResponse(response.data.included)
          this.setHeader()
          const objectName = _.compact([this.answer.userName, this.answer.surveyName]).join(' | ')
          this.$store.dispatch('header/setObjectName', objectName)
        })
        .catch(error => {
          console.dir(error)
        })
    },
    setAnswer ({ id, attributes }) {
      this.answer = { ...attributes, id }
    },
    setRequirementsFromResponse (response) {
      this.requirements = response.data.included
        .filter(inclusion => inclusion.type === 'requirement')
        .map(requirement => requirement.attributes)
    },
    setSurveyFromResponse (included) {
      const includedSurvey = included.find(e => e.type === 'survey')
      this.survey = {
        ...this.survey,
        ...includedSurvey.attributes,
        questions: this.setQuestions(included)
      }
    },
    setQuestions (collection) {
      return collection
        .filter(inclusion => inclusion.type === 'question')
        .map(({ id, attributes, relationships: { questionFields, requirements } }) => {
          return {
            id: id,
            ...attributes,
            questionFields: this.mapRelationshipsToInclusions(questionFields, collection, 'question_field'),
            requirements: this.mapRelationshipsToInclusions(requirements, collection, 'requirement')
          }
        })
    },
    mapRelationshipsToInclusions (relationships, collection, type) {
      return relationships.data.map(({ id }) => {
        const element = collection.find(e => e.type === type && e.id === id)
        return { id: element.id, ...element.attributes }
      })
    },
    isQuestionVisible (id) {
      const parentRequirements = this.requirements.filter(e => e.childQuestionId === id)
      return parentRequirements.length > 0 ? parentRequirements.some(e => this.isRequirementMet(e)) : true
    },
    isRequirementMet ({ questionFieldIds, requirementType }) {
      const requirementQuestionFieldIds = questionFieldIds.map(e => e.toString())

      return requirementType === 'all_selected'
        ? this.areAllFieldsCompleted(requirementType, requirementQuestionFieldIds)
        : this.isAnyFieldCompleted(requirementType, requirementQuestionFieldIds)
    },
    isAnyFieldCompleted (requirementType, requiredFieldsIds) {
      return requiredFieldsIds.some(e => this.completedQuestionFieldsIds.includes(e))
    },
    areAllFieldsCompleted (requirementType, requiredFieldsIds) {
      return requiredFieldsIds.every(e => this.completedQuestionFieldsIds.includes(e))
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.answer.surveyName,
        subtitle: this.answer.userName
      })
    }
  },
  computed: {
    visibleQuestions () {
      return this.survey.questions.filter(e => this.isQuestionVisible(e.id))
    },
    sortedVisibleQuestions () {
      return _.orderBy(this.visibleQuestions, 'sort', 'asc')
    },
    completedQuestionFieldsIds () {
      return this.answer.answers.reduce((agg, answer) => {
        const question = this.survey.questions.find(e => e.id === answer.questionId)
        if (['checkbox', 'radiobutton'].includes(question.questionType)) {
          const questionFieldsIds = answer.answerValues.map(value => {
            const questionField = question.questionFields.find(qf => qf.value === value) ||
              question.questionFields.find(qf => qf.fieldType === 'text')
            return questionField ? questionField.id : null
          })
          return [...agg, ...questionFieldsIds.filter(qf => qf !== null)]
        }
        return [...agg, ...question.questionFields.map(e => e.id)]
      }, [])
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.answer.id = this.$route.params.id
    this.getAnswer()
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/stylesheets/vars";

  .survey-container {
    max-width: 600px;
    margin-bottom: $pcg-m-xxl;
  }
  .question {
    margin-bottom: $pcg-m-lg;
  }
</style>
