import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './modules/auth'
import { footerNavbar } from './modules/footerNavbar'
import { environment } from './modules/env'
import { user } from './modules/user'
import { notifications } from './modules/notifications'
import { tmpIds } from './modules/tmp_ids'
import { sidebarMenu } from './modules/sidebarMenu'

import { header, locale, menu, pageCover, page } from 'pcg-core-vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    environment,
    footerNavbar,
    header,
    pageCover,
    user,
    notifications,
    tmpIds,
    locale,
    sidebarMenu,
    page,
    menu
  }
})

export {
  store
}
