<template>
  <div>
    <h4 class="pcg-event-header-text">{{ $t('components.surveys.steps.abk_survey_step_3_form.info_text') }}</h4>
    <abk-form-summary-table :title="$t('components.surveys.steps.abk_survey_step_3_form.information')"
                            :records="getRecordsByFieldsNames(informationFieldsNames)"
                            class="summary-table"/>
  </div>
</template>

<script>
import AbkFormSummaryTable from '../form/AbkFormSummaryTable'
import { decamelize } from 'humps'

export default {
  name: 'AbkSurveyStep3Form',
  components: { AbkFormSummaryTable },
  props: {
    survey: Object,
    recipientGroups: Array
  },
  data () {
    return {
      informationFieldsNames: ['name', 'attributes', 'expirationDate', 'recipientGroup']
    }
  },
  computed: {
    surveyAttributes () {
      return ['requiresAnswer', 'anonymous', 'graduateFate'].filter(attribute => this.survey[attribute])
    }
  },
  methods: {
    formatField (field) {
      const name = this.$t(`components.surveys.steps.abk_survey_step_3_form.fields_names.${decamelize(field)}`)
      let value

      if (field === 'attributes') {
        const attributes = this.surveyAttributes.map(attribute =>
          this.$t(`general.surveys.attributes.${decamelize(attribute)}`)
        )
        value = attributes.length > 0 ? attributes : null
      } else if (field === 'expirationDate') {
        value = this.survey[field] ? moment(this.survey[field]).format('DD.MM.YYYY') : null
      } else if (field === 'recipientGroup') {
        const group = this.recipientGroups.find(e => Number.parseInt(e.value) === Number.parseInt(this.survey.recipientGroupId))
        value = group ? group.text : null
      } else {
        value = this.survey[field]
      }

      return { name: name, value: value || '—' }
    },
    getRecordsByFieldsNames (names) {
      return names.map(name => this.formatField(name))
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .summary-table {
    margin-bottom: $pcg-m-lg;
  }
</style>
