<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="fas fa-user-friends"></i>{{ $t('components.abk_recommended_profiles.title') }}</span>
      <router-link v-if="totalProfiles > profiles.length"
                   class="pcg-link pcg-small"
                   :to="allRecommendedProfilesLink">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <div class="profiles-container">
      <div v-for="profile in profiles" :key="profile.id" class="text-center">
        <pcg-avatar class="profile-avatar mb-1"
                    :img="profile.img"
                    :name="profile.name"
                    :role="$t('components.abk_recommended_profiles.perfect_candidate')"/>
        <span class="position-text"><i class="pcg-icon-check mr-1"></i>{{ profile.preferedPosition }}</span><br/>
        <pcg-btn size="small">{{ $t('general.make_offer') }}</pcg-btn>
      </div>
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkRecommendedProfiles',
  props: {
    profiles: Array,
    allRecommendedProfilesLink: Object,
    totalProfiles: Number
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .profiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-row-gap: 40px;
    grid-column-gap: 15px;
  }
  /deep/ .profile-avatar {
    .abk-avatar-image {
      width: 50%;
      padding-bottom: 50%;
    }
    .abk-name {
      font-size: 1em;
      margin-bottom: .2em;
    }
  }
  .position-text {
    color: $main-active-color;
    font-size: $font-size-s;
    font-weight: 500;
  }
  @media (max-width: $screen-xs-max) {
    .profiles-container {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .position-text {
      color: $main-active-color;
    }
  }
</style>
