<template>
  <div>
    <b-row class="top-bar d-flex justify-content-between">
      <abk-progress-bar class="mb-3" :steps="creatingEventSteps" :current-step="currentStep"/>
      <pcg-btn size="small" variant="additional" @click="saveDraft">
        {{ $t('components.events.abk_event_form.save_working_copy') }}
      </pcg-btn>
    </b-row>
    <transition name="fade" mode="out-in">
      <keep-alive v-if="currentStep === 1">
        <abk-event-step1-form v-model="step1Form" :companies="companies" :errors="errors" @updateImage="updateMainImage" @addSubEvent="addSubEvent"/>
      </keep-alive>
      <keep-alive v-else-if="currentStep === 2">
        <abk-event-step2-form v-model="step2Form" :errors="step2FormFormattedErrors"/>
      </keep-alive>
      <keep-alive v-else-if="currentStep === 3">
        <abk-event-step3-form :quick-info="quickInfoForm"
                                  :attachments="filtredAttachments"
                                  :content="sectionsPreview"/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import AbkProgressBar from '../AbkProgressBar'
import AbkEventStep1Form from './AbkEventStep1Form'
import AbkEventStep2Form from './AbkEventStep2Form'
import AbkEventStep3Form from './AbkEventStep3Form'
import ApiEvents from '../../api/events'
import { mapGetters } from 'vuex'
import ApiCompanies from '../../api/companies'

export default {
  name: 'AbkEventForm',
  components: { AbkEventStep3Form, AbkEventStep2Form, AbkEventStep1Form, AbkProgressBar },
  props: {
    employerId: String,
    eventId: String,
    submitAction: {
      type: Function,
      required: true
    },
    validateAction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      dictionaryAttributes: ['workplace', 'user', 'company', 'instructor'],
      copyEventId: this.$route.query.copy_id,
      step2ErrorKeys: ['eventAttachments', 'eventSections', 'eventCompetences'],
      errors: {},
      companies: [],
      step1Form: {
        company: null,
        user: null,
        place: null,
        address: null,
        eventType: null,
        eventTitle: null,
        workplace: null,
        startDate: null,
        endDate: null,
        cost: null,
        freePlaces: null,
        reservePlaces: null,
        subEvents: [],
        mainImage: null,
        eventMainImage64: null
      },
      step2Form: {
        sections: [{ content: '', key: 1, sectionType: 'text', title: null }],
        attachments: [],
        competences: []
      },
      currentStep: 1
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole', 'userId']),
    ...mapGetters('user', ['company']),
    creatingEventSteps () {
      return [
        this.$t('components.events.abk_event_form.step_1'),
        this.$t('components.events.abk_event_form.step_2'),
        this.$t('components.events.abk_event_form.step_3')
      ]
    },
    isCareerOffice () {
      return this.$store.getters['auth/currentRole'] === 'career_office' || this.$store.getters['auth/currentRole'] === 'counselor'
    },
    step2FormFormattedErrors () {
      const errors = this.getStep2FormErrorsObject()
      this.step2FormErrors.forEach(key => {
        const splittedKey = key.split('.')
        if (!errors[splittedKey[0]][splittedKey[1]]) { errors[splittedKey[0]][splittedKey[1]] = {} }
        if (splittedKey[0] === 'eventAttachments' || splittedKey[0] === 'eventCompetences') {
          errors[splittedKey[0]][splittedKey[1]] = this.errors[key]
        } else {
          errors[splittedKey[0]][splittedKey[1]][splittedKey[2]] = this.errors[key]
        }
      })

      return errors
    },
    step2FormErrors () {
      return Object.keys(this.errors).filter(key => this.step2ErrorKeys.includes(key.split('.')[0]))
    },
    formattedForm () {
      return {
        event: {
          step: this.currentStep,
          ...this.formattedStep1Form,
          eventSections: this.formattedSections,
          eventAttachments: this.formattedAttachments,
          eventCompetences: this.formattedCompetences
        }
      }
    },
    formattedStep1Form () {
      const newForm = { ...this.step1Form }
      this.dictionaryAttributes.forEach(attribute => {
        delete newForm[attribute]
        if (this.step1Form[attribute]) {
          newForm[`${attribute}Id`] = this.step1Form[attribute].value
        }
      })
      return newForm
    },
    formattedAttachments () {
      let attachments = this.step2Form.attachments
      if (this.copyEventId) {
        attachments = attachments.filter(attachment => !attachment._destroy)
      }
      return attachments.map(({ key, ...rest }) => { return { ...rest } })
    },
    formattedSections () {
      let sections = this.step2Form.sections
      if (this.copyEventId) {
        sections = sections.filter(section => !section._destroy)
      }

      return sections.map(({ key, fileName, imageFile, imageUrl, ...form }, index) => {
        return { sort: index, ...form }
      })
    },
    formattedCompetences () {
      const competences = this.step2Form.competences
      return competences.map(competence => {
        return { competenceId: competence.value }
      })
    },
    quickInfoForm () {
      const newForm = { ...this.step1Form }
      this.dictionaryAttributes.forEach(attribute => {
        const attributeObject = this.step1Form[attribute]
        if (attributeObject) {
          newForm[attribute] = { value: attributeObject.text }
        }
      })
      let subEvent = newForm.subEvents[0]
      newForm.subEvents.forEach(sub => {
        if (Date.parse(sub.startDate) < Date.parse(subEvent.startDate)) {
          subEvent = sub
        }
      })
      const competences = []
      this.step2Form.competences.forEach(competence => {
        competences.push({ name: competence.text })
      })
      newForm.competences = competences
      newForm.startDate = subEvent.startDate
      newForm.endDate = subEvent.endDate
      newForm.registrationStart = subEvent.registrationStart
      newForm.registrationEnd = subEvent.registrationEnd
      newForm.reservePlaces = subEvent.reservePlaces
      if (subEvent.currentlyReservePlaces) {
        newForm.currentlyReservePlaces = subEvent.currentlyReservePlaces
      }
      newForm.freePlaces = subEvent.freePlaces
      if (subEvent.currentlyFreePlaces) {
        newForm.currentlyFreePlaces = subEvent.currentlyFreePlaces
      }
      return newForm
    },
    sectionsPreview () {
      return this.step2Form.sections.filter(section => !section._destroy)
    },
    filtredAttachments () {
      return this.step2Form.attachments.filter(section => !section._destroy)
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office' || this.currentRole === 'counselor'
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    }
  },
  watch: {
    currentStep () {
      this.setFooterNavbarItems()
    },
    company: {
      deep: true,
      handler () {
        this.setUser()
      }
    }
  },
  mounted () {
    this.getEvent()
    if (this.isCareerOffice) { this.getCompanies() }
    this.setUser()
    this.setFooterNavbarItems()
  },
  methods: {
    updateMainImage (value) {
      this.step1Form.mainImage = value
    },
    addSubEvent () {
      const subEvent = {
        id: null,
        eventId: this.step1Form.id,
        startDate: null,
        endDate: null,
        registrationStart: Date.now(),
        registrationEnd: null,
        reminderContent: null,
        reminderTopic: null,
        freePlaces: null,
        reservePlaces: null,
        status: 'draft'
      }
      this.step1Form.subEvents.push(subEvent)
    },
    setFooterNavbarItems () {
      const items = []

      items.push({
        show: this.currentStep > 1,
        variant: 'additional',
        class: 'abk-footer-nav-btn ml-0',
        type: 'button',
        text: this.$t('general.steps.previous'),
        action: () => { --this.currentStep }
      })
      items.push({
        show: this.currentStep < 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.steps.next'),
        action: this.goToNextStep
      })
      items.push({
        show: this.currentStep === 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.save'),
        action: this.save
      })
      items.push({
        show: this.currentStep === 3,
        class: 'abk-footer-nav-btn mr-0',
        type: 'button',
        text: this.showCareerOfficeActions
          ? this.$t('general.steps.publish')
          : this.$t('general.steps.send_for_publication'),
        action: this.publish
      })

      this.$store.dispatch('footerNavbar/setItems', items)
    },
    setUser () {
      if (this.showEmployerActions) {
        this.step1Form.user = { value: this.userId }
        if (this.company) {
          this.step1Form.company = { value: this.company.id }
        } else {
          this.step1Form.company = { value: null }
        }
      }
    },
    saveDraft () {
      this.submitAction({ event: { ...this.formattedForm.event, status: 'draft' } }, this.eventId)
        .then(() => {
          this.$toastr.s(this.$t('components.events.abk_event_form.working_copy_saved'))
          this.$router.push({ name: 'events_list_all_tab_path' })
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.errors = error.response.data
        })
    },
    save () {
      this.submitAction({ event: { ...this.formattedForm.event, status: 'recent' } }, this.eventId)
        .then(() => {
          this.$toastr.s(this.$t('components.events.abk_event_form.saved'))
          this.$router.push({ name: 'events_list_all_tab_path' })
        })
        .catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    getCompanies () {
      ApiCompanies.getCompanies({ status: ['accepted'] })
        .then(response => {
          if (this.step1Form.company === null) {
            const co = response.data.data.find(({ attributes }) => {
              return attributes.careerOffice
            })
            this.step1Form.company = { text: co.attributes.name, value: co.id }
            this.step1Form.user = { value: this.userId }
          }
          this.companies = response.data.data.map((el) => {
            return { value: el.id, text: el.attributes.name }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    goToNextStep () {
      this.validateAction(this.formattedForm, this.eventId)
        .then(() => {
          this.clearErrors()
          ++this.currentStep
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    publish () {
      this.submitAction({ event: { ...this.formattedForm.event, status: 'for_acceptance' } }, this.eventId)
        .then(response => {
          this.$toastr.s(this.$t('components.events.abk_event_form.event_updated'))
          this.$router.push({
            name: 'sub_events_show_path',
            params: {
              id: response.data.data.attributes.subEvents[0].data.id
            }
          })
        }).catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    clearErrors () {
      this.errors = {}
    },
    getStep2FormErrorsObject () {
      const errors = {}
      this.step2ErrorKeys.forEach(key => { errors[key] = [] })
      return errors
    },
    getEvent () {
      if (!this.eventId && !this.copyEventId) {
        this.addSubEvent()
        return
      }
      ApiEvents.getEvent(this.eventId || this.copyEventId)
        .then(response => {
          this.setEvent(response.data.data.attributes)
          if (this.copyEventId) {
            this.step1Form.user.value = this.userId
            this.step1Form.subEvents = []
            if (this.currentRole === 'counselor') {
              this.step1Form.eventType = 'counseling'
            }
          }
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.events.abk_event_form.event_download_error'))
        })
    },
    setEvent ({ eventSections, eventAttachments, competences, subEvents, ...event }) {
      this.dictionaryAttributes.forEach(attribute => {
        if (event[attribute]) {
          event[attribute] = {
            text: event[attribute].value,
            value: event[attribute].id.toString()
          }
        }
      })
      let subs = subEvents.map((sub) => {
        const attributes = sub.data.attributes
        return {
          id: this.copyEventId ? null : sub.data.id,
          eventId: attributes.eventId,
          startDate: attributes.startDate && moment(attributes.startDate),
          endDate: attributes.endDate && moment(attributes.endDate),
          registrationStart: attributes.registrationStart && moment(attributes.registrationStart),
          registrationEnd: attributes.registrationEnd && moment(attributes.registrationEnd),
          reminderContent: attributes.reminderContent,
          reminderTopic: attributes.reminderTopic,
          freePlaces: attributes.freePlaces,
          reservePlaces: attributes.reservePlaces,
          currentlyFreePlaces: attributes.currentlyFreePlaces,
          currentlyReservePlaces: attributes.currentlyReservePlaces,
          status: this.copyEventId ? 'draft' : attributes.status
        }
      })
      if (subs.length === 0) {
        subs = [{
          id: null,
          eventId: event.id,
          startDate: null,
          endDate: null,
          registrationStart: Date.now(),
          registrationEnd: null,
          reminderContent: null,
          reminderTopic: null,
          freePlaces: null,
          reservePlaces: null,
          status: 'draft'
        }]
      }
      Object.assign(this.step1Form, {
        ...event,
        startDate: event.startDate && moment(event.startDate),
        endDate: event.endDate && moment(event.endDate),
        cost: event.cost,
        subEvents: subs
      })
      Object.assign(this.step2Form, {
        sections: eventSections.map(section => {
          return {
            id: section.id,
            content: section.content,
            key: section.sort,
            sectionType: section.sectionType,
            title: section.title,
            imageUrl: section.image ? section.image.url : null
          }
        }),
        attachments: eventAttachments,
        competences: competences.map(competence => {
          return {
            text: competence.name,
            value: competence.id.toString()
          }
        })
      })
      this.$store.dispatch('header/setObjectName', this.step1Form.eventTitle)
    }
  }
}
</script>

<style scoped lang="scss">
  .top-bar {
    margin-bottom: 1.7rem;
  }
  .navigation-buttons-container {
    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>
