<template>
  <div>
    <div v-if="notFound">
      <abk-page-not-found></abk-page-not-found>
    </div>
    <div v-else>
      <hr class="pcg-header-hr"/>
      <b-row>
        <b-col v-if="cv.name" cols="12">
          <h2 class="pcg-title-l mt-0 cv-name" :class="{ 'small-margin': showApplicationAttachments }">{{ cv.name }}</h2>
        </b-col>
        <b-col cols="12" lg="7" xl="8">
          <div v-if="showApplicationJobTitle" class="application">
            {{ $t('views.offers.offers_show.applying_on') }}
            <router-link :to="{ name: 'offers_employer_show_path', params: { id: application.offerId } }"
                         class="applications-offer">
              {{ application.offerJobTitle }}
            </router-link>
          </div>
          <abk-attachments-box v-if="showApplicationAttachments" :attachments="application.offerApplicationAttachments"/>

          <!--        <div class="matching-profile">-->
          <!--          <i class="pcg-icon-check matching-profile-check"></i>-->
          <!--          {{ $t('views.employer_cv.matching_profile') }}-->
          <!--          <router-link class="matching-offer-name text-nowrap" :to="fitting_offer.path">-->
          <!--            {{ fitting_offer.name }}-->
          <!--          </router-link>-->
          <!--          <pcg-btn class="make-offer-btn my-0" size="small">{{ $t('general.make_offer') }}</pcg-btn>-->
          <!--        </div>-->
          <abk-cv-main-section :content="cv"/>
          <abk-competences-box v-if="env && env.competenceFeature !== 'off'" :items="competences" :headers="competencesHeaders">
            <template v-slot:header>
              <i class="pcg-icon-check-shield"></i>{{ $t('components.abk_competences_box.title') }}
            </template>
          </abk-competences-box>
        </b-col>
        <b-col cols="12" lg="5" xl="4">
          <div class="text-center">
            <pcg-btn @click="getCurriculumVitaePdf" size="small">
              <i v-if="pdfInProgress" class="fa fa-spinner fa-spin"/>
              <span :class="{'ml-2': pdfInProgress }">{{ $t('views.curriculum_vitaes.curriculum_vitae_show.download_pdf') }}</span>
            </pcg-btn>
          </div>
          <b-row class="contact-row d-flex align-items-center">
            <pcg-avatar class="avatar col-10 col-sm-4 col-lg-12 col-xl-10 m-auto m-sm-0 mx-lg-auto mb-lg-4"
                        no-border
                        :img="cv.avatar"/>
            <abk-contact inner-header class="contact col-12 col-sm-8 col-lg-12" :firm-info="cv.contact"/>
          </b-row>
          <abk-competences-box v-if="env && env.competenceFeature !== 'off'" :items="cvCustomCompetences" :headers="cvCustomCompetencesHeaders">
            <template v-slot:header>
              <i class="pcg-icon-graduate"></i>{{ $t('views.offers.offers_show.additional_skills') }}
            </template>
          </abk-competences-box>
          <abk-cv-hobby-box v-if="hobby" :content="hobby"/>
          <!--        <div class="text-center">-->
          <!--          <router-link :to="similarProfilesPath" tag="button" class="pcg-button pcg-additional-btn pcg-small-btn">-->
          <!--            <span class="font-weight-bold">{{ $t('views.employer_cv.show_similar_profiles') }}</span>-->
          <!--          </router-link>-->
          <!--        </div>-->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbkCvMainSection from '../../components/AbkCvMainSection'
import AbkContact from '../../components/AbkContact'
import ApiCurriculumVitaes from '../../api/curriculum_vitaes'
import AbkCvHobbyBox from '../../components/AbkCvHobbyBox'
import { mapGetters } from 'vuex'
import ApiOfferApplications from '../../api/offer_applications'
import AbkAttachmentsBox from '../../components/AbkAttachmentsBox'
import AbkCompetencesBox from '../../components/AbkCompetencesBox'
import AbkPageNotFound from '../../components/AbkPageNotFound'

export default {
  name: 'EmployerCv',
  components: { AbkPageNotFound, AbkCompetencesBox, AbkAttachmentsBox, AbkCvHobbyBox, AbkContact, AbkCvMainSection },
  data () {
    return {
      application: null,
      fitting_offer: { name: 'Junior Brand Manager', path: { name: 'offer_path', params: { id: '123' } } },
      similarProfilesPath: { name: 'root_path' },
      hobby: null,
      cv: {
        id: null,
        name: null,
        avatar: null,
        intro: null,
        education: [],
        experience: [],
        contact: {
          phone: null,
          email: null
        }
      },
      cvCustomCompetences: [],
      competences: [],
      cvCustomCompetencesHeaders: {
        foreign_language: this.$t('views.offers.offers_show.custom_competences_headers.foreign_language'),
        driving_license: this.$t('views.offers.offers_show.custom_competences_headers.driving_license'),
        other: this.$t('views.offers.offers_show.custom_competences_headers.other')
      },
      competencesHeaders: {
        career_path: this.$t('views.offers.offers_show.competences_headers.career_path'),
        event: this.$t('views.offers.offers_show.competences_headers.event'),
        user_assignment: this.$t('views.offers.offers_show.competences_headers.user_assignment')
      },
      customCompetencesOrder: ['foreign_language', 'driving_license', 'other'],
      competencesOrder: ['career_path', 'event', 'user_assignment'],
      notFound: false,
      pdfInProgress: false
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.employer_cv.title')
      })
    },
    getCurriculumVitae () {
      const action = this.cv.id ? 'getCurriculumVitae' : 'getMyCurriculumVitae'
      ApiCurriculumVitaes[action](this.cv.id)
        .then(response => {
          if (response && response.data) {
            this.updateCv(response.data)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            this.notFound = true
          } else {
            this.$toastr.e(error.response)
          }
        })
    },
    getCurriculumVitaePdf () {
      // const action = this.cv.id ? 'getCurriculumVitaePdf' : 'getMyCurriculumVitaePdf'
      const action = 'getCurriculumVitaePdf'
      this.pdfInProgress = true
      ApiCurriculumVitaes[action](this.cv.id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'cv.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          this.$toastr.e(error.response)
        }).finally(() => {
          this.pdfInProgress = false
        })
    },
    updateCv ({ included, data: { id, attributes } }) {
      const { firstName, lastName, avatarUrl, introduction, phone, email, privateEmail, websites, additionalInformation } = attributes
      const grouped = _.groupBy(included, 'type')
      const cvEducation = this.getSortedInclusion(grouped.cv_education)
      const cvExperience = this.getSortedInclusion(grouped.cv_experience)
      const cvCertificate = this.getSortedInclusion(grouped.cv_certificate)
      const cvCustomCompetence = this.getSortedInclusion(grouped.cv_custom_competence)
      const cvCompetence = grouped.cv_competence || []

      this.hobby = additionalInformation
      this.cv = Object.assign(this.cv, {
        id: id,
        name: _.compact([firstName, lastName]).join(' '),
        avatar: avatarUrl,
        intro: introduction,
        contact: {
          phone: phone,
          email: email,
          privateEmail: privateEmail,
          websites: websites
        },
        education: cvEducation.map(education => {
          const { id, attributes: { dateStart, dateEnd, ...rest } } = education

          return {
            id: id,
            dateFrom: dateStart,
            dateTo: dateEnd,
            ...rest
          }
        }),
        experience: cvExperience.map(experience => {
          const { id, attributes: { dateStart, dateEnd, ...rest } } = experience

          return {
            id: id,
            dateFrom: dateStart,
            dateTo: dateEnd,
            ...rest
          }
        }),
        certificates: cvCertificate.map(certificate => {
          const { id, attributes: { dateEnd, ...rest } } = certificate
          return {
            id: id,
            dateFrom: dateEnd,
            ...rest
          }
        })
      })
      this.cvCustomCompetences = Object.entries(_.groupBy(cvCustomCompetence.filter(customCompetence => {
        return customCompetence.attributes.customCompetence
      }).map(customCompetence => {
        const { id, attributes } = customCompetence
        return {
          id: id,
          type: attributes.customCompetenceType,
          name: attributes.customCompetence.value
        }
      })
      , 'type')).sort((competenceTypeA, competenceTypeB) => {
        return this.customCompetencesOrder.indexOf(competenceTypeA[0]) - this.customCompetencesOrder.indexOf(competenceTypeB[0])
      })
      this.competences = Object.entries(_.groupBy(cvCompetence.filter(cvCompetence => {
        return cvCompetence.attributes.competence &&
          Object.keys(this.competencesHeaders).includes(cvCompetence.attributes.source)
      }).map(cvCompetence => {
        const { id, attributes } = cvCompetence
        return {
          id: id,
          type: attributes.source,
          name: attributes.competence.value
        }
      })
      , 'type')).sort((competenceTypeA, competenceTypeB) => {
        return this.competencesOrder.indexOf(competenceTypeA[0]) - this.competencesOrder.indexOf(competenceTypeB[0])
      })

      if (this.$route.name !== 'curriculum_vitae_my_show_path') {
        this.$store.dispatch('header/setObjectName', this.cv.name)
      }
    },
    getSortedInclusion (data) {
      return data ? _.sortBy(data, [e => e.attributes.sort]) : []
    },
    setCurriculumVitaeId () {
      this.cv.id = this.$route.params.id
    },
    getApplication () {
      const offerApplicationId = this.$route.query.offerApplicationId

      if (!(this.showEmployerActions && offerApplicationId)) { return }

      ApiOfferApplications.getOfferApplication(offerApplicationId)
        .then(({ data: { data } }) => {
          this.application = { ...data.attributes, offerId: data.relationships.offer.data.id }
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('environment', ['env']),
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showApplicationAttachments () {
      return this.showEmployerActions &&
        this.application &&
        this.application.offerApplicationAttachments &&
        this.application.offerApplicationAttachments.length > 0
    },
    showApplicationJobTitle () {
      return this.showEmployerActions && this.application && this.application.offerJobTitle
    }
  },
  mounted () {
    this.setCurriculumVitaeId()
    this.getCurriculumVitae()
    this.getApplication()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .button-spinner {
    height: 15px !important;
    width: 15px !important;
  }
  .pcg-title-l {
    margin-bottom: $pcg-m-xxl;
  }
  .box-container {
    padding: 0;
  }
  .offer-name {
    font-size: $pcg-title-font-size;
    font-weight: lighter;
    color: $main-color;
  }
  .matching-profile {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-dark-gray;
    margin-bottom: 1.2em;
  }
  .matching-profile-check {
    color: $main-active-color;
    margin-right: .9em;
  }
  .matching-offer-name {
    color: $main-active-color;
    font-weight: bold;
  }
  .make-offer-btn {
    font-size: 1em;
  }
  .contact {
    /deep/ .content {
      font-size: $font-size-s;
      font-weight: 400;
      margin-bottom: 0;
      line-height: 2.5!important;
    }
  }
  .avatar {
    padding: 0 2.6em .571em;
    margin-bottom: $font-size-xl;
  }
  .contact-row {
    padding: 0 $grid-gutter-width / 2;
  }
  .application {
    font-size: $font-size-m;
    color: $pcg-gray;
    font-weight: 500;
    margin-bottom: $pcg-m-lg;
  }
  .applications-offer {
    color: $main-color;
    margin-left: $pcg-m-sm;
    font-weight: bold;
  }
  .pcg-title-l.small-margin {
    margin-bottom: $pcg-m-lg;
  }
  .cv-name {
    font-weight: 800;
  }

</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .offer-name {
      color: $main-color;
    }
    .matching-profile {
      color: $pcg-dark-gray;
    }
    .matching-profile-check {
      color: $main-active-color;
    }
    .matching-offer-name {
      color: $main-active-color;
    }
    .application {
      color: $pcg-gray;
    }
    .applications-offer {
      color: $main-color;
    }
  }
</style>
