<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="pcg-icon-buildings-alt"></i>{{ $t('components.abk_new_employers_box.title') }}</span>
      <!--<pcg-sort-btn class="sort-btn w-auto" :options="sortOptions" :value="order" @input="changeOrder"/>-->
      <router-link v-if="employers && totalEmployers > employers.length"
                   class="pcg-link pcg-small"
                   :to="{ name: 'companies_index_for_acceptance_tab_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <ul v-if="employers && employers.length" class="p-0 m-0">
      <li class="employer d-flex align-items-center" v-for="employer in employers" :key="employer.id">
        <i v-if="employer.status === 'accepted'" class="pcg-icon-check"/>
        <div class="ml-2 offer-info">
          <router-link class="company-name" :to="{ name: 'company_show_path', params: { id: employer.id } }">
            {{ employer.name }}
          </router-link>
        </div>
        <pcg-btn-symbol v-if="waitingForAcceptance(employer.status)"
                        class="ml-auto"
                        variant="success"
                        icon-class="pcg-icon-check-alt"
                        :tooltip="$t('general.accept')"
                        @click="$emit('acceptEmployer', employer.id)"/>
      </li>
    </ul>
    <span v-else class="pcg-no-records">{{ $t('components.abk_new_employers_box.no_records') }}</span>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkNewEmployersBox',
  props: {
    employers: Array,
    totalEmployers: Number
  },
  data () {
    return {}
  },
  methods: {
    waitingForAcceptance (status) {
      return ['saved'].includes(status)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .employer {
    list-style: none;
    padding: .2em 0;
    align-items: center;
    min-height: 57px;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
  }
  .pcg-icon-check {
    color: $pcg-green;
    font-size: $font-size-s;
    line-height: 3;
    margin-right: .7em;
  }
  .company-name {
    font-size: $font-size-s;
    font-weight: 500;
    color: $main-color;
    text-transform: uppercase;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .employer {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .pcg-icon-check {
      color: $pcg-green;
    }
    .company-name {
      color: $main-color;
    }
  }
</style>
