<template>
  <div>
    <div v-if="$slots.header" class="pcg-box-inner-header">
      <slot name="header"></slot>
    </div>
    <ul class="timeline">
      <li v-for="(item, index) in items"
          :key="`period-${index}`"
          class="period"
          :class="{ 'hide-date-range': hideDateRange }">
        <div class="period-content">
          <template v-if="!hideDateRange">
            <label class="date" v-if="!hideDateRange" v-html="dateRange(item)"/>
            <br/>
          </template>
          <div class="content">
            <slot v-bind="item"></slot>
          </div>
          <abk-attachment v-if="item.filename"
                          @click="downloadAttachment(item.id, item.filename)"
                          class="attachment">
            {{ item.filename }}
          </abk-attachment>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import AbkAttachment from './offers/AbkAttachment'
import ApiCurriculumVitaes from '../api/curriculum_vitaes'

export default {
  name: 'AbkTimeline',
  components: { AbkAttachment },
  props: {
    items: Array,
    // https://momentjs.com/docs/#/displaying/format/
    dateFormat: {
      type: String,
      default: 'MMMM YYYY'
    },
    hideDateRange: Boolean,
    type: String,
    cvId: String
  },
  data () {
    return {
      link: null
    }
  },
  methods: {
    dateRange ({ dateFrom, dateTo, finished }) {
      let formattedDateTo
      if (this.formatDate(dateFrom)) {
        formattedDateTo = this.formatDate(dateTo)
      } else if (!finished) {
        formattedDateTo = `<span class="in-progress">${this.$t('components.abk_timeline.in_progress')}</span>`
      }
      return _.compact([this.formatDate(dateFrom), formattedDateTo]).join(' - ')
    },
    formatDate (date) {
      if (date) {
        const momentDate = moment(date)
        return momentDate.isValid() ? momentDate.format(this.dateFormat) : null
      }

      return null
    },
    downloadAttachment (id, filename) {
      ApiCurriculumVitaes.getSectionAttachment(this.cvId, this.type, id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .timeline {
    padding-left: 20px;
    position: relative;
    margin: 0;
  }
  .period {
    position: relative;
    z-index: 0;
    padding-left: $font-size-s;
    padding-bottom: $font-size-xl;
    list-style: none;
    &:last-child {
      padding-bottom: 0;
    }
    .date {
      font-weight: 400;
      color: $pcg-gray;
      margin-bottom: 10px;
    }
    .content {
      color: $pcg-dark-gray;
      font-size: $font-size-m;
      font-weight: 400;
      line-height: 1.3;
    }
    &:before {
      content: $pcg-icon-bullet;
      color: $main-color;
      left: -20px;
      position: absolute;
      font-size: 40px;
    }
    &.hide-date-range {
      &:before {
        top: 4px;
      }
    }
  }
  .period-content:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -14px;
    height: 100%;
    border-left: 2px solid $pcg-lightest-gray;
    transform: translateY(10px);
    z-index: -10;
  }
  /deep/ .in-progress {
    color: $pcg-white;
    background-color: $pcg-light-gray;
    padding: 4px 10px;
    border-radius: 9px;
    font-size: $font-size-xs;
  }
  .attachment {
    margin-top: $pcg-m-sm;
    padding: 0;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .period {
      .date {
        color: $pcg-gray;
      }
      .content {
        color: $pcg-dark-gray;
      }
      &:before {
        color: $main-color;
      }
    }
    .period-content:before {
      border-left: 2px solid $pcg-lightest-gray;
    }
    /deep/ .in-progress {
      color: $pcg-white;
      background-color: $pcg-light-gray;
    }
  }
</style>
