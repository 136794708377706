<template>
  <b-modal v-model="model" footer-class="d-flex justify-content-between abk-modal-footer" centered hide-header content-class="abk-modal-content">
    <h2 class="title">{{ $t('components.offers.abk_reject_offer_modal.title') }}</h2>
    <div class="mt-5">
      <pcg-text-input class="rejection-textarea m-xl-0"
                      type="textarea"
                      required
                      :label="$t('components.offers.abk_reject_offer_modal.rejection_reason')"
                      :error-text="getError('rejectionReason')"
                      :show-error="errorExist('rejectionReason')"
                      v-model="rejectionReason"/>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn @click="cancel" variant="additional" class="mr-3">{{ $t('general.cancel') }}</pcg-btn>
      <pcg-btn @click="submit" class="mr-3">{{ $t('general.reject') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>
import ApiOffer from '../../api/offers'
export default {
  name: 'AbkRejectOfferModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    offerId: {
      type: String
    }
  },
  data () {
    return {
      rejectionReason: null,
      errors: {}
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.errors = {}
        this.rejectionReason = null
      }
    }
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    getError (field) {
      return this.errorExist(field) ? this.errors[field].join(', ') : ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    cancel () {
      this.$emit('input', false)
    },
    submit () {
      this.errors = {}
      ApiOffer.rejectOffer(this.offerId, {
        offer: {
          rejectionReason: this.rejectionReason
        }
      })
        .then(response => {
          this.rejectionReason = null
          this.$emit('reload', false)
          this.$emit('input', false)
        }).catch(error => {
          this.errors = error.response.data
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  /deep/ .modal-footer {
    padding-top: 17px;
    padding-bottom: 10px;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }

  .title {
    font-size: $font-size-xl;
    font-weight: bold;
    text-align: center;
    color: $main-color;
    margin-bottom: 10px;
  }

  .rejection-textarea /deep/ textarea {
    min-height: 150px;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .title {
      color: $main-color;
    }
  }
</style>
