<template>
  <div>
    <div class="title">
      {{ $t('components.surveys.abk_survey_question_fields_form.title_text') }}
    </div>

    <draggable :value="value"
               @input="update($event)"
               animation="200">
      <transition-group name="slide" mode="out-in">
        <div v-for="(questionField, index) in value" :key="questionField.tmpId">
          <abk-survey-question-field-form :value="questionField"
                                          v-show="!questionField._destroy"
                                          :errors="errors"
                                          :question-type="questionType"
                                          :parent-error-path="errorPath"
                                          @open="$emit('open')"
                                          @remove="remove(questionField, index)"
                                          @input="updateSection($event, index)"/>
        </div>
      </transition-group>
    </draggable>

    <div v-if="errorExist('questionFields') && !visibleQuestionFieldsLength" class="pcg-error-messages mb-2">
      {{ getError('questionFields') }}
    </div>

    <div class="actions" v-if="canAddAnswer">
      <button class="btn" @click="addAnswer">
        {{ $t('components.surveys.abk_survey_question_fields_form.add_answer') }}
      </button>
      <button class="btn" @click="addOpenAnswer" v-if="canAddOpenAnswer">
        {{ $t('components.surveys.abk_survey_question_fields_form.add_open_answer') }}
      </button>
    </div>

  </div>
</template>

<script>
import AbkSurveyQuestionFieldForm from './AbkSurveyQuestionFieldForm'
import draggable from 'vuedraggable'

export default {
  name: 'AbkSurveyQuestionFieldsForm',
  components: { AbkSurveyQuestionFieldForm, draggable },
  props: {
    value: Array,
    errors: Object,
    questionType: String,
    parentErrorPath: String
  },
  data: function () {
    return {}
  },
  computed: {
    visibleQuestionFieldsLength () {
      return this.value.filter(questionField => !questionField._destroy).length
    },
    canAddAnswer () {
      return (this.questionType === 'checkbox' || this.questionType === 'radiobutton')
    },
    canAddOpenAnswer () {
      return this.canAddAnswer && !this.value.find(questionField => {
        return questionField.fieldType === 'text' && !questionField._destroy
      })
    },
    errorPath () {
      return `${this.parentErrorPath}.questionFields`
    }
  },
  methods: {
    errorExist (field) {
      return !!(this.errors && this.errors[`${this.parentErrorPath}.${field}`])
    },
    getError (field) {
      if (this.errorExist(field)) {
        this.$emit('open')
        return this.errors[`${this.parentErrorPath}.${field}`].join(', ')
      }
      return ''
    },
    update (newArray) {
      console.log(newArray)
      this.$emit('input', newArray)
    },
    updateSection (newSection, index) {
      const copy = [...this.value]
      copy.splice(index, 1, newSection)
      this.update(copy)
    },
    remove (questionField, index) {
      if (questionField.edited || !questionField.isNew) {
        this.$swal({
          title: this.$t('general.are_you_sure'),
          showCancelButton: true,
          confirmButtonText: this.$t('general.delete'),
          cancelButtonText: this.$t('general.cancel')
        }).then(result => {
          if (result.value) {
            this.removeQuestionField(questionField, index)
          }
        })
      } else {
        this.removeQuestionField(questionField, index)
      }
    },
    removeQuestionField (questionField, index) {
      if (Number.isInteger(questionField.id)) {
        const copy = [...this.value]
        copy.splice(index, 1, { ...questionField, _destroy: true })
        this.update(copy)
      } else {
        const copy = [...this.value]
        copy.splice(index, 1)
        this.update(copy)
      }
      this.$emit('questionFieldRemoved', questionField.id || questionField.tmpId)
    },
    addAnswer () {
      this.addAnswerToQuestion(this.questionType)
    },
    addOpenAnswer () {
      this.addAnswerToQuestion('text')
    },
    addAnswerToQuestion (questionType) {
      const copy = [...this.value]
      this.$store.dispatch('tmpIds/generateTmpId').then(tmpId => {
        copy.push({ fieldType: questionType, tmpId: tmpId, isNew: true, edited: false })
        this.update(copy)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .title {
    color: $main-color;
    font-weight: bolder;
    margin-bottom: 10px;
  }
  .actions {
    padding-left: 38px;
    .btn {
      color: $main-active-color;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast{
    .title {
      color: $main-color;
    }
    .actions {
      .btn {
        color: $main-active-color;
      }
    }
  }
</style>
