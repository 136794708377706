<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-wallet"></i>{{ $t('components.abk_offer_salary_box.title') }}
    </div>
    <div class="salary">
      {{ salary }}
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkOfferSalaryBox',
  props: {
    salaryFrom: Number,
    salaryTo: Number,
    currency: {
      type: String,
      default: 'zł'
    }
  },
  computed: {
    salary () {
      return `${[this.salaryFrom, this.salaryTo].filter(salary => Boolean(salary)).join(' - ')} ${this.currency}`
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .salary {
    text-align: center;
    color: $main-color;
    font-weight: 500;
    font-size: $font-size-l;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .salary {
      color: $main-color;
    }
  }
</style>
