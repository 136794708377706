<template>
  <div>
    <abk-list-of-employers :list-of-employers="favoriteOffers"
                           :linkPath="'offers_show_path'"
                           :no-records-text="$t('views.favorite_offers.favorite_offers_index.no_records')"
                           :show-loader="showLoader">
      <template v-slot="{ user_id }">
        <pcg-btn-symbol icon-class="pcg-icon-trash-can"
                        class="ml-auto abk-delete-btn"
                        :tooltip="$t('general.remove_from_favorites')"
                        @click="removeFromFavorites(user_id)"/>
      </template>
    </abk-list-of-employers>
  </div>
</template>

<script>
import AbkListOfEmployers from '../../components/AbkListOfEmployers'
import ApiOffers from '../../api/offers'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'favorite-offers-index',
  components: { AbkListOfEmployers },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      favoriteOffers: []
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.getfavoriteOffers()
    this.$store.dispatch('user/getCatalogOfferCounters')
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.favorite_offers.favorite_offers_index.title')
      })
    },
    getfavoriteOffers () {
      ApiOffers.getfavoriteOffers()
        .then(response => {
          this.setFavoriteOffers(response.data)
        })
        .catch(error => {
          this.$toastr.e(this.$t('views.favorite_offers.favorite_offers_index.offers_fetching_error'))
          console.error(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setFavoriteOffers ({ data, included }) {
      this.favoriteOffers = data.map(favorite => {
        const offer = included.find(offer => offer.id === favorite.relationships.offer.data.id)
        return {
          user_id: offer.id,
          company: {
            id: offer.attributes.company ? offer.attributes.company.id : null,
            name: offer.attributes.company ? offer.attributes.company.name : null,
            employerType: offer.attributes.company ? offer.attributes.company.employerType : null,
            employerTypeText: offer.attributes.company ? offer.attributes.company.employerTypeText : null,
            employerTypeLogo: offer.attributes.company ? offer.attributes.company.employerTypeLogo : null,
            employerTypeLogoNegative: offer.attributes.company ? offer.attributes.company.employerTypeLogoNegative : null
          },
          offerType: offer.attributes.offerType,
          avatar: offer.attributes.company ? offer.attributes.company.logo : null,
          name: offer.attributes.jobTitle,
          date: this.formattedDate(favorite.attributes.createdAt)
        }
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    removeFromFavorites (offerId) {
      ApiOffers.removeFromFavorites(offerId)
        .then(() => {
          this.favoriteOffers = this.favoriteOffers.filter(offer => offer.user_id !== offerId)
          this.$store.dispatch('user/getCatalogOfferCounters')
        })
        .catch(() => {
          this.$toastr(this.$t('general.unexpected_error'))
        })
    }
  }
}
</script>
