<template>
  <pcg-box>
    <b-row>
      <b-col class="note-content d-flex flex-column">
        <div class="note-header d-flex align-items-center">
          <div class="d-flex align-items-baseline">
            <span v-if="note.edited" class="note-date">{{ $t('general.edited') }} {{ note.editedDate }}</span>
            <span v-else class="note-date">{{ note.date }}</span>
            <span class="note-creator">{{ note.creatorName }}</span>
            <router-link v-if="allowEdit()"
                         :to="{ name: 'student_notes_edit_path', params: { id: note.id } }"
                         class="action-btn">
              <i class="pcg-icon-edit action-icon" v-b-tooltip :title="$t('general.edit')"/>
            </router-link>
            <span v-if="allowDelete()"
                  class="delete-btn"
                  v-b-tooltip
                  :title="$t('general.delete_note')"
                  @click="deleteNote">
              <i class="pcg-icon-cross"/>
            </span>
          </div>
        </div>
        <div v-html="note.content"></div>
        <br/>
        <div class="d-flex flex-wrap mt-auto">
        </div>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import ApiStudents from '../../api/students'

export default {
  name: 'AbkStudentNotes',
  props: {
    note: Object,
    studentId: String,
    currentUser: String
  },
  methods: {
    allowEdit () {
      return this.note.creatorId === this.currentUser
    },
    allowDelete () {
      return this.note.creatorId === this.currentUser
    },
    deleteNote () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete')
      }).then(result => {
        if (result.value) {
          ApiStudents.deleteNote(this.note.id)
              .then(() => {
                this.$toastr.s(this.$t('components.abk_student_notes.note_deleted'))
                this.$emit('deleted')
              })
              .catch(error => {
                console.error(error)
                this.$toastr.e(this.$t('components.abk_student_notes.note_deleted_error'))
              })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars";

/deep/ .box {
  padding-top: $font-size-m !important;
  padding-bottom: $font-size-m !important;
  position: relative;
  overflow: hidden;
}
.note-content {
  min-width: 170px;
}
.note-date {
  color: $pcg-light-gray;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 1rem;
}
.note-creator {
  color: $main-color;
  font-weight: 600;
}
.action-icon {
}
.action-btn {
  color: $main-active-color;
  margin-left: $pcg-m-md;
  margin-right: 1rem;
}
.note-header {
  margin-bottom: $pcg-m-md;
}
.delete-btn {
  color: $pcg-red;
  cursor: pointer;
}
@media(max-width: 423px) {
  .note-date {
    text-align: right;
  }
}
</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .note-date {
    color: $pcg-light-gray;
  }
  .note-creator {
    color: $main-color;
    font-weight: 600;
  }
  .action-btn {
    color: $main-active-color;
  }
  .delete-btn {
    color: $pcg-red;
  }
}
</style>
