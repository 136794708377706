<template>
  <div>
    <b-tabs v-model="tab" @input="changeTab">
      <template v-if="showStudentOrEmployerActions">
        <b-tab :title="$t('views.surveys.surveys_list.recent')"/>
        <b-tab :title="$t('views.surveys.surveys_list.completed')"/>
      </template>
      <template v-else-if="showCareerOfficeActions">
        <b-tab :title="$t('views.surveys.surveys_list.active')"/>
        <b-tab :title="$t('views.surveys.surveys_list.inactive')"/>
      </template>
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SurveysList',
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  data () {
    return {
      tab: 1,
      tabs: []
    }
  },
  methods: {
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab })
      }
    },
    setTabs () {
      if (this.showStudentOrEmployerActions) {
        this.tabs = ['surveys_list_recent_tab_path', 'surveys_list_completed_tab_path']
      } else if (this.showCareerOfficeActions) {
        this.tabs = ['surveys_list_active_tab_path', 'surveys_list_inactive_tab_path']
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showEmployerActions () {
      return this.currentRole === 'employer' || this.currentRole === 'recruiter'
    },
    showStudentOrEmployerActions () {
      return this.showStudentActions || this.showEmployerActions
    },
    showCareerOfficeActions () {
      return ['career_office'].includes(this.currentRole)
    }
  },
  mounted () {
    this.setTabs()
    this.tab = this.tabs.indexOf(this.$route.name)
  }
}
</script>

<style scoped>

</style>
