<template>
  <div class="position-relative">
    <b-tabs v-model="tab" @input="changeTab">
      <b-tab :title="$t('views.competences.competences_list.all')"/>
      <b-tab :title="$t('views.competences.competences_list.connected_with_skills')"/>
      <b-tab :title="$t('views.competences.competences_list.not_connected_with_skills')"/>
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CompetencesList',
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  data () {
    return {
      tab: 1,
      tabs: [
        'competences_list_all_tab_path',
        'competences_list_connected_with_skills_tab_path',
        'competences_list_not_connected_with_skills_tab_path'
      ]
    }
  },
  methods: {
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab })
      }
    }
  },
  mounted () {
    this.tab = this.tabs.indexOf(this.$route.name)
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-message-btn-container {
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-sm-min) {
    .new-message-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>
