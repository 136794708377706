<template>
  <b-row>
    <abk-loader v-if="showLoader" class="with-margins col-12"/>
    <template v-else>
      <abk-managing-offers class="col-12"
                           :offers="offers"
                           :total-offers="totalOffers"
                           :order="orderOffersObject"
                           @changeOrder="changeOffersOrder"/>
      <abk-managing-events-box class="col-12"
                               :events="events"
                               :total-events="totalEvents"
                               :order="orderEventsObject"
                               @changeOrder="changeEventsOrder"/>
      <abk-messages-box class="col-12"
                        :messages="messages"
                        :total-messages="totalMessages"
                        link-path="user_messages_show_path"/>
      <abk-surveys-box class="col-12"
                       :surveys="surveys"
                       :total-surveys="totalSurveys"
                       :order="surveysFilters"
                       @changeOrder="changeSurveysOrder"/>
    </template>
  </b-row>
</template>

<script>
import ApiOffers from '../api/offers'
import ApiCompanies from '../api/companies'
import ApiUserMessages from '../api/user_messages'
import ApiEvents from '../api/events'
import ApiSurveyUsers from '../api/survey_users'
import AbkMessagesBox from '../components/AbkMessagesBox'
import AbkManagingOffers from '../components/AbkManagingOffers'
import AbkLoader from '../components/AbkLoader'
import AbkManagingEventsBox from '../components/events/AbkManagingEventsBox'
import LoaderDelayMixin from '../mixins/loader_delay'
import AbkSurveysBox from '../components/surveys/AbkSurveysBox'
import { app } from '../index'
import Users from '../api/users'

export default {
  name: 'EmployerDashboard',
  components: { AbkSurveysBox, AbkLoader, AbkManagingOffers, AbkManagingEventsBox, AbkMessagesBox },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      companyName: null,
      totalOffers: 0,
      totalMessages: 0,
      totalEvents: 0,
      totalSurveys: 0,
      offers: [],
      messages: [],
      events: [],
      surveys: [],
      offersFilters: {
        order_direction: 'desc',
        order_by: 'publication_date'
      },
      eventsFilters: {
        order_direction: 'desc',
        order_by: 'start_date'
      },
      surveysFilters: {
        orderDirection: 'desc',
        orderBy: 'initial_sent_date'
      },
      areOffersLoaded: false,
      areEventsLoaded: false,
      areMessagesLoaded: false,
      areSurveysLoaded: false,
      careerOfficeTeamImage: require('../assets/images/carrer_office_logo.png'),
      markPerOfferStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question'
      },
      markPerEventStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question',
        canceled: 'question'
      }
    }
  },
  computed: {
    orderOffersObject () {
      return {
        orderDirection: this.offersFilters.order_direction,
        orderBy: this.offersFilters.order_by
      }
    },
    orderEventsObject () {
      return {
        orderDirection: this.eventsFilters.order_direction,
        orderBy: this.eventsFilters.order_by
      }
    },
    loadingDone () {
      return this.areOffersLoaded && this.areMessagesLoaded && this.areEventsLoaded && this.areSurveysLoaded
    }
  },
  beforeRouteEnter (to, from, next) {
    Users.getMyCompanyStatus()
      .then(response => {
        if (response.data.accepted === false) {
          app.$swal({
            title: app.$t('views.employer_dashboard.modal_title'),
            text: app.$t('views.employer_dashboard.modal_text')
          })
        }
      })
    next(vm => {
      vm.setHeader()
    })
  },
  created () {
    this.minLoadingTimeMs = 3000
  },
  mounted () {
    this.getCompany()
    this.getOffers()
    this.getEvents()
    this.getUserMessages()
    this.getSurveys()
  },
  methods: {
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.employer_dashboard.title')
      })
    },
    getCompany () {
      ApiCompanies.getMyCompany()
        .then(response => {
          this.companyName = response.data.data.attributes.name
        }).catch((error) => {
          console.log(error)
        })
    },
    getOffers () {
      ApiOffers.getAllOffers({ filters: this.offersFilters })
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalOffers = pagination.count

          this.setOffers(response.data)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.offers.abk_offers_list.offers_download_error'))
        })
        .finally(() => {
          this.areOffersLoaded = true
        })
    },
    setOffers ({ data }) {
      this.offers = data.map(({ attributes, id }) => {
        const offerTypeTranslationPath = `general.${attributes.offerType}`

        return {
          id,
          ...attributes,
          faded: ['rejected', 'finished'].includes(attributes.status),
          path: { name: 'offers_employer_show_path', params: { id: id } },
          status: {
            mark: this.markPerOfferStatus[attributes.status],
            text: this.$t(`general.offers.statuses.${attributes.status}`),
            value: attributes.status
          },
          name: attributes.jobTitle,
          attributes: _.compact([
            this.$te(offerTypeTranslationPath) ? this.$t(offerTypeTranslationPath) : null,
            this.formatDate(attributes.publicationDate, 'DD.MM.YYYY')
          ])
        }
      })
    },
    getEvents () {
      ApiEvents.getEvents({ filters: this.eventsFilters })
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalEvents = pagination.count

          this.setEvents(response.data)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('components.events.abk_events_list.events_download_error'))
        })
        .finally(() => {
          this.areEventsLoaded = true
        })
    },
    setEvents ({ data }) {
      this.events = data.map(({ attributes, id }) => {
        const eventTypeTranslationPath = `general.event_type.${attributes.eventType}`

        return {
          ...attributes,
          id,
          path: { name: 'events_show_path', params: { id: id } },
          faded: ['rejected', 'finished', 'canceled'].includes(attributes.status),
          name: attributes.eventTitle,
          status: {
            mark: this.markPerEventStatus[attributes.status],
            text: this.$t(`general.events.statuses.${attributes.status}`),
            value: attributes.status
          },
          attributes: _.compact([
            this.$te(eventTypeTranslationPath) ? this.$t(eventTypeTranslationPath) : null,
            !this.showEmployerActions && attributes.company ? attributes.company.name : null
          ]),
          subs: attributes.subEvents.map((sub) => {
            const subAttributes = sub.data.attributes
            const startDate = this.formatDate(subAttributes.startDate, 'dddd DD.MM.YYYY HH:mm')
            const endDate = this.formatDate(subAttributes.endDate, 'dddd DD.MM.YYYY HH:mm')
            let duration

            if (startDate && endDate) {
              duration = _.uniq([startDate, endDate]).join(' - ')
            } else if (startDate) {
              duration = `${this.$t('general.from')} ${startDate}`
            } else if (endDate) {
              duration = `${this.$t('general.to')} ${endDate}`
            }

            return {
              ...subAttributes,
              id: sub.data.id,
              faded: ['rejected', 'finished', 'canceled'].includes(subAttributes.status),
              name: attributes.eventTitle,
              status: {
                mark: this.markPerEventStatus[subAttributes.status],
                text: this.$t(`general.events.statuses.${subAttributes.status}`),
                value: subAttributes.status
              },
              path: { name: 'sub_events_show_path', params: { id: sub.data.id } },
              attributes: _.compact([
                duration
              ])
            }
          })
        }
      })
    },
    changeOffersOrder (newOrder) {
      this.offersFilters.order_by = newOrder.orderBy
      this.offersFilters.order_direction = newOrder.orderDirection
      this.getOffers()
    },
    changeEventsOrder (newOrder) {
      this.eventsFilters.order_by = newOrder.orderBy
      this.eventsFilters.order_direction = newOrder.orderDirection
      this.getEvents()
    },
    getUserMessages () {
      ApiUserMessages.getUserMessages(1)
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalMessages = pagination.count

          this.messages = response.data.data.map(userMessage => {
            const message = response.data.included.find(inclusion => inclusion.id === userMessage.relationships.message.data.id)
            return {
              id: userMessage.id,
              text: message.attributes.content,
              date: message.attributes.sentAt,
              sender: {
                name: this.$t('general.career_office_team'),
                avatar: this.careerOfficeTeamImage
              }
            }
          })
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.user_messages.user_messages_index.download_error'))
        })
        .finally(() => {
          this.areMessagesLoaded = true
        })
    },
    getSurveys () {
      ApiSurveyUsers.getAssignedToMeRecent(this.surveysFilters)
        .then(({ data }) => {
          this.totalSurveys = data.meta.pagination.count
          this.setSurveys(data)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.employer_dashboard.downloading_surveys_error'))
        })
        .finally(() => {
          this.areSurveysLoaded = true
        })
    },
    setSurveys ({ data, included }) {
      this.surveys = data.map(({ id, attributes, relationships }) => {
        const survey = included.find(e => e.type === 'survey' && e.id === relationships.survey.data.id)
        const { requiresAnswer, anonymous, graduateFate } = survey.attributes
        const initialSentDate = this.formatDate(attributes.initialSentDate, 'DD.MM.YYYY')
        const updatedAt = this.formatDate(attributes.updatedAt, 'DD.MM.YYYY')
        return {
          id,
          ...attributes,
          ...survey.attributes,
          path: this.surveysType === 'completed' ? null : { name: 'surveys_show_path', params: { id } },
          disabledPath: this.surveysType === 'completed',
          status: {
            value: attributes.status
          },
          attributes: _.compact([
            updatedAt && attributes.completed
              ? this.$t('components.surveys.abk_surveys_list.completed_at', { date: updatedAt })
              : null,
            initialSentDate ? this.$t('components.surveys.abk_surveys_list.sent_at', { date: initialSentDate }) : null,
            requiresAnswer ? this.$t('general.surveys.attributes.requires_answer') : null,
            anonymous ? this.$t('general.surveys.attributes.anonymous') : null,
            graduateFate ? this.$t('general.surveys.attributes.graduate_fate') : null
          ])
        }
      })
    },
    changeSurveysOrder (newOrder) {
      this.surveysFilters = { ...this.surveysFilters, ...newOrder }
      this.getSurveys()
    }
  }
}
</script>
