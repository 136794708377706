import api from './index'

const resource = 'offer_filters'

export default {
  getOfferFilters (page) {
    return api.get(`${resource}?page=${page}`)
  },
  createOfferFilter (payload) {
    return api.post(`${resource}`, payload)
  },
  removeOfferFilter (offerFilterId) {
    return api.delete(`${resource}/${offerFilterId}`)
  }
}
