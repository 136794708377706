<template>
  <li class="record d-flex align-items-center"
      :class="{ 'no-border': noBorder, 'fixed-height': fixedHeight, 'highlighted': highlighted }"
      :style="[ isInactive ? { opacity: '0.5' } : { opacity: '1' } ]">
    <template v-if="!hideStatusIcon">
      <i v-if="record.status === 'accepted'"
          class="pcg-icon-check status-icon"
          :style="[ fixedHeight ? {'color': 'green' } : { color: '#00B4EB' } ]"/>
      <i v-else-if="record.status === 'rejected'" class="pcg-icon-cross status-icon"/>
      <i v-else-if="record.status === 'draft'" class="pcg-icon-edit edit-icon status-icon"/>
      <span v-else class="waiting status-icon"/>
    </template>
    <div class="record-key" :class="{ 'fixed-height': fixedHeight }">{{ index }}</div>
    <div class="ml-2 record-info">
      <div class="record-header">
        <span v-if="record.typeText" class="record-status">
          {{ record.typeText }}
        </span>
        <span v-if="record.statusText" class="record-status">
          {{ record.statusText }}
        </span>
        <span v-if="record.owner">
          {{ record.owner }}
        </span>
      </div>
      <router-link class="record-name" :to="{ name: showRecordPath, params: { id: record.id } }">
        {{ record.name || $t('general.noname') }}
      </router-link>
    </div>
    <slot/>
  </li>
</template>

<script>
export default {
  name: 'AbkListItem',
  props: {
    record: Object,
    noBorder: Boolean,
    fixedHeight: Boolean,
    index: Number,
    showRecordPath: String,
    highlighted: Boolean,
    hideStatusIcon: Boolean
  },
  computed: {
    isInactive () {
      return ['rejected', 'finished'].includes(this.record.status)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .record {
    list-style: none;
    padding: .2em 0;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &.no-border {
      border: none;
    }
    &.fixed-height {
      min-height: 57px;
      flex-wrap: wrap;
    }
    &.highlighted {
      background-color: $pcg-the-lightest-gray;
    }
  }
  .record-key {
    line-height: 2.2;
    margin: 4px 0;
    font-size: $font-size-s;
    min-width: 40px;
    width: 40px;
    background-color: $pcg-the-lightest-gray;
    color: $pcg-dark-gray;
    text-align: center;
    border-radius: 5px;
    &.fixed-height {
      background-color: $pcg-lightest-gray;
    }
  }
  .record-header {
    font-size: $font-size-xs;
    font-weight: 500;
    color: $pcg-gray;
    span:not(:last-child) {
      margin-right: .5em;
    }
  }
  .record-company {
    color: $pcg-dark-gray;
  }
  .record-name {
    font-size: $font-size-s;
    font-weight: 500;
    color: $main-color;
    text-transform: uppercase;
  }
  .pcg-icon-cross {
    color: $pcg-red;
  }
  .waiting {
    background-color: $pcg-light-gray;
    padding: 1px 6px;
  }
  .edit-icon {
    color: $pcg-gray;
  }
  .status-icon {
    font-size: $font-size-s;
    line-height: 3;
    margin-right: .7em;
  }
  .record-info {
    width: fit-content;
    min-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .free-space {
    margin-right: 1.7rem;
  }
  .record-status {
    color: $pcg-light-gray;
  }
  .record-carrer-office {
    color: $main-color;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .record {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .record-key {
      background-color: $pcg-the-lightest-gray;
      color: $pcg-dark-gray;
      &.fixed-height {
        background-color: $pcg-the-lightest-gray;
      }
    }
    .record-header {
      color: $pcg-gray;
    }
    .record-company {
      color: $pcg-dark-gray;
    }
    .record-name {
      color: $main-color;
    }
    .pcg-icon-cross {
      color: $pcg-red;
    }
    .waiting {
      background-color: $pcg-light-gray;
    }
    .record-status {
      color: $pcg-light-gray;
    }
    .record-carrer-office {
      color: $main-color;
    }
  }
</style>
