<template>
  <div class="filter-offers-button-container">
    <pcg-btn class="w-100 filter-offers-button" @click="$emit('click')">
      <slot/>
    </pcg-btn>
    <pcg-btn v-if="cancel" variant="additional" class="w-100 filter-offers-button" @click="$emit('cancel')">
      <slot name="cancel"/>
    </pcg-btn>
  </div>
</template>

<script>
export default {
  name: 'AbkMobileBtn',
  props: {
    cancel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .filter-offers-button-container {
    background-color: $pcg-white;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    text-align: center;
    padding: $pcg-m-xs;
    display: flex;
    inner-container {
      /*width: 90%;*/
    }
  }
  .filter-offers-button {

  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    .filter-offers-button-container {
      background-color: $pcg-white;
    }
  }
</style>
