<template>
  <div class="match-indicator-container">
    <div class="degrees-container">
      <div v-for="barNumber in barsCount"
           :key="`degree-bar-${barNumber}`"
           class="degree-bar"
           :class="{ 'highlighted': barNumber <= degree }"
           :style="{ height: `${minBarHeight + (degreeHeightDifference * (barNumber - 1))}px` }"/>
    </div>
    <div class="degree-text" v-if="degreesTexts && degreesTexts.length >= (degree + 1)">
      {{ degreesTexts[degree]}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbkMatchIndicator',
  props: {
    barsCount: {
      type: Number,
      default: 5
    },
    degree: Number,
    degreesTexts: Array,
    minBarHeight: {
      type: Number,
      default: 5
    },
    maxBarHeight: {
      type: Number,
      default: 50
    }
  },
  computed: {
    degreeHeightDifference () {
      return (this.maxBarHeight - this.minBarHeight) / (this.barsCount - 1)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .degrees-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .degree-bar {
    width: 20px;
    background-color: $pcg-light-gray;
    opacity: 0.3;
    &:not(:first-child) {
      margin-left: $pcg-m-xs;
    }
    &.highlighted {
      opacity: 1;
      background-color: $pcg-green-color;
    }
  }
  .degree-text {
    margin-top: $pcg-m-sm;
    color: $pcg-green-color;
    font-weight: 500;
  }
</style>
