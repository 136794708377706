<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <span class="filters-text">{{ $t('components.skills.abk_skills_list.filter_skills') }}</span>
        <div class="d-flex flex-wrap">
          <pcg-search-select :placeholder="$t('components.skills.abk_skills_list.placeholders.name')"
                             always-active
                             allow-empty
                             :options="skillsNamesOptions"
                             class="filter-select"
                             v-model="filters.name"
                             @input="getSkillsAndResetPagination"/>
          <pcg-search-select :placeholder="$t('components.skills.abk_skills_list.placeholders.description')"
                             always-active
                             allow-empty
                             :options="skillsDescriptionsOptions"
                             class="filter-select"
                             v-model="filters.description"
                             @input="getSkillsAndResetPagination"/>
          <pcg-search-select :placeholder="$t('components.skills.abk_skills_list.placeholders.type')"
                             always-active
                             allow-empty
                             :options="skillsTypesOptions"
                             class="filter-select"
                             v-model="filters.type"
                             @input="getSkillsAndResetPagination"/>
          <pcg-search-select :placeholder="$t('components.skills.abk_skills_list.placeholders.kind')"
                             always-active
                             allow-empty
                             :options="skillsKindsOptions"
                             class="filter-select"
                             v-model="filters.kind"
                             @input="getSkillsAndResetPagination"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <abk-loader v-if="showLoader" class="with-margins"/>
        <pcg-table v-else-if="skills && skills.length" :items="skills" :fields="fields">
          <template v-slot:header>{{ $t('components.skills.abk_skills_list.table_header') }}</template>
          <template v-slot:cell(actions)="{ item }">
            <pcg-btn-symbol icon-class="pcg-icon-notes"
                            :tooltip="$t('general.show_report')"
                            @click="$router.push({ name: 'skills_report_path', params: { id: item.id } })"/>
          </template>
        </pcg-table>
        <pcg-box v-else>
          <span class="pcg-no-records">{{ $t('components.skills.abk_skills_list.no_records') }}</span>
        </pcg-box>
      </b-col>

      <b-col class="col-12">
        <pcg-pagination v-model="pagination.currentPage"
                        @input="getSkills"
                        :page-count="pagination.pages"
                        :total-records="pagination.totalRecords"
                        class="col-12 justify-content-center my-pagination"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiSkills from '../../api/skills'
import ApiMessages from '../../api/messages'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import AbkLoader from '../AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'AbkSkillsList',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  props: {
    skillsType: {
      type: String,
      default: 'all',
      validate (skillType) {
        return ['all', 'connectedWithCompetences', 'notConnectedWithCompetences'].includes(skillType)
      }
    }
  },
  data () {
    return {
      skills: [],
      fields: [],
      labels: [],
      getSkillsActionPerType: {
        all: 'getSkills',
        connectedWithCompetences: 'getConnectedWithCompetences',
        notConnectedWithCompetences: 'getNotConnectedWithCompetences'
      },
      filters: {
        name: null,
        description: null,
        type: null,
        kind: null
      },
      skillsNamesOptions: [],
      skillsDescriptionsOptions: [],
      skillsTypesOptions: [],
      skillsKindsOptions: []
    }
  },
  mounted () {
    this.getSkills()
    this.getSkillsOptions()
  },
  methods: {
    getSkillsAndResetPagination () {
      this.resetPaginationPage()
      this.getSkills()
    },
    getSkills () {
      ApiSkills[this.getSkillsActionPerType[this.skillsType]]({ ...this.filters, page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.skills = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    getSkillsOptions () {
      ApiMessages.getUniversityStructure()
        .then(response => {
          this.skillsNamesOptions = this.formatUniversityStructureFor(response.data, 'course')
          this.skillsDescriptionsOptions = this.formatUniversityStructureFor(response.data, 'faculty')
          this.skillsTypesOptions = this.formatUniversityStructureFor(response.data, 'type')
          this.skillsKindsOptions = this.formatUniversityStructureFor(response.data, 'kind')
        })
        .catch((error) => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    formatUniversityStructureFor (data, type) {
      if (data && data[type]) {
        return this.skillsKindsOptions = _.uniq(data[type].map(type => type.name.polish)).sort().map(kind => {
          return { value: kind, text: kind }
        })
      }
      return []
    },
    dataTable (data) {
      const result = []
      data.forEach(skill => {
        result.push({
          ...skill.attributes,
          id: skill.id,
          kinds: skill.attributes.kinds && skill.attributes.kinds.join(', '),
          types: skill.attributes.types && skill.attributes.types.join(', ')
        })
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key !== 'externalId') {
              fields.push({
                key: key,
                label: this.$t(`views.skills.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionaries.labels.actions')
      })

      return fields
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .matching-competences-container {
    margin-left: auto;
    min-width: 130px;
    text-align: center;
  }
  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  /deep/ .pcg-table-container {
    tr {
      td:not(:first-child):not(:last-child) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    .matching-competences-container {
      min-width: 70px;
      width: 70px;
    }
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
