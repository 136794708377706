<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-calendar-date oneline"></i>
      <span class="oneline">{{ $t('components.abk_managing_events_box.title') }}</span>
      <div class="d-none d-sm-inline-block">
        <pcg-sort-btn class="add-button" :options="sortOptions" :value="order" @input="changeOrder"/>
      </div>
      <router-link v-if="events && totalEvents > events.length"
                   class="ml-auto pcg-link pcg-small"
                   :to="{ name: 'events_list_all_tab_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <template v-if="events && events.length">
      <div class="all-records-text">{{ $t('components.abk_managing_events_box.events_count') }}: {{ totalEvents }}</div>
      <abk-list-with-sub :items="events"
                :no-records-text="$t('components.events.abk_new_events_box.no_records')"
                :items-column-name="$t('general.event')">
        <template v-slot:title="event">
            <span v-if="event.place" class="event-place-container d-none d-lg-inline">
              <i class="pcg-icon-location-alt"/>
              <span>{{ event.place }}</span>
            </span>
        </template>
        <template v-slot:sub-event="sub">
          <span class="event-count">
            <span v-if="sub.registrationsCount >= 50"><i class="fas fa-fire fire-icon"></i></span>
            {{ $tc('components.abk_managing_events_box.registrations', sub.registrationsCount) }}
          </span>
        </template>
      </abk-list-with-sub>
    </template>
    <span v-else class="pcg-no-records">{{ $t('components.abk_managing_events_box.no_records') }}</span>
  </pcg-box>
</template>

<script>
import AbkListWithSub from '../AbkListWithSub'

export default {
  name: 'abk-managing-offers',
  components: { AbkListWithSub },
  props: {
    events: Array,
    totalEvents: Number,
    order: Object
  },
  data: function () {
    return {
      sortOptions: [
        { text: this.$t('general.start_date').toLowerCase(), value: 'start_date' },
        { text: this.$t('general.end_date').toLowerCase(), value: 'end_date' }
      ]
    }
  },
  methods: {
    changeOrder (newOrder) {
      this.$emit('changeOrder', newOrder)
    },
    getDuration ({ startDate, endDate }) {
      const start = this.formatDate(startDate, 'DD.MM.YYYY HH:mm')
      const end = this.formatDate(endDate, 'DD.MM.YYYY HH:mm')

      if (start && end) {
        return _.uniq([start, end]).join(' - ')
      } else if (start) {
        return `${this.$t('general.from')} ${start}`
      }
      return `${this.$t('general.to')} ${end}`
    },
    formatDate (date, format) {
      const newDate = moment(date)
      return newDate.isValid() ? newDate.format(format) : null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .event-place-container {
    margin-left: $pcg-m-md;
    text-transform: uppercase;
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-dark-gray;
    i {
      color: $pcg-light-gray;
      margin-right: $pcg-m-xs;
    }
  }
  .all-records-text {
    font-weight: 500;
    color: $pcg-gray;
    font-size: $font-size-s;
    margin-bottom: $pcg-m-xs;
  }
  .add-button {
    margin-left: 2rem;
  }
  .oneline {
    display: inline-block;
  }
  .event-id {
    line-height: 2.2;
    margin: 4px 0;
    font-size: $font-size-s;
    width: 40px;
    background-color: $pcg-the-lightest-gray;
    color: $pcg-dark-gray;
    text-align: center;
    border-radius: 5px;
  }
  .event-type {
    font-size: $font-size-xs;
    font-weight: 400;
    color: $pcg-dark-gray;
  }
  .event-title {
    font-weight: 500;
    text-transform: uppercase;
    color: $main-color;
  }
  .event-count {
    line-height: 2.2;
    margin: 4px 0;
    font-size: $font-size-s;
    min-width: 7.2rem;
    max-width: 7.2rem;
    background-color: $pcg-the-lightest-gray;
    color: $main-active-color;
    text-align: center;
    border-radius: 5px;
  }
  .event {
    list-style: none;
    padding: .2em 0;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &.no-border {
      border: none;
    }
  }
  .event-time {
    align-self: flex-end;
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-dark-gray;
  }
  .event-icon {
    margin-right: .2rem;
  }
  .fire-icon {
    color: $pcg-red;
    margin-right: .2rem;
  }
  .p-2 {
    padding: 0.2rem !important;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .event-place-container {
      color: $pcg-dark-gray;
      i {
        color: $pcg-light-gray;
      }
    }
    .event-id {
      background-color: $pcg-the-lightest-gray;
      color: $pcg-dark-gray;
    }
    .event-type {
      color: $pcg-dark-gray;
    }
    .event-title {
      color: $main-color;
    }
    .event-count {
      background-color: $pcg-the-lightest-gray;
      color: $main-active-color;
    }
    .event {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .event-time {
      color: $pcg-dark-gray;
    }
    .fire-icon {
      color: $pcg-red;
    }
    .add-button {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
  }
</style>
