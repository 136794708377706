<template>
  <router-link :to="{ name: 'offers_show_path', params: { id: record.id } }" tag="div" class="record">
    <b-col class="col-12 col-sm-2 d-flex align-items-center justify-content-center">
      <img v-if="record.company" :src="record.company.logo.thumb.url" class="img-fluid d-block mx-auto logo" ref="logoImage">
      <div v-if="record.company && record.company.employerType === 'partner'" ref="partnerContainer" style="width: 0; height: 0; position: absolute; top: 1rem; left: 3rem; display: none">
        <img v-if="contrast" :src="record.company.employerTypeLogoNegative" class="img-fluid rounded" style="max-width: 20px;">
        <img v-else :src="record.company.employerTypeLogo" class="img-fluid rounded" style="max-width: 20px;">
      </div>
    </b-col>
    <b-col class="col-12 col-sm-10">
      <div class="header d-flex">
        <div class="content">
          <div class="top-bar">
            <template v-if="$te(`components.abk_work_record.type.${record.offerType}`)">
              <span class="offer-type-indicator" :class="{ 'alternative': record.offerType === 'job' }"/>
              <span class="type small-text">
                {{ $t(`components.abk_work_record.type.${record.offerType}`) }}
              </span>
            </template>
            <span class="fresh-record" v-if="recordTimeSpent(record) <= 2">
              {{ $t('components.abk_work_record.fresh_record') }}
            </span>
            <span v-if="record.publicationDate" class="publication-date small-text">
              {{ $t('components.abk_work_record.published') }}:
              <span class="date">{{ formatDate(record.publicationDate) }}</span>
            </span>
            <span v-else-if="record.expirationDate" class="publication-date small-text">
              {{ $t('components.abk_work_record.offer_expires') }}:
              <span class="date">{{ formatDate(record.expirationDate) }}</span>
            </span>
          </div>
          <div class="d-flex align-items-center flex-wrap">
            <div class="main-content">
              <div>
                <span class="title">
                  {{ record.jobTitle }}
                </span>
              </div>
              <div>
              <span v-if="record.company" class="company-name small-text">
                {{ record.company.name }}
              </span>
                <span class="place small-text">
                {{ record.workplace }}
              </span>
              </div>
            </div>
            <span v-if="record.salaryFrom || record.salaryTo" class="salary">
              {{ formatSalary(record.salaryFrom, record.salaryTo) }} {{ $t('general.currency.pln') }}
            </span>
          </div>
        </div>
      </div>
    </b-col>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import WindowResizeMixin from '../mixins/window_resize'

export default {
  name: 'AbkOfferRecord',
  props: {
    record: Object
  },
  mixins: [WindowResizeMixin],
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    dictionaryTranslatedKey (distionaryName, distionaryKey) {
      if (this.dictionaries && this.dictionaries[distionaryName]) {
        const found = this.dictionaries[distionaryName].find(dictionaryWord => dictionaryWord.value === distionaryKey)
        return found && found.text
      }
      return distionaryKey
    },
    recordTimeSpent (record) {
      const time = moment(record.publicationDate)
      const now = moment()
      if (time.isValid()) {
        const timeSpent = now.diff(time, 'days')
        return timeSpent
      }
      return 0
    },
    formatDate (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatSalary (salaryFrom, salaryTo) {
      if (!salaryFrom) {
        return `${this.$t('general.to')} ${salaryTo}`
      } else if (!salaryTo) {
        return `${this.$t('general.from')} ${salaryFrom}`
      }
      return `${salaryFrom} - ${salaryTo}`
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.$refs.logoImage && this.$refs.partnerContainer) {
        this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.clientWidth + this.$refs.logoImage.offsetLeft) - 23) + 'px'
        this.$refs.partnerContainer.style.top = this.$refs.logoImage.offsetTop + 3 + 'px'
        this.$refs.partnerContainer.style.display = 'block'
      }
    }, 1000)
    setTimeout(() => {
      if (this.$refs.logoImage && this.$refs.partnerContainer) {
        this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.clientWidth + this.$refs.logoImage.offsetLeft) - 23) + 'px'
        this.$refs.partnerContainer.style.top = this.$refs.logoImage.offsetTop + 3 + 'px'
        this.$refs.partnerContainer.style.display = 'block'
      }
    }, 2000)
  },
  watch: {
    windowWidth () {
      if (this.$refs.partnerContainer) {
        this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.clientWidth + this.$refs.logoImage.offsetLeft) - 23) + 'px'
        this.$refs.partnerContainer.style.top = this.$refs.logoImage.offsetTop + 3 + 'px'
      }
    },
    'record.company.logo': {
      deep: true,
      handler () {
        setTimeout(() => {
          if (this.$refs.partnerContainer) {
            this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.clientWidth + this.$refs.logoImage.offsetLeft) - 23) + 'px'
            this.$refs.partnerContainer.style.top = this.$refs.logoImage.offsetTop + 3 + 'px'
          }
        }, 200)
        setTimeout(() => {
          if (this.$refs.partnerContainer) {
            this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.clientWidth + this.$refs.logoImage.offsetLeft) - 23) + 'px'
            this.$refs.partnerContainer.style.top = this.$refs.logoImage.offsetTop + 3 + 'px'
            this.$refs.partnerContainer.style.display = 'block'
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .main-content {
    margin-right: $pcg-m-sm;
  }
  .small-text {
    font-size: $font-size-s;
    font-weight: 500;
  }
  .type {
    color: $pcg-the-darkest-gray;
    margin-right: .7rem;
  }
  .publication-date {
    display: inline-block;
    color: $pcg-light-gray;
    margin-left: auto;
    .date {
      margin-left: $pcg-m-sm;
      color: $pcg-gray;
      font-weight: 500;
    }
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: 1rem;
    &:hover {
      text-decoration: underline;
    }
  }
  .salary {
    white-space: nowrap;
    margin-left: auto;
    font-weight: 500;
    border-radius: 10px;
    padding: 4px 10px;
    color: $pcg-black;
    background-color: $pcg-lightest-gray;
  }
  .company-name, .place {
    color: $pcg-gray;
    margin-right: $pcg-m-sm;
  }
  .logo {
    max-height: 80px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .record {
    padding-top: $pcg-m-xs;
    padding-bottom: $pcg-m-xs;
    cursor: pointer;
    &:hover {
      background-color: $pcg-the-lightest-gray;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .offer-type-indicator {
    display: inline-block;
    width: 8px;
    height: 10px;
    border-radius: 3px;
    margin-right: $pcg-m-xs;
    background-color: $pastel-pink-color;
    &.alternative {
      background-color: $pastel-blue-color;
    }
  }
  .content {
    display: inline;
    width: 100%;
  }
  .header {
    padding-top: $pcg-m-xs;
    padding-bottom: $pcg-m-xs;
    display: inline-block;
    width: 100%;
    clear: both;
  }
  .fresh-record {
    display: inline-block;
    font-size: 0.86em;
    padding: 1px 9px;
    border-radius: 25px;
    margin-left: 2px;
    background-color: $pcg-green;
    color: $pcg-white;
  }
  .top-bar {
    display: flex;
    align-items: center;
    margin-bottom: $pcg-m-xs;
  }

  @media(max-width: $screen-xs-max) {
    .publication-date {
      .date {
        margin-left: 0;
      }
    }
    .type {
      margin-right: 3px;
    }
    .fresh-record {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .type {
      color: $pcg-the-darkest-gray;
    }
    .publication-date {
      color: $pcg-light-gray;
      .date {
        color: $pcg-gray;
      }
    }
    .title {
      color: $main-color;
    }
    .salary {
      color: $pcg-black;
      background-color: $pcg-lightest-gray;
    }
    .company-name, .place {
      color: $pcg-gray;
    }
    .record {
      &:hover {
        background-color: $pcg-the-lightest-gray;
      }
    }
    .offer-type-indicator {
      background-color: $pastel-pink-color;
      &.alternative {
        background-color: $pastel-blue-color;
      }
    }
    .fresh-record {
      background-color: $pcg-green;
      color: $pcg-white;
    }
  }
</style>
