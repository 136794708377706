<template>
  <b-row>
    <b-col cols="12" class="top-bar">
      <span class="sortby-text">{{ $t('components.offer_invitations.abk_offer_invitations_list.sort_by') }}</span>
      <pcg-sort-btn class="sort-button" :options="sortOptions" v-model="sort" @input="filterOfferInvitations"/>
    </b-col>
    <pcg-box v-if="showLoader" class="col-12">
      <abk-loader/>
    </pcg-box>
    <template v-else-if="offersInvitations && offersInvitations.length > 0">
      <abk-invitation-box v-for="offer in offersInvitations"
                          :key="offer.id"
                          :offer-invitation="offer"
                          class="col-12"
                          @applied="getInvited"/>
    </template>
    <pcg-box v-else>
      <span class="pcg-no-records">{{ $t('components.offer_invitations.abk_offer_invitations_list.no_records') }}</span>
    </pcg-box>
    <pcg-pagination v-model="currentPage"
                    :page-count="pages"
                    :total-records="totalRecords"
                    @input="getInvited"
                    class="col-12 justify-content-center my-pagination"/>
  </b-row>
</template>

<script>
import AbkInvitationBox from '../../components/AbkInvitationBox'
import ApiOfferInvitations from '../../api/offer_invitations'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'AbkOfferInvitationsList',
  components: { AbkLoader, AbkInvitationBox },
  mixins: [LoaderDelayMixin],
  props: {
    offerInvitationsType: {
      type: String,
      default: 'all',
      validate (offerInvitationType) {
        return ['all', 'current', 'archived'].includes(offerInvitationType)
      }
    }
  },
  data () {
    return {
      offersInvitations: [],
      currentPage: 1,
      pages: 0,
      totalRecords: 0,
      sort: { orderBy: 'created_at', orderDirection: 'desc' },
      getOfferInvitationsPerType: {
        all: 'getOfferInvitations',
        current: 'getCurrentOfferInvitations',
        archived: 'getArchivedOfferInvitations'
      }
    }
  },
  computed: {
    sortOptions () {
      return [
        {
          text: this.$t('components.offer_invitations.abk_offer_invitations_list.sort_options.invitation_sent_date'),
          value: 'created_at'
        },
        {
          text: this.$t('components.offer_invitations.abk_offer_invitations_list.sort_options.offer_job_title'),
          value: 'offers.job_title'
        }
      ]
    },
    queryParams () {
      return { page: this.currentPage, ...this.sort }
    }
  },
  methods: {
    getInvited () {
      ApiOfferInvitations[this.getOfferInvitationsPerType[this.offerInvitationsType]](this.queryParams)
        .then(response => {
          const pagination = response.data.meta.pagination

          this.pages = pagination.pages
          this.totalRecords = pagination.count
          this.offersInvitations = response.data.data.map(invitation => {
            const offer = response.data.included.find(({ id }) => id === invitation.relationships.offer.data.id)
            const inviting = response.data.included.find(({ id }) => id === invitation.relationships.inviting.data.id)
            const { id, attributes: { publicationDate, ...rest } } = offer
            return {
              createdAt: invitation.attributes.createdAt,
              inviting: {
                name: `${inviting.attributes.firstName} ${inviting.attributes.lastName}`,
                companyName: inviting.attributes.companyName
              },
              offer: { id, applied: invitation.attributes.applied, ...rest }
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    filterOfferInvitations () {
      this.resetPage()
      this.getInvited()
    },
    resetPage () {
      this.currentPage = 1
    }
  },
  mounted () {
    this.getInvited()
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .top-bar {
    display: flex;
    padding-bottom: $pcg-m-md;
  }
  .sortby-text {
    margin-right: $pcg-m-md;
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-dark-gray;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .sortby-text {
      color: $pcg-dark-gray;
    }
    .sort-button {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
