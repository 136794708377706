export const general = {
  remove_account: 'Usuń konto',
  account_removed: 'Konto usunięte',
  accept: 'Akceptuj',
  accept_event: 'Akceptuj wydarzenie',
  accept_offer: 'Akceptuj ofertę',
  accept_employer: 'Akceptuj pracodawcę',
  accuracy: 'trafność',
  action_cannot_be_reverted: 'Ta akcja nie może zostać cofnięta!',
  add: 'Dodaj',
  add_attachment: 'Dodaj załącznik',
  add_event: 'Dodaj wydarzenie',
  add_offer: 'Dodaj ofertę',
  add_user: 'Dodaj użytkownika',
  add_value: 'Dodaj wartość',
  all: 'Wszystkie',
  answer: 'Odpowiedź',
  apply: 'Aplikuj',
  apprenticeship: 'Praktyki',
  are_you_sure: 'Jesteś pewien?',
  back: 'Powrót',
  back_to_edit: 'Wróć do edycji',
  cancel: 'Anuluj',
  cancel_event: 'Anuluj wydarzenie',
  cancel_sub_event: 'Anuluj termin',
  career_office_team: 'Zespół BK',
  change: 'Zmień',
  change_order: 'Zmień kolejność',
  change_student_to_graduate: {
    confirm: 'Przekształć konto',
    dismiss: 'Załóż nowe konto',
    title: 'Istniejące konto',
    text: 'Znaleziono istniejące konto studenta z takim adresem e-mail. Chcesz dopisać się do tego konta i przekształcić je w konto absolwenta?'
  },
  channel: 'Kanał',
  check_errors: 'Sprawdź błędy',
  clear: 'Wyczyść',
  close: 'Zamknij',
  close_survey: 'Zamknij ankietę',
  collapse: 'Zwiń',
  collapse_all: 'Zwiń wszystkie',
  confirmed: 'Potwierdzony',
  company: 'Firma',
  company_popup: {
    go_to_company_edit: 'Przejdź do edycji profilu',
    title: 'Uzupełnienie profilu firmy',
    text: 'Aby dodawać nowe oferty, oraz wydarzenia, profil firmy musi zostać zaakceptowany przez pracownika Biura ' +
      'Karier. W celu przesłania profilu firmy do akceptacji, uzupełnij wymagane informacje.'
  },
  competence_deleted: 'Kompetencja została usunięta',
  competence_updated: 'Kompetencja została zaktualizowana pomyślnie',
  confirm: 'Zatwierdź',
  content: 'Treść',
  copy: 'Kopiuj',
  cookies_information: 'Informacja o plikach COOKIES',
  cookies_policy: 'Informujemy, iż w celu optymalizacji treści dostępnych w naszym serwisie, dostosowania ich do Państwa' +
    ' indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą plików cookies na urządzeniach końcowych użytkowników.' +
    ' Pliki cookies użytkownik może kontrolować za pomocą ustawień swojej przeglądarki internetowej. ' +
    'Dalsze korzystanie z naszego serwisu internetowego, bez zmiany ustawień przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie plików cookies.',
  currency: {
    pln: 'zł'
  },
  current_version: 'Aktualna wersja',
  curriculum_vitae_popup: {
    go_to_cv_edit: 'Przejdź do edycji CV',
    text: 'Uzupełnienie CV',
    title: 'Aby w pełni korzystać z aplikacji uzupełnij wymagane informacje.'
  },
  create_new: 'Stwórz nową',
  date_added: 'data dodania',
  date_of_publication: 'data publikacji',
  days_left: '| Został 1 dzień do końca | Zostały {count} dni do końca | Zostało {count} dni do końca',
  delete: 'Usuń',
  delete_message: 'Usuń wiadomość',
  delete_note: 'Usuń notatkę',
  destroy_survey: 'Usuń ankietę',
  dictionary_updated: 'Słownik został zaktualizowany pomyślnie',
  dictionary_word_updated: 'Słowo słownikowe zostało zaktualizowane pomyślnie',
  discard_changes: 'Odrzuć zmiany',
  edit: 'Edytuj',
  edited: 'Edytowano',
  employer_recruiter: 'Pracodawca/Rekruter',
  end_date: 'Data zakończenia',
  error_try_later: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.',
  event: 'Wydarzenie',
  event_type: {
    counseling: 'Doradztwo',
    fair: 'Targi',
    training: 'Szkolenie',
    rally: 'Wiec',
    other: 'Inne'
  },
  events: {
    statuses: {
      accepted: 'Zaakceptowane',
      canceled: 'Anulowane',
      draft: 'Wersja robocza',
      finished: 'Zakończone',
      for_acceptance: 'Oczekujące',
      recent: 'Nowe',
      rejected: 'Odrzucone',
      published: 'Opublikowane'
    }
  },
  expand: 'Rozwiń',
  expand_all: 'Rozwiń wszystkie',
  fields_not_filled: 'Niektóre pola nie zostały poprawnie wypełnione. Sprawdź błędy i spróbuj ponownie.',
  no_captcha: 'Musisz potwierdzić, że nie jesteś robotem',
  finish: 'Zakończ',
  finish_event: 'Zakończ wydarzenie',
  finish_offer: 'Zakończ ofertę',
  forbidden: 'Nie masz uprawnień do wykonania tej czynności.',
  from: 'od',
  full_time: 'Pełny etat',
  full_name: 'Imię i nazwisko',
  gallery: 'Galeria',
  hello_world: 'Siema Świecie!',
  hide: 'Ukryj',
  in: 'w',
  job: 'Praca',
  languages: {
    english: 'angielski',
    polish: 'polski'
  },
  list: 'Typ listy',
  list_type: {
    basic: 'Lista podstawowa',
    reserve: 'Lista rezerwowa'
  },
  localization: 'Lokalizacja',
  logged_out: 'Zostałeś wylogowany',
  log_in: 'Zaloguj',
  make_copy: 'Stwórz kopię',
  make_offer: 'Złóż ofertę',
  message_sent: 'Wiadomość została wysłana',
  message_type: {
    communique: 'Komunikat',
    newsletter: 'Newsletter',
    sms: 'SMS'
  },
  missing_approvals: 'Aby móc zalogować się do systemu musisz zaznaczyć niezbędne zgody',
  new_message: 'Nowa wiadomość',
  new_note: 'Nowa notatka',
  no_access: 'Brak dostępu',
  noname: 'Brak nazwy',
  number: 'Numer',
  offer: 'Oferta',
  offer_type: {
    job: 'Praca',
    practice: 'Praktyki',
    training: 'Staż'
  },
  offers: {
    statuses: {
      accepted: 'Zaakceptowana',
      draft: 'Wersja robocza',
      finished: 'Zakończona',
      for_acceptance: 'Oczekująca',
      recent: 'Nowa',
      rejected: 'Odrzucona',
      published: 'Opublikowana'
    }
  },
  or: 'lub',
  old_version: 'Poprzednia wersja',
  other: 'Inne',
  page_not_found: 'Nie znaleziono strony',
  part_time: 'Pół etatu',
  practice: 'Praktyka',
  preview: 'Podgląd',
  preview_saved_draft: 'Podgląd zapisanej wersji roboczej',
  publish: 'Opublikuj',
  publish_survey: 'Opublikuj ankietę',
  read_less: '< Czytaj mniej',
  read_more: 'Czytaj więcej >',
  remove_sub_event: 'Usuń termin',
  removed_date: 'Data usunięcia',
  recipient_type: {
    employers: 'Pracodawcy',
    recruiters: 'Rekruterzy',
    students: 'Studenci',
    graduates: 'Absolwenci'
  },
  recipients_group: 'Grupa odbiorców',
  register: 'Zapisz się',
  reject: 'Odrzuć',
  reject_changes: 'Odrzuć zmiany',
  reject_event: 'Odrzuć wydarzenie',
  reject_offer: 'Odrzuć ofertę',
  remote_work: 'Praca zdalna',
  remove: 'Usuń',
  remove_from_favorites: 'Usuń z ulubionych',
  roles: {
    administrator: 'administrator',
    career_office: 'biuro karier',
    counselor: 'doradca',
    employer: 'pracodawca',
    graduate: 'absolwent',
    recruiter: 'rekruter',
    student: 'student'
  },
  save: 'Zapisz',
  save_and_login: 'Zapisz i zaloguj',
  save_changes: 'Zapisz zmiany',
  save_draft: 'Zapisz wersję roboczą',
  save_note: 'Zapisz notatkę',
  search_word: 'Wyszukaj słowo...',
  see_less: 'pokaż mniej',
  see_more: 'zobacz więcej',
  select: 'Wybierz',
  select_city: 'Wybierz miasto',
  send: 'Wyślij',
  send_for_publication: 'Wyślij do publikacji',
  show_all: 'Zobacz wszystkie',
  show_more: 'ZOBACZ WIĘCEJ',
  show_params: 'Pokaż parametry',
  show_report: 'Pokaż raport',
  show_results: 'Pokaż wyniki',
  start_date: 'Data rozpoczęcia',
  status: 'Status',
  steps: {
    next: 'Dalej',
    previous: 'Wstecz',
    publish: 'Zapisz i opublikuj',
    send_for_publication: 'Zapisz i wyślij do publikacji'
  },
  student_graduate: 'Student/Absolwent',
  suggest: 'Zaproponuj',
  survey: 'Ankieta',
  surveys: {
    attributes: {
      anonymous: 'Anonimowa',
      graduate_fate: 'Badanie losów absolwentów',
      requires_answer: 'Wymaga odpowiedzi'
    },
    statuses: {
      draft: 'Kopia robocza',
      published: 'Opublikowana',
      completed: 'Zakończona',
      closed: 'Zamknięta'
    }
  },
  text_section: 'Sekcja tekstowa',
  time_to_end: 'czas do końca',
  title: 'Tytuł',
  training: 'Staż',
  to: 'do',
  type: 'Rodzaj',
  unexpected_error: 'Wystąpił nieoczekiwany błąd.',
  unregister: 'Wypisz się',
  video: 'Video',
  when: 'kiedy',
  where: 'gdzie',
  words: 'Słowa',
  work_on_site: 'Praca na miejscu'
}
