<template>
  <b-row>
    <b-col class="col-12 col-sm-6">
      <label class="pcg-input-label" :for="`multi-select-maping-left-${id}`">{{ leftLabel }}</label>
      <pcg-text-input autocomplete="off" v-model="leftRegexp" :show-label="false" :placeholder="leftSearchPlaceholder"/>
      <select multiple
              class="form-control"
              v-model="selectedLeftModel"
              :id="`multi-select-maping-left-${id}`">
        <option v-for="option in leftOptions"
                :key="option.id"
                :value="option.id"
                v-show="filteredLeftOptionsId.includes(option.id)">
        {{ option.name }}
        </option>
      </select>
      <div class="selected-items">
        <div v-show="selectedLeftNames.length > 0">
          {{ $t('components.abk_multiselect_mpaing.selected') }} {{ selectedLeftNames.join(', ') }}
        </div>
      </div>
    </b-col>
    <b-col class="col-12 col-sm-6">
      <label class="pcg-input-label" :for="`multi-select-maping-right-${id}`">{{ rightLabel }}</label>
      <pcg-text-input autocomplete="off" v-model="rightRegexp" :show-label="false" :placeholder="rightSearchPlaceholder"/>
      <select multiple
              class="form-control"
              v-model="selectedRightModel"
              :id="`multi-select-maping-right-${id}`">
        <option v-for="option in rightOptions"
                :key="option.id"
                :value="option.id"
                v-show="filteredRightOptionsId.includes(option.id)">
          {{ option.name }}
        </option>
      </select>
      <div class="selected-items">
        <div v-show="selectedRightNames.length > 0">
          {{ $t('components.abk_multiselect_mpaing.selected') }} {{ selectedRightNames.join(', ') }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'AbkMultiSelectMaping',
  props: {
    leftLabel: String,
    rightLabel: String,
    leftSearchPlaceholder: String,
    rightSearchPlaceholder: String,
    selectedLeft: {
      type: Array,
      default: () => []
    },
    selectedRight: {
      type: Array,
      default: () => []
    },
    leftOptions: {
      type: Array,
      default: () => []
    },
    rightOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      id: this._uid.toString(),
      leftRegexp: '',
      rightRegexp: ''
    }
  },
  computed: {
    filteredLeftOptionsId () {
      const regexp = new RegExp(this.leftRegexp, 'i')
      return this.leftOptions.filter(e => e.name.match(regexp)).map(e => e.id)
    },
    filteredRightOptionsId () {
      const regexp = new RegExp(this.rightRegexp, 'i')
      return this.rightOptions.filter(e => e.name.match(regexp)).map(e => e.id)
    },
    selectedLeftNames () {
      return this.leftOptions.filter(e => this.selectedLeft.includes(e.id)).map(e => e.name)
    },
    selectedRightNames () {
      return this.rightOptions.filter(e => this.selectedRight.includes(e.id)).map(e => e.name)
    },
    selectedLeftModel: {
      get () {
        return this.selectedLeft
      },
      set (val) {
        this.update('selectedLeft', [val[0]])
      }
    },
    selectedRightModel: {
      get () {
        return this.selectedRight
      },
      set (val) {
        this.update('selectedRight', val)
      }
    }
  },
  methods: {
    update (selectedSide, newValue) {
      this.$emit(selectedSide, newValue)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .form-control {
    min-height: 400px;
  }

  .selected-items {
    color: $main-color;
    font-size: $font-size-s;
    margin-top: 5px;
    margin-bottom: 14px;
    min-height: 18px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .selected-items {
      color: $main-color;
    }
  }
</style>
