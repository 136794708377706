<template>
<div>
  <b-row>
    <abk-loader v-if="showLoader" class="col-12 with-margins"/>
    <template v-else>
      <div v-if="userMessages.length > 0">
        <abk-message v-for="userMessage in userMessages"
                     class="col-12 message"
                     :title="userMessage.title"
                     :highlighted="userMessage.unread"
                     :key="userMessage.id"
                     :sender-name="$t('general.career_office_team')"
                     :date="userMessage.date"
                     :message="userMessage.content"
                     :attachments="userMessage.attachments"
                     :important="userMessage.important"
                     @click.native="markAsRead(userMessage.id)">
          {{ userMessage.content }}
        </abk-message>
      </div>
      <pcg-box v-else class="col-12">
        <span class="pcg-no-records">{{ $t('views.user_messages.user_messages_index.no_records') }}</span>
      </pcg-box>
    </template>
  </b-row>
  <pcg-pagination v-model="currentPage"
                  @input="getUserMessages"
                  :page-count="pages"
                  :total-records="totalRecords"
                  class="col-12 justify-content-center my-pagination"/>
</div>
</template>

<script>
import AbkMessage from '../../components/AbkMessage'
import ApiUserMessages from '../../api/user_messages'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'UserMessagesIndex',
  components: { AbkLoader, AbkMessage },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      pages: 0,
      totalRecords: 0,
      userMessages: []
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.getUserMessages()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.user_messages.user_messages_index.title')
      })
    },
    getUserMessages () {
      ApiUserMessages.getUserMessages(this.currentPage)
        .then(response => {
          const pagination = response.data.meta.pagination
          this.pages = pagination.pages
          this.totalRecords = pagination.count

          this.userMessages = response.data.data.map(userMessage => {
            const message = response.data.included.find(inclusion => inclusion.id === userMessage.relationships.message.data.id)
            const messageAttachments = response.data.included.filter(inclusion => {
              return message.relationships.messageAttachments.data.map(e => e.id).includes(inclusion.id) &&
                inclusion.type === 'message_attachment'
            })
            return {
              id: userMessage.id,
              title: message.attributes.title,
              content: message.attributes.content,
              date: message.attributes.sentAt,
              unread: userMessage.attributes.status === 'recent',
              attachments: messageAttachments.map(({ id, attributes }) => {
                return {
                  id: id,
                  filename: attributes.filename
                }
              })
            }
          })
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.user_messages.user_messages_index.download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    markAsRead (userMessageId) {
      const userMessage = this.userMessages.find(userMessage => userMessage.id === userMessageId)
      if (!userMessage.unread) { return }

      ApiUserMessages.markAsRead(userMessageId)
        .then(() => {
          userMessage.unread = false
          this.$store.dispatch('notifications/getMessages')
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .message {
    cursor: pointer;
    /deep/ &.dimmed {
      cursor: default;
    }
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
