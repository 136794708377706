<template>
  <abk-survey-form :survey-id="$route.params.id" :submit-action="updateSurvey" :validate-action="validate"/>
</template>

<script>
import ApiSurveys from '../../api/surveys'
import AbkSurveyForm from '../../components/surveys/AbkSurveyForm'

export default {
  name: 'SurveysEdit',
  components: { AbkSurveyForm },
  methods: {
    validate (form, surveyId) {
      return ApiSurveys.updateValidateStep(surveyId, form)
    },
    updateSurvey (form, surveyId) {
      return ApiSurveys.updateSurvey(surveyId, form)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.surveys.surveys_edit.title'),
        subtitle: this.$t('views.surveys.surveys_edit.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
