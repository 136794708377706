<template>
  <abk-offer-form :offer-id="$route.params.id" :submit-action="updateOffer" :validate-action="validate"/>
</template>

<script>
import AbkOfferForm from '../../components/AbkOfferForm'
import ApiOffers from '../../api/offers'
export default {
  name: 'OffersEdit',
  components: { AbkOfferForm },
  methods: {
    validate (form, id) {
      return ApiOffers.updateValidateStep(form, id)
    },
    updateOffer (form, offerId) {
      return ApiOffers.updateOffer(form, offerId)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_edit.title'),
        subtitle: this.$t('views.offers.offers_edit.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
