<template>
  <abk-event-form :submit-action="createEvent" :validate-action="validate"/>
</template>

<script>
import AbkEventForm from '../../components/events/AbkEventForm'
import ApiEvents from '../../api/events'
import ApiCompanies from '../../api/companies'
import { app } from '../../index'

export default {
  name: 'EventsNew',
  components: { AbkEventForm },
  methods: {
    validate (form) {
      return ApiEvents.validateStep(form)
    },
    createEvent (form) {
      return ApiEvents.createEvent(form)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.events_new.title'),
        subtitle: this.$t('views.events.events_new.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    if (app && (app.$store.getters['auth/currentRole'] === 'employer' || app.$store.getters['auth/currentRole'] === 'recruiter')) {
      ApiCompanies.getMyCompanyStatus()
        .then(response => {
          if (response.data.status !== 'accepted') {
            app.$swal({
              title: app.$t('general.no_access'),
              text: app.$t('views.events.events_new.no_access_text')
            })
            next(from)
          }
        })
    }
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
