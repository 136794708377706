<template>
  <i class="fas abk-caret" :class="open ? 'fa-caret-up' : 'fa-caret-down'"></i>
</template>

<script>
export default {
  name: 'AbkCaret',
  props: {
    open: Boolean
  }
}
</script>

<style lang="scss" scoped>

</style>
