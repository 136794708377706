<template>
  <div class="events">
    <transition name="slide" mode="out-in">
      <router-view :key="$route.params.id" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'events-view'
}
</script>

<style lang="scss" scoped>

</style>
