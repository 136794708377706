<template>
  <div>
    <template v-if="env && env.newEventsView">
      <div class="box-container col-12 col-sm-6 col-xl-12 col">
        <div class="custom-box">
          <div class="img-box d-flex justify-content-center align-items-center">
            <img v-if="info.eventMainImage64" :src="info.eventMainImage64" :alt="info.eventTitle" />
            <img v-else-if="info.eventMainImage && info.eventMainImage.url" :src="info.eventMainImage.url" :alt="info.eventTitle" />
            <img v-else src="../../assets/images/event-placeholder.jpg" :alt="info.eventTitle" />
          </div>
          <div class="d-flex align-items-center flex-wrap prop-flex p-3">
            <template v-for="property in newProperties">
              <span v-if="formattedProperty(property, info[property]) !== null" :key="property" class="property-container-tags pcg-txt-small-bold value">{{ formattedProperty(property, info[property]) }}</span>
            </template>
          </div>
          <div v-if="info && info.tags" class="pcg-txt-small-bold tags">{{ info.tags.join(', ') }}</div>
        </div>
      </div>
    </template>
    <template>
      <pcg-box>
        <div class="pcg-box-inner-header">
          <i class="pcg-icon-info"></i>{{ $t('components.events.abk_event_quick_info.title') }}
        </div>
        <template v-for="property in properties">
          <div v-if="formattedProperty(property, info[property]) !== null" class="property-container" :key="property">
            <span class="pcg-txt-small-bold property">
              {{ $t(`components.events.abk_event_quick_info.${decamelizeString(property)}`) }}
            </span>
            <br/>
            <span class="pcg-txt-small-bold value">{{ formattedProperty(property, info[property]) }}</span>
          </div>
        </template>
        <div v-if="info && info.tags" class="pcg-txt-small-bold tags">{{ info.tags.join(', ') }}</div>
      </pcg-box>
    </template>
    <template v-if="info.competences && info.competences.length > 0">
      <pcg-box>
        <div class="pcg-box-inner-header">
          <i class="pcg-icon-info"></i>{{ $t('components.events.abk_event_quick_info.competences') }}
        </div>
        <div class="competence" v-for="competence in info.competences" :key="competence.id">
          {{ competence.name }}
        </div>
      </pcg-box>
    </template>
  </div>
</template>

<script>
import { decamelize } from '@ridi/object-case-converter'
import { mapGetters } from 'vuex'

export default {
  name: 'AbkEventQuickInfo',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    decamelizeString (value) {
      return Object.keys(decamelize({ [value]: null }))[0]
    },
    formattedProperty (name, value) {
      if (!Object.prototype.hasOwnProperty.call(this.info, name)) {
        return null
      }
      if (name === 'freePlaces' && Object.prototype.hasOwnProperty.call(this.info, 'currentlyFreePlaces')) {
        return null
      }
      if (['currentlyFreePlaces', 'freePlaces'].includes(name)) {
        const currentlyFreePlaces = Object.prototype.hasOwnProperty.call(this.info, 'currentlyFreePlaces')
          ? this.info.currentlyFreePlaces
          : this.info.freePlaces

        return value === null
          ? this.$t('components.events.abk_event_quick_info.unlimited')
          : `${currentlyFreePlaces}/${this.info.freePlaces}`
      }
      if (name === 'reservePlaces' && Object.prototype.hasOwnProperty.call(this.info, 'currentlyReservePlaces')) {
        return null
      }
      if (['currentlyReservePlaces', 'reservePlaces'].includes(name)) {
        const currentlyReservePlaces = Object.prototype.hasOwnProperty.call(this.info, 'currentlyReservePlaces')
            ? this.info.currentlyReservePlaces
            : this.info.reservePlaces

        return value === null
            ? this.$t('components.events.abk_event_quick_info.unlimited')
            : `${currentlyReservePlaces}/${this.info.reservePlaces}`
      }
      if (name === 'where' && this.placeWithAddress) {
        return this.placeWithAddress
      }
      if (value == null) {
        return null
      }
      if (name === 'cost' && value <= 0) {
        return this.$t('components.events.abk_event_quick_info.free')
      }
      if (name === 'startDate' || name === 'endDate' || name === 'registrationStart' || name === 'registrationEnd') {
        return this.formatDate(value)
      }
      if (name === 'eventType') {
        return this.$te(`general.event_type.${value}`) ? this.$t(`general.event_type.${value}`) : value
      }
      if (name === 'instructor' && this.info.eventType === 'counseling') {
        return value.fullName
      }

      return value
    },
    formatDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null
    }
  },
  computed: {
    ...mapGetters('environment', ['env']),
    properties () {
      return ['startDate', 'endDate', 'registrationStart', 'registrationEnd', 'where', 'eventType', 'instructor', 'cost', 'freePlaces', 'currentlyFreePlaces', 'reservePlaces', 'currentlyReservePlaces']
    },
    newProperties () {
      if (this.env && this.env.newEventsView) {
        return ['eventType', 'instructor']
      } else {
        return []
      }
    },
    placeWithAddress () {
      return _.compact([this.info.place, this.info.address]).join(', ')
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .prop-flex {
    grid-row-gap: 1rem;
  }

  .property-container-tags {
    padding: .2rem;
    background-color: $pcg-lightest-gray;
    color: $pcg-gray;
    display: block;
    margin-right: 1rem;
    font-size: 0.69em;
    font-weight: 500;
    line-height: 1rem;
    width: max-content;
  }

  .box-container {
    margin-bottom: 20px;

    .custom-box {
      background: $pcg-white;
      border-radius: 15px;
      -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
      overflow: hidden;

      .img-box {
        height: 215px;
      }

      & > div > img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .property-container {
    margin-bottom: $font-size-m;
    line-height: .7;
  }
  .property {
    color: $pcg-light-gray;
  }
  .value {
    color: $main-color;
  }
  .tags {
    color: $main-active-color;
    margin: $font-size-l 0;
  }
  .competence {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-m;
    margin: $font-size-l 0;
  }
</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .property-container-tags {
      background-color: $pcg-lightest-gray;
      color: $pcg-gray;
    }
    .box-container {
      margin-bottom: 20px;

      .custom-box {
        background: $pcg-white;
      }
    }
    .property {
      color: $pcg-light-gray;
    }
    .value {
      color: $main-color;
    }
    .tags {
      color: $main-active-color;
    }
    .competence {
      color: $main-color;
    }
  }
</style>
