<template>
  <abk-offer-invitations-list offer-invitations-type="all"/>
</template>

<script>
import AbkOfferInvitationsList from '../../../components/offer_invitations/AbkOfferInvitationsList'

export default {
  name: 'OfferInvitationsAllTab',
  components: { AbkOfferInvitationsList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offer_invitations.list_tabs.all.title'),
        subtitle: this.$t('views.offer_invitations.list_tabs.all.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
