<template>
  <div class="role-choose d-flex justify-content-center">
    <div class="background"/>
    <b-row>
      <b-col>
        <img src="../assets/images/logo.svg" class="logo" />
        <img src="../assets/images/logo-negatyw.svg" class="logo-negatyw" />
        <div class="name">{{ fullName }}</div>
        <pcg-btn @click="pickRole('graduate')">{{ $t('views.role_choose.graduate_btn') }}</pcg-btn>
        <div>{{ $t('general.or') }}</div>
        <pcg-btn @click="pickRole('employer')">{{ $t('views.role_choose.employer_btn') }}</pcg-btn>
      </b-col>
    </b-row>
    <b-modal ref="acceptancesModal"
             centered hide-header
             v-bind:ok-title="$t('general.save_and_login')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="submitAcceptances"
             @cancel="logout"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2 class="missing-approvals">{{ $t('views.edit_company_profile.approvals') }}</h2>
      <div class="missing-approvals-text">{{ $t('general.missing_approvals') }}</div>
      <h3 v-if="currentAcceptances.cv && currentAcceptances.cv.length > 0">{{ $t('general.student_graduate') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
             v-for="(acceptance, index) in currentAcceptances.cv"
             :key="`cv-approval-${index}`">
        <pcg-checkbox variant="normal"
                      class="col-12"
                      :required="acceptance.required"
                      :show-error="errorExist(`acceptances.${index}.value`)"
                      :error-text="getError(`acceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </b-row>
      <h3 v-if="currentAcceptances.company && currentAcceptances.company.length > 0">{{ $t('general.employer_recruiter') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
             v-for="(acceptance, index) in currentAcceptances.company"
             :key="`company-approval-${index}`">
        <pcg-checkbox variant="normal"
                      class="col-12"
                      :required="acceptance.required"
                      :show-error="errorExist(`acceptances.${index}.value`)"
                      :error-text="getError(`acceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ApiUsers from '../api/users'
import ApiDictionaryAcceptances from '../api/dictionary_acceptances'
import CompanyPopupMixin from '../mixins/company_popup'
import CvPopupMixin from '../mixins/cv_popup'
import { mapGetters } from 'vuex'

export default {
  name: 'RoleChoose',
  mixins: [CompanyPopupMixin, CvPopupMixin],
  data: function () {
    return {
      pickedRole: null,
      cvAcceptances: [],
      companyAcceptances: [],
      currentAcceptances: [],
      errors: []
    }
  },
  created () {

  },
  mounted () {
    this.initializeAcceptances()
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('environment', ['env']),
    fullName () {
      return this.$store.getters['auth/fullName']
    },
    cvAcceptancesToSend () {
      return this.currentAcceptances.cv.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    },
    companyAcceptancesToSend () {
      return this.currentAcceptances.company.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    }
  },
  methods: {
    logout () {
      const role = this.currentRole
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${this.env.abkOpEndSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${this.env.abkOpEndSsessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    async checkCurrentUserAcceptances (role) {
      let valid = false
      try {
        const response = await ApiUsers.checkAcceptances(role)
        valid = response.data.cvAcceptance && response.data.companyAcceptance
        if (!valid) {
          if (role === 'student' || role === 'graduate') {
            response.data.currentCvAcceptances.forEach(acc => {
              const idx = this.cvAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.cvAcceptances[idx].value = acc.value
                this.cvAcceptances[idx].type = 'cv'
              }
            })
          }
          if (role === 'employer' || role === 'recruiter') {
            response.data.currentCompanyAcceptances.forEach(acc => {
              const idx = this.companyAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.companyAcceptances[idx].value = acc.value
                this.companyAcceptances[idx].type = 'company'
              }
            })
          }
        }
        return valid
      } catch (err) {
        return false
      }
    },
    submitAcceptances (e) {
      e.preventDefault()
      this.setRole(this.pickedRole)
    },
    initializeAcceptances () {
      ApiDictionaryAcceptances.getDictionaryAcceptanceAllWords()
        .then(res => {
          this.cvAcceptances = []
          this.companyAcceptances = []
          res.data.data.forEach((dw) => {
            if (dw.attributes.registration) {
              const a = {
                key: dw.attributes.type,
                dictionaryAcceptanceWordId: dw.id,
                required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                text: dw.attributes.value,
                value: false,
                name: dw.attributes.name,
                wordType: dw.attributes.wordType
              }
              const b = {
                key: dw.attributes.type,
                dictionaryAcceptanceWordId: dw.id,
                required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                text: dw.attributes.value,
                value: false,
                name: dw.attributes.name,
                wordType: dw.attributes.wordType
              }
              this.cvAcceptances.push(a)
              this.companyAcceptances.push(b)
            }
          })
          let sortByName = []
          sortByName.push(...this.cvAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
          this.cvAcceptances = sortByName
          sortByName = []
          sortByName.push(...this.companyAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
          this.companyAcceptances = sortByName
        })
        .catch(err => {
          this.$toastr.e(err)
          console.log(err)
        })
    },
    async pickRole (role) {
      const hasValidAcceptances = await this.checkCurrentUserAcceptances(role)
      if (hasValidAcceptances) {
        this.setRole(role)
      } else {
        this.pickedRole = role
        let result = []
        if (role === 'employer' || role === 'recruiter') {
          result.push(this.companyAcceptances.filter(a => !a.value && (a.key === 'employer' || a.key === 'cooperation' || a.key === 'survey')))
        }
        if (role === 'student' || role === 'graduate') {
          result.push(this.cvAcceptances.filter(a => !a.value && (a.key === 'cv' || a.key === 'survey')))
        }
        result = result.flat()
        this.currentAcceptances = result
        const sortByName = []
        sortByName.push(...this.currentAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
        this.currentAcceptances = sortByName
        this.currentAcceptances = {
          cv: this.currentAcceptances.filter(acc => acc.type === 'cv'),
          company: this.currentAcceptances.filter(acc => acc.type === 'company')
        }
        this.$refs.acceptancesModal.show()
      }
    },
    setRole (role) {
      ApiUsers.updateMissingRoles({ user: { roles: role, cvAcceptances: this.cvAcceptancesToSend, companyAcceptances: this.companyAcceptancesToSend } })
        .then(response => {
          this.$store.dispatch('auth/fetchUser')
            .then(response => {
              const redirectTo = this.$store.getters['auth/getRedirectTo']
              if (redirectTo && redirectTo.name) {
                this.$store.dispatch('auth/clearRedirectTo')
                this.$router.push(redirectTo)
              } else {
                const userRole = this.$store.getters['auth/currentRole']
                if (['student', 'graduate'].includes(userRole)) {
                  this.$router.push({ name: 'student_dashboard_path' })
                } else {
                  this.$router.push({ name: 'root_path' })
                }
              }
              this.$router.go()
              this.checkCompany()
              this.checkCv()
            })
            .catch(error => {
              console.log(error.response.data.message)
              this.$toastr.e(error.response.data.message)
            })
        }).catch(error => {
          this.errors = error.response.data
          this.$toastr.e(this.$t('general.fields_not_filled'))
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .role-choose {
    text-align: center;
    padding-top: 20vh;
    .background {
      background-image: url('../assets/images/login/role_choose_bcg.jpeg');
      position: fixed;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
      }
    }
    .logo {
      max-width: 255px;
    }
    .name {
      padding-top: 40px;
      padding-bottom: 30px;
      color: $main-color;
      font-weight: bold;
      font-size: $font-size-xl;
    }
    button {
      min-width: 300px;
    }
  }

  .logo-negatyw{
    display: none;
  }

  .missing-approvals {
    margin-bottom: 2rem;
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
  }

  .missing-approvals-text {
    margin-bottom: 2rem;
    font-weight: 400;
    color: $pcg-gray;
  }

</style>

<style lang="scss" >
@import "../assets/stylesheets/vars-contrast";
  .contrast{
    .logo-negatyw{
      display: inline-block;
    }
    .logo{
      display: none;
    }
    .missing-approvals {
      color: $pcg-gray;
    }
    .missing-approvals-text {
      color: $pcg-gray;
    }
  }

</style>
