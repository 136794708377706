<template>
  <abk-event-form :event-id="$route.params.id" :submit-action="updateEvent" :validate-action="validate"/>
</template>

<script>
import ApiEvents from '../../api/events'
import AbkEventForm from '../../components/events/AbkEventForm'
export default {
  name: 'EventsEdit',
  components: { AbkEventForm },
  methods: {
    validate (form, id) {
      return ApiEvents.updateValidateStep(form, id)
    },
    updateEvent (form, eventId) {
      return ApiEvents.updateEvent(form, eventId)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.events_edit.title'),
        subtitle: this.$t('views.events.events_edit.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
