<template>
  <pcg-box>
    <div>
      <b-row>
        <b-col v-if="currentOffers.logo && currentOffers.logo.url" class="col-12 col-sm-3 d-flex align-items-center">
          <img :src="currentOffers.logo.url" class="img-fluid d-block mx-auto">
        </b-col>
        <b-col class="col-12 col-sm-9">
          <div class="header">
            <span class="d-flex justify-content-center title">
              <span class="mr-3">{{ currentOffers.name }}</span>
              <span v-if="currentOffers.employerType === 'partner'" class="partner-container">
                <span class="partner-text">{{ currentOffers.employerTypeText }}</span>
                <img v-if="contrast" :src="currentOffers.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
                <img v-else :src="currentOffers.employerTypeLogo" class="img-fluid rounded partner-logo">
              </span>
            </span>
            <span class="d-flex justify-content-center socials">
              <a v-if="currentOffers.facebook" v-bind:href="getValidLink(currentOffers.facebook)" target="_blank">
                <i class="fab fa-facebook-square fa-2x socials"></i>
              </a>
              <a v-if="currentOffers.twitter" v-bind:href="getValidLink(currentOffers.twitter)" target="_blank">
                <i class="fab fa-twitter-square fa-2x socials"></i>
              </a>
              <a v-if="currentOffers.linkedin" v-bind:href="getValidLink(currentOffers.linkedin)" target="_blank">
                <i class="fab fa-linkedin fa-2x icon"></i>
              </a>
            </span>
          </div>
          <router-link v-if="showCareerOfficeActions || acceptedCompany" :to="offersPath" class="offer-link">
            {{ offers(currentOffers.offersCount) }}
          </router-link>
          <div class="content" v-html="currentOffers.shortDescription"></div>
        </b-col>
      </b-row>
    </div>
  </pcg-box>
</template>

<script>
import { mapGetters } from 'vuex'
import WindowResizeMixin from '../mixins/window_resize'

export default {
  name: 'abk-current-offers',
  props: {
    currentOffers: {
      type: Object,
      default: () => {}
    },
    companyId: String
  },
  data () {
    return {
      originalContainerWidth: null
    }
  },
  mixins: [WindowResizeMixin],
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['acceptedCompany']),
    ...mapGetters('page', ['contrast']),
    offersPath () {
      return this.showStudentActions
        ? { name: 'offers_path', query: { search: { company: [this.companyId] } } }
        : { name: 'offers_list_path' }
    },
    showStudentActions () {
      return ['student', 'graduate'].includes(this.currentRole)
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    }
  },
  methods: {
    offers (numberOfOffers) {
      return this.$t('components.abk_current_offers.number_of_offers', { count: numberOfOffers })
    },
    getValidLink (link) {
      return !/^(http|https):\/\//.test(link) ? `http://${link}` : link
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .title {
    font-size: $font-size-xl;
    font-weight: 400;
    color: $main-color;
    display: inline;
    float: left;

    /deep/ i {
      font-size: $font-size-l;
      margin-right: .5rem;
    }
  }

  .socials {
    display: inline;
    float: right;
    margin-right: .4rem;
    color: $pcg-socials;
  }

  .icon {
    color: $pcg-socials;
  }

  .offer-link {
    color: $main-active-color;
  }

  .header {
    padding: .2em!important;
    display: inline-block;
    width: 100%;
    clear: both;
  }

  .content {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-gray;
    margin-top: $font-size-m;
  }

  .partner-container {
    font-size: .5em;
    display: flex;
    align-items: center;
  }

  .partner-text {
    color: $pcg-black;
    font-weight: bold;
    background-color: rgba(200, 200, 200, 0.5);
    padding: .15rem .4rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .partner-logo {
    position: relative;
    right: .1rem;
    max-width: 20px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .title {
      color: $main-color;
    }

    .socials {
      color: $pcg-socials;
    }

    .icon {
      color: $pcg-socials;
    }

    .offer-link {
      color: $main-active-color;
    }

    .partner-text {
      color: $pcg-black;
    }
  }
</style>
