<template>
  <div class="skills">
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'skills-view'
}
</script>

<style lang="scss" scoped>

</style>
