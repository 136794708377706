<template>
  <pcg-box>
    <abk-loader v-if="showLoader"/>
    <template v-else-if="workRecords.length > 0">
      <abk-offer-record v-for="record in workRecords" :key="record.id" :record="record" class="row offer-record"/>
    </template>
    <b-row v-else class="no-records">
      {{ $t("components.abk_work_record.no_records") }}
    </b-row>
  </pcg-box>
</template>

<script>
import AbkOfferRecord from './AbkOfferRecord'
import AbkLoader from './AbkLoader'
export default {
  name: 'abk-work-record',
  components: { AbkLoader, AbkOfferRecord },
  props: {
    workRecords: {
      type: Array,
      default: () => []
    },
    dictionaries: {
      type: Object,
      default: () => {}
    },
    showLoader: Boolean
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .offer-record {
    &:not(:last-child) {
      border-bottom: 2px solid $pcg-the-lightest-gray;
    }
  }
  .no-records {
    color: $pcg-gray;
    margin-left: .7rem;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    color: $pcg-gray;
    .offer-record {
      &:not(:last-child) {
        border-bottom: 2px solid $pcg-the-lightest-gray;
      }
    }
  }
</style>
