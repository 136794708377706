<template>
  <div>
    <b-row v-if="errors" style="margin-top: 5rem">
      <b-col cols="12" class="mt-3 d-flex justify-content-center">
        <span class="pcg-dark-gray-color">{{ errors }}</span>
      </b-col>
      <b-col cols="12" class="mt-3 d-flex justify-content-center">
        <pcg-text-input class="col-6"
                        autocomplete="on"
                        type="email"
                        name="email"
                        :label="$t('components.abk_user_registration_form.email')"
                        v-model="email"/>
      </b-col>
      <b-col cols="12" class="mt-3 d-flex justify-content-center">
        <pcg-btn class="save-button mr-0" size="small" @click="reConfirm()">{{ $t('views.email_confirmation.reconfirm') }}</pcg-btn>
      </b-col>
    </b-row>
    <b-modal ref="confirmPassword"
             centered hide-header
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="changeStudentToGraduate"
             @cancel="confirmEmail"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2 class="modal-title">{{ $t('views.email_confirmation.modal_title') }}</h2>
      <div class="modal-text">{{ $t('views.email_confirmation.modal_text') }}</div>
      <pcg-text-input :show-label="true"
                      type="password"
                      :label="$t('components.abk_user_registration_form.password')"
                      v-model="password"/>
    </b-modal>
  </div>
</template>

<script>
import ApiUsers from '../api/users'
import { mapGetters } from 'vuex'
import { app } from '../index'

export default {
  name: 'email-confirmation',
  data () {
    return {
      email: null,
      errors: null,
      password: null,
      graduateId: null,
      studentId: null
    }
  },
  created () {
    this.confirmEmail()
    this.hideAppLoader()
  },
  watch: {
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.email_confirmation.title'),
        subtitle: this.$t('views.email_confirmation.subtitle')
      })
    },
    confirmEmail (skipValidateStudent = false) {
      ApiUsers.emailConfirmation(this.$route.params.token, { skipValidateStudent: skipValidateStudent })
        .then((response) => {
          if (response.data && !skipValidateStudent) {
            this.graduateId = response.data.graduateId
            this.studentId = response.data.studentId
          }
          if (this.graduateId && this.studentId) {
            this.$swal({
              showCancelButton: true,
              confirmButtonText: app.$t('general.change_student_to_graduate.confirm'),
              cancelButtonText: app.$t('general.change_student_to_graduate.dismiss'),
              title: app.$t('general.change_student_to_graduate.title'),
              text: app.$t('general.change_student_to_graduate.text')
            })
              .then(result => {
                if (result.dismiss) {
                  this.confirmEmail(true)
                } else {
                  this.$refs.confirmPassword.show()
                }
              })
          } else {
            this.$toastr.s(this.$t('views.email_confirmation.success_confirmation'))
            this.$router.push({ name: 'login_user_path' })
          }
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
            this.errors = error.response.data
          }
        })
    },
    changeStudentToGraduate () {
      this.$refs.confirmPassword.hide()
      ApiUsers.changeStudentToGraduate({ studentId: this.studentId, graduateId: this.graduateId, password: this.password })
        .then((response) => {
          this.$toastr.s(this.$t('views.email_confirmation.student_changed'))
          this.$router.push({ name: 'login_user_path' })
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
            this.$refs.confirmPassword.show()
          }
        })
    },
    reConfirm () {
      ApiUsers.emailReconfirmation({ email: this.email })
        .then((response) => {
          this.$toastr.s(this.$t('views.email_confirmation.email_reconfirmation'))
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
            this.errors = error.response.data
          }
        })
    },
    hideAppLoader () {
      setTimeout(() => {
        document.getElementById('app-loader').style.display = 'none'
      }, 500)
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/vars";
.modal-title {
  color: $main-color;
  margin-bottom: 2rem;
}
.modal-text {
  color: $pcg-dark-gray;
  margin-bottom: 2rem;
}
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .modal-title {
    color: $main-color;
  }
  .modal-text {
    color: $pcg-dark-gray;
  }
}
</style>
