<template>
  <div class="add-btn" role="button">
    <i :class="iconClass"></i>
    <br/>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'abk-add-button',
  props: {
    text: {
      type: String
    },
    iconClass: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .add-btn {
    color: $main-active-color;
    text-align: center;
    &:hover {
      color: $hover-color;
      cursor: pointer;
    }
    font-weight: bold;
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .add-btn {
      color: $main-active-color;
      &:hover {
        color: $hover-color;
      }
    }
  }
</style>
