<template>
  <div class="step-2">
    <div class="pcg-section-title">{{ $t('components.surveys.steps.abk_survey_step_2_form.title') }}</div>
    <div>
      <draggable :value="value.questions"
                 @input="updateForm('questions', $event)"
                 animation="200">
        <transition-group name="slide" mode="out-in">
          <template v-for="(question, index) in value.questions">
            <abk-survey-question-form v-if="!value.questions[index]._destroy"
                                      :value="question"
                                      :key="question.tmpId"
                                      :level="0"
                                      :errors="errors"
                                      :requires-answer="requiresAnswer"
                                      :new-question="question.isNew"
                                      :class="{ 'abk-white-border': showWhiteBorder(index) }"
                                      class="section-bcg"
                                      @input="updateQuestion($event, index)"
                                      @dontRequiresAnswer="$emit('dontRequiresAnswer')"
                                      @copy="copy($event, index)"
                                      @remove="remove(question, index)"
                                      @expand="$set(expandedForms, question.tmpId, $event)"/>
          </template>
        </transition-group>
      </draggable>
      <abk-survey-question-choose class="bottom-section"
                                  :class="{'abk-rounded-corners': !visibleQuestionsLength}"
                                  :requires-answer="requiresAnswer"
                                  @addQuestion="addQuestion"/>
    </div>
    <div v-if="errorExist('questions') && !visibleQuestionsLength"
         class="pcg-error-messages">
      {{ getError('questions') }}
    </div>
  </div>
</template>

<script>
import AbkSurveyQuestionChoose from '../form/AbkSurveyQuestionChoose'
import AbkSurveyQuestionForm from '../form/AbkSurveyQuestionForm'
import draggable from 'vuedraggable'

export default {
  name: 'AbkSurveyStep2Form',
  components: { AbkSurveyQuestionForm, AbkSurveyQuestionChoose, draggable },
  props: {
    value: Object,
    errors: Object,
    requiresAnswer: Boolean
  },
  data: function () {
    return {
      expandedForms: {}
    }
  },
  computed: {
    visibleQuestionsLength () {
      return this.value.questions.filter(question => !question._destroy).length
    }
  },
  methods: {
    showWhiteBorder (index) {
      return index < this.value.questions.length - 1 &&
       [index, index + 1].map(index => this.expandedForms[this.value.questions[index].tmpId]).every(e => e)
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateQuestion (question, index) {
      const questionsCopy = [...this.value.questions]
      questionsCopy.splice(index, 1, question)
      this.updateForm('questions', questionsCopy)
    },
    copy (question, index) {
      const questionsCopy = [...this.value.questions]
      const newQuestion = { ..._.omit(question, 'id'), questionFields: (question.questionFields || []).map(e => _.omit(e, 'id')) }
      questionsCopy.splice(index + 1, 0, newQuestion)
      this.updateForm('questions', questionsCopy)
    },
    remove (question, index) {
      if (question.edited || !question.isNew) {
        this.$swal({
          title: this.$t('general.are_you_sure'),
          showCancelButton: true,
          confirmButtonText: this.$t('general.delete'),
          cancelButtonText: this.$t('general.cancel')
        }).then(result => {
          if (result.value) {
            this.removeQuestion(question, index)
          }
        })
      } else {
        this.removeQuestion(question, index)
      }
    },
    removeQuestion (question, index) {
      if (Number.isInteger(question.id)) {
        const questionsCopy = [...this.value.questions]
        questionsCopy.splice(index, 1, { ...question, _destroy: true })
        this.updateForm('questions', questionsCopy)
      } else {
        const questionsCopy = [...this.value.questions]
        questionsCopy.splice(index, 1)
        this.updateForm('questions', questionsCopy)
      }
    },
    addQuestion (question) {
      const newQuestion = {
        ...question
      }
      this.$store.dispatch('tmpIds/generateTmpId').then(tmpId => {
        newQuestion.tmpId = tmpId
        this.updateForm('questions', [...this.value.questions, newQuestion])
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .step-2 {
    margin-bottom: 50px;
    .abk-white-border {
      position: relative;
      border-bottom: 2px solid $pcg-white;
    }
    /deep/ .section-bcg {
      background: $pcg-white;
      &:first-child {
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
      }
    }
    .bottom-section {
      background: $pcg-white;
      &.abk-rounded-corners {
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast{
    .step-2 {
      .abk-white-border {
        border-bottom: 2px solid $pcg-white;
      }
      ::v-deep .section-bcg {
        background: $pcg-white;
      }
      .bottom-section {
        background: $pcg-white;
      }
    }
  }
</style>
