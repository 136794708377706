<template>
  <div class="question-container">
    <div class="question-header">
      <button class="btn question-title no-borders p-0"
              :aria-expanded="value ? 'true' : 'false'"
              :aria-controls="collapseId"
              v-b-toggle="collapseId">
        <span class="question-text">{{ question.questionTitle }}</span>
        <abk-caret :open="value" class="caret"/>
      </button>
    </div>
    <b-collapse :visible="value" @input="$emit('input', $event)" :id="collapseId">
      <div v-if="answersCount.length > 0 || question.otherCount > 0" class="table-container">
        <table class="answers-table">
          <tr v-for="(answer, index) in answersCount" :key="`answer-${collapseId}-${index}`">
            <td class="answers-count">{{ answer.count }}</td>
            <td class="answer-value">{{ answer.value }}</td>
          </tr>
          <tr v-if="question.otherCount">
            <td class="answers-count">{{ question.otherCount }}</td>
            <td class="answer-value">
              <div class="other-answers-container">
                {{ $t('general.other') }}:
                <transition-group name="fade" mode="out-in">
                  <span v-for="(answer, index) in answers" :key="`other-answer-${index}`" class="other-answer">
                    <span class="answers-count other-answer-count">{{ answer.count }}</span>
                    <span class="answer-value other-answer-value">{{ `${answer.value},` }}</span>
                  </span>
                </transition-group>
              </div>
              <br/>
              <button v-if="filters.page < lastPage" class="other-values-btn pcg-link" @click="getOpenQuestionAnswers">
                {{ showOtherAnswersText }}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="pcg-no-records">
        {{ $t('components.surveys.abk_survey_stats_question_answers.no_records') }}
      </div>
    </b-collapse>
  </div>
</template>

<script>
import AbkCaret from '../AbkCaret'
import ApiOpenSurveyQuestionAnswers from '../../api/open_survey_question_answers'

export default {
  name: 'AbkSurveyStatsQuestionAnswers',
  components: { AbkCaret },
  props: {
    question: {
      type: Object,
      required: true
    },
    value: Boolean
  },
  data () {
    return {
      collapseId: `collapse-${this._uid.toString()}`,
      filters: {
        orderBy: 'count',
        orderDirection: 'desc',
        questionId: this.question.questionId,
        page: 1
      },
      answers: [],
      lastPage: 2
    }
  },
  computed: {
    answersCount () {
      return this.question.answersCount.filter(e => e.count > 0)
    },
    showOtherAnswersText () {
      return this.filters.page === 1
        ? this.$t('components.surveys.abk_survey_stats_question_answers.show')
        : this.$t('components.surveys.abk_survey_stats_question_answers.show_more')
    }
  },
  methods: {
    getOpenQuestionAnswers () {
      ApiOpenSurveyQuestionAnswers.getOpenSurveyQuestionAnswers(this.filters)
        .then(response => {
          this.setOpenQuestionAnswers(response.data.data)
          this.setPagination(response.data.meta.pagination)
        }).catch(error => {
          console.error(error)
        })
    },
    setOpenQuestionAnswers (data) {
      this.answers.push(...data.map(e => _.pick(e.attributes, ['count', 'value'])))
    },
    setPagination (data) {
      this.lastPage = data.last
      ++this.filters.page
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .question-container {
    background: transparent;
    transition: background-color 0.3s ease;
  }
  .no-borders {
    border: none !important;
  }
  .caret {
    padding-left: 15px;
  }
  .question-container {
    &:not(:last-child) {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 3px;
        right: 3px;
        height: 1px;
        background: $pcg-lightest-gray;
      }
    }
  }
  .table-container, .question-header, .pcg-no-records {
    padding: 13px $pcg-box-padding-x;
  }
  .question-header {
    position: relative;
    display: flex;
    align-items: center;
  }
  .question-title {
    display: flex;
    align-items: center;
    color: $main-color;
    overflow: hidden;
  }
  .question-text {
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
  }
  .answers-count {
    vertical-align: top;
    text-align: right;
    color: $pcg-gray;
    font-weight: 500;
    padding-left: $pcg-m-sm;
    padding-right: $pcg-m-xl;
  }
  .answer-value {
    color: $main-color;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
  }
  .answers-table {
    tr {
      &:not(:last-child) {
        td {
          padding-bottom: $pcg-m-xs;
        }
      }
    }
  }
  .other-answers-container {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .other-values-btn {
    border: none;
    background: none;
    outline: none;
  }
  .other-answer-count {
    padding-right: 2px;
  }
  .other-answer-value {
    margin-right: $pcg-m-sm;
    white-space: nowrap;
  }
  .other-answer-count, .other-answer-value {
    display: inline-block;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .question-container {
      &:not(:last-child) {
        &:after {
          background: $pcg-lightest-gray;
        }
      }
    }
    .question-title {
      color: $main-color;
    }
    .answers-count {
      color: $pcg-gray;
    }
    .answer-value {
      color: $main-color;
    }
  }
</style>
