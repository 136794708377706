<template>
  <pcg-box :class="env && env.newEventsView && info.otherDates.length > 0 ? 'box-subbox' : ''">
    <template v-if="env && env.newEventsView">
      <div v-if="startDate || endDate" :class="{ 'margin-bottom': address || place}">
        <span class="title mb-3 d-block text-uppercase">{{ $t('components.events.abk_event_quick_info.chosen_date') }}</span>
        <b-container>
          <b-row class="align-items-center">
            <b-col lg="5" cols="12" class="p-0">
              <b-container class="dates_and_address p-0">
                <b-row class="justify-content-start align-items-center mb-2">
                  <b-col cols="1" class="d-flex justify-content-center pl-0">
                    <i style="font-weight: 500;" class="fa fa-clock pcg-lg icon mr-0"/>
                  </b-col>
                  <b-col cols="6" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('general.from') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[0] }}</span><span v-if="showOnlyStartDate" style="font-size: 0.97em;" class="range pcg-txt-small property"> - {{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[1] }}</span></span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[0] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[0] }}</p>
                  </b-col>
                  <b-col v-if="!showOnlyStartDate" cols="5" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[1] }}</span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[1] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[1] }}</p>
                  </b-col>
                </b-row>
                <b-row class="justify-content-start align-items-center mb-2">
                  <b-col cols="1" class="d-flex justify-content-center pl-0">
                    <i style="font-weight: 500;" class="fa fa-clock pcg-lg icon mr-0"/>
                  </b-col>
                  <b-col cols="6" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('components.events.abk_event_quick_info.registration_start') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[2] }}</span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[2] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[2] }}</p>
                  </b-col>
                  <b-col cols="5" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('components.events.abk_event_quick_info.registration_end') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[3] }}</span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[3] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[3] }}</p>
                  </b-col>
                </b-row>
                <b-row class="justify-content-start align-items-center">
                  <b-col cols="1" class="d-flex justify-content-center pl-0">
                    <i class="pcg-icon-location-alt pcg-lg icon mr-0"/>
                  </b-col>
                  <b-col cols="10" class="pl-0">
                    <div class="content">{{ fullLocation }}</div>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col lg="1" cols="12">
              <div class="vr"></div>
            </b-col>
            <b-col lg="6" cols="12" class="p-0">
                <b-row>
                  <b-col :cols="formattedProperty('cost', info['cost']) === null ? '12' : '7'" class="d-flex align-items-center places" :class="info.currentlyFreePlaces === 0 ? info.currentlyReservePlaces === 0 ? 'red-text' : 'orange-text' : 'green-text'">
                    <i class="fa fa-user-plus pcg-lg icon"/>
                    <div v-if="formattedProperty('currentlyFreePlaces', info['currentlyFreePlaces']) !== null" class="property-container-places d-flex align-items-center content" :class="info.currentlyFreePlaces === 0 ? info.currentlyReservePlaces === 0 ? 'red-text' : 'orange-text' : 'green-text'">
                      <p v-if="info.currentlyFreePlaces === null" class="mb-0 ml-2">
                        {{ $t('components.events.abk_event_quick_info.unlimited_places') }}
                      </p>
                      <p v-else-if="info.currentlyFreePlaces !== 0" class="mb-0 ml-2">
                          {{ $tc('components.events.abk_event_quick_info.free_places_left', parseInt(formattedProperty('currentlyFreePlaces', info['currentlyFreePlaces']))) }}
                      </p>
                      <p v-else-if="info.currentlyReservePlaces === null" class="mb-0 ml-2">
                        {{ $t('components.events.abk_event_quick_info.unlimited_reserve_places') }}
                      </p>
                      <p v-else-if="info.currentlyReservePlaces !== 0" class="mb-0 ml-2">
                        {{ $tc('components.events.abk_event_quick_info.reserve_places_left', parseInt(formattedProperty('currentlyReservePlaces', info['currentlyReservePlaces']))) }}
                      </p>
                      <p v-else class="mb-0 ml-2">
                        {{ $t('components.events.abk_event_quick_info.no_places') }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="5" class="d-flex align-items-center">
                    <template v-if="formattedProperty('cost', info['cost']) !== null">
                      <i class="fa fa-money-bill-alt pcg-lg icon"/>
                      <div class="property-container content">
                        <p class="mb-0">
                          {{ $t('components.events.abk_event_quick_info.cost') }} {{ formattedProperty('cost', info['cost']) }} zł
                        </p>
                      </div>
                    </template>
                  </b-col>
                </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <abk-additional-dates v-if="info && info.otherDates.length > 0"
                            :other-dates="otherDates"
                            @removeSubevent="removeSubevent"
                            :show-employer-actions="showEmployerActions"
                            :show-career-office-actions="showCareerOfficeActions"
                            :is-not-draft="isNotDraft"/>
    </template>
    <template v-else>
      <div v-if="startDate || endDate" class="d-flex align-items-center" :class="{ 'margin-bottom': address || place}">
        <i class="pcg-icon-calendar-dates pcg-2x icon"/>
        <div>
          <div class="label">{{ $t('general.when') }}:</div>
          <div class="content">
            <span class="range">{{ daysNamesRange }}</span>
            <span class="date range">{{ datesRange }}</span>
            <span class="range">{{ timeRange }}</span>
          </div>
        </div>
      </div>
      <div v-if="address || place" class="d-flex align-items-center">
        <i class="pcg-icon-location-alt pcg-2x icon"/>
        <div>
          <div class="label">{{ $t('general.where') }}:</div>
          <div class="content">{{ fullLocation }}</div>
        </div>
      </div>
    </template>
  </pcg-box>
</template>

<script>
import { decamelize } from '@ridi/object-case-converter'
import { mapGetters } from 'vuex'
import AbkAdditionalDates from './AbkAdditionalDates'

export default {
  name: 'AbkTimeAndPlaceBox',
  components: {
    AbkAdditionalDates
  },
  props: {
    startDate: [String, Date],
    endDate: [String, Date],
    place: String,
    address: String,
    otherDates: {
      type: Array,
      default: () => []
    },
    info: {
      type: Object,
      default: () => {}
    },
    showEmployerActions: Boolean,
    showCareerOfficeActions: Boolean,
    isNotDraft: Boolean
  },
  computed: {
    ...mapGetters('environment', ['env']),
    daysNamesRange () {
      return this.getRangeString(...this.getFormattedDatesArray('dddd', true).map(day => _.capitalize(day)))
    },
    datesRange () {
      return this.getFormattedDatesRange('DD.MM.YYYY', true)
    },
    timeRange () {
      return this.getFormattedDatesRange('HH.mm')
    },
    fullLocation () {
      return _.compact([this.place, this.address]).join(', ')
    },
    showOnlyStartDate () {
      return this.getFormattedDate(this.startDate, 'DD.MM.YYYY') === this.getFormattedDate(this.endDate, 'DD.MM.YYYY')
    }
  },
  methods: {
    decamelizeString (value) {
      return Object.keys(decamelize({ [value]: null }))[0]
    },
    formattedProperty (name, value) {
      if (!Object.prototype.hasOwnProperty.call(this.info, name)) {
        return null
      }
      if (name === 'freePlaces' && Object.prototype.hasOwnProperty.call(this.info, 'currentlyFreePlaces')) {
        return null
      }
      if (['currentlyFreePlaces', 'freePlaces'].includes(name)) {
        const currentlyFreePlaces = Object.prototype.hasOwnProperty.call(this.info, 'currentlyFreePlaces')
          ? this.info.currentlyFreePlaces
          : this.info.freePlaces

        return value === null
          ? this.$t('components.events.abk_event_quick_info.unlimited')
          : `${currentlyFreePlaces}`
      }
      if (name === 'reservePlaces' && Object.prototype.hasOwnProperty.call(this.info, 'currentlyReservePlaces')) {
        return null
      }
      if (['currentlyReservePlaces', 'reservePlaces'].includes(name)) {
        const currentlyReservePlaces = Object.prototype.hasOwnProperty.call(this.info, 'currentlyReservePlaces')
            ? this.info.currentlyReservePlaces
            : this.info.reservePlaces

        return value === null
            ? this.$t('components.events.abk_event_quick_info.unlimited')
            : `${currentlyReservePlaces}`
      }
      if (name === 'where' && this.placeWithAddress) {
        return this.placeWithAddress
      }
      if (value == null) {
        return null
      }
      if (name === 'cost' && value <= 0) {
        return this.$t('components.events.abk_event_quick_info.free')
      }
      if (name === 'startDate' || name === 'endDate') {
        return this.formatDate(value)
      }
      if (name === 'eventType') {
        return this.$te(`general.event_type.${value}`) ? this.$t(`general.event_type.${value}`) : value
      }
      if (name === 'instructor' && this.info.eventType === 'counseling') {
        return value.fullName
      }

      return value
    },
    getFormattedDatesRange (format, checkDate = false) {
      return this.getRangeString(...this.getFormattedDatesArray(format, checkDate))
    },
    getRangeString (startValue, endValue) {
      return _.compact([startValue, endValue]).join(' - ')
    },
    getFormattedDatesArray (format, checkDate = false) {
      const endDate = checkDate && this.showOnlyStartDate ? null : this.endDate
      return [this.startDate, endDate, this.info.registrationStart, this.info.registrationEnd].map(date => this.getFormattedDate(date, format))
    },
    getFormattedDate (date, format) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.format(format) : null
    },
    removeSubevent (id) {
      this.$emit('removeSubevent', id)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .box-container.box-subbox ::v-deep .box {
    padding-bottom: 0;
  }

  .vr {
    width: 100%;
    height: 2px;
    margin: 1rem auto;
    background-color: $pcg-light-gray;

    @media screen and (min-width: 992px) {
      width: 2px;
      height: 72px;
      margin: 0 auto;
    }
  }

  .title {
    font-weight: 900;
    text-transform: uppercase;
    color: $main-color;

    /deep/ i {
      font-size: $font-size-l;
      margin-right: .5rem;
    }
  }

  .property {
    color: $pcg-light-gray;
  }

  .places p, .places .fa.icon {
    color: $pcg-green-color;
  }

  .places.red-text p, .places.red-text .fa.icon {
    color: $pcg-red;
  }

  .places.orange-text p, .places.orange-text .fa.icon {
    color: $pcg-orange;
  }

  .places .fa.icon {
    margin-right: 0.7rem;
  }

  .margin-bottom {
    margin-bottom: $pcg-m-lg;
  }
  .icon {
    color: $pcg-light-gray;
    margin-right: $pcg-m-lg;
  }
  .label {
    color: $main-color;
    font-weight: bold;
  }
  .content {
    color: $pcg-gray;
    font-weight: 500;

    p {
      line-height: 1rem;
      font-size: .86em;
    }
  }
  .range {
    white-space: nowrap;
    margin-right: $pcg-m-sm;
  }
  .date {
    background-color: $pcg-lightest-gray;
    padding: $pcg-m-xs;
    border-radius: 4px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .vr {
      background-color: $pcg-light-gray;
    }
    .title {
      color: $main-color;
    }
    .property {
      color: $pcg-light-gray;
    }
    .places p, .places .fa.icon {
      color: $pcg-green-color;
    }
    .places.red-text p, .places.red-text .fa.icon {
      color: $pcg-red;
    }
    .places.orange-text p, .places.orange-text .fa.icon {
      color: $pcg-orange;
    }
    .icon {
      color: $pcg-light-gray;
    }
    .label {
      color: $main-color;
    }
    .content {
      color: $pcg-gray;
    }
    .date {
      background-color: transparent;
    }
  }
</style>
