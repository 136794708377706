export const components = {
  abk_actual_recrutations_box: {
    cv_match: 'Twoje CV idealnie pasuje do tej oferty!',
    days_left: 'Został mniej niż 1 dzień do końca | Został 1 dzień do końca | Zostały {count} dni do końca | ' +
      'Zostało {count} dni do końca',
    header: 'Aktualne oferty',
    no_offers: 'Brak ofert'
  },
  abk_add_to_favorites_button: {
    add: 'Dodaj do ulubionych',
    remove: 'Usuń z ulubionych'
  },
  abk_additional_sections_form: {
    add_subsection: 'dodaj podsekcję',
    background: 'Obraz tła',
    content: 'Treść',
    empty_section_error: {
      gallery: 'Nie można dodać nowej sekcji z galerią, ponieważ istnieje już jedna pusta sekcja z galerią.',
      text: 'Nie można dodać nowej sekcji tekstowej, ponieważ istnieje już jedna pusta tekstowa.',
      video: 'Nie można dodać nowej sekcji z wideo, ponieważ istnieje już jedna pusta sekcja z wideo.'
    },
    section_name: 'Nazwa',
    select_section_type: 'Wybierz typ sekcji',
    subsection_name: 'Nazwa podsekcji',
    subtitle: 'Secja tekstowa',
    title: 'Sekcje Dodatkowe'
  },
  abk_attachment_input: {
    text: 'Dodaj załącznik'
  },
  abk_attachments_box: {
    title: 'Załączniki do aplikacji'
  },
  abk_basic_info_form: {
    address: 'Adres',
    brief_description: 'Zwięzły opis',
    city: 'Miasto',
    company_name: 'Nazwa firmy',
    email: 'E-mail',
    facebook: 'Facebook',
    info: 'Wypełnij jak najwięcej informacji o swojej firmie.',
    linkedin: 'Linkedin',
    nip: 'NIP',
    phone: 'Telefon',
    progress: 'Procent wypełnienia profilu',
    title: 'Podstawowe informacje',
    twitter: 'Twitter',
    website: 'Strona internetowa'
  },
  abk_competences_box: {
    title: 'Kompetencje'
  },
  abk_contact: {
    header: 'Kontakt'
  },
  abk_content_preview: {
    attachments: 'Załączniki',
    header: 'Podgląd treści',
    hide: 'Zwiń',
    show: 'Rozwiń'
  },
  abk_current_offers: {
    number_of_offers: 'Aktualnych ofert pracy: {count}'
  },
  abk_cv_hobby_box: {
    title: 'Zainteresowania, hobby, dodatkowe aktywności'
  },
  abk_datetime_picker: {
    tooltips: {
      clear: 'Wyczyść',
      close: 'Zamknij',
      decrementHour: 'Zmniejsz godziny',
      decrementMinute: 'Zmniejsz minuty',
      decrementSecond: 'Zmniejsz sekundy',
      incrementHour: 'Zwiększ godziny',
      incrementMinute: 'Zwiększ minuty',
      incrementSecond: 'Zwiększ sekundy',
      nextCentury: 'Następny wiek',
      nextDecade: 'Następna dekada',
      nextMonth: 'Następny miesiąc',
      nextYear: 'Następny rok',
      pickHour: 'Wybierz godzinę',
      pickMinute: 'Wybierz minutę',
      pickSecond: 'Wybierz sekundę',
      prevCentury: 'Poprzedni wiek',
      prevDecade: 'Poprzednia dekada',
      prevMonth: 'Poprzedni miesiąc',
      prevYear: 'Poprzedni rok',
      selectDecade: 'Wybierz dekadę',
      selectMonth: 'Wybierz miesiąc',
      selectYear: 'Wybierz rok',
      today: 'Przejdź do dzisiaj'
    }
  },
  abk_employers_box: {
    no_records: 'Brak pracodwaców',
    offers: 'brak ofert | 1 oferta | {count} oferty | {count} ofert',
    title: 'poznaj pracodawców'
  },
  abk_file_input: {
    error_title: 'Wystąpił błąd',
    loading_title: 'Wczytywanie pliku',
    success_title: 'Plik załadowano pomyślnie',
    upload_title: 'Przeciągnij i upuść lub wybierz plik'
  },
  abk_gallery_section_form: {
    add_image: 'dodaj zdjęcie',
    subtitle: 'Zdjęcia',
    title: 'Galeria',
    title_placeholder: 'Nazwa'
  },
  abk_get_to_know_us: {
    atmosphere: 'atmosfera',
    benefits: 'benefity',
    career: 'kariera',
    title: 'Poznaj nas',
    what_we_do: 'co robimy?',
    who_we_seek: 'kogo szukamy?'
  },
  abk_header_navbar: {
    application_login: 'Pracodawca/Absolwent',
    login_btn: 'Zaloguj się',
    register_btn: 'Zarejestruj się'
  },
  abk_image_input: {
    add_file: 'Dodaj plik'
  },
  abk_invitation_box: {
    applied: 'Już zaaplikowałeś na tę ofertę',
    outdated_offer: 'Oferta nieaktualna'
  },
  abk_list_of_employers: {
    recruit: 'Zaproś do rekrutacji'
  },
  abk_list_of_offers: {
    applications: 'Zgłoszenia',
    matching_profiles: 'Pasujące profile',
    number: 'Numer',
    offer: 'Oferta',
    unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji'
  },
  abk_managing_events_box: {
    registrations: '0 zapisów | 1 zapis | {count} zapisy | {count} zapisów',
    no_records: 'Brak wydarzeń',
    events_count: 'Łączna liczba wydarzeń',
    sort_btn: 'data rozpoczęcia',
    title: 'Zarządzanie wydarzeniami'
  },
  abk_managing_offers: {
    applications: '0 zgłoszeń | 1 zgłoszenie | {count} zgłoszenia | {count} zgłoszeń',
    no_records: 'Brak ofert',
    offers_count: 'Łączna liczba ofert',
    sort_btn: 'data publikacji',
    title: 'Zarządzanie ofertami',
    type: {
      internship: 'Staż',
      work: 'Praca'
    }
  },
  abk_message: {
    attachment_download_error: 'Wystąpił błąd podczas pobierania załącznika.',
    show_thread: 'Pokaż wątek'
  },
  abk_message_secondary: {
    attachment_download_error: 'Wystąpił błąd podczas pobierania załącznika.',
    delete_message_error: 'Wystąpił nieoczekiwany błąd podczas usuwania wiadomości',
    message_deleted: 'Wiadomość została usunięta',
    sent_to_users: 'Wysłano do 0 użytkowników | Wysłano do 1 użytkownika | Wysłano do {count} użytkowników'
  },
  abk_message_form: {
    collapse: 'ukryj',
    error_download_list_of_recipients: 'Wystąpił nieoczekiwany błąd podczas pobierania listy odbiorców.',
    error_download_messages: 'Wystąpił nieoczekiwany błąd podczas pobierania wiadomości.',
    error_download_recipients: 'Wystąpił nieoczekiwany błąd podczas pobierania odbiorców grup.',
    error_university_structure: 'Wystąpił nieoczekiwany błąd podczas pobierania struktury uniwersytetu.',
    fields: {
      content: 'Treść',
      message_type: 'Rodzaj wiadomości',
      recipient_group_id: 'Grupa odbiorców',
      recipient_type: 'Rodzaj odbiorców',
      send_to: 'Do',
      title: 'Tytuł'
    },
    recipients_count: 'Liczba odbiorców: ',
    show_more: '...zobacz więcej',
    working_copy_saved: 'Kopia robocza wiadomości została zapisana.'
  },
  abk_messages_box: {
    create_newsletter: 'Stwórz newsletter',
    no_records: 'Brak wiadomości',
    title: 'Wiadomości',
    write_message: 'Napisz wiadomość'
  },
  abk_multiselect_mpaing: {
    selected: 'Zaznaczone: '
  },
  abk_navigation_menu: {
    accepted_companies: 'Zatwierdzeni',
    active: 'Aktywne',
    admin_dashboard: 'Pulpit admina',
    archived_events: 'Archiwalne',
    assigned_events: 'Przypisane do mnie',
    attendance_list: 'Lista obecności',
    base_blocks: 'Podstawowe bloki',
    catalog_of_offers: 'Katalog ofert',
    cms: 'CMS',
    competences: 'Kompetencje',
    competences_connected_with_skills: 'Powiązane z kierunkami',
    competences_list: 'Wszystkie kompetencje',
    competences_not_connected_with_skills: 'Niepowiązane z kierunkami',
    completed: 'Wypełnione',
    components: 'Komponenty',
    communiques: 'Komunikaty',
    contact: 'Kontakt',
    conversations: 'Konwersacje',
    current_events: 'Aktualne',
    cv: 'CV',
    dashboard: 'Pulpit',
    dictionaries: 'Słowniki',
    dictionaries_of_consents: 'Słowniki zgód',
    draft_messages: 'Wiadomości robocze',
    edit: 'Edytuj',
    email_confirmation: 'Potwierdzenie adresu email',
    employer_dashboard: 'Pulpit pracodawcy',
    employer_list: 'Pracodawcy',
    events: 'Wydarzenia',
    events_list: 'Lista wydarzeń',
    events_list_student: 'Wszystkie',
    favorites: 'Ulubione',
    firm: 'Firma',
    for_acceptance_companies: 'Oczekujący',
    group_messages: 'Wiadomości grupowe',
    inactive: 'Nieaktywne',
    invitations: 'Zaproszenia',
    list_of_news: 'Aktualności',
    list_of_offers: 'Lista ofert',
    list_of_pages: 'Lista stron',
    log_in: 'Zaloguj',
    login_user: 'Zaloguj',
    messages: 'Wiadomości',
    messages_sent: 'Wiadomości wysłane',
    my_cv: 'Moje CV',
    my_offers: 'Moje oferty',
    my_submissions: 'Moje zgłoszenia',
    new_event: 'Nowe wydarzenie',
    new_message: 'Nowa wiadomość',
    new_news: 'Dodaj aktualność',
    new_offer: 'Nowa oferta',
    new_page: 'Nowa strona',
    new_password: 'Nowe hasło',
    new_survey: 'Nowa ankieta',
    news: 'Aktualności',
    news_index: 'Lista aktualności',
    observed_results: 'Obserwowane wyniki',
    reserve_list: 'Lista rezerwowa',
    reset_password: 'Resetowanie hasła',
    pending_events: 'Oczekujące',
    profile: 'Profil',
    recent: 'Nowe',
    recipient_groups: 'Grupy odbiorców',
    register_user: 'Zarejestruj się',
    registered_events: 'Moje zapisy',
    rejected_companies: 'Odrzuceni',
    removed_users_list: 'Usunięci użytkownicy',
    search: 'Wyszukiwarka',
    sign_out_list: 'Lista wypisanych',
    skills: 'Kierunki',
    skills_connected_with_competences: 'Powiązane z kompetencjami',
    skills_list: 'Wszystkie kierunki',
    skills_not_connected_with_competences: 'Niepowiązane z kompetencjami',
    static_pages: 'Strony statyczne',
    student: 'Student/Absolwent',
    student_list: 'Studenci/Absolwenci',
    student_notes: 'Notatki',
    student_notes_edit: 'Edycja notatki',
    student_notes_new: 'Nowa notatka',
    surveys: 'Ankiety',
    surveys_list: 'Lista ankiet',
    thread_messages: 'Lista wiadomości',
    users_competences: 'Użytkownicy',
    users_list: 'Użytkownicy'
  },
  abk_new_employers_box: {
    no_records: 'Brak pracodawców',
    title: 'Nowi pracodawcy'
  },
  abk_new_news_form: {
    access_roles: {
      career_office: 'Biuro karier',
      employer: 'Pracodawca',
      graduate: 'Absolwent',
      public: 'Niezalogowani',
      recruiter: 'Rekruter',
      student: 'Student'
    },
    address: 'Adres url',
    content: 'Treść',
    keywords: 'Słowa kluczowe',
    metaDescription: 'Meta opis',
    navigation: 'Nawigacja',
    news_title: 'Tytuł aktualności',
    permissions: 'Kto będzie widział aktualność'
  },
  abk_new_offers_box: {
    no_records: 'Brak ofert',
    title: 'Nowe oferty'
  },
  abk_new_offer_step_1_form: {
    add: '+ dodaj',
    company: 'Pracodawca',
    expiration_date: 'Data zakończenia',
    free_places: 'Liczba miejsc',
    job_title: 'Nazwa stanowiska',
    next: 'Dalej',
    offer_type: 'Typ oferty',
    position_type: 'Typ stanowiska',
    publication_date: 'Data rozpoczęcia',
    salary_text: 'Możesz dodać informacje dotyczące zakresu przewidywanego wynagrodzenia',
    title: 'Wypełnij podstawowe informacje albo',
    trade: 'Branża',
    type_of_work: 'Rodzaj pracy',
    use_template: 'utwórz na podstawie innej oferty',
    work_dimension: 'Wymiar pracy',
    workplace: 'Miejsce pracy'
  },
  abk_new_offer_step_2_form: {
    competence_header: 'Kompetencje',
    competence_placeholder: 'Wybierz kompetencje',
    no_competences: 'Nie wybrano żadnych kompetencji',
    title: 'Dodaj uszczegółowione informacje o ofercie'
  },
  abk_new_offer_step_3_form: {
    back: 'Wstecz',
    info_text: 'Przed opublikowaniem sprawdź, czy podane informacje się zgadzają',
    publish: 'Publikuj',
    title: 'Podgląd treści'
  },
  abk_new_page_content_form: {
    title: 'Treść'
  },
  abk_new_page_form: {
    access_roles: {
      career_office: 'Biuro karier',
      employer: 'Pracodawca',
      graduate: 'Absolwent',
      public: 'Niezalogowani',
      recruiter: 'Rekruter',
      student: 'Student'
    },
    address: 'Adres url',
    content: 'Treść',
    forum: 'Forum na stronie',
    image: 'Obrazek',
    keywords: 'Słowa kluczowe',
    language: 'Język strony',
    max_size: 'Rozmiar pliku powinien być mniejszy niż 4 MB',
    metaDescription: 'Meta opis',
    navigation: 'Nawigacja',
    page_title: 'Tytuł strony',
    parent: 'Rodzic',
    permissions: 'Kto może przeglądać strone',
    sort: 'Kolejność'
  },
  abk_not_accepted_users: {
    approve: 'Zatwierdź',
    approve_success: 'Pomyślnie zatwierdzono pracownika',
    header: 'Rekruterzy',
    reject: 'Odrzuć pracownika',
    reject_success: 'Pomyślnie odrzucono pracownika'
  },
  abk_observed_search_results: {
    date_added: 'data dodania:',
    new_results: '0 nowych | 1 nowy | {count} nowe | {count} nowych',
    no_records: 'Nie obserwujesz żadnych ogłoszeń',
    results: '0 wyników | 1 wynik | {count} wyniki | {count} wyników'
  },
  abk_offer_description: {
    tasks: 'Zadania:',
    we_expect: 'Oczekujemy:',
    we_offer: 'Oferujemy:'
  },
  abk_offer_description_form: {
    add_image: 'Dodaj obraz',
    add_text_section: 'Dodaj sekcję tekstową',
    empty_section_error: {
      image: 'Nie można dodać nowej sekcji z obrazem, ponieważ istnieje już jedna pusta sekcja.',
      text: 'Nie można dodać nowej sekcji tekst, ponieważ istnieje już jedna pusta sekcja.'
    },
    subtitle: 'Przeciągnij sekcję aby zmienić kolejność',
    title: 'TREŚĆ I KOMPETENCJE'
  },
  abk_offer_form: {
    accept: 'Rozumiem',
    accepted_offer: 'Zmodyfikowana oferta zostanie opublikowana po akceptacji pracownika biura karier. Kontynuowanie i zapisanie nowej wersji oferty spowoduje czasowe jej ukrycie (zaprzestanie publikowania) do momentu akceptacji.',
    accepted_offer_bk: 'Edycja oferty powoduje potrzebę ponownej akceptacji oferty. Kontynuowanie i zapisanie nowej wersji oferty spowoduje czasowe jej ukrycie (zaprzestanie publikowania) do momentu akceptacji.',
    offer_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania oferty.',
    offer_updated: 'Oferta została zaaktualizowana.',
    save_working_copy: 'Zapisz kopię roboczą',
    step_1: 'Podstawowe informacje',
    step_2: 'Szczegóły',
    step_3: 'Publikacja',
    working_copy_saved: 'Kopia robocza oferty została zapisana.'
  },
  abk_offer_item: {
    carrer_office: 'Dodana przez Biuro Karier'
  },
  abk_offer_salary_box: {
    title: 'Wynagrodzenie brutto'
  },
  abk_offer_text_section: {
    content: 'Treść',
    title: 'Tytuł sekcji'
  },
  abk_offers_box: {
    title: 'Podobne oferty'
  },
  abk_page_not_found: {
    main_page: 'Strona główna',
    not_exist: 'Strona której szukasz, nie istnieje',
    title: 'PSSST! TUTAJ NIC NIE MA'
  },
  abk_paired_offers_box: {
    no_records: 'Brak ofert',
    title: 'Sparowane oferty'
  },
  abk_popular_searches_box: {
    no_records: 'Brak wyszukiwań',
    title: 'popularne wyszukiwania'
  },
  abk_quick_info: {
    company_name: 'Firma',
    expiration_date: 'Data wygaśnięcia ogłoszenia',
    free_places: 'Liczba miejsc',
    offer_type: 'Typ oferty',
    position_type: 'Typ stanowiska',
    publication_date: 'Data rozpoczęcia ogłoszenia',
    time_left: ' | Został 1 dzień do końca | Zostały {count} dni do końca | Zostało {count} dni do końca',
    title: 'Szybkie info',
    type_of_work: 'Rodzaj pracy',
    workplace: 'Miejsce pracy',
    work_dimension: 'Wymiar pracy'
  },
  abk_recommended_profiles: {
    perfect_candidate: 'Idealny kandydat',
    title: 'Polecane profile'
  },
  abk_recipient_group_list: {
    career_office_users: 'Użytkownicy biura karier',
    date_added: 'data dodania:',
    error_download: 'Wystąpił błąd podczas pobierania grup odbiorców.',
    no_records: 'Nie istnieją żadne grupy odbiorców.',
    recipient_types: {
      employer: 'Pracodawcy',
      recruiter: 'Rekruter',
      student: 'Studenci',
      graduate: 'Absolwenci'
    },
    unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji'
  },
  abk_recipient_group_form: {
    buttons: {
      add: 'Dodaj grupę',
      save: 'Zapisz grupę odbiorców'
    },
    fields: {
      activity_id: 'Aktywność',
      career_office_users: 'Użytkownicy biura karier',
      department_id: 'Wydział',
      dictionary_acceptance_employer: 'Zgody pracodawców/rekruterów',
      dictionary_acceptance_word_id: 'Formy współpracy',
      dictionary_acceptance_student: 'Zgody studentów/absolwentów',
      field_of_study_id: 'Kierunek',
      name: 'Nazwa',
      recruitment_year_id: 'Rok naboru',
      specialization_id: 'Specjalizacja',
      specialty_id: 'Specjalność',
      subject: 'Tematyka',
      students: 'Studenci',
      study_mode_id: 'Tryb',
      surveys: 'Ankiety',
      type_of_studies_id: 'Rodzaj'
    },
    recipient_group_saved: 'Grupa odbiorców została zapisana.'
  },
  abk_remove_account_modal: {
    make_sure_title: 'Czy na pewno chcesz usunąć konto?',
    warning_title: 'Ta operacja jest nieodwracalna!',
    warning_text: 'Po usunięciu konta nie będziesz mógł zalogować się do systemu.',
    company_events_warning: 'Utworzone wydarzenia zostaną usunięte, a zapisani uczestnicy otrzymają wiadomość, że dane wydarzenie się nie odbędzie.',
    remove_reason: 'Powód usunięcia'
  },
  abk_save_offer_filter: {
    add: 'Dodaj do obserwowanych wyszukiwań',
    info_text: 'Po dodaniu wyszukiwania do obserwowanych bedziesz mógł pózniej śledzić wyniki tego wyszukiwania.',
    remove: 'Dodano do obserwowanych - cofnij',
    show_observed: 'Pokaż obserwowane'
  },
  abk_search: {
    placeholder: 'szukaj..'
  },
  abk_search_filter: {
    headers: {
      categories: 'Kategorie',
      company: 'Firma',
      offer_type: 'Typ oferty',
      position_type: 'Poziom stanowiska',
      trade: 'Branże',
      type_of_work: 'Rodzaj umowy',
      work_dimension: 'Wymiar pracy',
      workplace: 'Lokalizacja'
    },
    offer_type: {
      course: 'Szkolenia',
      internship: 'Staże',
      practice: 'Praktyki',
      work: 'Praca'
    }
  },
  abk_search_filter_list: {
    title: 'Filtry'
  },
  abk_search_new_offers: {
    title: 'Nowe oferty pracy, które mogą cię zainteresować'
  },
  abk_section: {
    toggle_hide: 'ukryj',
    toggle_show: 'pokaż'
  },
  abk_students: {
    abk_student_list: {
      error_download_students: 'Wystąpił błąd podczas pobierania studentów.',
      error_download_student_notes: 'Wystąpił błąd podczas pobierania notatek.',
      registration_date: 'Data rejestracji.',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji',
      no_records: 'Brak studentów.'
    },
    filter_students: 'Filtruj studentów',
    role: 'Rola'
  },
  abk_student_notes: {
    note_deleted: 'Notatka usunięta poprawnie',
    note_deleted_error: 'Nie udało się usunąć notatki'
  },
  abk_suggest_dictionary_word_modal: {
    send_for_verification: 'Słowo zostało wysłane do weryfikacji',
    title: 'Zaproponuj wartość'
  },
  abk_survey_question_choose: {
    type: {
      text: 'Tekst',
      number: 'Liczby',
      radiobutton: 'Radio',
      checkbox: 'Checkbox',
      separator: 'Separator',
      section: 'Sekcja'
    }
  },
  abk_tags_box: {
    header: 'TAGI',
    input_placeholder: 'Wpisz słowo kluczowe'
  },
  abk_text_section_form: {
    add_subsection: 'dodaj podsekcję',
    background: 'Obraz tła',
    content: 'Treść',
    section_name: 'Nazwa',
    subsection_name: 'Nazwa podsekcji',
    subtitle: 'Secja tekstowa'
  },
  abk_timeline: {
    in_progress: 'w toku'
  },
  abk_upcoming_events_box: {
    no_records: 'Brak nadchodzących wydarzeń',
    title: 'nadchodzące wydarzenia'
  },
  abk_user_login_form: {
    application_login: 'Jeśli założyłeś konto pracodawcy lub absolwenta w systemie Biura Karier',
    idp_login: 'Jeśli posiadasz konto w uczelnianym systemie logowania',
    reset_password: 'Zapomniałeś hasła?',
    title: 'Logowanie',
    university_login: 'Zaloguj w systemie uczelnianym'
  },
  abk_user_registration_form: {
    album_no: 'Numer albumu',
    company_name: 'Nazwa firmy',
    email: 'E-mail',
    first_name: 'Imię',
    last_name: 'Nazwisko',
    log_in_here: 'zaloguj się tutaj.',
    nip: 'NIP',
    password: 'Hasło',
    password_complexity: 'Zaproponowane hasło:\n' +
      'musi składać się z minimum 8 znaków\n' +
      'musi zawierać przynajmniej jedną małą i wielką literę oraz cyfrę\n' +
      'nie może zawierać 3 występujących po sobie znaków zawartych w loginie\n' +
      'nie może zawierać Twojego imienia, nazwiska lub loginu\n' +
      'nie może zawierać polskich znaków',
    password_confirmation: 'Potwierdzenie hasła',
    role: 'Rola',
    roles: {
      employer: 'Pracodawca',
      graduate: 'Absolwent'
    },
    subtitle: 'Formularz rejestracji dla pracodawców i absolwentów.\nJeśli posiadasz aktywne konto uczelniane',
    title: 'Rejestracja'
  },
  abk_users_list: {
    add: 'Dodaj',
    admin_login: 'Przeloguj się na użytkownika',
    all: 'Wybierz',
    edit_roles: 'Edytuj role',
    filter_users: 'Filtruj użytkowników',
    no_records: 'Brak użytkowników',
    no_role: 'Brak roli',
    role: 'Rola',
    table_header: 'Tabela użytkowników',
    acceptances: 'Pokaż zgody użytkownika',
    unblock: 'Odblokuj',
    block: 'Zablokuj',
    blocked: 'Użytkownik został zablokowany',
    unblocked: 'Użytkownik został odblokowany',
    deleted_at_start: 'Data usunięcia od: ',
    deleted_at_end: 'Data usunięcia do:'
  },
  abk_video_section_form: {
    add_video: 'Dodaj adres filmu',
    subsection_name: 'Adres filmu',
    subtitle: 'Adresy filmów',
    title: 'Wideo',
    title_placeholder: 'Nazwa'
  },
  abk_visual_identification_form: {
    background: 'Zdjęcie w tle',
    logo: 'Logo',
    max_size: 'Rozmiar pliku powinien być mniejszy niż 4 MB',
    title: 'Identyfikacja wizualna'
  },
  abk_work_record: {
    fresh_record: 'Nowość!',
    no_records: 'Brak ofert',
    offer_expires: 'oferta wygasa',
    published: 'opublikowano',
    type: {
      job: 'Praca',
      practice: 'Praktyka',
      training: 'Staż'
    }
  },
  curriculum_vitaes: {
    abk_curriculum_vitaes_additional_information_form: {
      title: 'Zainteresowania, Hobby, dodatkowe aktywności'
    },
    abk_curriculum_vitaes_basic_info_form: {
      add_website: 'Dodaj kolejną stronę',
      avatar: 'Zdjęcie profilowe',
      email: 'E-mail',
      firstName: 'Imię',
      info: 'Wypełnij jak najwięcej informacji o sobie, im więcej pól formularza wypełnisz tym bardziej atrakcyjne bedzie Twoje CV dla potencjalnego pracodawcy.',
      introduction: 'Wprowadzenie',
      lastName: 'Nazwisko',
      phone: 'Telefon',
      title: 'Podstawowe informacje',
      websites: 'Strona internetowa'
    },
    abk_cv_competences_form: {
      competence_placeholder: 'Wybierz kompetencje',
      no_competences: 'Nie wybrano żadnych kompetencji',
      title: 'Kompetencje'
    },
    abk_cv_custom_competence_form: {
      empty_section_error: 'Nie można dodać nowej sekcji umiejętności, ponieważ istnieje już jedna pusta sekcja.',
      title: 'Umiejętności'
    },
    abk_cv_educations_form: {
      empty_section_error: 'Nie można dodać nowej sekcji wykształcenia, ponieważ istnieje już jedna pusta sekcja.',
      title: 'Wykształcenie'
    },
    abk_cv_custom_competence_form_section: {
      custom_competence_type: {
        driving_license: 'Prawo jazdy',
        foreign_language: 'Język obcy',
        other: 'Pozostałe'
      }
    },
    abk_cv_education_form_section: {
      attachment: '',
      date_end: 'Rok zakończenia',
      date_start: 'Rok rozpoczęcia',
      degree: 'Stopień',
      diploma_date: 'Data uzyskania dyplomu',
      discipline: 'Kierunek',
      institution: 'Nazwa instytucji',
      institution_types: {
        college: 'Szkoła wyższa',
        high_school: 'Szkoła średnia'
      },
      faculty: 'Wydział',
      finished: 'Ukończone'
    },
    abk_cv_experiences_form: {
      empty_section_error: 'Nie można dodać nowej sekcji doświadczenia, ponieważ istnieje już jedna pusta sekcja.',
      title: 'Doświadczenie'
    },
    abk_cv_experiences_form_section: {
      date_end: 'Rok zakończenia',
      date_start: 'Rok rozpoczęcia',
      description: 'Zakres obowiązków',
      employment_type_id: 'Forma zatrudnienia',
      experience_types: {
        job: 'Praca',
        practice: 'Praktyka',
        training: 'Staż'
      },
      institution: 'Nazwa instytucji',
      position: 'Stanowisko'
    },
    abk_cv_certificates_form: {
      empty_section_error: 'Nie można dodać nowej sekcji certyfikatów, ponieważ istnieje już jedna pusta sekcja.',
      title: 'Szkolenia, kursy, certyfikaty'
    },
    abk_cv_certificates_form_section: {
      date_end: 'Data ukończenia',
      description: 'Opis',
      exam: 'Zakończone egzaminem',
      institution: 'Instytucja prowadząca',
      name: 'Nazwa'
    }
  },
  companies: {
    abk_company_list: {
      edit_employer_type: 'Edytuj typ pracodawcy',
      editing_employer_type: 'Edycja typu pracodawcy',
      employer_type: 'Typ pracodawcy',
      employer_type_success: 'Pomyślnie zaktualizowano typ pracodawcy',
      employer_types: {
        employer: 'Pracodawca',
        lite: 'LITE',
        partner: 'Partner'
      },
      error_download_companies: 'Wystąpił błąd podczas pobierania pracodawców.',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji',
      accepted: {
        no_records: 'Brak zatwierdzonych pracodawców.'
      },
      registration_date: 'Data zgłoszenia: ',
      reject: 'Odrzuć firmę',
      rejected: {
        no_records: 'Brak odrzuconych pracodawców.'
      },
      forAcceptance: {
        no_records: 'Brak oczekujących na zatwierdzenie pracodawców.'
      }
    }
  },
  competences: {
    abk_competences_list: {
      add_button: 'Dodaj kompetencje',
      no_records: 'Brak kompetencji',
      table_header: 'Tabela kompetencji',
      delete_info_text: 'Usunięcie kompetencji, spowoduje również usunięcie powiązań pomiędzy tą kompetencją i ' +
        'wszystkimi ofertami, wydarzeniami, kierunkami oraz CV w których została wykorzystana.'
    }
  },
  events: {
    abk_event_description_form: {
      add_image: 'Dodaj obraz',
      add_text_section: 'Dodaj sekcję tekstową',
      empty_section_error: {
        image: 'Nie można dodać nowej sekcji z obrazem, ponieważ istnieje już jedna pusta sekcja.',
        text: 'Nie można dodać nowej sekcji tekst, ponieważ istnieje już jedna pusta sekcja.'
      },
      subtitle: 'Przeciągnij sekcję aby zmienić kolejność',
      title: 'TREŚĆ I KOMPETENCJE'
    },
    abk_event_form: {
      add_sub_event: 'dodaj kolejne',
      event_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania wydarzenia.',
      event_updated: 'Wydarzenie zostało zaaktualizowana.',
      save_working_copy: 'Zapisz kopię roboczą',
      saved: 'Wydarzenie zostało zapisane',
      step_1: 'Podstawowe informacje',
      step_2: 'Szczegóły',
      step_3: 'Publikacja',
      working_copy_saved: 'Kopia robocza wydarzenia została zapisana.'
    },
    abk_event_quick_info: {
      cost: 'Koszt',
      currently_free_places: 'Liczba miejsc',
      currently_reserve_places: 'Liczba miejsc rezerwowych',
      chosen_date: 'Wybrany termin',
      choose_date: 'Wybierz termin',
      competences: 'Kompetencje',
      date_canceled: 'Termin anulowany',
      other_dates: 'Pozostałe terminy',
      no_places: 'Brak wolnych miejsc',
      more_dates: 'Brak innych terminów | ... i {count} inny termin | ... i {count} inne terminy | ... i {count} innych terminów',
      free_places_left: 'Brak wolnych miejsc | Pozostało {count} wolne miejsce | Pozostało {count} wolne miejsca | Pozostało {count} wolnych miejsc',
      finished: 'Wydarzenie już się odbyło',
      end_date: 'Czas zakończenia',
      event_type: 'Typ',
      free: 'Bezpłatne',
      free_places: 'Liczba wolnych miejsc',
      instructor: 'Prowadzący',
      registration_end: 'Koniec rejestracji',
      registration_start: 'Początek rejestracji',
      remove_date: 'Usuń termin',
      reserve_places: 'Liczba miejsc rezerwowych',
      reserve_places_left: 'Brak wolnych miejsc rezerwowych | Pozostało {count} wolne miejsce rezerwowe | Pozostało {count} wolne miejsca rezerwowe | Pozostało {count} wolnych miejsc rezerwowych',
      start_date: 'Czas rozpoczęcia',
      title: 'Szybkie info',
      unlimited: 'Nieograniczona',
      unlimited_places: 'Nieograniczona liczba miejsc',
      unlimited_reserve_places: 'Pozostały miejsca na liście rezerwowej',
      where: 'Gdzie'
    },
    abk_event_step_1_form: {
      add: '+ dodaj',
      address: 'Adres',
      company: 'Organizator',
      cost: 'Koszt',
      deadlines: 'Terminy',
      end_date: 'Zakończenie',
      event_title: 'Nazwa wydarzenia',
      event_type: 'Typ wydarzenia',
      free_places: 'Liczba miejsc',
      instructor: 'Prowadzący',
      next: 'Dalej',
      attendance_mandatory: 'Obecność obowiązkowa',
      place: 'Nazwa miejsca',
      registration_end: 'Koniec rejestracji',
      registration_start: 'Początek rejestracji',
      reminder: 'Przypomnienie (dni)',
      reminder_content: 'Treść przypomnienia',
      reminder_topic: 'Temat przypomnienia',
      remove: 'usuń',
      reserve_places: 'Liczba miejsc rezerwowych',
      start_date: 'Rozpoczęcie',
      title: 'Wypełnij podstawowe informacje albo',
      use_template: 'utwórz na podstawie innego wydarzenia'
    },
    abk_event_step_2_form: {
      competence_header: 'Kompetencje',
      competence_placeholder: 'Wybierz kompetencje',
      no_competences: 'Nie wybrano żadnych kompetencji',
      title: 'Dodaj uszczegółowione informacje o wydarzeniu'
    },
    abk_event_step_3_form: {
      back: 'Wstecz',
      info_text: 'Przed opublikowaniem sprawdź, czy podane informacje się zgadzają',
      publish: 'Publikuj',
      title: 'Podgląd treści'
    },
    abk_event_text_section: {
      content: 'Treść',
      title: 'Tytuł sekcji'
    },
    abk_events_list: {
      all: 'Wszystkie',
      already_taken_place: 'Wydarzenia już się odbyło',
      attendance_list: 'Lista obecności',
      event_canceled: 'Wydarzenie odwołane',
      events_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania wydarzeń.',
      free_places_left: '|Pozostało 1 wolne miejsce|Pozostały {count} wolne miejsca|Pozostało {count} wolnych miejsc',
      filter_events: 'Filtruj wydarzenia',
      matching_competences: 'Pasujące kompetencje',
      no_events: 'Brak wydarzeń',
      no_free_places: 'Brak wolnych miejsc',
      reserve_list: 'Lista rezerwowa',
      reserve_places_left: '|Pozostało 1 wolne miejsce na liście rezerwowej|Pozostały {count} wolne miejsca na liście rezerwowej|Pozostało {count} wolnych miejsc na liście rezerwowej',
      sign_out_list: 'Lista wypisanych',
      take_part: 'Bierzesz udział',
      take_part_reserve: 'Jesteś na liście rezerwowej',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji',
      unlimited_free_places: 'Nieograniczona liczba miejsc'
    },
    abk_events_list_box: {
      accepted: 'Wydarzenie zaakceptowane',
      canceled: 'Wydarzenie anulowane',
      draft: 'Wersja robocza',
      finished: 'Wydarzenie już się odbyło',
      for_acceptance: 'Oczekujące na zatwierdzenie',
      recent: 'Nowe wydarzenie',
      registration_start: 'Zapisy od',
      rejected: 'Wydarzenie odrzucone'
    },
    abk_new_events_box: {
      no_records: 'Brak wydarzeń',
      title: 'Nowe wydarzenia'
    }
  },
  messages: {
    abk_messages_list: {
      career_office_users: 'Użytkownicy biura karier',
      download_recipient_groups_error: 'Wystąpił nieoczekiwany błąd podczas pobierania odbiorców grup.',
      error_university_structure: 'Wystąpił nieoczekiwany błąd podczas pobierania struktury uniwersytetu.',
      filter_messages: 'Filtruj wiadomości',
      no_records: 'Brak wiadomości',
      recipient_types: {
        employer: 'Pracodawcy',
        recruiter: 'Rekruter',
        student: 'Studenci',
        graduate: 'Absolwenci'
      }
    }
  },
  offer_applications: {
    abk_offer_application_modal: {
      application_info: 'Twoja aplikacja została zapisana.',
      attachment_info: 'Jeśli chcesz możesz dodać załączniki do swojej aplikacji',
      title: 'Aplikujesz na ofertę'
    }
  },
  offer_filters: {
    abk_remove_object_filter_confirmation_modal: {
      confirm_delete: 'Potwierdź usunięcie z obserwowanych'
    }
  },
  offer_invitations: {
    abk_offer_invitations_list: {
      no_records: 'Brak zaproszeń',
      sort_by: 'Sortowanie według',
      sort_options: {
        invitation_sent_date: 'data wysłania zaproszenia',
        offer_job_title: 'nazwa oferty'
      }
    }
  },
  offers: {
    abk_offers_list: {
      all: 'Wszystkie',
      columns_names: {
        applications: 'Zgłoszenia',
        expiration_date: 'Data wygaśnięcia',
        matching_profiles: 'Pasujące profile'
      },
      filter_offers: 'Filtruj oferty',
      offers_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ofert.'
    },
    abk_reject_offer_modal: {
      rejection_reason: 'Powód odrzucenia',
      title: 'Podaj powód odrzucenia'
    },
    tabs: {
      abk_offer_applications_tab: {
        title: 'Zgłoszenia'
      },
      abk_offer_matching_profiles_tab: {
        invitation_error: 'Wystąpił nieoczekiwany błąd podczas wysyłania zaproszenia',
        invitation_sent: 'Zaproszenie zostało wysłane',
        invite: 'Zaproś do rekrutacji',
        match_text: 'Dopasowanie',
        title: 'Pasujące profile'
      },
      abk_offer_tab: {
        apply_now: 'Aplikuj teraz',
        matching_cv: 'Twoje CV idealnie pasuje do tej oferty!',
        other_company_offers: 'Inne oferty tej firmy',
        similar_offers: 'Podobne oferty',
        title: 'Oferta'
      }
    }
  },
  pcg_header_messages: {
    empty_messages: 'Nie masz żadnych wiadomości',
    footer_see_more: 'Zobacz więcej >',
    header_title: 'Wiadomości'
  },
  pcg_header_notifications: {
    company_message: '{userName} czeka na akceptację firmy',
    company_title: 'Nowy pracodawca',
    dictionary_message: 'Został dodany nowy słownik - {title}',
    dictionary_title: 'Nowy słownik',
    dictionary_word_title: 'Nowa propozycja słowa',
    dictionary_word_message: 'Użytkownik {userName} zaproponował nowe słowo {title}',
    empty_notifications: 'Nie masz żadnych powiadomień',
    event_message: '{userName} stworzył nowe wydarzenie {title}.',
    event_title: 'Nowe wydarzenie',
    header_title: 'Powiadomienia',
    offer_application_title: 'Aplikacja na ofertę',
    offer_application_message: '{userName} złożył aplikację na ofertę {title}.',
    offer_invitation_title: 'Zaproszenie na ofertę',
    offer_invitation_message: '{userName} zaprasza Cię do aplikacji na ofertę {title}.',
    offer_message: '{userName} stworzył nową ofertę na stanowisko {title}.',
    offer_title: 'Nowa oferta',
    user_message_title: 'Powiadomienie',
    user_message_message: '{content}'
  },
  pcg_header_profile_button: {
    lang_change_to: 'Zmień język na {lang}',
    logout: 'Wyloguj mnie',
    role_change_to: 'Zmień rolę na {role}',
    settings: 'Ustawienia'
  },
  pcg_pagination_summary: {
    summary: 'Pokazuje {from} - {to} z {total} wyników'
  },
  pcg_search_select: {
    add_value: 'Dodaj wartość',
    change: 'Zmień'
  },
  skills: {
    abk_competence_training_box: {
      new_competences: 'Nowe kompetencje',
      no_place: 'Nie określono miejsca wydarzenia',
      no_records: 'Brak szkoleń',
      offers_count: 'brak ofert pracy | + {count} oferta pracy | + {count} oferty pracy | + {count} ofert pracy',
      title: 'Rekomendowane szkolenia',
      top_text: 'Propozycje szkoleń rozszerzających kompetencje absolwentów kierunku'
    },
    abk_graduates_competences_box: {
      graduates_count: 'Liczba absolwentów kierunku zarejestrowanych w systemie',
      no_records: 'Brak kompetencji',
      title: 'Kompetencje uzyskane przez absolwentów kierunku'
    },
    abk_matching_to_labor_market_box: {
      degrees_texts: [
        'Brak dopasowania',
        'Bardzo niskie dopasowanie',
        'Niskie dopasowanie',
        'Średnie dopasowanie',
        'Wysokie dopasowanie',
        'Bardzo wysokie dopasowanie'
      ],
      increasing_competences_text: 'Aby jeszcze bardziej zwiekszyć dopasowanie, należy wyposażyć absolwentów w ' +
        'następujące kompetencje:',
      matching_offers: 'Pasujących ofert | Pasująca oferta | Pasujące oferty | Pasujących ofert',
      offers_count: 'brak ofert pracy | + {count} oferta pracy | + {count} oferty pracy | + {count} ofert pracy',
      title: 'Stopień dopasowania kierunku do rynku pracy'
    },
    abk_skills_list: {
      filter_skills: 'Filtruj kierunki',
      no_records: 'Brak kierunków',
      placeholders: {
        name: 'Nazwa',
        description: 'Opis',
        kind: 'Tryb',
        type: 'Rodzaj'
      },
      table_header: 'Tabela kierunków'
    }
  },
  surveys: {
    abk_answer: {
      no_answer: 'Brak odpowiedzi'
    },
    abk_requirement_modal: {
      not_selected: 'Warunek musi posiadać conajmniej jedną zaznaczoną odpowiedź',
      requirementTypeOptions: {
        all_selected: 'Wszystkie zaznaczone',
        any_selected: 'Jeden z wielu'
      },
      title: 'Zdefiniuj warunek'
    },
    abk_survey_form: {
      save_working_copy: 'Zapisz kopię roboczą',
      survey_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ankiety.',
      survey_steps_texts: {
        step_1: 'Metainformacje',
        step_2: 'Kwestionariusz',
        step_3: 'Publikacja'
      },
      survey_published: 'Ankieta została opublikowana.',
      working_copy_saved: 'Kopia robocza ankiety została zapisana.'
    },
    abk_survey_stats_answers_box: {
      no_records: 'Brak pytań',
      title: 'Statystyki odpowiedzi'
    },
    abk_survey_stats_info_box: {
      attributes: {
        anonymous: 'Anonimowa',
        closed: 'Zamknięta',
        completed: 'Zakończona',
        graduate_fate: 'Badanie losów absolwentów',
        published: 'Opublikowana',
        requires_answer: 'Obowiązkowa'
      },
      fields_names: {
        attributes: 'Atrybuty ankiety',
        completed_count: 'Ankiet wypełnionych',
        name: 'Nazwa ankiety',
        publication_period: 'Okres publikacji',
        recipient_group: 'Grupa odbiorców',
        sent_count: 'Ankiet wysłanych'
      },
      title: 'Informacje'
    },
    abk_survey_stats_question_answers: {
      no_records: 'Brak odpowiedzi',
      show: 'Pokaż',
      show_more: 'Pokaż więcej'
    },
    abk_surveys_box: {
      no_records: 'Brak nowych ankiet',
      sort_options: {
        initial_sent_date: 'Data wysłania',
        survey_name: 'Nazwa ankiety'
      },
      surveys_count: 'Łączna liczba nowych ankiet',
      title: 'Nowe ankiety'
    },
    abk_surveys_list: {
      completed_answers: 'Wypełnione ankiety',
      completed_at: 'Wypełniono: {date}',
      expires_at: 'Wygasa: {date}',
      no_surveys: 'Brak ankiet',
      sent_at: 'Wysłano: {date}',
      show_completed_surveys: 'Wypełnione ankiety',
      stats: 'Statystyki',
      surveys_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ankiet.',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji'
    },
    steps: {
      abk_survey_step_1_form: {
        add_message_content: 'Dodaj treść wiadomości, która zostanie wysłana mailem wraz z zaproszeniem do ' +
        'wypełnienia ankiety',
        add_reminder: 'dodaj przypomnienie',
        additional_info: 'Informacje dodatkowe',
        basic_info: 'Podstawowe informacje',
        collapse: 'ukryj',
        create_survey_based_on_another: 'utwórz na podstawie innej ankiety',
        delete: 'usuń',
        description: 'Opis',
        error_download_list_of_recipients: 'Wystąpił nieoczekiwany błąd podczas pobierania listy odbiorców.',
        error_download_recipients: 'Wystąpił nieoczekiwany błąd podczas pobierania odbiorców grup.',
        error_university_structure: 'Wystąpił nieoczekiwany błąd podczas pobierania struktury uniwersytetu.',
        expiration_date: 'Data wygaśnięcia',
        graduate_send_date: 'Ilość miesięcy od ukończenia studiów, po której ma zostać wysłana ankieta',
        message: 'Wiadomość',
        message_after_completing: 'Komunikat po wypełnieniu ankiety',
        message_title: 'Tytuł wiadomości',
        number_of_days_from_receipt: 'Ilość dni od otrzymania',
        publication_period: 'Okres publikacji',
        recipients_count: 'Liczba odbiorców: ',
        receivers_group: 'Grupa odbiorców',
        reminder_1: 'I przypomnienie',
        reminder_2: 'II przypomnienie',
        reminder_3: 'III przypomnienie',
        reminder_content: 'Treść przypomnienia',
        reminder_title: 'Tytuł przypomnienia',
        reminders: 'Przypomnienia',
        send_to: 'Do',
        show_more: '...zobacz więcej',
        survey_attributes: 'Atrybuty ankiety',
        survey_name: 'Nazwa ankiety'
      },
      abk_survey_step_2_form: {
        title: 'Ankieta'
      },
      abk_survey_step_3_form: {
        fields_names: {
          attributes: 'Atrybuty ankiety',
          expiration_date: 'Data wygaśnięcia',
          name: 'Nazwa ankiety',
          recipient_group: 'Grupa odbiorców',
          questions_set: 'Zestaw pytań'
        },
        info_text: 'Przed opublikowaniem ankiety sprawdź czy podane informacje się zgadzają',
        information: 'Informacje'
      }
    },
    abk_survey_question_form: {
      add_requirement_btn: '+ Dodaj warunek',
      checkbox_display_type_options: {
        horizontal: 'Checkbox (poziom)',
        vertical: 'Checkbox (pion)'
      },
      conditions_text: 'Warunki',
      labels: {
        description: 'Opis',
        display_type: 'Typ wyświetlania',
        mandatory: 'Pytanie obowiązkowe',
        section: 'Treść sekcji',
        title: 'Pytanie'
      },
      missing_question_fields_text: 'To pytanie nie ma zdefinowanych żadnych odpowiedzi, które można wykorzystać' +
        ' przy definiowaniu warunku.',
      radio_display_type_options: {
        horizontal: 'Radio (poziom)',
        vertical: 'Radio (pion)'
      }
    },
    abk_survey_question_field_form: {
      labels: {
        label: 'Etykieta',
        value: 'Wartość'
      }
    },
    abk_survey_question_fields_form: {
      add_answer: 'dodaj odpowiedź',
      add_open_answer: 'dodaj odpowiedź otwartą',
      title_text: 'Odpowiedzi'
    }
  },
  webpages: {
    abk_webpages_tree: {
      creation_date: 'Data utworzenia',
      edition_date: 'Data edycji',
      publication_status: 'Status publikacji',
      show_in_menu: 'Widoczne w menu'
    }
  }
}
