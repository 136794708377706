import api from './index'

const resource = '/users'

export default {
  getUsers (params) {
    return api.get(`${resource}`, { params: params })
  },
  getRemovedUsers (params) {
    return api.get(`${resource}/removed_users`, { params: params })
  },
  getOffersCatalogCounters () {
    return api.get(`${resource}/offers_catalog_counters`)
  },
  updateMissingRoles (payload) {
    return api.patch(`${resource}/update_missing_role`, payload)
  },
  getMatchingToOffer (offerId) {
    return api.get(`${resource}/matching_to_offer/${offerId}`)
  },
  updateRoles (userId, payload) {
    return api.patch(`${resource}/${userId}/update_roles`, payload)
  },
  registerUser (payload) {
    return api.post(`${resource}`, payload)
  },
  loginUser (payload) {
    return api.post(`${resource}/email_password`, payload)
  },
  emailConfirmation (token, payload) {
    return api.patch(`${resource}/email_confirmation/${token}`, payload)
  },
  emailReconfirmation (payload) {
    return api.post(`${resource}/email_reconfirmation`, payload)
  },
  resetPassword (payload) {
    return api.post(`${resource}/reset_password`, payload)
  },
  savePassword (payload) {
    return api.patch(`${resource}/password_confirmation`, payload)
  },
  getMyCompany () {
    return api.get(`${resource}/get_my_company`)
  },
  getMyCompanyStatus () {
    return api.get(`${resource}/get_my_company_status`)
  },
  getConsents () {
    return api.get(`${resource}/get_consents`)
  },
  checkAcceptances (role) {
    return api.get(`${resource}/check_acceptances?picked_role=${role}`)
  },
  fillAcceptances (payload) {
    return api.post(`${resource}/fill_acceptances`, payload)
  },
  changeCompanyEmployeeAccepted (payload) {
    return api.patch(`${resource}/change_company_employee_accepted`, payload)
  },
  getAllAcceptances (payload) {
    return api.get(`${resource}/${payload.userId}/get_all_acceptances`)
  },
  toggleStatus (payload) {
    return api.patch(`${resource}/${payload.id}/toggle_locked`, { blocked: payload.blocked })
  },
  removeAccount (payload) {
    let query = ''
    if (payload.reason) {
      query += `?reason=${payload.reason}`
    }
    return api.delete(`${resource}/remove_account${query}`)
  },
  adminRemoveAccount (payload) {
    let query = `?removing_user_id=${payload.removedAccountId}`
    if (payload.reason) {
      query += `&reason=${payload.reason}`
    }
    return api.delete(`${resource}/admin_remove_account${query}`)
  },
  changeStudentToGraduate (payload) {
    return api.post(`${resource}/change_student_to_graduate`, payload)
  },
  getAdminLogin () {
    return api.get(`${resource}/get_admin_login`)
  }
}
