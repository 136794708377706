import api from './index'

const resource = '/users'

export default {
  signIn (params) {
    return api.get(`${resource}/auth/openid_connect/callback`, {
      params: params,
      baseURL: '/'
    })
  },
  devSignIn (payload) {
    return api.post('dev_sessions', payload)
  },
  adminDevSignIn (payload) {
    return api.post('/dev_sessions/admin_dev_session', payload)
  },
  signOut () {
    return api.delete(`${resource}/sign_out`, {
      baseURL: '/'
    })
  },
  fetchUser () {
    return api.get(`${resource}/initial_check`)
  },
  verifyRecaptcha (payload) {
    return api.post(`${resource}/verify_recaptcha`, {
      response: payload.response
    })
  }
}
