import ApiCurriculumVitaes from '../api/curriculum_vitaes'
import { app } from '../index'

export default {
  methods: {
    checkCv () {
      if (this.$store.getters['auth/roles'].includes('student') || this.$store.getters['auth/roles'].includes('graduate')) {
        ApiCurriculumVitaes.getMyCvStatus()
          .then(response => {
            if (response.data.status === 'draft') {
              this.$swal({
                showCancelButton: true,
                cancelButtonText: app.$t('general.curriculum_vitae_popup.go_to_cv_edit'),
                title: app.$t('general.curriculum_vitae_popup.title'),
                text: app.$t('general.curriculum_vitae_popup.text')
              })
                .then(result => {
                  if (result.dismiss) {
                    app.$router.push({ name: 'curriculum_vitae_edit_path' })
                  }
                })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }
}
