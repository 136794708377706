<template>
  <div class="sub-events col-12">
    <abk-sub-event-form  v-for="(sub, index) in value"
                         :key="index"
                         :subEventCount="value.length"
                         v-model="value[index]"
                         @remove="removeSubEvent(sub,index)"
                         :errors = "getErrors(index)"
    />
    <span class="pcg-link" @click="addSubEvent">
      + {{ $t('components.events.abk_event_form.add_sub_event') }}
    </span>
  </div>
</template>

<script>
import AbkSubEventForm from './AbkSubEventForm'
import { mapGetters } from 'vuex'

export default {
  name: 'AbkSubEvents',
  components: { AbkSubEventForm },
  props: {
    value: Array,
    errors: Array
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    addSubEvent () {
      this.$emit('addSubEvent')
    },
    getErrors (index) {
      return this.errors[index]
    },
    removeSubEvent (sub, index) {
      const copy = [...this.value]

      if (sub.id) {
        this.$set(copy[index], '_destroy', true)
        this.updateForm(copy)
      } else {
        this.$set(copy[index], '_destroy', true)
        this.updateForm(copy)
        this.$nextTick(() => {
          copy.splice(index, 1)
          this.updateForm(copy)
        })
      }
    },
    updateForm (value) {
      this.$emit('input', value)
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters('auth', ['roles'])
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .form-container {
    width: 100%;
    padding: 1.785rem 0 $pcg-m-xl;
  }
  .pcg-link {
    cursor: pointer;
  }
  .separator {
    border-bottom: 1px solid $pcg-lightest-gray;
  }
  /deep/ .abk-input {
    margin-bottom: $pcg-m-lg;
    height: fit-content!important;
  }
  .date-input {
    /deep/ input {
      color: $pcg-gray;
    }
    /deep/ .bootstrap-datetimepicker-widget {
      width: 312px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .date-input {
      /deep/ input {
        width: 100%;
      }
      /deep/ .pcg-datetimepicker-btn {
        position: absolute;
      }
    }
    .end-date-picker {
      margin-left: 76px;
    }
  }

  @media (max-width: $screen-lg-min) {
    .start-date-picker {
      margin-bottom: $pcg-m-lg!important;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .separator {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    /deep/ .abk-input {
      margin-bottom: $pcg-m-lg;
    }
    .date-input {
      /deep/ input {
        color: $pcg-gray;
      }
    }
  }
</style>
