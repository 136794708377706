<template>
  <abk-company-list companiesType="forAcceptance"/>
</template>

<script>
import AbkCompanyList from '../../../components/companies/AbkCompanyList'

export default {
  name: 'ForAcceptanceCompaniesTab',
  components: { AbkCompanyList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.companies.companies_index.title'),
        subtitle: this.$t('views.companies.companies_index.for_acceptance')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
