import api from './index'

const resource = 'offer_applications'

export default {
  getOfferApplication (applicationId) {
    return api.get(`${resource}/${applicationId}`)
  },
  getApplicationsOffers (studentId) {
    let url = `${resource}/applications_offers`
    if (studentId) {
      url += `?student_id=${studentId}`
    }
    return api.get(url)
  },
  createOfferApplication (payload) {
    return api.post(`${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  }
}
