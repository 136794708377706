import api from './index'

const resource = 'competence_skills'

export default {
  getCompetenceSkills(params={}) {
    return api.get(`${resource}`, { params: params })
  },
  getSkillCompetences (params = {}) {
    return api.get(`${resource}/skill_competences`, { params: params })
  },
  createCompetenceSkills (payload) {
    return api.post(`${resource}`, payload)
  }
}
