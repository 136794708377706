export const views = {
  admin_dashboard: {
    subtitle: 'Administracja',
    title: 'Pulpit'
  },
  attendances: {
    actions: {
      present: 'obecny'
    },
    attendance_reserve_index: {
      title: 'Lista rezerwowa'
    },
    download_list: 'Pobierz listę uczestników',
    download_reserve_list: 'Pobierz listę rezerwowych uczestników',
    download_sign_out_list: 'Pobierz listę wypisanych',
    sign_out_list: {
      title: 'Lista wypisanych'
    },
    students_index: {
      title: 'Lista uczestników'
    }
  },
  career_office_dashboard: {
    events_download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania wydarzeń',
    unexpected_action_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji'
  },
  common: {
    save: 'Zapisz'
  },
  companies: {
    companies_index: {
      for_acceptance: 'Oczekujący',
      accepted: 'Zatwierdzeni',
      rejected: 'Odrzuceni',
      title: 'Lista pracodawców'
    }
  },
  company: {
    carousel_title: 'Tak to u nas wygląda'
  },
  user_competences: {
    edit: {
      no_competences: 'Brak kompetencji',
      placeholder: 'Dodaj kompetencje',
      subtitle: 'Edycja',
      title: 'Kompetencje studenta'
    }
  },
  competences: {
    competences_list: {
      all: 'Wszystkie',
      connected_with_skills: 'Powiązane z kierunkami',
      not_connected_with_skills: 'Niepowiązane z kierunkami'
    },
    edit: {
      competence_updated: 'Kompetencja została zaktualizowana pomyślnie',
      subtitle: 'Edycja',
      title: 'Kompetencje'
    },
    labels: {
      actions: 'Akcje',
      description: 'Opis',
      name: 'Nazwa',
      status: 'Status',
      switches: 'Zmień status'
    },
    list_tabs: {
      all_competences_tab: {
        title: 'Kompetencje',
        subtitle: 'Wszystkie'
      },
      competences_connected_with_skills_tab: {
        title: 'Kompetencje',
        subtitle: 'Powiązane z kierunkami'
      },
      competences_not_connected_with_skills_tab: {
        title: 'Kompetencje',
        subtitle: 'Niepowiązane z kierunkami'
      }
    },
    new: {
      competence_created: 'Kompetencja została utworzona pomyślnie',
      subtitle: 'Nowa',
      title: 'Kompetencja'
    },
    statuses: {
      active: 'Aktywna',
      inactive: 'Nieaktywna'
    },
    users: {
      no_records: 'Brak studentów',
      subtitle: 'Studenci',
      title: 'Kompetencje'
    }
  },
  competence_skills: {
    edit: {
      connections_created: 'Połączenia zostały utworzone pomyślnie',
      clear: 'Wyczyść zaznaczenia',
      competences_filter_placeholer: 'Filtruj kompetencje',
      save: 'Zapisz powiązania',
      skills_filter_placeholer: 'Filtruj kierunki',
      subtitle: 'Mapowanie',
      title: 'Kompetencje i Kierunki'
    },
    index: {
      add_button: 'Edytuj połączenia',
      table_header: 'Tabela połączonych kompetencji z kierunkami',
      title: 'Kompetencje',
      subtitle: 'Kierunki'
    },
    labels: {
      competences: 'Kompetencje',
      name: 'Kompetencja',
      skills: 'Kierunki'
    }
  },
  curriculum_vitaes: {
    curriculum_vitae_edit: {
      cv_download_error: 'Wystąpił błąd podczas pobierania CV.',
      cv_updated: 'Curriculum Vitae zostało zaktualizowane pomyślnie',
      draft_destroyed: 'Zmiany zostały odrzucone.',
      draft_info_title: 'Wersja robocza',
      draft_info_message: 'Przedstawione zmiany pochodzą z zapisanej wersji roboczej.',
      subtitle: 'Edycja CV',
      title: 'Mój profil',
      unsaved_changes_modal: {
        buttons: {
          cancel: 'Anuluj',
          draft: 'Zapisz jako wersja robocza',
          save: 'Zapisz'
        },
        title: 'Masz niezapisane zmiany. Czy chcesz je zapisać?'
      }
    },
    curriculum_vitae_show: {
      subtitle: 'Moje CV',
      title: 'Mój profil',
      download_pdf: 'Pobierz CV'
    }
  },
  dev_sessions: {
    dev_sessions_index: {
      title: 'Dev login'
    }
  },
  dictionaries: {
    dictionary_types: {
      closed: 'Zamknięty',
      open: 'Otwarty'
    },
    edit: {
      subtitle: 'Edycja',
      title: 'Słowniki',
      dictionary_type: 'Słownik otwarty'
    },
    index: {
      subtitle: '',
      table_header: 'Tabela dostępnych słowników',
      title: 'Słowniki'
    },
    labels: {
      actions: 'Akcje',
      created_at: 'Data utworzenia konta',
      description: 'Opis',
      dictionary_type: 'Typ słownika',
      email: 'Adres email',
      last_login: 'Ostatni czas logowania',
      name: 'Nazwa',
      recent_words_count: 'Nowe słowa'
    }
  },
  dictionary_acceptance_words: {
    acceptance_types: {
      allow_emails: 'Wysyłanie wiadomości',
      marketing_emails: 'Wysyłanie treści marketingowych',
      null: 'Nie'
    },
    edit: {
      subtitle: 'Edycja',
      title: 'Słowa'
    },
    index: {
      delete_word: 'Usuń słowo',
      subtitle: '',
      table_header: 'Tabela dostępnych warunków do zaakceptowania',
      title: 'Słowa'
    },
    labels: {
      acceptance_type: 'Wysyłanie wiadomości',
      actions: 'Akcje',
      blocked_on_profile: 'Zablokowane na profilu',
      name: 'Nazwa',
      registration: 'Rejestracja',
      registration_required: 'Wymagany przy rejestracji',
      status: 'Wymagane',
      type: 'Typ',
      value: 'Wartość',
      value_en: 'Wartość (en)',
      word_type: 'Typ zgody'
    },
    new: {
      subtitle: 'Nowe',
      title: 'Słowo'
    },
    statuses: {
      null: 'Opcjonalny',
      optional: 'Opcjonalny',
      required: 'Wymagany'
    },
    types: {
      cooperation: 'Współpraca',
      cv: 'CV',
      employer: 'Pracodawca',
      offer: 'Oferta',
      survey: 'Ankieta'
    },
    word_types: {
      blocked_on_profile: 'Zablokowane na profilu',
      clause: 'Klauzula informacyjna',
      null: 'Domyślny',
      profiling: 'Profilowanie',
      rodo: 'RODO'
    }
  },
  dictionary_acceptances: {
    index: {
      labels: {
        actions: 'Akcje',
        description: 'Opis',
        dictionaryType: 'Typ słownika',
        key: 'Klucz',
        name: 'Nazwa'
      },
      subtitle: 'Zgód i akceptacji',
      table_header: 'Tabela dostępnych słowników',
      title: 'Słowniki'
    }
  },
  dictionary_words: {
    edit: {
      subtitle: 'Edycja',
      title: 'Słowa'
    },
    index: {
      accepted: 'Zaakceptowane',
      add: 'Dodaj słowo',
      all: 'Wszystkie',
      no_records: 'Brak dostępnych słów',
      recent: 'Nowe',
      rejected: 'Odrzucone',
      subtitle: '',
      table_header: 'Tabela dostępnych słów',
      title: 'Słowa'
    },
    labels: {
      actions: 'Akcje',
      status: 'Status',
      switches: 'Zmień status',
      value: 'Wartość (pl)',
      value_en: 'Wartość (en)'
    },
    new: {
      subtitle: 'Nowe',
      title: 'Słowo'
    },
    statuses: {
      recent: 'Nowe',
      accepted: 'Zaakceptowane',
      rejected: 'Odrzucone'
    }
  },
  edit_company_profile: {
    approvals: 'Zgody',
    cooperations: 'Formy współpracy',
    draft_destroyed: 'Zmiany zostały odrzucone.',
    draft_info_title: 'Wersja robocza',
    draft_info_message: 'Przedstawione zmiany pochodzą z zapisanej wersji roboczej.',
    firm_download_error: 'Wystąpił błąd podczas pobierania profilu firmy.',
    firm_download_profile: 'Wystąpił błąd podczas pobierania profilu firmy.',
    firm_profile_updated: 'Profil firmy został zaaktualizowany',
    subtitle: 'Edycja',
    title: 'Profil firmy'
  },
  email_confirmation: {
    email_reconfirmation: 'Potwierdź rejestrację klikając w link wysłany na e-mail',
    admin_email_reconfirmation: 'Mail aktywacyjny wysłany do użytkownika',
    modal_text: 'Aby potwierdzić tożsamość podaj hasło użyte podczas rejestracji konta absolwenta',
    modal_title: 'Potwierdzenie tożsamości',
    reconfirm: 'Wyślij ponownie potwierdzenie',
    student_changed: 'Konto pomyślnie przekształcone. Możesz się teraz zalogować',
    subtitle: '',
    success_confirmation: 'Potwierdzenie adresu email przebiegło pomyślnie. Możesz się teraz zalogować',
    title: 'Potwierdzenie adresu email'
  },
  employer_cv: {
    certificates: 'Szkolenia, kursy, certyfikaty',
    competences: 'Kompetencje',
    diploma_date: 'Data uzyskania dyplomu',
    education: 'Edukacja',
    experience: 'Doświadczenie',
    introduction: 'Wprowadzenie',
    matching_profile: 'Profil idealnie pasujący do Twojej oferty',
    show_similar_profiles: 'Pokaż podobne profile',
    title: 'Katalog CV',
    with_exam: 'Zakończone egzaminem'
  },
  employer_dashboard: {
    downloading_surveys_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ankiet',
    modal_text: 'Aby dodawać i wyświetlać nowe oferty, oraz wydarzenia musisz zostać zaakceptowany przez pracodawcę.',
    modal_title: 'Niezatwierdzone konto',
    title: 'Pulpit'
  },
  events: {
    events_edit: {
      subtitle: 'Edycja',
      title: 'Wydarzenia'
    },
    events_list: {
      all: 'Wszystkie',
      archived: 'Archiwalne',
      assigned: 'Przypisane do mnie',
      current: 'Aktualne',
      pending: 'Oczekujące',
      registered: 'Moje zapisy'
    },
    events_list_similar: {
      title: 'Podobne wydarzenia'
    },
    events_new: {
      no_access_text: 'Aby dodawać nowe wydarzenia, profil twojej firmy musi zostać zweryfikowany przez pracownika ' +
        'Biura Karier.',
      subtitle: 'Nowe wydarzenie',
      title: 'Wydarzenia'
    },
    events_show: {
      event_canceled: 'Wydarzenie anulowane',
      event_finished: 'Wydarzenie już się odbyło',
      event_sending_for_publication_error: 'Wystąpił błąd podczas wysyłania wydarzenia do publikacji <br/>' +
        'Sprawdź, czy wszystkie wymagane pola są poprawnie wypełnione',
      event_sent_for_publication: 'Wydarzenie zostało wysłane do publikacji',
      no_free_places: 'Brak wolnych miejsc',
      number_of_views: 'Liczba wyświetleń:',
      register: 'Zapisz się',
      register_reserve: 'Zapisz się na listę rezerwową',
      register_confirmation_question: 'Czy na pewno chcesz się zapisać na to wydarzenie?',
      register_reserve_confirmation_question: 'Czy na pewno chcesz się zapisać na listę rezerwową na to wydarzenie?',
      register_for_event_error: 'Wystąpił nieoczekiwany błąd podczas zapisywania na wydarzenie',
      registered_for_event: 'Zostałeś zapisany na wydarzenie',
      registered_for_event_reserve: 'Zostałeś zapisany na listę rezerwową na wydarzenie',
      show_similar_events: 'Pokaż podobne wydarzenia',
      similar_events: 'Podobne wydarzenia',
      title: 'Wydarzenia',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji',
      unregister: 'Wypisz się',
      unregister_confirmation_question: 'Czy na pewno chcesz się wypisać z tego wydarzenia?',
      unregister_from_event_error: 'Wystąpił nieoczekiwany błąd podczas wypisywania z wydarzenia',
      unregistered_from_event: 'Zostałeś wypisany z wydarzenia',
      unregistered_from_event_reserve: 'Zostałeś wypisany z listy rezerwowej wydarzenia',
      unregister_reserve: 'Wypisz się z listy rezerwowej'
    },
    events_student_registered: {
      subtitle: 'Zapisy studenta',
      title: 'Wydarzenia'
    },
    list_tabs: {
      all_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Wszystkie'
      },
      archived_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Archiwalne'
      },
      assigned_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Przypisane do mnie'
      },
      current_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Aktualne'
      },
      pending_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Oczekujące'
      },
      registered_events_tab: {
        title: 'Wydarzenia',
        subtitle: 'Moje zapisy'
      }
    }
  },
  favorite_offers: {
    favorite_offers_index: {
      no_records: 'Nie posiadasz ulubionych ogłoszeń',
      offers_fetching_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ofert',
      title: 'Ulubione oferty'
    }
  },
  login: {
    companies_title: 'firm | firma | firmy | firm',
    companies_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget ' +
      'maximus dolor.',
    copyrights: 'Copyrighting.',
    header: 'Akademickie Biuro Karier',
    login_btn: 'Zaloguj się',
    main_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget maximus ' +
      'dolor.',
    offers_title: 'ofert pracy | oferta pracy | oferty pracy | ofert pracy',
    students_and_graduates_title: 'studentów i absolwentów | student lub absolwent | studentów i absolwentów | ' +
      'studentów i absolwentów'
  },
  login_user: {
    subtitle: '',
    title: 'Logowanie',
    wrong_login: 'Niepoprawna kombinacja maila i hasła'
  },
  messages: {
    index_tabs: {
      draft_messages_tab: {
        title: 'Wersje robocze'
      },
      group_messages_tab: {
        group: 'Grupowe',
        title: 'Wiadomości'
      }
    },
    messages_new: {
      subtitle: 'NOWA WIADOMOŚĆ',
      title: 'WIADOMOŚCI'
    },
    messages_index: {
      draft: 'Robocze',
      group: 'Grupowe'
    }
  },
  messages_list: {
    title: 'SKRZYNKA ODBIORCZA'
  },
  new_password: {
    instructions: 'Podaj nowe hasło',
    password_saved: 'Hasło zostało pomyślnie zaktualizowane',
    save: 'Zapisz',
    subtitle: '',
    title: 'Nowe hasło'
  },
  news: {
    news_edit: {
      news_updated: 'Aktualność została pomyślnie zaktualizowana.',
      save: 'Zapisz',
      save_and_publish: 'Zapisz i opublikuj',
      save_as_draft: 'Zapisz wersję roboczą',
      subtitle: 'Edycja aktualności',
      title: 'Aktualności'
    },
    news_index: {
      add_news: 'Dodaj aktualność',
      subtitle: 'Lista aktualności',
      title: 'Aktualności'
    },
    news_list: {
      no_records: 'Brak aktualności',
      title: 'Aktualności'
    },
    news_new: {
      publish: 'Opublikuj',
      save: 'Zapisz',
      save_draft: 'Zapisz wersję roboczą',
      subtitle: 'Dodaj aktualność',
      title: 'Aktualności'
    }
  },
  offers: {
    offers_edit: {
      subtitle: 'Edycja',
      title: 'Oferty'
    },
    offers_employer_tabs: {
      subtitle: 'Oferta',
      title: 'Oferty'
    },
    offers_index: {
      show_filters: 'Pokaż filtry',
      sort_btn: 'trafność',
      title: 'wyniki wyszukiwania'
    },
    offers_list: {
      subtitle: 'Lista',
      title: 'Oferty'
    },
    offers_list_similar: {
      title: 'Podobne oferty'
    },
    offers_matching_to_skill_list: {
      subtitle: 'Lista pasujących',
      title: 'Oferty'
    },
    offers_new: {
      no_access_text: 'Aby dodawać nowe oferty, profil twojej firmy musi zostać zweryfikowany przez pracownika ' +
        'Biura Karier.',
      subtitle: 'Nowa oferta',
      title: 'Oferty'
    },
    offers_not_matching_to_skill_list: {
      subtitle: 'Lista',
      title: 'Oferty'
    },
    offers_show: {
      additional_skills: 'Dodatkowe umiejętności',
      applying_on: 'Aplikujący na',
      competences_headers: {
        career_path: 'wynikające ze ścieżki kształcenia',
        event: 'wynikające ze szkoleń',
        user_assignment: 'wprowadzone przez użytkownika'
      },
      custom_competences_headers: {
        foreign_language: 'Języki obce',
        driving_license: 'Prawo jazdy',
        other: 'Pozostałe'
      },
      cv_matching_offer_percent: 'Procent dopasowania Twojego CV do oferty',
      go_back_to_search: 'Wróć do wyszukiwania',
      offer_sending_for_publication_error: 'Wystąpił błąd podczas wysyłania oferty do publikacji. <br/>' +
        'Sprawdź, czy wszystkie wymagane pola są poprawnie wypełnione.',
      offer_sent_for_publication: 'Oferta została wysłana do publikacji',
      outdated_offer: 'Ogłoszenie nieaktualne',
      see_your_applications_link: 'Zobacz listę swoich zgłoszeń',
      show_similar_offers: 'Zobacz podobne oferty',
      subtitle: 'Praca',
      title: 'Oferty',
      unexpected_error: 'Wystąpił nieoczekiwany błąd podczas wykonywania akcji',
      update_date: 'Data aktualizacji',
      you_applied_for_this_offer: 'Aplikowałeś na tę ofertę.'
    }
  },
  offer_applications: {
    applications_offers: {
      applications_fetching_error: 'Wystąpił nieoczekiwany błąd podczas pobierania zgłoszeń',
      cv_sent: 'wysłano CV',
      no_records: 'Nie aplikowałeś na żadne ogłoszenie',
      title: 'Moje zgłoszenia'
    }
  },
  offer_filters: {
    error_get_offer_filters: 'Wystąpił nieoczekiwany błąd podczas pobierania obserwowanych wyników wyszukiwania.',
    offer_filter_removed: 'Zapisany wynik wyszukiwania został usunięty.',
    remove_error: 'Wystąpił nieoczekiwany błąd podczas usuwania.',
    title: 'Obserwowane wyniki wyszukiwania'
  },
  offer_invitations: {
    list_tabs: {
      all: {
        subtitle: 'Wszystkie',
        title: 'Zaproszenia od pracodawców'
      },
      archived: {
        subtitle: 'Archiwalne',
        title: 'Zaproszenia od pracodawców'
      },
      current: {
        subtitle: 'Aktualne',
        title: 'Zaproszenia od pracodawców'
      }
    },
    offer_invitations_list: {
      all: 'Wszystkie',
      archived: 'Archiwalne',
      current: 'Aktualne'
    }
  },
  pages_list: {
    new_page: 'Nowa strona',
    subtitle: 'Lista stron',
    title: 'CMS'
  },
  recipient_groups: {
    recipient_groups_index: {
      title: 'Grupy odbiorców'
    }
  },
  register_user: {
    register: 'Zarejestruj się',
    title: 'Rejestracja',
    subtitle: '',
    user_registered: 'Rejestracja zakończona pomyślnie. Potwierdź rejestrację klikając w link wysłany na e-mail'
  },
  reset_password: {
    link_generated: 'Na podany adres email został wysłany link resetujący hasło',
    reset: 'Resetuj hasło',
    reset_instructions: 'Wpisz swój adres e-mail poniżej, a wyślemy Ci link do zresetowania hasła',
    subtitle: '',
    title: 'Resetowanie hasła'
  },
  role_choose: {
    employer_btn: 'Zaloguj jako pracodawca',
    graduate_btn: 'Zaloguj jako absolwent'
  },
  simple_messages: {
    simple_messages_index: {
      filter_messages: 'Filtruj wiadomości',
      no_records: 'Brak wiadomości',
      title: 'Wiadomości'
    },
    simple_messages_new: {
      subtitle: 'Nowa wiadomość',
      title: 'Wiadomości'
    },
    simple_messages_show: {
      title: 'Wiadomość'
    },
    statuses: {
      all: 'Wszystkie',
      read: 'Przeczytane',
      recent: 'Nieprzeczytane'
    }
  },
  skills: {
    labels: {
      description: 'Opis',
      external_id: 'Id',
      name: 'Nazwa',
      kinds: 'Tryb',
      types: 'Rodzaj'
    },
    list_tabs: {
      all_skills_tab: {
        title: 'Kierunki',
        subtitle: 'Wszystkie'
      },
      skills_connected_with_competences_tab: {
        title: 'Kierunki',
        subtitle: 'Powiązane z kompetencjami'
      },
      skills_not_connected_with_competences_tab: {
        title: 'Kierunki',
        subtitle: 'Niepowiązane z kompetencjami'
      }
    },
    skill_competences_index: {
      add_button: 'Edytuj połączenia',
      labels: {
        competences: 'Kompetencje',
        name: 'Kierunek',
        skills: 'Kierunki'
      },
      subtitle: 'Kompetencje',
      table_header: 'Tabela połączonych kierunków z kompetencjami',
      title: 'Kierunki'
    },
    skills_list: {
      all: 'Wszystkie',
      connected_with_competences: 'Powiązane z kompetencjami',
      not_connected_with_competences: 'Niepowiązane z kompetencjami'
    },
    skills_report: {
      title: 'Kompetencje',
      subtitle: 'Kierunki'
    }
  },
  students: {
    actions: {
      cv: 'Pokaż CV',
      edit_cv: 'Edycja CV',
      events: 'Wydarzenia',
      notes: 'Notatki',
      offers: 'Oferty'
    },
    students_index: {
      for_acceptance: 'Oczekujący',
      accepted: 'Zatwierdzeni',
      rejected: 'Odrzuceni',
      title: 'Lista studentów/absolwentów'
    },
    student_show: {
      title: 'Student/absolwent'
    },
    tabs: {
      cv: 'CV',
      edit_cv: 'Edycja CV',
      events: 'Wydarzenia',
      offers: 'Oferty'
    }
  },
  student_dashboard: {
    apprenticeships_and_internships: 'Praktyki i staże',
    downloading_surveys_error: 'Wystąpił nieoczekiwany błąd podczas pobierania ankiet',
    search_placeholder: 'Wyszukaj stanowiska, firmy, słowa kluczowe..',
    subtitle: 'ZNAJDŹ PRACĘ LUB STAŻ DLA SIEBIE.<br/>WYSZUKAJ W BAZIE {count} OFERT.',
    work: 'Praca',
    your_city: 'Twoje miasto'
  },
  student_notes: {
    new_note: 'Dodawanie notatki',
    title: 'Notatki'
  },
  student_notes_edit: {
    title: 'Edycja notatki'
  },
  student_notes_new: {
    note_error: 'Notatka nie została zapisana. Za duża ilość znaków.',
    note_saved: 'Notatka dodana poprawnie',
    save_note: 'Zapisz notatkę',
    title: 'Nowa notatka'
  },
  surveys: {
    answers: {
      answers_index: {
        no_records: 'Brak wypełnionych ankiet',
        subtitle: 'Odpowiedzi'
      }
    },
    list_tabs: {
      active_surveys_tab: {
        title: 'Ankiety',
        subtitle: 'Aktywne'
      },
      completed_surveys_tab: {
        title: 'Ankiety',
        subtitle: 'Wypełnione'
      },
      inactive_surveys_tab: {
        title: 'Ankiety',
        subtitle: 'Nieaktywne'
      },
      recent_surveys_tab: {
        title: 'Ankiety',
        subtitle: 'Nowe'
      }
    },
    surveys_edit: {
      subtitle: 'Edycja',
      title: 'Ankiety'
    },
    surveys_new: {
      subtitle: 'Nowa ankieta',
      title: 'Ankiety'
    },
    surveys_list: {
      active: 'Aktywne',
      all: 'Wszysktie',
      completed: 'Wypełnione',
      inactive: 'Nieaktywne',
      recent: 'Nowe'
    },
    surveys_show: {
      creating_answer_success: 'Odpowiedź została wysłana pomyślnie',
      title: 'Ankieta',
      validates_success: 'Ankieta została wypełniona poprawnie'
    },
    surveys_stats: {
      download_report: 'Pobierz raport z dnia {date}',
      export_answers: 'Eksportuj odpowiedzi',
      exporting_answers_error: 'Wystąpił nieoczekiwany błąd podczas exportowania odpowiedzi.',
      report_filename: 'ankieta_{id}_odpowiedzi_{time}.csv',
      subtitle: 'Statystyki',
      title: 'Ankiety'
    }
  },
  test: {
    title: 'Components'
  },
  test2: {
    title: 'Base blocks'
  },
  user_messages: {
    user_messages_index: {
      download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania komunikatów.',
      no_records: 'Brak komunikatów',
      title: 'Komunikaty'
    },
    user_messages_show: {
      download_error: 'Wystąpił nieoczekiwany błąd podczas pobierania komunikatu.',
      title: 'Komunikat'
    }
  },
  users_list: {
    block_user: 'Czy na pewno chcesz zablokować użytkownika',
    labels: {
      activation_mail: 'Wyślij maila aktywacyjnego',
      status_actions: 'Zablokuj/Odblokuj użytkownika',
      created_at: 'Data utworzenia konta',
      email: 'Adres email',
      full_name: 'Imię i nazwisko',
      last_login: 'Ostatni czas logowania',
      roles: 'Rola użytkownika',
      acceptances: 'Zgody użytkownika',
      account_remove: 'Usuń konto',
      relogin: 'Zaloguj jako użytkownik'
    },
    no_role: 'Brak roli',
    roles: {
      administrator: 'Administrator',
      career_office: 'Pracownik biura karier',
      counselor: 'Doradca',
      employer: 'Pracodawca',
      graduate: 'Absolwent',
      recruiter: 'Rekruter',
      student: 'Student'
    },
    title: 'Użytkownicy',
    subtitle: 'Lista'
  },
  removed_users_list: {
    labels: {
      created_at: 'Data utworzenia konta',
      email: 'Adres email',
      full_name: 'Imię i nazwisko',
      removed_by: 'Osoba usuwająca',
      roles: 'Role',
      removed_at: 'Data usunięcia',
      remove_reason: "Powód usunięcia"
    },
    no_role: 'Brak roli',
    roles: {
      administrator: 'Administrator',
      career_office: 'Pracownik biura karier',
      counselor: 'Doradca',
      employer: 'Pracodawca',
      graduate: 'Absolwent',
      recruiter: 'Rekruter',
      student: 'Student'
    },
    title: 'Usunięci Użytkownicy',
    subtitle: 'Lista'
  },
  webpages: {
    webpages_edit: {
      save: 'Zapisz',
      save_and_publish: 'Zapisz i opublikuj',
      save_as_draft: 'Zapisz wersję roboczą',
      subtitle: 'Edycja strony',
      title: 'CMS',
      webpage_updated: 'Strona internetowa została pomyślnie zaktualizowana.'
    },
    webpages_new: {
      publish: 'Opublikuj',
      save: 'Zapisz',
      save_draft: 'Zapisz wersję roboczą',
      subtitle: 'Nowa strona',
      title: 'CMS'
    }
  }
}
