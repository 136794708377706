<template>
  <b-row>
    <b-col class="col-12 text-right">
      <pcg-btn size="small" @click="goToNewForm">
        {{ $t('views.competence_skills.index.add_button') }}
      </pcg-btn>
    </b-col>

    <b-col class="col-12">
      <abk-loader v-if="showLoader" class="with-margins"/>
      <pcg-table v-else :items="competenceSkills" :fields="fields" class="table">
        <template v-slot:header>{{$t('views.competence_skills.index.table_header')}}</template>
        <template v-slot:cell(skills)="{ index, item: { skills } }">
          <div v-show="!showAllSkills[index]"
               class="skill-wrapper"
               :class="{ 'with-dots': skillsOverflowing[index] }"
               :ref="`skillsWrapper_${index}`">
            {{ skills.join(', ') }}
          </div>
          <div v-show="showAllSkills[index]">
            <div v-for="skill in skills" :key="skill" class="skill-record">{{ skill }}</div>
          </div>
          <span v-if="skillsOverflowing[index]"
                class="pcg-link pcg-small"
                @click="toggleSkills(index)">
            {{ showAllSkills[index] ? $t('general.hide') : $t('general.expand') }}
          </span>
        </template>
      </pcg-table>
    </b-col>

    <b-col class="col-12">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getCompetenceSkills"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </b-col>
  </b-row>
</template>

<script>
import ApiCompetenceSkills from '../../api/competence_skills'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import WindowResizeMixin from '../../mixins/window_resize'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'CompetenceSkillsIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, WindowResizeMixin, LoaderDelayMixin],
  data () {
    return {
      competenceSkills: [],
      fields: [],
      labels: [],
      skillsOverflowing: [],
      showAllSkills: []
    }
  },
  mounted () {
    this.getCompetenceSkills()
  },
  watch: {
    competenceSkills () {
      this.checkAllSkillsOverflowing()
    },
    windowWidth () {
      this.checkAllSkillsOverflowing()
    }
  },
  methods: {
    toggleSkills (index) {
      this.$set(this.showAllSkills, index, !this.showAllSkills[index])
      this.areSkillsOverflowing(index)
    },
    checkAllSkillsOverflowing () {
      for (let i = 0; i < this.competenceSkills.length; ++i) {
        this.areSkillsOverflowing(i)
      }
    },
    areSkillsOverflowing (index) {
      this.$nextTick(() => {
        if (!this.showAllSkills[index]) {
          const element = this.$refs[`skillsWrapper_${index}`]
          this.$set(this.skillsOverflowing, index, element && (element.offsetWidth < element.scrollWidth))
        }
      })
    },
    goToNewForm () {
      this.$router.push({ name: 'competences_skills_edit_path' })
    },
    getCompetenceSkills () {
      ApiCompetenceSkills.getCompetenceSkills({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.competenceSkills = this.dataTable(response.data.data)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.competence_skills.index.title'),
        subtitle: this.$t('views.competence_skills.index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((competence, index) => {
        const attributes = competence.attributes
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            fields.push({
              key: key,
              label: this.$t(`views.competence_skills.labels.${key}`)
            })
          })
        }
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  ::v-deep .table {
    table {
      table-layout: fixed;
    }
    th, td {
      &:nth-child(1) {
        width: 150px;
      }
    }
  }

  .skill-record {
    margin-bottom: $pcg-m-sm;
  }
  .skill-wrapper {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    &.with-dots {
      &::after {
        padding-left: 2px;
        content: '...';
        display: block;
        background-color: $pcg-white;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .pcg-link {
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
