import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'messages'

export default {
  getMessages (params) {
    return api.get(`${resource}?${stringify(decamelize(params))}`)
  },
  getMessage (messageId) {
    return api.get(`${resource}/${messageId}`)
  },
  getUniversityStructure () {
    return api.get(`${resource}/university_structure`)
  },
  getUserList (params) {
    return api.get(`${resource}/user_list`, { params: params })
  },
  createMessage (payload) {
    return api.post(`${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  updateMessage (messageId, payload) {
    return api.patch(`${resource}/${messageId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteMessage (messageId) {
    return api.delete(`${resource}/${messageId}`)
  }
}
