<template>
  <abk-events-list events-type="similar"/>
</template>

<script>
import AbkEventsList from '../../components/events/AbkEventsList'

export default {
  name: 'EventsListSimilar',
  components: { AbkEventsList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.events_list_similar.title')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
