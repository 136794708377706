<template>
  <div>
    <div class="title-container d-flex align-items-center">
      <h1 class="pcg-form-title d-inline-block h-auto mb-0 mr-3">
        {{ $t('components.curriculum_vitaes.abk_cv_custom_competence_form.title') }}
      </h1>
    </div>
    <draggable ghost-class="abk-ghost" animation="200" :value="value" @input="$emit('input', $event)">
      <transition-group name="slide" mode="out-in">
        <div v-for="(cvCustomCompetence, index) in value" :key="cvCustomCompetence.key" class="section">
          <abk-cv-custom-competence-form-section :value="cvCustomCompetence"
                                                 :index="index"
                                                 v-show="!cvCustomCompetence._destroy"
                                                 :errors="errors"
                                                 :dictionaries-options="dictionariesOptions"
                                                 :dictionaries-meta-info="dictionariesMetaInfo"
                                                 @remove="remove(cvCustomCompetence, index)"
                                                 @input="updateSection($event, index)"/>
        </div>
      </transition-group>
    </draggable>
    <pcg-btn size="small" variant="additional" class="my-0" @click="addSection">
      {{ $t('general.add') }}
    </pcg-btn>
  </div>
</template>

<script>
import AbkCvCustomCompetenceFormSection from './AbkCvCustomCompetenceFormSection'
import ApiDictionaries from '../../api/dictionaries'
import draggable from 'vuedraggable'

export default {
  name: 'AbkCvCustomCompetenceForm',
  components: { AbkCvCustomCompetenceFormSection, draggable },
  props: {
    value: Array,
    errors: Object
  },
  data () {
    return {
      dictionaries: ['foreign_language', 'driving_license', 'cv_custom_competence_other'],
      dictionariesOptions: {},
      dictionariesMetaInfo: {}
    }
  },
  computed: {
    maxKey () {
      return Math.max(...this.value.map((section) => section.key ? section.key : 0), 0)
    }
  },
  created () {
    this.getCustomCompetenceOptions()
  },
  methods: {
    getCustomCompetenceOptions () {
      ApiDictionaries.getSelectedDictionaries(this.dictionaries)
        .then(responses => {
          this.dictionaries.forEach((dictionary, index) => {
            if (responses[index] && responses[index].data && responses[index].data.data) {
              const options = responses[index].data.data.map(({ id, attributes }) => {
                return {
                  text: attributes.value,
                  value: id
                }
              })
              this.$set(this.dictionariesOptions, dictionary, options)
              this.$set(this.dictionariesMetaInfo, dictionary, responses[index].data.meta)
            }
          })
        })
        .catch((error) => {
          console.log(error)
          this.$toastr.e(this.$t('general.error_try_later'))
        })
    },
    update (newArray) {
      this.$emit('input', newArray)
    },
    updateSection (newSection, index) {
      const copy = [...this.value]
      copy.splice(index, 1, newSection)
      this.update(copy)
    },
    remove (section, index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          if (section.id) {
            const copy = [...this.value]
            copy.splice(index, 1, { ...section, _destroy: true })
            this.update(copy)
          } else {
            const copy = [...this.value]
            copy.splice(index, 1)
            this.update(copy)
          }
        }
      })
    },
    addSection () {
      const emptySection = this.value.filter(entry => !entry._destroy).some(entry => {
        const filtredEntry = _.omitBy(entry, (value, key) => {
          return ['id', 'key', 'customCompetenceType'].includes(key)
        })
        return _.isEmpty(_.pickBy(filtredEntry))
      })
      if (emptySection) {
        this.$toastr.e(this.$t('components.curriculum_vitaes.abk_cv_custom_competence_form.empty_section_error'))
        return
      }

      this.update([...this.value, {
        id: null,
        key: this.generateKey(),
        customCompetenceType: 'foreign_language',
        customCompetenceId: null
      }])
    },
    generateKey () {
      return this.maxKey + 1
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .title-container {
    margin-bottom: $pcg-m-lg;
  }
  .section:not(:first-child) {
    margin-top: $pcg-m-lg;
  }
  .section:last-child {
    margin-bottom: 15px;
  }
</style>
