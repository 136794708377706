import api from './index'

const resource = 'recipient_groups'

export default {
  getRecipientGroups () {
    return api.get(`${resource}`)
  },
  getSurveysRecipientGroups () {
    return api.get(`${resource}/surveys_recipient_groups`)
  },
  getMessagesRecipientGroups () {
    return api.get(`${resource}/messages_recipient_groups`)
  },
  getRecipientGroupsList (params={}) {
    return api.get(`${resource}/list`, { params: params })
  },
  createRecipientGroup (payload) {
    return api.post(`${resource}`, payload)
  },
  removeRecipientGroup (recipientGroupId) {
    return api.delete(`${resource}/${recipientGroupId}`)
  }
}
