<template>
  <div>
    <b-row>
      <pcg-radio class="col-12 form-group"
                 :required="true"
                 :options="messageType"
                 v-model="message.messageType"
                 name="message-type"
                 :show-error="errorExist('messageType')"
                 :error-text="getError('messageType')"
                 :label="$t('components.abk_message_form.fields.message_type')"/>

      <transition name="slide" mode="out-in">
        <div class="col-12 form-group" >
          <label class="pcg-input-label pcg-input-label-required">
            {{ $t('components.abk_message_form.fields.recipient_group_id') }}
          </label>

          <b-row class="align-items-center" v-if="!showRecipientGroupForm">
            <pcg-search-select class="abk-input col-12 col-lg"
                               always-active
                               :options="options.recipientGroup"
                               :value="String(message.recipientGroupId)"
                               @input="message.recipientGroupId = $event"
                               :error-text="getError('recipientGroupId') || getError('recipientGroup')"
                               :show-error="errorExist('recipientGroupId') || errorExist('recipientGroup')"/>
            <b-col class="col-12 col-lg-auto text-center recipient-group-separator">
              {{ $t('general.or') }}
            </b-col>
            <b-col class="col-12 col-lg-auto text-center">
              <pcg-btn size="small"
                       @click="showRecipientGroupForm = true"
                       variant="additional"
                       class="action-btn">
                {{ $t('general.create_new') }}
              </pcg-btn>
            </b-col>
          </b-row>
          <abk-recipient-group-form :universityStructure="universityStructure"
                                    :messageRecipientTypes="message.recipientTypes"
                                    v-if="showRecipientGroupForm"
                                    @saved="recipientGroupSaved"
                                    @close="showRecipientGroupForm = false"/>
        </div>
      </transition>

      <transition name="slide" mode="out-in">
        <div class="col-12 form-group" v-if="!showRecipientGroupForm">
          <label class="pcg-input-label">
            {{ $t('components.abk_message_form.fields.send_to') }}
          </label>
          <div class="recipients-list" :class="{ collapsed: recipientsCollapsed }">
            {{ recipients }}
            <span class="custom-ellipsis" @click="recipientsCollapsed = !recipientsCollapsed" v-if="recipients">
              {{ recipientsCollapsed ? $t('components.abk_message_form.show_more') : $t('components.abk_message_form.collapse') }}
            </span>
          </div>
          <div class="recipient-count mt-2">
            {{ $t('components.abk_message_form.recipients_count') }}
            <b>{{ recipientsCount }}</b>
          </div>
        </div>
      </transition>
      <pcg-text-input class="col-12 col-lg-6"
                      autocomplete="off"
                      :required="true"
                      :show-label="true"
                      :label="$t('components.abk_message_form.fields.title')"
                      :error-text="getError('title')"
                      :show-error="errorExist('title')"
                      v-model="message.title"/>
      <label class="col-12 pcg-input-label">
        {{ $t('general.content') }}
      </label>
      <b-col class="col-12" style="margin-bottom: 1rem">
        <tinymce-editor :class="{ invalid: showContentErrors }"
                        :init="tinymceSettings"
                        :value="message.content"
                        @input="update('content', $event)"/>
        <input class="pcg-field d-none" :required="showContentErrors"/>
        <div v-if="errorExist('content')" class="pcg-error-messages">{{ getError('content') }}</div>
      </b-col>
      <b-col class="col-12">
        <label class="attachment-input" @click="addAttachment">
          <i class="pcg-icon-clip mr-3"></i> {{ $t('components.abk_attachment_input.text') }}
        </label>
        <b-row>
          <template v-for="(attachment, index) in attachments">
            <div v-if="!attachment._destroy" :key="attachment.key" class="col-12 col-sm-6 col-lg-4">
              <abk-file-input show-remove-button
                              :error-text="getAttachmentError(index)"
                              v-b-tooltip.hover
                              :title="getAttachmentError(index)"
                              :file-url="getAttachmentUrl(index)"
                              @input="updateAttachment(index, $event)"
                              @remove="removeAttachment(index)"
                              :status="fileInputStatus(index)"/>
            </div>
          </template>
        </b-row>
      </b-col>

      <div v-if="errorExist('base')" class="pcg-error-messages form-group col-12">{{ getError('base') }}</div>

      <b-col>
        <pcg-btn size="small" @click="save" class="action-btn mr-3">{{ $t('general.send') }}</pcg-btn>
        <pcg-btn size="small" @click="saveDraft" variant="additional" class="action-btn">{{ $t('general.save_draft') }}</pcg-btn>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiMessages from '../../api/messages'
import ApiRecipientGroups from '../../api/recipient_groups'
import AbkFileInput from '../AbkFileInput'
import AbkRecipientGroupForm from '../recipient_groups/AbkRecipientGroupForm'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autolink'
import 'tinymce-i18n/langs/pl'
import 'tinymce-i18n/langs/en_GB'
import TinymceEditor from '@tinymce/tinymce-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AbkMessageForm',
  components: { AbkRecipientGroupForm, AbkFileInput, TinymceEditor },
  props: {
    messageId: String,
    submitAction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      message: {
        messageType: 'communique',
        marketingContent: false,
        recipientTypes: [],
        title: null,
        content: null,
        status: null,
        recipientGroupId: null
      },
      // allowSms: null,
      attachments: [],
      recipients: '',
      recipientsCount: 0,
      universityStructure: {},
      recipientsCollapsed: true,
      showRecipientGroupForm: false,
      errors: {},
      options: {
        recipientGroup: []
      },
      invalidContentValue: null,
      tinymceSettings: {
        content_style: 'p { font-size: 12pt; font-family: "times new roman"; margin: 0 0 14px 0 } ul { font-size: 12pt; font-family: "times new roman" }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 300,
        plugins: 'lists autolink link textcolor colorpicker',
        toolbar: 'bold italic bullist insert link forecolor backcolor fontselect fontsizeselect',
        menubar: false,
        branding: false,
        statusbar: false,
        valid_elements: 'br,i,ul,li,p,em,strong/b,span[style],a[href|target=_blank]',
        default_link_target: '_blank',
        target_list: false,
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 30pt 36pt 48pt',
        link_assume_external_targets: 'http',
        language: this.$i18n.locale === 'pl' ? 'pl' : 'en_GB',
        anchor_top: false,
        anchor_bottom: false
      }
    }
  },
  watch: {
    watchedRecipientType (newValue) {
      this.getUsers()
    },
    watchedRecipientGroupId (newValue) {
      this.getUsers()
    },
    watchMessageType (newValue) {
      this.getUsers()
    },
    'errors.content': function (newError) {
      if (newError) {
        this.invalidContentValue = this.message.content
      }
    }
  },
  computed: {
    ...mapGetters('environment', ['env']),
    watchedRecipientType () {
      return this.message.recipientTypes
    },
    watchedRecipientGroupId () {
      return this.message.recipientGroupId
    },
    watchMessageType () {
      return this.message.messageType
    },
    attachmentErrorsKeys () {
      const keys = Object.keys(this.errors).filter(key => {
        return key.split('.')[0] === 'messageAttachments' && key.split('.').length > 1
      })
      return keys
    },
    attachmentErrors () {
      const errors = []

      this.attachmentErrorsKeys.forEach(key => {
        const splittedKey = key.split('.')
        if (!errors[splittedKey[1]]) { errors[splittedKey[1]] = {} }
        errors[splittedKey[1]] = this.errors[key]
      })

      return errors
    },
    messageType () {
      // eslint-disable-next-line eqeqeq
      if (this.env && this.env.allowSms == 1) {
        return [
          { text: this.$t('general.message_type.communique'), value: 'communique' },
          { text: this.$t('general.message_type.sms'), value: 'sms' },
          { text: this.$t('general.message_type.newsletter'), value: 'newsletter' }
        ]
      } else {
        return [
          { text: this.$t('general.message_type.communique'), value: 'communique' },
          { text: this.$t('general.message_type.newsletter'), value: 'newsletter' }
        ]
      }
    },
    showContentErrors () {
      return (!this.changedInvalidContentValue && this.errors.content)
    },
    changedInvalidContentValue () {
      return this.invalidContentValue !== this.message.content
    }
  },
  beforeMount () {
    // this.allowSms = this.env.allowSms
  },
  mounted () {
    this.getMessage()
    this.getRecipientGroups()
    this.getUsers()
    this.getUniversityStructure()
  },
  methods: {
    recipientGroupSaved (recipientGroupId) {
      this.message.recipientGroupId = recipientGroupId
      this.getRecipientGroups()
    },
    fileInputStatus (index) {
      const attachment = this.attachments[index]

      if (this.getAttachmentError(index)) {
        return 'fail'
      } else if (attachment.attachment) {
        return 'success'
      }
      return 'upload'
    },
    addAttachment () {
      const attachmentKeys = this.attachments.map(attachment => attachment.key)
      const newKey = attachmentKeys.length > 0 ? Math.max(...attachmentKeys) + 1 : 0
      this.attachments.push({ key: newKey, attachment: null })
    },
    getAttachmentError (index) {
      const error = this.errors[this.getAttachmentErrorKey(index)]
      return error ? error.join(', ') : null
    },
    getAttachmentErrorKey (index) {
      return `messageAttachments.${index}.attachment`
    },
    getAttachmentUrl (index) {
      const attachment = this.attachments[index].attachment
      return attachment ? attachment.url : null
    },
    updateAttachment (index, value) {
      this.attachments[index].attachment = value
      this.errors[this.getAttachmentErrorKey(index)] = null
    },
    removeAttachment (index) {
      const attachment = this.attachments[index]
      if (attachment.id) {
        attachment._destroy = true
      } else {
        this.attachments.splice(index, 1)
      }
    },
    save () {
      this.submitAction({
        message: {
          ...this.message,
          messageAttachments: this.attachments,
          status: 'sent'
        }
      })
        .then((response) => {
          this.clearErrors()
          this.$toastr.s(this.$t('general.message_sent'))
          this.$router.go(-1)
        })
        .catch((error) => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    saveDraft () {
      this.submitAction({
        message: {
          ...this.message,
          messageAttachments: this.attachments,
          status: 'draft'
        }
      })
        .then((response) => {
          this.clearErrors()
          this.getMessage()
          this.$toastr.s(this.$t('components.abk_message_form.working_copy_saved'))
          this.$router.go(-1)
        })
        .catch((error) => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    clearErrors () {
      this.errors = {}
    },
    getRecipientGroups () {
      ApiRecipientGroups.getMessagesRecipientGroups()
        .then(response => {
          this.options.recipientGroup = response.data.data.map(recipientGroup => {
            return {
              text: recipientGroup.attributes.name,
              value: recipientGroup.id
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_message_form.error_download_recipients'))
        })
    },
    getMessage () {
      if (!this.messageId) { return }
      ApiMessages.getMessage(this.messageId)
        .then(response => {
          this.message = { ...this.message, ...response.data.data.attributes }
          let key = 0
          this.attachments = response.data.included.filter(included => included.type === 'message_attachment').map(messageAttachment => {
            return {
              key: key++,
              id: messageAttachment.id,
              ...messageAttachment.attributes
            }
          })
          this.$store.dispatch('header/setObjectName', this.message.title)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_message_form.error_download_messages'))
        })
    },
    getUsers () {
      ApiMessages.getUserList({
        recipient_group_id: this.message.recipientGroupId,
        recipient_type: this.message.recipientTypes,
        message_type: this.message.messageType,
        graduate_fate: false
      })
        .then(response => {
          this.recipientsCollapsed = true
          this.recipients = response.data.records.join(', ')
          this.recipientsCount = response.data.count
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_message_form.error_download_list_of_recipients'))
        })
    },
    getUniversityStructure () {
      ApiMessages.getUniversityStructure()
        .then(response => {
          this.universityStructure = response.data
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_message_form.error_university_structure'))
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    update (key, value) {
      this.message.content = value
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .content-textarea {
    margin-bottom: 2rem;
    /deep/ textarea {
      height: 260px;
    }
  }
  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .recipient-count {
    color: $pcg-dark-gray;
    line-height: $font-size-m;
    font-size: $font-size-s;
  }
  .recipients-list {
    color: $pcg-dark-gray;
    line-height: $font-size-m;
    font-size: $font-size-s;
    .custom-ellipsis {
      color: $main-active-color;
      font-weight: bolder;
      cursor: pointer;
    }
    &.collapsed {
      white-space: nowrap;
      overflow: hidden;
      .custom-ellipsis {
        position: absolute;
        right: 10px;
        // background color
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(247, 248, 250, 1) 25%, rgba(247, 248, 250, 1) 0%);
        padding-left: 30px;
      }
    }
  }
  .recipient-group-separator {
    color: $pcg-the-darkest-gray;
    font-size: $font-size-s;
    @media (max-width: $screen-md-max) {
      margin-top: 10px;
    }
  }

  .invalid ~ /deep/ .tox-tinymce {
    box-shadow: $invalid-field-box-shadow;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .attachment-input {
      color: $main-active-color;
    }
    .recipients-list {
      color: $pcg-dark-gray;
      .custom-ellipsis {
        color: $main-active-color;
      }
      &.collapsed {
        .custom-ellipsis {
          background: $pcg-the-lightest-gray;
        }
      }
    }
  }
</style>
