<template>
  <div>
    <div v-for="sub in subEvents" :key="sub.id">
      <router-link :to="{ name: 'sub_events_show_path', params: { id: sub.id } }" class="event-title">
        {{ sub.duration }}
      </router-link>
      <br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbkSubEventsBox',
  props: {
    event: Object
  },
  data () {
    return {
      subEvents: []
    }
  },
  mounted () {
    this.event.subEvents.forEach((se) => {
      const subAttributes = se.data.attributes
      const startDate = this.formatDate(subAttributes.startDate, 'dddd DD.MM.YYYY HH:mm')
      const endDate = this.formatDate(subAttributes.endDate, 'dddd DD.MM.YYYY HH:mm')
      let duration

      if (startDate && endDate) {
        duration = _.uniq([startDate, endDate]).join(' - ')
      } else if (startDate) {
        duration = `${this.$t('general.from')} ${startDate}`
      } else if (endDate) {
        duration = `${this.$t('general.to')} ${endDate}`
      }
      this.subEvents.push({
        id: se.data.id,
        duration: duration
      })
    })
  },
  methods: {
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";
</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
</style>
