<template>
  <div class="favorite-btn" :class="{ added: added }" role="button" @click="$emit('click')">
    <i class="fa-heart" :class="iconClass"></i>
    <span v-show="!added">
      {{ $t("components.abk_add_to_favorites_button.add") }}
    </span>
    <span v-show="added">
      {{ $t("components.abk_add_to_favorites_button.remove") }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'abk-add-to-favorites-button',
  props: {
    added: Boolean
  },
  computed: {
    iconClass () {
      return this.added ? 'fas' : 'far'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .favorite-btn {
    color: $main-active-color;
    font-size: $font-size-m;
    display: flex;
    &:hover {
      color: $hover-color;
      cursor: pointer;
    }
    &:active, &:focus {
      color: $pressed-color;
    }
    i {
      padding-right: 12px;
      font-size: 1.4em;
    }
    &.added {
      color: $alert-error;
      &:hover {
        color: darken($alert-error, 20%);
        cursor: pointer;
      }
      &:active, &:focus {
        color: darken($alert-error, 15%);
      }
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .favorite-btn {
      color: $main-active-color;
      &:hover {
        color: $hover-color;
      }
      &:active, &:focus {
        color: $pressed-color;
      }
      &.added {
        color: $alert-error;
        &:hover {
          color: darken($alert-error, 20%);
        }
        &:active, &:focus {
          color: darken($alert-error, 15%);
        }
      }
    }
  }
</style>
