<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.968" height="12">
        <path :class="variant" d="M19.533 0H2.447A2.428 2.428 0 00.006 2.4v7.2A2.428 2.428 0 002.447 12h17.086a2.428 2.428 0 002.441-2.4V2.4A2.428 2.428 0 0019.533 0zm.474 10.159H1.972V1.842h18.035v8.317zM5.83 9.52H4.54V4.628l-1.541.47V4.066l2.693-.948h.138v6.4zm6.569 0H7.942v-.87l2.1-2.2a5.193 5.193 0 00.641-.812 1.3 1.3 0 00.207-.659.967.967 0 00-.219-.67.8.8 0 00-.625-.243.859.859 0 00-.69.3 1.164 1.164 0 00-.252.779h-1.3a2.055 2.055 0 01.284-1.066 1.98 1.98 0 01.8-.758 2.468 2.468 0 011.174-.274 2.335 2.335 0 011.561.474 1.665 1.665 0 01.556 1.339 2.118 2.118 0 01-.25.966 5.377 5.377 0 01-.858 1.146L9.593 8.505h2.8v1.023zm2.1-3.759h.688a.979.979 0 00.727-.241.879.879 0 00.237-.642.772.772 0 00-.235-.6.914.914 0 00-.645-.215.966.966 0 00-.62.2.633.633 0 00-.25.521H13.11a1.536 1.536 0 01.275-.9 1.813 1.813 0 01.768-.621 2.585 2.585 0 011.087-.224 2.461 2.461 0 011.616.485 1.642 1.642 0 01.585 1.337 1.334 1.334 0 01-.272.808 1.771 1.771 0 01-.714.566 1.617 1.617 0 01.819.58 1.553 1.553 0 01.271.913 1.674 1.674 0 01-.633 1.366 2.572 2.572 0 01-1.672.513 2.43 2.43 0 01-1.592-.5 1.637 1.637 0 01-.618-1.335h1.29a.735.735 0 00.275.59 1.161 1.161 0 001.4-.012.822.822 0 00.261-.634.9.9 0 00-1.072-.958H14.5V5.76z" fill-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
  name: 'AbkSurveyNumberSvg',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (variant) {
        return ['primary', 'secondary'].includes(variant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars";

    .primary {
        fill: $main-color
    }
    .secondary {
        fill: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars-contrast";

    .contrast{
        .primary {
            fill: $main-color
        }
        .secondary {
            fill: $main-active-color;
        }
    }
</style>
