<template>
  <b-row style="margin-top: 4rem">
    <abk-user-login-form class="col-12 form" v-model="user" :errors="errors" @loginUser="loginUser" @resetPassword="resetPassword"/>
    <b-modal ref="acceptancesModal"
             centered hide-header
             v-bind:ok-title="$t('general.save_and_login')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="submitAcceptances"
             @cancel="logout"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2 class="missing-approvals">{{ $t('views.edit_company_profile.approvals') }}</h2>
      <div class="missing-approvals-text">{{ $t('general.missing_approvals') }}</div>
      <h3 v-if="currentAcceptances.cv && currentAcceptances.cv.length > 0">{{ $t('general.student_graduate') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
               v-for="(acceptance, index) in currentAcceptances.cv"
               :key="`cv-approval-${index}`">
          <pcg-checkbox variant="normal"
                        class="col-12"
                        :required="acceptance.required"
                        :show-error="errorExist(`acceptances.${index}.value`)"
                        :error-text="getError(`acceptances.${index}.value`)"
                        v-model="acceptance.value">
            <span v-html="acceptance.text"></span>
          </pcg-checkbox>
        </b-row>
      <h3 v-if="currentAcceptances.company && currentAcceptances.company.length > 0">{{ $t('general.employer_recruiter') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
             v-for="(acceptance, index) in currentAcceptances.company"
             :key="`company-approval-${index}`">
        <pcg-checkbox variant="normal"
                      class="col-12"
                      :required="acceptance.required"
                      :show-error="errorExist(`acceptances.${index}.value`)"
                      :error-text="getError(`acceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import AbkUserLoginForm from '../components/AbkUserLoginForm'
import ApiUsers from '../api/users'
import ApiDictionaryAcceptances from '../api/dictionary_acceptances'
import CompanyPopupMixin from '../mixins/company_popup'
import CvPopupMixin from '../mixins/cv_popup'
import { mapGetters } from 'vuex'

export default {
  name: 'login-user',
  components: { AbkUserLoginForm },
  mixins: [CompanyPopupMixin, CvPopupMixin],
  data () {
    return {
      user: {
        email: null,
        password: null
      },
      userId: null,
      cvAcceptances: [],
      companyAcceptances: [],
      currentAcceptances: [],
      errors: {},
      accErrors: []
    }
  },
  created () {
    this.hideAppLoader()
  },
  watch: {
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapGetters('auth', ['currentRole', 'roles']),
    ...mapGetters('environment', ['env']),
    cvAcceptancesToSend () {
      return this.currentAcceptances.cv.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    },
    companyAcceptancesToSend () {
      return this.currentAcceptances.company.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    }
  },
  methods: {
    getError (field) {
      if (this.errorExist(field)) {
        return this.accErrors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.accErrors && this.accErrors[field])
    },
    logout () {
      const role = this.currentRole
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${this.env.abkOpEndSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${this.env.abkOpEndSsessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    submitAcceptances (e) {
      e.preventDefault()
      ApiUsers.fillAcceptances({ user: { cvAcceptances: this.cvAcceptancesToSend, companyAcceptances: this.companyAcceptancesToSend } })
        .then(response => {
          this.setLogin(this.userId)
        })
        .catch(error => {
          this.accErrors = error.response.data
          this.$toastr.e(this.$t('general.fields_not_filled'))
        })
    },
    loginUser () {
      ApiUsers.loginUser(this.user)
        .then((response) => {
          ApiDictionaryAcceptances.getDictionaryAcceptanceAllWords()
            .then(res => {
              this.cvAcceptances = []
              this.companyAcceptances = []
              res.data.data.forEach((dw) => {
                if (dw.attributes.registration) {
                  const a = {
                    key: dw.attributes.type,
                    dictionaryAcceptanceWordId: dw.id,
                    required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                    text: dw.attributes.value,
                    value: false,
                    name: dw.attributes.name,
                    wordType: dw.attributes.wordType
                  }
                  const b = {
                    key: dw.attributes.type,
                    dictionaryAcceptanceWordId: dw.id,
                    required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                    text: dw.attributes.value,
                    value: false,
                    name: dw.attributes.name,
                    wordType: dw.attributes.wordType
                  }
                  this.cvAcceptances.push(a)
                  this.companyAcceptances.push(b)
                }
              })
              let sortByName = []
              sortByName.push(...this.cvAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
              this.cvAcceptances = sortByName
              sortByName = []
              sortByName.push(...this.companyAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
              this.companyAcceptances = sortByName
              this.setLogin(response.data.data.id)
            })
            .catch(err => {
              this.$toastr.e(err)
              console.log(err)
            })
        })
        .catch(error => {
          this.$toastr.e(error.response.data)
          this.errors = error.response.data
        })
    },
    async checkCurrentUserAcceptances () {
      let valid = false
      try {
        const response = await ApiUsers.checkAcceptances()
        valid = response.data.cvAcceptance && response.data.companyAcceptance
        if (!valid) {
          const userRoles = this.$store.getters['auth/roles']
          if (userRoles.includes('student') || userRoles.includes('graduate')) {
            response.data.currentCvAcceptances.forEach(acc => {
              const idx = this.cvAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.cvAcceptances[idx].value = acc.value
                this.cvAcceptances[idx].type = 'cv'
              }
            })
          }
          if (userRoles.includes('employer') || userRoles.includes('recruiter')) {
            response.data.currentCompanyAcceptances.forEach(acc => {
              const idx = this.companyAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.companyAcceptances[idx].value = acc.value
                this.companyAcceptances[idx].type = 'company'
              }
            })
          }
        }
        return valid
      } catch (err) {
        return false
      }
    },
    setLogin (userId) {
      this.$store.dispatch('auth/devSignIn', { id: userId })
        .then(response => {
          this.checkCurrentUserAcceptances()
            .then(result => {
              this.userId = response.data.data.id
              if (result) {
                const redirectTo = this.$store.getters['auth/getRedirectTo']
                if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
                  this.$router.push({ name: 'role_choose_path' })
                } else if (redirectTo && redirectTo.name) {
                  this.$store.dispatch('auth/clearRedirectTo')
                  this.$router.push(redirectTo)
                } else {
                  this.$router.push({ name: 'student_dashboard_path' })
                  this.checkCompany()
                  this.checkCv()
                }
              } else {
                const userRoles = this.$store.getters['auth/roles']
                let result = []
                if (userRoles.includes('employer') || userRoles.includes('recruiter')) {
                  result.push(this.companyAcceptances.filter(a => !a.value && (a.key === 'employer' || a.key === 'cooperation' || a.key === 'survey')))
                }
                if (userRoles.includes('student') || userRoles.includes('graduate')) {
                  result.push(this.cvAcceptances.filter(a => !a.value && (a.key === 'cv' || a.key === 'survey')))
                }
                result = result.flat()
                this.currentAcceptances = result
                const sortByName = []
                sortByName.push(...this.currentAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
                this.currentAcceptances = sortByName
                this.currentAcceptances = {
                  cv: this.currentAcceptances.filter(acc => acc.type === 'cv'),
                  company: this.currentAcceptances.filter(acc => acc.type === 'company')
                }
                this.$refs.acceptancesModal.show()
              }
            })
            .catch(err => {
              this.$toastr.e(err.response.data.error)
            })
        })
        .catch(error => {
          console.log(error.response.data.error)
          this.$toastr.e(error.response.data.error)
        })
    },
    resetPassword () {
      this.$router.push({ name: 'reset_password_path' })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.login_user.title'),
        subtitle: this.$t('views.login_user.subtitle')
      })
    },
    hideAppLoader () {
      setTimeout(() => {
        document.getElementById('app-loader').style.display = 'none'
      }, 500)
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .missing-approvals {
    margin-bottom: 2rem;
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
  }

  .missing-approvals-text {
    margin-bottom: 2rem;
    font-weight: 400;
    color: $pcg-gray;
  }
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .missing-approvals {
    color: $pcg-gray;
  }
  .missing-approvals-text {
    color: $pcg-gray;
  }
}
</style>
