import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'offer_invitations'

export default {
  createOfferInvitation (payload) {
    return api.post(`${resource}`, payload)
  },
  getOfferInvitations (queryParams) {
    return api.get(`${resource}?${stringify(decamelize(queryParams))}`)
  },
  getCurrentOfferInvitations (queryParams) {
    return api.get(`${resource}/current?${stringify(decamelize(queryParams))}`)
  },
  getArchivedOfferInvitations (queryParams) {
    return api.get(`${resource}/archived?${stringify(decamelize(queryParams))}`)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  }
}
