<template>
  <abk-users-list></abk-users-list>
</template>

<script>
import AbkUsersList from '../components/AbkUsersList'
export default {
  name: 'users-list',
  components: { AbkUsersList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.users_list.title'),
        subtitle: this.$t('views.users_list.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped>

</style>
