<template>
  <abk-survey-form :submit-action="createSurvey"
                   :validate-action="validate"
                   :survey-id="this.$route.query.copy_id"
                   new-survey />
</template>

<script>
import ApiSurveys from '../../api/surveys'
import AbkSurveyForm from '../../components/surveys/AbkSurveyForm'

export default {
  name: 'SurveysNew',
  components: { AbkSurveyForm },
  methods: {
    validate (form) {
      return ApiSurveys.validateStep(form)
    },
    createSurvey (form) {
      return ApiSurveys.createSurvey(form)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.surveys.surveys_new.title'),
        subtitle: this.$t('views.surveys.surveys_new.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
