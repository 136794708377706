<template>
  <pcg-box v-if="offers.length > 0">
    <div class="pcg-box-inner-header">
      <div class="d-flex">
        <i class="pcg-icon-fan"></i>
        {{ $t('components.abk_search_new_offers.title') }}
      </div>
      <router-link :to="{ name: 'test2_path' }" v-if="offersCount > 4" class="pcg-link float-right">
        {{ $t("general.see_more") }}
      </router-link>
    </div>
    <b-row>
      <b-col v-for="offer in offers" :key="offer.id" class="offer col-12 col-sm-6 col-md-6 col-lg">
        <router-link tag="div" :to="{ name: 'offers_show_path', params: { id: offer.id } }">
          <div class="time-left" v-if="offer.expirationDate && offerTimeLeft(offer) > 0 && offerTimeLeft(offer) <= 4">
            {{ $tc('general.days_left', offerTimeLeft(offer)) }}
          </div>
          <div class="img-container">
            <img :src="companyLogo(offer)" class="img-fluid image mr-2">
            <div v-if="offer.company && offer.company.employerType === 'partner'" class="partner-container">
              <span class="partner-text">{{ offer.company.employerTypeText }}</span>
              <img v-if="contrast" :src="offer.company.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
              <img v-else :src="offer.company.employerTypeLogo" class="img-fluid rounded partner-logo">
            </div>
          </div>
          <div class="line-separator"></div>
          <div class="title">
            {{ offer.jobTitle }}
          </div>
          <div class="place">
            {{ offer.workplace }}
          </div>
        </router-link>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'abk-search-new-offers',
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    offersCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    companyLogo (offer) {
      return _.get(offer, ['company', 'logo', 'thumb', 'url']) || _.get(offer, ['company', 'logo', 'url'])
    },
    offerTimeLeft (offer) {
      const time = moment(offer.expirationDate)
      const now = moment()
      if (time.isValid()) {
        const timeLeft = time.diff(now, 'days') + 1
        return timeLeft > 0 ? timeLeft : null
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .pcg-box-inner-header {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $screen-xs-max) {
      flex-direction: column;
    }
    .pcg-link {
      font-weight: normal;
      padding-left: 15px;
      font-size: $font-size-s;
      white-space: nowrap;
      text-align: right;
    }
  }

  .row {
    padding-top: 15px;
  }

  .offer {
    @media only screen and (max-width: $screen-md-max) {
      &:nth-child(-n+2) {
        padding-bottom: 40px;
      }
    }
    @media only screen and (max-width: $screen-xs-max) {
      &:not(:last-child) {
        padding-bottom: 40px;
      }
    }

    & > div {
      cursor: pointer;
    }
    .time-left {
      font-size: $font-size-s;
      color: $pcg-light-red;
      position: absolute;
      line-height: 1;
      top: -15px;
    }
    .img-container {
      height: 75px;
      margin-bottom: 15px;
      display: flex;
      vertical-align: middle;
      align-items: flex-end;
      .image {
        max-width: 60%;
        max-height: 75px;
      }
    }
    .line-separator {
      height: 5px;
      background: $main-color;
      width: 90%;
    }
    .title {
      color: $main-color;
      font-weight: 400;
      margin-top: 1.2em;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
    .place {
      color: $pcg-gray;
      font-size: $font-size-s;
    }
  }

  .partner-container {
    display: flex;
    align-items: center;
  }

  .partner-text {
    color: $pcg-black;
    font-weight: bold;
    font-size: $font-size-xs;
    background-color: rgba(200, 200, 200, 0.5);
    padding: .1rem .3rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .partner-logo {
    position: relative;
    right: .1rem;
    max-width: 20px;
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .offer {
      .time-left {
        color: $pcg-light-red;
      }
      .line-separator {
        background: $main-color;
      }
      .title {
        color: $main-color;
      }
      .place {
        color: $pcg-gray;
        font-size: $font-size-s;
      }
    }
    .partner-text {
      color: $pcg-black;
    }
  }
</style>
