<template>
  <b-row>
    <b-col class="col-12 text-right">
      <pcg-btn size="small" @click="goToNewForm">
        + {{ $t('components.competences.abk_competences_list.add_button') }}
      </pcg-btn>
    </b-col>

    <b-col class="col-12">
      <abk-loader v-if="showLoader" class="with-margins"/>
      <pcg-table v-else-if="competences && competences.length"
                 :items="competences"
                 :fields="fields"
                 class="competences-table">
        <template v-slot:header>{{$t('components.competences.abk_competences_list.table_header')}}</template>
        <template v-slot:cell(actions)="{ item }">
          <div class="d-flex align-items-center">
            <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                            class="my-0"
                            :tooltip="$t('general.edit')"
                            @click="goToEditPath(item.id)"/>
            <pcg-btn-symbol icon-class="pcg-icon-trash-can"
                            class="abk-delete-btn my-0"
                            :tooltip="$t('general.delete')"
                            @click="destroyCompetence(item)"/>
          </div>
        </template>
      </pcg-table>
      <pcg-box v-else>
        <span class="pcg-no-records">{{ $t('components.competences.abk_competences_list.no_records') }}</span>
      </pcg-box>
    </b-col>

    <b-col class="col-12">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getCompetences"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </b-col>
  </b-row>
</template>

<script>
import ApiCompetences from '../../api/competences'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import LoaderDelayMixin from '../../mixins/loader_delay'
import { decamelize } from 'humps'
import AbkLoader from '../AbkLoader'
export default {
  name: 'AbkCompetencesList',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  props: {
    competencesType: {
      type: String,
      default: 'all',
      validate (competenceType) {
        return ['all', 'connectedWithSkills', 'notConnectedWithSkills'].includes(competenceType)
      }
    }
  },
  data () {
    return {
      competences: [],
      fields: [],
      labels: ['name', 'description', 'status'],
      getCompetencesActionPerType: {
        all: 'getCompetences',
        connectedWithSkills: 'getConnectedWithSkills',
        notConnectedWithSkills: 'getNotConnectedWithSkills'
      }
    }
  },
  mounted () {
    this.getCompetences()
    this.fields = this.fieldsTable()
  },
  methods: {
    goToEditPath (competenceId) {
      this.$router.push({ name: 'competences_edit_path', params: { id: competenceId } })
    },
    goToNewForm () {
      this.$router.push({ name: 'competences_new_path' })
    },
    getCompetences () {
      ApiCompetences[this.getCompetencesActionPerType[this.competencesType]]({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.competences = this.dataTable(response.data.data)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    updateCompetence ({ id }) {
      this.loading = true
      const competence = this.competences.find(e => e.id === id)
      ApiCompetences.updateCompetence(competence.id, {
        competence: {
          status: this.booleanToStatus(!this.statusToBoolean(competence.status))
        }
      })
        .then(() => {
          this.errors = {}
          competence.status = this.booleanToStatus(!this.statusToBoolean(competence.status))
          competence.switches[0].value = this.statusToBoolean(competence.status)
          setTimeout(() => { this.$toastr.s(this.$t('general.competence_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
        })
    },
    destroyCompetence (competence) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('components.competences.abk_competences_list.delete_info_text'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCompetences.destroyCompetence(competence.id)
            .then(() => {
              this.getCompetences()
              setTimeout(() => { this.$toastr.s(this.$t('general.competence_deleted')) }, 1)
            })
            .catch(error => {
              console.error(error.response.data)
            })
        }
      })
    },
    statusToBoolean (status) {
      return status === 'active'
    },
    booleanToStatus (status) {
      return status ? 'active' : 'inactive'
    },
    dataTable (data) {
      const result = []
      data.forEach(competence => {
        const attributes = { id: competence.id, ...competence.attributes }
        const switches = {
          switches: [{
            id: `${competence.id}-b`,
            link: () => this.updateCompetence(competence),
            type: 'switch',
            value: this.statusToBoolean(attributes.status)
          }]
        }
        Object.assign(attributes, switches)
        result.push(attributes)
      })
      return result
    },
    fieldsTable () {
      const fields = []
      _.forEach(this.labels, (val) => {
        if (val === 'status') {
          fields.push({
            key: val,
            label: this.$t(`views.competences.labels.${decamelize(val)}`),
            formatter: value => {
              return this.$t(`views.competences.statuses.${value === 'active' ? value : 'inactive'}`)
            }
          })
        } else {
          fields.push({
            key: val,
            label: this.$t(`views.competences.labels.${val}`)
          })
        }
      })
      fields.push({
        key: 'switches',
        label: this.$t('views.competences.labels.switches')
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.competences.labels.actions')
      })
      return fields
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  @media(max-width: $screen-md-max) {
    .competences-table {
      ::v-deep table {
        th, td {
          &:nth-child(n+2):nth-child(-n+3) {
            display: none !important;
          }
        }
      }
    }}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>

