<template>
  <pcg-box>
    <div v-if="$slots.header" class="pcg-box-inner-header">
      <slot name="header"/>
    </div>
    <div class="event" v-for="event in events.slice(0, limit)" :key="`event-${event.id}`">
      <span class="event-title">{{ event.eventTitle }}<br/></span>
      <span class="pcg-txt-small-bold event-location">{{ event.place }}</span>
      <abk-sub-events-box :event="event"></abk-sub-events-box>
    </div>
  </pcg-box>
</template>

<script>
import AbkSubEventsBox from './AbkSubEventsBox'

export default {
  name: 'AbkEventsBox',
  components: { AbkSubEventsBox },
  props: {
    events: Array,
    limit: {
      type: Number,
      default: 5
    }
  },
  methods: {
    formatDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .event:not(:last-child) {
    margin-bottom: $font-size-s;
  }
  .event-title {
    color: $main-color;
    font-weight: 400;
    text-transform: uppercase;
  }
  .event-date {
    font-size: $font-size-s;
    margin-bottom: 3px;
  }
  .all-events-link {
    width: 100%;
    text-align: right;
    a {
      color: $main-active-color;
    }
  }
  .event-date, .event-location {
    color: $pcg-dark-gray;
    font-weight: 400;
  }
</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .event-title {
      color: $main-color;
    }
    .all-events-link {
      a {
        color: $main-active-color;
      }
    }
    .event-date, .event-location {
      color: $pcg-dark-gray;
    }
  }
</style>
