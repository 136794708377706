<template>
  <pcg-box class="list-of-students">
    <abk-loader v-if="showLoader"/>
    <template v-else-if="listOfStudents.length > 0">
      <div v-for="(record, index) in listOfStudents" :key="`record-${index}`"
           class="oneline d-flex justify-content-between">
        <div class="one-row d-flex">
          <pcg-avatar size="extra-small" class="mr-3" :img="getAvatar(record.avatar)"/>
          <div>
            <div>
              <span class="name" :style="[ record.avatar ? { 'font-weight': 'bold' } : { 'font-weight': '400' } ]">
                {{ record.name }}
              </span>
              <span class="information" v-if="record.date">
                <label v-if="dateLabelText">{{ dateLabelText }}</label>
                {{ record.date }}
              </span>
              <span class="information" v-if="record.avatar">
                {{ record.college }}
              </span>
              <i v-for="attachment in record.attachments" v-b-tooltip.hover :title="attachment.filename"
                 :key="attachment.id"
                 class="pcg-icon-clip pcg-sm attachment-icon"
                 @click="downloadAttachment(attachment)"></i>
            </div>
          </div>
        </div>
        <div class="one-row d-flex" v-if="showActions">
          <pcg-btn v-if="record.numberOfNotes > 0" size="small" variant="additional" @click="$router.push({ name: 'student_notes_path', params: { id: record.id } })">
            {{ $t('views.students.actions.notes') }} ({{ record.numberOfNotes }})
          </pcg-btn>
          <pcg-btn v-else size="small" @click="$router.push({ name: 'student_notes_path', params: { id: record.id } })">
            {{ $t('views.students.actions.notes') }}
          </pcg-btn>
          <pcg-btn size="small" @click="$router.push({ name: 'curriculum_vitae_show_path', params: { id: record.cvId } })">
            {{ $t('views.students.actions.cv') }}
          </pcg-btn>
<!--          <pcg-btn size="small" @click="$router.push({ name: 'student_curriculum_vitae_edit_path', params: { id: record.cvId } })">-->
<!--            {{ $t('views.students.actions.edit_cv') }}-->
<!--          </pcg-btn>-->
          <pcg-btn size="small" @click="$router.push({ name: 'student_events_list_all_tab_path', params: { student_id: record.id } })">
            {{ $t('views.students.actions.events') }}
          </pcg-btn>
          <pcg-btn size="small" @click="$router.push({ name: 'student_applications_offers_path', params: { student_id: record.id } })">
            {{ $t('views.students.actions.offers') }}
          </pcg-btn>
        </div>
        <slot v-bind="record"></slot>
      </div>
    </template>
    <span v-else class="pcg-no-records">{{ noRecordsText }}</span>
  </pcg-box>

</template>

<script>
import ApiOfferApplicationAttachments from '../../api/offer_application_attachments'
import { saveAs } from 'file-saver'
import AbkLoader from '../AbkLoader'

export default {
  name: 'abk-list-of-students',
  components: { AbkLoader },
  props: {
    listOfStudents: {
      type: Array,
      default: () => []
    },
    showActions: {
      type: Boolean,
      default: false
    },
    linkPath: {
      type: String,
      default: 'curriculum_vitae_show_path'
    },
    noRecordsText: String,
    dateLabelText: String,
    showLoader: Boolean
  },
  methods: {
    getAvatar (avatar) {
      return avatar && avatar.url ? avatar.url : require('../../assets/images/avatar-placeholder.png')
    },
    downloadAttachment ({ id, filename }) {
      ApiOfferApplicationAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .oneline {
    padding-bottom: 10px;
    flex-wrap: wrap;
    &:not(:first-child) {
      padding-top: 10px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  .name {
    color: $main-color;
  }
  .one-row {
    align-items: center;
    flex-wrap: wrap;
  }
  .information {
    color: $pcg-dark-gray;
    margin-left: 15px;
  }
  .firm {
    color: $main-color;
    font-weight: bold;
    margin-left: 15px;
  }
  .reject {
    color: $main-active-color;
    margin-left: 5px;
    font-weight: 400;
    &:hover {
      cursor: pointer;
    }
  }
  .attachment-icon {
    margin-left: $pcg-m-sm;
    cursor: pointer;
    color: $pcg-gray;
  }
  .offer-type {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-gray;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .oneline {
       &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
    }
    .name {
      color: $main-color;
    }
    .information {
      color: $pcg-dark-gray;
    }
    .firm {
      color: $main-color;
    }
    .reject {
      color: $main-active-color;
    }
    .attachment-icon {
      color: $pcg-gray;
    }
    .offer-type {
      color: $pcg-gray;
    }
  }
</style>
