<template>
  <b-modal v-model="model" class="requirement-modal" footer-class="d-flex justify-content-between abk-modal-footer" centered hide-header
           content-class="abk-modal-content">
    <h2 class="title">{{ $t('components.surveys.abk_requirement_modal.title') }}</h2>
    <div class="row mt-5">
      <div class="form-group">
        <pcg-checkbox v-for="questionField in questionFields"
                      :key="questionField.tmpId"
                      class="col-12"
                      variant="normal"
                      :value="!!questionFieldIds.find(x => questionField.id === x || questionField.tmpId === x)"
                      @input="updateCheckbox(questionField, $event)">
          {{ questionField.label }}
        </pcg-checkbox>
        <div v-if="errorExist('questionFieldIds')" class="col-12 pcg-error-messages">
          {{ getError('questionFieldIds') }}
        </div>
      </div>

      <pcg-radio class="col-12"
                 :options="requirementTypeOptions"
                 v-model="requirementType"
                 name="requirement_type"/>

    </div>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn @click="cancel" variant="additional" class="mr-3">{{ $t('general.cancel') }}</pcg-btn>
      <pcg-btn @click="confirm" class="mr-3">{{ $t('general.confirm') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'AbkRequirementModal',
  props: {
    question: Object,
    value: {
      type: Boolean,
      default: false
    },
    errors: Object,
    requirement: Object
  },
  data () {
    return {
      rejectionReason: null,
      questionFieldIds: [],
      requirementType: 'any_selected',
      allRequirementTypeOptions: [
        {
          text: this.$t('components.surveys.abk_requirement_modal.requirementTypeOptions.any_selected'),
          value: 'any_selected'
        },
        {
          text: this.$t('components.surveys.abk_requirement_modal.requirementTypeOptions.all_selected'),
          value: 'all_selected'
        }
      ]
    }
  },
  watch: {
    value (newValue) {
      if (newValue) {
        this.clear()
        this.setRequirement()
      }
    }
  },
  computed: {
    requirementTypeOptions () {
      if (this.question.questionType === 'checkbox') {
        return this.allRequirementTypeOptions
      } else {
        return this.allRequirementTypeOptions.filter(x => x.value === 'any_selected')
      }
    },
    questionFields () {
      return this.question.questionFields.filter(questionField => {
        return (questionField.fieldType === 'checkbox' || questionField.fieldType === 'radiobutton') &&
            !questionField._destroy
      })
    },
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    errorPath () {
      return this.requirement ? `requirements.${this.requirement.tmpId}` : null
    }
  },
  mounted () {
    this.clear()
  },
  methods: {
    updateCheckbox (questionField, checked) {
      if (checked) {
        this.questionFieldIds.push(questionField.id || questionField.tmpId)
      } else {
        this.questionFieldIds = this.questionFieldIds.filter(x => x !== questionField.id && x !== questionField.tmpId)
      }
    },
    setRequirement () {
      if (this.requirement) {
        this.requirementType = this.requirement.requirementType
        this.questionFieldIds = this.requirement.questionFieldIds
      }
    },
    clear () {
      this.questionFieldIds = []
      this.requirementType = 'any_selected'
    },
    errorExist (field) {
      return !!(this.errors && this.errors[`${this.errorPath}.${field}`])
    },
    getError (field) {
      if (this.errorExist(field)) {
        this.$emit('open')
        return _.uniq(this.errors[`${this.errorPath}.${field}`]).join(', ')
      }
      return ''
    },
    cancel () {
      this.$emit('input', false)
    },
    confirm () {
      if (this.questionFields.length && !this.questionFieldIds.length) {
        this.$toastr.e(this.$t('components.surveys.abk_requirement_modal.not_selected'))
      } else {
        this.$emit('requirement', {
          requirementType: this.requirementType,
          questionFieldIds: this.questionFieldIds
        })
        this.$emit('input', false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/stylesheets/vars";

  /deep/ .pcg-radio-container {
    font-weight: bolder;
    &:not(:hover) {
      color: $main-color;
    }
  }

  /deep/ .modal-footer {
    padding-top: 17px;
    padding-bottom: 10px;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }

  .title {
    color: $pcg-dark-gray;
    margin-bottom: 10px;
  }

  .rejection-textarea /deep/ textarea {
    min-height: 150px;
  }
</style>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars-contrast";

  .contrast {
    ::v-deep .pcg-radio-container {
      &:not(:hover) {
        color: $main-color;
      }
    }
    .title {
      color: $pcg-dark-gray;
    }

    /deep/ .modal-content {
      background: $pcg-white;
    }
  }
</style>
