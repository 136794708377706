<template>
  <div>
    <pcg-btn size="small" @click="updateNews(false)" class="action-btn mr-3">{{ $t('views.news.news_edit.save') }}</pcg-btn>
    <pcg-btn size="small" @click="updateNews(false, true)" class="action-btn mr-3">{{ $t('views.news.news_edit.save_and_publish') }}</pcg-btn>
    <pcg-btn v-show="form.status === 'draft'" size="small" @click="updateNews(true)" variant="additional" class="action-btn">{{ $t('views.news.news_edit.save_as_draft') }}</pcg-btn>
    <abk-new-news-form class="form" v-model="form" :loading="loading" :submitted="submitted" :errors="errors"/>
    <pcg-btn size="small" @click="updateNews(false)" class="action-btn mr-3">{{ $t('views.news.news_edit.save') }}</pcg-btn>
    <pcg-btn size="small" @click="updateNews(false, true)" class="action-btn mr-3">{{ $t('views.news.news_edit.save_and_publish') }}</pcg-btn>
    <pcg-btn v-show="form.status === 'draft'" size="small" @click="updateNews(true)" variant="additional" class="action-btn">{{ $t('views.news.news_edit.save_as_draft') }}</pcg-btn>
  </div>
</template>

<script>
import ApiWebpages from '../../api/webpages'
import AbkNewNewsForm from '../../components/AbkNewNewsForm'

export default {
  name: 'NewsEdit',
  components: { AbkNewNewsForm },
  data () {
    return {
      id: this.$route.params.id,
      errors: {},
      loading: false,
      submitted: false,
      form: {
        roles: ['student', 'graduate', 'career_office', 'employer', 'recruiter', 'public'],
        navigation: null,
        address: null,
        title: null,
        content: null,
        sort: 0,
        metaDescription: null,
        keywords: null,
        status: null,
        webpageType: 'news',
        forum: null,
        webpageAttachments: []
      }
    }
  },
  mounted () {
    this.getNews()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.news.news_edit.title'),
        subtitle: this.$t('views.news.news_edit.subtitle')
      })
    },
    getNews () {
      ApiWebpages.getWebpage(this.id)
        .then(response => {
          this.form = response.data.data.attributes
          this.$store.dispatch('header/setObjectName', this.form.title)
        }).catch(error => {
          console.log(error)
        })
    },
    updateNews (draft, published = false) {
      this.loading = true
      this.submitted = false
      let webpage
      if (draft) {
        webpage = { ...this.form, status: 'draft' }
      } else if (published) {
        webpage = { ...this.form, status: 'published' }
      } else {
        webpage = { ...this.form }
      }

      ApiWebpages.updateWebpage(this.id, { webpage: webpage })
        .then(response => {
          this.errors = {}
          this.getNews()
          setTimeout(() => { this.$toastr.s(this.$t('views.news.news_edit.news_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
          this.submitted = true
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars";

.action-btn {
  padding-left: 0;
  padding-right: 0;
  width: 150px;
  margin: 0;
}
.form {
  margin-top: $pcg-m-xxl;
}
</style>
