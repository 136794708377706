<template>
  <div class="home">
    <div class="info-container">
      <div class="big-circle-container">
        <div class="big-circle circle">
          <img :src="require('../assets/images/login/czlowiek.jpg')" class="student-image"/>
          <h1 class="header">{{ $t('views.login.header') }}</h1>
          <div class="text" v-html="instance.mainText"></div>
        </div>
      </div>
      <div class="info">
        <div class="side-info info-content offers-count-info">
          <div class="icon icon-bookmark"></div>
          <span class="number">{{ offersCount }}</span>
          <span class="info-title">{{ $tc('views.login.offers_title', offersCount) }}</span>
        </div>
        <div class="small-circle-container companies-count-info">
          <div class="small-circle circle info-content">
            <div class="icon icon-buildings"></div>
            <span class="number">{{ companiesCount }}</span>
            <span class="info-title">{{ $tc('views.login.companies_title', companiesCount) }}</span>
            <span class="text" v-html="instance.companiesText"></span>
          </div>
        </div>
        <div class="side-info info-content students-count-info">
          <div class="icon icon-student"></div>
          <span class="number">{{ studentsAndGraduatesCount }}</span>
          <span class="info-title">
            {{ $tc('views.login.students_and_graduates_title', studentsAndGraduatesCount) }}
          </span>
        </div>
      </div>
    </div>
    <b-modal ref="acceptancesModal"
             centered hide-header
             v-bind:ok-title="$t('general.save_and_login')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="submitAcceptances"
             @cancel="logout"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             footer-class="abk-modal-footer"
             content-class="abk-modal-content"
    >
      <h2 class="missing-approvals">{{ $t('views.edit_company_profile.approvals') }}</h2>
      <div class="missing-approvals-text">{{ $t('general.missing_approvals') }}</div>
      <h3 v-if="currentAcceptances.cv && currentAcceptances.cv.length > 0">{{ $t('general.student_graduate') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
             v-for="(acceptance, index) in currentAcceptances.cv"
             :key="`cv-approval-${index}`">
        <pcg-checkbox variant="normal"
                      class="col-12"
                      :required="acceptance.required"
                      :show-error="errorExist(`acceptances.${index}.value`)"
                      :error-text="getError(`acceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </b-row>
      <h3 v-if="currentAcceptances.company && currentAcceptances.company.length > 0">{{ $t('general.employer_recruiter') }}</h3>
      <b-row class="d-flex justify-content-center mb-3"
             v-for="(acceptance, index) in currentAcceptances.company"
             :key="`company-approval-${index}`">
        <pcg-checkbox variant="normal"
                      class="col-12"
                      :required="acceptance.required"
                      :show-error="errorExist(`acceptances.${index}.value`)"
                      :error-text="getError(`acceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import WindowResizeMixin from '../mixins/window_resize'
import CompanyPopupMixin from '../mixins/company_popup'
import CvPopupMixin from '../mixins/cv_popup'
import ApiDictionaryAcceptances from '../api/dictionary_acceptances'
import ApiStatistics from '../api/statistics'
import ApiInstances from '../api/instances'
import ApiUsers from '../api/users'
import { mapGetters } from 'vuex'

export default {
  name: 'login',
  mixins: [WindowResizeMixin, CompanyPopupMixin, CvPopupMixin],
  data: function () {
    return {
      offersCount: 0,
      companiesCount: 0,
      studentsAndGraduatesCount: 0,
      cvAcceptances: [],
      companyAcceptances: [],
      currentAcceptances: [],
      errors: [],
      instance: {
        companiesText: null,
        footerLogo: null,
        footerLogoNegative: null,
        footerBottomText: null,
        loginFooter: null,
        loginLogo: null,
        loginLogoNegative: null,
        footerUpperText: null,
        mainText: null,
        menuLogo: null,
        menuLogoNegative: null
      }
    }
  },
  created () {
    this.loginCallback()
    this.getBasicInfo()
    ApiInstances.getInstance()
      .then(response => {
        this.instance = response.data.data.attributes
      })
  },
  watch: {
    windowWidth (newValue) {
      this.setBackgroundImageStyle()
    },
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapGetters('auth', ['currentRole', 'roles']),
    ...mapGetters('environment', ['env']),
    cvAcceptancesToSend () {
      return this.currentAcceptances.cv.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    },
    companyAcceptancesToSend () {
      return this.currentAcceptances.company.map(acceptance => ({
        dictionaryAcceptanceWordId: acceptance.dictionaryAcceptanceWordId,
        value: acceptance.value,
        required: acceptance.required
      }))
    }
  },
  methods: {
    logout () {
      const role = this.currentRole
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${this.env.abkOpEndSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${this.env.abkOpEndSsessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    async checkCurrentUserAcceptances () {
      let valid = false
      try {
        const response = await ApiUsers.checkAcceptances()
        valid = response.data.cvAcceptance && response.data.companyAcceptance
        if (!valid) {
          const userRoles = this.$store.getters['auth/roles']
          if (userRoles.includes('student') || userRoles.includes('graduate')) {
            response.data.currentCvAcceptances.forEach(acc => {
              const idx = this.cvAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.cvAcceptances[idx].value = acc.value
                this.cvAcceptances[idx].type = 'cv'
              }
            })
          }
          if (userRoles.includes('employer') || userRoles.includes('recruiter')) {
            response.data.currentCompanyAcceptances.forEach(acc => {
              const idx = this.companyAcceptances.findIndex(acceptance => parseInt(acceptance.dictionaryAcceptanceWordId) === parseInt(acc.dictionaryAcceptanceWordId))
              if (idx >= 0) {
                this.companyAcceptances[idx].value = acc.value
                this.companyAcceptances[idx].type = 'company'
              }
            })
          }
        }
        return valid
      } catch (err) {
        return false
      }
    },
    submitAcceptances (e) {
      e.preventDefault()
      ApiUsers.fillAcceptances({ user: { cvAcceptances: this.cvAcceptancesToSend, companyAcceptances: this.companyAcceptancesToSend } })
        .then(response => {
          const userRole = this.$store.getters['auth/currentRole']
          if (['student', 'graduate'].includes(userRole)) {
            this.$router.push({ name: 'student_dashboard_path' })
          } else if (userRole === 'employer' || userRole === 'recruiter') {
            this.$router.push({ name: 'employer_dashboard_path' })
          } else if (userRole === 'career_office') {
            this.$router.push({ name: 'career_office_dashboard_path' })
          } else {
            this.$router.push({ name: 'root_path' })
          }
          this.checkCompany()
          this.checkCv()
        })
        .catch(error => {
          this.errors = error.response.data
          this.$toastr.e(this.$t('general.fields_not_filled'))
        })
    },
    getBasicInfo () {
      ApiStatistics.getBasicInfo()
        .then(response => {
          const { acceptedCompaniesTotalCount, acceptedOffersTotalCount, studentsOrGraduatesTotalCount } = response.data
          this.offersCount = acceptedOffersTotalCount
          this.companiesCount = acceptedCompaniesTotalCount
          this.studentsAndGraduatesCount = studentsOrGraduatesTotalCount
        })
    },
    login () {
      window.location = '/users/auth/openid_connect'
    },
    loginCallback () {
      if (this.$route.query.code) {
        ApiDictionaryAcceptances.getDictionaryAcceptanceAllWords()
          .then(res => {
            this.cvAcceptances = []
            this.companyAcceptances = []
            res.data.data.forEach((dw) => {
              if (dw.attributes.registration) {
                const a = {
                  key: dw.attributes.type,
                  dictionaryAcceptanceWordId: dw.id,
                  required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                  text: dw.attributes.value,
                  value: false,
                  name: dw.attributes.name,
                  wordType: dw.attributes.wordType
                }
                const b = {
                  key: dw.attributes.type,
                  dictionaryAcceptanceWordId: dw.id,
                  required: dw.attributes.registrationRequired || dw.attributes.status === 'required',
                  text: dw.attributes.value,
                  value: false,
                  name: dw.attributes.name,
                  wordType: dw.attributes.wordType
                }
                this.cvAcceptances.push(a)
                this.companyAcceptances.push(b)
              }
            })
            let sortByName = []
            sortByName.push(...this.cvAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
            this.cvAcceptances = sortByName
            sortByName = []
            sortByName.push(...this.companyAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
            this.companyAcceptances = sortByName
            this.setLogin()
          })
          .catch(err => {
            this.$toastr.e(err)
            console.log(err)
          })
      } else {
        this.hideAppLoader()
      }
    },
    setLogin () {
      const query = { ...this.$route.query }
      this.$router.replace({ query: {} })
      this.$store.dispatch('auth/signIn', query)
        .then(response => {
          this.checkCurrentUserAcceptances()
            .then(result => {
              if (result) {
                const redirectTo = this.$store.getters['auth/getRedirectTo']
                if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
                  this.$router.push({ name: 'role_choose_path' })
                } else if (redirectTo && redirectTo.name) {
                  this.$store.dispatch('auth/clearRedirectTo')
                  this.$router.push(redirectTo)
                  this.checkCompany()
                  this.checkCv()
                } else {
                  const userRole = this.$store.getters['auth/currentRole']
                  if (['student', 'graduate'].includes(userRole)) {
                    this.$router.push({ name: 'student_dashboard_path' })
                  } else if (userRole === 'employer' || userRole === 'recruiter') {
                    this.$router.push({ name: 'employer_dashboard_path' })
                  } else if (userRole === 'career_office') {
                    this.$router.push({ name: 'career_office_dashboard_path' })
                  } else {
                    this.$router.push({ name: 'root_path' })
                  }
                  this.checkCompany()
                  this.checkCv()
                }
              } else {
                let result = []
                const userRoles = this.$store.getters['auth/roles']
                if (userRoles.includes('employer') || userRoles.includes('recruiter')) {
                  result.push(this.companyAcceptances.filter(a => !a.value && (a.key === 'employer' || a.key === 'cooperation' || a.key === 'survey')))
                }
                if (userRoles.includes('student') || userRoles.includes('graduate')) {
                  result.push(this.cvAcceptances.filter(a => !a.value && (a.key === 'cv' || a.key === 'survey')))
                }
                result = result.flat()
                this.currentAcceptances = result
                const sortByName = []
                sortByName.push(...this.currentAcceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
                this.currentAcceptances = sortByName
                this.currentAcceptances = {
                  cv: this.currentAcceptances.filter(acc => acc.type === 'cv'),
                  company: this.currentAcceptances.filter(acc => acc.type === 'company')
                }
                this.$refs.acceptancesModal.show()
              }
            })
        })
        .catch(error => {
          console.log(error.response.data)
          this.$toastr.e(error.response.data)
        })
        .finally(() => {
          this.hideAppLoader()
        })
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/blank.png'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    },
    hideAppLoader () {
      setTimeout(() => {
        document.getElementById('app-loader').style.display = 'none'
      }, 2000)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  /deep/ .pcg-btn-sidebar-menu {
    display: none !important;
  }
  .info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .home {
    text-align: center;
    position: relative;
    color: $pcg-the-darkest-gray;
    transform: translateY(-20px);
  }
  .big-circle-container {
    position: relative;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    &:after {
      content: $pcg-icon-bullet;
      position: absolute;
      bottom: -31px;
      left: 0;
      right: 0;
      color: $pcg-pink-color;
      font-size: 40px;
    }
  }
  .student-image {
    width: 100px;
    margin-bottom: $pcg-m-xxl;
  }
  .small-circle-container {
    width: 390px;
  }
  .circle {
    position: relative;
    width: 100%;
    height: 0;
    padding: 12% 0 88%;
    border-radius: 50%;
    border: 1px solid $pcg-medium-lightest-gray;
  }
  .small-circle {
    border-color: $pcg-light-pink-color;
  }
  .text {
    line-height: 1.3;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }
  .big-circle {
    text-align: center;
    .text {
      font-size: $font-size-m;
      max-width: 300px;
    }
  }
  .header {
    margin-bottom: $pcg-m-xl;
  }
  .header, .info-title, .number {
    font-weight: 900;
  }
  .info {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    align-items: center;
    bottom: -255px;
    width: 100%;
    max-width: 800px;
    left: 0;
    right: 0;
  }
  .side-info {
    margin-top: 5px;
    width: 165px;
    height: 300px;
  }
  .info-content {
    display: flex;
    flex-direction: column;
    .text {
      width: 145px;
      font-size: $font-size-s;
    }
  }
  .icon {
    min-height: 30px;
    margin-bottom: $pcg-m-sm;
    background-color: $main-active-color;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
  .icon-student {
    mask-image: url('../assets/images/icons/student-blue.svg');
  }
  .icon-buildings {
    mask-image: url('../assets/images/icons/buildings-blue.svg');
  }
  .icon-bookmark {
    mask-image: url('../assets/images/icons/bookmark-blue.svg');
  }
  .number {
    font-size: $font-size-xxl;
    color: $main-active-color;
  }
  .number, .icon {
    margin-bottom: 10px;
  }
  .info-title {
    font-size: $font-size-xl;
    margin-bottom: $pcg-m-md;
  }
  @media (max-width: 1040px) {
    .info {
      flex-direction: column;
      justify-content: flex-start;
      position: static;
    }
    .info-content {
      height: fit-content;
    }
    .small-circle {
      padding: 85px;
      margin-left: auto;
      margin-right: auto;
    }
    .offers-count-info {
      margin-top: 30px;
      order: 2;
    }
    .companies-count-info {
      order: 1
    }
    .students-count-info {
      order: 3
    }
    .header {
      font-size: $font-size-xl;
    }
    .student-image {
      width: 90px;
      margin-bottom: $pcg-m-lg;
    }
    @-moz-document url-prefix() {
      .small-circle {
        padding: 82px !important;
      }
    }
  }
  @media (max-width: $screen-md-max) {
    .info-container {
      top: 60px;
    }
  }
  @media (max-width: $screen-xs-max) {
    .small-circle {
      width: 270px;
      padding: 30px;
    }
    .big-circle-container {
      max-width: 100%;
    }
    .info-title {
      margin-bottom: $pcg-m-xs;
    }
    .header {
      margin-bottom: $pcg-m-sm;
    }

    /* Firefox */
    @-moz-document url-prefix() {
      .small-circle {
        padding: 30px !important;
      }
    }
  }

  .missing-approvals {
    margin-bottom: 2rem;
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
  }

  .missing-approvals-text {
    margin-bottom: 2rem;
    font-weight: 400;
    color: $pcg-gray;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    .home {
      color: $pcg-the-darkest-gray;
    }
    .big-circle-container {
      &:after {
        color: $pcg-pink-color;
      }
    }
    .circle {
      border: 1px solid $pcg-light-gray;
    }
    .number {
      color: $main-active-color;
    }
    .icon {
      background-color: $main-active-color;
    }
    .missing-approvals {
      color: $pcg-gray;
    }
    .missing-approvals-text {
      color: $pcg-gray;
    }
  }
</style>
