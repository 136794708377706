<template>
  <div :class="{'abk-draft': isDraft(page) }" class="grid position-relative">
    <span v-if="page._vm.level > 1" class="child-icon">&#x2B91;</span>
    <span class="edit-page-link-container">
      <span class="edit-page-link">
        <router-link v-if="linksActive" :to="{ name: 'webpages_show_path', params: { id: page.address} }">
          {{ page.title }}
        </router-link>
        <span v-else>{{ page.title }}</span>
      </span>
    </span>
    <span>
      <pcg-switch class="mx-auto"
                  :disabled="isDraft(page)"
                  :value="page.status === 'published'"
                  @input="$emit('publishPage', { pageId: page.id, publish: $event })"/>
    </span>
    <span>
      <pcg-switch class="mx-auto"
                  :disabled="isDraft(page)"
                  :value="page.showInMenu === true"
                  @input="$emit('showInMenu', { pageId: page.id, showInMenu: $event })"/>
    </span>
    <span class="date">{{ formatDate(page.updatedAt) }}</span>
    <span class="date">{{ formatDate(page.createdAt) }}</span>
    <span class="actions">
      <button v-if="linksActive" class="delete-page-btn" @click="$router.push({ name: 'webpages_edit_path', params: { id: page.id } })">
        <i class="pcg-icon-edit"/>
      </button>
      <button v-if="linksActive" class="delete-page-btn" @click="$emit('deletePage', page.id)">
        <i class="pcg-icon-trash-can"/>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AbkWebpagesTreeNode',
  props: {
    page: Object,
    depth: Number,
    linksActive: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    formatDate (date) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
      }
      return '—'
    },
    isDraft (currentPage) {
      return currentPage.status === 'draft'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "app/javascript/abk/assets/stylesheets/vars";

  .delete-page-btn {
    background-color: transparent;
    border: none;
    outline: none;
    color: $pcg-dark-gray;
  }
  .edit-page-link-container {
    font-weight: 500;
    display: inline-block;
    white-space: nowrap;
    min-width: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left!important;
  }
  .edit-page-link {
    position: relative;
    &, a {
      color: $main-color;
    }
  }
  .abk-draft{
    opacity: 0.3;
  }
  .date {
    line-height: 1;
    color: $pcg-gray;
    font-weight: 500;
    padding: $pcg-m-sm $pcg-m-lg $pcg-m-sm 0;
    position: relative;
    bottom: .5rem;
  }
  .he-tree{
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
  }
  .tree-node-inner{
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  .child-icon {
    color: $pcg-dark-gray;
    position: absolute;
    top: -2px;
    left: -25px;
    font-weight: bold;
  }
  .actions {
    position: relative;
    bottom: .1rem;
  }
  @media(max-width: $screen-md-max) {
    .actions {
      position: relative;
      bottom: 0;
    }
  }
</style>

<style lang="scss">
  @import "app/javascript/abk/assets/stylesheets/vars-contrast";
  .contrast {
    .delete-page-btn {
      color: $pcg-dark-gray;
    }
    .edit-page-link {
      &, a {
        color: $main-color;
      }
    }
    .child-icon {
      color: $pcg-dark-gray;
    }
  }
</style>
