<template>
  <b-modal v-model="model" footer-class="d-flex justify-content-between abk-modal-footer"
           class="search-filter-modal"
           content-class="abk-modal-content"
           centered hide-header hide-footer>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-filters"></i>
      {{ $t('components.abk_search_filter_list.title') }}
    </div>
    <abk-search-filter v-for="(filterProperties, name) in filters"
                       :filters="filterProperties"
                       :name="name"
                       :path="path"
                       :dictionaries="dictionaries"
                       :key="`filter-${name}`">
    </abk-search-filter>
  </b-modal>
</template>

<script>
import AbkSearchFilter from '../AbkSearchFilter'

export default {
  name: 'AbkSearchFilterModal',
  components: { AbkSearchFilter },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {}
    },
    path: {
      type: String,
      required: true
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  computed: {
    model: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  /deep/ .modal-footer {
    padding-top: 17px;
    padding-bottom: 10px;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }
  /deep/ .modal .modal-dialog {
    margin-bottom: 80px;
  }
  .pcg-icon-filters {
    font-size: .9rem;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{

  }
</style>
