export default {
  data () {
    return {
      currentDictionaryId: null,
      showSuggestDictionaryWordModal: false
    }
  },
  watch: {
    showSuggestDictionaryWordModal (newValue) {
      if (!newValue) { this.currentDictionaryId = null }
    }
  },
  methods: {
    showModalSuggestion (dictionaryId) {
      this.currentDictionaryId = dictionaryId
      this.showSuggestDictionaryWordModal = true
    }
  }
}
