<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path :class="variant" d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm0 18a8 8 0 118-8 8 8 0 01-8 8zm0-13a5 5 0 11-5 5 5 5 0 015-5z" fill-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
  name: 'AbkSurveyRadiobuttonSvg',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (variant) {
        return ['primary', 'secondary'].includes(variant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars";

    .primary {
        fill: $main-color
    }
    .secondary {
        fill: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars-contrast";

    .contrast{
        .primary {
            fill: $main-color
        }
        .secondary {
            fill: $main-active-color;
        }
    }
</style>
