<template>
  <div class="news">
    <transition name="slide" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'news-view'
}
</script>

<style lang="scss" scoped>

</style>
