<template>
  <div v-if="question.questionType === 'section'" class="pcg-section-title">{{ question.title }}</div>
  <div v-else-if="question.questionType === 'separator'" class="pcg-separator"/>
  <div v-else>
    <div class="pcg-input-label">{{ question.title }}</div>
    <div v-if="answer" :class="{ 'horizontal-answers-container': question.displayType === 'horizontal' }">
      <div v-for="(answerValue, index) in answers" :key="`question-${question.id}-answer-${index}`" class="answer">
        {{ answerValue }}
      </div>
    </div>
    <div v-else class="no-answer-text">{{ $t('components.surveys.abk_answer.no_answer') }}</div>
  </div>
</template>

<script>
export default {
  name: 'AbkAnswer',
  props: {
    question: Object,
    answer: Object
  },
  computed: {
    answers () {
      return ['text', 'number'].includes(this.question.questionType)
        ? this.answer.answerValues
        : this.answer.answerLabels
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .horizontal-answers-container {
    display: flex;
    flex-wrap: wrap;
  }
  .answer {
    margin-right: $pcg-m-md;
    color: $pcg-the-darkest-gray;
  }
  .no-answer-text {
    color: $pcg-light-gray;
  }
</style>
