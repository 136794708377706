<template>
  <pcg-box>
    <b-row>
      <b-col class="message-content d-flex flex-column">
        <div class="message-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <i v-if="message.messageType === 'communique'" class="pcg-icon-envelope-alt message-type-icon"/>
            <i v-else-if="message.messageType === 'sms'" class="pcg-icon-comment-dots message-type-icon"/>
            <i v-else-if="message.messageType === 'newsletter'" class="pcg-icon-comment-info-alt message-type-icon"/>
            <div>
              <template v-if="!isNil(message.recipientGroupId)">
                <span :id="message.id" class="recipient-group-name">
                  {{ message.recipientGroupName }}
                </span>
                <b-tooltip :target="message.id" triggers="hover">
                  <div v-for="property in recipientGroupProperties" :key="property">{{ property }}</div>
                  {{ $tc('components.abk_message_secondary.sent_to_users', message.recipientsCount) }}
                </b-tooltip>
              </template>
              <span class="message-type">{{ $t(`general.message_type.${message.messageType}`) }}</span>
            </div>
          </div>
          <div class="d-flex align-items-baseline">
            <span class="message-date">{{ formatDate(message.sentAt) }}</span>
            <router-link v-if="allowCopy"
                         :to="{ name: 'messages_copy_path', params: { id: message.id } }"
                         class="action-btn">
              <i class="pcg-icon-duplicate action-icon" v-b-tooltip :title="$t('general.make_copy')"/>
            </router-link>
            <router-link v-if="allowEdit"
                         :to="{ name: 'messages_edit_path', params: { id: message.id } }"
                         class="action-btn">
              <i class="pcg-icon-edit action-icon" v-b-tooltip :title="$t('general.edit')"/>
            </router-link>
            <span v-if="allowDelete"
                  class="delete-btn action-btn"
                  v-b-tooltip
                  :title="$t('general.delete_message')"
                  @click="deleteMessage">
              ✕
            </span>
          </div>
        </div>
        <div ref="messageText" class="message-text" :class="{ unread: false, collapsed: !show && showExpander }">
          <div class="message-title" v-if="message.title">{{ message.title }}</div>
          <div ref="messageTextDiv" v-html="message.content"></div>
          <br/>
          <abk-attachment v-for="attachment in message.attachments"
                          :key="attachment.id"
                          class="message-attachment"
                          @click="downloadAttachment(attachment)">
            {{ attachment.filename }}
          </abk-attachment>
          <div class="expander-btn" :class="{ collapsed: !show }" @click="toggleShow" v-if="showExpander">
            {{ show ? $t('general.collapse') : $t('general.expand') }}
          </div>
        </div>
        <div class="d-flex flex-wrap mt-auto">
        </div>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import WindowResizeMixin from '../mixins/window_resize'
import AbkAttachment from './offers/AbkAttachment'
import ApiMessageAttachments from '../api/message_attachments'
import { saveAs } from 'file-saver'
import ApiMessages from '../api/messages'

export default {
  name: 'AbkMessageSecondary',
  props: {
    message: Object,
    recipientGroupProperties: Array,
    allowCopy: Boolean,
    allowEdit: Boolean,
    allowDelete: Boolean
  },
  mixins: [WindowResizeMixin],
  components: { AbkAttachment },
  data: function () {
    return {
      messageTextDivHeight: 0,
      show: false
    }
  },
  mounted () {
    this.updateMessageTextDivHeight()
  },
  watch: {
    windowWidth () {
      this.updateMessageTextDivHeight()
    },
    message () {
      this.updateMessageTextDivHeight()
    }
  },
  computed: {
    showExpander () {
      return this.messageTextDivHeight > 112
    }
  },
  methods: {
    isNil (value) {
      return _.isNil(value)
    },
    formatDate (date) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.calendar() : null
    },
    updateMessageTextDivHeight () {
      this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      setTimeout(() => {
        this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      }, 500)
    },
    downloadAttachment ({ filename, id }) {
      ApiMessageAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('components.abk_message_secondary.attachment_download_error'))
        })
    },
    toggleShow () {
      this.show = !this.show
      const element = this.$refs.messageText
      if (this.show) {
        const sectionHeight = element.scrollHeight
        element.style.height = sectionHeight + 'px'
        const handler = function (e) {
          element.removeEventListener('transitionend', handler)
          element.style.height = null
        }
        element.addEventListener('transitionend', handler)
      } else {
        const sectionHeight = element.scrollHeight
        const elementTransition = element.style.transition
        element.style.transition = ''
        requestAnimationFrame(function () {
          element.style.height = sectionHeight + 'px'
          element.style.transition = elementTransition
          requestAnimationFrame(function () {
            element.style.height = 111 + 'px'
          })
        })
      }
    },
    deleteMessage () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiMessages.deleteMessage(this.message.id)
            .then(() => {
              this.$emit('deleted')
              this.$toastr.s(this.$t('components.abk_message_secondary.message_deleted'))
            })
            .catch(error => {
              console.error(error)
              this.$toastr.e(this.$t('components.abk_message_secondary.delete_message_error'))
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .recipient-group-tooltip-link {
    cursor: pointer;
  }
  /deep/ .box {
    padding-top: $font-size-m !important;
    padding-bottom: $font-size-m !important;
    position: relative;
    overflow: hidden;
  }
  .message-content {
    min-width: 170px;
  }
  .message-date {
    color: $pcg-light-gray;
    font-weight: 400;
    white-space: nowrap;
  }
  .dots, .message-text {
    line-height: 1.2;
    font-weight: 400;
    color: $pcg-dark-gray;
  }
  .dots {
    margin-right: 7px;
  }
  .message-text {
    line-height: 1.2;
    font-weight: 400;
    color: $pcg-dark-gray;
    /deep/ a {
      color: $main-active-color;
    }
    position: relative;
    margin-bottom: 10px;
    transition: height 0.3s ease-out;
    height: auto;
    &.unread {
      font-weight: bold;
    }
    &.collapsed {
      overflow: hidden;
      height: 111px;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top, $pcg-white -4%, rgba(255, 255, 255, 0) 90%);
        pointer-events: none;
      }
      &.unread {
        &:before {
          background: linear-gradient(to top, rgba(255, 255, 255, 1) -4%, rgba(255, 255, 255, 0) 90%);
        }
      }
    }
  }
  .expander-btn {
    text-align: center;
    color: $main-color;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    &.collapsed {
      bottom: 2px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .message-title {
    font-size: $font-size-m;
    color: $main-color;
    font-weight: bold;
    margin-bottom: $pcg-m-sm;
  }
  i {
    font-size: $font-size-l;
  }
  .action-icon {
  }
  .action-btn {
    color: $pcg-the-darkest-gray;
    margin-left: $pcg-m-md;
  }
  .message-type-icon {
    color: $main-color;
    margin-right: $pcg-m-md;
  }
  .recipient-group-name {
    cursor: default;
    color: $main-color;
    font-weight: 500;
    margin-right: $pcg-m-md;
  }
  .message-type {
    color: $pcg-gray;
    font-weight: 500;
  }
  .message-header {
    margin-bottom: $pcg-m-md;
  }
  .message-attachment {
    margin-right: $pcg-m-lg;
  }
  .delete-btn {
    cursor: pointer;
  }

  @media(max-width: $screen-lg-max) {
    .message-footer {
      text-align: center;
    }
  }
  @media(max-width: 423px) {
    .message-date {
      text-align: right;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .message-date {
      color: $pcg-light-gray;
    }
    .dots, .message-text {
      color: $pcg-dark-gray;
    }
    .message-text {
      color: $pcg-dark-gray;
      /deep/ a {
        color: $main-active-color;
      }
      &.collapsed {
        &:before {
          background: linear-gradient(to top, $pcg-white -4%, rgba(255, 255, 255, 0) 90%);
        }
        &.unread {
          &:before {
            background: linear-gradient(to top, rgba(255, 255, 255, 1) -4%, rgba(255, 255, 255, 0) 90%);
          }
        }
      }
    }
    .expander-btn {
      color: $main-color;
    }
    .message-title {
      color: $main-color;
    }
    .action-btn {
      color: $pcg-the-darkest-gray;
    }
    .message-type-icon {
      color: $main-color;
    }
    .recipient-group-name {
      color: $main-color;
    }
    .message-type {
      color: $pcg-gray;
    }
  }
</style>
