import ApiSimpleMessages from '../../api/simple_messages'
import ApiOfferInvitations from '../../api/offer_invitations'
import ApiOfferApplications from '../../api/offer_applications'
import ApiUserMessages from '../../api/user_messages'
import ApiOffers from '../../api/offers'
import ApiCompanies from '../../api/companies'
import ApiEvents from '../../api/events'
import ApiDictionaries from '../../api/dictionaries'
import ApiDictionaryWords from '../../api/dictionary_words'
import axios from 'axios'

const notifications = {
  namespaced: true,
  state: {
    messages: {},
    notifications: {}
  },
  getters: {
    messages: state => state.messages,
    notifications: state => state.notifications
  },
  mutations: {
    setMessages (state, result) {
      state.messages = result
    },
    setNotifications (state, result) {
      state.notifications = result
    }
  },
  actions: {
    getAllNotifications ({ dispatch }) {
      dispatch('getNotifications')
      dispatch('getMessages')
    },
    getMessages ({ commit, rootGetters }) {
      const role = rootGetters['auth/currentRole']
      let actions
      if (role === 'career_office') {
        actions = [ApiSimpleMessages.getNotifications()]
      } else if (['student', 'graduate', 'employer', 'recruiter'].includes(role)) {
        actions = [ApiSimpleMessages.getNotifications(), ApiUserMessages.getCommunique()]
      }
      if (!actions) { return }

      axios.all(actions)
        .then(responses => {
          const messages = responses.reduce((messagesAcc, response) => {
            messagesAcc.data.push(
              ...response.data.data.map(message => {
                return {
                  id: message.id,
                  type: message.type,
                  ...message.attributes
                }
              })
            )
            messagesAcc.unreadCount += response.data.meta.unreadCount
            return messagesAcc
          }, { data: [], unreadCount: 0 })
          messages.data = messages.data.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 6)
          commit('setMessages', messages)
        }).catch(error => {
          console.error(error)
        })
    },
    getNotifications ({ commit, rootGetters }) {
      const role = rootGetters['auth/currentRole']
      let actions
      if (role === 'employer' || role === 'recruiter') {
        actions = [ApiOfferApplications.getNotifications(), ApiUserMessages.getNotifications()]
      } else if (['student', 'graduate'].includes(role)) {
        actions = [ApiOfferInvitations.getNotifications(), ApiUserMessages.getNotifications()]
      } else if (role === 'career_office') {
        actions = [
          ApiOffers.getNotifications(),
          ApiCompanies.getNotifications(),
          ApiEvents.getNotifications(),
          ApiDictionaries.getNotifications(),
          ApiDictionaryWords.getNotifications(),
          ApiUserMessages.getNotifications()
        ]
      }
      if (!actions) { return }

      axios.all(actions)
        .then(responses => {
          const notifications = responses.reduce((notificationsAcc, response) => {
            notificationsAcc.data.push(
              ...response.data.data.map(notification => {
                return {
                  id: notification.id,
                  type: notification.type,
                  ...notification.attributes
                }
              })
            )
            notificationsAcc.unreadCount += response.data.meta.unreadCount
            return notificationsAcc
          }, { data: [], unreadCount: 0 })
          notifications.data = notifications.data.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 6)
          commit('setNotifications', notifications)
        }).catch(error => {
          console.error(error)
        })
    }
  }
}

export { notifications }
