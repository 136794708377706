<template>
  <div>
    <div :class="{'horizontal-checkbox-container': question.displayType === 'horizontal'}">
      <pcg-checkbox v-for="(questionField, index) in sortedCheckboxQuestionFields"
                    :key="`checkbox-question-field-${question.id}-${index}-${updateKey}`"
                    :name="`checkbox-question-${question.id}`"
                    :input-value="questionField.value"
                    :value="!!value[questionField.id]"
                    @input="handleInput($event ? questionField.value : null, questionField.id)">
        <span class="text-nowrap">{{ questionField.label }}</span>
      </pcg-checkbox>
      <pcg-checkbox v-if="textQuestionField"
                    :value="showTextQuestionField"
                    @input="$emit('update:showTextQuestionField', $event)">
        {{ textQuestionField.label }}
      </pcg-checkbox>
    </div>
    <b-collapse v-if="textQuestionField" :visible="showTextQuestionField" :id="String(textQuestionField.id)">
      <pcg-text-input :key="`checkbox-question-field-${textQuestionField.id}`"
                      :show-label="false"
                      @input="handleInput($event, textQuestionField.id)"/>
    </b-collapse>
    <div v-show="showError" class="pcg-error-messages">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  name: 'AbkCheckboxQuestion',
  props: {
    question: Object,
    value: {
      type: Object,
      default: () => { return {} }
    },
    showError: Boolean,
    errorText: String,
    showTextQuestionField: Boolean
  },
  data () {
    return {
      selectedValues: {},
      updateKey: 0
    }
  },
  watch: {
    value () {
      ++this.updateKey
    },
    showTextQuestionField (newValue) {
      if (!newValue) {
        this.handleInput(null, this.textQuestionField.id)
      }
    }
  },
  methods: {
    handleInput (event, questionFieldId) {
      typeof event === 'string' && event.length > 0
        ? this.value[questionFieldId] = event
        : delete this.value[questionFieldId]
      this.$emit('input', this.value)
    }
  },
  computed: {
    sortedCheckboxQuestionFields () {
      return _.orderBy(this.question.questionFields.filter(field => field.fieldType === 'checkbox'), 'sort', 'asc')
    },
    textQuestionField () {
      return this.question.questionFields.find(field => field.fieldType === 'text')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .horizontal-checkbox-container {
    display: flex;
    flex-wrap: wrap;
  }
</style>
