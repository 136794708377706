<template>
  <div>
    <abk-list-of-employers :list-of-employers="applications"
                           :linkPath="'offers_show_path'"
                           :date-label-text="`${ $t('views.offer_applications.applications_offers.cv_sent') }: `"
                           :no-records-text="$t('views.offer_applications.applications_offers.no_records')"
                           :show-loader="showLoader"/>
  </div>
</template>

<script>
import AbkListOfEmployers from '../../components/AbkListOfEmployers'
import ApiOfferApplication from '../../api/offer_applications'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'applications-offers',
  components: { AbkListOfEmployers },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      applications: [],
      studentId: null,
      studentName: null
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offer_applications.applications_offers.title')
      })
    },
    getOfferApplications () {
      ApiOfferApplication.getApplicationsOffers(this.studentId)
        .then(response => {
          this.setApplications(response.data.data)
          if (response.data.meta && response.data.meta.user) {
            this.studentName = response.data.meta.user.name
          }
          if (this.studentId) {
            this.$store.dispatch('header/setHeader', {
              title: `${this.studentName}: ${this.$t('views.offer_applications.applications_offers.title')}`
            })
          }
        })
        .catch(error => {
          this.$toastr.e(this.$t('views.offer_applications.applications_offers.applications_fetching_error'))
          console.error(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    setApplications (applicationOffers) {
      this.applications = applicationOffers.map(applicationOffer => {
        return {
          company: {
            id: applicationOffer.attributes.companyId,
            name: applicationOffer.attributes.companyName,
            employerType: applicationOffer.attributes.employerType,
            employerTypeText: applicationOffer.attributes.employerTypeText,
            employerTypeLogo: applicationOffer.attributes.employerTypeLogo,
            employerTypeLogoNegative: applicationOffer.attributes.employerTypeLogoNegative
          },
          offerType: applicationOffer.attributes.offerType,
          user_id: applicationOffer.relationships.offer.data.id,
          avatar: applicationOffer.attributes.companyLogo,
          date: this.formattedDate(applicationOffer.attributes.createdAt),
          name: applicationOffer.attributes.offerJobTitle,
          attachments: applicationOffer.attributes.offerApplicationAttachments
        }
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    }
  },
  mounted () {
    this.studentId = this.$route.params.student_id
    this.getOfferApplications()
    this.$store.dispatch('user/getCatalogOfferCounters')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
