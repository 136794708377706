<template>
  <div>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">

          <pcg-text-input class="abk-input col-12"
                          :label="$t('views.dictionary_words.labels.value')"
                          :required="true"
                          :error-text="getError('valuePl')"
                          :show-error="errorExist('valuePl')"
                          v-model="dictionaryWord.valuePl"/>
          <pcg-text-input class="abk-input col-12"
                          :label="$t('views.dictionary_words.labels.value_en')"
                          :error-text="getError('valueEn')"
                          :show-error="errorExist('valueEn')"
                          v-model="dictionaryWord.valueEn"/>
          <pcg-checkbox class="col-12"
                        :error-text="getError('status')"
                        :show-error="errorExist('status')"
                        v-model="dictionaryWord.statusBoolean">
            {{$t('views.dictionary_words.statuses.accepted')}}
          </pcg-checkbox>

          <pcg-btn v-if="dictionaryWord" size="small" @click="updateDictionaryWord()" variant="additional" class="action-btn">
            {{ $t('views.common.save') }}
          </pcg-btn>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'
export default {
  name: 'DictionaryWordsEdit',
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      id: this.$route.params.id,
      errors: {},
      dictionaryWord: {
        valuePl: null,
        valueEn: null,
        status: null,
        statusBoolean: null
      }
    }
  },
  mounted () {
    this.getDictionaryWord()
  },
  methods: {
    getDictionaryWord () {
      ApiDictionaryWords.getDictionaryWord(this.dictionaryId, this.id)
        .then(response => {
          this.dictionaryWord = response.data.data.attributes
          if (this.dictionaryWord.status === 'accepted') {
            this.dictionaryWord.statusBoolean = true
          } else {
            this.dictionaryWord.statusBoolean = false
          }
          this.$store.dispatch('header/setObjectName', this.dictionaryWord.value)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_words.edit.title'),
        subtitle: this.$t('views.dictionary_words.edit.subtitle')
      })
    },
    updateDictionaryWord () {
      this.loading = true
      const dictionaryWordDup = this.dictionaryWord
      if (this.dictionaryWord.statusBoolean) {
        dictionaryWordDup.status = 'accepted'
      } else {
        dictionaryWordDup.status = 'rejected'
      }
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, this.id, { dictionaryWord: dictionaryWordDup })
        .then(response => {
          this.errors = {}
          this.getDictionaryWord()
          this.$router.go(-1)
          this.$toastr.s(this.$t('general.dictionary_word_updated'))
        }).catch(error => {
          this.errors = error.response.data
        this.$toastr.e(this.$t('general.check_errors'))
        }).finally(() => {
          this.loading = false
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
