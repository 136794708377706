<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
        <path :class="variant" d="M16 0H2a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2V2a2.006 2.006 0 00-2-2zm0 16H2V2h14v14zM14.99 6l-1.41-1.42-6.59 6.59L4.41 8.6l-1.42 1.41 4 3.99z" fill-rule="evenodd"/>
    </svg>
</template>

<script>
export default {
  name: 'AbkSurveyCheckboxSvg',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator (variant) {
        return ['primary', 'secondary'].includes(variant)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars";

    .primary {
        fill: $main-color
    }
    .secondary {
        fill: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../../assets/stylesheets/vars-contrast";

    .contrast{
        .primary {
            fill: $main-color
        }
        .secondary {
            fill: $main-active-color;
        }
    }
</style>
