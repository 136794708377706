<template>
  <li class="offer d-flex flex-wrap align-items-center"
      :class="{ 'no-border': noBorder, 'fixed-height': fixedHeight, highlighted: highlighted }"
      :style="[ isInactive ? { opacity: '0.5' } : { opacity: '1' } ]">
    <i v-if="offer.status === 'accepted'" class="pcg-icon-check"
       :style="[ fixedHeight ? {'color': 'green' } : { color: '#00B4EB' } ]"/>
    <i v-else-if="offer.status === 'rejected'" class="pcg-icon-cross"/>
    <span v-else class="waiting"/>
    <!--    <div class="free-space" v-if="fixedHeight && !offer.accepted && !offer.rejected && !offer.waiting"></div>-->
    <div class="offer-key" :style="[ fixedHeight ? {'background-color': '#EEF0F5' } : { 'background-color': '#F7F8FA' } ]">
      {{ index }}
    </div>
    <div class="ml-2 offer-info">
      <div class="offer-header">
        <span v-if="offerType">{{ offerType }}</span>
        <span v-if="offer.carrer_office" class="offer-carrer-office">{{ $t('components.abk_offer_item.carrer_office') }}</span>
        <span v-if="offer.status" class="offer-status">{{ $t(`general.offers.statuses.${offer.status}`) }}</span>
        <span v-if="offer.publicationDate">{{ formatDate(offer.publicationDate) }}</span>
        <span v-if="offer.company" class="offer-company">{{ offer.company.name }}</span>
      </div>
      <router-link class="offer-name" :to="{ name: 'offers_employer_show_path', params: { id: offer.id } }">
        {{ jobTitle }}
      </router-link>
    </div>
    <slot/>
  </li>
</template>

<script>
export default {
  name: 'AbkOfferItem',
  props: {
    offer: Object,
    noBorder: Boolean,
    fixedHeight: Boolean,
    index: Number,
    highlighted: Boolean
  },
  methods: {
    formatDate (date) {
      const newDate = moment(date)
      return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
    }
  },
  computed: {
    jobTitle () {
      return this.offer.jobTitle || this.$t('general.noname')
    },
    offerType () {
      return this.$te(`general.${this.offer.offerType}`) ? this.$t(`general.${this.offer.offerType}`) : null
    },
    isInactive () {
      return ['finished', 'rejected'].includes(this.offer.status)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .offer {
    list-style: none;
    padding: .2em 0;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    &.no-border {
      border: none;
    }
    &.fixed-height {
      min-height: 57px;
      flex-wrap: wrap;
    }
    &.highlighted {
      background-color: $pcg-the-lightest-gray;
    }
  }
  .offer-key {
    line-height: 2.2;
    margin: 4px 0;
    font-size: $font-size-s;
    min-width: 40px;
    width: 40px;
    background-color: $pcg-the-lightest-gray;
    color: $pcg-dark-gray;
    text-align: center;
    border-radius: 5px;
  }
  .offer-header {
    font-size: $font-size-xs;
    font-weight: 500;
    color: $pcg-gray;
    span:not(:last-child) {
      margin-right: .5em;
    }
  }
  .offer-company {
    color: $pcg-dark-gray;
  }
  .offer-name {
    font-size: $font-size-s;
    font-weight: 500;
    color: $main-color;
    text-transform: uppercase;
  }
  .pcg-icon-check {
    font-size: $font-size-s;
    line-height: 3;
    margin-right: .7em;
  }
  .pcg-icon-cross {
    color: $pcg-red;
    font-size: $font-size-s;
    line-height: 3;
    margin-right: .7em;
  }
  .waiting {
    background-color: $pcg-light-gray;
    line-height: 3;
    padding: 1px 6px;
    margin-right: .7em;
  }
  .offer-info {
    width: fit-content;
    min-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .free-space {
    margin-right: 1.7rem;
  }
  .offer-status {
    color: $pcg-light-gray;
  }
  .offer-carrer-office {
    color: $main-color;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .offer {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
      &.highlighted {
        background-color: $pcg-the-lightest-gray;
      }
    }

    .offer-key {
      /*background-color: $pcg-the-lightest-gray;*/
      color: $pcg-the-lightest-gray;
    }

    .offer-header {
      color: $pcg-gray;
    }

    .offer-company {
      color: $pcg-dark-gray;
    }

    .offer-name {
      color: $main-color;
    }

    .pcg-icon-cross {
      color: $pcg-red;
    }

    .waiting {
      background-color: $pcg-light-gray;
    }

    .offer-status {
      color: $pcg-light-gray;
    }

    .offer-carrer-office {
      color: $main-color;
    }
  }
</style>
