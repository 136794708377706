<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-competences" /> {{ $t('components.skills.abk_graduates_competences_box.title') }}
    </div>
    <div class="info-text top-info">
      {{ $t('components.skills.abk_graduates_competences_box.graduates_count') }}: {{ graduatesCount }}
    </div>
    <div v-if="competences.length > 0">
      <div v-for="competence in competences" :key="competence" class="info-text competence-name">{{ competence }}</div>
    </div>
    <div v-else class="pcg-no-records mt-4">
      {{ $t('components.skills.abk_graduates_competences_box.no_records') }}
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkGraduatesCompetencesBox',
  props: {
    graduatesCount: Number,
    competences: Array
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .info-text {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 500;
  }
  .top-info {
    margin-bottom: $pcg-m-lg;
  }
  .competence-name {
    margin-top: $pcg-m-sm;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .info-text {
      color: $pcg-gray;
    }
  }
</style>
