<template>
  <abk-events-list events-type="archived"/>
</template>

<script>
import AbkEventsList from '../../../components/events/AbkEventsList'

export default {
  name: 'ArchivedEventsTab',
  components: { AbkEventsList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.list_tabs.archived_events_tab.title'),
        subtitle: this.$t('views.events.list_tabs.archived_events_tab.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
