<template>
  <div class="position-relative">
    <div class="new-message-btn-container">
      <pcg-btn size="small" @click="goToNewMessage" class="new-message-btn">{{ $t('general.new_message') }}</pcg-btn>
    </div>
    <b-tabs v-model="tab" @input="changeTab">
      <b-tab :title="$t('views.messages.messages_index.group')"/>
      <b-tab :title="$t('views.messages.messages_index.draft')"/>
    </b-tabs>
    <transition type="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MessagesIndex',
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  data () {
    return {
      tab: 1,
      tabs: ['messages_index_group_path', 'messages_index_draft_path']
    }
  },
  methods: {
    goToNewMessage () {
      this.$router.push({ name: 'messages_new_path' })
    },
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab })
      }
    }
  },
  mounted () {
    this.tab = this.tabs.indexOf(this.$route.name)
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-message-btn-container {
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-sm-min) {
    .new-message-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .new-message-btn-container {
      background-color: $pcg-the-lightest-gray;
    }
  }
</style>
