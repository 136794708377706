<template>
  <transition name="fade" mode="out-in">
    <div v-if="backgroundImage"
         class="pcg-image-cover"
         :class="backgroundImageClasses"
         :style="backgroundStyle"/>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AbkPageBackgroundCover',
  data: function () {
    return {
      height: 100
    }
  },
  computed: {
    ...mapGetters('pageCover', ['backgroundImage', 'backgroundImageStyle', 'backgroundImageClasses']),
    backgroundStyle () {
      return {
        ...this.backgroundImageStyle,
        backgroundImage: `url(${this.backgroundImage})`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

</style>
