<template>
  <div>
    <b-row>
      <abk-loader v-if="showLoader" class="col-12 with-margins"/>
      <abk-message v-else
                   class="col-12 message"
                   :title="userMessage.title"
                   :highlighted="userMessage.unread"
                   :sender-name="$t('general.career_office_team')"
                   :date="userMessage.date"
                   :message="userMessage.content"
                   :attachments="userMessage.attachments"
                   :important="userMessage.important"
                   @click.native="markAsRead">
        {{ userMessage.content }}
      </abk-message>
    </b-row>
  </div>
</template>

<script>
import AbkMessage from '../../components/AbkMessage'
import ApiUserMessages from '../../api/user_messages'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'UserMessagesShow',
  components: { AbkLoader, AbkMessage },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      userMessage: {
        id: null
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  created () {
    this.userMessage.id = this.$route.params.id
    this.getUserMessage()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.user_messages.user_messages_show.title')
      })
    },
    getUserMessage () {
      ApiUserMessages.getUserMessage(this.userMessage.id)
        .then(response => {
          const userMessage = response.data.data
          const message = response.data.included.find(inclusion => {
            return inclusion.id === userMessage.relationships.message.data.id
          })
          const messageAttachments = response.data.included.filter(inclusion => {
            return message.relationships.messageAttachments.data.map(e => e.id).includes(inclusion.id) &&
                  inclusion.type === 'message_attachment'
          })
          this.userMessage = {
            id: userMessage.id,
            title: message.attributes.title,
            content: message.attributes.content,
            date: message.attributes.sentAt,
            unread: userMessage.attributes.status === 'recent',
            attachments: messageAttachments.map(({ id, attributes }) => {
              return {
                id: id,
                filename: attributes.filename
              }
            })
          }
          this.$store.dispatch('header/setObjectName', this.userMessage.title)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.user_messages.user_messages_show.download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    markAsRead () {
      if (!this.userMessage.unread) { return }

      ApiUserMessages.markAsRead(this.userMessage.id)
        .then(() => {
          this.userMessage.unread = false
          this.$store.dispatch('notifications/getMessages')
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .message {
    cursor: pointer;
    /deep/ &.dimmed {
      cursor: default;
    }
  }
</style>
