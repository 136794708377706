<template>
  <pcg-box :header="header" :iconClass="iconClass">
    <div class="panel-wrapper">
      <button v-if="expandable" class="show btn" id="show" @click="show = !show ">
        <span v-if="show">{{ $t('components.abk_content_preview.hide') }}</span>
        <span v-else>{{ $t('components.abk_content_preview.show') }}</span>
      </button>
      <div :class="{ show: show, panel: expandable}">
        <div v-for="section in sortedOfferSections" :key="section.id || section.key" class="section">
          <template v-if="section.sectionType === 'text'">
            <span class="title mb-3 d-block">{{ section.title }}</span>
            <span class="content" v-html="section.content"></span>
          </template>
          <template v-else>
            <div v-if="section.imageUrl || section.image">
              <img :src="imageUrl(section)" class="img-fluid">
            </div>
          </template>
        </div>
        <div class="section" v-if="attachments && attachments.length > 0">
          <span class="title mb-3 d-block">{{ $t('components.abk_content_preview.attachments') }}</span>
          <div v-for="item in attachments" :key="item.id" class="pcg-txt-small">
            <button v-if="item.filename"
                    class="download-btn"
                    @click="downloadAttachment(item.id, item.filename)">
              <i class="pcg-icon-clip pcg-sm mr-2"></i>
              {{ item.filename }}
            </button>
            <button v-else-if="item.attachment" class="download-btn" @click="downloadFromFile(item.attachment)">
              <i class="pcg-icon-clip pcg-sm mr-2"></i>
              {{ item.attachment.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </pcg-box>
</template>

<script>
import ApiOfferAttachments from '../api/offer_attachments'
import { saveAs } from 'file-saver'

export default {
  name: 'abk-content-preview',
  props: {
    contentSections: {
      type: Array,
      default: () => []
    },
    attachments: {
      type: Array,
      default: () => []
    },
    expandable: Boolean,
    header: {
      type: String
    },
    iconClass: {
      type: String
    },
    downloadAttachmentAction: {
      type: Function,
      default: ApiOfferAttachments.getCvCertificateAttachment
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    imageUrl (section) {
      return section.imageUrl ||
        section.image.url ||
        (section.image instanceof File ? URL.createObjectURL(section.image) : null)
    },
    downloadAttachment (id, filename) {
      this.downloadAttachmentAction(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
    },
    downloadFromFile (file) {
      saveAs(file)
    }
  },
  computed: {
    sortedOfferSections () {
      return this.contentSections.concat().sort((a, b) => a.sort - b.sort)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';
  .download-btn {
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: $pcg-m-xs 0 0 0;
    outline: none;
  }
  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .section {
    margin-bottom: 2.5rem;
  }

  .content {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-dark-gray;
    margin-bottom: $font-size-l;
    p {
      margin-bottom: .7rem;
    }
    ::v-deep ul {
      &, li {
        list-style-position: inside !important;
      }
      &:not([style*="padding"]), &[style*="padding: initial"], &[style*="padding-left: initial"] {
        padding-left: 0;
      }
      list-style-position: inside;
      &:not([style*="list-style"]) > li:not([style*="list-style"]),
      &:not([style*="list-style"]) > li:not([style*="list-style"]) {
        list-style-image: url("../assets/images/bullet.svg") !important;
      }
      li:not([style*="margin"]), li[style*="margin-left: initial"] {
        margin-left: 0 !important;
      }
      li:not([style*="margin"]), li[style*="margin-bottom: initial"] {
        margin-bottom: $pcg-m-xs !important;
      }
    }
  }

  .title {
    font-weight: 900;
    text-transform: uppercase;
    color: $main-color;

    /deep/ i {
      font-size: $font-size-l;
      margin-right: .5rem;
    }
  }

  img {
    margin-bottom: 40px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .panel-wrapper {
    position: relative;
  }

  .btn {
    color: $main-active-color;
    font-weight: bold;
    left: 30%;
    width: 40%;
    text-transform: uppercase;
    bottom: -1em;
    z-index: 100;
    text-align: center;
    position: absolute;

    &:hover {
      color: $hover-color;
      cursor: pointer;
    }
  }

  .btn:focus {
    box-shadow: none;
  }

  .panel {
    position: relative;
    max-height: 300px;
    overflow: hidden;
    transition: max-height .5s;

    &.show {
      max-height: 2000px;

      &.panel:after {
        background: none;
      }
    }
  }

  .panel:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%);
    pointer-events: none;
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .download-btn {
      color: $main-active-color;
    }
    .content {
      color: $pcg-dark-gray;
    }
    .title {
      color: $main-color;
    }
    .btn {
      color: $main-active-color;
      &:hover {
        color: $hover-color;
      }
    }
    .panel:after {
      background: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 90%);
    }
  }
</style>
