<template>
  <div>
    <span class="pcg-title">PcgBtn</span>
    <div class="container">
      <pcg-btn>Normal</pcg-btn>
      <pcg-btn size="small">Small</pcg-btn>
      <pcg-btn size="small" disabled>Disabled</pcg-btn>
      <br/>
      <pcg-btn variant="additional">Normal</pcg-btn>
      <pcg-btn variant="additional" size="small">Small</pcg-btn>
      <pcg-btn variant="additional" size="small" disabled>Disabled</pcg-btn>
    </div>

    <span class="pcg-title">PcgNavButton</span>
    <div class="container">
      <pcg-nav-button direction="left"/>
      <pcg-nav-button direction="right"/>
      <pcg-nav-button direction="left" disabled/>
      <pcg-nav-button direction="right" disabled/>
    </div>

    <span class="pcg-title">PcgSearch</span>
    <div class="container">
      <pcg-search/>
    </div>

    <span class="pcg-title">PcgTextInput</span>
    <div class="container">
      <pcg-text-input label="Text field" placeholder="textarea" info-text="This is a valid field!" show-info/>
      <pcg-text-input label="Text required field" placeholder="textarea" error-text="This is an invalid field!"
                      show-error required/>
      <pcg-text-input label="Textarea field" type="textarea" placeholder="textarea" info-text="This is a valid field!"
                      show-info/>
      <pcg-text-input label="Textarea required field" type="textarea" placeholder="textarea"
                      error-text="This is an invalid field!" show-error required/>
    </div>

    <span class="pcg-title">PcgSelect</span>
    <div class="container">
      <pcg-select :options="options"/>
    </div>

    <span class="pcg-title">AbkFileInput</span>
    <div class="container">
      <abk-file-input status="upload"/>
      <abk-file-input status="success"/>
      <abk-file-input status="fail"/>
      <abk-file-input status="loading"/>
    </div>

    <span class="pcg-title">PcgCheckbox</span>
    <div class="container">
      <pcg-checkbox>Checkbox</pcg-checkbox>
    </div>

    <span class="pcg-title">PcgRadio</span>
    <div class="container">
      <pcg-radio name="x" :options="radioOptions"/>
    </div>

    <span class="pcg-title">PcgSection</span>
    <div class="container">
      <pcg-section toggleHideText="Hide" toggleShowText="Show">
        <template v-slot:header>Section</template>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque consequuntur earum error facere hic inventore,
        ipsam laboriosam maxime minima nihil obcaecati omnis optio possimus praesentium quibusdam recusandae saepe velit
        vitae.
      </pcg-section>
    </div>

    <span class="pcg-title">PcgSectionInfo</span>
    <div class="container">
      <pcg-section-info>
        <template v-slot:property>Property</template>
        value
      </pcg-section-info>
    </div>

    <span class="pcg-title">PcgTable</span>
    <div class="container">
      <pcg-table :items="items">
        <template v-slot:header>Table header</template>
      </pcg-table>

      <pcg-table :items="items" rows-type="wide">
        <template v-slot:header>Table header</template>
      </pcg-table>

      <pcg-table :sections="sections">
        <template v-slot:header>Table header</template>
      </pcg-table>

      <pcg-table :sections="sections" collapse>
        <template v-slot:header>Table header</template>
      </pcg-table>

      <pcg-table :subgroups="sections" :items="items" collapse>
        <template v-slot:header>Table header</template>
      </pcg-table>
    </div>

    <span class="pcg-title">AbkDatetimePicker</span>
    <div class="container">
      <abk-datetime-picker variant="datetime" v-model="datetime"></abk-datetime-picker>
      <abk-datetime-picker variant="date" v-model="date"></abk-datetime-picker>
      <abk-datetime-picker variant="time" v-model="time"></abk-datetime-picker>
    </div>

    <span class="pcg-title">PcgSpinner</span>
    <div class="container">
      <pcg-spinner/>
    </div>

    <span class="pcg-title">PcgPopover</span>
    <div class="container popover-container">
      <pcg-popover placement="top">
        Hello
        <template v-slot:popoverBody>Popover top</template>
      </pcg-popover>

      <pcg-popover placement="bottom">
        Hello
        <template v-slot:popoverBody>Popover bottom</template>
      </pcg-popover>

      <pcg-popover placement="left">
        Hello
        <template v-slot:popoverBody>Popover left</template>
      </pcg-popover>

      <pcg-popover placement="right">
        Hello
        <template v-slot:popoverTitle>Popover title</template>
        <template v-slot:popoverBody>Popover body</template>
      </pcg-popover>
    </div>

    <span class="pcg-title">Bootstrapowy b-tabs</span>
    <div class="container">
      <b-tabs>
        <b-tab title="Tab 1">Tab 1</b-tab>
        <b-tab title="Tab 2">Tab 2</b-tab>
        <b-tab title="Tab 3">Tab 3</b-tab>
      </b-tabs>
    </div>

    <span class="pcg-title">Klasa pcg-slide-tabs i pcg-slide-tab-btn bez komponentu</span>
    <div class="container">
      <div class="pcg-slide-tabs">
        <button class="pcg-slide-tab-btn">Tab1</button>
        <button class="pcg-slide-tab-btn active">Tab2</button>
        <button class="pcg-slide-tab-btn">Tab3</button>
      </div>
    </div>

    <span class="pcg-title">PcgAlert</span>
    <div class="container">
      <pcg-alert variant="danger">
        <template v-slot:header>ALERT DANGER</template>
      </pcg-alert>
      <pcg-alert variant="info">
        <template v-slot:header>ALERT INFO</template>
      </pcg-alert>
      <pcg-alert variant="danger" collapse>
        <template v-slot:header>ALERT INFO</template>
        <template>
          <div class="pcg-alert-message">
            ALERT MESSAGE TITLE
            <span>alert message</span>
          </div>
          <div class="pcg-alert-error-code">
            ALERT ERROR CODE TITLE
            <span>alert error code</span>
          </div>
          <div class="pcg-alert-additional-info">
            ALERT ADDITIONAL INFO
            <span>alert additional info</span>
          </div>
        </template>
      </pcg-alert>
    </div>

    <span class="pcg-title">PcgDropdown oraz PcgDropdownItem</span>
    <div class="container">
      <pcg-dropdown text="Dropdown">
        <pcg-dropdown-item>Option 1</pcg-dropdown-item>
        <pcg-dropdown-item>Option 2</pcg-dropdown-item>
        <pcg-dropdown-item>Option 3</pcg-dropdown-item>
        <pcg-dropdown-item>Option 4</pcg-dropdown-item>
      </pcg-dropdown>
    </div>

    <span class="pcg-title">pcg icons</span>
    <div class="container">
      <b-row v-for="icon in icons" :key="icon">
        <b-col>
          <span class="mr-3" :class="icon"></span>
          {{ icon }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AbkDatetimePicker from '../components/AbkDatetimePicker'
import AbkFileInput from '../components/AbkFileInput'
export default {
  name: 'test-2-view',
  components: {
    AbkDatetimePicker,
    AbkFileInput
  },
  data: function () {
    return {
      icons: ['pcg-icon-adjust', 'pcg-icon-arrow-down', 'pcg-icon-arrow-left', 'pcg-icon-arrow-right',
        'pcg-icon-arrow-up', 'pcg-icon-bell', 'pcg-icon-burger', 'pcg-icon-calendar', 'pcg-icon-catalog',
        'pcg-icon-check', 'pcg-icon-circle-calendar', 'pcg-icon-circle-check', 'pcg-icon-circle-create',
        'pcg-icon-circle-cross', 'pcg-icon-circle-details', 'pcg-icon-circle-document', 'pcg-icon-circle-download',
        'pcg-icon-circle-ellipsis', 'pcg-icon-circle-marks', 'pcg-icon-circle-message', 'pcg-icon-circle-money',
        'pcg-icon-circle-pencil', 'pcg-icon-circle-printer', 'pcg-icon-circle-radio', 'pcg-icon-circle-upload',
        'pcg-icon-comment', 'pcg-icon-contact', 'pcg-icon-cross', 'pcg-icon-document', 'pcg-icon-dormitory',
        'pcg-icon-envelope', 'pcg-icon-graduation-cap', 'pcg-icon-help', 'pcg-icon-home', 'pcg-icon-info',
        'pcg-icon-insurance', 'pcg-icon-log-history', 'pcg-icon-logout', 'pcg-icon-maps', 'pcg-icon-marks',
        'pcg-icon-money', 'pcg-icon-notify', 'pcg-icon-person', 'pcg-icon-printer', 'pcg-icon-printer-small',
        'pcg-icon-proces-done', 'pcg-icon-proces-error', 'pcg-icon-proces-in-progress',
        'pcg-icon-proces-negative-decision', 'pcg-icon-proces-positive-decision', 'pcg-icon-radio',
        'pcg-icon-scholarship', 'pcg-icon-search', 'pcg-icon-settings', 'pcg-icon-statistics'],
      datetime: null,
      date: null,
      time: null,
      radioOptions: [
        { text: 'One', value: 'one' },
        { text: 'Two', value: 'two' },
        { text: 'Three', value: 'three' }
      ],
      options: [
        { text: 'Option 1', value: 'o1' },
        { text: 'Option 2', value: 'o2' },
        { text: 'Option 3', value: 'o3' },
        { text: 'Option 4', value: 'o4' }
      ],
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ],
      sections: [
        {
          header: 'Section I',
          items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
            { age: 38, first_name: 'Jami', last_name: 'Carney' }
          ]
        },
        {
          header: 'Section II',
          items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
            { age: 38, first_name: 'Jami', last_name: 'Carney' }
          ]
        }
      ]
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.test2.title')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  .popover-container {
    display: flex;
    flex-direction: row;
  }
  .container {
    margin-top: 20px;
    margin-bottom: 60px;
  }
</style>
