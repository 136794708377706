import Vue from 'vue'
import { store } from './store'
import router from './router'
import { i18n } from './translations'
import BootstrapVue from 'bootstrap-vue'
import Vuex from 'vuex'
import App from './layout/app.vue'
import VueToastr from 'vue-toastr'
import VueProgressBar from 'vue-progressbar'
import Paginate from 'vuejs-paginate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueDisqus from 'vue-disqus'
import PcgCoreVue from 'pcg-core-vue'
import LazyTube from 'vue-lazytube'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'

import './assets/stylesheets/application.scss'
import 'sweetalert2/dist/sweetalert2.min.css'

const sweetAlertOptions = {
  cancelButtonText: i18n.messages[i18n.locale].general.cancel,
  buttonsStyling: false,
  customClass: {
    title: 'pcg-sweetalert-title',
    content: 'pcg-sweetalert-content',
    confirmButton: 'pcg-button pcg-main-btn pcg-small-btn',
    cancelButton: 'pcg-button pcg-additional-btn pcg-small-btn'
  }
}
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2, sweetAlertOptions)
Vue.use(VueDisqus)
Vue.use(LazyTube)
Vue.use(VueCookies)
Vue.use(VueGtag, {
  config: { id: 'G-D7YNPKT93T' },
  // eslint-disable-next-line eqeqeq
  enabled: process.env.GOOGLE_ANALYTICS == 0
}, router)
Vue.component('paginate', Paginate)
Vue.use(PcgCoreVue)

moment.locale(i18n.locale)

let app
document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueToastr, {
    defaultTimeout: 10000
  })
  Vue.use(VueProgressBar, { color: '#00B4EB', failedColor: '#FC4747', thickness: '4px' })

  app = new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
  })

  store.$app = app
  app.$mount('#app-root')
})

export { app }
