<template>
  <span class="header-title">
    <i :class="iconClass"/>
    {{ title }}
   <span class="subtitle">{{ subtitle }}</span>
  </span>
</template>

<script>
export default {
  name: 'AbkSectionHeader',
  props: {
    title: String,
    subtitle: String,
    iconClass: String
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .header-title {
    color: $main-color;
    font-size: $font-size-xl;
    display: inline-block;
    margin-bottom: $pcg-m-xs;
    i {
      font-size: $font-size-s;
    }
    .subtitle {
      margin-left: $pcg-m-sm;
      font-size: 0.86rem;
      color: $pcg-gray;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .header-title {
      color: $main-color;
      .subtitle {
        color: $pcg-gray;
      }
    }
  }
</style>
