<template>
  <div>
    <abk-loader v-if="showLoader" class="with-margins"/>
    <div v-else-if="observedSearchResults.length > 0">
      <div v-for="searchResult in observedSearchResults" :key="`search-result-${searchResult.id}`" class="oneline">
        <div class="date-added">
          {{ $t('components.abk_observed_search_results.date_added') }} {{ formatDate(searchResult.createdAt) }}
        </div>
        <div class="flex-container">
          <span>
              <abk-observed-search-result :filter="searchResult.filter" :dictionaries="dictionaries"/>
          </span>
          <div class="d-flex flex-wrap justify-content-end">
            <span class="my-sm">
            <router-link :to="{ name: 'offers_path', query: { search: searchResult.filter } }" class="results">
              {{ $tc('components.abk_observed_search_results.results', searchResult.results) }}
            </router-link>
            </span>
              <span v-if="searchResult.newResults > 0 || true" class="new-results my-sm">
              {{ $tc('components.abk_observed_search_results.new_results', searchResult.newResults) }}
            </span>
          </div>
          <pcg-btn-symbol icon-class="pcg-icon-trash-can"
                          class="abk-delete-btn my-sm"
                          :tooltip="$t('general.delete')"
                          @click="removeSearchResult(searchResult.id, searchResult.filter)"/>
        </div>
      </div>
    </div>
    <pcg-box v-else>
      <span class="pcg-no-records">{{ $t('components.abk_observed_search_results.no_records') }}</span>
    </pcg-box>
  </div>
</template>

<script>
import AbkObservedSearchResult from './offer_filters/AbkObservedSearchResult'
import AbkLoader from './AbkLoader'
export default {
  name: 'abk-observed-search-results',
  components: { AbkLoader, AbkObservedSearchResult },
  props: {
    observedSearchResults: {
      type: Array,
      default: () => []
    },
    dictionaries: {
      type: Object,
      default: () => {}
    },
    showLoader: Boolean
  },
  methods: {
    formatDate (date) {
      const newDate = moment(date)
      return newDate.isValid() ? newDate.format('DD.MM.YYYY') : null
    },
    removeSearchResult (id, filter) {
      this.$emit('remove', id, filter)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .my-sm {
    margin-top: $pcg-m-xs;
    margin-bottom: $pcg-m-xs;
  }
  .flex-container {
    display: flex;
    align-items: center;
  }
  .date-added {
    color: $pcg-dark-gray;
    margin-bottom: .2rem;
  }
  .results {
    color: $main-color;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
  }
  .new-results {
    color: $pcg-white;
    background-color: $pcg-green;
    padding: .2rem .8rem;
    border-radius: 25px;
    font-size: .8em;
    font-weight: 400;
    margin-left: 1rem;
    display: inline-block;
    white-space: nowrap;
  }
  .delete {
    text-transform: lowercase;
    color: $pcg-dark-gray;
    font-weight: 400;
    margin-left: 2.5rem;
    &:hover {
      cursor: pointer;
    }
  }
  .one-tag {
    background-color: $color-active-filter;
    color: $main-color;
    font-weight: 400;
    border-radius: 10px;
    padding: 8px 13px;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }
  .box {
    background: $pcg-white;
    border-radius: 20px;
    padding: 15px 15px 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    margin-right: 1.5rem;
  }
  .oneline {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .date-added {
      color: $pcg-dark-gray;
    }
    .results {
      color: $main-color;
    }
    .new-results {
      color: $pcg-white;
      background-color: $pcg-green;
    }
    .delete {
      color: $pcg-dark-gray;
    }
    .one-tag {
      background-color: $color-active-filter;
      color: $main-color;
    }
    .box {
      background: $pcg-white;
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    }
  }
</style>
