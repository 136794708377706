<template>
  <div>
    <abk-simple-message-form style="margin-bottom: 1.5rem" v-model="message" :errors="errors"/>
    <pcg-btn size="small" @click="sendMessage">{{ $t('general.send') }}</pcg-btn>
  </div>
</template>

<script>
import AbkSimpleMessageForm from './AbkSimpleMessageForm'
import ApiMessages from '../../api/simple_messages'
import { mapGetters } from 'vuex'
export default {
  name: 'AbkSimpleMessagesNew',
  components: { AbkSimpleMessageForm },
  data () {
    return {
      message: {
        title: null,
        content: null
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['roles'])
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.simple_messages.simple_messages_new.title'),
        subtitle: this.$t('views.simple_messages.simple_messages_new.subtitle')
      })
    },
    sendMessage () {
      this.errors = {}

      ApiMessages.createSimpleMessage({ simpleMessage: this.message })
        .then(() => {
          this.$toastr.s(this.$t('general.message_sent'))
          this.$emit('messageSent')
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped>

</style>
