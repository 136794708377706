<template>
  <b-row style="margin-top: 5rem">
    <b-col cols="12" class="mt-3 d-flex justify-content-center">
      <span class="pcg-dark-gray-color">{{ $t('views.reset_password.reset_instructions') }}</span>
    </b-col>
    <b-col cols="12" class="mt-3 d-flex justify-content-center">
      <pcg-text-input class="col-6"
                      autocomplete="on"
                      type="email"
                      name="email"
                      :label="$t('components.abk_user_registration_form.email')"
                      v-model="email"/>
    </b-col>
    <b-col cols="12" class="mt-3 d-flex justify-content-center">
      <pcg-btn class="save-button mr-0" size="small" @click="resetPassword()">{{ $t('views.reset_password.reset') }}</pcg-btn>
    </b-col>
  </b-row>
</template>

<script>
import ApiUsers from '../api/users'
import { mapGetters } from 'vuex'

export default {
  name: 'reset-password',
  data () {
    return {
      email: null
    }
  },
  created () {
    this.hideAppLoader()
  },
  watch: {
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    resetPassword () {
      ApiUsers.resetPassword({ email: this.email })
        .then((response) => {
          this.$toastr.s(this.$t('views.reset_password.link_generated'))
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
            this.errors = error.response.data
          }
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.reset_password.title'),
        subtitle: this.$t('views.reset_password.subtitle')
      })
    },
    hideAppLoader () {
      setTimeout(() => {
        document.getElementById('app-loader').style.display = 'none'
      }, 500)
    },
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important'
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
</style>
