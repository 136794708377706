<template>
 <div>
   <input id="attachment-input" class="d-none" type="file" :value="value" @input="$emit('input', $event.target.value)"/>
   <label for="attachment-input" class="attachment-input">
     <i class="pcg-icon-clip pcg-sm mr-3"></i> {{ $t('components.abk_attachment_input.text') }}
   </label>
 </div>
</template>

<script>
export default {
  name: 'AbkAttachmentInput',
  props: {
    value: Object
  },
  data () {
    return {
      attachment: this.value
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .attachment-input {
      color: $main-active-color;
    }
  }
</style>
