<template>
  <div class="filter">
    <span :class="show ? 'collapsed' : null"
          :aria-expanded="show ? 'true' : 'false'"
          :aria-controls="`collapse-${name}`"
          @click="show = !show"
          class="header">
      {{ $t(`components.abk_search_filter.headers.${name}`) }}
      <i :class="`fas fa-caret-${ show ? 'up' : 'down' }`"></i>
      <span class="active-filters-number" v-show="!show && activeFiltersCount > 0">
        {{ activeFiltersCount }}
      </span>
    </span>
    <b-collapse v-model="show" :id="`collapse-${name}`" class="filter-collapse">
      <template v-for="filter in alwaysShowFilters">
        <div v-if="dictionaryTranslatedKey(name, filter.value.toString())"
             :key="`filter-${filter.value}`"
             class="property">
          <div class="custom-checkbox"
               :class="{ active: checkFilterActive(name, filter.value.toString()) }"
               @click="toggleFilter(name, filter.value.toString())">
            {{ dictionaryTranslatedKey(name, filter.value.toString()) }}
          </div>
          <span>
            {{ filter.doc_count }}
          </span>
        </div>
      </template>
      <b-collapse v-model="showCollapsed" :id="`collapse-more-${name}`" class="collapsed-filters">
        <div v-for="filter in collapsedFilters"
             :key="`filter-${filter.value}`"
             class="property">
          <div class="custom-checkbox"
               :class="{ active: checkFilterActive(name, filter.value.toString()) }"
               @click="toggleFilter(name, filter.value.toString())">
            {{ dictionaryTranslatedKey(name, filter.value.toString()) }}
          </div>
          <span>
            {{ filter.doc_count }}
          </span>
        </div>
      </b-collapse>
      <span class="pcg-link collapsed-btn" @click="showCollapsed = !showCollapsed" v-if="collapsedFilters.length">
        {{ showCollapsed ? $t('general.see_less') : $t('general.see_more') }}
      </span>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'abk-search-filter',
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      required: true
    },
    dictionaries: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      show: false,
      showCollapsed: false,
      showRecordsNumber: 10
    }
  },
  computed: {
    alwaysShowFilters () {
      return this.filters.slice(0, this.showRecordsNumber)
    },
    collapsedFilters () {
      return this.filters.slice(this.showRecordsNumber, this.filters.length)
    },
    activeFiltersCount () {
      return this.$route.query &&
        this.$route.query.search &&
        this.$route.query.search[this.name] ? this.$route.query.search[this.name].length : 0
    }
  },
  methods: {
    dictionaryTranslatedKey (distionaryName, distionaryKey) {
      if (this.dictionaries && this.dictionaries[distionaryName]) {
        const found = this.dictionaries[distionaryName].find(dictionaryWord => dictionaryWord.value === distionaryKey)
        return found && found.text
      }
      return distionaryKey
    },
    checkFilterActive (filterName, filterProperty) {
      return this.$route.query &&
        this.$route.query.search &&
        this.$route.query.search[filterName] &&
        (this.$route.query.search[filterName].includes(filterProperty) ||
        this.$route.query.search[filterName].includes(filterProperty.toString()))
    },
    toggleFilter (filterName, filterProperty) {
      const search = (this.$route.query && this.$route.query.search) ? Object.assign({}, this.$route.query.search) : {}
      if (search[filterName]) {
        if (search[filterName].indexOf(filterProperty) === -1) {
          search[filterName] = search[filterName].concat([filterProperty])
        } else {
          search[filterName] = search[filterName].concat([filterProperty]).filter(el => el !== filterProperty)
        }
      } else {
        search[filterName] = [filterProperty]
      }
      this.$router.push({ name: this.path, query: { search } })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .filter {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .header {
      color: $main-color;
      font-weight: 400;
      &:hover {
        cursor: pointer;
      }
      i {
        margin-left: 20px;
      }
      .active-filters-number {
        background: $pcg-gray;
        border-radius: 100px;
        color: $pcg-white;
        height: 18px;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0;
        padding-bottom: 0px;
        text-align: center;
        font-size: 0.9rem;
        margin-left: 16px;
        min-width: 18px;
      }
    }
    .filter-collapse {
      padding-left: 15px;
    }
    .property {
      display: flex;
      margin-top: 5px;
      &:first-child {
        margin-top: 15px;
      }
      span {
        font-size: $font-size-s;
        line-height: 2rem;
        color: $pcg-gray;
      }
      .custom-checkbox {
        position: relative;
        display: inline-block;
        flex: 1 0;
        margin-bottom: 0px;
        line-height: 1.5;
        color: $main-color;
        padding-left: 2.5rem;
        font-weight: 400;
        font-size: $font-size-s;
        &:before {
          content: '';
          border-radius: 5px;
          height: 1.5rem;
          width: 1.5rem;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
          border: 2px solid $main-color;
        }
        &.active:after {
          content: '';
          left: 0.5rem;
          top: 0.1rem;
          width: 0.5rem;
          height: .9rem;
          border: solid $main-color;
          border-width: 0 .15rem .15rem 0;
          position: absolute;
          transform: rotate(45deg);
        }
        &:hover {
          cursor: pointer;
          color: $hover-color;
          &:before, &:after {
            border-color: $hover-color;
          }
        }
      }
    }
    .collapsed-btn {
      cursor: pointer;
    }
    .collapsed-filters {
      .property {
        margin-top: 5px;
      }
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .filter {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .header {
        color: $main-color;
        .active-filters-number {
          background: $pcg-gray;
          color: $pcg-white;
        }
      }
      .property {
        span {
          color: $pcg-gray;
        }
        .custom-checkbox {
          color: $main-color;
          &:before {
            border: 2px solid $main-color;
          }
          &.active:after {
            border: solid $main-color;
          }
          &:hover {
            color: $hover-color;
            &:before, &:after {
              border-color: $hover-color;
            }
          }
        }
      }
    }
  }
</style>
