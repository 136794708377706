<template>
  <abk-recipient-group-list/>
</template>

<script>
import AbkRecipientGroupList from '../../components/recipient_groups/AbkRecipientGroupList'

export default {
  name: 'RecipientGroupsIndex',
  components: { AbkRecipientGroupList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.recipient_groups.recipient_groups_index.title')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
</style>
