<template>
  <div class="loader-container">
    <pcg-spinner/>
  </div>
</template>

<script>

export default {
  name: 'AbkLoader'
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .loader-container {
    text-align: center;
    width: 100%;
    &.with-margins {
      margin: $pcg-m-xxl 0;
    }
  }
</style>
