<template>
  <abk-offer-form :submit-action="createOffer" :validate-action="validate"/>
</template>

<script>
import AbkOfferForm from '../../components/AbkOfferForm'
import ApiOffers from '../../api/offers'
import ApiCompanies from '../../api/companies'
import { app } from '../../index'

export default {
  name: 'OffersNew',
  components: { AbkOfferForm },
  methods: {
    validate (form) {
      return ApiOffers.validateStep(form)
    },
    createOffer (form) {
      return ApiOffers.createOffer(form)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offers.offers_new.title'),
        subtitle: this.$t('views.offers.offers_new.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    if (app && app.$store && (app.$store.getters['auth/currentRole'] === 'employer' || app.$store.getters['auth/currentRole'] === 'recruiter')) {
      ApiCompanies.getMyCompanyStatus()
        .then(response => {
          if (response.data.status !== 'accepted') {
            app.$swal({
              title: app.$t('general.no_access'),
              text: app.$t('views.offers.offers_new.no_access_text')
            })
            next(from)
          }
        })
    }
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
