<template>
  <pcg-box class="competence-training-box">
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-calendar-date" /> {{ $t('components.skills.abk_competence_training_box.title') }}
    </div>
    <div class="top-text info-text">{{ $t('components.skills.abk_competence_training_box.top_text') }}</div>
    <ul v-if="events && events.length" class="events">
      <li v-for="event in events" :key="event.id" class="event">
        <b-row>
          <b-col cols="12" lg="5" class="d-flex flex-column justify-content-center">
            <router-link class="event-link" :to="{ name: 'events_show_path', params: { id: event.id } }">
              {{ event.eventTitle }}
            </router-link>
            <div class="d-flex justify-content-between">
              <div class="event-location">
                {{ event.place || $t('components.skills.abk_competence_training_box.no_place') }}
              </div>
              <div class="event-date">{{ formatDate(event.startDate) }}</div>
            </div>
          </b-col>
          <b-col cols="12" lg="3" class="angle-container">
            <i class="fas fa-angle-right fa-5x"></i>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="info-text">{{ $t('components.skills.abk_competence_training_box.new_competences') }}</div>
            <div v-for="competence in event.competences" :key="competence.id" class="competence-container">
              <div class="competence-name">{{ competence.name }}</div>
              <router-link :to="getOffersNotMatchingToSkillByCompetenceListPath(competence.id)" class="pcg-link">
                {{ $tc('components.skills.abk_competence_training_box.offers_count', competence.offersCount) }}
              </router-link>
            </div>
          </b-col>
        </b-row>
      </li>
    </ul>
    <div v-else class="pcg-no-records mt-4">
      {{ $t('components.skills.abk_competence_training_box.no_records') }}
    </div>
  </pcg-box>
</template>

<script>
export default {
  name: 'AbkCompetenceTrainingBox',
  props: {
    skillId: [String, Number],
    events: Array
  },
  methods: {
    formatDate (date) {
      if (date) {
        const momentDate = moment(date)
        return momentDate.isValid() ? momentDate.format('DD MMMM') : null
      }
      return null
    },
    getOffersNotMatchingToSkillByCompetenceListPath (competenceId) {
      return {
        name: 'offers_not_matching_to_skill_list_path',
        params: {
          skillId: this.skillId,
          competenceId: competenceId
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .competence-training-box {
    ::v-deep .box {
      padding-left: 0!important;
      padding-right: 0!important;
    }
  }
  .pcg-box-inner-header {
    margin-left: $pcg-box-padding-x;
    margin-right: $pcg-box-padding-x;
  }
  .pcg-no-records {
    padding-left: $pcg-box-padding-x;
  }
  .top-text {
    margin: $pcg-m-sm $pcg-box-padding-x;
  }
  .info-text {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 500;
  }
  .events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .event {
    position: relative;
    padding: 15px $pcg-box-padding-x 15px 52px;
    &:not(:last-child) {
     border-bottom: 1px solid $pcg-lightest-gray;
   }
    &:last-child {
     padding-bottom: 0;
   }
    &:last-child:before {
      padding-top: 20px;
    }
    &:before {
     content: $pcg-icon-bullet;
     color: $pcg-light-gray;
     top: 0;
     bottom: 0;
     left: 1.857rem;
     position: absolute;
     font-size: 40px;
     display: flex;
     align-items: center;
   }
  }
  .event-link {
    text-transform: uppercase;
    display: block;
    color: $main-color;
    font-weight: 400;
    margin-bottom: .1em;
  }
  .event-location {
    color: $pcg-dark-gray;
    max-width: 50%;
    line-height: 1.2;
  }
  .event-date {
    color: $pcg-gray;
  }
  .event-location, .event-date {
    font-size: $font-size-s;
  }
  .angle-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fa-angle-right {
    text-shadow: 3px 0 2px $pcg-lightest-gray;
    color: $pcg-white;
  }
  .competence-container {
    margin-top: $pcg-m-xs;
    font-weight: 500;
  }
  .competence-name {
    color: $main-color;
  }

  @media(max-width: $screen-lg-min) {
    .fa-angle-right {
      transform: rotate(90deg);
    }
    .angle-container {
      max-height: 80px;
    }
    .event {
      &:before {
        padding: 0 !important;
        align-items: start;
        top: 5px;
      }
    }
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .info-text {
      color: $pcg-gray;
    }
    .event {
      &:not(:last-child) {
        border-bottom: 1px solid $pcg-lightest-gray;
      }
      &:before {
        color: $pcg-light-gray;
      }
      .event-link {
        color: $main-color;
      }
      .event-location {
        color: $pcg-dark-gray;
      }
      .event-date {
        color: $pcg-gray;
      }
      .fa-angle-right {
        text-shadow: 3px 0 2px $pcg-lightest-gray;
        color: $pcg-white;
      }
      .competence-name {
        color: $main-color;
      }
    }
  }
</style>
