<template>
  <abk-events-list events-type="all"></abk-events-list>
</template>

<script>
import AbkEventsList from '../../components/events/AbkEventsList'
export default {
  name: 'EventsStudentRegistered',
  components: { AbkEventsList },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.events.events_student_registered.title'),
        subtitle: this.$t('views.events.events_student_registered.subtitle')
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped>

</style>
