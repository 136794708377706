<template>
  <div>
    <div class="radio-buttons-container">
      <pcg-radio :name="`radio-button-question-${question.id}`"
                 :class="{ 'd-inline-block': question.displayType === 'horizontal' }"
                 :show-label="false"
                 :options="questionFieldsOptions"
                 :orientation="question.displayType"
                 :value="radiobuttonValue"
                 @input="handleRadiobuttonInput"/>
      <template v-if="textQuestionField">
        <pcg-radio :class="{ 'd-inline-block': question.displayType === 'horizontal' }"
                   :show-label="false"
                   :options="[{ text: textQuestionField.label, value: `${openAnswerValuePrefix}-true` }]"
                   :name="`radio-button-question-${question.id}`"
                   :value="`${openAnswerValuePrefix}-${showTextQuestionField}`"
                   @input="handleRadiobuttonTextInput"/>
        <b-collapse :visible="showTextQuestionField" :id="String(textQuestionField.id)">
          <pcg-text-input :key="`radiobutton-question-field-${textQuestionField.id}`"
                          class="mt-2"
                          :show-label="false"
                          :value="value[textQuestionField.id]"
                          @input="handleTextInput(textQuestionField.id, $event)"/>
        </b-collapse>
      </template>
    </div>
    <div v-show="showError" class="pcg-error-messages">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  name: 'AbkRadioButtonsQuestion',
  props: {
    question: Object,
    value: {
      type: Object,
      default: () => { return {} }
    },
    showError: Boolean,
    errorText: String,
    showTextQuestionField: Boolean
  },
  methods: {
    handleRadiobuttonInput (value) {
      const questionField = this.question.questionFields.find(field => field.value === value)
      this.$emit('input', { [questionField.id]: value })
      this.$emit('update:showTextQuestionField', false)
    },
    handleRadiobuttonTextInput () {
      this.$emit('update:showTextQuestionField', true)
      this.$emit('input', {})
    },
    handleTextInput (questionFieldId, value) {
      typeof value === 'string' && value.length > 0
        ? this.$emit('input', { [questionFieldId]: value })
        : this.$emit('input', {})
    }
  },
  computed: {
    openAnswerValuePrefix () {
      return `${this.question.id}-${this.textQuestionField.id}-visible`
    },
    sortedQuestionFields () {
      return _.orderBy(this.question.questionFields, 'sort', 'asc')
    },
    questionFieldsOptions () {
      return this.sortedQuestionFields.filter(field => field.fieldType === 'radiobutton')
        .map(field => { return { text: field.label, value: field.value } })
    },
    textQuestionField () {
      return this.question.questionFields.find(field => field.fieldType === 'text')
    },
    radiobuttonValue () {
      return !this.textQuestionField || this.textQuestionField.id !== Object.keys(this.value)[0]
        ? Object.values(this.value)[0]
        : null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";
</style>
