let gridBreakpoints = {
  screenXsMax: 575,
  screenSmMin: 576,
  screenSmMax: 767,
  screenMdMin: 768,
  screenMdMax: 991,
  screenLgMin: 992,
  screenLgMax: 1199,
  screenXlgMin: 1200
}

export default gridBreakpoints
