<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-clip"></i>{{ $t('components.abk_attachments_box.title') }}
    </div>
    <ul class="p-0">
      <li v-for="attachment in attachments" :key="attachment.id" class="attachment-container">
        <abk-attachment @click="downloadAttachment(attachment)" class="application-attachment">
          {{ attachment.filename }}
        </abk-attachment>
      </li>
    </ul>
  </pcg-box>
</template>

<script>
import ApiOfferApplicationAttachments from '../api/offer_application_attachments'
import { saveAs } from 'file-saver'
import AbkAttachment from './offers/AbkAttachment'
export default {
  name: 'AbkAttachmentsBox',
  props: {
    attachments: Array
  },
  components: { AbkAttachment },
  methods: {
    downloadAttachment ({ id, filename }) {
      ApiOfferApplicationAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .attachment-container {
    list-style: none;
  }
  .application-attachment {
    max-width: calc(100% - 23px);
    padding-left: 1px;
  }
  .pcg-icon-clip {
    font-size: .9rem;
  }
</style>
