<template>
  <div class="dev-sessions">
    <table class="table">
      <thead>
      <tr>
        <th>id</th>
        <th>first name</th>
        <th>last name</th>
        <th>roles</th>
        <th>actions</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id" class="mb-2">
          <td>{{ user.id }}</td>
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>{{ user.roles }}</td>
          <td>
            <pcg-btn size="small" @click="login(user.id)">{{ $t('views.login.login_btn') }}</pcg-btn>
          </td>
        </tr>
        </tbody>
    </table>

  </div>
</template>

<script>
import ApiDevSessions from '../../api/dev_sessions'
import CompanyPopupMixin from '../../mixins/company_popup'
import CvPopupMixin from '../../mixins/cv_popup'

export default {
  name: 'DevSessionsIndex',
  mixins: [CompanyPopupMixin, CvPopupMixin],
  data: function () {
    return {
      users: []
    }
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dev_sessions.dev_sessions_index.title')
      })
    },
    login (userId) {
      if (process.env.RAILS_ENV === 'development') {
        this.$store.dispatch('auth/devSignIn', { id: userId })
          .then(response => {
            const redirectTo = this.$store.getters['auth/getRedirectTo']
            if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
              this.$router.push({ name: 'role_choose_path' })
            } else if (redirectTo && redirectTo.name) {
              this.$store.dispatch('auth/clearRedirectTo')
              this.$router.push(redirectTo)
            } else {
              // TODO go to user dashboard
              this.$router.push({ name: 'student_dashboard_path' })
            }
            this.checkCompany()
            this.checkCv()
          })
          .catch(error => {
            console.log(error.response.data)
            this.$toastr.e(error.response.data)
          })
      }
    },
    loadUsers () {
      ApiDevSessions.getUsers()
        .then(response => {
          this.users = response.data.data.map(user => { return { id: user.id, ...user.attributes } })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

</style>
