<template>
  <div>
    <b-row>
      <b-col class="mb-4">
        <pcg-btn @click="backToIndex" size="small">
          &lt; {{ $t('general.back') }}
        </pcg-btn>
      </b-col>
    </b-row>
    <abk-competence-form :submitAction="createCompetence"
                         :success-message="$t('views.competences.new.competence_created')"/>
  </div>
</template>

<script>
import ApiCompetences from '../../api/competences'
import AbkCompetenceForm from '../../components/competences/AbkCompetenceForm'
export default {
  name: 'CompetencesNew',
  components: { AbkCompetenceForm },
  methods: {
    createCompetence (competence) {
      return ApiCompetences.createCompetence(competence)
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.competences.new.title'),
        subtitle: this.$t('views.competences.new.subtitle')
      })
    },
    backToIndex () {
      this.$router.push({ name: 'competences_list_all_tab_path' })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
