<template>
  <div>
    <b-row class="top-bar">
      <b-col cols="12" class="top-bar-content">
        <div class="d-flex flex-wrap">
          <div class="filters-text d-flex align-items-center">{{ $t('components.abk_users_list.filter_users') }}</div>
          <pcg-search @searchEvent="getUsersAndResetPagination" @input="getUsersAndResetPagination" class="filter-select" v-model="filters.fullName"></pcg-search>
          <pcg-select :options="rolesSortOptions"
                      :default-option="{ text: this.$t('views.users_list.labels.roles'), value: null }"
                      class="filter-select"
                      v-model="filters.role"
                      @input="getUsersAndResetPagination"/>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        <abk-loader v-if="showLoader" class="with-margins"/>
        <pcg-table v-else-if="users && users.length" :items="users" :fields="fields">
          <template v-slot:header>{{ $t('components.abk_users_list.table_header') }}</template>
          <template v-slot:cell(roles)="data">
            <div v-if="data.value.length > 0" class="my-text">
              <div v-for="role in data.value" :key="role.id">
                <span>{{ roleName(role) }}</span>
              </div>
            </div>
            <div v-else class="my-text">
              <span class="no-role">{{ $t('views.users_list.no_role') }}</span>
            </div>
          </template>
          <template v-slot:cell(createdAt)="data">{{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}</template>
          <template v-slot:cell(lastLogin)="data">
            <span v-if="data.value">
              {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
            </span>
          </template>
          <template v-slot:cell(actions)="data">
            <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                            class="my-0"
                            :tooltip="$t('components.abk_users_list.edit_roles')"
                            @click="editRoles(data.item.id)"/>
          </template>
          <template v-slot:cell(acceptances)="data">
            <pcg-btn-symbol icon-class="pcg-icon-info"
                            class="my-0"
                            :tooltip="$t('components.abk_users_list.acceptances')"
                            @click="showAcceptances(data.item.id)" />
          </template>
          <template v-slot:cell(adminLogin)="data">
            <pcg-btn-symbol icon-class="pcg-icon-profile"
                            class="my-0"
                            :tooltip="$t('components.abk_users_list.admin_login')"
                            @click="login(data.item.id)"/>
          </template>
          <template v-slot:cell(accountActivate)="data">
            <pcg-btn v-if="data.item.confirmed" size="small" disabled class="action-button">
              {{ $t('general.confirmed') }}
            </pcg-btn>
            <pcg-btn v-else size="small" @click="sendActivationLink(data.item)" class="action-button">
              {{ $t('general.send') }}
            </pcg-btn>
          </template>
          <template v-slot:cell(statusActions)="data">
            <pcg-btn v-if="data.item.locked" size="small" @click="openUnblockModal(data.item)" variant="additional" class="action-button">
              {{ $t('components.abk_users_list.unblock') }}
            </pcg-btn>
            <pcg-btn v-else size="small" @click="openBlockModal(data.item)" variant="additional" class="action-button">
              {{ $t('components.abk_users_list.block') }}
            </pcg-btn>
          </template>
          <template v-slot:cell(remove)="data">
            <pcg-btn class="m-0 danger-delete-btn" size="small" @click="deleteUser(data.item.id)">{{ $t('general.remove_account') }}</pcg-btn>
          </template>
        </pcg-table>
        <pcg-box v-else>
          <span class="pcg-no-records">{{ $t('components.abk_users_list.no_records') }}</span>
        </pcg-box>
      </b-col>
      <b-modal ref="acceptances"
               centered hide-header
               v-bind:ok-title="$t('general.close')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="escAcceptances"
               @hide="escAcceptances"
               cancel-variant="default"
               footer-class="abk-modal-footer acceptances"
               content-class="abk-modal-content"
      >
        <div class="d-flex justify-content-between">
          <div class="my-header">
            <span class="edit-roles">{{ $t('views.edit_company_profile.approvals') }}</span>
          </div>
        </div>
        <div style="margin-bottom: 3rem">
          <transition-group name="fade" mode="out-in">
            <template v-if="currentAcceptances.cv && currentAcceptances.cv.length > 0">
              <h3 key="title-student">{{ $t('general.student_graduate') }}:</h3>
              <b-row class="d-flex justify-content-center"
                v-for="(acceptance, index) in currentAcceptances.cv"
                :key="`cv-approval-${index}`">
                <pcg-checkbox v-if="acceptance.wordType !== 'clause'"
                              variant="normal"
                              class="col-12"
                              :disabled="true"
                              v-model="acceptance.value">
                  <span v-html="acceptance.text"></span>
                </pcg-checkbox>
              </b-row>
            </template>
            <template v-if="currentAcceptances.company && currentAcceptances.company.length > 0">
              <h3 key="title-employer">{{ $t('general.employer_recruiter') }}:</h3>
              <b-row class="d-flex justify-content-center"
                v-for="(acceptance, index) in currentAcceptances.company"
                :key="`company-approval-${index}`">
                <pcg-checkbox v-if="acceptance.wordType !== 'clause'"
                              variant="normal"
                              class="col-12"
                              :disabled="true"
                              v-model="acceptance.value">
                  <span v-html="acceptance.text"></span>
                </pcg-checkbox>
              </b-row>
            </template>
          </transition-group>
        </div>
      </b-modal>
      <b-modal ref="roles"
               centered hide-header
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="rolesSave"
               @hide="escRoles"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               footer-class="abk-modal-footer"
               content-class="abk-modal-content"
      >
        <div class="d-flex justify-content-between">
          <div class="my-header">
            <span class="edit-roles">{{ $t('components.abk_users_list.edit_roles') }}</span>
          </div>
          <div>
            <pcg-btn size="small" variant="additional" @click="addRole">{{ $t('components.abk_users_list.add') }}</pcg-btn>
          </div>
        </div>
        <div style="margin-bottom: 3rem">
          <transition-group name="fade" mode="out-in">
            <div v-for="(role, index) in changingRoles" :key="index + 0" style="margin-bottom: 1rem">
              <div class="one-role">{{ $t('components.abk_users_list.role') }}</div>
              <b-row>
                <b-col>
                  <pcg-select
                      class="modal-select"
                      :options="rolesOptions"
                      :default-option="{ text: $t('general.select'), id: '' }"
                      v-model="changingRoles[index].id"
                  />
                </b-col>
                <b-col cols="1">
                  <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeRole(index)">
                    <i class="pcg-icon-cross my-cross"></i>
                  </a>
                </b-col>
              </b-row>
            </div>
          </transition-group>
        </div>
      </b-modal>
      <b-modal ref="blockModal"
               centered hide-header
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="toggleUserStatus(selectedUser.id, false)"
               @hide="escModal"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               footer-class="abk-modal-footer"
               content-class="abk-modal-content"
      >
        <div class="d-flex justify-content-between">
          <h5>{{ $t('views.users_list.block_user') }} {{ `${selectedUser.fullName} (${selectedUser.email})` }}?</h5>
        </div>
      </b-modal>
      <b-modal ref="unblockModal"
               centered hide-header
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="toggleUserStatus(selectedUser.id, true)"
               @hide="escModal"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               footer-class="abk-modal-footer"
               content-class="abk-modal-content"
      >
        <div class="d-flex justify-content-between">
          <h5>{{ $t('views.users_list.block_user') }} {{ `${selectedUser.fullName} (${selectedUser.email})` }}?</h5>
        </div>
      </b-modal>
      <b-col class="col-12">
        <pcg-pagination v-model="pagination.currentPage"
                        @input="getUsers"
                        :page-count="pagination.pages"
                        :total-records="pagination.totalRecords"
                        class="col-12 justify-content-center my-pagination"/>
      </b-col>
    </b-row>
    <abk-remove-account-modal :show="showAccountModal" :deleting-id="deletingUserId" type="admin" @discard="showAccountModal = false" @removed="getUsersAndResetPagination" />
  </div>
</template>

<script>
import ApiUsers from '../api/users'
import LoaderDelayMixin from '../mixins/loader_delay'
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import AbkLoader from './AbkLoader'
import PaginableResourceMixin from '../mixins/paginable_resource_mixin'
import AbkRemoveAccountModal from './AbkRemoveAccountModal.vue'

export default {
  name: 'abk-users-list',
  components: { AbkLoader, AbkRemoveAccountModal },
  mixins: [LoaderDelayMixin, PaginableResourceMixin],
  data () {
    return {
      changingRoles: [],
      currentAcceptances: [],
      selectedUser: {},
      currentUser: {},
      fields: [],
      showAccountModal: false,
      errors: {},
      deletingUserId: null,
      filters: {
        full_name: null,
        order_direction: 'desc',
        order_by: 'created_at',
        page: 1,
        role: null
      },
      roles: ['graduate', 'employer', 'recruiter', 'career_office', 'student', 'counselor', 'administrator'],
      rolesOptions: [],
      rolesSortOptions: [],
      users: [],
      adminLogin: false
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole', 'userId']),
    ...mapGetters('environment', ['env']),
    isAnyUser () {
      return this.users && this.users.length
    },
    queryParams () {
      return {
        filters: this.filters
      }
    }
  },
  mounted () {
    ApiUsers.getAdminLogin()
      .then(response => {
        this.adminLogin = response.data.adminLogin
        this.getUsers()
        this.initializeOptions()
      })
  },
  methods: {
    sendActivationLink (user) {
      ApiUsers.emailReconfirmation({ email: user.email })
        .then(() => {
          this.$toastr.s(this.$t('views.email_confirmation.admin_email_reconfirmation'))
        })
        .catch(error => {
          if (error.response.data) {
            this.$toastr.e(error.response.data)
          }
        })
    },
    deleteUser (id) {
      this.deletingUserId = id
      this.showAccountModal = true
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    openBlockModal (user) {
      this.selectedUser = user
      this.$refs.blockModal.show()
    },
    openUnblockModal (user) {
      this.selectedUser = user
      this.$refs.unblockModal.show()
    },
    escModal () {
      this.selectedUser = {}
      this.$refs.blockModal.hide()
      this.$refs.unblockModal.hide()
    },
    getUsersAndResetPagination () {
      this.resetPaginationPage()
      this.getUsers()
    },
    getUsers () {
      this.filters.page = this.pagination.currentPage
      ApiUsers.getUsers(this.filters)
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.users = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    dataTable (data) {
      const result = []
      data.forEach(user => {
        result.push({
          ...user.attributes
        })
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key !== 'id' && key !== 'locked' && key !== 'confirmed') {
              fields.push({
                key: key,
                label: this.$t(`views.users_list.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionaries.labels.actions'),
        thStyle: 'width: 5rem;'
      })
      fields.push({
        key: 'acceptances',
        label: this.$t('views.users_list.labels.acceptances')
      })
      if (this.adminLogin) {
        fields.push({
          key: 'adminLogin',
          label: this.$t('views.users_list.labels.relogin')
        })
      }
      fields.push({
        key: 'accountActivate',
        label: this.$t('views.users_list.labels.activation_mail')
      })
      fields.push({
        key: 'statusActions',
        label: this.$t('views.users_list.labels.status_actions')
      })
      fields.push({
        key: 'remove',
        label: this.$t('views.users_list.labels.account_remove')
      })

      return fields
    },
    roleName (role) {
      return this.$t(`views.users_list.roles.${role}`)
    },
    initializeOptions () {
      setTimeout(() => {
        this.initializeRolesOptions()
      }, 0)
    },
    initializeRolesOptions () {
      this.rolesSortOptions = this.roles.map(role => {
        return { text: this.$t(`views.users_list.roles.${role}`), value: role }
      })
      this.rolesSortOptions.unshift({ text: this.$t('components.abk_users_list.no_role'), value: 'no_role' })
      this.rolesSortOptions.unshift({ text: this.$t('components.abk_users_list.all'), value: '' })
      this.rolesOptions = this.roles.map(role => {
        return { text: this.$t(`views.users_list.roles.${role}`), value: role }
      })
    },
    escRoles () {
      this.$refs.roles.hide()
    },
    escAcceptances () {
      this.$refs.acceptances.hide()
    },
    addRole () {
      this.changingRoles.push({
        id: ''
      })
    },
    showAcceptances (id) {
      this.currentAcceptances = []
      ApiUsers.getAllAcceptances({ userId: id })
        .then(res => {
          this.currentAcceptances = res.data.acceptances
          const sortByName = []
          sortByName.push(...this.currentAcceptances.sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' })))
          this.currentAcceptances = sortByName
          this.currentAcceptances = {
            cv: this.currentAcceptances.filter(acc => acc.student === true),
            company: this.currentAcceptances.filter(acc => acc.company === true)
          }
          this.$refs.acceptances.show()
        })
        .catch(err => {
          console.log(err)
        })
    },
    toggleUserStatus (id, isBlocked) {
      ApiUsers.toggleStatus({ id, blocked: isBlocked })
        .then(result => {
          this.$toastr.s(this.$t(`components.abk_users_list.${isBlocked ? 'unblocked' : 'blocked'}`))
          this.getUsers()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data)
          console.log(err.response.data)
        })
    },
    editRoles (id) {
      this.changingRoles = []
      this.$refs.roles.show()
      const idx = this.users.findIndex(u => {
        return u.id === id.toString()
      })
      this.currentUser = this.users[idx]
      this.currentUser.roles.forEach(role => {
        this.changingRoles.push({ id: role })
      })
    },
    removeRole (id) {
      this.changingRoles.splice(id, 1)
    },
    rolesSave () {
      const roles = []
      this.changingRoles.forEach(role => {
        roles.push(role.id)
      })
      ApiUsers.updateRoles(this.currentUser.id, { user: { roles: roles } })
        .then(result => {
          this.getUsers()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data.roles)
          console.log(err.response.data)
        })
    },
    setTime (date) {
      if (date) {
        const momentDate = moment(date)
        return momentDate.format('LT')
      }
    },
    login (id) {
      this.$store.dispatch('auth/adminSignIn', { id: id })
        .then(response => {
          const redirectTo = this.$store.getters['auth/getRedirectTo']
          if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
            this.$router.push({ name: 'role_choose_path' })
          } else if (redirectTo && redirectTo.name) {
            this.$store.dispatch('auth/clearRedirectTo')
            this.$router.push(redirectTo)
          } else {
            // TODO go to user dashboard
            this.$router.push({ name: 'student_dashboard_path' })
          }
          this.checkCompany()
          this.checkCv()
        })
        .catch(error => {
          console.log(error.response.data)
          this.$toastr.e(error.response.data)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .action-button {
    margin: 0;
  }

  .danger-delete-btn, .danger-delete-btn:focus {
    background-color: $pcg-red;
    color: $color-wcag-static;
  }

  .danger-delete-btn:hover {
    background-color: $alert-error;
  }

  .matching-competences-container {
    margin-left: auto;
    min-width: 130px;
    text-align: center;
  }
  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  /deep/ .pcg-table-container {
    tr {
      td:not(:first-child):not(:last-child) {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    .matching-competences-container {
      min-width: 70px;
      width: 70px;
    }
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-roles {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .modal-select {
    position: relative;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
    /deep/ .ls-element .pcg-input{
      height: calc(1.5em + 0.75em + 2px);
    }
  }

  .my-cross {
    color: $pcg-gray;
    position: relative;
    top: .8rem;
    cursor: pointer;
  }

  .one-role {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .filters-text {
      color: $pcg-gray;
    }
    .edit-roles {
      color: $pcg-gray;
    }
    .modal-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .my-cross {
      color: $pcg-gray;
    }
    .one-role {
      color: $pcg-gray;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
