<template>
  <div class="cookies-policy">
    <b-row style="margin-bottom: 20px;">
      <b-col cols="10">
        <img style="margin-right: .3rem" src="../assets/images/cookie.png" alt="Cookie">
        <h2 class="cookies-information">{{ $t('general.cookies_information') }}</h2>
      </b-col>
      <b-col cols="2" class="d-flex justify-content-end">
        <i class="pcg-icon-cross" style="cursor: pointer" @click="acceptCookies"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12">
        {{ $t('general.cookies_policy') }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AbkCookiesPolicy',
  methods: {
    acceptCookies () {
      this.$emit('acceptCookies')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .cookies-policy {
    min-width: 350px;
    bottom: 0;
    margin-left: -10px;
    position: fixed;
    background: #ECF9FD 0 0 no-repeat padding-box;
    box-shadow: 0 10px 20px #0000001A;
    color: $main-color;
    z-index: 1000;
    padding: 30px 77px 30px 60px;
    font-weight: 400;
  }

  .cookies-information {
    display: inline-block;
    position: relative;
    top: .2rem;
    font-weight: bold;
    letter-spacing: 0.32px;
    color: #253B80;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
