<template>
  <b-row class="position-relative">
    <div class="new-word-btn-container">
      <pcg-btn size="small" @click="goToNewWordForm">
        + {{ $t('views.dictionary_words.index.add') }}
      </pcg-btn>
    </div>
    <b-tabs v-model="tab" @input="changeTab" class="col-12">
      <b-tab :title="$t('views.dictionary_words.index.all')"/>
      <b-tab>
        <template v-slot:title>
          {{ $t('views.dictionary_words.index.recent') }}
          <span v-if="recentWordsCount > 0" class="abk-counter">{{ recentWordsCount }}</span>
        </template>
      </b-tab>
      <b-tab :title="$t('views.dictionary_words.index.accepted')"/>
      <b-tab :title="$t('views.dictionary_words.index.rejected')"/>
    </b-tabs>
    <transition name="slide" mode="out-in">
      <router-view :key="$route.path" @wordsChanged="getDictionaryWordsCounter"/>
    </transition>
  </b-row>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'

export default {
  name: 'DictionaryWordsTabs',
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      tab: 1,
      tabs: [
        'dictionary_words_path',
        'dictionary_words_recent_path',
        'dictionary_words_accepted_path',
        'dictionary_words_rejected_path'
      ],
      recentWordsCount: 0
    }
  },
  watch: {
    '$route.name' (newValue) {
      this.tab = this.tabs.indexOf(newValue)
    }
  },
  created () {
    this.getDictionaryWordsCounter()
  },
  methods: {
    getDictionaryWordsCounter () {
      ApiDictionaryWords.getDictionaryWordsCounter(this.dictionaryId, 'recent')
        .then(response => {
          this.recentWordsCount = response.data.count
        })
        .catch(error => {
          console.error(error)
        })
    },
    changeTab (event) {
      const targetTab = this.tabs[event]

      if (this.$route.name !== targetTab) {
        this.$router.push({ name: targetTab, params: { dictionaryId: this.dictionaryId } })
      }
    },
    goToNewWordForm () {
      this.$router.push({ name: 'dictionary_words_new_path', params: { dictionaryId: this.dictionaryId } })
    }
  },
  mounted () {
    this.tab = this.tabs.indexOf(this.$route.name)
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-word-btn-container {
    z-index: 10;
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-lg-min) {
    .new-word-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .new-word-btn-container {
      background-color: $pcg-the-lightest-gray;
    }
  }
</style>
