<template>
  <div>
    <div class="grid table-header">
      <span/>
      <span class="table-column-header">{{ $t('components.webpages.abk_webpages_tree.publication_status') }}</span>
      <div class="d-flex mx-auto date table-column-header">
        {{ $t('components.webpages.abk_webpages_tree.edition_date') }}
      </div>
      <div class="d-flex mx-auto date table-column-header">
        {{ $t('components.webpages.abk_webpages_tree.creation_date') }}
      </div>
      <span/>
    </div>
    <div v-for="n in news" :key="n.id">
      <abk-news-tree-node
          :news="n"
          @publishPage="$emit('publishPage', $event)"
          @deletePage="$emit('deletePage', $event)"/>
    </div>
  </div>
</template>

<script>
import AbkNewsTreeNode from './AbkNewsTreeNode'
export default {
  name: 'AbkNewsTree',
  components: { AbkNewsTreeNode },
  props: {
    tableKey: String,
    news: Array
  }
}
</script>

<style scoped lang="scss">
@import "app/javascript/abk/assets/stylesheets/vars";

.table-column-header {
  white-space: nowrap;
  font-size: $font-size-s;
  line-height: 1;
  color: $pcg-gray;
  font-weight: 500;
  padding: $pcg-m-sm $pcg-m-lg $pcg-m-sm 0;
}
.table-header {
  margin-bottom: $pcg-m-sm;
}
/deep/ .grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 10vw 10vw 10vw 8vw;
  & > * {
    padding-left: $pcg-m-xs;
    padding-right: $pcg-m-xs;
    &:not(:first-child) {
      text-align: center;
    }
  }
}
@media(max-width: $screen-md-max) {
  /deep/ .date {
    display: none!important;
  }
  /deep/ .grid {
    grid-template-columns: 1fr 15vw 25vw;
  }
}
@media(max-width: $screen-sm-max) {
  /deep/ .grid {
    grid-template-columns: 1fr 25vw 25vw;
  }
}
</style>
