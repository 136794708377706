<template>
  <div class="recipient-group-container">
    <button class="section-btn small float-right" @click="close()">✕ {{ $t('general.delete') }}</button>
    <b-row>
      <div class="col-12  col-sm-9 form-group">
        <label class="pcg-input-label pcg-input-label-required">
          {{ $t('components.abk_message_form.fields.recipient_type') }}
        </label>
        <div>
          <pcg-checkbox v-for="(recipient) in recipientTypes"
                        :value="recipient.boolValue"
                        @input="updateRecipientTypes(recipient.value, $event)"
                        :key="recipient.value"
                        class="abk-inline-block">
            {{ recipient.text }}
          </pcg-checkbox>
        </div>
        <div v-if="errorExist('recipientType')" class="pcg-error-messages col-12">{{ getError('recipientType') }}</div>
      </div>
    </b-row>
    <b-row>
      <pcg-text-input class="col-12 col-sm-9"
                      autocomplete="off"
                      :required="true"
                      :show-label="true"
                      :label="$t('components.abk_recipient_group_form.fields.name')"
                      :error-text="getError('name')"
                      :show-error="errorExist('name')"
                      v-model="recipientGroup.name"/>
    </b-row>
    <hr/>
    <b-row v-if="showOnlyForEmployers()">
      <div class="col-12  col-sm-9 form-group">
        <label class="pcg-input-label">
          {{ $t('components.abk_recipient_group_form.fields.dictionary_acceptance_word_id') }}
        </label>
        <div>
          <pcg-checkbox v-for="cooperation in cooperations"
                        :key="cooperation.value"
                        class="d-inline-block"
                        :input-value="cooperation.value"
                        @input="selectAcceptanceWord(cooperation.value, $event)">
            {{ cooperation.text }}
          </pcg-checkbox>
        </div>
        <div v-if="errorExist('recipientGroupAcceptances')" class="pcg-error-messages col-12">
          {{ getError('recipientGroupAcceptances') }}
        </div>
      </div>
    </b-row>
    <b-row v-if="showForEmployers()">
      <div class="col-12  col-sm-9 form-group">
        <label class="pcg-input-label">
          {{ $t('components.abk_recipient_group_form.fields.dictionary_acceptance_employer') }}
        </label>
        <div>
          <pcg-checkbox v-for="company in companies"
                        :key="company.value"
                        class="d-inline-block"
                        :input-value="company.value"
                        @input="selectAcceptanceWord(company.value, $event)">
            {{ company.text }}
          </pcg-checkbox>
        </div>
        <div v-if="errorExist('recipientGroupAcceptances')" class="pcg-error-messages col-12">
          {{ getError('recipientGroupAcceptances') }}
        </div>
      </div>
    </b-row>
    <b-row v-if="showForStudentsOrGraduates()">
      <div class="col-12  col-sm-9 form-group">
        <label class="pcg-input-label">
          {{ $t('components.abk_recipient_group_form.fields.dictionary_acceptance_student') }}
        </label>
        <div>
          <pcg-checkbox v-for="cv in cvs"
                        :key="cv.value"
                        class="d-inline-block"
                        :input-value="cv.value"
                        @input="selectAcceptanceWord(cv.value, $event)">
            {{ cv.text }}
          </pcg-checkbox>
        </div>
        <div v-if="errorExist('recipientGroupAcceptances')" class="pcg-error-messages col-12">
          {{ getError('recipientGroupAcceptances') }}
        </div>
      </div>
    </b-row>
    <b-row v-if="parentType === 'survey'">
      <div class="col-12  col-sm-9 form-group">
        <label class="pcg-input-label pcg-input-label-required">
          {{ $t('components.abk_recipient_group_form.fields.surveys') }}
        </label>
        <div>
          <pcg-checkbox v-for="surveyAcceptance in surveyAcceptances"
                        :key="surveyAcceptance.value"
                        class="d-inline-block"
                        :input-value="surveyAcceptance.value"
                        @input="selectAcceptanceWord(surveyAcceptance.value, $event)">
            <span v-html="surveyAcceptance.text"></span>
          </pcg-checkbox>
        </div>
        <div v-if="errorExist('recipientGroupSurveyAcceptances')" class="pcg-error-messages col-12">
          {{ getError('recipientGroupSurveyAcceptances') }}
        </div>
      </div>
    </b-row>
    <b-row v-if="onlyStudentsAndGraduates">
      <!--<pcg-select class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"-->
                  <!--:options="selectOptionsFor('generalType', 'root')"-->
                  <!--:value="recipientGroup.activityId"-->
                  <!--@input="recipientGroup.activityId = $event"-->
                  <!--:error-text="getError('activityId')"-->
                  <!--:show-error="errorExist('activityId')"-->
                  <!--:label="$t('components.abk_recipient_group_form.fields.activity_id')"/>-->
      <pcg-select class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('faculty', 'root')"
                  :value="recipientGroup.departmentId"
                  @input="recipientGroup.departmentId = $event"
                  :error-text="getError('departmentId')"
                  :show-error="errorExist('departmentId')"
                  :label="$t('components.abk_recipient_group_form.fields.department_id')"/>
      <pcg-select class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('course', recipientGroup.departmentId)"
                  :value="recipientGroup.fieldOfStudyId"
                  @input="recipientGroup.fieldOfStudyId = $event"
                  :error-text="getError('fieldOfStudyId')"
                  :show-error="errorExist('fieldOfStudyId')"
                  :label="$t('components.abk_recipient_group_form.fields.field_of_study_id')"/>
      <pcg-select v-if="parentType === 'message'"
                  class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="options.recruitmentYearId"
                  :value="recipientGroup.recruitmentYearId"
                  @input="recipientGroup.recruitmentYearId = $event"
                  :error-text="getError('recruitmentYearId')"
                  :show-error="errorExist('recruitmentYearId')"
                  :label="$t('components.abk_recipient_group_form.fields.recruitment_year_id')"/>
      <pcg-select class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('type', recipientGroup.fieldOfStudyId)"
                  :value="recipientGroup.typeOfStudiesId"
                  @input="recipientGroup.typeOfStudiesId = $event"
                  :error-text="getError('typeOfStudiesId')"
                  :show-error="errorExist('typeOfStudiesId')"
                  :label="$t('components.abk_recipient_group_form.fields.type_of_studies_id')"/>
      <pcg-select class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('kind', recipientGroup.typeOfStudiesId)"
                  :value="recipientGroup.studyModeId"
                  @input="recipientGroup.studyModeId = $event"
                  :error-text="getError('studyModeId')"
                  :show-error="errorExist('studyModeId')"
                  :label="$t('components.abk_recipient_group_form.fields.study_mode_id')"/>
      <pcg-select v-if="parentType === 'message'"
                  class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('speciality', recipientGroup.studyModeId)"
                  :value="recipientGroup.specialtyId"
                  @input="recipientGroup.specialtyId = $event"
                  :error-text="getError('specialtyId')"
                  :show-error="errorExist('specialtyId')"
                  :label="$t('components.abk_recipient_group_form.fields.specialty_id')"/>
      <pcg-select v-if="parentType === 'message'"
                  class="abk-input col-12 col-sm-6 col-lg-4 col-xl-3 form-group"
                  :options="selectOptionsFor('specialization', recipientGroup.specialtyId)"
                  :value="recipientGroup.specializationId"
                  @input="recipientGroup.specializationId = $event"
                  :error-text="getError('specializationId')"
                  :show-error="errorExist('specializationId')"
                  :label="$t('components.abk_recipient_group_form.fields.specialization_id')"/>

      <div v-if="errorExist('base')" class="pcg-error-messages col-12">{{ getError('base') }}</div>
    </b-row>
    <div class="text-right">
      <pcg-btn size="small" @click="save" class="action-btn">{{ $t('components.abk_recipient_group_form.buttons.save') }}</pcg-btn>
    </div>
  </div>
</template>

<script>
import ApiRecipientGroups from '../../api/recipient_groups'
import ApiDictionaryAcceptances from '../../api/dictionary_acceptances'

export default {
  name: 'AbkRecipientGroupForm',
  props: {
    universityStructure: {
      type: Object,
      default: () => {}
    },
    messageRecipientTypes: {
      type: Array,
      default: () => []
    },
    parentType: {
      type: String,
      default: 'message',
      validator (parentType) {
        return ['message', 'survey'].includes(parentType)
      }
    }
  },
  data () {
    return {
      recipientGroup: {
        name: null,
        subject: null,
        students: null,
        activityId: null,
        departmentId: null,
        fieldOfStudyId: null,
        recruitmentYearId: null,
        specialtyId: null,
        specializationId: null,
        studyModeId: null,
        typeOfStudiesId: null,
        dictionaryAcceptanceWordsIds: [],
        recipients: []
      },
      errors: {},
      universityStructureOptions: {},
      options: {
        recruitmentYearId: []
      },
      cooperations: [],
      surveyAcceptances: [],
      cvs: [],
      companies: []
    }
  },
  computed: {
    watchDepartmentId () {
      return this.recipientGroup.departmentId
    },
    watchFieldOfStudyId () {
      return this.recipientGroup.fieldOfStudyId
    },
    watchTypeOfStudiesId () {
      return this.recipientGroup.typeOfStudiesId
    },
    watchStudyModeId () {
      return this.recipientGroup.studyModeId
    },
    watchSpecialtyId () {
      return this.recipientGroup.specialtyId
    },
    attachmentErrorsKeys () {
      const keys = Object.keys(this.errors).filter(key => {
        return key.split('.')[0] === 'messageAttachments' && key.split('.').length > 1
      })
      return keys
    },
    attachmentErrors () {
      const errors = []

      this.attachmentErrorsKeys.forEach(key => {
        const splittedKey = key.split('.')
        if (!errors[splittedKey[1]]) { errors[splittedKey[1]] = {} }
        errors[splittedKey[1]] = this.errors[key]
      })

      return errors
    },
    onlyStudentsAndGraduates () {
      return (this.recipientTypeIncludes('student') || this.recipientTypeIncludes('graduate')) && !this.recipientTypeIncludes('employer') && !this.recipientTypeIncludes('recruiter')
    },
    recipientTypes () {
      return [
        { text: this.$t('general.recipient_type.students'), value: 'student', boolValue: false },
        { text: this.$t('general.recipient_type.graduates'), value: 'graduate', boolValue: false },
        { text: this.$t('general.recipient_type.employers'), value: 'employer', boolValue: false },
        { text: this.$t('general.recipient_type.recruiters'), value: 'recruiter', boolValue: false }
      ]
    }
  },
  watch: {
    universityStructure (newValue) {
      this.parseUniversityStructure()
    },
    watchDepartmentId (newValue) {
      this.recipientGroup.fieldOfStudyId = null
    },
    watchFieldOfStudyId (newValue) {
      this.recipientGroup.typeOfStudiesId = null
    },
    watchTypeOfStudiesId (newValue) {
      this.recipientGroup.studyModeId = null
    },
    watchStudyModeId (newValue) {
      this.recipientGroup.specialtyId = null
    },
    watchSpecialtyId (newValue) {
      this.recipientGroup.specializationId = null
    },
    onlyStudentsAndGraduates (newValue) {
      if (!newValue) {
        this.recipientGroup.activityId = null
        this.recipientGroup.departmentId = null
        this.recipientGroup.fieldOfStudyId = null
        this.recipientGroup.recruitmentYearId = null
        this.recipientGroup.typeOfStudiesId = null
        this.recipientGroup.studyModeId = null
        this.recipientGroup.specialtyId = null
        this.recipientGroup.specializationId = null
      }
    }
  },
  mounted () {
    this.generateRecruitmentYearIdOptions()
    this.parseUniversityStructure()
    this.getCooperations()
    this.getSurveyAcceptances()
    this.getCvs()
    this.getCompanies()
  },
  methods: {
    selectAcceptanceWord (cooperationId, event) {
      const acceptancesIds = this.recipientGroup.dictionaryAcceptanceWordsIds

      this.recipientGroup.dictionaryAcceptanceWordsIds = event
        ? [...acceptancesIds, cooperationId]
        : acceptancesIds.filter(e => e !== cooperationId)
    },
    generateSequence (from, howMany, by) {
      return Array.from({ length: howMany }, (el, index) => index * by + from)
    },
    selectOptionsFor (name, key) {
      if (this.universityStructureOptions && this.universityStructureOptions[name] && this.universityStructureOptions[name][key]) {
        return this.universityStructureOptions[name][key]
      } else {
        return []
      }
    },
    close () {
      this.$emit('close')
    },
    save () {
      ApiRecipientGroups.createRecipientGroup({ recipientGroup: this.getRecipientGroupForm(), type: this.parentType })
        .then((response) => {
          this.clearErrors()
          this.$toastr.s(this.$t('components.abk_recipient_group_form.recipient_group_saved'))
          this.$emit('saved', response.data.data.id)
          this.close()
        })
        .catch((error) => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    clearErrors () {
      this.errors = {}
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    parseUniversityStructure () {
      let correctKey = 'root'
      const copy = {}
      Object.keys(this.universityStructure).forEach(key => {
        copy[key] = {}
        this.universityStructure[key].forEach(entry => {
          correctKey = entry.parentId === null ? 'root' : entry.parentId
          if (!Object.prototype.hasOwnProperty.call(copy[key], correctKey)) {
            copy[key][correctKey] = []
          }
          copy[key][correctKey].push({
            text: entry.name.polish,
            value: entry.id
          })
        })
      })
      this.universityStructureOptions = copy
    },
    generateRecruitmentYearIdOptions () {
      const startYear = 1950
      this.options.recruitmentYearId = this.generateSequence(startYear, moment().year() - startYear + 1, 1).map(year => {
        return {
          text: year,
          value: year
        }
      })
      this.options.recruitmentYearId = this.options.recruitmentYearId.reverse()
    },
    recipientTypeIncludes (value) {
      return _.includes(this.recipientGroup.recipients, value)
    },
    showForStudentsOrGraduates () {
      return this.recipientTypeIncludes('student') || this.recipientTypeIncludes('graduate')
    },
    showForEmployers () {
      return this.recipientTypeIncludes('employer') || this.recipientTypeIncludes('recruiter')
    },
    showOnlyForEmployers () {
      return (this.recipientTypeIncludes('employer') || this.recipientTypeIncludes('recruiter')) && !(this.recipientTypeIncludes('student') || this.recipientTypeIncludes('graduate'))
    },
    updateRecipientTypes (name, value) {
      if (value) {
        this.recipientGroup.recipients.push(name)
        this.messageRecipientTypes.push(name)
      } else {
        this.recipientGroup.recipients.splice(this.recipientGroup.recipients.indexOf(name), 1)
        this.messageRecipientTypes.splice(this.messageRecipientTypes.indexOf(name), 1)
      }
    },
    getCooperations () {
      ApiDictionaryAcceptances.getDictionaryAcceptanceWords('cooperation')
        .then(response => {
          this.cooperations = response.data.data.map(word => { return { text: word.attributes.value, value: word.id } })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    getCvs () {
      ApiDictionaryAcceptances.getDictionaryAcceptanceWords('cv')
        .then(response => {
          response.data.data.forEach(word => {
            if (word.attributes.acceptanceType) {
              this.cvs.push({ text: word.attributes.value, value: word.id })
            }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    getCompanies () {
      ApiDictionaryAcceptances.getDictionaryAcceptanceWords('employer')
        .then(response => {
          response.data.data.forEach(word => {
            if (word.attributes.acceptanceType) {
              this.companies.push({ text: word.attributes.value, value: word.id })
            }
          })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    getSurveyAcceptances () {
      ApiDictionaryAcceptances.getDictionaryAcceptanceWords('survey')
        .then(response => {
          this.surveyAcceptances = response.data.data.map(word => {
            return { text: word.attributes.value, value: word.id }
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getRecipientGroupForm () {
      return {
        ...this.recipientGroup
        // studyModeId: this.getValidId('kind', this.recipientGroup.studyModeId),
        // typeOfStudiesId: this.getValidId('type', this.recipientGroup.typeOfStudiesId)
      }
    }
    // getValidId (type, id) {
    //   return id && this.universityStructure[type].find(e => e.id === id).sTypeId
    // }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .section-btn {
    white-space: nowrap;
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.small {
      font-size: $font-size-s;
    }
  }
  .recipient-group-container {
    padding: 30px 25px;
    border: 2px dashed $pcg-gray;
    min-height: 130px;
    border-radius: 20px;
  }
  hr {
    margin-left: -25px;
    margin-right: -25px;
  }
  /deep/ .dropdown-menu {
    overflow: scroll;
    max-height: 328px;
  }
  .abk-inline-block{
    display: inline-block;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .section-btn {
      color: $main-active-color;
    }
    .recipient-group-container {
      border: 2px dashed $pcg-gray;
    }
  }
</style>
