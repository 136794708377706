<template>
  <pcg-box :header="$t('components.abk_actual_recrutations_box.header')">
    <router-link slot="headerLink" :to="{ name: 'test2_path' }" v-if="offersCount > 4" class="header-link">
      {{ $t("general.see_more") }}
    </router-link>
    <b-col>
      <b-row>
        <template v-if="offers.length > 0">
          <b-col class="col-12 offer"
                 v-for="(offer, index) in offers"
                 :key="offer.id"
                 :class="offerClasses(offer, index)">
            <router-link tag="div" :to="{ name: 'test2_path' }">
              <div class="type">
                {{ $t(`general.${offer.offerType}`) }}
              </div>
              <router-link class="title" :to="{ name: 'offers_show_path', params: { id: offer.id } }">
                {{ offer.jobTitle }}
              </router-link>
              <div class="place" v-if="offer.workplace">
                {{ offer.workplace.value }}
              </div>
              <div class="cv-match" v-if="offer.cvMatch">
                {{ $t('components.abk_actual_recrutations_box.cv_match') }}
              </div>
              <div class="expiration-time" v-if="offer.expirationDate">
                {{ timeLeft(offer.expirationDate) }}
              </div>
            </router-link>
          </b-col>
        </template>
        <b-col v-else class="no-offers">
          {{ $t('components.abk_actual_recrutations_box.no_offers') }}
        </b-col>
      </b-row>
    </b-col>
  </pcg-box>
</template>

<script>

export default {
  name: 'abk-actual-recrutations-box',
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    offersCount: {
      type: Number,
      required: true
    }
  },
  methods: {
    timeLeft (expirationTime) {
      const momentDate = moment(expirationTime)
      const daysLeft = momentDate.isValid() ? momentDate.diff({ hours: 0 }, 'days') : -1
      return daysLeft < 0 ? null : this.$tc('components.abk_actual_recrutations_box.days_left', daysLeft)
    },
    offerClasses (offer, index) {
      return {
        'col-sm-6': this.offersCount > 1,
        'show-border-bottom': this.offers.length > 2 && index < 2
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .header-link {
    text-transform: uppercase;
  }

  /deep/ .box {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  .offer {
    padding: 0 25px;
    min-height: 127px;
    @media only screen and (min-width: $screen-sm-min) {
      &:first-child, &:nth-child(3) {
        border-right: 1px solid $pcg-custom-gray;
      }
      &.show-border-bottom {
        border-bottom: 1px solid $pcg-custom-gray;
      }
      &:nth-child(n+3) {
        padding-top: 20px;
      }
      &:nth-child(-n+2) {
        padding-bottom: 15px;
      }
    }
    @media only screen and (max-width: $screen-xs-max) {
      &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid $pcg-custom-gray;
      }
      &:nth-child(n+2) {
        padding-top: 25px;
      }
    }
    > div:hover {
      cursor: pointer;
    }
    .title {
      color: $main-color;
      font-weight: 500;
      text-transform: uppercase;
    }
    .title, .type {
      display: block;
      margin-bottom: 5px;
    }
    .place {
      margin-bottom: 15px;
    }
    .type, .place {
      color: $pcg-dark-gray;
    }
    .cv-match {
      color: $pcg-orange;
      padding-bottom: 7px;
    }
    .expiration-time {
      color: $pcg-light-red;
    }
    .type, .place, .cv-match, .expiration-time {
      font-size: $font-size-xs;
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .title {
      color: $main-color;
    }
    .type, .place {
      color: $pcg-dark-gray;
    }
    .cv-match {
      color: $pcg-orange;
    }
    .expiration-time {
      color: $pcg-light-red;
    }
  }
</style>
