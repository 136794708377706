<template>
  <div style="margin-top: 4rem">
    <abk-page-not-found></abk-page-not-found>
  </div>
</template>

<script>
import AbkPageNotFound from '../components/AbkPageNotFound'
export default {
  name: 'PageNotFound',
  components: { AbkPageNotFound }
}
</script>

<style scoped>

</style>
