<template>
  <b-modal :visible="value"
           @change="$emit('input', $event)"
           footer-class="d-flex justify-content-center abk-modal-footer"
           centered
           content-class="abk-modal-content"
           hide-header
           @hidden="clearData">
    <h2>{{ $t('components.abk_suggest_dictionary_word_modal.title') }}</h2>
    <pcg-text-input autocomplete="off" v-model="word" :show-error="!!this.error" :error-text="formattedError"/>
    <template v-slot:modal-footer="{ cancel }">
      <pcg-btn @click="cancel()" variant="additional" class="mr-3">
        {{ $t('general.cancel') }}
      </pcg-btn>
      <pcg-btn @click="suggestNewWord">{{ $t('general.suggest') }}</pcg-btn>
    </template>
  </b-modal>
</template>

<script>
import ApiDictionaryWords from '../api/dictionary_words'

export default {
  name: 'AbkSuggestDictionaryWordModal',
  props: {
    dictionaryId: [String, Number],
    value: Boolean
  },
  data () {
    return {
      word: null,
      error: null
    }
  },
  methods: {
    suggestNewWord () {
      this.error = null

      ApiDictionaryWords.suggestDictionaryWord(this.dictionaryId, { dictionaryWord: { value: this.word } })
        .then(() => {
          this.$emit('input', !this.value)
          this.$toastr.s(this.$t('components.abk_suggest_dictionary_word_modal.send_for_verification'))
        })
        .catch(error => {
          if (error && error.response && error.response.data) {
            this.error = error.response.data.value
          }
          console.error(error)
        })
    },
    clearData () {
      this.error = null
      this.word = null
    }
  },
  computed: {
    formattedError () {
      return this.error ? this.error.join(', ') : null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  h2 {
    text-align: center;
    margin-bottom: 2em;
    color: $main-color;
  }

  /deep/ .modal-footer {
    padding-top: 3em;
    border: none;
  }
  /deep/ .modal-content {
    border-radius: 20px;
    border: none;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  }
</style>
<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    h2 {
      color: $main-color;
    }
  }
</style>
