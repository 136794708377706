import api from './index'
import { stringify } from 'query-string'
import { decamelize } from '@ridi/object-case-converter'

const resource = 'offers'
const formOfferConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

function getFiltersUrl (filters) {
  let filtersArray = []
  Object.keys(filters).forEach(key => {
    if (Array.isArray(filters[key])) {
      filters[key].forEach(value => {
        filtersArray.push(value ? `${key}[]=${value}` : null)
      })
    } else {
      filtersArray.push(filters[key] ? `${key}=${filters[key]}` : null)
    }
  })
  filtersArray = filtersArray.filter(e => e)
  return filtersArray.length ? `?${filtersArray.join('&')}` : ''
}

export default {
  getOffers (filters) {
    // let location = path || resource
    // return api.get(`${location}`)
    return api.get(`${resource}${getFiltersUrl(filters)}`)
  },
  getOffersCount () {
    return api.get(`${resource}/count`)
  },
  getAllOffers ({ filters }) {
    return api.get(`${resource}/all${getFiltersUrl(filters)}`)
  },
  getOffer (offerId) {
    return api.get(`${resource}/${offerId}`)
  },
  getRecentOffers (count=4) {
    return api.get(`${resource}/recent`, { params: { count: count } })
  },
  getForAcceptanceOffers (count=4) {
    return api.get(`${resource}/for_acceptance`, { params: { count: count } })
  },
  getOtherOffers (offerId, count) {
    return api.get(`${resource}/${offerId}/company_offers`, { params: { count: count } })
  },
  getSimilarOffers ({ offerId, filters }) {
    return api.get(`${resource}/${offerId}/similar_offers${getFiltersUrl(filters)}`)
  },
  getFilters () {
    return api.get(`${resource}/filters`)
  },
  getOfferApplications (offerId, queryParams) {
    return api.get(`${resource}/${offerId}/applications?${stringify(decamelize(queryParams))}`)
  },
  updateValidateStep (payload, offerId) {
    return api.post(`${resource}/${offerId}/validate`, payload, formOfferConfig)
  },
  validateStep (payload) {
    return api.post(`${resource}/validate`, payload, formOfferConfig)
  },
  createOffer (payload) {
    return api.post(`${resource}`, payload, formOfferConfig)
  },
  updateOffer (payload, offerId) {
    return api.patch(`${resource}/${offerId}`, payload, formOfferConfig)
  },
  acceptOffer (offerId) {
    return api.post(`${resource}/${offerId}/accept`)
  },
  rejectOffer (offerId, payload) {
    return api.post(`${resource}/${offerId}/reject`, payload)
  },
  addToFavorites (offerId) {
    return api.post(`${resource}/${offerId}/add_to_favorites`)
  },
  removeFromFavorites (offerId) {
    return api.post(`${resource}/${offerId}/remove_from_favorites`)
  },
  getfavoriteOffers () {
    return api.get(`${resource}/favorites`)
  },
  getOffersInfo () {
    return api.get(`${resource}/info`)
  },
  sendForPublication (offerId) {
    return api.patch(`${resource}/${offerId}/send_for_acceptance`)
  },
  getNotifications () {
    return api.get(`${resource}/notifications`)
  },
  markAllAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  markAllNotificationsAsRead () {
    return api.patch(`${resource}/read_all_notifications`)
  },
  getMatchingToSkill ({ filters, skillId }) {
    return api.get(`${resource}/matching_to_skill/${skillId}${getFiltersUrl(filters)}`)
  },
  getNotMatchingToSkill ({ filters, skillId, competenceId }) {
    return api.get(`${resource}/not_matching_to_skill/${skillId}/${competenceId}${getFiltersUrl(filters)}`)
  },
  finishOffer (offerId) {
    return api.patch(`${resource}/${offerId}/finish`)
  }
}
