<template>
  <div>
    <abk-loader v-if="showLoader"/>
    <template v-else-if="items && items.length > 0">
      <div class="list-header">
        <span :class="['items-column-name', { 'ml-0': hideStatus }]">{{ itemsColumnName }}</span>
        <slot name="header"/>
      </div>
      <ul class="list">
        <li v-for="item in items"
            :key="item.id"
            :class="['list-item', { faded: item.faded, highlighted: item.highlighted }]">
          <div :class="['item-header', { 'ml-0': hideStatus }]">
            <div class="item-attributes">
                    <span v-for="(attribute, index) in item.attributes"
                          :key="`${item.id}-attribute-${index}`"
                          class="item-attribute">
                      {{ attribute }}
                    </span>
            </div>
            {{ item.name }}
            <slot name="title" v-bind="item"/>
          </div>
          <div class="item-content">
            <div class="d-flex flex-nowrap">
              <div v-if="item.status && !hideStatus" class="item-status">
                <i v-if="item.status.mark" :class="['status-icon', statusIcon(item.status.mark)]"/>
                <span v-if="item.status.text" class="status-text d-none d-md-inline">{{ item.status.text }}</span>
              </div>
              <ul class="sub-list">
                <li v-for="sub in item.subs"
                    :key="`${item.id}-${sub.id}`"
                    :class="['sub-list-item', { faded: sub.faded, highlighted: sub.highlighted }]">
                  <div :class="{ 'd-flex align-items-center': !(sub.attributes && sub.attributes.length > 0) }">
                    <div class="item-attributes">
                      <router-link v-if="sub.path" :class="['item-name', { disabled: item.disabledPath }]" :to="sub.path">
                        <span v-for="(attribute, index) in sub.attributes"
                              :key="`sub-${item.id}-${sub.id}-attribute-${index}`"
                              >
                          {{ attribute }}
                        </span>
                      </router-link>
                      &nbsp;
                      <span class="item-attribute">
                        <slot name='sub-event' v-bind="sub"/>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="item-slot-container">
              <slot v-bind="item"/>
            </div>
          </div>
        </li>
      </ul>
    </template>
    <span v-else class="pcg-no-records">{{ noRecordsText }}</span></div>
</template>

<script>
import AbkLoader from './AbkLoader'
export default {
  name: 'AbkListWithSub',
  components: { AbkLoader },
  props: {
    items: Array,
    itemsColumnName: String,
    noRecordsText: String,
    showLoader: Boolean,
    hideStatus: Boolean
  },
  methods: {
    statusIcon (mark) {
      switch (mark) {
        case 'question':
          return 'fas fa-question gray-color'
        case 'times':
          return 'fas fa-times red-color'
        case 'edit':
          return 'pcg-icon-edit gray-color'
        default:
          return 'pcg-icon-check green-color'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/vars";

  $status-container-width: 135px;

  .list-header {
    font-size: $font-size-s;
    font-weight: 500;
    color: $main-color;
    display: flex;
    align-items: flex-end;
  }
  .items-column-name {
    margin-left: $status-container-width;
  }
  .list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sub-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sub-list-item {
    display: flex;
    flex-wrap: wrap;
    &.faded {
      opacity: .5;
    }
    &.highlighted {
      background-color: $pcg-the-lightest-gray;
    }
  }
  .list-item {
    //display: flex;
    padding: 7px 0;
    //flex-wrap: wrap;
    &:not(:last-child) {
      border-bottom: 2px solid $pcg-the-lightest-gray;
    }
    &.faded {
      opacity: .5;
    }
    &.highlighted {
      background-color: $pcg-the-lightest-gray;
    }
  }
  .item-header {
    margin-left: 135px;
    padding: 7px 0;
    font-size: 0.86em;
    font-weight: 700;
    color: $pcg-dark-gray;
    text-transform: uppercase;
    .item-name {
      font-weight: 700;
      font-size: 0.86em;
    }
  }
  .item-content {
    display: flex;
    flex-wrap: wrap;
  }
  .item-status {
    display: flex;
    align-items: center;
    width: $status-container-width;
    padding-right: 10px;
  }
  .status-icon {
    display: inline-block;
    width: 28px;
  }
  .green-color {
    color: $pcg-green-color;
  }
  .gray-color {
    color: $pcg-light-gray;
  }
  .red-color {
    color: $pcg-red;
  }
  .status-text {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-light-gray;
  }
  .item-attributes {
    font-size: $font-size-s;
    font-weight: 500;
    color: $pcg-gray;
  }
  .item-attribute {
    &:not(:last-child) {
      margin-right: $pcg-m-xs;
    }
    button {
      margin: 0;
      font-size: 1.5em;
    }
  }
  .item-name {
    font-size: 1em;
    font-weight: 500;
    color: $main-color;
    text-transform: uppercase;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    &.disabled {
      cursor: not-allowed;
    }
  }
  .item-slot-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  @media (max-width: $screen-md-min) {
    $status-container-width: 30px;

    .items-column-name {
      margin-left: $status-container-width;
    }
    .item-status {
      width: $status-container-width;
    }
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    .list-header {
      color: $main-color;
    }
    .list-item {
      &:hover {
        background-color: $pcg-the-lightest-gray;
      }
      &:not(:last-child) {
        border-bottom: 2px solid $pcg-light-gray;
      }
      &.highlighted {
        background-color: $pcg-the-lightest-gray;
      } }
    .green-color {
      color: $pcg-green-color;
    }
    .gray-color {
      color: $pcg-light-gray;
    }
    .red-color {
      color: $pcg-red;
    }
    .status-text {
      color: $pcg-light-gray;
    }
    .item-attributes {
      color: $pcg-gray;
    }
    .item-name {
      color: $main-color;
    }
  }
</style>
