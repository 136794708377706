<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex justify-content-between">
      <span><i class="pcg-icon-calendar-date"/>{{ $t('components.events.abk_new_events_box.title') }}</span>
      <router-link v-if="events && (totalEvents > events.length)"
                   class="pcg-link pcg-small"
                   :to="{ name: 'events_list_pending_tab_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <abk-list :items="events"
              :no-records-text="$t('components.events.abk_new_events_box.no_records')"
              :items-column-name="$t('general.event')">
      <template v-slot="event">
        <pcg-btn-symbol v-if="waitingForAcceptance(event.status.value)"
                        class="ml-auto my-0"
                        variant="success"
                        icon-class="pcg-icon-check-alt"
                        :tooltip="$t('general.accept')"
                        @click="$emit('acceptEvent', event.id)"/>
      </template>
    </abk-list>
  </pcg-box>
</template>

<script>
import AbkList from '../AbkList'

export default {
  name: 'AbkNewEventsBox',
  props: {
    events: Array,
    totalEvents: Number
  },
  components: { AbkList },
  methods: {
    waitingForAcceptance (status) {
      return ['recent', 'for_acceptance'].includes(status)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .events-list {
    margin-left: -$pcg-box-padding-x;
    margin-right: -$pcg-box-padding-x;
    padding: 0;
  }

  .event-item {
    padding-left: $pcg-box-padding-x;
    padding-right: $pcg-box-padding-x;
  }
</style>
