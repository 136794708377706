<template>
  <div class="row align-items-center">
    <b-col class="col-auto align-self-center">
      <span class="icon-container">
        <component :is="`abk-survey-${value.fieldType}-svg`"></component>
      </span>
    </b-col>
    <pcg-text-input class="col"
                    :label="$t('components.surveys.abk_survey_question_field_form.labels.label')"
                    :error-text="getError('label')"
                    :show-error="errorExist('label')"
                    autocomplete="off"
                    required
                    :value="value.label"
                    @input="updateForm('label', $event)"/>
    <pcg-text-input v-if="isRadioOrCheckbox"
                    class="col"
                    :label="$t('components.surveys.abk_survey_question_field_form.labels.value')"
                    :error-text="getError('value')"
                    :show-error="errorExist('value')"
                    autocomplete="off"
                    required
                    :value="value.value"
                    @input="updateForm('value', $event)"/>
    <b-col class="col-auto" v-if="isRadioOrCheckboxOrOpenAnswer">
      <button type="button" class="close" aria-label="Close" @click="remove">
        <span aria-hidden="true">✕</span>
      </button>
    </b-col>
  </div>
</template>

<script>
import AbkSurveyCheckboxSvg from '../icons/AbkSurveyCheckboxSvg'
import AbkSurveySectionSvg from '../icons/AbkSurveySectionSvg'
import AbkSurveyNumberSvg from '../icons/AbkSurveyNumberSvg'
import AbkSurveyRadiobuttonSvg from '../icons/AbkSurveyRadiobuttonSvg'
import AbkSurveySeparatorSvg from '../icons/AbkSurveySeparatorSvg'
import AbkSurveyTextSvg from '../icons/AbkSurveyTextSvg'

export default {
  name: 'AbkSurveyQuestionFieldForm',
  components: {
    AbkSurveyTextSvg,
    AbkSurveySeparatorSvg,
    AbkSurveyRadiobuttonSvg,
    AbkSurveyNumberSvg,
    AbkSurveySectionSvg,
    AbkSurveyCheckboxSvg
  },
  props: {
    value: Object,
    errors: Object,
    questionType: String,
    parentErrorPath: String
  },
  computed: {
    isRadioOrCheckbox () {
      return this.value.fieldType === 'radiobutton' || this.value.fieldType === 'checkbox'
    },
    isRadioOrCheckboxOrOpenAnswer () {
      return this.questionType === 'checkbox' || this.questionType === 'radiobutton'
    },
    errorPath () {
      return `${this.parentErrorPath}.${this.value.id || this.value.tmpId}`
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (!oldValue.edited) {
        this.updateForm('edited', true)
      }
    }
  },
  methods: {
    updateForm (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    errorExist (field) {
      return !!(this.errors && this.errors[`${this.errorPath}.${field}`])
    },
    getError (field) {
      if (this.errorExist(field)) {
        this.$emit('open')
        return this.errors[`${this.errorPath}.${field}`].join(', ')
      }
      return ''
    },
    remove () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/stylesheets/vars";

  .close {
    font-size: $pcg-m-md;
    float: none;
    padding: 0.3rem 0.7rem;
    margin-top: 10px;
  }
  .icon-container {
    width: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
</style>
