<template>
  <div>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">

          <pcg-text-input class="abk-input col-12"
                          :label="$t('views.dictionary_acceptance_words.labels.name')"
                          :error-text="getError('name')"
                          :show-error="errorExist('name')"
                          v-model="dictionaryWord.name"/>
          <pcg-text-input class="abk-input col-12" type="textarea"
                          :required="true"
                          :label="$t('views.dictionary_acceptance_words.labels.value')"
                          :error-text="getError('valuePl')"
                          :show-error="errorExist('valuePl')"
                          v-model="dictionaryWord.valuePl"/>
          <pcg-text-input class="abk-input col-12" type="textarea"
                          :label="$t('views.dictionary_acceptance_words.labels.value_en')"
                          :error-text="getError('valueEn')"
                          :show-error="errorExist('valueEn')"
                          v-model="dictionaryWord.valueEn"/>
          <pcg-checkbox class="col-12"
                        :error-text="getError('status')"
                        :show-error="errorExist('status')"
                        v-model="dictionaryWord.statusBoolean">
            {{$t('views.dictionary_acceptance_words.labels.status')}}
          </pcg-checkbox>
          <pcg-select :options="wordTypesOptions"
                      :error-text="getError('wordType')"
                      :show-error="errorExist('wordType')"
                      :label="$t('views.dictionary_acceptance_words.labels.word_type')"
                      :default-option="{ text: this.$t('views.dictionary_acceptance_words.word_types.null'), value: null }"
                      v-model="dictionaryWord.wordType"/>
          <pcg-select :options="acceptanceTypesOptions"
                      :error-text="getError('acceptanceType')"
                      :show-error="errorExist('acceptanceType')"
                      :label="$t('views.dictionary_acceptance_words.labels.acceptance_type')"
                      :default-option="{ text: this.$t('views.dictionary_acceptance_words.acceptance_types.null'), value: null }"
                      v-model="dictionaryWord.acceptanceType"/>
          <pcg-checkbox class="col-12"
                        :error-text="getError('registration')"
                        :show-error="errorExist('registration')"
                        v-model="dictionaryWord.registration">
            {{$t('views.dictionary_acceptance_words.labels.registration')}}
          </pcg-checkbox>
          <pcg-checkbox class="col-12"
                        :error-text="getError('registrationRequired')"
                        :show-error="errorExist('registrationRequired')"
                        v-model="dictionaryWord.registrationRequired">
            {{$t('views.dictionary_acceptance_words.labels.registration_required')}}
          </pcg-checkbox>
          <pcg-checkbox class="col-12"
                        :error-text="getError('blockedOnProfile')"
                        :show-error="errorExist('blockedOnProfile')"
                        v-model="dictionaryWord.blockedOnProfile">
            {{$t('views.dictionary_acceptance_words.labels.blocked_on_profile')}}
          </pcg-checkbox>
          <pcg-btn v-if="dictionaryWord"
                   size="small"
                   variant="additional"
                   class="action-btn"
                   @click="updateDictionaryAcceptanceWord()">
            {{ $t('views.common.save') }}
          </pcg-btn>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ApiDictionaryAcceptanceWords from '../../api/dictionary_acceptance_words'
export default {
  name: 'DictionaryAcceptanceWordsEdit',
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      id: this.$route.params.id,
      errors: {},
      dictionaryWord: {
        valuePl: null,
        valueEn: null,
        status: null,
        statusBoolean: null,
        wordType: null,
        registration: false,
        registrationRequired: false,
        acceptanceType: null,
        blockedOnProfile: false
      },
      wordTypesOptions: [
        { text: this.$t('views.dictionary_acceptance_words.word_types.null'), value: '' },
        { text: this.$t('views.dictionary_acceptance_words.word_types.rodo'), value: 'rodo' },
        { text: this.$t('views.dictionary_acceptance_words.word_types.clause'), value: 'clause' },
        { text: this.$t('views.dictionary_acceptance_words.word_types.profiling'), value: 'profiling' }
      ],
      acceptanceTypesOptions: [
        { text: this.$t('views.dictionary_acceptance_words.acceptance_types.null'), value: '' },
        { text: this.$t('views.dictionary_acceptance_words.acceptance_types.allow_emails'), value: 'allow_emails' },
        { text: this.$t('views.dictionary_acceptance_words.acceptance_types.marketing_emails'), value: 'marketing_emails' }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.getDictionaryAcceptanceWord()
  },
  methods: {
    getDictionaryAcceptanceWord () {
      ApiDictionaryAcceptanceWords.getDictionaryAcceptanceWord(this.dictionaryId, this.id)
        .then(response => {
          // console.log(JSON.stringify(response.data.data))
          this.dictionaryWord = response.data.data.attributes
          if (this.dictionaryWord.status === 'required') {
            this.dictionaryWord.statusBoolean = true
          } else {
            this.dictionaryWord.statusBoolean = false
          }
          this.$store.dispatch('header/setObjectName', this.dictionaryWord.name)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptance_words.edit.title'),
        subtitle: this.$t('views.dictionary_acceptance_words.edit.subtitle')
      })
    },
    updateDictionaryAcceptanceWord () {
      this.loading = true
      const dictionaryWordDup = this.dictionaryWord
      if (this.dictionaryWord.statusBoolean) {
        dictionaryWordDup.status = 'required'
      } else {
        dictionaryWordDup.status = 'optional'
      }
      ApiDictionaryAcceptanceWords.updateDictionaryAcceptanceWord(this.dictionaryId, this.id,
        { dictionaryAcceptanceWord: dictionaryWordDup })
        .then(() => {
          this.errors = {}
          this.getDictionaryAcceptanceWord()
          this.$toastr.s(this.$t('general.dictionary_word_updated'))
          this.$router.go(-1)
        }).catch(error => {
          this.errors = error.response.data
          this.$toastr.e(this.$t('general.check_errors'))
        }).finally(() => {
          this.loading = false
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars';
/deep/ .dropdown-menu {
  overflow: auto;
  max-height: 300px;
}
</style>
