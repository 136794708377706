<template>
<div>
  <abk-simple-messages-new v-if="!showCareerOfficeActions" class="mb-5" @messageSent="getMessages"/>
  <b-row class="top-bar">
    <b-col cols="12" class="top-bar-content">
      <span class="filters-text">{{ $t('views.simple_messages.simple_messages_index.filter_messages') }}</span>
      <div class="d-flex flex-wrap">
        <pcg-select :options="statusOptions"
                    :default-option="{ text: this.$t('general.status'), value: null }"
                    class="filter-select"
                    v-model="filters.status"
                    @input="getMessagesAndResetPagination"/>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <abk-loader v-if="showLoader" class="with-margins"/>
    <template v-else-if="messages.length">
      <abk-message v-for="message in messages"
                   class="col-12 message"
                   :title="message.title"
                   :highlighted="message.unread"
                   :key="message.id"
                   :sender-name="message.senderName"
                   :sender-company="message.companyName"
                   :sender-avatar="message.senderAvatar"
                   :sender-company-img="message.companyLogo"
                   :date="message.date"
                   :message="message.content"
                   :user-profile-path="message.userProfilePath"
                   :user-profile-id="message.userProfileId"
                   :thread-path="'simple_messages_show_path'"
                   :thread-id="message.parentId"
                   :important="message.important"
                   :company="message.company"
                   :is-deleted="message.isDeleted"
                   @messageClick="goToThread(message)">
        {{ message.content }}
      </abk-message>
    </template>
    <pcg-box v-else>
      <span class="pcg-no-records">{{ $t('views.simple_messages.simple_messages_index.no_records') }}</span>
    </pcg-box>
  </b-row>
  <pcg-pagination v-model="pagination.currentPage"
                  @input="getMessages"
                  :page-count="pagination.pages"
                  :total-records="pagination.totalRecords"
                  class="col-12 justify-content-center my-pagination"/>
</div>
</template>

<script>
import AbkMessage from '../../components/AbkMessage'
import ApiSimpleMessages from '../../api/simple_messages'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { mapGetters } from 'vuex'
import AbkSimpleMessagesNew from '../../components/simple_messages/AbkSimpleMessagesNew'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  name: 'SimpleMessagesIndex',
  components: { AbkLoader, AbkSimpleMessagesNew, AbkMessage },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      messages: [],
      filters: {},
      statuses: ['recent', 'read'],
      statusOptions: []
    }
  },
  computed: {
    ...mapGetters('auth', ['roles', 'currentRole']),
    hasCareerOfficeRole () {
      return this.roles.includes('career_office')
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    }
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.simple_messages.simple_messages_index.title')
      })
    },
    getMessages () {
      ApiSimpleMessages.getSimpleMessages({ ...this.filters, page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.messages = response.data.data.map(message => {
            const user = response.data.included.find(inclusion => inclusion.id === message.relationships.user.data.id)
            const avatar = user.attributes.avatar
            return {
              id: message.id,
              title: message.attributes.title,
              content: message.attributes.content,
              date: message.attributes.createdAt,
              senderAvatar: avatar && avatar.thumb ? avatar.thumb.url : (avatar ? avatar.url : null),
              senderName: `${user.attributes.firstName} ${user.attributes.lastName}`,
              company: message.attributes.company,
              isDeleted: user.attributes.isDeleted,
              companyName: user.attributes.roles
                .filter((role, _, roles) => role !== 'graduate' || !roles.includes('student'))
                .map(role => _.capitalize(this.$t(`general.roles.${role}`))).join(', '),
              unread: this.hasCareerOfficeRole ? message.attributes.status === 'recent' : false,
              userProfilePath: user.attributes.companyId ? 'company_show_path' : 'curriculum_vitae_show_path',
              userProfileId: user.attributes.companyId || user.attributes.curriculumVitaeId,
              parentId: message.attributes.parentId || message.id
            }
          })
        }).catch(error => {
          console.error(error)
        }).finally(() => {
          this.loadingDone = true
        })
    },
    getMessagesAndResetPagination () {
      this.resetPaginationPage()
      this.getMessages()
    },
    goToThread (message) {
      this.markAsRead(message)
      this.$router.push({ name: 'simple_messages_show_path', params: { id: message.parentId } })
    },
    markAsRead (message) {
      if (!message.unread) { return }

      ApiSimpleMessages.markAsRead(message.id)
        .then(() => {
          message.unread = false
          this.$store.dispatch('notifications/getMessages')
        })
        .catch(error => {
          console.error(error)
        })
    },
    initializeStatusOptions () {
      this.statusOptions = this.statuses.map(status => {
        return { text: this.$t(`views.simple_messages.statuses.${status}`), value: status }
      })
      this.statusOptions.push({ text: this.$t('views.simple_messages.statuses.all'), value: '' })
    }
  },
  mounted () {
    if (Vue.$cookies.get('locale')) {
      this.$i18n.locale = Vue.$cookies.get('locale')
    }
    this.getMessages()
    this.initializeStatusOptions()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .message {
    cursor: pointer;
  }

  .top-bar {
    margin-bottom: $pcg-m-sm;
  }
  .top-bar-content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .filters-text {
    white-space: nowrap;
    color: $pcg-gray;
    font-weight: 500;
    margin-right: $pcg-m-sm;
  }
  .filter-select {
    margin: $pcg-m-sm $pcg-m-sm;
    width: 220px;
  }
  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .filters-text {
    color: $pcg-gray;
  }
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
