<template>
  <pcg-box>
    <div class="pcg-box-inner-header d-flex">
      <span><i class="pcg-icon-list"></i>{{ $t('components.surveys.abk_surveys_box.title') }}</span>
      <div class="d-none d-sm-inline-block">
        <pcg-sort-btn class="add-button" :options="sortOptions" :value="order" @input="$emit('changeOrder', $event)"/>
      </div>
      <router-link v-if="surveys && totalSurveys > surveys.length"
                   class="pcg-link pcg-small ml-auto"
                   :to="{ name: 'surveys_list_recent_tab_path' }">
        {{ $t('general.show_more') }}
      </router-link>
    </div>
    <div class="all-records-text">{{ $t('components.surveys.abk_surveys_box.surveys_count') }}: {{ totalSurveys }}</div>
    <abk-list :items="surveys"
              :items-column-name="$t('general.survey')"
              :no-records-text="$t('components.surveys.abk_surveys_box.no_records')"
              hide-status/>
  </pcg-box>
</template>

<script>
import AbkList from '../AbkList'
export default {
  name: 'AbkSurveysBox',
  components: { AbkList },
  props: {
    surveys: Array,
    totalSurveys: Number,
    order: Object
  },
  computed: {
    sortOptions () {
      return [
        {
          text: this.$t('components.surveys.abk_surveys_box.sort_options.initial_sent_date'),
          value: 'initial_sent_date'
        },
        {
          text: this.$t('components.surveys.abk_surveys_box.sort_options.survey_name'),
          value: 'surveys.name'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .add-button {
    margin-left: 2rem;
  }
  .all-records-text {
    font-weight: 500;
    color: $pcg-gray;
    font-size: $font-size-s;
    margin-bottom: $pcg-m-xs;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .all-records-text {
      color: $pcg-gray;
    }
    .add-button {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
  }
</style>
