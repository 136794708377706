<template>
  <b-col cols="12">
    <b-row>
      <pcg-search class="col-12 col-xl-3"
                  :placeholder="$t('general.search_word')"
                  v-model="wordPattern"
                  @searchEvent="getDictionaryWords"/>
      <abk-loader v-if="showLoader" class="with-margins"/>
      <pcg-table v-else-if="dictionaryWords.length > 0" :items="dictionaryWords" :fields="fields">
        <template v-slot:header>{{$t('views.dictionary_words.index.table_header')}}</template>
        <template v-slot:cell(actions)="{ item }">
          <div class="d-flex align-items-center">
            <pcg-btn-symbol v-if="item.status !== 'accepted'"
                            class="my-0"
                            variant="success"
                            icon-class="pcg-icon-check-alt"
                            :tooltip="$t('general.accept')"
                            @click="changeStatusWithConfirmation(item.id, 'accept')"/>
            <pcg-btn-symbol v-if="item.status !== 'rejected'"
                            class="my-0"
                            variant="danger"
                            icon-class="pcg-icon-times"
                            :tooltip="$t('general.reject')"
                            @click="changeStatusWithConfirmation(item.id, 'reject')"/>
            <pcg-btn-symbol icon-class="pcg-icon-edit-alt"
                            class="my-0"
                            :tooltip="$t('general.edit')"
                            @click="goToEditPath(item.id)"/>
          </div>
        </template>
      </pcg-table>
      <pcg-box v-else class="col-12">
        <span class="pcg-no-records">{{ $t('views.dictionary_words.index.no_records') }}</span>
      </pcg-box>
    </b-row>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getDictionaryWords"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </b-col>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import AbkLoader from '../../components/AbkLoader'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'DictionaryWordsIndex',
  components: { AbkLoader },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      dictionaryWords: [],
      fields: [],
      wordPattern: null
    }
  },
  props: ['status'],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.getDictionaryWords()
  },
  methods: {
    goToEditPath (dictionaryWordId) {
      this.$router.push({
        name: 'dictionary_words_edit_path',
        params: {
          dictionaryId: this.dictionaryId,
          id: dictionaryWordId
        }
      })
    },
    getDictionaryWords () {
      ApiDictionaryWords.getDictionaryWords(this.dictionaryId, this.status, this.pagination.currentPage, this.wordPattern)
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaryWords = this.dataTable(response.data.data)
          this.$store.dispatch('header/setObjectName', response.data.meta.dictionaryName)
        })
        .catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    changeStatusWithConfirmation (dictionaryWordId, status) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${status}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          this.updateDictionaryWord(dictionaryWordId, `${status}ed`)
        }
      })
    },
    updateDictionaryWord (dictionaryWordId, status) {
      this.loading = true
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, dictionaryWordId, { dictionaryWord: { status: status } })
        .then(response => {
          this.getDictionaryWords()
          this.errors = {}
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
          this.$emit('wordsChanged')
        })
        .catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_words.index.title'),
        subtitle: this.$t('views.dictionary_words.index.subtitle')
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        result.push({ id: dictionary.id, ...attributes })
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'status') {
              if (!this.status) {
                fields.push({
                  key: key,
                  label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`),
                  formatter: value => {
                    return this.$t(`views.dictionary_words.statuses.${value}`)
                  }
                })
              }
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_words.labels.actions')
      })
      return fields
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
