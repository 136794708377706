<template>
<div>
  <div class="pcg-section-title">{{ title }}</div>
  <table>
    <tr v-for="record in records" :key="record.name">
      <td class="record-name">{{ record.name }}</td>
      <td class="record-value">
        <template v-if="Array.isArray(record.value)">
          <div v-for="value in record.value" :key="value">{{ value }}</div>
        </template>
        <template v-else>{{ record.value }}</template>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
export default {
  name: 'AbkFormSummaryTable',
  props: {
    title: String,
    records: Array
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/stylesheets/vars";

  td {
    vertical-align: top;
    padding-bottom: $pcg-m-lg;
  }
  .record-name {
    color: $pcg-gray;
    font-weight: 500;
    width: 150px;
  }
  .record-value {
    color: $main-color;
    font-weight: 500;
    overflow: hidden;
  }
</style>

<style scoped lang="scss">
@import "../../../assets/stylesheets/vars-contrast";
.contrast{
  .record-name {
    color: $pcg-gray;
  }
  .record-value {
    color: $main-color;
  }
}
</style>
