<template>
  <pcg-box class="position-relative">
    <abk-loader v-if="showLoader"/>
    <template v-else-if="isAnyRecord">
      <div class="d-flex justify-content-between">
        <div class="d-flex header">
          <span class="column-header" :class="{ 'ml-0': hideStatusIcon }">{{ numberColumnTitle }}</span>
          <span class="column-header ml-0">{{ elementsColumnTitle }}</span>
          <pcg-sort-btn class="sort-btn w-auto my-btn"
                        :value="{ orderBy: null, orderDirection: orderDirection}"
                        @input="changeSortDirection"/>
          <slot name="header"/>
        </div>
      </div>
      <ul class="p-0 m-0">
        <abk-list-item v-for="(record, index) in records"
                       :show-record-path="showRecordPath || dynamicShowRecordPath(record)"
                       :index="index + 1"
                       :key="record.id"
                       :record="record"
                       :highlighted="showCareerOfficeActions && record.status === 'for_acceptance'"
                       :hide-status-icon="hideStatusIcon"
                       fixed-height>
          <slot v-bind="record"/>
        </abk-list-item>
      </ul>
    </template>
    <span v-else class="pcg-no-records">{{ noRecordsText }}</span>
  </pcg-box>
</template>

<script>
import AbkListItem from './AbkListItem'
import { mapGetters } from 'vuex'
import AbkLoader from './AbkLoader'

export default {
  name: 'abk-records-list',
  props: {
    records: {
      type: Array,
      default: () => []
    },
    orderDirection: String,
    numberColumnTitle: String,
    elementsColumnTitle: String,
    noRecordsText: String,
    showRecordPath: String,
    dynamicShowRecordPath: Function,
    hideStatusIcon: Boolean,
    showLoader: Boolean
  },
  components: { AbkLoader, AbkListItem },
  computed: {
    ...mapGetters('auth', ['roles']),
    isAnyRecord () {
      return this.records && this.records.length
    },
    showEmployerActions () {
      return this.roles.includes('employer') || this.roles.includes('recruiter')
    },
    showCareerOfficeActions () {
      return this.roles.includes('career_office')
    }
  },
  methods: {
    waitingForAcceptance (status) {
      return ['recent', 'for_acceptance'].includes(status)
    },
    changeSortDirection ({ orderDirection }) {
      this.$emit('changeOrderDirection', orderDirection)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

  .header {
    color: $main-color;
    font-size: $font-size-s;
    font-weight: 400;
    width: 100%;
  }
  .column-header {
    margin-left: 1.7rem;
    margin-right: .8rem;
  }
  .my-btn{
    font-size: $font-size-s;
    margin-right: .5rem;
  }
  .one-row {
    flex-wrap: wrap;
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .header {
      color: $main-color;
    }
    .download {
      color: $pcg-dark-gray;
    }
    .check-file {
      color: $pcg-dark-gray;
    }
    .sort-btn {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
  }
</style>
