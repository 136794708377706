<template>
  <b-row>
    <b-col class="col-12 pl-4 pl-xl-5 mb-3">
      <label class="attachment-input" @click="addNewAttachment">
        <i class="pcg-icon-clip mr-3"></i> {{ $t('components.abk_attachment_input.text') }}
      </label>
    </b-col>
    <template v-for="(attachment, index) in attachments">
      <b-col v-if="!attachment._destroy" :class="tileClass" :key="attachment.key || attachment.id">
        <div class="attachment-container" ref="attachmentContainer">
          <abk-file-input show-remove-button
                          :file-url="attachment.filename"
                          @remove="removeAttachment(attachment, index)"
                          @input="handleFile(attachment, $event, index)"
                          :status="fileUploadStatus(index)"
                          v-b-tooltip.hover
                          :title="getError(index)"
                          :error-text="getError(index)"/>
        </div>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import AbkFileInput from './AbkFileInput'
export default {
  name: 'AbkAttachments',
  props: {
    value: Array,
    errors: Array,
    tileClass: {
      type: String,
      default: 'col-12 col-md-6 col-xl-4'
    }
  },
  components: { AbkFileInput },
  data () {
    return {
      attachments: this.value || [],
      attachmentsErrors: this.errors || []
    }
  },
  watch: {
    errors (newValue) {
      this.attachmentsErrors = newValue
    }
  },
  computed: {
    maxKey () {
      return Math.max(...this.attachments.map((attachment) => attachment.key ? attachment.key : 0), 0)
    }
  },
  methods: {
    generateKey () {
      return this.maxKey + 1
    },
    updateForm (value) {
      this.attachments = value
      this.$emit('input', this.attachments)
    },
    addNewAttachment () {
      this.updateForm([...this.attachments, {
        id: null,
        attachment: null,
        key: this.generateKey()
      }])
    },
    handleFile (attachment, file, index) {
      if (file.size > 4200000) {
        this.attachmentsErrors[index] = [this.$t('components.abk_new_page_form.max_size')]
        attachment.filename = null
        attachment.attachment = null
        this.$forceUpdate()
      } else {
        attachment.filename = null
        attachment.attachment = file
        this.updateForm(this.attachments)
        if (this.errorExist(index)) {
          this.removeError(index)
        }
      }
    },
    fileUploadStatus (index) {
      const { attachment, filename } = this.attachments[index]
      if (this.errorExist(index)) {
        return 'fail'
      } else if (attachment || filename) {
        return 'success'
      }
      return 'upload'
    },
    getError (index) {
      return this.errorExist(index) ? this.attachmentsErrors[index].join(', ') : null
    },
    errorExist (index) {
      return !!(this.attachmentsErrors && this.attachmentsErrors[index])
    },
    removeAttachment (attachment, index) {
      const copy = [...this.attachments]

      if (attachment.id) {
        copy[index]._destroy = true
        this.updateForm(copy)
      } else {
        copy.splice(index, 1)
        this.updateForm(copy)
        if (this.errorExist(index)) {
          this.removeError(index)
        }
        this.attachments.forEach((attachment, attachmentIndex) => {
          if (attachmentIndex > index && this.errorExist(attachmentIndex)) {
            this.attachmentsErrors[attachmentIndex - 1] = this.attachmentsErrors[attachmentIndex]
            this.removeError(attachmentIndex)
          }
        })
      }
    },
    removeError (index) {
      this.$set(this.attachmentsErrors, index, null)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .attachment-input {
    color: $main-active-color;
    font-weight: 500;
    cursor: pointer;
  }
  .attachment-container {
    display: flex;
  }
  .close {
    margin-left: 10px;
    max-height: 21px;
    user-select: none;
    float: none;
    &:hover {
       cursor: pointer;
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .attachment-input {
      color: $main-active-color;
    }
  }
</style>
