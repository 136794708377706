<template>
  <div>
    <b-col cols="12" class="box mt-3">
      <b-row class="box-header">
        <b-col cols="12" class="d-flex mb-4">
          <h2 class="title">{{ $t('components.abk_gallery_section_form.title') }}</h2>
          <button class="section-btn small" @click="removeGallery">✕ {{ $t('general.delete') }}</button>
        </b-col>
        <pcg-text-input class="col-12 col-xl-6 m-0"
                        v-model="value.name"
                        :show-error="errorExist('name')"
                        :error-text="getError('name')"
                        :label="$t('components.abk_gallery_section_form.title_placeholder')"/>
      </b-row>
      <transition-group name="slide" type="out-in" class="subsection row">
        <b-col cols="12" class="d-flex mb-4" key="subsection-header">
          <h2>{{ $t('components.abk_gallery_section_form.subtitle') }}</h2>
          <button class="section-btn small" @click="addImage">+ {{ $t('components.abk_gallery_section_form.add_image') }}</button>
        </b-col>
        <template v-for="(image, index) in value.companySubsections">
          <div v-if="!image._destroy" :key="image.key" class="col-12 col-sm-6 col-lg-4 col-xl-3">
            <abk-file-input show-remove-button
                            :accept="acceptableExtensions"
                            :error-text="getImageError(index)"
                            v-b-tooltip.hover
                            :title="getImageError(index)"
                            :file-url="getImageUrl(index)"
                            @input="updateImage(index, $event)"
                            @remove="removeImage(index)"
                            :status="fileInputStatus(index)"/>
          </div>
        </template>
      </transition-group>
    </b-col>
  </div>
</template>

<script>
import AbkFileInput from './AbkFileInput'

export default {
  name: 'abk-gallery-section-form',
  components: { AbkFileInput },
  props: {
    value: Object,
    errors: Object,
    sectionIndex: [Number, String]
  },
  data () {
    return {
      maxImages: 12,
      acceptableExtensions: '.jpg,.jpeg,.gif,.png'
    }
  },
  methods: {
    updateImage (index, value) {
      this.value.companySubsections[index].attachment = value
      this.errors[this.getImageErrorKey(index)] = null
      const copy = { ...this.value }
      this.$emit('input', copy)
    },
    addImage () {
      if (this.value.companySubsections.length < this.maxImages) {
        const imageKeys = this.value.companySubsections.map(image => image.key)
        const newKey = imageKeys.length > 0 ? Math.max(...imageKeys) + 1 : 0
        this.value.companySubsections.push({ key: newKey, attachment: null })
      }
    },
    removeImage (index) {
      const subsection = this.value.companySubsections[index]
      if (subsection.id) {
        subsection._destroy = true
      } else {
        this.value.companySubsections.splice(index, 1)
      }
      this.$emit('input', { ...this.value })
    },
    removeGallery () {
      this.$emit('remove')
    },
    fileInputStatus (index) {
      const image = this.value.companySubsections[index]

      if (this.getImageError(index)) {
        return 'fail'
      } else if (image.attachment) {
        return 'success'
      }
      return 'upload'
    },
    getImageUrl (index) {
      const attachment = this.value.companySubsections[index].attachment
      return attachment ? attachment.url : null
    },
    getImageError (index) {
      const error = this.errors[this.getImageErrorKey(index)]
      return error ? error.join(', ') : null
    },
    getImageErrorKey (index) {
      return `companySections.${this.sectionIndex}.companySubsections.${index}.attachment`
    },
    getError (field) {
      return this.errorExist(field) ? this.errors[`companySections.${this.sectionIndex}.${field}`].join(', ') : null
    },
    errorExist (field) {
      return !!this.errors[`companySections.${this.sectionIndex}.${field}`]
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  h2 {
    font-size: 1em;
    color: $pcg-gray;
    width: fit-content;
    margin: 0 1em 0 0;
  }
  .title {
    text-transform: uppercase;
  }
  .box {
    border: 2px dashed $pcg-light-gray;
    border-radius: 15px;
  }
  .section-btn {
    background: none;
    border: none;
    color: $main-active-color;
    padding: 0;
    margin: 0;
    outline: none;
    &.remove-subsection-btn {
      height: fit-content;
      position: absolute;
      top: 12px;
      bottom: 0;
      right: -53px;
      margin: 0 auto 10px;
    }
    &.small {
      font-size: $font-size-s;
    }
  }
  .box-header {
    padding: 30px 25px;
  }
  .subsection {
    border-top: 2px solid $pcg-lightest-gray;
    padding: $pcg-m-xl 25px $pcg-m-sm;
  }
  .content-textarea {
    height: 100%;
    /deep/ textarea {
      height: 100%;
    }
  }
  @media (max-width: $screen-md-min) {
    .box-header, .subsection {
      padding: 20px 5px;
    }
  }

  @media (max-width: $screen-xlg-min) {
    .subsection {
      padding-top: 40px;
    }
    .remove-subsection-btn {
      top: -30px!important;
      right: 0!important;
    }
  }

  .images {
    padding: 0 25px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    h2 {
      color: $pcg-gray;
    }
    .box {
      border: 2px dashed $pcg-light-gray;
    }
    .section-btn {
      color: $main-active-color;
    }
    .subsection {
      border-top: 2px solid $pcg-lightest-gray;
    }
  }
</style>
