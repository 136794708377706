<template>
  <b-tab title="Oferta">
    <offers-show/>
  </b-tab>
</template>

<script>
import OffersShow from '../../../views/offers/OffersShow'

export default {
  name: 'AbkOfferTab',
  components: { OffersShow }
}
</script>

<style scoped lang="scss">
  /*@import "../../../assets/stylesheets/vars";*/

</style>
