<template>
  <b-row>
    <abk-loader v-if="showLoader" class="col-12 with-margins"/>
    <template v-else>
      <abk-new-offers-box class="col-12 col-xl-6"
                          :offers="newOffers"
                          :total-offers="totalOffers"
                          @acceptOffer="acceptOffer"/>
      <abk-new-events-box class="col-12 col-xl-6"
                          :events="newEvents"
                          :total-events="totalEvents"
                          @acceptEvent="acceptEvent"/>
      <abk-messages-box class="col-12 col-xl-6"
                        :messages="messages"
                        :total-messages="totalMessages"
                        link-path="simple_messages_show_path"/>
      <!--<abk-paired-offers-box class="col-12 col-xl-6" :offers="pairedOffers" :total-offers="5"/>-->
      <abk-new-employers-box class="col-12 col-xl-6"
                             @acceptEmployer="acceptEmployer"
                             :employers="employers"
                             :total-employers="totalEmployers"/>
      <abk-popular-searches-box class="col-12 col-xl-6"
                                :searches="searches"
                                :total-searches="totalCountedSearchPhrases"/>
    </template>
  </b-row>
</template>

<script>
import AbkNewOffersBox from '../components/AbkNewOffersBox'
import AbkMessagesBox from '../components/AbkMessagesBox'
import AbkPopularSearchesBox from '../components/AbkPopularSearchesBox'
import ApiCountedSearchPhrases from '../api/counted_search_phrases'
import ApiSimpleMessages from '../api/simple_messages'
import ApiOffers from '../api/offers'
import ApiCompanies from '../api/companies'
import ApiEvents from '../api/events'
import AbkNewEmployersBox from '../components/AbkNewEmployersBox'
import AbkLoader from '../components/AbkLoader'
import LoaderDelayMixin from '../mixins/loader_delay'
import AbkNewEventsBox from '../components/events/AbkNewEventsBox'

export default {
  name: 'CareerOfficeDashboard',
  components: {
    AbkNewEventsBox,
    AbkLoader,
    AbkNewEmployersBox,
    AbkPopularSearchesBox,
    AbkMessagesBox,
    AbkNewOffersBox
  },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      newOffers: [],
      messages: [],
      employers: [],
      searches: [],
      newEvents: [],
      totalMessages: 0,
      totalOffers: 0,
      totalCountedSearchPhrases: 0,
      totalEmployers: 0,
      totalEvents: 0,
      filters: {
        order_direction: 'desc',
        order_by: 'publication_date',
        status: 'for_acceptance'
      },
      offersLoaded: false,
      messagesLoaded: false,
      companiesLoaded: false,
      eventsLoaded: false,
      countedSearchPhrasesLoaded: false,
      markPerOfferStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question'
      },
      markPerEventStatus: {
        draft: 'edit',
        recent: 'question',
        for_acceptance: 'question',
        accepted: 'check',
        rejected: 'times',
        finished: 'question'
      }
    }
  },
  computed: {
    loadingDone () {
      return this.offersLoaded &&
        this.messagesLoaded &&
        this.companiesLoaded &&
        this.countedSearchPhrasesLoaded &&
        this.eventsLoaded
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  mounted () {
    this.getOffers()
    this.getEvents()
    this.getMessages()
    this.getCompanies()
    this.loadCountedSearchPhrases()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.admin_dashboard.title'),
        subtitle: this.$t('views.admin_dashboard.subtitle')
      })
    },
    loadCountedSearchPhrases () {
      ApiCountedSearchPhrases.getCountedSearchPhrases()
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalCountedSearchPhrases = pagination.count
          this.searches = response.data.data.map(phrase => phrase.attributes.phrase)
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error)
        })
        .finally(() => {
          this.countedSearchPhrasesLoaded = true
        })
    },
    getMessages () {
      ApiSimpleMessages.getSimpleMessages()
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalMessages = pagination.count
          this.messages = response.data.data.map(message => {
            const user = response.data.included.find(inclusion => inclusion.id === message.relationships.user.data.id)
            let role = _.intersection(user.attributes.roles, ['student', 'graduate', 'employer', 'recruiter'])[0]
            if (role) {
              role = this.$t(`general.roles.${role}`)
            }
            const avatar = user.attributes.avatar
            return {
              id: message.id,
              title: message.attributes.title,
              text: message.attributes.content,
              date: message.attributes.createdAt,
              parentId: message.attributes.parentId,
              sender: {
                name: `${user.attributes.firstName} ${user.attributes.lastName}`,
                role: role,
                avatar: avatar && avatar.thumb ? avatar.thumb.url : (avatar ? avatar.url : null)
              }
            }
          })
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.user_messages.user_messages_index.download_error'))
        })
        .finally(() => {
          this.messagesLoaded = true
        })
    },
    getOffers () {
      ApiOffers.getAllOffers({ filters: this.filters })
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalOffers = pagination.count
          this.setOffers(response.data)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.offers.abk_offers_list.offers_download_error'))
        })
        .finally(() => {
          this.offersLoaded = true
        })
    },
    setOffers ({ data }) {
      this.newOffers = data.map(({ attributes, id }) => {
        const offerTypeTranslationPath = `general.${attributes.offerType}`

        return {
          id,
          ...attributes,
          faded: ['rejected', 'finished'].includes(attributes.status),
          path: { name: 'offers_employer_show_path', params: { id: id } },
          status: {
            mark: this.markPerOfferStatus[attributes.status],
            text: this.$t(`general.offers.statuses.${attributes.status}`),
            value: attributes.status
          },
          name: attributes.jobTitle,
          attributes: _.compact([
            this.$te(offerTypeTranslationPath) ? this.$t(offerTypeTranslationPath) : null,
            this.formatDate(attributes.publicationDate, 'DD.MM.YYYY'),
            attributes.company && attributes.company.name
          ])
        }
      })
    },
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    },
    getEvents () {
      ApiEvents.getEvents({ filters: this.filters })
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalEvents = pagination.count
          this.setEvents(response.data)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.career_office_dashboard.events_download_error'))
        })
        .finally(() => {
          this.eventsLoaded = true
        })
    },
    setEvents ({ data }) {
      this.newEvents = data.map(({ attributes, id }) => {
        const eventTypeTranslationPath = `general.event_type.${attributes.eventType}`
        const startDate = this.formatDate(attributes.startDate, 'DD.MM.YYYY HH:mm')
        const endDate = this.formatDate(attributes.endDate, 'DD.MM.YYYY HH:mm')
        let duration

        if (startDate && endDate) {
          duration = _.uniq([startDate, endDate]).join(' - ')
        } else if (startDate) {
          duration = `${this.$t('general.from')} ${startDate}`
        } else if (endDate) {
          duration = `${this.$t('general.to')} ${endDate}`
        }

        return {
          ...attributes,
          id,
          path: { name: 'events_show_path', params: { id: id } },
          name: attributes.eventTitle,
          status: {
            mark: this.markPerEventStatus[attributes.status],
            text: this.$t(`general.events.statuses.${attributes.status}`),
            value: attributes.status
          },
          attributes: _.compact([
            this.$te(eventTypeTranslationPath) ? this.$t(eventTypeTranslationPath) : null,
            duration,
            !this.showEmployerActions && attributes.company ? attributes.company.name : null
          ])
        }
      })
    },
    acceptOffer (offerId) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.accept_offer'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiOffers.acceptOffer(offerId)
            .then(() => {
              this.getOffers()
            })
            .catch(() => {
              this.$toastr.e(this.$t('components.abk_list_of_offers.unexpected_error'))
            })
        }
      })
    },
    acceptEvent (eventId) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.accept_event'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiEvents.acceptEvent(eventId)
            .then(() => {
              this.getEvents()
            })
            .catch(() => {
              this.$toastr.e(this.$t('views.career_office_dashboard.unexpected_action_error'))
            })
        }
      })
    },
    getCompanies () {
      ApiCompanies.getCompaniesList({ status: ['saved'], page: 1 })
        .then(response => {
          const pagination = response.data.meta.pagination
          this.totalEmployers = pagination.count
          this.employers = response.data.data.map(employer => {
            return {
              id: employer.id,
              name: employer.attributes.name,
              status: employer.attributes.status
            }
          })
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.companies.abk_company_list.error_download_companies'))
        })
        .finally(() => {
          this.companiesLoaded = true
        })
    },
    acceptEmployer (companyId) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.accept_employer'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCompanies.acceptCompany(companyId)
            .then(() => {
              this.getCompanies()
            }).catch(() => {
              this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  /deep/ .box {
    height: 100%;
  }
</style>
