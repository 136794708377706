<template>
  <div>
    <b-row class="top-bar d-flex justify-content-between">
      <b-col v-if="!showPreview" class="progress-bar-container">
        <abk-progress-bar class="steps-progress-bar"
                          :steps="surveyStepsTexts"
                          :current-step="currentStep"/>
      </b-col>
      <pcg-btn v-if="!showPreview" size="small" variant="additional" @click="saveDraft">
        {{ $t('components.surveys.abk_survey_form.save_working_copy') }}
      </pcg-btn>
      <pcg-btn size="small" variant="additional" class="ml-auto" @click="showPreview = !showPreview">
        {{ showPreview ? $t('general.back_to_edit') : $t('general.preview') }}
      </pcg-btn>
    </b-row>
    <transition name="fade" mode="out-in">
      <abk-loader v-if="showPreview && showLoader"/>
      <surveys-show v-else-if="showPreview" class="mt-4" :get-survey-to-preview="formattedPreview"/>
      <keep-alive v-else-if="currentStep === 1">
        <abk-survey-step1-form v-model="survey"
                               :recipientGroups.sync="recipientGroups"
                               :errors="errors"
                               @requiresAnswer="requiresAnswer(step2Form.questions)"/>
      </keep-alive>
      <keep-alive v-else-if="currentStep === 2">
        <abk-survey-step2-form v-model="step2Form"
                               :errors="errors"
                               @dontRequiresAnswer="survey.requiresAnswer = false"
                               :requires-answer="survey.requiresAnswer"/>
      </keep-alive>
      <keep-alive v-else-if="currentStep === 3">
        <abk-survey-step3-form :survey="survey" :recipient-groups="recipientGroups"/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import AbkSurveyStep2Form from './steps/AbkSurveyStep2Form'
import AbkSurveyStep1Form from './steps/AbkSurveyStep1Form'
import AbkSurveyStep3Form from './steps/AbkSurveyStep3Form'
import AbkProgressBar from '../AbkProgressBar'
import ApiSurveys from '../../api/surveys'
import SurveysShow from '../../views/surveys/SurveysShow'
import LoaderDelayMixin from '../../mixins/loader_delay'
import AbkLoader from '../AbkLoader'

export default {
  name: 'AbkSurveyForm',
  components: { AbkLoader, SurveysShow, AbkProgressBar, AbkSurveyStep1Form, AbkSurveyStep2Form, AbkSurveyStep3Form },
  mixins: [LoaderDelayMixin],
  props: {
    surveyId: String,
    submitAction: {
      type: Function,
      required: true
    },
    validateAction: {
      type: Function,
      required: true
    },
    newSurvey: Boolean
  },
  data () {
    return {
      currentStep: 1,
      survey: {
        name: null,
        title: null,
        message: null,
        requiresAnswer: false,
        anonymous: false,
        graduateFate: false,
        expirationDate: null,
        recipientGroupId: null,
        informationText: null,
        reminderTime1: null,
        reminderTime2: null,
        graduateSendDate: null,
        messageAfterCompleting: null
      },
      step2Form: {
        questions: []
      },
      recipientGroups: [],
      errors: {},
      questionSort: 0,
      questionFieldsIdsMapping: [],
      showPreview: false,
      preview: {
        questionFields: [],
        requirements: [],
        survey: {
          questions: []
        }
      }
    }
  },
  computed: {
    surveyStepsTexts () {
      return [
        this.$t('components.surveys.abk_survey_form.survey_steps_texts.step_1'),
        this.$t('components.surveys.abk_survey_form.survey_steps_texts.step_2'),
        this.$t('components.surveys.abk_survey_form.survey_steps_texts.step_3')
      ]
    }
  },
  watch: {
    currentStep () {
      this.setFooterNavbarItems()
    },
    showPreview () {
      this.setFooterNavbarItems()
    }
  },
  mounted () {
    this.getSurvey()
    this.setFooterNavbarItems()
  },
  methods: {
    setFooterNavbarItems () {
      const items = []

      items.push({
        show: !this.showPreview && this.currentStep > 1,
        variant: 'additional',
        class: 'abk-footer-nav-btn ml-0',
        type: 'button',
        text: this.$t('general.steps.previous'),
        action: () => { --this.currentStep }
      })
      items.push({
        show: !this.showPreview && this.currentStep < 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.steps.next'),
        action: this.goToNextStep
      })
      items.push({
        show: !this.showPreview && this.currentStep === 3,
        class: 'abk-footer-nav-btn ml-auto mr-0',
        type: 'button',
        text: this.$t('general.publish'),
        action: this.publish
      })

      this.$store.dispatch('footerNavbar/setItems', items)
    },
    requiresAnswer (questions) {
      questions.forEach(question => {
        question.mandatory = true
        question.requirements.forEach(requirement => this.requiresAnswer(requirement.questions))
      })
    },
    goToNextStep () {
      this.validateAction(this.formattedForm(), this.surveyId)
        .then(() => {
          this.clearErrors()
          ++this.currentStep
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.fields_not_filled'))
          this.errors = error.response.data
        })
    },
    publish () {
      this.submitAction({ survey: { ...this.formattedForm().survey, status: 'published' } }, this.surveyId)
        .then(response => {
          this.$toastr.s(this.$t('components.surveys.abk_survey_form.survey_published'))
          this.$router.push({
            name: 'surveys_list_active_tab_path',
            params: {
              id: response.data.data.id
            }
          })
        }).catch(() => {
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    saveDraft () {
      this.submitAction({ survey: { ...this.formattedForm().survey, status: 'draft' } }, this.surveyId)
        .then(() => {
          this.$toastr.s(this.$t('components.surveys.abk_survey_form.working_copy_saved'))
          this.$router.push({ name: 'surveys_list_active_tab_path' })
        })
        .catch(error => {
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.errors = error.response.data
        })
    },
    clearErrors () {
      this.errors = {}
    },
    questionFieldsFormatted (questionFields) {
      return questionFields.map(({ isNew, edited, ...rest }, index) => {
        return {
          ...rest,
          sort: index
        }
      })
    },
    questionsFormatted (questions, parentRequirement) {
      let result = []

      questions.forEach(question => {
        const { isNew, edited, requirements, questionFields, ...rest } = question
        const newQuestion = {
          ...rest,
          sort: this.questionSort++,
          questionFields: this.questionFieldsFormatted(questionFields)
        }
        if (parentRequirement) {
          if (parentRequirement._destroy) {
            newQuestion._destroy = true
          }
        }
        result.push(newQuestion)
        if (!newQuestion._destroy) {
          requirements.forEach(requirement => {
            result = result.concat(this.questionsFormatted(requirement.questions, requirement))
          })
        }
      })

      return result
    },
    requirementsFormatted (questions) {
      let result = []

      questions.forEach(question => {
        if (question._destroy) {
          return
        }
        let requirementSort = 0
        question.requirements.forEach(requirement => {
          const { isNew, edited, questions, ...rest } = requirement
          if (requirement.questions.length) {
            const usedRequirementIds = []
            requirement.questions.forEach((nestedQuestion, index) => {
              const requirementId = requirement.requirementsIds.find(reqId => reqId.childQuestionId === nestedQuestion.id)
              let newRequirement = {
                ...rest,
                sort: requirementSort++,
                questionId: (question.id || question.tmpId),
                childQuestionId: (nestedQuestion.id || nestedQuestion.tmpId)
              }
              if (requirementId) {
                usedRequirementIds.push(requirementId.id)
                newRequirement = { ...newRequirement, ...requirementId }
              }
              result.push(newRequirement)
            })
            result = result.concat(this.requirementsFormatted(requirement.questions))

            const notUsedRequirementsIds = requirement.requirementsIds.filter(requriementId => {
              return !usedRequirementIds.includes(requriementId.id)
            })
            notUsedRequirementsIds.forEach(notUsedRequirementId => {
              const newRequirement = {
                ...rest,
                sort: requirementSort++,
                questionId: (question.id || question.tmpId),
                childQuestionId: null,
                id: notUsedRequirementId.id,
                _destroy: true
              }
              result.push(newRequirement)
            })

            if (_.every(requirement.questions, (quest) => quest._destroy) && !requirement._destroy) {
              const newRequirement = {
                ...rest,
                sort: requirementSort++,
                questionId: (question.id || question.tmpId),
                childQuestionId: null
              }
              result.push(newRequirement)
            }
          } else {
            if (requirement.requirementsIds.length) {
              requirement.requirementsIds.forEach((requirementId, index) => {
                const newRequirement = {
                  ...rest,
                  sort: requirementSort++,
                  questionId: (question.id || question.tmpId),
                  childQuestionId: null,
                  id: requirementId.id
                }
                if (index === 0 && !requirement._destroy) {
                  delete newRequirement._destroy
                }
                if (index !== 0) {
                  newRequirement._destroy = true
                }
                result.push(newRequirement)
              })
            } else {
              const newRequirement = {
                ...rest,
                sort: requirementSort++,
                questionId: (question.id || question.tmpId),
                childQuestionId: null
              }
              result.push(newRequirement)
            }
          }
        })
      })

      return result
    },
    formattedForm () {
      return {
        survey: {
          step: this.currentStep,
          ...this.survey,
          ...this.formattedStep2Form()
        }
      }
    },
    formattedPreview () {
      this.preview = { questionFields: [], requirements: [], survey: { questions: [] } }
      const { questions, requirements } = this.formattedStep2Form()
      const formattedQuestions = questions
        .filter(e => !e._destroy)
        .map(({ tmpId, id, questionFields, ...rest }, index) => {
          const qfs = questionFields
            .filter(e => !e._destroy)
            .map(({ tmpId, id, ...rest }, index) => {
              return { id: id || tmpId, sort: index, ...rest }
            })
          const reqs = requirements
            .filter(({ questionId, _destroy }) => _.compact([tmpId, id]).includes(questionId) && !_destroy)
            .map(({ tmpId, id, ...rest }, index) => {
              return { id: id || tmpId, sort: index, ...rest }
            })

          this.preview.questionFields.push(...qfs)
          this.preview.requirements.push(...reqs)

          return { id: id || tmpId, sort: index, ...rest, questionFields: qfs, requirements: reqs }
        })

      this.preview.survey.questions.push(...formattedQuestions)

      return this.preview
    },
    formattedStep2Form () {
      this.questionSort = 0
      return {
        questions: this.questionsFormatted(this.step2Form.questions),
        requirements: this.requirementsFormatted(this.step2Form.questions)
      }
    },
    getSurvey () {
      if (!this.surveyId) {
        this.loadingDone = true
        return
      }
      ApiSurveys.getSurvey(this.surveyId)
        .then(response => {
          this.setSurvey(response.data)
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.surveys.abk_survey_form.survey_download_error'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    async setSurvey ({ included, data: { attributes } }) {
      this.survey = { ...this.survey, ...attributes }

      const grouped = _.groupBy(included, 'type')

      const questions = (grouped.question || []).sort((a, b) => a.sort - b.sort)
      const questionFields = (grouped.question_field || []).sort((a, b) => a.sort - b.sort)
      const requirements = (grouped.requirement || []).sort((a, b) => a.sort - b.sort)

      const nestedQuestions = requirements.map(requirement => requirement.attributes.childQuestionId)
      const rootQuestions = questions.filter(question => !nestedQuestions.includes(Number.parseInt(question.id)))

      for (let index = 0; index < rootQuestions.length; index++) {
        const newQuestion = await this.mapQuestionToStructure(rootQuestions[index], questions, questionFields, requirements)
        this.step2Form.questions.push(newQuestion)
      }

      if (this.newSurvey) {
        this.mapQuestionFieldsIdsToTmpIds(this.step2Form.questions)
      }
    },
    async mapQuestionToStructure (question, questions, questionFields, requirements) {
      const newQuestionFields = []
      const newRequirements = []

      const questionFieldsIds = question.relationships.questionFields.data.map(questionField => questionField.id)
      const fillteredQuestionFields = questionFields.filter(questionField => questionFieldsIds.includes(questionField.id))
      for (let j = 0; j < fillteredQuestionFields.length; j++) {
        const questionFieldTmpId = await this.$store.dispatch('tmpIds/generateTmpId')
        const questionFieldId = Number.parseInt(fillteredQuestionFields[j].id)
        const questionField = {
          ...fillteredQuestionFields[j].attributes,
          tmpId: questionFieldTmpId
        }

        this.newSurvey
          ? this.questionFieldsIdsMapping[questionFieldId] = questionFieldTmpId
          : questionField.id = questionFieldId

        newQuestionFields.push(questionField)
      }

      const requirementsIds = question.relationships.requirements.data.map(requirement => requirement.id)
      const fillteredrequirements = requirements.filter(requirement => requirementsIds.includes(requirement.id))

      const requirementsGrouped = _.groupBy(fillteredrequirements, (requirement) => {
        return [requirement.attributes.questionFieldIds, requirement.attributes.requirementType].sort().join(',')
      })
      // eslint-disable-next-line no-unused-vars
      for (const [name, groupedRequirements] of Object.entries(requirementsGrouped)) {
        const representative = groupedRequirements[0]
        const requirementQuestions = []
        const sortedGroupedRequirements = groupedRequirements.sort((a, b) => a.sort - b.sort)
        const requirementsIds = this.newSurvey
          ? []
          : sortedGroupedRequirements.map(requirement => {
            return {
              id: Number.parseInt(requirement.id),
              childQuestionId: requirement.attributes.childQuestionId
            }
          })

        for (let j = 0; j < sortedGroupedRequirements.length; j++) {
          if (sortedGroupedRequirements[j].attributes.childQuestionId !== null) {
            const childQuestion = questions.find(question => {
              return Number.parseInt(question.id) === sortedGroupedRequirements[j].attributes.childQuestionId
            })

            if (childQuestion) {
              const newQuestion = await this.mapQuestionToStructure(childQuestion, questions, questionFields, requirements)
              requirementQuestions.push(newQuestion)
            }
          }
        }

        newRequirements.push({
          requirementType: representative.attributes.requirementType,
          questionFieldIds: representative.attributes.questionFieldIds,
          sort: representative.attributes.sort,
          tmpId: await this.$store.dispatch('tmpIds/generateTmpId'),
          questions: requirementQuestions,
          requirementsIds: requirementsIds
        })
      }

      const { id, ...restQuestionAttributes } = question.attributes
      const newQuestion = {
        tmpId: await this.$store.dispatch('tmpIds/generateTmpId'),
        ...restQuestionAttributes,
        questionFields: newQuestionFields,
        requirements: newRequirements
      }

      if (!this.newSurvey) {
        newQuestion.id = Number.parseInt(question.id)
      }

      return newQuestion
    },
    mapQuestionFieldsIdsToTmpIds (questions) {
      questions.forEach(question => {
        question.requirements.forEach(requirement => {
          requirement.questionFieldIds = requirement.questionFieldIds.map(questionFieldId => {
            return this.questionFieldsIdsMapping[questionFieldId]
          })
          this.mapQuestionFieldsIdsToTmpIds(requirement.questions)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .progress-bar-container {
    margin-bottom: $pcg-m-lg;
  }
  .steps-progress-bar {
    max-width: 590px;
    ::v-deep .progress-bar-step {
      padding-left: 5% !important;
    }
  }
</style>
