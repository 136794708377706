<template>
  <div class="offer-filters">
    <abk-observed-search-results :observed-search-results="offerFilters"
                                 :dictionaries="dictionariesOptionsWithOfferTypes"
                                 :show-loader="showLoader"
                                 @remove="openRemoveConfirmationModal"/>
    <abk-remove-object-filter-confirmation-modal @remove="removeOfferFilter"
                                                 :dictionaries="dictionariesOptionsWithOfferTypes"
                                                 v-model="confirmationModalShow"
                                                 :filter="filterToDelete"/>
    <pcg-pagination v-model="pagination.currentPage"
                    @input="loadOfferFilters"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import ApiOfferFilters from '../../api/offer_filters'
import ApiDictionaries from '../../api/dictionaries'
import ApiCompanies from '../../api/companies'
import AbkObservedSearchResults from '../../components/AbkObservedSearchResults'
import { decamelize } from '@ridi/object-case-converter'
import AbkRemoveObjectFilterConfirmationModal
  from '../../components/offer_filters/AbkRemoveObjectFilterConfirmationModal'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import LoaderDelayMixin from '../../mixins/loader_delay'

export default {
  name: 'OfferFiltersIndex',
  components: { AbkRemoveObjectFilterConfirmationModal, AbkObservedSearchResults },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  data: function () {
    return {
      removeOfferFilterId: null,
      filterToDelete: {},
      confirmationModalShow: false,
      dictionaries: ['workplace', 'trade', 'position_type', 'type_of_work', 'work_dimension'],
      offerFilters: [],
      dictionariesOptions: {},
      dictionariesLoaded: false,
      companiesLoaded: false,
      offersLoaded: false
    }
  },
  computed: {
    dictionariesOptionsWithOfferTypes () {
      return { ...this.dictionariesOptions, offer_type: this.offerTypes }
    },
    offerTypes () {
      return [
        { text: this.$t('general.job'), value: 'job' },
        { text: this.$t('general.training'), value: 'training' },
        { text: this.$t('general.practice'), value: 'practice' }
      ]
    }
  },
  created () {
    this.loadDictionaries()
    this.loadCompaniessNames()
    this.loadOfferFilters()
    this.$store.dispatch('user/getCatalogOfferCounters')
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.offer_filters.title')
      })
    },
    loadDictionaries () {
      ApiDictionaries.getSelectedDictionaries(this.dictionaries)
        .then(responses => {
          this.dictionaries.forEach((dictionary, index) => {
            if (responses[index] && responses[index].data && responses[index].data.data) {
              const options = responses[index].data.data.map(({ id, attributes }) => {
                return { text: attributes.value, value: id }
              })
              this.$set(this.dictionariesOptions, dictionary, options)
            }
          })
          this.dictionariesLoaded = true
        })
        .catch((error) => {
          console.log(error)
          this.dictionariesLoaded = true
          this.$toastr.e(this.$t('general.error_try_later'))
        })
          .finally(() => {
            if (this.dictionariesLoaded && this.companiesLoaded && this.offersLoaded) {
              this.loadingDone = true
            }
          })
    },
    loadCompaniessNames () {
      ApiCompanies.getCompaniesNames()
        .then(response => {
          const options = response.data.data.map(company => {
            return { value: company[0], text: company[1] }
          })
          this.$set(this.dictionariesOptions, 'company', options)
          this.companiesLoaded = true
        })
        .catch(() => {
          this.companiesLoaded = true
          this.$toastr.e(this.$t('general.error_try_later'))
        })
          .finally(() => {
            if (this.dictionariesLoaded && this.companiesLoaded && this.offersLoaded) {
              this.loadingDone = true
            }
          })
    },
    openRemoveConfirmationModal (offerFilterId, filterToDelete) {
      this.removeOfferFilterId = offerFilterId
      this.filterToDelete = filterToDelete
      this.confirmationModalShow = true
    },
    removeOfferFilter () {
      ApiOfferFilters.removeOfferFilter(this.removeOfferFilterId)
        .then(response => {
          this.$toastr.s(this.$t('views.offer_filters.offer_filter_removed'))
          this.removeOfferFilterId = null
          this.filterToDelete = {}
          this.confirmationModalShow = false
          this.loadOfferFilters()
          this.$store.dispatch('user/getCatalogOfferCounters')
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error)
          console.log(error.response)
          this.$toastr.e(this.$t('views.offer_filters.remove_error'))
        })
    },
    loadOfferFilters () {
      ApiOfferFilters.getOfferFilters(this.pagination.currentPage)
        .then(response => {
          this.setPagination(response)
          this.offerFilters = response.data.data.map(offerFilter => {
            return {
              id: offerFilter.id,
              ...offerFilter.attributes,
              ...{ filter: decamelize(offerFilter.attributes.filter, { recursive: true }) }
            }
          })
          this.offersLoaded = true
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error)
          console.log(error.response)
          this.offersLoaded = true
          this.$toastr.e(this.$t('views.offer_filters.error_get_offer_filters'))
        })
        .finally(() => {
          if (this.dictionariesLoaded && this.companiesLoaded && this.offersLoaded) {
            this.loadingDone = true
          }
        })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
