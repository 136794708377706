<template>
  <div>
    <div class="title-container d-flex align-items-center">
      <h1 class="pcg-form-title d-inline-block h-auto mb-0 mr-3">
        {{ $t('components.abk_additional_sections_form.title') }}
      </h1>
    </div>
    <transition-group name="slide" mode="out-in">
      <template v-for="(section, index) in value" class="section">
          <component v-if="!section._destroy"
                     :is="getSectionComponentName(section.sectionType)"
                     class="section"
                     v-model="value[index]"
                     :key="section.key"
                     :section-index="index"
                     @remove="removeSection(index)"
                     :errors="errors"/>
      </template>
    </transition-group>
    <pcg-btn size="small" variant="additional" class="my-0" v-b-modal.section-type-select>
      {{ $t('general.add') }}
    </pcg-btn>
    <abk-select-modal id="section-type-select"
                      :title="$t('components.abk_additional_sections_form.select_section_type')"
                      :options="sectionTypes"
                      v-model="sectionType"
                      @input="addSection"/>
  </div>
</template>

<script>
import AbkTextSectionForm from './AbkTextSectionForm'
import AbkSelectModal from './AbkSelectModal'
import AbkVideoSectionForm from './AbkVideoSectionForm'
import AbkGallerySectionForm from './AbkGallerySectionForm'
export default {
  name: 'AbkAdditionalSectionsForm',
  components: { AbkGallerySectionForm, AbkSelectModal, AbkTextSectionForm, AbkVideoSectionForm },
  props: {
    value: Array,
    errors: Object
  },
  data () {
    return {
      sectionType: 'text',
      sectionTypes: [
        { text: this.$t('general.text_section'), value: 'text' },
        { text: this.$t('general.gallery'), value: 'gallery' },
        { text: this.$t('general.video'), value: 'video' }
      ],
      componentId: this._uid.toString()
    }
  },
  methods: {
    getEmptyTextSection () {
      return { name: null, companySubsections: [] }
    },
    getEmptyGallery () {
      return { name: null, companySubsections: [] }
    },
    getEmptyVideo () {
      return { name: null, companySubsections: [] }
    },
    addSection (sectionType) {
      const emptySection = this.value.filter(entry => {
        return !entry._destroy && entry.sectionType === sectionType
      }).some(entry => {
        if (sectionType === 'gallery' || sectionType === 'video') {
          return !(entry.name || entry.companySubsections.filter(subsection => !subsection._destroy).length)
        } else {
          const destroyed = entry.companySubsections.filter(subsection => !subsection._destroy)
          return !(entry.name || (destroyed.length && !destroyed.some(subsection => {
            return !(subsection.content || subsection.name || subsection.attachment)
          })))
        }
      })
      if (emptySection) {
        this.$toastr.e(this.$t(`components.abk_additional_sections_form.empty_section_error.${sectionType}`))
        return
      }

      const sectionKeys = this.value.map(section => section.key)
      const newKey = sectionKeys.length > 0 ? Math.max(...sectionKeys) + 1 : 0
      const sectionAttributes = sectionType === 'text' ? this.getEmptyTextSection() : sectionType === 'gallery' ? this.getEmptyGallery() : this.getEmptyVideo()
      const newSection = { key: newKey, sectionType: sectionType, ...sectionAttributes }
      this.value.push(newSection)
    },
    removeSection (index) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.delete'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          const copy = [...this.value]
          if (copy[index].id) {
            copy[index]._destroy = true
          } else {
            copy.splice(index, 1)
          }
          this.$emit('input', copy)
        }
      })
    },
    getSectionComponentName (sectionType) {
      return sectionType === 'text' ? 'abk-text-section-form' : sectionType === 'gallery' ? 'abk-gallery-section-form' : 'abk-video-section-form'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .title-container {
    margin-bottom: $pcg-m-lg;
  }
  .section:not(:first-child) {
    margin-top: $pcg-m-lg;
  }
  .section:last-child {
    margin-bottom: 15px;
  }
</style>
