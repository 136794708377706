<template>
  <pcg-box :header="section.name">
    <b-tabs justified nav-class="flex-column flex-md-row">
      <b-tab v-for="subsection in section.subsections"
             :key="subsection.id"
             :title="subsection.name">
        <template v-if="subsection.attachment && subsection.attachment.url">
          <b-row>
            <b-col class="col-12 col-sm-6 tab-content" v-html="subsection.content">
            </b-col>
            <b-col class="col-12 col-sm-6 text-center">
              <img :src="subsection.attachment.url" class="img-fluid subsection-img">
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <div v-html="subsection.content"></div>
        </template>
      </b-tab>
    </b-tabs>
  </pcg-box>
</template>

<script>
export default {
  name: 'abk-get-to-know-us',
  props: {
    section: Object
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  /deep/ .nav.nav-tabs {
    border: none;
    @media only screen and (min-width: $screen-md-min) {
      align-items: center;
    }
    .nav-item {
      font-size: $font-size-s;
      text-transform: uppercase;
      .nav-link {
        color:  $main-active-color;
        font-weight: bold;
        &.active {
          border: none;
          color: $main-color;
        }
      }
    }
  }

  /deep/ .tab-pane {
    color: $pcg-dark-gray;
    font-size: $font-size-s;
  }

  img {
    border-radius: 20px;
    @media only screen and (max-width: $screen-sm-max) {
      margin-top: 20px;
    }
  }

  .subsection-img {
    max-height: 180px;
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    /deep/ .nav.nav-tabs {
      .nav-item {
        .nav-link {
          color:  $main-active-color;
          &.active {
            color: $main-color;
          }
        }
      }
    }

    /deep/ .tab-pane {
      color: $pcg-dark-gray;
    }
  }
</style>
