<template>
  <div @keyup.enter="search" class="student-dashboard-container">
    <div class="d-flex align-items-center top-bar flex-wrap">
      <h4 class="subtitle" v-html="$tc('views.student_dashboard.subtitle', offersCount)"></h4>
      <div class="checkbox-container">
        <pcg-checkbox class="checkbox" v-model="searchFilters.work" variant="secondary">
          {{ $t('views.student_dashboard.work') }}
        </pcg-checkbox>
        <pcg-checkbox class="checkbox" v-model="searchFilters.interships" variant="secondary">
          {{ $t('views.student_dashboard.apprenticeships_and_internships') }}
        </pcg-checkbox>
      </div>
    </div>
    <b-row class="search-container">
      <pcg-text-input v-model="searchText"
                      :show-label="false"
                      autocomplete="off"
                      class="search-input position-relative col-12 col-xl-7 m-0"
                      :placeholder="$t('views.student_dashboard.search_placeholder')"/>
      <b-col cols="12" xl="5" class="d-flex">
        <pcg-search-select :placeholder="$t('general.localization')"
                           :options="workplaceOptions"
                           v-model="currentCity"
                           always-active
                           allow-empty
                           :show-value-addition="false"
                           :dictionary-id="getDictionaryId('workplace')"
                           class="flex-grow-1 localization-select"/>
        <pcg-btn class="position-relative pcg-icon-search search-btn" @click="search"/>
      </b-col>
    </b-row>
    <abk-loader v-if="showLoader" class="col-12 with-margins"/>
    <template v-else>
      <abk-search-new-offers class="col-12 p-0" :offers="newOffers"/>
      <b-row class="d-flex flex-wrap">
        <abk-upcoming-events-box class="col-12 col-lg-6" :events="events"/>
        <b-col lg="6" class="d-flex flex-column">
          <b-row class="side-box-container">
            <abk-popular-searches-box class="col-12" :searches="popularSearches"/>
            <abk-employers-box class="col-12" :employers="employers"/>
          </b-row>
        </b-col>
        <abk-surveys-box class="col-12"
                         :surveys="surveys"
                         :total-surveys="totalSurveys"
                         :order="surveysFilters"
                         @changeOrder="changeSurveysOrder"/>
      </b-row>
    </template>
  </div>
</template>

<script>
import ApiOffers from '../api/offers'
import ApiCompanies from '../api/companies'
import ApiDictionaryWords from '../api/dictionary_words'
import ApiCountedSearchPhrases from '../api/counted_search_phrases'
import ApiEvents from '../api/events'
import ApiSurveyUsers from '../api/survey_users'
import AbkSearchNewOffers from '../components/AbkSearchNewOffers'
import AbkUpcomingEventsBox from '../components/AbkUpcomingEventsBox'
import AbkPopularSearchesBox from '../components/AbkPopularSearchesBox'
import AbkEmployersBox from '../components/AbkEmployersBox'
import AbkLoader from '../components/AbkLoader'
import LoaderDelayMixin from '../mixins/loader_delay'
import AbkSurveysBox from '../components/surveys/AbkSurveysBox'
export default {
  name: 'StudentDashboard',
  components: {
    AbkSurveysBox,
    AbkLoader,
    AbkEmployersBox,
    AbkPopularSearchesBox,
    AbkUpcomingEventsBox,
    AbkSearchNewOffers
  },
  mixins: [LoaderDelayMixin],
  data () {
    return {
      offersCount: 0,
      currentCity: null,
      searchFilters: {
        work: false,
        interships: false
      },
      surveysFilters: {
        orderDirection: 'desc',
        orderBy: 'initial_sent_date'
      },
      searchText: null,
      popularSearches: [],
      workplaceOptions: [],
      events: [],
      employers: [],
      newOffers: [],
      surveys: [],
      totalSurveys: 0,
      dictionariesMetaInfo: {},
      newOffersLoaded: false,
      newEmployersLoaded: false,
      countedSearchPhrasesLoaded: false,
      upcomingEventsLoaded: false,
      surveysLoaded: false
    }
  },
  computed: {
    loadingDone () {
      return this.newOffersLoaded &&
        this.newEmployersLoaded &&
        this.countedSearchPhrasesLoaded &&
        this.upcomingEventsLoaded &&
        this.surveysLoaded
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
    })
  },
  created () {
    this.loadNewOffers()
    this.loadNewEmployers()
    this.loadOfferCount()
    this.loadCountedSearchPhrases()
    this.getLocationsOptions()
    this.getUpcomingEvents()
    this.getSurveys()
  },
  methods: {
    getDictionaryId (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryId : null
    },
    isDictionaryOpen (field) {
      const dictionaryInfo = this.dictionariesMetaInfo[field]
      return dictionaryInfo ? dictionaryInfo.dictionaryType === 'open' : false
    },
    search () {
      const filter = _.pickBy({
        offer_type: _.compact([
          this.searchFilters.work ? 'job' : null,
          this.searchFilters.interships ? 'training' : null
        ]),
        workplace: _.compact([this.currentCity]),
        keyword: _.compact([this.searchText])
      }, (value, key) => Array.isArray(value) && value.length)

      this.$router.push({ name: 'offers_path', query: { search: filter } })
    },
    loadNewOffers () {
      ApiOffers.getRecentOffers(4)
        .then(response => {
          this.newOffers = response.data.data.map(offer => { return { id: offer.id, ...offer.attributes } })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
        .finally(() => {
          this.newOffersLoaded = true
        })
    },
    loadNewEmployers () {
      ApiCompanies.getRecentCompanies(4)
        .then(response => {
          this.employers = response.data.data.map(employer => { return { id: employer.id, ...employer.attributes } })
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
        .finally(() => {
          this.newEmployersLoaded = true
        })
    },
    loadOfferCount () {
      ApiOffers.getOffersCount()
        .then(response => {
          this.offersCount = response.data.data.count
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error.response)
        })
    },
    getLocationsOptions () {
      ApiDictionaryWords.getDictionaryWordsByKey('workplace')
        .then(response => {
          this.workplaceOptions = response.data.data.map(workplace => {
            return {
              text: workplace.attributes.value,
              value: workplace.id
            }
          })
          this.dictionariesMetaInfo = { ...this.dictionariesMetaInfo, workplace: { ...response.data.meta } }
        }).catch(error => {
          console.log(error)
        })
    },
    loadCountedSearchPhrases () {
      ApiCountedSearchPhrases.getCountedSearchPhrases()
        .then(response => {
          this.popularSearches = response.data.data.map(phrase => phrase.attributes.phrase)
        })
        .catch(error => {
          // TODO Wyświetlanie błędów
          console.log(error)
        })
        .finally(() => {
          this.countedSearchPhrasesLoaded = true
        })
    },
    setBackgroundImage () {
      this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/student_dashboard_bg.jpg'))
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '480px'
      })
    },
    getUpcomingEvents () {
      ApiEvents.getUpcomingEvents()
        .then(response => {
          this.events = response.data.data.map(event => {
            return {
              id: event.id,
              ...event.attributes,
              subEvents: event.attributes.subEvents.map((sub) => {
                const subAttributes = sub.data.attributes
                const startDate = this.formatDate(subAttributes.startDate, 'dddd DD.MM.YYYY HH:mm')
                const endDate = this.formatDate(subAttributes.endDate, 'dddd DD.MM.YYYY HH:mm')
                let duration

                if (startDate && endDate) {
                  duration = _.uniq([startDate, endDate]).join(' - ')
                } else if (startDate) {
                  duration = `${this.$t('general.from')} ${startDate}`
                } else if (endDate) {
                  duration = `${this.$t('general.to')} ${endDate}`
                }

                return {
                  ...subAttributes,
                  id: sub.data.id,
                  name: event.attributes.eventTitle,
                  path: { name: 'sub_events_show_path', params: { id: sub.data.id } },
                  attributes: _.compact([
                    duration
                  ])
                }
              })
            }
          })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.upcomingEventsLoaded = true
        })
    },
    getSurveys () {
      ApiSurveyUsers.getAssignedToMeRecent(this.surveysFilters)
        .then(({ data }) => {
          this.totalSurveys = data.meta.pagination.count
          this.setSurveys(data)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('views.student_dashboard.downloading_surveys_error'))
        })
        .finally(() => {
          this.surveysLoaded = true
        })
    },
    setSurveys ({ data, included }) {
      this.surveys = data.map(({ id, attributes, relationships }) => {
        const survey = included.find(e => e.type === 'survey' && e.id === relationships.survey.data.id)
        const { requiresAnswer, anonymous, graduateFate } = survey.attributes
        const initialSentDate = this.formatDate(attributes.initialSentDate, 'DD.MM.YYYY')
        const updatedAt = this.formatDate(attributes.updatedAt, 'DD.MM.YYYY')
        return {
          id,
          ...attributes,
          ...survey.attributes,
          path: this.surveysType === 'completed' ? null : { name: 'surveys_show_path', params: { id } },
          disabledPath: this.surveysType === 'completed',
          status: {
            value: attributes.status
          },
          attributes: _.compact([
            updatedAt && attributes.completed
              ? this.$t('components.surveys.abk_surveys_list.completed_at', { date: updatedAt })
              : null,
            initialSentDate ? this.$t('components.surveys.abk_surveys_list.sent_at', { date: initialSentDate }) : null,
            requiresAnswer ? this.$t('general.surveys.attributes.requires_answer') : null,
            anonymous ? this.$t('general.surveys.attributes.anonymous') : null,
            graduateFate ? this.$t('general.surveys.attributes.graduate_fate') : null
          ])
        }
      })
    },
    changeSurveysOrder (newOrder) {
      this.surveysFilters = { ...this.surveysFilters, ...newOrder }
      this.getSurveys()
    },
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .side-box-container {
    flex: 1;
  }
  .student-dashboard-container {
    padding-top: 200px;
    @media screen and (max-width: $screen-sm-max) {
      padding-top: 45px;
    }
  }
  .top-bar {
    margin-bottom: $pcg-m-xs;
  }
  .subtitle {
    white-space: nowrap;
    position: relative;
    font-weight: 900;
    color: $pcg-white;
    margin-bottom: 0;
    margin-right: $pcg-m-xl;
  }
  .checkbox-container {
    display: flex;
    align-items: baseline;
    /deep/ .pcg-checkbox-container {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }
  .subtitle, .checkbox-container {
    margin-bottom: $pcg-m-sm;
  }
  .search-container {
    margin-bottom: $pcg-m-lg;
  }
  .search-btn {
    margin: 0 0 0 $pcg-m-sm;
    padding: 0 26px;
    background-color: $main-color;
    box-shadow: 0 10px 20px $main-color-shadow;
  }

  @media(min-width: $screen-sm-min) {
    .search-input, .localization-select, .search-btn {
      &, ::v-deep input {
        height: 53px;
        font-size: 20px;
      }
    }
  }
  @media(max-width: $screen-lg-max) {
    .search-input {
      margin-bottom: $pcg-m-lg!important;
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .subtitle{
      color: $pcg-white;
    }
  }
</style>
