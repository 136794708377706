<template>
  <div>
    <b-row>
      <b-col style="margin-bottom: 1rem" class="col-12 col-sm-5 col-md-12 col-lg-5">
        <div class="title">{{ $t('components.abk_page_not_found.title') }}</div>
        <div class="not-exist">{{ $t('components.abk_page_not_found.not_exist') }}</div>
        <pcg-btn variant="additional" class="main-page" @click="goToHomePage">{{ $t('components.abk_page_not_found.main_page') }}</pcg-btn>
      </b-col>
      <b-col class="col-12 col-sm-7 col-md-12 col-lg-7 d-flex justify-content-end" style="margin-top: 17px">
        <img src="../assets/images/404.png" alt="404-LOGO" class="img-fluid d-block mx-auto"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AbkPageNotFound',
  methods: {
    goToHomePage () {
      this.$router.push({ name: 'login_path' })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .title {
    color: $main-color;
    font-size: $font-size-xl;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.96px;
    margin-bottom: 40px;
  }

  .not-exist {
    font-size: $font-size-s;
    color: $pcg-dark-gray;
    margin-bottom: 120px;
  }

  .main-page {
    margin: 0;
  }

  .image-404 {
    margin-top: 17px;
  }
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars-contrast';

  .contrast {
    .not-found-page {
      color: $pcg-the-darkest-gray;
    }
  }
</style>
