<template>
  <div>
    <abk-list-of-students :list-of-students="students"
                           :no-records-text="$t(`components.abk_students.abk_student_list.no_records`)"
                           :show-loader="showLoader"
                           :attendanceMandatory="attendanceMandatory"
                           :event="event"
                           :status="status"
                           :show-actions="true" >
    </abk-list-of-students>

    <pcg-pagination v-model="pagination.currentPage"
                    @input="getStudents"
                    :page-count="pagination.pages"
                    :total-records="pagination.totalRecords"
                    class="col-12 justify-content-center my-pagination"/>
  </div>
</template>

<script>
import AbkListOfStudents from './AbkListOfStudents'
import ApiStudents from '../../api/attendances'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import LoaderDelayMixin from '../../mixins/loader_delay'
export default {
  name: 'AbkAttendanceList',
  components: { AbkListOfStudents },
  mixins: [PaginableResourceMixin, LoaderDelayMixin],
  props: {
    status: String
  },
  data () {
    return {
      students: [],
      attendanceMandatory: false,
      event: null
    }
  },
  mounted () {
    this.getStudents()
  },
  methods: {
    getStudents () {
      const eventId = this.$route.params.event
      ApiStudents.getStudentsList(eventId, { page: this.pagination.currentPage, status: this.status })
        .then(response => {
          this.setPagination(response)
          const eventAttributes = response.data.meta.event
          this.attendanceMandatory = eventAttributes.attendance
          this.students = response.data.data.map(student => {
            return {
              id: student.id,
              eventId: eventId,
              attendance: student.attributes.attendance,
              avatar: student.attributes.avatar,
              name: `${student.attributes.firstName} ${student.attributes.lastName}`,
              email: student.attributes.email,
              phone: student.attributes.phone,
              cvId: student.attributes.cvId
            }
          })
          const startDate = this.formatDate(eventAttributes.startDate, 'dddd DD.MM.YYYY HH:mm')
          const endDate = this.formatDate(eventAttributes.endDate, 'dddd DD.MM.YYYY HH:mm')
          let duration

          if (startDate && endDate) {
            duration = _.uniq([startDate, endDate]).join(' - ')
          } else if (startDate) {
            duration = `${this.$t('general.from')} ${startDate}`
          } else if (endDate) {
            duration = `${this.$t('general.to')} ${endDate}`
          }
          this.event = {
            duration: duration,
            id: eventAttributes.id,
            title: eventAttributes.eventTitle
          }
        })
        .catch(() => {
          this.$toastr.e(this.$t('components.abk_students.abk_student_list.error_download_students'))
        })
        .finally(() => {
          this.loadingDone = true
        })
    },
    updateStudentStatus (studentId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${action}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiStudents[`${action}Student`](studentId)
            .then(() => {
              this.getStudents()
            }).catch(() => {
              this.$toastr.e(this.$t('components.companies.abk_company_list.unexpected_error'))
            })
        }
      })
    },
    formattedDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    formatDate (date, format) {
      if (date) {
        const newDate = moment(date)
        return newDate.isValid() ? newDate.format(format) : null
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">
  /deep/ .list-of-students {
    .name {
      font-weight: 400 !important;
    }
  }
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast{
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
