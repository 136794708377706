<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col v-if="showCareerOfficeActions || acceptedCompany" cols="12" xl="6" class="progress-bar-container order-xl-last mb-xl-0">
          <div class="w-80 text-right">
            <pcg-btn class="m-0 danger-delete-btn" size="small" @click="showAccountModal = true">{{ $t('general.remove_account') }}</pcg-btn>
            <pcg-btn class="mr-0 mb-0" size="small" @click="updateCompany('draft')">{{ $t('general.save_draft') }}</pcg-btn>
            <pcg-btn class="mr-0 mb-0" size="small" @click="updateCompany('saved')">{{ $t('general.save') }}</pcg-btn>
            <template v-if="!company.main">
              <br class="d-none d-xl-block">
              <pcg-btn class="mr-0 mb-0" size="small" @click="deleteDraft">{{ $t('general.reject_changes') }}</pcg-btn>
            </template>
          </div>
        </b-col>
        <b-col cols="12" xl="6" class="order-xl-first">
          <div class="info d-flex pcg-the-darkest-gray-color">
            <i class="pcg-icon-comment-info"></i>
            <div class="ml-3">{{ $t('components.abk_basic_info_form.info') }}</div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <abk-basic-info-form class="col-12 form" v-model="company" :errors="errors"/>
    <abk-visual-identification-form class="col-12 form" v-model="images" :errors="errors"/>
    <abk-additional-sections-form v-if="company.employerType !== 'lite'" class="col-12 form" v-model="companySections" :errors="errors"/>
    <b-col cols="12">
      <h2 class="pcg-form-title">{{ $t('views.edit_company_profile.cooperations') }}</h2>
      <div v-for="(acceptance, index) in cooperations" :key="`approval-${index}`">
        <div v-if="acceptance.wordType === 'clause'" class="clause-information">
          <span v-html="acceptance.text"></span>
        </div>
        <pcg-checkbox v-else
                      variant="normal"
                      :required="acceptance.required"
                      :show-error="errorExist(`companyAcceptances.${index + acceptances.length}.value`)"
                      :error-text="getError(`companyAcceptances.${index + acceptances.length}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </div>
    </b-col>
    <b-col cols="12">
      <h2 class="pcg-form-title">{{ $t('views.edit_company_profile.approvals') }}</h2>
      <div v-for="(acceptance, index) in acceptances" :key="`approval-${index}`">
        <div v-if="acceptance.wordType === 'clause'" class="clause-information">
          <span v-html="acceptance.text"></span>
        </div>
        <pcg-checkbox v-else-if="acceptance.blockedOnProfile"
                      class="blocked-on-profile"
                      variant="normal"
                      :disabled="true"
                      :required="acceptance.required"
                      :show-error="errorExist(`cvAcceptances.${index}.value`)"
                      :error-text="getError(`cvAcceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
        <pcg-checkbox v-else
                      variant="normal"
                      :required="acceptance.required"
                      :show-error="errorExist(`companyAcceptances.${index}.value`)"
                      :error-text="getError(`companyAcceptances.${index}.value`)"
                      v-model="acceptance.value">
          <span v-html="acceptance.text"></span>
        </pcg-checkbox>
      </div>
      <div v-if="showCareerOfficeActions || acceptedCompany" class="d-flex justify-content-between align-items-center">
        <div>
          <pcg-btn class="m-0 danger-delete-btn" size="small" @click="showAccountModal = true">{{ $t('general.remove_account') }}</pcg-btn>
        </div>
        <div>
          <pcg-btn class="mr-0 mb-0" size="small" @click="updateCompany('draft')">{{ $t('general.save_draft') }}</pcg-btn>
          <pcg-btn class="save-button mr-0" size="small" @click="updateCompany('saved')">{{ $t('general.save') }}</pcg-btn>
          <pcg-btn class="mr-0 mb-0" size="small" @click="deleteDraft" v-if="!company.main">{{ $t('general.reject_changes') }}</pcg-btn>
          <pcg-btn class="mr-0 mb-0" size="small" @click="showPreview" v-if="!company.main">{{ $t('general.preview_saved_draft') }}</pcg-btn>
        </div>
      </div>
    </b-col>
    <unsaved-changes-modal :title="$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.title')"
                           :buttons="unsavedChangesModalButtons"
                           v-model="showUnsavedChangesModal"
                           ref="unsavedChangesModal"/>
    <abk-remove-account-modal :show="showAccountModal" type="company" @discard="showAccountModal = false" @removed="logout" />
  </b-row>
</template>

<script>
import AbkBasicInfoForm from '../../components/AbkBasicInfoForm'
import AbkVisualIdentificationForm from '../../components/AbkVisualIdentificationForm'
import AbkAdditionalSectionsForm from '../../components/AbkAdditionalSectionsForm'
import AbkRemoveAccountModal from '../../components/AbkRemoveAccountModal.vue'
import ApiCompanies from '../../api/companies'
import ApiDictionaryAcceptances from '../../api/dictionary_acceptances'
import axios from 'axios'
import UnsavedChangesModal from '../../components/UnsavedChangesModal'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyEdit',
  components: {
    UnsavedChangesModal,
    AbkAdditionalSectionsForm,
    AbkVisualIdentificationForm,
    AbkBasicInfoForm,
    AbkRemoveAccountModal
  },
  data () {
    return {
      company: {
        name: null,
        shortDescription: null,
        address: null,
        city: null,
        phone: null,
        email: null,
        website: null,
        facebook: null,
        linkedin: null,
        twitter: null,
        main: true,
        nip: null
      },
      showAccountModal: false,
      companyId: null,
      errors: {},
      images: {},
      companySections: [],
      acceptances: [],
      cooperations: [],
      notSavedChanges: false,
      showUnsavedChangesModal: false,
      unsavedChangesModalButtons: [
        {
          event: 'save',
          size: 'small',
          variant: 'main',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.save')
        },
        {
          event: 'draft',
          size: 'small',
          variant: 'main',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.draft')
        },
        {
          event: 'cancel',
          size: 'small',
          variant: 'additional',
          text: this.$t('views.curriculum_vitaes.curriculum_vitae_edit.unsaved_changes_modal.buttons.cancel')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('user', ['acceptedCompany']),
    ...mapGetters('environment', ['env']),
    getImagesFormComputed () {
      return this.getImagesForm()
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    }
  },
  watch: {
    company: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    getImagesFormComputed: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    companySections: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    acceptances: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    },
    cooperations: {
      handler (newValue) {
        this.notSavedChanges = true
      },
      deep: true
    }
  },
  methods: {
    logout () {
      const role = this.currentRole
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${this.env.abkOpEndSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${this.env.abkOpEndSsessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    getCompanyProfile () {
      const promises = [
        ApiDictionaryAcceptances.getDictionaryAcceptanceWords('employer'),
        ApiDictionaryAcceptances.getDictionaryAcceptanceWords('cooperation'),
        ApiDictionaryAcceptances.getDictionaryAcceptanceWords('survey'),
        ApiCompanies.getMyCompanyEdit()
      ]

      axios.all(promises)
        .then(responses => {
          responses.forEach(response => {
            if (response.data.data.type === 'company') {
              this.companyId = response.data.data.id
              const { logo, backgroundImage, ...rest } = response.data.data.attributes
              this.company = rest
              this.images = Object.assign({}, this.images, { logo, backgroundImage })
              let key = 0
              const sections = response.data.included.filter(section => section.type === 'company_section')
              this.companySections = sections.map(section => {
                const subsectionIds = section.relationships.companySubsections.data.map(subsection => subsection.id)
                const subsections = response.data.included.filter(({ id, type }) => {
                  return type === 'company_subsection' && subsectionIds.includes(id)
                })

                return {
                  key: key++,
                  id: section.id,
                  ...section.attributes,
                  companySubsections: subsections.map(({ id, attributes }) => {
                    return {
                      key: key++,
                      id: id,
                      ...attributes
                    }
                  })
                }
              })
              // W tabeli company_acceptance przechowujemy i zgody i formy wspolpracy
              // dla tego musimy wyciagac z tego co dostaniemy i jedno i drugie i rozdzielic na podstawie
              // typu jaki tam jest
              const includedAcceptances = response.data.included.filter(e => e.type === 'company_acceptance')
              const acceptancesTmp = includedAcceptances.filter(e => {
                return ['survey', 'employer'].includes(e.attributes.word.type)
              })
              const cooperationsTmp = includedAcceptances.filter(e => e.attributes.word.type === 'cooperation')

              acceptancesTmp.forEach(companyAcceptance => {
                const existingAcceptance = this.acceptances.find(acceptance => {
                  return acceptance.dictionaryAcceptanceWordId === companyAcceptance.attributes.dictionaryAcceptanceWordId.toString()
                })
                if (existingAcceptance) {
                  existingAcceptance.value = companyAcceptance.attributes.value
                  existingAcceptance.id = companyAcceptance.id
                } else {
                  this.acceptances.push({
                    key: companyAcceptance.attributes.dictionaryAcceptanceWordId,
                    id: companyAcceptance.id,
                    ...companyAcceptance.attributes
                  })
                }
              })

              cooperationsTmp.forEach(companyCooperation => {
                const existingAcceptance = this.cooperations.find(cooperation => {
                  return cooperation.dictionaryAcceptanceWordId === companyCooperation.attributes.dictionaryAcceptanceWordId.toString()
                })
                if (existingAcceptance) {
                  existingAcceptance.value = companyCooperation.attributes.value
                  existingAcceptance.id = companyCooperation.id
                } else {
                  this.cooperations.push({
                    key: companyCooperation.attributes.dictionaryAcceptanceWordId,
                    id: companyCooperation.id,
                    ...companyCooperation.attributes
                  })
                }
              })
              if (response.data.data.attributes.status === 'draft') {
                this.$swal({
                  title: this.$t('views.edit_company_profile.draft_info_title'),
                  text: this.$t('views.edit_company_profile.draft_info_message')
                })
              }
            } else {
              // pobieramy dwoma roznymi requestami raz ackeptacje employera
              // drugi raz formy wspolpracy i musimy je rozroznic
              const acceptancesTmp = response.data.data.filter(element => {
                return ['survey', 'employer'].includes(element.attributes.type)
              })
              const cooperationsTmp = response.data.data.filter(element => element.attributes.type === 'cooperation')
              if (acceptancesTmp.length) {
                this.acceptances = [
                  ...this.acceptances,
                  ...acceptancesTmp.map(acceptance => {
                    if (this.acceptances.findIndex(acc => acc.dictionaryAcceptanceWordId === acceptance.id.toString()) === -1) {
                      return {
                        id: null,
                        dictionaryAcceptanceWordId: acceptance.id,
                        value: null,
                        text: acceptance.attributes.value,
                        key: acceptance.id,
                        required: acceptance.attributes.status === 'required',
                        name: acceptance.attributes.name,
                        wordType: acceptance.attributes.wordType,
                        blockedOnProfile: acceptance.attributes.blockedOnProfile
                      }
                    }
                  }).filter((el) => (el !== undefined))
                ]
              }
              if (cooperationsTmp.length) {
                this.cooperations = cooperationsTmp.map(acceptance => {
                  return {
                    id: null,
                    dictionaryAcceptanceWordId: acceptance.id,
                    value: null,
                    text: acceptance.attributes.value,
                    key: acceptance.id,
                    required: acceptance.attributes.status === 'required',
                    name: acceptance.attributes.name,
                    wordType: acceptance.attributes.wordType,
                    blockedOnProfile: acceptance.attributes.blockedOnProfile
                  }
                })
              }
            }
          })
          let sortByName = []
          sortByName.push(...this.acceptances.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
          this.acceptances = sortByName
          sortByName = []
          sortByName.push(...this.cooperations.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })))
          this.cooperations = sortByName
          this.$nextTick(() => {
            this.notSavedChanges = false
          })
        })
        .catch((error) => {
          console.log(error)
          this.$toastr.e(this.$t('views.edit_company_profile.firm_download_profile'))
        })
    },
    updateCompany (status) {
      return new Promise((resolve, reject) => {
        ApiCompanies.updateMyCompany(this.getForm(status))
          .then((response) => {
            this.notSavedChanges = false
            this.$store.dispatch('auth/fetchUser')
              .then(() => {
              })
              .catch(error => {
                console.log(error.response.data.message)
                this.$toastr.e(error.response.data.message)
              })
            this.$toastr.s(this.$t('views.edit_company_profile.firm_profile_updated'))
            this.$router.go(-1)
            // this.$router.push({ name: 'company_show_path' })
            resolve(response)
          })
          .catch(error => {
            this.$toastr.e(this.$t('general.fields_not_filled'))
            this.errors = error.response.data
            reject(error)
          })
      })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.edit_company_profile.title'),
        subtitle: this.$t('views.edit_company_profile.subtitle')
      })
    },
    getForm (status) {
      return {
        company: {
          ...this.company,
          ...this.getImagesForm(),
          companySections: this.companySections,
          companyAcceptances: this.acceptances.concat(this.cooperations),
          status: status
        }
      }
    },
    isRemoveField (key, value) {
      return (/^remove\w+$/.test(key) && typeof value === 'boolean')
    },
    getImagesForm () {
      return _.pickBy(this.images, (value, key) => (value instanceof File) || this.isRemoveField(key, value))
    },
    deleteDraft () {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        showCancelButton: true,
        confirmButtonText: this.$t('general.reject'),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiCompanies.destroyDraft()
            .then(response => {
              this.errors = {}
              this.getCompanyProfile()
              this.$toastr.s(this.$t('views.edit_company_profile.draft_destroyed'))
            }).catch(error => {
              console.log(error)
              this.$toastr.e(this.$t('general.unexpected_error'))
            })
        }
      })
    },
    showPreview () {
      this.$router.push({ name: 'company_show_path', params: { id: this.companyId } })
    }
  },
  mounted () {
    this.getCompanyProfile()
    this.getImagesForm()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.notSavedChanges) {
      this.showUnsavedChangesModal = true
      this.$nextTick(() => {
        this.$refs.unsavedChangesModal.$once('cancel', () => {
          console.log('cancel')
          this.showUnsavedChangesModal = false
          next()
        })
        this.$refs.unsavedChangesModal.$once('save', () => {
          console.log('save')
          this.updateCompany('saved').then(response => {
            this.showUnsavedChangesModal = false
            next()
          })
        })
        this.$refs.unsavedChangesModal.$once('draft', () => {
          console.log('draft')
          this.updateCompany('draft').then(response => {
            this.showUnsavedChangesModal = false
            next()
          })
        })
        this.$refs.unsavedChangesModal.$once('input', (val) => {
          if (!val) {
            console.log('input', val)
            this.$refs.unsavedChangesModal.$off(['cancel', 'save', 'draft'])
            next(false)
          }
        })
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .danger-delete-btn, .danger-delete-btn:focus {
    background-color: $pcg-red;
    color: $color-wcag-static;
  }

  .danger-delete-btn:hover {
    background-color: $alert-error;
  }

  .form {
    margin-bottom: $pcg-m-xxl;
  }
  .progress-bar-container {
    margin-bottom: $pcg-m-lg;
  }
  .info {
    margin-bottom: $pcg-m-xxl;
    font-size: $font-size-s;
    i {
      font-size: $font-size-xl;
      margin-top: .15em;
    }
  }
  button {
    padding-left: 50px;
    padding-right: 50px;
  }
  .clause-information {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-bottom: 1rem;
  }
  .blocked-on-profile {
    opacity: 0.6;
  }
</style>

<style scoped lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .clause-information {
    color: $pcg-gray;
  }
}
</style>
