<template>
  <b-row>
    <h4 class="pcg-event-header-text">{{ $t('components.events.abk_event_step_3_form.info_text') }}</h4>
    <h3 class="header-title col-12 mb-4">
      <i class="pcg-icon-eye mr-3"></i>
      {{ $t('components.events.abk_event_step_3_form.title') }}
    </h3>
    <b-col cols="12">
      <b-row>
        <abk-content-preview class="col-12 col-xl-8" :attachments="attachments" :content-sections="content" expandable/>
        <abk-event-quick-info class="col-12 col-xl-4" :info="quickInfo"/>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import AbkContentPreview from '../AbkContentPreview'
import AbkEventQuickInfo from './AbkEventQuickInfo'
export default {
  name: 'AbkNewEventStep3Form',
  components: { AbkEventQuickInfo, AbkContentPreview },
  props: {
    quickInfo: Object,
    content: Array,
    attachments: Array
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .header-title {
    color: $main-color;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    i {
      font-size: $font-size-m;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .header-title {
      color: $main-color;
    }
  }
</style>
