<template>
  <pcg-box>
    <div class="header p-0">
      <div class="pcg-box-inner-header m-0">{{ $t('components.surveys.abk_survey_stats_answers_box.title') }}</div>
      <button v-if="questions.length > 0 && !expanded" class="expand-btn" @click="expandAllAnswers">
        {{ $t('general.expand_all') }}
      </button>
      <button v-if="expanded" class="expand-btn" @click="collapseAllAnswers">
        {{ $t('general.collapse_all') }}
      </button>
    </div>
    <template v-if="questions.length > 0">
      <abk-survey-stats-question-answers v-for="(question, index) in questions"
                                         :key="`question-${index}`"
                                         v-model="showAnswers[index]"
                                         :question="question"/>
    </template>
    <div v-else class="pcg-no-records">
      {{ $t('components.surveys.abk_survey_stats_answers_box.no_records') }}
    </div>
  </pcg-box>
</template>

<script>
import AbkSurveyStatsQuestionAnswers from './AbkSurveyStatsQuestionAnswers'

export default {
  name: 'AbkSurveyStatsBoxAnswers',
  components: { AbkSurveyStatsQuestionAnswers },
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showAnswers: [],
      expanded: false
    }
  },
  watch: {
    questions (newValue) {
      newValue.forEach((_, index) => {
        this.showAnswers[index] = this.showAnswers[index] || false
      })
    },
    showAnswers: {
      deep: true,
      handler () {
        if (this.showAnswers.includes(false) && !this.showAnswers.includes(true)) {
          this.expanded = false
        }
        if (this.showAnswers.includes(true) && !this.showAnswers.includes(false)) {
          this.expanded = true
        }
      }
    }
  },
  methods: {
    expandAllAnswers () {
      this.showAnswers = new Array(this.showAnswers.length).fill(true)
      this.expanded = true
    },
    collapseAllAnswers () {
      this.showAnswers = new Array(this.showAnswers.length).fill(false)
      this.expanded = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  ::v-deep .box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pcg-no-records {
    padding-left: $pcg-box-padding-x;
    padding-right: $pcg-box-padding-x;
  }
  .pcg-no-records {
    margin-top: $pcg-m-md;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: $pcg-box-padding-x;
    margin-right: $pcg-box-padding-x;
  }
  .expand-btn {
    background: none;
    border: none;
    outline: none;
    color: $main-active-color;
    text-transform: uppercase;
    font-weight: 500;
  }
</style>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars-contrast";

  .contrast {
    .expand-btn {
      color: $main-active-color;
    }
  }
</style>
